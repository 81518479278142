import React, { useEffect, useRef, useState } from "react";
import { Container, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faDownload,
  faXmark,
  faFloppyDisk,
  faFolder,
  faFile,
} from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";

import api from "../../components/common/commonFunctionForApi";
import Loader from "../../components/common/loader";
import logoutHelper from "../../helpers/logoutHelper";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import {
  Box,
  Stack,
  Breadcrumbs,
  Link,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Avatar,
  TableBody,
  Menu,
  MenuItem,
  Button,
  IconButton,
  Typography,
  Popover,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  TableHead,
  Tooltip,
  Tabs,
  Tab
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { CSVLink } from "react-csv";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";
import { Toaster } from "react-hot-toast";
import { Toast } from "../../utils/toast";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import StatusIcon from "../../components/common/StatusIcon";
import { exportXlsx } from "../../helpers/Exports/FileExport";
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { onlyDateFormat } from "../../helpers/DateTimeFormat/dateTimeFormat";
import { filterSliceAction } from "../../redux/slices/userTypePage/filterSlice";
import { typeTabAction } from "../../redux/slices/type/type";
import PaginationCustom from "../../components/common/pagination/paginationCustom";

function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box className="tab-box">{children}</Box>}
    </div>
  );
}


const Home = () => {
  let { type } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const csvLinkRef = useRef();
  const { selectedTypeTabForAdmin, paginationForPost } = useSelector((state) => state?.typeTab);
  const selectedTypeID = localStorage.getItem("type-selected-id");
  const selectedTypeName = localStorage.getItem("type-selected-name");
  const user = localStorage.getItem("user");
  const userToken = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(false);
  const [allNews, setAllNews] = useState([]);
  const [isWarningMessage, setIsWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [valueTab, setValueTab] = useState(selectedTypeTabForAdmin);

  //states for csv
  const [getChangelogCsvData, setGetChangelogCsvData] = useState([]);
  const [isCsvReady, setIsCsvReady] = useState(false);

  const [dataOrder, setDataOrder] = useState("asc");
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [openConfirmDeleteAlert, setOpenConfirmDeleteAlert] = useState(false);
  const [categoryId, setCategoryId] = useState();

  //states for paginations
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [nextPage, setNextPage] = useState(false);
  const [isDataFetching, setIsDataFetching] = useState(false);

  const [anchorElMenu, setAnchorElForMenu] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openAddPopup, setOpenAddPopup] = useState(false);

  const [isFolderEnable, setIsFolderEnable] = useState(false)

  const [openAddFolder, setOpenAddFolder] = useState(false)
  const [newFolderName, setNewFolderName] = useState()

  const [openFolderIds, setOpenFolderIds] = useState([]);
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [selectedFolderName, setSelectedFolderName] = useState("");

  const [folderDetails, setFolderDetails] = useState()
  const [breadcrumbData, setBreadcrumbData] = useState({ 0: selectedTypeName });
  const [typeDetails, setTypeDetails] = useState()

  const openMenuFoEdit = Boolean(anchorElMenu);
  const ITEM_HEIGHT = 48;

  const header_csv = [
    { label: "No", key: "no" },
    { label: "Name", key: "name" },
    { label: "Last Created", key: "lastcreated" },
    { label: "Created BY", key: "createdby" },
    { label: "Status", key: "status" },
  ];

  const {
    open,
  } = useSelector((state) => state?.drawerMenu);

  const { sortTypeForAdmin } = useSelector((state) => state.filter);


  const handleOpenWarningMessage = () => {
    setIsWarningMessage(true);
    setIsWarningMessage("");
  };

  const handleClick = (event, row) => {
    setAnchorElForMenu(event.currentTarget);
    setCategoryId(row);
  };

  const handleCloseWarningMessage = () => {
    setIsWarningMessage(false);

    setWarningMessage("");
  };

  const handleOpenSuccessMessage = () => {
    setIsSuccessMessage(true);
    setIsSuccessMessage("");
  };

  const handleCloseSuccessMessage = () => {
    setIsSuccessMessage(false);
    setIsSuccessMessage("");
  };

  const handleClose = () => {
    setAnchorElForMenu(null);
  };

  const handleCloseAddFolderModel = () => {
    setOpenAddFolder(false);
    setNewFolderName()
    setFolderDetails()
  };

  const handleAddClick = (event) => {
    setOpenAddPopup(true)
    setAnchorEl(event.currentTarget);
  };

  const handleCloseForAdd = () => {
    setAnchorEl(null);
    setOpenAddPopup(false)
  };

  const handleCloseDeleteAlertModel = () => {
    setOpenDeleteAlert(false);
  };

  const handleCloseConfirmDeleteAlertModel = () => {
    setOpenConfirmDeleteAlert(false);
  };

  const handleChangeDataOrdr = () => {
    let order = dataOrder === "asc" ? "desc" : "asc";
    setDataOrder(order);
    setAllNews([]);
    setPage(1);
  };

  const handleChange = (event, newValue) => {
    dispatch(typeTabAction.handleSelectedTabForAdmin(newValue));
    setValueTab(newValue);
    setPage(1);
    fetchAllTypePost(1, selectedFolderId, selectedFolderName, newValue, paginationForPost?.per_page);
  };

  const handleSort = (key) => {
    let sortTypeValue = null;
    if (sortTypeForAdmin[key] === null) {
      sortTypeValue = 'asc';
    } else if (sortTypeForAdmin[key] === 'asc') {
      sortTypeValue = 'desc';
    } else if (sortTypeForAdmin[key] === 'desc') {
      sortTypeValue = null;
    }
    const data = {
      name: null,
      updated_at: null,
      created_by: null,
      [key]: sortTypeValue
    };
    dispatch(filterSliceAction.setSortTypeForAdmin(data));

  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    dispatch(typeTabAction.setPaginationForPost({
      ...paginationForPost,
      page: newPage
    }))

    fetchAllTypePost(newPage, selectedFolderId, selectedFolderName, valueTab, paginationForPost?.per_page);

  };

  const handleChangeRowsPerPage = (event) => {

    dispatch(typeTabAction.setPaginationForPost({
      ...paginationForPost,
      per_page: event.target.value,
      page: 1
    }));
    fetchAllTypePost(page, selectedFolderId, selectedFolderName, valueTab, event.target.value);

  };

  const handleFolderClickForManage = async (id) => {
    try {
      setLoading(true);
      const response = await api.get(`admin/post/folder-edit/${id} `, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setFolderDetails(response?.response?.data)
        setNewFolderName(response?.response?.data?.folder_name)
        setAnchorElForMenu(null)
        setOpenAddFolder(true)
        setLoading(false);

        // setAnchorElForMenu(null);
      } else if (response?.success == false) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        setLoading(false);
        // setAnchorElForMenu(null);
      }
    } catch (err) {
      console.log("error for change type status::", err)
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  }

  const updateBreadcrumbs = (folderId, folderName) => {
    console.log("updateBreadcrumbs folderId::", folderId)
    if (!folderId) return;  // Exit early if folderId is null or undefined
    setBreadcrumbData((prevData) => {
      const newBreadcrumbs = { ...prevData };

      // Check if folderId exists in the breadcrumb data
      if (newBreadcrumbs.hasOwnProperty(folderId)) {
        // If it exists, remove that entry and everything after it
        const updatedBreadcrumbs = {};
        Object.keys(newBreadcrumbs).forEach((key) => {
          if (parseInt(key, 10) <= folderId) {
            updatedBreadcrumbs[key] = newBreadcrumbs[key];
          }
        });
        return updatedBreadcrumbs;
      } else {
        // If it doesn't exist, append the new folderId and folderName
        const newKey =
          Math.max(...Object.keys(newBreadcrumbs).map(Number)) + 1;
        return { ...newBreadcrumbs, [folderId]: folderName };
      }
    });
  };

  const changeTypePostStatus = async (id, status) => {
    try {
      let data = {
        status: status,
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/post/status `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {

        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setAnchorElForMenu(null);
        fetchAllTypePost(page, selectedFolderId, selectedFolderName, valueTab, paginationForPost?.per_page)
      } else if (response?.success == false) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      console.log("error for change type status::", err)
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  };

  const RestoreTypePost = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/post/restore `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        console.log(response);

        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setAnchorElForMenu(null);
        fetchAllTypePost(page, selectedFolderId, selectedFolderName, valueTab, paginationForPost?.per_page)
      } else if (response?.success == false) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        setLoading(false);
        handleClose();
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  };

  const deleteTypePostAPI = async (id) => {
    try {
      setLoading(true);
      const response = await api.delete(`admin/post/delete/${id} `, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {

        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        handleCloseDeleteAlertModel();
        setAnchorElForMenu(null);
        fetchAllTypePost(page, selectedFolderId, selectedFolderName, valueTab, paginationForPost?.per_page)
      } else if (response?.success == false) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        handleCloseDeleteAlertModel();
        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  };

  const permanantlyDeleteTypePostAPI = async (id) => {
    try {
      setLoading(true);
      const response = await api.delete(`admin/post/forcedelete/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setIsSuccessMessage(true);
        setPage(1);
        setAllNews([]);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        handleCloseConfirmDeleteAlertModel();
        setAnchorElForMenu(null);
        fetchAllTypePost(page, selectedFolderId, selectedFolderName, valueTab, paginationForPost?.per_page);
      } else if (response?.success == false) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        handleCloseConfirmDeleteAlertModel();

        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  };

  const fetchAllTypePost = async (page, folder_id, folder_name, selectedTab, per_page) => {
    setLoading(true)
    try {

      let value =
        selectedTab == 0
          ? "all"
          : selectedTab == 1
            ? "enable"
            : selectedTab == 2
              ? "disable"
              : "deleted";
      let data = {
        type_id: selectedTypeID,
        page: page,
        folder_id: folder_id,
        per_page: per_page
      }

      const firstNonNull = Object.entries(sortTypeForAdmin).find(([key, value]) => value !== null);
      if (firstNonNull) {
        data.order_by = firstNonNull[0] + '_' + firstNonNull[1];
      }
      const userToken = localStorage.getItem("accessToken");

      const response = await api.post(
        `admin/post/list/${value}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      // setAllNews(response?.data);
      const newPosts = response?.response?.data;

      setAllNews(newPosts)
      dispatch(typeTabAction.setPaginationForPost({
        ...paginationForPost,
        page: response?.response?.meta?.current_page,
        per_page: response?.response?.meta?.per_page,
        meta_data: response?.response?.meta
      }));

      setIsFolderEnable(response?.response?.type?.is_folder === "disable" ? false : true)
      updateBreadcrumbs(folder_id, folder_name)
      setSelectedFolderId(null)
      setSelectedFolderName("")
      setTypeDetails(response?.response?.type)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log("error in fetch posts api::", err);
      if (err?.response?.status === 401) {
        setLoading(false)
        localStorage.setItem("last-redirect-page", `/type/${type}`);
        logoutHelper(dispatch, navigate, userToken);
      }

      if (err?.response?.status == 429) {
        setLoading(false)
        Toast.error("Too many attempts wait for 2 min.")
      }
    } finally {
      setIsDataFetching(false); // Reset fetching state after the API call
      setLoading(false);
    }
  };

  const fetchAllTypePostForScroll = async (page, folder_id, folder_name) => {
    setLoading(true)
    try {
      if (page == 1) {
        setLoading(true);
      }
      let data = {
        type_id: selectedTypeID,
        page: page,
        folder_id: folder_id
      }

      const firstNonNull = Object.entries(sortTypeForAdmin).find(([key, value]) => value !== null);
      if (firstNonNull) {
        data.order_by = firstNonNull[0] + '_' + firstNonNull[1];
      }
      const userToken = localStorage.getItem("accessToken");

      const response = await api.post(
        `admin/post/list/all`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const newPosts = response?.response?.data;
      setAllNews((prevPosts) => {
        const uniquePosts = newPosts.filter(
          (newPost) =>
            !prevPosts.some((post) => post.id === newPost.id)
        );
        return [...prevPosts, ...uniquePosts];
      });

      setTotalPages(response?.response?.meta.last_page);
      if (page + 1 <= response?.response?.meta.last_page) {
        setNextPage(true);
      }
      setIsFolderEnable(response?.response?.type?.is_folder === "disable" ? false : true)
      updateBreadcrumbs(folder_id, folder_name)
      setSelectedFolderId(null)
      setSelectedFolderName("")
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log("error in fetch posts api::", err);
      if (err?.response?.status === 401) {
        setLoading(false)
        localStorage.setItem("last-redirect-page", `/type/${type}`);
        logoutHelper(dispatch, navigate, userToken);
      }

      if (err?.response?.status == 429) {
        setLoading(false)
        Toast.error("Too many attempts wait for 2 min.")
      }
    } finally {
      setIsDataFetching(false); // Reset fetching state after the API call
      setLoading(false);
    }
  };

  const addNewFolderAPI = async () => {
    if (!newFolderName) {
      return Toast.error("Please enter folder name")
    }
    let data = {
      folder_name: newFolderName,
      parent_id: selectedFolderId
    }

    try {
      setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(
        `/user/post/store-folder/${selectedTypeID}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success == true) {
        Toast.success(response?.message);
        setLoading(false);
        handleCloseAddFolderModel()
        setNewFolderName()
        fetchAllTypePost(page, selectedFolderId, selectedFolderName, valueTab, paginationForPost?.per_page)
      } else {
        setLoading(false);
        Toast.error(response?.message || response?.response)
      }
    } catch (err) {
      console.log("error in acknowledge user::", err);
      setLoading(false);
      if (err?.response?.status == 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      Toast.error(err?.response?.data?.message)
    }
  }

  const updateFolderAPI = async () => {
    if (!newFolderName) {
      return Toast.error("Please enter folder name")
    }
    let data = {
      folder_name: newFolderName,
    }

    try {
      setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(
        `/admin/post/folder-update/${folderDetails?.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success == true) {
        Toast.success(response?.message);
        setLoading(false);
        handleCloseAddFolderModel()
        setNewFolderName()
        fetchAllTypePost(page, selectedFolderId, selectedFolderName, valueTab, paginationForPost?.per_page)
      } else {
        setLoading(false);
        Toast.error(response?.response?.message || response?.response)
      }
    } catch (err) {
      console.log("error in acknowledge user::", err);
      setLoading(false);
      if (err?.response?.status == 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      Toast.error(err?.response?.data?.message || err?.response?.data?.response)
    }
  }

  const deleteFolderAPI = async (id) => {
    try {
      setLoading(true);
      const response = await api.delete(`admin/post/folder-delete/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setAllNews((prevPosts) => prevPosts.filter((post) => post.id !== id));
        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        handleCloseDeleteAlertModel();
        setAnchorElForMenu(null);
      } else if (response?.success == false) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        handleCloseDeleteAlertModel();

        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  };

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  const handleScroll = async () => {
    const scrollableHeight = document.documentElement.scrollHeight;
    const scrollPosition = window.innerHeight + window.scrollY;
    const remainingScrollThreshold = scrollableHeight * 0.3;

    if (
      scrollableHeight - scrollPosition < remainingScrollThreshold &&
      !isDataFetching &&
      page < totalPages
    ) {
      console.log("inside handle scroll if condition");
      setIsDataFetching(true);
      const nextPageNum = page + 1;
      setPage(nextPageNum);
      await fetchAllTypePostForScroll(nextPageNum, selectedFolderId, selectedFolderName);
    }
  };

  const handleExportClick = async () => {
    await handleChangeLogCsvDownload(dataOrder);
  };

  const handleChangeLogCsvDownload = async (order) => {
    try {
      setLoading(true);
      const firstNonNull = Object.entries(sortTypeForAdmin).find(([key, value]) => value !== null);
      let order_by
      let value =
        valueTab == 0
          ? "all"
          : valueTab == 1
            ? "enable"
            : valueTab == 2
              ? "disable"
              : "deleted";
      if (firstNonNull) {
        order_by = firstNonNull[0] + '_' + firstNonNull[1];
      }
      const result = await exportXlsx(`admin/post/export/${value}/?order_by=${order_by === undefined ? "date_desc" : order_by}&type_id=${selectedTypeID}&folder_id=${selectedFolderId === null ? 0 : selectedFolderId}`, selectedTypeName);
      if (!result) {
        Toast.error("Failed to download file");
      }

    } catch (error) {
      console.error("Error while downloading csv changelog::", error);
      Toast.error("Failed to download file");
    } finally {
      setLoading(false);
    }
  }

  function a11yProps1(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        valueTab == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }
  function a11yProps2(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        valueTab == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }
  function a11yProps3(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        valueTab == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }
  function a11yProps4(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        valueTab == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }

  useEffect(() => {
    setSelectedFolderId(null)
    setSelectedFolderName("")
    setBreadcrumbData({ 0: selectedTypeName })
    setAllNews([]);
    setGetChangelogCsvData([]);
    setPage(1);

    fetchAllTypePost(page, selectedFolderId, selectedFolderName, valueTab, paginationForPost?.per_page);
  }, [type]);

  useEffect(() => {
    fetchAllTypePost(page, selectedFolderId, selectedFolderName, valueTab, paginationForPost?.per_page);
  }, [sortTypeForAdmin])


  useEffect(() => {
    if (isCsvReady && getChangelogCsvData.length > 0) {
      csvLinkRef.current.link.click();
      setIsCsvReady(false);
    }
  }, [isCsvReady, getChangelogCsvData]);

  return (
    <>

      {/* START :: MAIN CONTENT WRAPPER */}

      <div
        className={`main-content-wrapper ${open === true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          {isSuccessMessage && (
            <SuccessAlert
              message={successMessage}
              onClose={handleCloseSuccessMessage}
            />
          )}

          {isWarningMessage && (
            <WarningAlert
              message={warningMessage}
              onClose={handleCloseWarningMessage}
            />
          )}
          <div className="main-div-2-types">
            <Box className="">
              <Stack spacing={2}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link
                    underline="hover"
                    key="1"
                    color="inherit"
                    href="/"
                    component="h3"
                    className="cursor-pointer"
                  >
                    Admin
                  </Link>

                  {Object.entries(breadcrumbData).map(
                    ([key, value], index, array) => {
                      const isLast =
                        index === array.length - 1;
                      return isLast ? (
                        <Typography
                          key={key}
                          component="h3"
                          color="text.primary"
                          className="cursor-pointer"
                          underline="hover"
                        >
                          {value}
                        </Typography>
                      ) : (
                        <Link
                          key={key}
                          color="inherit"
                          onClick={() =>
                            fetchAllTypePost(page, key, value, valueTab, paginationForPost?.per_page)
                          }
                          component="h3"
                          className="cursor-pointer"
                          underline="hover"
                        >
                          {value}
                        </Link>
                      );
                    }
                  )}
                </Breadcrumbs>
              </Stack>
            </Box>
            <div className="d-flex flex-wrap gap-3">
              <Button className="btn export-btn" variant="outlined" onClick={handleExportClick}>
                Export
                <FontAwesomeIcon icon={faDownload} />
              </Button>
              <CSVLink
                data={getChangelogCsvData}
                headers={header_csv}
                filename={`type post ${selectedTypeName}.csv`}
                target="_blank"
                className="csv-btn-a"
                ref={csvLinkRef} // Refrence for auto downlaod
                style={{ display: "none" }} // Hide the CSVLink
              />
              <Button
                className="btn primary-btn"
                onClick={(e) => {
                  handleAddClick(e)
                }}
              >
                Add New
                <FontAwesomeIcon icon={faPlus} />
              </Button>
              <Popover
                id={openAddPopup ? 'simple-popover' : undefined}
                open={openAddPopup}
                anchorEl={anchorEl}
                onClose={handleCloseForAdd}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                className="mt-2"
              >
                <Box>
                  <List component="nav" className="p-0" aria-label="main mailbox folders">
                    <ListItemButton
                      onClick={() => {
                        handleCloseForAdd()
                        localStorage.setItem(
                          "last-redirect-page",
                          `/type/add/${type}`
                        );
                        navigate(`/type/add/${type}`)
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 0, mr: 2, justifyContent: "center" }}>
                        <FontAwesomeIcon icon={typeDetails?.icons?.icon_value} className="new-icon" />
                      </ListItemIcon>
                      <ListItemText primary={`${selectedTypeName}`} className="new-text" />
                    </ListItemButton>

                    {isFolderEnable === true && (
                      <>
                        <Divider className="divider" />
                        <ListItemButton
                          onClick={() => {
                            handleCloseForAdd()
                            setOpenAddFolder(true)
                          }}
                        >
                          <ListItemIcon sx={{ minWidth: 0, mr: 2, justifyContent: "center" }}>
                            <FontAwesomeIcon icon={faFolder} className="new-icon" />
                          </ListItemIcon>
                          <ListItemText primary="Folder" className="new-text" />
                        </ListItemButton>
                      </>
                    )}

                  </List>
                </Box>
              </Popover>
            </div>
          </div>
          <Divider className="mt-3" style={{ background: "#EEEEEE" }} />
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="box-attribute"
            >
              <Tabs
                value={valueTab}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                className={"tabs type-post-tab"}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#660C60",
                  },
                }}
              >
                <Tab
                  label="All"
                  iconPosition="start"
                  {...a11yProps1(0)}
                />

                <Tab
                  label="Active"
                  iconPosition="start"
                  {...a11yProps2(1)}
                />
                <Tab
                  label="Inactive"
                  {...a11yProps3(2)}
                  iconPosition="start"
                />
                <Tab
                  label="Deleted"
                  {...a11yProps4(3)}
                  iconPosition="start"
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={valueTab} index={valueTab}>
              <Paper sx={{ width: "100%" }} className="type-list-paper">
                <TableContainer component={Paper} className="type-list-container">
                  <Table
                    sx={{ minWidth: 700 }} aria-label="customized table"
                  // stickyHeader
                  >
                    {allNews?.length > 0 && (
                      <>
                        <TableHead className='type-list-header'>
                          <TableRow>
                            <TableCell className='type-list-header-data cursor-pointer type-list-first-header'
                              onClick={
                                () => {
                                  handleSort('name');
                                }
                              }
                            >Name {(sortTypeForAdmin?.name && sortTypeForAdmin?.name === 'asc') ? <ExpandLess className="sort-icon" /> : ((sortTypeForAdmin?.name && sortTypeForAdmin?.name === 'desc') ? <ExpandMore className="sort-icon" /> : <ExpandLess className="sort-icon text-muted opacity-50" />)}</TableCell>
                            <TableCell className='type-list-header-data type-list-second-header-att-post' align="left">Last Updated</TableCell>
                            <TableCell className='type-list-header-data type-list-third-header' align="left">Creator</TableCell>
                            <TableCell className='type-list-header-data type-list-last-header-att-post' align="right">Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableRow style={{ height: '10px', backgroundColor: 'transparent' }} />

                        <TableBody className='type-list-body'>
                          {allNews?.map((row, index) => (
                            <TableRow className='type-list-body-row' key={index + 1}>
                              <TableCell className='' component="td" scope="row">

                                <Box className={`d-flex gap-2 align-items-center ${row?.list_type === 'folder' && "cursor-pointer"}`} onClick={() => {
                                  if (row?.list_type === 'folder') {
                                    setSelectedFolderId(row?.id)
                                    fetchAllTypePost(page, row?.id, row?.title, valueTab, paginationForPost?.per_page)
                                  }
                                }}>
                                  {row?.list_type === 'folder' ? (
                                    <FontAwesomeIcon className='type-list-title-icon' icon={faFolder} />
                                  ) : (
                                    <FontAwesomeIcon className='type-list-title-icon' icon={faFile} />
                                  )}
                                  <Tooltip title={row?.title?.length > 50 ? row?.title : ''} placement="top">
                                    <Typography className="type-list-title" variant="h4" component="div">
                                      {row?.title?.length > 50 ? row?.title.substr(0, 50) + '...' : row?.title}
                                    </Typography>
                                  </Tooltip>
                                </Box>
                              </TableCell>
                              <TableCell className='type-list-text' align="left">
                                {onlyDateFormat(row?.modified_date, false, false, true) ?? '-'}
                              </TableCell>

                              <TableCell className='' align="left">
                                <Box className='type-list-text d-flex align-items-center type-list-createdby'>
                                  <Avatar
                                    className="type-list-created-avatar"
                                    src={row?.created_by?.image}
                                    alt={row?.created_by?.name}
                                  >
                                    {row?.created_by?.name?.charAt(0)}
                                  </Avatar>
                                  <span className>
                                    {`${(row?.created_by?.last_name && row?.created_by?.last_name?.trim() !== '') ? row?.created_by?.name[0] + '.' : row?.created_by?.name ?? ''} ${row?.created_by?.last_name ?? ''}` ?? '-'}
                                  </span>
                                </Box>
                              </TableCell>
                              <TableCell
                                align="center"
                                className="tbl-body-cell-2 tbl-border-right"
                              >
                                <div>
                                  <Stack
                                    direction="row"
                                    alignContent="center"
                                    alignItems="center"
                                    spacing={1}
                                    className="gap-1"
                                  >
                                    <StatusIcon status={row?.is_archived === 1 ? 'archived' : row?.status} />
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-haspopup="true"
                                      className="status-icon"
                                      onClick={(e) => handleClick(e, row)}
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </Stack>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </>
                    )}
                  </Table>
                </TableContainer>

                {allNews?.length > 0 && (
                  <>
                    <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
                    <PaginationCustom
                      per_page={paginationForPost?.per_page}
                      per_page_options={[10, 20, 50, 100]}
                      from={paginationForPost?.meta_data?.from}
                      to={paginationForPost?.meta_data?.to}
                      last_page={paginationForPost?.meta_data?.last_page}
                      total={paginationForPost?.meta_data?.total}
                      page={paginationForPost?.page}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </>
                )}
              </Paper>
              {allNews?.length < 1 && (
                <div className="no-data mt-5">
                  <div>
                    <Image
                      src={NoDataFoundImg}
                      className="text-center mx-auto no-data-img mt-4"
                    />
                  </div>
                  <Typography component="h5" variant="h5">
                    No data found
                  </Typography>
                </div>
              )}
            </CustomTabPanel>
          </Box>


        </Container>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openDeleteAlert}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            Are you sure?
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDeleteAlertModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="">
            {" "}
            Are you sure want to delete {categoryId?.list_type === "folder" ? "folder" : "post"}?
          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
            <Button
              className="btn delete-btn-model"
              onClick={handleCloseDeleteAlertModel}
            >
              <FontAwesomeIcon icon={faXmark} />
              Cancel
            </Button>
            <Button
              className="btn primary-btn"
              onClick={() => {
                if (categoryId?.list_type === "folder") {
                  deleteFolderAPI(categoryId?.id)
                } else {
                  deleteTypePostAPI(categoryId?.id)
                }
              }
              }
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openConfirmDeleteAlert}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            Are you sure?
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseConfirmDeleteAlertModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="">
            {" "}
            Are you sure want to permanantly delete {categoryId?.list_type === "folder" ? "folder" : "post"}? Once you permanatly
            delete you can not restore it again.
          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
            <Button
              className="btn delete-btn-model"
              onClick={handleCloseConfirmDeleteAlertModel}
            >
              <FontAwesomeIcon icon={faXmark} />
              Cancel
            </Button>
            <Button
              className="btn primary-btn"
              onClick={() => permanantlyDeleteTypePostAPI(categoryId?.id)}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openAddFolder}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            {folderDetails?.id ? "Edit" : "Add"} Folder
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseAddFolderModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="">
            <Box>
              <Typography className="fs-14 mt-2">Folder Name</Typography>
              <TextField
                className="input-field mt-2"
                fullWidth
                id="outlined-basic short summary"
                variant="outlined"
                placeholder={`write folder name`}
                value={newFolderName}
                onChange={(event) => setNewFolderName(event?.target?.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
            <Button
              className="btn delete-btn-model"
              onClick={handleCloseAddFolderModel}
            >
              <FontAwesomeIcon icon={faXmark} />
              Cancel
            </Button>
            <Button
              className="btn primary-btn"
              onClick={() => {
                if (folderDetails?.id) {
                  updateFolderAPI()
                } else {
                  addNewFolderAPI()
                }
              }}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              {folderDetails?.id ? "Edit" : "Add"} Folder
            </Button>
          </DialogActions>
        </Dialog>
        <Menu
          anchorEl={anchorElMenu}
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          open={openMenuFoEdit}
          anchorOrigin={"right"}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
              zIndex: "1",
            },
          }}
        >
          {categoryId?.status !== "deleted" && (
            <MenuItem
              onClick={() => {
                if (categoryId?.list_type === "folder") {
                  handleFolderClickForManage(categoryId?.id)
                } else {
                  localStorage.setItem(
                    "last-redirect-page",
                    `/type/edit/${type}/${categoryId?.id}`
                  );
                  navigate(`/type/edit/${type}/${categoryId?.id}`);
                }

              }}
            >
              Edit
            </MenuItem>
          )}

          {categoryId?.status !== "enable" &&
            categoryId?.status !== "deleted" &&
            categoryId?.list_type !== "folder" &&
            categoryId?.status !== "draft" &&
            (
              <MenuItem
                onClick={() => {
                  changeTypePostStatus(categoryId?.id, "enable");
                }}
              >
                Active
              </MenuItem>
            )}
          {categoryId?.status !== "disable" &&
            categoryId?.status !== "deleted" &&
            categoryId?.list_type !== "folder" &&
            categoryId?.status !== "draft" &&
            (
              <MenuItem
                onClick={() => {
                  changeTypePostStatus(categoryId?.id, "disable");
                }}
              >
                Inactive
              </MenuItem>
            )}
          {categoryId?.status !== "deleted" && (
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenDeleteAlert(true);
              }}
            >
              Delete
            </MenuItem>
          )}
          {categoryId?.status === "deleted" && (
            <MenuItem
              onClick={() => {
                RestoreTypePost(categoryId?.id);
              }}
            >
              Restore
            </MenuItem>
          )}
          {categoryId?.status === "deleted" && (
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenConfirmDeleteAlert(true);
              }}
            >
              Permanantly Delete
            </MenuItem>
          )}
        </Menu>
        <Loader isLoading={loading} />
        <Toaster />
      </div>

      {/* END :: MAIN CONTENT WRAPPER */}
    </>
  );
};

export default Home;
