import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Image } from "react-bootstrap";
import {
    Box,
    Stack,
    Breadcrumbs,
    Link,
    Typography,
    Divider,
    Paper,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    Avatar,
    TableBody,
    Menu,
    MenuItem,
    Tabs,
    Tab,
    TableHead,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import logoutHelper from "../../helpers/logoutHelper";
import Loader from "../../components/common/loader";
import api from "../../components/common/commonFunctionForApi";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";
import { Toast } from "../../utils/toast";
import {
    faXmark,
    faFloppyDisk
} from "@fortawesome/free-solid-svg-icons";
import { Toaster } from "react-hot-toast";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import StatusIcon from "../../components/common/StatusIcon";
import { insightTabAction } from "../../redux/slices/insight/insight";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import PaginationCustom from "../../components/common/pagination/paginationCustom";

function CustomTabPanel(props) {
    const { children, value, className, index, errorTab, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
            {...other}
        >
            {value === index && <Box className="tab-box">{children}</Box>}
        </div>
    );
}

const InsightList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [anchorElMenu, setAnchorElForMenu] = useState(null);

    const openMenuFoEdit = Boolean(anchorElMenu);
    const [loading, setLoading] = useState(false);
    const [getAllInsights, setGetAllInsights] = useState([])
    const { selectedInsightTab, pagination } = useSelector((state) => state?.insight);

    const [value, setValue] = useState(selectedInsightTab);
    const [insightId, setInsightId] = useState();

    const [isWarningMessage, setIsWarningMessage] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");
    const [isSuccessMessage, setIsSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
    const [openConfirmDeleteAlert, setOpenConfirmDeleteAlert] = useState(false);

    const [page, setPage] = useState(1);

    const [sortTypeForAdmin, setSortTypeForAdmin] = useState({
        name: null,
        updated_at: null,
        created_by: null,
    })

    const user = localStorage.getItem("user");
    const loggedUser = JSON.parse(user);
    const userToken = localStorage.getItem("accessToken");

    const {
        open,
    } = useSelector((state) => state?.drawerMenu);

    const ITEM_HEIGHT = 48;

    const handleCloseWarningMessage = () => {
        setIsWarningMessage(false);
        setWarningMessage("");
    };

    const handleCloseSuccessMessage = () => {
        setIsSuccessMessage(false);
        setSuccessMessage("");
    };

    const getStatusValue = (value) => {
        return value === 0 ? "all"
            : value === 1 ? "enable"
                : value === 2 ? "disable"
                    : "deleted";
    };

    const handleChange = (event, newValue) => {
        dispatch(insightTabAction?.handleSelectedTab(newValue))
        setValue(newValue);
        setGetAllInsights([]);
        fetchAllInsights(getStatusValue(newValue), page, pagination?.per_page)
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
        dispatch(insightTabAction.setPagination({
            ...pagination,
            page: newPage
        }))

        fetchAllInsights(getStatusValue(value), newPage, pagination?.per_page)
    };

    const handleChangeRowsPerPage = (event) => {

        dispatch(insightTabAction.setPagination({
            ...pagination,
            per_page: event.target.value,
            page: 1
        }));
        fetchAllInsights(getStatusValue(value), page, event.target.value)
    };

    const handleClick = (event, row) => {
        setAnchorElForMenu(event.currentTarget);
        setInsightId(row);
    };

    const handleClose = () => {
        setAnchorElForMenu(null);
    };

    const handleCloseDeleteAlertModel = () => {
        setOpenDeleteAlert(false);
    };

    const handleCloseConfirmDeleteAlertModel = () => {
        setOpenConfirmDeleteAlert(false);
    };


    const fetchAllInsights = async (type, page, perPage) => {
        try {
            setLoading(true);
            let order_by
            const firstNonNull = Object.entries(sortTypeForAdmin).find(([key, value]) => value !== null);
            if (firstNonNull) {
                order_by = firstNonNull[0] + '_' + firstNonNull[1];
            }
            const response = await api.get(
                `admin/insight/list/${type}?${order_by ? `order_by=${order_by}` : ""}&page=${page ?? 1}&per_page=${perPage}`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            const newPages = response?.response?.data;
            setGetAllInsights(newPages)
            dispatch(insightTabAction.setPagination({
                ...pagination,
                page: response?.response?.meta?.current_page,
                per_page: response?.response?.meta?.per_page,
                meta_data: response?.response?.meta
            }));
            setLoading(false);
        } catch (err) {
            console.log("err`or in fetch menu api::", err);
            if (err?.response?.status === 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attempts wait for 2 min.");
            }
            setLoading(false);
        } finally {
            setLoading(false)
        }
    }

    const changeInsightStatus = async (id, status) => {
        try {
            let data = {
                status: status,
            };
            setLoading(true);
            const response = await api.post(`admin/insight/change-status/${id} `, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success === true) {
                if (value === 0) {
                    setGetAllInsights((prevUsers) =>
                        prevUsers.map((user) =>
                            user.id === id ? { ...user, status: status } : user
                        )
                    );
                } else {
                    setGetAllInsights((prevUsers) =>
                        prevUsers.filter((user) => user.id !== id)
                    );
                }
                setIsSuccessMessage(true);
                setSuccessMessage(response?.message);
                Toast.success(response?.message);
                setLoading(false);
                setAnchorElForMenu(null);
                setInsightId();
            }
        } catch (err) {
            if (err?.response?.status === 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attempts wait for 2 min.");
            }
            setLoading(false);
        }
    };

    const RestoreInsight = async (id) => {
        try {
            setLoading(true);
            const response = await api.get(`admin/insight/restore/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success === true) {
                if (value === 0) {
                    setGetAllInsights((prevPosts) =>
                        prevPosts.map((post) =>
                            post.id === id ? { ...post, status: response?.response?.data?.status } : post
                        )
                    );
                } else {
                    setGetAllInsights((prevUsers) =>
                        prevUsers.filter((user) => user.id !== id)
                    );
                }
                setLoading(false);
                setIsSuccessMessage(true);
                setSuccessMessage(response?.message);
                Toast.success(response?.message);
                setLoading(false);
                setAnchorElForMenu(null);
            }
        } catch (err) {
            if (err?.response?.status === 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
            setLoading(false);
        }
    };

    const deleteInsightAPI = async (id) => {
        try {
            setLoading(true);
            const response = await api.delete(`admin/insight/delete/${id} `, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success === true) {
                if (value === 0) {
                    setGetAllInsights((prevUsers) =>
                        prevUsers.map((user) =>
                            user.id === id ? { ...user, status: 'deleted' } : user
                        )
                    );
                } else {
                    setGetAllInsights((prevUsers) =>
                        prevUsers.filter((user) => user.id !== id)
                    );
                }
                setLoading(false);
                setIsSuccessMessage(true);
                setSuccessMessage(response?.message);
                Toast.success(response?.message);
                handleCloseDeleteAlertModel();
                setLoading(false);
                setAnchorElForMenu(null);
            } else if (response?.success === false) {
                setIsWarningMessage(true);
                setWarningMessage(response?.message);
                Toast.warning(response?.message);
                handleCloseDeleteAlertModel();
                setLoading(false);
                setAnchorElForMenu(null);
            }
        } catch (err) {
            if (err?.response?.status === 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
            setLoading(false);
        }
    };

    const permanantlyDeleteInsightPI = async (id) => {
        try {
            setLoading(true);
            const response = await api.delete(`admin/insight/force-delete/${id} `, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success === true) {
                setIsSuccessMessage(true);
                setSuccessMessage(response?.message);
                Toast.success(response?.message);
                handleCloseConfirmDeleteAlertModel();
                setLoading(false);
                setAnchorElForMenu(null);

                setGetAllInsights([]);
                setPage(1);

                fetchAllInsights(
                    getStatusValue(value), page, pagination?.per_page
                );
            } else if (response?.success === false) {
                setIsWarningMessage(true);
                setWarningMessage(response?.message);
                Toast.warning(response?.message);
                handleCloseConfirmDeleteAlertModel();

                setLoading(false);
                setAnchorElForMenu(null);
            }
        } catch (err) {
            if (err?.response?.status === 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
            setLoading(false);
        }
    };

    function a11yProps2(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            className:
                value === index
                    ? `selected-tab-attribute`
                    : `non-selected-tab-attribute`,
        };
    }

    const handleSort = (key) => {
        let sortTypeValue = null;
        if (sortTypeForAdmin[key] === null) {
            sortTypeValue = 'asc';
        } else if (sortTypeForAdmin[key] === 'asc') {
            sortTypeValue = 'desc';
        } else if (sortTypeForAdmin[key] === 'desc') {
            sortTypeValue = null;
        }
        const data = {
            name: null,
            updated_at: null,
            created_by: null,
            [key]: sortTypeValue
        };
        setSortTypeForAdmin(data)
    }

    useEffect(() => {
        fetchAllInsights(getStatusValue(selectedInsightTab), 1, pagination?.per_page)
        setValue(selectedInsightTab);
    }, [])

    useEffect(() => {
        fetchAllInsights(getStatusValue(value), page, pagination?.per_page)
    }, [sortTypeForAdmin])

    return (
        <>
            <div
                className={`main-content-wrapper-2 ${open === true ? "drawer-open" : "drawer-close"
                    }  ${window.innerWidth <= 768 && "small-screen"}`}
            >
                <Container
                    className={`${window.innerWidth <= 768 && "small-screen-container"
                        }`}
                >
                    {isSuccessMessage && (
                        <SuccessAlert
                            message={successMessage}
                            onClose={handleCloseSuccessMessage}
                        />
                    )}
                    {isWarningMessage && (
                        <WarningAlert
                            message={warningMessage}
                            onClose={handleCloseWarningMessage}
                        />
                    )}
                    <div className="main-div-2-types">
                        <Box className="">
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={
                                        <NavigateNextIcon fontSize="small" />
                                    }
                                    aria-label="breadcrumb"
                                >
                                    <Link
                                        underline="hover"
                                        key="1"
                                        color="inherit"
                                        href="/"
                                        component="h3"
                                        className="cursor-pointer"
                                    >
                                        Admin
                                    </Link>
                                    <Typography
                                        key="3"
                                        component="h3"
                                        color="text.primary"
                                    >
                                        Insights
                                    </Typography>
                                </Breadcrumbs>
                            </Stack>
                        </Box>
                        <Button
                            className="btn primary-btn"
                            onClick={() => {
                                localStorage.setItem(
                                    "last-redirect-page",
                                    `/add-insight`
                                );
                                navigate("/add-insight");
                            }}
                        >
                            Add New
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    </div>
                    <Divider
                        className="mt-4"
                        style={{ background: "#EEEEEE" }}
                    />
                    <Box
                        sx={{
                            width: "100%",
                        }}
                    >
                        <Box
                            sx={{ borderBottom: 1, borderColor: "divider" }}
                            className="box-attribute"
                        >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                                variant="scrollable"
                                className={"tabs type-post-tab"}
                                TabIndicatorProps={{
                                    style: {
                                        backgroundColor: "#660C60",
                                    },
                                }}
                            >
                                <Tab
                                    label="All"
                                    iconPosition="start"
                                    {...a11yProps2(0)}
                                />
                                <Tab
                                    label="Active"
                                    iconPosition="start"
                                    {...a11yProps2(1)}
                                />
                                <Tab
                                    label="Inactive"
                                    {...a11yProps2(2)}
                                    iconPosition="start"
                                />

                                <Tab
                                    label="Deleted"
                                    {...a11yProps2(3)}
                                    iconPosition="start"
                                />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={value}>
                            <Paper
                                sx={{ width: "100%" }}
                                className="type-list-paper"
                            >
                                <TableContainer component={Paper} className="type-list-container">
                                    <Table
                                        sx={{ minWidth: 750 }}
                                        aria-labelledby="tableTitle"
                                        stickyHeader
                                    >
                                        {getAllInsights?.length > 0 && (
                                            <>
                                                <TableHead className='type-list-header'>
                                                    <TableRow>
                                                        <TableCell className='type-list-header-data cursor-pointer type-list-first-header'
                                                            onClick={
                                                                () => {
                                                                    handleSort('name');
                                                                }
                                                            }
                                                        >Name
                                                            {(sortTypeForAdmin?.name && sortTypeForAdmin?.name === 'asc') ? <ExpandLess className="sort-icon" /> : ((sortTypeForAdmin?.name && sortTypeForAdmin?.name === 'desc') ? <ExpandMore className="sort-icon" /> : <ExpandLess className="sort-icon text-muted opacity-50" />)}
                                                        </TableCell>
                                                        <TableCell className='type-list-header-data type-list-second-header-group' align="left">Type</TableCell>
                                                        <TableCell className='type-list-header-data type-list-second-header-group' align="left">Insights Generated</TableCell>
                                                        <TableCell className='type-list-header-data type-list-third-header' align="left">Creator</TableCell>
                                                        <TableCell className='type-list-header-data type-list-last-header-att-post' align="right">Status</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableRow style={{ height: '10px', backgroundColor: 'transparent' }} />
                                                <TableBody className='type-list-body'>
                                                    {getAllInsights?.map(
                                                        (row, index) => (
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                key={index + 1}
                                                                className='type-list-body-row'
                                                            >
                                                                <TableCell
                                                                    align="left"
                                                                    className={`tbl-body-cell tbl-border-left ${row?.status ===
                                                                        "deleted" &&
                                                                        "text-muted"
                                                                        }`}
                                                                >
                                                                    <div
                                                                        className="d-flex flex-warp gap-3"
                                                                        style={{
                                                                            fontSize:
                                                                                "14px",
                                                                        }}
                                                                    >
                                                                        <Typography className={`type-list-title ${row?.status ===
                                                                            "deleted" &&
                                                                            "text-muted fw-700"
                                                                            }`} variant="h4" component="div">
                                                                            {row?.name}
                                                                        </Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    className='type-list-text'
                                                                >
                                                                    {
                                                                        row?.insight_type
                                                                    }
                                                                </TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    className='type-list-text'
                                                                >
                                                                    {
                                                                        row?.insight_count
                                                                    }
                                                                </TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    className="tbl-body-cell"
                                                                >
                                                                    <Box className="type-list-text d-flex align-items-center type-list-createdby">
                                                                        <Avatar
                                                                            className="type-list-created-avatar"
                                                                            src={row?.created_by?.thumbnail}
                                                                            alt={row?.created_by?.name}
                                                                        >
                                                                            {row?.created_by?.name?.charAt(0)}
                                                                        </Avatar>
                                                                        <span>
                                                                            {
                                                                                row
                                                                                    ?.created_by
                                                                                    ?.name
                                                                            }
                                                                        </span>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell
                                                                    align="center"
                                                                    className="tbl-body-cell-2 tbl-border-right"
                                                                >
                                                                    <div>
                                                                        <Stack
                                                                            direction="row"
                                                                            alignContent="center"
                                                                            alignItems="center"
                                                                            spacing={
                                                                                1
                                                                            }
                                                                            className="gap-1"
                                                                        >
                                                                            <StatusIcon status={row?.status} />
                                                                            <IconButton
                                                                                aria-label="more"
                                                                                id="long-button"
                                                                                aria-controls={
                                                                                    openMenuFoEdit
                                                                                        ? "long-menu"
                                                                                        : undefined
                                                                                }
                                                                                aria-expanded={
                                                                                    openMenuFoEdit
                                                                                        ? "true"
                                                                                        : undefined
                                                                                }
                                                                                className="status-icon"
                                                                                aria-haspopup="true"
                                                                                onClick={(
                                                                                    e
                                                                                ) =>
                                                                                    handleClick(
                                                                                        e,
                                                                                        row
                                                                                    )
                                                                                }
                                                                            >
                                                                                <MoreVertIcon />
                                                                            </IconButton>
                                                                        </Stack>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    )}
                                                </TableBody>
                                            </>
                                        )}
                                    </Table>
                                </TableContainer>
                                {getAllInsights?.length > 0 && (
                                    <>
                                        <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
                                        <PaginationCustom
                                            per_page={pagination?.per_page}
                                            per_page_options={[10, 20, 50, 100]}
                                            from={pagination?.meta_data?.from}
                                            to={pagination?.meta_data?.to}
                                            last_page={pagination?.meta_data?.last_page}
                                            total={pagination?.meta_data?.total}
                                            page={pagination?.page}
                                            handleChangePage={handleChangePage}
                                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </>
                                )}
                            </Paper>
                            {getAllInsights?.length < 1 && (
                                <div className="no-data mt-5">
                                    <div>
                                        <Image
                                            src={NoDataFoundImg}
                                            className="text-center mx-auto no-data-img mt-4"
                                        />
                                    </div>
                                    <Typography component="h5" variant="h5">
                                        No data found
                                    </Typography>
                                </div>
                            )}
                        </CustomTabPanel>
                    </Box>
                </Container>

                <Dialog
                    aria-labelledby="customized-dialog-title"
                    open={openDeleteAlert}
                    maxWidth="sm"
                    fullWidth={true}
                >
                    <DialogTitle
                        sx={{ m: 0, p: 2 }}
                        id="customized-dialog-title"
                        className="dialog-title"
                    >
                        Are you sure?
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDeleteAlertModel}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        className="alert-close-btn"
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers className="">
                        {" "}
                        Are you sure want to delete insight?
                    </DialogContent>
                    <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
                        <Button
                            className="btn delete-btn-model"
                            onClick={handleCloseDeleteAlertModel}
                        >
                            <FontAwesomeIcon icon={faXmark} />
                            Cancel
                        </Button>
                        <Button
                            className="btn primary-btn"
                            onClick={() => deleteInsightAPI(insightId?.id)}
                        >
                            <FontAwesomeIcon icon={faFloppyDisk} />
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    aria-labelledby="customized-dialog-title"
                    open={openConfirmDeleteAlert}
                    maxWidth="sm"
                    fullWidth={true}
                >
                    <DialogTitle
                        sx={{ m: 0, p: 2 }}
                        id="customized-dialog-title"
                        className="dialog-title"
                    >
                        Are you sure?
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseConfirmDeleteAlertModel}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        className="alert-close-btn"
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers className="">
                        {" "}
                        Are you sure want to permanantly delete insight? Once you permanatly
                        delete you can not restore it again.
                    </DialogContent>
                    <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
                        <Button
                            className="btn delete-btn-model"
                            onClick={handleCloseConfirmDeleteAlertModel}
                        >
                            <FontAwesomeIcon icon={faXmark} />
                            Cancel
                        </Button>
                        <Button
                            className="btn primary-btn"
                            onClick={() => permanantlyDeleteInsightPI(insightId?.id)}
                        >
                            <FontAwesomeIcon icon={faFloppyDisk} />
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <Menu
                    anchorEl={anchorElMenu}
                    id="long-menu"
                    MenuListProps={{
                        "aria-labelledby": "long-button",
                    }}
                    open={openMenuFoEdit}
                    anchorOrigin={"right"}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "20ch",
                            zIndex: "1",
                        },
                    }}
                >
                    {insightId?.status !== "deleted" && (
                        <MenuItem
                            onClick={() => {
                                localStorage.setItem(
                                    "last-redirect-page",
                                    `/edit-insight/${insightId?.id}`
                                );

                                navigate(`/edit-insight/${insightId?.id}`);
                            }}
                        >
                            Edit
                        </MenuItem>
                    )}

                    {insightId?.status !== "enable" &&
                        insightId?.status !== "deleted" && (
                            <MenuItem
                                onClick={() =>
                                    changeInsightStatus(insightId?.id, "enable")
                                }
                            >
                                Active
                            </MenuItem>
                        )}
                    {insightId?.status !== "disable" &&
                        insightId?.status !== "deleted" && (
                            <MenuItem
                                onClick={() =>
                                    changeInsightStatus(insightId?.id, "disable")
                                }
                            >
                                Inactive
                            </MenuItem>
                        )}
                    {insightId?.status !== "deleted" && (
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                setOpenDeleteAlert(true);
                            }}
                        >
                            Delete
                        </MenuItem>
                    )}
                    {insightId?.status === "deleted" && (
                        <MenuItem
                            onClick={() => {
                                RestoreInsight(insightId?.id);
                            }}
                        >
                            Restore
                        </MenuItem>
                    )}
                    {insightId?.status === "deleted" && (
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                setOpenConfirmDeleteAlert(true);
                            }}
                        >
                            Permanantly Delete
                        </MenuItem>
                    )}
                </Menu>
                <Loader isLoading={loading} />
                <Toaster />
            </div>
        </>
    )
}

export default InsightList