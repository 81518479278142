import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Stack,
  Breadcrumbs,
  Link,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Avatar,
  TableBody,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Button,
  IconButton,
  Typography,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  TableHead
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faFloppyDisk,
  faPlus,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import api from "../../components/common/commonFunctionForApi";
import logoutHelper from "../../helpers/logoutHelper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Image } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "../../utils/toast";
import Loader from "../../components/common/loader";
import { Toaster } from "react-hot-toast";
import { userTabAction } from "../../redux/slices/users/users";
import StatusIcon from "../../components/common/StatusIcon";
import { dateWithTime } from "../../helpers/DateTimeFormat/dateTimeFormat";
import { exportXlsx } from "../../helpers/Exports/FileExport";
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import PaginationCustom from "../../components/common/pagination/paginationCustom";

function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box className="tab-box">{children}</Box>}
    </div>
  );
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selectedUserTab, pagination } = useSelector((state) => state?.userTab);

  const [isWarningMessage, setIsWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [value, setValue] = useState(selectedUserTab);
  const [loading, setLoading] = useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [openConfirmDeleteAlert, setOpenConfirmDeleteAlert] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [sortTypeForAdmin, setSortTypeForAdmin] = useState({
    name: null,
    updated_at: null,
    created_by: null,
  })

  const ITEM_HEIGHT = 48;

  const [categoryId, setCategoryId] = useState();

  const [anchorElMenu, setAnchorElForMenu] = useState(null);

  const maxVisibleGroups = 5;

  const openMenuFoEdit = Boolean(anchorElMenu);

  const userToken = localStorage.getItem("accessToken");

  //states for paginations
  const [page, setPage] = useState(1);
  const [isDataFetching, setIsDataFetching] = useState(false);

  const handleClick = (event, row) => {
    setAnchorElForMenu(event.currentTarget);
    setCategoryId(row);
  };

  const handleCloseWarningMessage = () => {
    setIsWarningMessage(false);
    setWarningMessage("");
  };

  const handleCloseSuccessMessage = () => {
    setIsSuccessMessage(false);
    setIsSuccessMessage("");
  };

  const handleClose = () => {
    setAnchorElForMenu(null);
  };

  const handleCloseDeleteAlertModel = () => {
    setOpenDeleteAlert(false);
  };

  const handleCloseConfirmDeleteAlertModel = () => {
    setOpenConfirmDeleteAlert(false);
  };

  const getStatusValue = (value) => {
    return value === 0 ? "all"
      : value === 1 ? "enable"
        : value === 2 ? "disable"
          : "deleted";
  };

  const handleChange = (event, newValue) => {
    dispatch(userTabAction.handleSelectedTab(newValue));
    setValue(newValue);
    setPage(1);
    setAllUsers([]);
    fetchAllUsers(getStatusValue(newValue), 1, pagination?.per_page);
  };

  const handleSort = (key) => {
    let sortTypeValue = null;
    if (sortTypeForAdmin[key] === null) {
      sortTypeValue = 'asc';
    } else if (sortTypeForAdmin[key] === 'asc') {
      sortTypeValue = 'desc';
    } else if (sortTypeForAdmin[key] === 'desc') {
      sortTypeValue = null;
    }
    const data = {
      name: null,
      updated_at: null,
      created_by: null,
      [key]: sortTypeValue
    };
    setSortTypeForAdmin(data)
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    dispatch(userTabAction.setPagination({
      ...pagination,
      page: newPage
    }))
    fetchAllUsers(getStatusValue(value), newPage, pagination?.per_page)
  };

  const handleChangeRowsPerPage = (event) => {

    dispatch(userTabAction.setPagination({
      ...pagination,
      per_page: event.target.value,
      page: 1
    }));
    fetchAllUsers(getStatusValue(value), 1, event.target.value)
  };

  const { open } = useSelector((state) => state?.drawerMenu);

  function a11yProps1(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value === index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }

  const fetchAllUsers = async (type, page, per_page) => {
    try {
      if (page === 1) {
        setLoading(true);
      }
      let order_by
      const userToken = localStorage.getItem("accessToken");
      const firstNonNull = Object.entries(sortTypeForAdmin).find(([key, value]) => value !== null);
      if (firstNonNull) {
        order_by = firstNonNull[0] + '_' + firstNonNull[1];
      }
      const response = await api.get(
        `admin/user/list/${type}?${order_by ? `order_by=${order_by}&` : ""}&page=${page ?? 1}&per_page=${per_page}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setAllUsers(response?.response?.data)
      dispatch(userTabAction.setPagination({
        ...pagination,
        page: response?.response?.meta?.current_page,
        per_page: response?.response?.meta?.per_page,
        meta_data: response?.response?.meta
      }));
      if (page + 1 <= response?.response?.meta.last_page) {
      }
    } catch (err) {
      console.log("error in fetch posts api::", err);
      if (err?.response?.status === 401) {
        localStorage.setItem("last-redirect-page", `/users`);
        logoutHelper(dispatch, navigate, userToken);
      }

      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
    } finally {
      setIsDataFetching(false); // Reset fetching state after the API call
      setLoading(false);
    }
  };

  const handleChangeLogCsvDownload = async (type, order) => {
    try {
      setLoading(true);

      const result = await exportXlsx(`admin/user/${type}${order ? `?order=${order}` : ""}&pagination=no`, 'users_listing');
      if (!result) {
        Toast.error("Failed to download file");
      }

    } catch (error) {
      console.error("Error while downloading csv changelog::", error);
      Toast.error("Failed to download file");
    } finally {
      setLoading(false);
    }
  }

  const changeUserStatus = async (id, status) => {
    try {
      let data = {
        status: status,
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/user/status `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        if (value === 0) {
          setAllUsers((prevUsers) =>
            prevUsers.map((user) =>
              user.id === id ? { ...user, status: status } : user
            )
          );
        } else {
          setAllUsers((prevUsers) =>
            prevUsers.filter((user) => user.id !== id)
          );
        }
        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
      } else if (response?.success === false) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const RestoreUser = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/user/restore `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        if (value === 0) {
          setAllUsers((prevPosts) =>
            prevPosts.map((post) =>
              post.id === id ? { ...post, status: response?.response?.status } : post
            )
          );
        } else {
          setAllUsers((prevUsers) =>
            prevUsers.filter((user) => user.id !== id)
          );
        }
        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
      } else if (response?.success === false) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        setLoading(false);
        handleClose();
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const deleteUserAPI = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/user/delete `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        if (value === 0) {
          setAllUsers((prevUsers) =>
            prevUsers.map((user) =>
              user.id === id ? { ...user, status: 'deleted' } : user
            )
          );
        } else {
          setAllUsers((prevUsers) =>
            prevUsers.filter((user) => user.id !== id)
          );
        }
        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        handleCloseDeleteAlertModel();
        setLoading(false);
        setAnchorElForMenu(null);
      } else if (response?.success === false) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        handleCloseDeleteAlertModel();

        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const permanantlyDeleteUserAPI = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/user/forcedelete `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        handleCloseConfirmDeleteAlertModel();
        setLoading(false);
        setAnchorElForMenu(null);

        setAllUsers([]);
        setPage(1);

        fetchAllUsers(
          getStatusValue(value),
          page, pagination?.per_page
        );
      } else if (response?.success === false) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        handleCloseConfirmDeleteAlertModel();

        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllUsers(
      getStatusValue(selectedUserTab),
      1, 10
    );
    setValue(selectedUserTab);
  }, []); //run only once after page load

  useEffect(() => {
    fetchAllUsers(getStatusValue(value), page, pagination?.per_page)
  }, [sortTypeForAdmin])


  const handleExportClick = async () => {
    await handleChangeLogCsvDownload(value === 0
      ? "all"
      : value === 1
        ? "enable"
        : value === 2
          ? "disable"
          : "deleted");
  };

  return (
    <>
      <div
        className={`main-content-wrapper-2 ${open === true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          {isSuccessMessage === true && (
            <SuccessAlert
              message={successMessage}
              onClose={handleCloseSuccessMessage}
            />
          )}

          {isWarningMessage === true && (
            <WarningAlert
              message={warningMessage}
              onClose={handleCloseWarningMessage}
            />
          )}
          <div className="main-div-2-types">
            <Box className="">
              <Stack spacing={2}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link
                    underline="hover"
                    key="1"
                    color="inherit"
                    href="/"
                    component="h3"
                    className="cursor-pointer"
                  >
                    Admin
                  </Link>
                  <Typography key="3" component="h3" color="text.primary">
                    Users
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Box>
            <div className="d-flex flex-wrap gap-3">

              <Button className="btn export-btn" variant="outlined" onClick={handleExportClick}>
                Export
                <FontAwesomeIcon icon={faDownload} />
              </Button>

              <Button
                className="btn primary-btn"
                onClick={() => {
                  localStorage.setItem("last-redirect-page", `/add/users`);

                  navigate("/add/users");
                }}
              >
                Add New
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          </div>
          <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="box-attribute"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                className={"tabs user-tab-list"}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#660C60",
                  },
                }}
              >
                <Tab
                  label="All"
                  iconPosition="start"
                  {...a11yProps1(0)}
                />

                <Tab
                  label="Active"
                  iconPosition="start"
                  {...a11yProps1(1)}
                />
                <Tab
                  label="Inactive"
                  {...a11yProps1(2)}
                  iconPosition="start"
                />
                <Tab
                  label="Deleted"
                  {...a11yProps1(3)}
                  iconPosition="start"
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={value}>
              <Paper sx={{ width: "100%" }} className="type-list-paper">
                <TableContainer component={Paper} className="type-list-container">
                  <Table
                    sx={{ minWidth: 700 }}
                    aria-labelledby="tableTitle"
                  >
                    {allUsers?.length > 0 && (
                      <>

                        <TableHead className='type-list-header'>
                          <TableRow>
                            <TableCell className='type-list-header-data cursor-pointer type-list-first-header'
                              onClick={
                                () => {
                                  handleSort('name');
                                }
                              }
                            >Name {(sortTypeForAdmin?.name && sortTypeForAdmin?.name === 'asc') ? <ExpandLess className="sort-icon" /> : ((sortTypeForAdmin?.name && sortTypeForAdmin?.name === 'desc') ? <ExpandMore className="sort-icon" /> : <ExpandLess className="sort-icon text-muted opacity-50" />)}</TableCell>
                            <TableCell className='type-list-header-data type-list-second-header-att-post' align="left">Groups</TableCell>
                            <TableCell className='type-list-header-data type-list-third-header' align="left">Date Created</TableCell>
                            <TableCell className='type-list-header-data type-list-last-header-att-post' align="right">Status</TableCell>
                          </TableRow>
                          <TableRow style={{ height: '10px', backgroundColor: 'transparent' }} />

                        </TableHead>
                        <TableBody className='type-list-body'>
                          {allUsers?.map((row, index) => (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index + 1}
                              className="cursor-pointer type-list-body-row"
                            >
                              <TableCell
                                align="left"
                                className={`${row?.status === "deleted" && "text-muted"
                                  }`}
                              >
                                <div
                                  className="d-flex flex-warp gap-3 align-items-center"
                                  style={{ fontSize: "14px" }}
                                >
                                  <div
                                    className={`icon-div-user-group d-flex justify-content-center align-items-center icons-div`}
                                    key={index}
                                    style={{ backgroundColor: row?.background }}
                                  >
                                    {
                                      row?.image ? <Avatar
                                        alt={row?.name}
                                        src={row?.image}
                                        className="author-avtar-creator"
                                      /> : <Avatar
                                        alt={row?.name}
                                        src="/static/images/avatar/1.jpg"
                                        className="author-avtar-creator"
                                      />
                                    }

                                  </div>
                                  <Typography className="type-list-title" variant="h4" component="div">
                                    {row?.name}
                                  </Typography>
                                  {row?.account_type_id === 1 && (
                                    <Chip
                                      label="System User"
                                      className="chip-label-div"
                                    />
                                  )}
                                </div>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={` ${row?.status === "deleted" && "text-muted"
                                  }`}
                              >
                                <div
                                  className="d-flex flex-warp gap-1 align-items-center"
                                  style={{ fontSize: "14px" }}
                                >
                                  {row?.groups
                                    ?.slice(0, maxVisibleGroups)
                                    .map((group, index) => (
                                      <div
                                        className={`icon-div-user-group d-flex justify-content-center align-items-center icons-div`}
                                        key={index}
                                        style={{
                                          backgroundColor: group?.background,
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={group?.icon}
                                          className="icon-user-group"
                                          style={{ color: group?.colour }}
                                        />
                                      </div>
                                    ))}
                                  {row?.groups?.length > maxVisibleGroups && (
                                    <>
                                      <HtmlTooltip
                                        title={
                                          <React.Fragment>
                                            <List>
                                              {row?.groups?.map(
                                                (manager, index) => (
                                                  <>
                                                    <ListItem>
                                                      <ListItemIcon>
                                                        <div
                                                          className={`icon-div-user-group d-flex justify-content-center align-items-center icons-div`}
                                                          key={index}
                                                          style={{
                                                            backgroundColor:
                                                              manager?.background,
                                                          }}
                                                        >
                                                          <FontAwesomeIcon
                                                            icon={manager?.icon}
                                                            className="icon-user-group"
                                                            style={{
                                                              color:
                                                                manager?.colour,
                                                            }}
                                                          />
                                                        </div>
                                                      </ListItemIcon>
                                                      <ListItemText
                                                        primary={manager?.name}
                                                      />
                                                    </ListItem>
                                                    {index !==
                                                      row?.groups?.length -
                                                      1 && (
                                                        <Divider component="li" />
                                                      )}{" "}
                                                    {/* Conditionally render the divider */}
                                                  </>
                                                )
                                              )}
                                            </List>
                                          </React.Fragment>
                                        }
                                        placement="left-start"
                                      >
                                        <Chip
                                          label={`+${row.groups.length - maxVisibleGroups
                                            } others`}
                                          variant="outlined"
                                          size="small"
                                          className="chip-label-div"
                                        />
                                      </HtmlTooltip>
                                    </>
                                  )}
                                </div>
                              </TableCell>
                              <TableCell
                                align="left"
                                className="type-list-text"
                              >
                                {dateWithTime(row?.created_at, true, false, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                className="tbl-body-cell-2 tbl-border-right"
                              >
                                <div>
                                  <Stack
                                    direction="row"
                                    alignContent="center"
                                    alignItems="center"
                                    spacing={1}
                                    className="gap-1"
                                  >
                                    <StatusIcon status={row?.is_archived === 1 ? 'archived' : row?.status} />
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-haspopup="true"
                                      className="status-icon"

                                      onClick={(e) => handleClick(e, row)}
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </Stack>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </>
                    )}
                  </Table>
                </TableContainer>
                {allUsers?.length > 0 && (
                  <>
                    <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
                    <PaginationCustom
                      per_page={pagination?.per_page}
                      per_page_options={[10, 20, 50, 100]}
                      from={pagination?.meta_data?.from}
                      to={pagination?.meta_data?.to}
                      last_page={pagination?.meta_data?.last_page}
                      total={pagination?.meta_data?.total}
                      page={pagination?.page}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </>
                )}

                {isDataFetching && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 2
                    }}
                  >
                    <CircularProgress sx={{ color: "#660C60" }} />
                  </Box>
                )}
              </Paper>
              {allUsers?.length < 1 && (
                <div className="no-data mt-5">
                  <div>
                    <Image
                      src={NoDataFoundImg}
                      className="text-center mx-auto no-data-img mt-4"
                    />
                  </div>
                  <Typography component="h5" variant="h5">
                    No data found
                  </Typography>
                </div>
              )}
            </CustomTabPanel>
          </Box>
        </Container>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openDeleteAlert}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            Are you sure?
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDeleteAlertModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="">
            {" "}
            Are you sure want to delete user?
          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
            <Button
              className="btn delete-btn-model"
              onClick={handleCloseDeleteAlertModel}
            >
              <FontAwesomeIcon icon={faXmark} />
              Cancel
            </Button>
            <Button
              className="btn primary-btn"
              onClick={() => deleteUserAPI(categoryId?.id)}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openConfirmDeleteAlert}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            Are you sure?
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseConfirmDeleteAlertModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="">
            {" "}
            Are you sure want to permanantly delete user? Once you permanatly
            delete you can not restore it again.
          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
            <Button
              className="btn delete-btn-model"
              onClick={handleCloseConfirmDeleteAlertModel}
            >
              <FontAwesomeIcon icon={faXmark} />
              Cancel
            </Button>
            <Button
              className="btn primary-btn"
              onClick={() => permanantlyDeleteUserAPI(categoryId?.id)}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Menu
          anchorEl={anchorElMenu}
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          open={openMenuFoEdit}
          anchorOrigin={"right"}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
              zIndex: "1",
            },
          }}
        >
          {categoryId?.status !== "deleted" && (
            <MenuItem
              onClick={() => {
                localStorage.setItem(
                  "last-redirect-page",
                  `/user/edit/${categoryId?.id}`
                );
                navigate(`/user/edit/${categoryId?.id}`);
              }}
            >
              Edit
            </MenuItem>
          )}

          {categoryId?.status !== "enable" &&
            categoryId?.status !== "deleted" && (
              <MenuItem
                onClick={() => {
                  changeUserStatus(categoryId?.id, "enable");
                }}
              >
                Active
              </MenuItem>
            )}
          {categoryId?.status !== "disable" &&
            categoryId?.status !== "deleted" && (
              <MenuItem
                onClick={() => {
                  changeUserStatus(categoryId?.id, "disable");
                }}
              >
                Inactive
              </MenuItem>
            )}
          {categoryId?.status !== "deleted" && (
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenDeleteAlert(true);
              }}
            >
              Delete
            </MenuItem>
          )}
          {categoryId?.status === "deleted" && (
            <MenuItem
              onClick={() => {
                RestoreUser(categoryId?.id);
              }}
            >
              Restore
            </MenuItem>
          )}
          {categoryId?.status === "deleted" && (
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenConfirmDeleteAlert(true);
              }}
            >
              Permanantly Delete
            </MenuItem>
          )}
        </Menu>
      </div>
      <Loader isLoading={loading} />
      <Toaster />
    </>
  );
};

export default Users;
