import React from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const DateComponent = ({ attdata, handleChangeForFormData, handleChangeForChildAtts, isChild, parentAttData, handleChangeForAdditionalTextField, isMulti }) => {
    return (
        <>
            <LocalizationProvider
                dateAdapter={
                    AdapterDayjs
                }
            >
                <DemoContainer
                    components={[
                        "DatePicker",
                        "DatePicker",
                    ]}
                    className="p-0 date"
                >
                    <DatePicker
                        name={
                            attdata?.nameValue
                        }
                        value={dayjs(
                            attdata?.value
                        )}
                        className="w-100 pt-0 time-field"
                        onChange={(
                            newValue
                        ) => {
                            if (isChild === true) {
                                handleChangeForChildAtts(
                                    attdata?.nameValue,
                                    newValue,
                                    attdata?.section_id,
                                    attdata,
                                    parentAttData
                                );
                            }
                            else if (isMulti === true) {
                                handleChangeForAdditionalTextField(
                                    attdata?.id,
                                    newValue,
                                    parentAttData?.nameValue,
                                    parentAttData?.section_id,
                                    attdata?.fieldtype
                                )
                            }
                            else {
                                handleChangeForFormData(
                                    attdata?.nameValue,
                                    newValue,
                                    attdata?.section_id
                                );
                            }

                        }}
                        views={[
                            "year",
                            "month",
                            "day",
                        ]}
                        format="DD/MM/YYYY"
                        slotProps={{
                            field: {
                                clearable: true,
                            },
                        }}
                    />
                </DemoContainer>
            </LocalizationProvider>
        </>
    )
}

export default DateComponent