import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import {
  Box,
  Stack,
  Breadcrumbs,
  Link,
  Typography,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Avatar,
  TableBody,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Chip,
  IconButton,
  AvatarGroup,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  CircularProgress,
  TableHead,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Button, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/common/loader";
import { Toaster } from "react-hot-toast";
import "../../styles/categories.scss";
import {
  faXmark,
  faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";
import { groupTabAction } from "../../redux/slices/groups/groups";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "../../styles/groups.scss";
import api from "../../components/common/commonFunctionForApi";
import logoutHelper from "../../helpers/logoutHelper";
import { Toast } from "../../utils/toast";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { BootstrapTooltip } from "../../components/common/tooltip/bootstrapTooltip";
import StatusIcon from "../../components/common/StatusIcon";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import PaginationCustom from "../../components/common/pagination/paginationCustom";

function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box className="tab-box">{children}</Box>}
    </div>
  );
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const Groups = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selectedGroupTab, pagination } = useSelector((state) => state?.groupTab);

  const ITEM_HEIGHT = 48;

  const [isWarningMessage, setIsWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [value, setValue] = useState(selectedGroupTab);
  const [dataOrder, setDataOrder] = useState("asc");
  const [loading, setLoading] = useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [openConfirmDeleteAlert, setOpenConfirmDeleteAlert] = useState(false);

  //states for paginations
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [isDataFetching, setIsDataFetching] = useState(false);

  const [sortTypeForAdmin, setSortTypeForAdmin] = useState({
    name: null,
    updated_at: null,
    created_by: null,
  })

  const [allUserGroups, setallUserGroups] = useState([]);
  const [categoryId, setCategoryId] = useState();

  const [anchorElMenu, setAnchorElForMenu] = useState(null);

  const openMenuFoEdit = Boolean(anchorElMenu);

  const user = localStorage.getItem("user");
  const userToken = localStorage.getItem("accessToken");

  const handleCloseWarningMessage = () => {
    setIsWarningMessage(false);
    setWarningMessage("");
  };

  const handleCloseSuccessMessage = () => {
    setIsSuccessMessage(false);
    setIsSuccessMessage("");
  };

  const handleClick = (event, row) => {
    setAnchorElForMenu(event.currentTarget);
    setCategoryId(row);
  };

  const getStatusValue = (value) => {
    return value === 0 ? "all"
      : value === 1 ? "enable"
        : value === 2 ? "disable"
          : "deleted";
  };

  const handleChangeDataOrdr = () => {
    let order = dataOrder === "asc" ? "desc" : "asc";
    setDataOrder(order);

    setPage(1);
    setallUserGroups([]);

    fetchAllGroups(
      getStatusValue(value),
      order,
      1
    );
  };

  const handleClose = () => {
    setAnchorElForMenu(null);
  };

  const handleCloseDeleteAlertModel = () => {
    setOpenDeleteAlert(false);
  };

  const handleCloseConfirmDeleteAlertModel = () => {
    setOpenConfirmDeleteAlert(false);
  };

  const handleChange = (event, newValue) => {
    dispatch(groupTabAction.handleSelectedTab(newValue));
    setValue(newValue);
    setPage(1);
    setallUserGroups([]);
    fetchAllGroups(getStatusValue(newValue), dataOrder, 1, pagination?.per_page);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    dispatch(groupTabAction.setPagination({
      ...pagination,
      page: newPage
    }))

    fetchAllGroups(getStatusValue(value), dataOrder, newPage, pagination?.per_page)
  };

  const handleChangeRowsPerPage = (event) => {

    dispatch(groupTabAction.setPagination({
      ...pagination,
      per_page: event.target.value,
      page: 1
    }));
    fetchAllGroups(getStatusValue(value), dataOrder, 1, event.target.value)
  };

  const { open } = useSelector((state) => state?.drawerMenu);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }

  const fetchAllGroups = async (type, order, page, per_page) => {
    try {
      let order_by
      const userToken = localStorage.getItem("accessToken");
      const firstNonNull = Object.entries(sortTypeForAdmin).find(([key, value]) => value !== null);
      if (firstNonNull) {
        order_by = firstNonNull[0] + '_' + firstNonNull[1];
      }
      const response = await api.get(
        `admin/group/${type}?${order_by ? `order_by=${order_by}` : ""}&page=${page ?? 1
        }&per_page=${per_page}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const newGroups = response?.response?.data;
      setallUserGroups(newGroups)
      dispatch(groupTabAction.setPagination({
        ...pagination,
        page: response?.response?.meta?.current_page,
        per_page: response?.response?.meta?.per_page,
        meta_data: response?.response?.meta
      }));

    } catch (err) {
      console.log("error in fetch posts api::", err);
      if (err?.response?.status === 401) {
        localStorage.setItem("last-redirect-page", `/groups`);
        logoutHelper(dispatch, navigate, userToken);
      }

      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.");
      }
    } finally {
      setIsDataFetching(false); // Reset fetching state after the API call
      setLoading(false);
    }
  };

  const handleScroll = async () => {
    const scrollableHeight = document.documentElement.scrollHeight;
    const scrollPosition = window.innerHeight + window.scrollY;
    const remainingScrollThreshold = scrollableHeight * 0.3;

    if (
      scrollableHeight - scrollPosition < remainingScrollThreshold &&
      !isDataFetching &&
      page < totalPages
    ) {
      setIsDataFetching(true);
      const nextPageNum = page + 1;
      setPage(nextPageNum);
      await fetchAllGroups(
        getStatusValue(value),
        dataOrder,
        nextPageNum,
        pagination?.per_page
      );
    }
  };

  const handleSort = (key) => {
    let sortTypeValue = null;
    if (sortTypeForAdmin[key] === null) {
      sortTypeValue = 'asc';
    } else if (sortTypeForAdmin[key] === 'asc') {
      sortTypeValue = 'desc';
    } else if (sortTypeForAdmin[key] === 'desc') {
      sortTypeValue = null;
    }
    const data = {
      name: null,
      updated_at: null,
      created_by: null,
      [key]: sortTypeValue
    };
    setSortTypeForAdmin(data)
  }

  const changeGroupStatus = async (id, status) => {
    try {
      let data = {
        status: status,
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/group/status `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        if (value === 0) {
          setallUserGroups((prevGroups) =>
            prevGroups.map((group) =>
              group.id === id ? { ...group, status: status } : group
            )
          );
        } else {
          setallUserGroups((prevGroups) =>
            prevGroups.filter((group) => group.id !== id)
          );
        }
        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
      } else if (response?.success === false) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.");
      }
      setLoading(false);
    }
  };

  const RestoreGroup = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/group/restore `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        if (value === 0) {
          setallUserGroups((prevGroups) =>
            prevGroups.map((group) =>
              group.id === id
                ? { ...group, status: response?.response?.status }
                : group
            )
          );
        } else {
          setallUserGroups((prevGroups) =>
            prevGroups.filter((group) => group.id !== id)
          );
        }
        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
      } else if (response?.success === false) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        setLoading(false);
        handleClose();
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.");
      }
      setLoading(false);
    }
  };

  const deleteGroupAPI = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/group/delete `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        if (value === 0) {
          setallUserGroups((prevGroups) =>
            prevGroups.map((group) =>
              group.id === id ? { ...group, status: "deleted" } : group
            )
          );
        } else {
          setallUserGroups((prevGroups) =>
            prevGroups.filter((group) => group.id !== id)
          );
        }
        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        handleCloseDeleteAlertModel();
        setLoading(false);
        setAnchorElForMenu(null);
      } else if (response?.success === false) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        handleCloseDeleteAlertModel();

        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.");
      }
      setLoading(false);
    }
  };

  const permanantlyDeleteGroupAPI = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/group/forcedelete `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        handleCloseConfirmDeleteAlertModel();
        setLoading(false);
        setAnchorElForMenu(null);

        setallUserGroups([]);
        setPage(1);

        fetchAllGroups(
          getStatusValue(value),
          dataOrder,
          page,
          pagination?.per_page
        );
      } else if (!response?.success) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        handleCloseConfirmDeleteAlertModel();

        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllGroups(
      getStatusValue(selectedGroupTab),
      dataOrder,
      1,
      10
    );

    setValue(selectedGroupTab);
  }, []); //run only once after page load

  useEffect(() => {
    fetchAllGroups(getStatusValue(value), dataOrder, page, pagination?.per_page)
  }, [sortTypeForAdmin])

  return (
    <>
      <div
        className={`main-content-wrapper-2 ${open === true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          {isSuccessMessage && (
            <SuccessAlert
              message={successMessage}
              onClose={handleCloseSuccessMessage}
            />
          )}

          {isWarningMessage && (
            <WarningAlert
              message={warningMessage}
              onClose={handleCloseWarningMessage}
            />
          )}
          <div className="main-div-2-types">
            <Box className="">
              <Stack spacing={2}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link
                    underline="hover"
                    key="1"
                    color="inherit"
                    href="/"
                    component="h3"
                    className="cursor-pointer"
                  >
                    Admin
                  </Link>
                  <Typography key="3" component="h3" color="text.primary">
                    User Groups
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Box>
            <Button
              className="btn primary-btn"
              onClick={() => {
                localStorage.setItem("last-redirect-page", `/add/groups`);

                navigate("/add/groups");
              }}
            >
              Add New
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
          <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="box-attribute"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                className={"tabs type-post-tab"}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#660C60",
                  },
                }}
              >
                <Tab label="All" iconPosition="start" {...a11yProps(0)} />
                <Tab label="Active" iconPosition="start" {...a11yProps(1)} />
                <Tab label="Inactive" {...a11yProps(2)} iconPosition="start" />
                <Tab label="Deleted" {...a11yProps(3)} iconPosition="start" />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={value}>
              <Paper sx={{ width: "100%" }} className="type-list-paper">
                <TableContainer component={Paper} className="type-list-container">
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                  >
                    {allUserGroups?.length > 0 && (
                      <>
                        <TableHead className='type-list-header'>
                          <TableRow>
                            <TableCell className='type-list-header-data cursor-pointer type-list-first-header-group-name'
                              onClick={
                                () => {
                                  handleSort('name');
                                }
                              }
                            >Name
                              {(sortTypeForAdmin?.name && sortTypeForAdmin?.name === 'asc') ? <ExpandLess className="sort-icon" /> : ((sortTypeForAdmin?.name && sortTypeForAdmin?.name === 'desc') ? <ExpandMore className="sort-icon" /> : <ExpandLess className="sort-icon text-muted opacity-50" />)}
                            </TableCell>
                            <TableCell className='type-list-header-data type-list-second-header-group' align="left">Members</TableCell>
                            <TableCell className='type-list-header-data type-list-second-header-group' align="left">Date Created</TableCell>
                            <TableCell className='type-list-header-data type-list-second-header-group' align="left">Type</TableCell>
                            <TableCell className='type-list-header-data type-list-third-header' align="left">Manager</TableCell>
                            <TableCell className='type-list-header-data type-list-third-header' align="left">Creator</TableCell>
                            <TableCell className='type-list-header-data type-list-last-header-att-post' align="right">Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableRow style={{ height: '10px', backgroundColor: 'transparent' }} />

                        <TableBody className='type-list-body'>
                          {allUserGroups?.map((row, index) => (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index + 1}
                              className='type-list-body-row'
                            >
                              <TableCell
                                align="left"
                                className={`tbl-body-cell tbl-border-left ${row?.status === "deleted" && "text-muted"
                                  }`}
                              >
                                <div
                                  className="d-flex flex-warp gap-3 align-items-center"
                                  style={{ fontSize: "14px" }}
                                >
                                  <div
                                    className={`icon-div-user-group d-flex justify-content-center align-items-center icons-div`}
                                    key={index}
                                    style={{ backgroundColor: row?.background }}
                                  >
                                    <FontAwesomeIcon
                                      icon={row?.icon}
                                      className="icon-user-group"
                                      style={{ color: row?.colour }}
                                    />
                                  </div>
                                  <p
                                    className={`${row?.status === "deleted" &&
                                      "text-muted fw-700"
                                      }`}
                                  >
                                    {row?.title}
                                  </p>
                                  {row?.groupType === "system Group" && (
                                    <Chip
                                      label="System Group"
                                      className="chip-label-div"
                                    />
                                  )}
                                </div>
                              </TableCell>
                              <TableCell
                                align="left"
                                className="tbl-body-cell-3 "
                              >
                                {row?.members}
                              </TableCell>
                              <TableCell
                                align="left"
                                className="tbl-body-cell-3 body-text-1"
                              >
                                {row?.created_at}
                              </TableCell>
                              <TableCell
                                align="left"
                                className="tbl-body-cell-3 text-capitalize"
                              >
                                {row?.user_type}
                              </TableCell>
                              <TableCell
                                align="center"
                                className="tbl-body-cell"
                              >
                                {row?.manager?.length > 0 ? (
                                  <HtmlTooltip
                                    title={
                                      <React.Fragment>
                                        <List>
                                          {row?.manager?.map(
                                            (manager, index) => (
                                              <>
                                                <ListItem>
                                                  <ListItemAvatar>
                                                    <Avatar
                                                      alt={manager?.user}
                                                      src="/static/images/avatar/1.jpg"
                                                      className="author-avtar-creator"
                                                    />
                                                  </ListItemAvatar>
                                                  <ListItemText
                                                    primary={manager?.user}
                                                  />
                                                </ListItem>
                                                {index !==
                                                  row?.manager?.length - 1 && (
                                                    <Divider component="li" />
                                                  )}{" "}
                                                {/* Conditionally render the divider */}
                                              </>
                                            )
                                          )}
                                        </List>
                                      </React.Fragment>
                                    }
                                    placement="left-start"
                                  >
                                    <AvatarGroup max={3} className="managers">
                                      {row?.manager?.map((manager, index) => (
                                        <Avatar
                                          className="type-list-created-avatar"
                                          src={manager?.user}
                                          alt={manager?.user}
                                        >
                                          {manager?.user?.charAt(0)}
                                        </Avatar>
                                      ))}
                                    </AvatarGroup>
                                  </HtmlTooltip>
                                ) : (
                                  <Typography className="body-text-1">
                                    Manager not assigned yet.
                                  </Typography>
                                )}
                              </TableCell>
                              <TableCell
                                align="center"
                                className="tbl-body-cell-creator text-center"
                              >
                                <BootstrapTooltip
                                  title={row?.created_by}
                                  placement="left-start"
                                >
                                  <Box className='type-list-text d-flex align-items-center type-list-createdby'>
                                    <Avatar
                                      className="type-list-created-avatar"
                                      src={row?.created_by}
                                      alt={row?.created_by}
                                    >
                                      {row?.created_by?.charAt(0)}
                                    </Avatar>
                                  </Box>
                                </BootstrapTooltip>
                              </TableCell>
                              <TableCell
                                align="center"
                                className="tbl-body-cell-2 tbl-border-right"
                              >
                                <div>
                                  <Stack
                                    direction="row"
                                    alignContent="center"
                                    alignItems="center"
                                    spacing={1}
                                    className="gap-1"
                                  >
                                    <StatusIcon status={row?.status} />
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-haspopup="true"
                                      onClick={(e) => handleClick(e, row)}
                                      className="status-icon"
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </Stack>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </>
                    )}
                  </Table>
                </TableContainer>
                {allUserGroups?.length > 0 && (
                  <>
                    <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
                    <PaginationCustom
                      per_page={pagination?.per_page}
                      per_page_options={[10, 20, 50, 100]}
                      from={pagination?.meta_data?.from}
                      to={pagination?.meta_data?.to}
                      last_page={pagination?.meta_data?.last_page}
                      total={pagination?.meta_data?.total}
                      page={pagination?.page}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </>
                )}
                {isDataFetching && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 2,
                    }}
                  >
                    <CircularProgress sx={{ color: "#660C60" }} />
                  </Box>
                )}
              </Paper>
              {allUserGroups?.length < 1 && (
                <div className="no-data mt-5">
                  <div>
                    <Image
                      src={NoDataFoundImg}
                      className="text-center mx-auto no-data-img mt-4"
                    />
                  </div>
                  <Typography component="h5" variant="h5">
                    No data found
                  </Typography>
                </div>
              )}
            </CustomTabPanel>
          </Box>
        </Container>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openDeleteAlert}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            Are you sure?
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDeleteAlertModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="">
            Are you sure want to delete group?
          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
            <Button
              className="btn delete-btn-model"
              onClick={handleCloseDeleteAlertModel}
            >
              <FontAwesomeIcon icon={faXmark} />
              Cancel
            </Button>
            <Button
              className="btn primary-btn"
              onClick={() => deleteGroupAPI(categoryId?.id)}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openConfirmDeleteAlert}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            Are you sure?
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseConfirmDeleteAlertModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="">
            {" "}
            Are you sure want to permanently delete group? Once you permanently
            delete you can not restore it again.
          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
            <Button
              className="btn delete-btn-model"
              onClick={handleCloseConfirmDeleteAlertModel}
            >
              <FontAwesomeIcon icon={faXmark} />
              Cancel
            </Button>
            <Button
              className="btn primary-btn"
              onClick={() => permanantlyDeleteGroupAPI(categoryId?.id)}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Menu
          anchorEl={anchorElMenu}
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          open={openMenuFoEdit}
          anchorOrigin={"right"}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
              zIndex: "1",
            },
          }}
        >
          {categoryId?.status !== "deleted" && (
            <MenuItem
              onClick={() => {
                localStorage.setItem(
                  "last-redirect-page",
                  `/group/edit//${categoryId?.id}`
                );

                navigate(`/group/edit/${categoryId?.id}`);
              }}
            >
              Edit
            </MenuItem>
          )}

          {categoryId?.status !== "enable" &&
            categoryId?.status !== "deleted" && (
              <MenuItem
                onClick={() => {
                  changeGroupStatus(categoryId?.id, "enable");
                }}
              >
                Active
              </MenuItem>
            )}
          {categoryId?.status !== "disable" &&
            categoryId?.status !== "deleted" && (
              <MenuItem
                onClick={() => {
                  changeGroupStatus(categoryId?.id, "disable");
                }}
              >
                Inactive
              </MenuItem>
            )}
          {categoryId?.status !== "deleted" && (
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenDeleteAlert(true);
              }}
            >
              Delete
            </MenuItem>
          )}
          {categoryId?.status === "deleted" && (
            <MenuItem
              onClick={() => {
                RestoreGroup(categoryId?.id);
              }}
            >
              Restore
            </MenuItem>
          )}
          {categoryId?.status === "deleted" && (
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenConfirmDeleteAlert(true);
              }}
            >
              Permanantly Delete
            </MenuItem>
          )}
        </Menu>
      </div>
      <Loader isLoading={loading} />
      <Toaster />
    </>
  );
};

export default Groups;

