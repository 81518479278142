import React, { useState, useEffect, useRef } from "react";
import { Image as BootstrapImage, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faFolder,
  faFile,
  faMessage,
  faArrowRight
} from "@fortawesome/free-solid-svg-icons";
import logoutHelper from "../../../helpers/logoutHelper";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import api from "../../common/commonFunctionForApi";
import {
  Box, Divider, Tabs,
  Tab,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Popper,
  Typography,
  TableHead
} from "@mui/material";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Avatar from "@mui/material/Avatar";
import { Toast } from "../../../utils/toast";

import ClickAwayListener from '@mui/material/ClickAwayListener';
import NoDataFoundImg from "../../../../src/assets/images/NoDataFound.png"
import Loader from "../loader";
import { ExpandLess, ExpandMore } from "@mui/icons-material";


function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box className="tab-box-4">{children}</Box>}
    </div>
  );
}

function GlobalSearch() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [globalSearch, setGlobalSearch] = useState("")
  const [globalSearchData, setGlobalSearchData] = useState()
  const [globalSearchDataTabs, setGlobalSearchDataTabs] = useState([])
  const [globalSearchFilterData, setGlobalSearchFilterData] = useState([])
  const [totalSearchCount, SetTotalSearchCount] = useState(0)
  const [value, setValue] = useState("all");
  const [dataOrder, setDataOrder] = useState("asc");

  const [openForGlobalSearch, setOpenforGobalSearch] = useState(false)
  const [anchorElForGlobalSearch, setanchorElForGlobalSearch] = useState(null)

  const [sortTypeForAdmin, setSortTypeForAdmin] = useState({
    name: null,
    updated_at: null,
    created_by: null,
  })

  const openMenuforGlobalSearch = Boolean(anchorElForGlobalSearch)
  const idForGlobalSearch = openMenuforGlobalSearch ? "simple-popover" : undefined

  const debounceTimeout = 500;
  let debounceTimer;

  const descriptionElementRef = useRef(null);

  const userToken = localStorage.getItem("accessToken");

  const handleCloseForGlobalSearch = () => {
    setOpenforGobalSearch(false)
    setanchorElForGlobalSearch(null)
    setValue("all")
    setGlobalSearch("")
    setGlobalSearchData()
    setGlobalSearchDataTabs([])
    setGlobalSearchFilterData([])
  }

  const handleChangeDataOrdr = () => {
    let order = dataOrder === "asc" ? "desc" : "asc";
    setDataOrder(order);
    fetchGlobalSearchData(value, true, order);
  };

  const handleChangeForGlobalSearchTab = (newValue) => {
    setValue(newValue);
    fetchGlobalSearchData(newValue, false, dataOrder);
  }

  const handleSort = (key) => {
    let sortTypeValue = null;
    if (sortTypeForAdmin[key] === null) {
      sortTypeValue = 'asc';
    } else if (sortTypeForAdmin[key] === 'asc') {
      sortTypeValue = 'desc';
    } else if (sortTypeForAdmin[key] === 'desc') {
      sortTypeValue = null;
    }
    const data = {
      name: null,
      updated_at: null,
      created_by: null,
      [key]: sortTypeValue
    };
    setSortTypeForAdmin(data)
  }

  const fetchGlobalSearchData = async (type, loading, order) => {
    let data = {
      search: globalSearch,
      type_id: [type],
      attribute_data: []
    };
    const firstNonNull = Object.entries(sortTypeForAdmin).find(([key, value]) => value !== null);
    if (firstNonNull) {
      data.order_by = firstNonNull[0] + '_' + firstNonNull[1];
    }
    try {
      setLoading(loading)
      const response = await api.post(`global-search`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {

        const totalCount = response?.response?.tabs?.reduce((acc, tab) => acc + (tab.count || 0), 0);
        setGlobalSearchData(response?.response)
        setGlobalSearchDataTabs(response?.response?.tabs)
        setGlobalSearchFilterData(response?.response?.data)
        SetTotalSearchCount(totalCount)
        setLoading(false)
        setOpenforGobalSearch(true)
      } else {
        setLoading(false)
      }
    } catch (err) {
      console.log("error in handleReactionAPI::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      } else if (err?.response?.status === 422) {
        handleCloseForGlobalSearch();
        Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
    }
  }

  function a11yProps1(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className: value === index ? `selected-tab-attribute` : `non-selected-tab-attribute`,
    };
  }

  useEffect(() => {
    if (globalSearch !== "") {
      if (globalSearch.length >= 3) {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
          fetchGlobalSearchData(value, true, dataOrder);
        }, debounceTimeout);
      }
      else {
        clearTimeout(debounceTimer);
      }

      return () => clearTimeout(debounceTimer);
    }
  }, [globalSearch]);

  useEffect(() => {
    if (openForGlobalSearch) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openForGlobalSearch]);

  useEffect(() => {
    if (sortTypeForAdmin?.name !== null) {
      fetchGlobalSearchData(value, true, dataOrder)
    }
  }, [sortTypeForAdmin])

  return (
    <>
      <ClickAwayListener onClickAway={handleCloseForGlobalSearch}>
        <Box className="serach-box">
          <InputGroup className="">
            <Form.Control
              placeholder="Search Platform..."
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              className="search-input"
              value={globalSearch}
              onChange={(e) => {
                setGlobalSearch(e.target.value)
                setanchorElForGlobalSearch(e.currentTarget)
              }}
            />
            <Button className="search-btn-global">
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup>
          <Popper
            id={idForGlobalSearch}
            open={openForGlobalSearch}
            anchorEl={anchorElForGlobalSearch}
            placement="bottom-start"
            className="global-search-popover bg-white"
          >
            <Box
              sx={{
                width: "100%",
              }}
              className="global-search-popover-box"
            >
              <Box className="search-top-box">
                <Typography className="search-result-text-1">Search Results</Typography>
                <Box className="search-top-box-2 cursor-pointer"
                  onClick={() => {
                    handleCloseForGlobalSearch()
                    navigate("/search-result", { state: { searchString: globalSearch, type: value } });
                  }}>
                  <Typography className="search-result-text-2">See All Search Results</Typography>
                  <FontAwesomeIcon icon={faArrowRight} className="search-result-text-2" />
                </Box>

              </Box>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className="box-attribute global-search-tab-margin"
              >
                <Tabs
                  value={value}
                  onChange={(event, newValue) => {
                    handleChangeForGlobalSearchTab(newValue)
                  }}
                  aria-label="basic tabs example"
                  variant={"scrollable"}
                  className={"tabs type-post-tab"}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#660C60",
                    },
                  }}
                  scrollButtons="auto"
                >
                  {globalSearchDataTabs?.map((tab, index) => (
                    <Tab
                      label={
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                          {tab?.name}
                          <span style={{
                            borderRadius: "99px",
                            padding: "4px 3px",
                            fontSize: "10px",
                            fontWeight: "700",
                          }}
                            className="tab-count-data"
                          >
                            {tab?.count || 0}
                          </span>
                        </div>
                      }
                      iconPosition="start"
                      value={tab?.id}
                      {...a11yProps1(tab?.id)}
                    />
                  ))}

                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={value}>
                <Paper sx={{ width: "100%" }} className="type-list-paper">
                  <TableContainer component={Paper} className="type-list-container">
                    <Table
                      aria-labelledby="tableTitle"
                      stickyHeader
                    >
                      {globalSearchFilterData?.length > 0 ? (
                        <>
                          <TableHead className='type-list-header'>
                            <TableRow>
                              <TableCell className='type-list-header-data cursor-pointer type-list-first-header'
                                onClick={
                                  () => {
                                    handleSort('name');
                                  }
                                }
                              >Name
                                {(sortTypeForAdmin?.name && sortTypeForAdmin?.name === 'asc') ? <ExpandLess className="sort-icon" /> : ((sortTypeForAdmin?.name && sortTypeForAdmin?.name === 'desc') ? <ExpandMore className="sort-icon" /> : <ExpandLess className="sort-icon text-muted opacity-50" />)}
                              </TableCell>
                              <TableCell className='type-list-header-data type-list-third-header' align="left">Type</TableCell>
                              <TableCell className='type-list-header-data type-list-third-header' align="left">Date Created</TableCell>
                              <TableCell className='type-list-header-data type-list-last-header-att-post' align="right">Creator</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableRow style={{ height: '10px', backgroundColor: 'transparent' }} />
                          <TableBody className='type-list-body'>
                            {globalSearchFilterData?.map((row, index) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={index + 1}
                                className="cursor-pointer type-list-body-row"
                                onClick={() => {
                                  if (row?.search_type?.toLowerCase() === "folder") {
                                    localStorage.setItem("type-selected-name-user", row?.post_type?.type_name)
                                    localStorage.setItem("selected-folder-item", row?.folder_id)
                                    window.open(`/user-type/${row?.post_type?.type_name}`, '_blank');
                                  }
                                  else if (row?.search_type?.toLowerCase() === "people") {
                                    window.open(`/user-type/people/profile/view/${row?.id}`, '_blank');
                                  }
                                  else if (row?.search_type?.toLowerCase() === "chat") {
                                    localStorage.setItem("selected-chat-room-id", row?.chat_room_id);
                                    window.open(`/chat?chat_room=${row?.chat_room_id}&chat_id=${row?.id}`, '_blank')
                                  }
                                  else {
                                    localStorage.setItem("type-selected-name-user", row?.post_data?.type_name)
                                    window.open(`/user-type/view/${row?.post_data?.type_name}/${row?.id}`, '_blank');
                                  }
                                }}
                              >
                                <TableCell
                                  align="left"
                                >
                                  {row?.search_type === "folder" ? (
                                    <div className="d-flex flex-warp gap-3 align-items-center">
                                      <Box className="folder-tbl-cell-box">
                                        <FontAwesomeIcon icon={faFolder} className="folder-tbl-cell" />
                                      </Box>
                                      <Typography className="type-list-title" variant="h4" component="div">
                                        {row?.title}
                                      </Typography>
                                    </div>
                                  ) : row?.search_type === "people" ? (
                                    <div className="d-flex flex-warp gap-3 align-items-center">
                                      <Avatar
                                        className="type-list-created-avatar"
                                        src={row?.image}
                                        alt={row?.title}
                                      >
                                        {row?.title?.charAt(0)}
                                      </Avatar>
                                      <Typography className="type-list-title" variant="h4" component="div">
                                        {row?.title}
                                      </Typography>
                                    </div>
                                  ) : row?.search_type === "chat" ? (
                                    <div className="d-flex flex-warp gap-3 align-items-center">
                                      <Box className="folder-tbl-cell-box">
                                        <FontAwesomeIcon icon={faMessage} className="folder-tbl-cell" />
                                      </Box>
                                      <Typography className="type-list-title" variant="h4" component="div">
                                        {row?.title}
                                      </Typography>
                                    </div>
                                  ) : (
                                    <div className="d-flex flex-warp gap-3 align-items-center">
                                      <Box className="folder-tbl-cell-box">
                                        <FontAwesomeIcon icon={faFile} className="folder-tbl-cell" />
                                      </Box>
                                      <Typography className="type-list-title" variant="h4" component="div">
                                        {row?.title}
                                      </Typography>
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell className='type-list-text' align="left">
                                  {row?.search_type !== "post" ? row?.search_type : row?.type}
                                </TableCell>
                                <TableCell className='type-list-text' align="left">
                                  {row?.created_at || "---"}
                                </TableCell>
                                <TableCell className='' align="left">
                                  <Box className='type-list-text d-flex align-items-center type-list-createdby'>
                                    <Avatar
                                      className="type-list-created-avatar"
                                      src={row?.created_by?.thumbnail}
                                      alt={row?.created_by?.name}
                                    >
                                      {row?.created_by?.name?.charAt(0)}
                                    </Avatar>

                                    <span className>
                                      {row?.created_by?.name}
                                    </span>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </>
                      ) : (
                        <div className="no-data mt-2">
                          <div>
                            <BootstrapImage
                              src={NoDataFoundImg}
                              className="text-center mx-auto no-data-img-2 mt-4"
                            />
                          </div>
                          <Typography component="h5" variant="h5">
                            No data found
                          </Typography>
                        </div>
                      )}
                    </Table>
                  </TableContainer>
                </Paper>
                {globalSearchData?.data?.length > 0 && (
                  <>
                    {globalSearchData?.links?.next !== null && (
                      <>
                        <Divider component="div" role="presentation" />
                        <Box className="p-3 d-flex justify-content-end">
                          <Typography className="search-all-text cursor-pointer"
                            onClick={() => {
                              handleCloseForGlobalSearch()
                              navigate("/search-result", { state: { searchString: globalSearch, type: value } });
                            }}>
                            {`See All ${totalSearchCount} Matching ${globalSearch}`}</Typography>
                        </Box>
                      </>
                    )}
                  </>
                )}
              </CustomTabPanel>
            </Box>
          </Popper>
        </Box>
      </ClickAwayListener>
      {loading && (<Loader isLoading={loading} />)}
    </>
  )
}

export default GlobalSearch