import React from "react";
import { TextField } from "@mui/material";

const ShortTextComponent = ({
    attdata,
    handleChangeForFormData,
    handleChangeForChildAtts,
    isChild,
    parentAttData,
    handleChangeForAdditionalTextField,
    isMulti,
}) => {
    return (
        <>
            <TextField
                className="input-field"
                fullWidth
                id="outlined-basic short summary"
                variant="outlined"
                name={attdata?.nameValue}
                placeholder={`Enter ${attdata?.label}...`}
                value={attdata?.value}
                onChange={(event) => {
                    if (isChild === true) {
                        handleChangeForChildAtts(
                            attdata?.nameValue,
                            event?.target?.value,
                            attdata?.section_id,
                            attdata,
                            parentAttData
                        );
                    } else if (isMulti === true) {
                        handleChangeForAdditionalTextField(
                            attdata?.id,
                            event?.target?.value,
                            parentAttData?.nameValue,
                            parentAttData?.section_id,
                            attdata?.fieldtype
                        );
                    } else {
                        handleChangeForFormData(
                            attdata?.nameValue,
                            event?.target?.value,
                            attdata?.section_id
                        );
                    }
                }}
            />
        </>
    );
};

export default ShortTextComponent;
