import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Image, InputGroup } from "react-bootstrap";
import ReactionGroup from "../../../components/ui/ReactionGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Mention, MentionsInput } from "react-mentions";
import mentionsInputStyle from "../../../helpers/styles/dashboard/mentionsInputStyle";
import mentionStyle from "../../../helpers/styles/dashboard/mentionStyle";
import {
  CommentReactionClassName,
  ReactionClassName,
  ReactionIcon,
} from "../../../helpers/constants/common.js";
import { faComments, faEye } from "@fortawesome/free-solid-svg-icons";
import { formatDateToReadable } from "../../../helpers/DateTimeFormat/dateTimeFormat.js";
import InView from "../../../components/common/dashboard/InView.js";
import PreviewLink from "../../../components/common/dashboard/PreviewLink.js";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DummyPostImg from "../../../assets/images/dummy-post.jpg";

function UpdatePostCard({
  post,
  setElementsCallback,
  openMenuForPost,
  handleClickForMenuPost,
  renderUpdateContent,
  setOpenImageModel,
  setSinglePostData,
  setSelectedImageIndex,
  setAckQuestions,
  setPostIdForAckQue,
  setOpenAckQuePopup,
  handleCheckboxChange,
  handlePopoverClose,
  handlePopoverCloseForReplyLike,
  handleEngagementModalOpen,
  toggleReaction,
  handlePopoverOpen,
  handleCommentToggle,
  handleCommentText,
  handleCommentsAPI,
  handlePaste,
  getMentionUsers,
  setIsSelectingSuggestion,
  renderCommentText,
  toggleLikeForCommentsReaction,
  setCurrentPostId,
  setCurrentPostIdForReply,
  handlePopoverOpenForReplyLike,
  handleCommentReplyToggle,
  handleCommentReplyText,
  handleCommentsReplyAPI,
  setFetchAll,
  fetchLoadMoreCommentsAPI,
}) {
  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);
  return (
    <Card
      className="mt-4"
      data-post-id={post?.id}
      ref={setElementsCallback}
      data-post-view={post?.is_post_seen}
    >
      <CardHeader
        avatar={
          <Avatar
            aria-label="recipe"
            className="author-avtar-creator-post"
            src={post?.created_by?.thumbnail}
          >
            {post?.created_by?.name?.charAt(0)}
          </Avatar>
        }
        title={
          <Stack direction="row" justifyContent={"space-between"}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent={"space-between"}
              alignItems={{ md: "center" }}
              flex={1}
            >
              <span className="post-posted-label">
                <b>{post?.created_by?.name}</b> posted a{" "}
                <b>{post?.type?.name}</b>
              </span>
              <Box>
                <Typography variant="caption" className="post-date-label">
                  {formatDateToReadable(post?.published_date_time)}
                </Typography>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={openMenuForPost ? "long-menu" : undefined}
                  aria-expanded={openMenuForPost ? "true" : undefined}
                  aria-haspopup="true"
                  className="edit-post-icon-btn"
                  onClick={(e) => handleClickForMenuPost(e, post)}
                  sx={{ display: { xs: "none", md: "inline-flex" } }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            </Stack>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={openMenuForPost ? "long-menu" : undefined}
              aria-expanded={openMenuForPost ? "true" : undefined}
              aria-haspopup="true"
              className="edit-post-icon-btn"
              onClick={(e) => handleClickForMenuPost(e, post)}
              sx={{ display: { xs: "inline-flex", md: "none" } }}
            >
              <MoreVertIcon />
            </IconButton>
          </Stack>
        }
        className="card-header-content"
      />

      <CardContent className="content-div-body-2">
        <Box>
          <Typography
            variant="h3"
            component="h3"
            className="content-title-update text-break break-1"
          >
            {renderUpdateContent(post?.content)}
          </Typography>
          {post?.meta_data && Object.keys(post?.meta_data).length > 0 && (
            <InView>
              <PreviewLink key={post?.id} linkData={post?.meta_data} />
            </InView>
          )}
        </Box>

        {post?.multi_image?.length > 0 &&
          (post?.multi_image?.length === 1 ? (
            <CardMedia
              sx={{
                height: 222,
              }}
              className="mt-2 cursor-pointer"
              image={
                post?.multi_image[0]?.file_url !== ""
                  ? post?.multi_image[0]?.file_url
                  : DummyPostImg
              } // Use file_url for single image
              onClick={() => {
                setOpenImageModel(true);
                setSinglePostData(post);
                setSelectedImageIndex(0); // Set the selected image index when clicked
              }}
            />
          ) : (
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              className="update-multi-grid-item mt-1"
              columns={12} // Total columns in the grid
            >
              {post?.multi_image.map((file, index) => (
                <Grid
                  item
                  xs={6} // Each image takes 6 columns (50% width) on extra small screens
                  sm={4} // Each image takes 4 columns (33.33% width) on small screens
                  md={3} // Each image takes 3 columns (25% width) on medium+ screens
                  key={index}
                >
                  <div
                    className="upload-img-wrapper"
                    style={{ height: "162px" }}
                  >
                    <Image
                      src={
                        file?.file_url !== "" ? file?.file_url : DummyPostImg
                      }
                      alt={file?.alt_text || `Image ${index + 1}`}
                      style={{
                        width: "100%",
                        height: "100%",
                      }} // Ensure images are responsive
                      onClick={() => {
                        setOpenImageModel(true);
                        setSinglePostData(post);
                        setSelectedImageIndex(index); // Set the selected image index when clicked
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          ))}
        {post?.is_require_acknowledge && (
          <Box
            className={`custom-checkbox ${
              post?.acknowledged_on !== "" && "active-checkbox"
            }`}
          >
            <label className="checkbox-style">
              {post?.acknowledged_on !== ""
                ? `You acknowledged this on ${formatDateToReadable(
                    post?.acknowledged_date_time
                  )}`
                : post?.acknowledge_data &&
                  typeof post?.acknowledge_data === "string"
                ? `${post?.acknowledge_data}`
                : `This requires your acknowledgement`}
              <input
                type="checkbox"
                checked={post?.acknowledged_on !== ""}
                onChange={() => {
                  if (post?.acknowledge_type === "question") {
                    setAckQuestions(post?.acknowledge_data);
                    setPostIdForAckQue(post?.id);
                    setOpenAckQuePopup(true);
                  } else if (post?.acknowledge_type === "checkbox") {
                    handleCheckboxChange(post?.id);
                  }
                }}
                disabled={post?.acknowledged_on !== ""}
              />
              <span className="checkmark"></span>
            </label>
          </Box>
        )}
      </CardContent>

      {post?.is_post_comment || post?.is_post_like ? (
        <>
          <CardActions
            className="bg-white"
            sx={{
              border: "1px solid #EEEEEE",
              padding: "10px",
            }}
            onMouseEnter={() => {
              handlePopoverClose();
              handlePopoverCloseForReplyLike();
            }}
            onMouseLeave={() => {
              handlePopoverClose();
              handlePopoverCloseForReplyLike();
            }}
          >
            {post?.is_post_like && (
              <Box
                className="action-like w-100 d-flex align-items-center gap-2 cursor-pointer"
                onClick={() => {
                  handleEngagementModalOpen(post?.id);
                }}
              >
                <ReactionGroup reactions={post?.reactions} />
                <Box className="like-counts-box">
                  <Typography className="fs-12-action-btn">
                    {post?.like_count}
                  </Typography>
                </Box>
              </Box>
            )}
            {post?.is_post_comment && (
              <Box className="w-100 ms-0">
                <Typography className="fs-12-action-btn d-flex  justify-content-end">
                  {`${post?.comment_count} comments`}
                </Typography>
              </Box>
            )}
          </CardActions>
          <CardActions
            className="bg-white"
            sx={{
              border: "1px solid #EEEEEE",
              padding: "10px",
            }}
          >
            {post?.is_post_like && (
              <Box
                className="action-like w-100 d-flex justify-content-center align-items-center gap-2 cursor-pointer"
                onClick={() => {
                  toggleReaction(
                    post?.id,
                    post?.user_has_liked === "no" ? "like" : "no",
                    post?.user_has_liked === "no"
                  );
                  handlePopoverClose();
                }}
                onMouseEnter={(e) => handlePopoverOpen(e, post.id)}
              >
                <FontAwesomeIcon
                  icon={ReactionIcon[post?.reaction || "like"]}
                  className={`fs-12-action-btn ${
                    ReactionClassName[post?.reaction || ""]
                  }`}
                />
                <Typography
                  className={`fs-12-action-btn text-capitalize ${
                    ReactionClassName[post?.reaction || ""]
                  }`}
                >
                  {post?.user_has_liked !== "no" ? post.reaction : "Like"}
                </Typography>
              </Box>
            )}
            {post?.is_post_comment && (
              <Box
                className="action-comments w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                onClick={() => handleCommentToggle(post?.id)}
                onMouseEnter={() => {
                  handlePopoverClose();
                  handlePopoverCloseForReplyLike();
                }}
                onMouseLeave={() => {
                  handlePopoverClose();
                  handlePopoverCloseForReplyLike();
                }}
              >
                <FontAwesomeIcon
                  icon={faComments}
                  className="fs-12-action-btn"
                />
                <Typography className="fs-12-action-btn">Comments</Typography>
              </Box>
            )}
          </CardActions>
          {post?.is_post_comment && post?.commentToggle && (
            <CardActions
              className="bg-white card-action-2"
              sx={{
                border: "1px solid #EEEEEE",
                padding: "15px",
              }}
              onMouseEnter={() => {
                handlePopoverClose();
              }}
              onMouseLeave={() => {
                handlePopoverClose();
              }}
            >
              <Box
                className="w-100"
                onMouseEnter={() => {
                  handlePopoverClose();
                  handlePopoverCloseForReplyLike();
                }}
                onMouseLeave={() => {
                  handlePopoverClose();
                  handlePopoverCloseForReplyLike();
                }}
                sx={{ height: "40px" }}
              >
                <Box
                  className="d-flex justify-content-start align-items-center gap-2"
                  sx={{ height: "40px" }}
                >
                  <Avatar
                    alt={loggedUser?.name}
                    className="author-avtar-creator-3"
                    src={loggedUser?.thumbnail}
                  >
                    {loggedUser?.name?.charAt(0)}
                  </Avatar>
                  <InputGroup
                    className=""
                    style={{
                      height: "40px",
                    }}
                  >
                    <MentionsInput
                      placeholder="Add Comment. Use '@' for mention"
                      value={post?.commentText}
                      singleLine={true}
                      onChange={(event) => {
                        handleCommentText(event.target.value, post?.id);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleCommentsAPI(post?.id, "yes");
                        }
                      }}
                      className="input-comments"
                      style={mentionsInputStyle}
                      a11ySuggestionsListLabel={"Suggested mentions"}
                      onPaste={handlePaste}
                      suggestionsPortalHost={document.body}
                    >
                      <Mention
                        style={mentionStyle}
                        className="mention-popup-class"
                        data={getMentionUsers}
                        onAdd={() => setIsSelectingSuggestion(true)} // Set the flag when a suggestion is selected
                        appendSpaceOnAdd={true}
                        renderSuggestion={(suggestion, index) => (
                          <div className="list-class" key={index}>
                            <div className="list-class-div-1">
                              <img
                                src={suggestion.photo}
                                alt={suggestion.display}
                                className="list-class-div-1-img"
                              />
                              <div className="list-class-div-2">
                                <span style={{ fontWeight: "bold" }}>
                                  {suggestion?.full_name}
                                </span>
                                <br />
                                <span>{suggestion?.job_title}</span>
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    </MentionsInput>
                  </InputGroup>
                </Box>
              </Box>
            </CardActions>
          )}

          {post?.is_post_comment && post?.comments?.length > 0 && (
            <>
              <CardActions
                className="bg-white card-action-3"
                sx={{
                  border: "1px solid #EEEEEE",
                  padding: "10px",
                }}
              >
                {post?.comments?.map((comment) => (
                  <>
                    {comment?.is_parent === "yes" && (
                      <>
                        <Box
                          className="w-100 ms-0"
                          onMouseEnter={handlePopoverClose}
                          onMouseLeave={handlePopoverClose}
                        >
                          <Box className="d-flex justify-content-start align-items-center gap-2">
                            <Avatar
                              alt={comment?.user?.name}
                              className="author-avtar-creator-4 mb-auto"
                              src={comment?.user?.thumbnail}
                            >
                              {comment?.user?.name?.charAt(0)}
                            </Avatar>
                            <Box className="comment-box">
                              <Typography className="comment-user-name">
                                {comment?.user?.name}
                              </Typography>
                              <Typography className="fs-14 mt-2">
                                {renderCommentText(comment?.comment)}
                              </Typography>
                            </Box>
                          </Box>
                          <Stack
                            direction={{ md: "row" }}
                            divider={
                              <Divider
                                orientation="vertical"
                                flexItem
                                className="nested-divider"
                              />
                            }
                            className="mt-2 align-items-md-center"
                            spacing={1}
                            style={{ margin: "0 0 0 44px" }}
                          >
                            <Box>
                              <Box className="d-flex gap-2 align-items-center">
                                <Typography className="fs-10">
                                  {formatDateToReadable(
                                    comment?.comment_date_time
                                  )}
                                </Typography>
                                <Typography className="fs-10 add-dot" />

                                <Typography
                                  className={`fs-10 cursor-pointer ${
                                    comment?.has_user_liked_comment === "yes" &&
                                    "liked-post-text"
                                  }`}
                                  onClick={() => {
                                    toggleLikeForCommentsReaction(
                                      post?.id,
                                      comment?.has_user_liked_comment === "no"
                                        ? "like"
                                        : "no",
                                      comment?.id,
                                      comment?.has_user_liked_comment === "no"
                                    );
                                  }}
                                  onMouseEnter={(e) => {
                                    setCurrentPostId(post?.id);

                                    setCurrentPostIdForReply(comment?.id);
                                    handlePopoverOpenForReplyLike(
                                      e,
                                      post.id,
                                      comment?.id
                                    );
                                  }}
                                  onMouseLeave={() => {
                                    setCurrentPostId(post?.id);

                                    setCurrentPostIdForReply(comment?.id);
                                  }}
                                >
                                  Like
                                </Typography>
                                <Box
                                  className="action-like d-flex align-items-center gap-1 cursor-pointer"
                                  onMouseEnter={() => {
                                    handlePopoverClose();
                                    handlePopoverCloseForReplyLike();
                                  }}
                                  onMouseLeave={() => {
                                    handlePopoverClose();
                                    handlePopoverCloseForReplyLike();
                                  }}
                                  onClick={() => {
                                    handleEngagementModalOpen(
                                      comment?.id,
                                      "comment"
                                    );
                                  }}
                                >
                                  {comment?.user_reactions?.map((reaction) => (
                                    <FontAwesomeIcon
                                      icon={ReactionIcon[reaction || ""]}
                                      className={
                                        CommentReactionClassName[reaction || ""]
                                      }
                                    />
                                  ))}

                                  <Box className="like-counts-box">
                                    <Typography className="fs-10">
                                      {comment?.comment_likes_count}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              onMouseEnter={() => {
                                handlePopoverClose();
                                handlePopoverCloseForReplyLike();
                              }}
                              onMouseLeave={() => {
                                handlePopoverClose();
                                handlePopoverCloseForReplyLike();
                              }}
                            >
                              <Box className="w-100 d-flex gap-2 align-items-center">
                                <Typography
                                  className="fs-10 cursor-pointer"
                                  onClick={() =>
                                    handleCommentReplyToggle(
                                      post?.id,
                                      comment?.id
                                    )
                                  }
                                >
                                  Reply
                                </Typography>
                                <Typography className="fs-10 cursor-pointer">
                                  {`${comment?.replies_count} reply`}
                                </Typography>
                              </Box>
                            </Box>
                          </Stack>
                        </Box>

                        {comment?.commentReplyToggle && (
                          <Box
                            className="w-100 ms-0"
                            sx={{ paddingLeft: "62px" }}
                            onMouseEnter={() => {
                              handlePopoverClose();
                              handlePopoverCloseForReplyLike();
                            }}
                            onMouseLeave={() => {
                              handlePopoverClose();
                              handlePopoverCloseForReplyLike();
                            }}
                          >
                            <Box className="w-100 mt-2 mb-1">
                              <Box className="d-flex justify-content-start align-items-center gap-2">
                                <Avatar
                                  alt={loggedUser?.name}
                                  className="author-avtar-creator-4"
                                  src={loggedUser?.thumbnail}
                                >
                                  {loggedUser?.name?.charAt(0)}
                                </Avatar>
                                <InputGroup style={{ height: "40px" }}>
                                  <MentionsInput
                                    placeholder="Add Comment. Use '@' for mention"
                                    value={comment?.commentReplyText}
                                    singleLine={true}
                                    onChange={(event) => {
                                      handleCommentReplyText(
                                        event?.target?.value,
                                        post?.id,
                                        comment?.id
                                      );
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        e.preventDefault();
                                        handleCommentsReplyAPI(
                                          post?.id,
                                          "no",
                                          comment?.id
                                        );
                                      }
                                    }}
                                    className="input-comments"
                                    style={mentionsInputStyle}
                                    a11ySuggestionsListLabel={
                                      "Suggested mentions"
                                    }
                                    onPaste={handlePaste}
                                  >
                                    <Mention
                                      style={mentionStyle}
                                      className="mention-popup-class"
                                      data={getMentionUsers}
                                      appendSpaceOnAdd={true}
                                      onAdd={() =>
                                        setIsSelectingSuggestion(true)
                                      } // Set the flag when a suggestion is selected
                                      renderSuggestion={(suggestion, index) => (
                                        <div className="list-class" key={index}>
                                          <div className="list-class-div-1">
                                            <img
                                              src={suggestion.photo}
                                              alt={suggestion.display}
                                              className="list-class-div-1-img"
                                            />
                                            <div className="list-class-div-2">
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {suggestion?.full_name}
                                              </span>
                                              <br />
                                              <span>
                                                {suggestion?.job_title}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    />
                                  </MentionsInput>
                                </InputGroup>
                              </Box>
                            </Box>
                          </Box>
                        )}

                        {(comment?.replies || [])?.map((reply) => (
                          <Box
                            className="w-100 ms-0"
                            sx={{
                              paddingLeft: "62px",
                            }}
                            onMouseEnter={() => {
                              handlePopoverClose();
                              handlePopoverCloseForReplyLike();
                            }}
                            onMouseLeave={() => {
                              handlePopoverClose();
                              handlePopoverCloseForReplyLike();
                            }}
                          >
                            <Box className="w-100 mt-2">
                              <Box className="d-flex justify-content-start align-items-center gap-2">
                                <Avatar
                                  alt={reply?.user?.name}
                                  className="author-avtar-creator-4 mb-auto"
                                  src={reply?.user?.thumbnail}
                                >
                                  {reply?.user?.name?.charAt(0)}
                                </Avatar>
                                <Box
                                  sx={{
                                    padding: "10px",
                                  }}
                                  className="comment-box"
                                >
                                  <Typography className="comment-user-name">
                                    {reply?.user?.name}
                                  </Typography>
                                  <Typography className="fs-14 mt-2">
                                    {renderCommentText(reply?.comment)}
                                  </Typography>
                                </Box>
                              </Box>
                              <Stack
                                direction="row"
                                divider={
                                  <Divider
                                    orientation="vertical"
                                    flexItem
                                    className="nested-divider"
                                  />
                                }
                                className="mt-2 align-items-center"
                                spacing={1}
                                style={{
                                  margin: "0 0 0 44px",
                                }}
                              >
                                <Box>
                                  <Box className="d-flex gap-2 align-items-center">
                                    <Typography className="fs-10">
                                      {formatDateToReadable(
                                        reply?.comment_date_time
                                      )}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Stack>
                            </Box>
                          </Box>
                        ))}
                      </>
                    )}
                  </>
                ))}
                {post?.comments?.length < post?.parent_comment_count && (
                  <Box
                    className="w-100 ms-0 d-flex justify-content-center"
                    onMouseEnter={() => {
                      handlePopoverClose();
                      handlePopoverCloseForReplyLike();
                    }}
                    onMouseLeave={() => {
                      handlePopoverClose();
                      handlePopoverCloseForReplyLike();
                    }}
                  >
                    <Button
                      className="mt-4 btn-load-comments d-inline-flex gap-1 text-capitalize"
                      onClick={() => {
                        setFetchAll(true);
                        fetchLoadMoreCommentsAPI(post);
                      }}
                    >
                      <FontAwesomeIcon icon={faEye} />
                      <span>Load More Comments</span>
                    </Button>
                  </Box>
                )}
              </CardActions>
            </>
          )}
        </>
      ) : (
        ""
      )}
    </Card>
  );
}

export default UpdatePostCard;
