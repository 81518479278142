import React, { useEffect, useState, useCallback } from "react";

import {
    Typography,
    Box,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Collapse,
    TablePagination
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import NoDataFoundImg from "../../../assets/images/NoDataFound.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFolder,
    faUser,
    faImage,
    faFilePdf,
    faFile,
    faFileExcel,
    faVideo,
    faXmark,
    faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";

import { Card, Image, Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";  // Option 1: Use uuid library (better for unique IDs)

import api from "../commonFunctionForApi";
import { Toast } from "../../../utils/toast";
import logoutHelper from "../../../helpers/logoutHelper";

const MyMediaSubFolders = ({ folder, setSelectedMediaType, setSelectedMediaFolderID, setSelectedMediaFile, setSelectedMediaFileDetails, setPageForMedia, selectedMediaFolderID, level, openFolders, toggleFolder }) => {
    const isOpen = openFolders.includes(folder?.id); // Check if folder is open

    return (
        <>
            <ListItem
                key={folder?.id}
                disablePadding
                sx={{ display: "block" }}
            >
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        px: 2.5,
                        justifyContent: "initial",
                        ml: `${level * 20}px` // Indentation based on the folder level
                    }}
                    onClick={() => {
                        toggleFolder(folder?.id); // Toggle open/close state of the folder
                        setSelectedMediaType("my-media");
                        setSelectedMediaFolderID(folder?.id);
                        setSelectedMediaFile("");
                        setSelectedMediaFileDetails();
                        setPageForMedia(0);
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 2,
                            ml: 2,
                            justifyContent: "center",
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faFolder}
                            className={`${selectedMediaFolderID == folder?.id ? "menu-icon-media-selected" : "menu-icon-media"}`}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={folder?.title}
                        className={`${selectedMediaFolderID == folder?.id ? "menu-name-media-selected" : "menu-name-media"}`}
                    />
                </ListItemButton>

                {/* Conditionally render the child folders only if the folder is open */}
                {folder?.child_folders?.length > 0 && (
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        {folder?.child_folders.map((childFolder) => (
                            <MyMediaSubFolders
                                key={childFolder.id}
                                folder={childFolder}
                                setSelectedMediaType={setSelectedMediaType}
                                setSelectedMediaFolderID={setSelectedMediaFolderID}
                                setSelectedMediaFile={setSelectedMediaFile}
                                setSelectedMediaFileDetails={setSelectedMediaFileDetails}
                                setPageForMedia={setPageForMedia}
                                selectedMediaFolderID={selectedMediaFolderID}
                                level={level + 1} // Increase the level for child folders
                                openFolders={openFolders}
                                toggleFolder={toggleFolder}
                            />
                        ))}
                    </Collapse>
                )}
            </ListItem>
        </>
    );
};

const SystemMediaSubFolders = ({ folder, setSelectedMediaType, setSelectedMediaFolderID, setSelectedMediaFile, setSelectedMediaFileDetails, setPageForMedia, selectedMediaFolderID, level, openFolders, toggleFolder }) => {
    const isOpen = openFolders.includes(folder?.id); // Check if folder is open

    return (
        <>
            <ListItem
                key={folder?.id}
                disablePadding
                sx={{ display: "block" }}
            >
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        px: 2.5,
                        justifyContent: "initial",
                        ml: `${level * 20}px` // Indentation based on the folder level
                    }}
                    onClick={() => {
                        toggleFolder(folder?.id); // Toggle open/close state of the folder
                        setSelectedMediaType("system");
                        setSelectedMediaFolderID(folder?.id);
                        setSelectedMediaFile("");
                        setSelectedMediaFileDetails();
                        setPageForMedia(0);
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 2,
                            ml: 2,
                            justifyContent: "center",
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faFolder}
                            className={`${selectedMediaFolderID == folder?.id ? "menu-icon-media-selected" : "menu-icon-media"}`}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={folder?.title}
                        className={`${selectedMediaFolderID == folder?.id ? "menu-name-media-selected" : "menu-name-media"}`}
                    />
                </ListItemButton>

                {/* Conditionally render the child folders only if the folder is open */}
                {folder?.child_folders?.length > 0 && (
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        {folder?.child_folders.map((childFolder) => (
                            <SystemMediaSubFolders
                                key={childFolder.id}
                                folder={childFolder}
                                setSelectedMediaType={setSelectedMediaType}
                                setSelectedMediaFolderID={setSelectedMediaFolderID}
                                setSelectedMediaFile={setSelectedMediaFile}
                                setSelectedMediaFileDetails={setSelectedMediaFileDetails}
                                setPageForMedia={setPageForMedia}
                                selectedMediaFolderID={selectedMediaFolderID}
                                level={level + 1} // Increase the level for child folders
                                openFolders={openFolders}
                                toggleFolder={toggleFolder}
                            />
                        ))}
                    </Collapse>
                )}
            </ListItem>
        </>
    );
};

const MyMediaFolderList = ({ myMediaFolderList, setSelectedMediaType, setSelectedMediaFolderID, setSelectedMediaFile, setSelectedMediaFileDetails, setPageForMedia, selectedMediaFolderID, selectedMediaType }) => {
    const [openFolders, setOpenFolders] = useState([]); // Track open folders by their ID

    // Function to toggle the open/close state of a folder
    const toggleFolder = (id) => {
        setOpenFolders((prevOpenFolders) =>
            prevOpenFolders.includes(id)
                ? prevOpenFolders.filter((folderId) => folderId !== id) // Remove from open list if it's open
                : [...prevOpenFolders, id] // Add to open list if it's closed
        );
    };

    // Function to find the parent folder hierarchy for a given folder ID
    const findParentFolders = (folderList, folderId) => {
        for (const folder of folderList) {
            if (folder.id === folderId) {
                return [folder.id];
            }
            if (folder.child_folders?.length > 0) {
                const childResult = findParentFolders(folder.child_folders, folderId);
                if (childResult.length > 0) {
                    return [folder.id, ...childResult];
                }
            }
        }
        return [];
    };

    useEffect(() => {
        if (selectedMediaFolderID) {
            // Find the parent folders and open them
            const parentFolders = findParentFolders(myMediaFolderList, selectedMediaFolderID);
            setOpenFolders(parentFolders);
        }
    }, [selectedMediaFolderID, myMediaFolderList]);

    return (
        <>
            <ListItem
                key={"my-media"}
                disablePadding
                sx={{ display: "block" }}
                className={`${selectedMediaType == "my-media" && "media-selected-type-border-left"}`}
            >
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        px: 2.5,
                    }}
                    onClick={() => {
                        setSelectedMediaType("my-media");
                        setSelectedMediaFolderID(0);
                        setSelectedMediaFile("");
                        setSelectedMediaFileDetails();
                        setPageForMedia(0);
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 2,
                            justifyContent: "center",
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faUser}
                            className={`${selectedMediaType == "my-media" ? "menu-icon-media-selected" : "menu-icon-media"}`}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary="My Media"
                        className={`${selectedMediaType == "my-media" ? "menu-name-media-selected" : "menu-name-media"}`}
                    />
                </ListItemButton>
            </ListItem>

            {/* Render the top-level folders */}
            <Collapse
                in={selectedMediaType == "my-media"}
                timeout="auto"
                unmountOnExit>
                {myMediaFolderList?.map((folder) => (
                    <MyMediaSubFolders
                        key={folder.id}
                        folder={folder}
                        setSelectedMediaType={setSelectedMediaType}
                        setSelectedMediaFolderID={setSelectedMediaFolderID}
                        setSelectedMediaFile={setSelectedMediaFile}
                        setSelectedMediaFileDetails={setSelectedMediaFileDetails}
                        setPageForMedia={setPageForMedia}
                        selectedMediaFolderID={selectedMediaFolderID}
                        level={1} // Start the level at 1 for top-level folders
                        openFolders={openFolders} // Pass open folders state
                        toggleFolder={toggleFolder} // Pass the function to toggle folders
                    />
                ))}
            </Collapse>
        </>
    );
};

const SystemMediaFolderList = ({ systemMediaFolderList, setSelectedMediaType, setSelectedMediaFolderID, setSelectedMediaFile, setSelectedMediaFileDetails, setPageForMedia, selectedMediaFolderID, selectedMediaType, loggedUser, setShowUploadBTN }) => {
    const [openFolders, setOpenFolders] = useState([]); // Track open folders by their ID
    // Function to toggle the open/close state of a folder
    const toggleFolder = (id) => {
        setOpenFolders((prevOpenFolders) =>
            prevOpenFolders.includes(id)
                ? prevOpenFolders.filter((folderId) => folderId !== id) // Remove from open list if it's open
                : [...prevOpenFolders, id] // Add to open list if it's closed
        );
    };

    // Function to find the parent folder hierarchy for a given folder ID
    const findParentFolders = (folderList, folderId) => {
        for (const folder of folderList) {
            if (folder.id === folderId) {
                return [folder.id];
            }
            if (folder.child_folders?.length > 0) {
                const childResult = findParentFolders(folder.child_folders, folderId);
                if (childResult.length > 0) {
                    return [folder.id, ...childResult];
                }
            }
        }
        return [];
    };

    useEffect(() => {
        if (selectedMediaFolderID) {
            // Find the parent folders and open them
            const parentFolders = findParentFolders(systemMediaFolderList, selectedMediaFolderID);
            setOpenFolders(parentFolders);
        }
    }, [selectedMediaFolderID, systemMediaFolderList]);

    useEffect(() => {
        if (loggedUser?.user_type !== "admin" && selectedMediaType === "system") {
            setShowUploadBTN(false)
        } else {
            setShowUploadBTN(true)
        }
    })

    return (
        <>
            <ListItem
                key={"system"}
                disablePadding
                sx={{ display: "block" }}
                className={`${selectedMediaType == "system" && "media-selected-type-border-left"}`}
            >
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        px: 2.5,
                    }}
                    onClick={() => {
                        setSelectedMediaType("system");
                        setSelectedMediaFolderID(0);
                        setSelectedMediaFile("");
                        setSelectedMediaFileDetails();
                        setPageForMedia(0);
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 2,
                            justifyContent: "center",
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faUser}
                            className={`${selectedMediaType == "system" ? "menu-icon-media-selected" : "menu-icon-media"}`}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary="System Media"
                        className={`${selectedMediaType == "system" ? "menu-name-media-selected" : "menu-name-media"}`}
                    />
                </ListItemButton>
            </ListItem>

            {/* Render the top-level folders */}
            <Collapse
                in={selectedMediaType == "system"}
                timeout="auto"
                unmountOnExit>
                {systemMediaFolderList?.map((folder) => (
                    <SystemMediaSubFolders
                        key={folder.id}
                        folder={folder}
                        setSelectedMediaType={setSelectedMediaType}
                        setSelectedMediaFolderID={setSelectedMediaFolderID}
                        setSelectedMediaFile={setSelectedMediaFile}
                        setSelectedMediaFileDetails={setSelectedMediaFileDetails}
                        setPageForMedia={setPageForMedia}
                        selectedMediaFolderID={selectedMediaFolderID}
                        level={1} // Start the level at 1 for top-level folders
                        openFolders={openFolders} // Pass open folders state
                        toggleFolder={toggleFolder} // Pass the function to toggle folders
                    />

                ))}
            </Collapse>
        </>
    );
};

const AllFoldersList = ({ myMediaFolderList, setSelectedMediaType, setSelectedMediaFolderID, setSelectedMediaFile, setSelectedMediaFileDetails, setPageForMedia, selectedMediaFolderID, selectedMediaType, systemMediaFolderList, loggedUser, setShowUploadBTN }) => {
    return (
        <List className="list-item-menu">
            <SystemMediaFolderList
                systemMediaFolderList={systemMediaFolderList}
                setSelectedMediaType={setSelectedMediaType}
                setSelectedMediaFolderID={setSelectedMediaFolderID}
                setSelectedMediaFile={setSelectedMediaFile}
                setSelectedMediaFileDetails={setSelectedMediaFileDetails}
                setPageForMedia={setPageForMedia}
                selectedMediaFolderID={selectedMediaFolderID}
                selectedMediaType={selectedMediaType}
                loggedUser={loggedUser}
                setShowUploadBTN={setShowUploadBTN}
            />
            <MyMediaFolderList
                myMediaFolderList={myMediaFolderList}
                setSelectedMediaType={setSelectedMediaType}
                setSelectedMediaFolderID={setSelectedMediaFolderID}
                setSelectedMediaFile={setSelectedMediaFile}
                setSelectedMediaFileDetails={setSelectedMediaFileDetails}
                setPageForMedia={setPageForMedia}
                selectedMediaFolderID={selectedMediaFolderID}
                selectedMediaType={selectedMediaType}
            />
        </List>
    );
};

const UploadMediaModel = (props) => {
    let {
        openMediaUpload,
        handleCloseForUploadMedia,
        selectedMediaTypeForImage,
        selectedMediaTypeForDoc,
        setSelectedMediaTypeForImage,
        setSelectedMediaTypeForDoc,
        setSelectedMediaFolderIDForImage,
        setSelectedMediaFolderIDForDoc,
        systemMediaFolderList,
        selectedMediaFolderIDForImage,
        selectedMediaFolderIDFordoc,
        myMediaFolderList,
        downloadFileForImage,
        downloadFileFordoc,
        selectedMediaFileDetailsForImage,
        setSelectedMediaFileDetailsForImage,
        selectedMediaFileForImage,
        setSelectedMediaFileForImage,
        selectedMediaFileDetailsForDoc,
        setSelectedMediaFileDetailsForDoc,
        selectedMediaFileForDoc,
        setSelectedMediaFileForDoc,
        setFieldValue,
        mediaList,
        setMediaList,
        total_mediaFiles,
        pageForMedia,
        setPageForMedia,
        handleChangePageForMedia,
        rowsPerPageForMedia,
        handleChangeRowsPerPageForMedia,
        media_Pagination_options,
        mediaDataFor,
        handleCloseMediaModelAddMedia,
        setSelectedFileMedia,
        selectedFileMedia,
        isEditMedia,
        setIsEditMedia,
        setSelectedMedia,
        selectedMedia,
        deleteMedia,
        setOpenRenameModel,
        setIsRenameMedia,
        setSelectedMediaFileForUpdate,
        selectedMediaFileForUpdate,
        setIsImageMedia,
        isMultiImage = false,
        setSelectedMediaFileDetailsForImages,
        selectedMediaFileDetailsForImages,
        setSelectedMediaFileForImages,
        selectedMediaFileForImages,
        setLoading,
        userToken,
        dispatch,
        navigate,
        setSelectedAnyMediaFile,
        selectedAnyMediaFile,
        setSelectedAnyMediaFileDetails,
        selectedAnyMediaFileDetails,
        setUploadedFile,
        uploadedFile,
        setShowRenderImage,
        showRenderImage,
        isSystemTypeMedia,
        mediaTitle,
        mediaAltText,
        mediaDescription,
        isAdvancePermissionForMedia,
        permissionsForMedia,
        allowedDrag
    } = props

    const [showUploadBRN, setShowUploadBTN] = useState(true)
    const modifiedSelectedMediaFile = mediaDataFor == "image" ? selectedMediaFileForImage : selectedMediaFileForDoc;
    const modifiedSelectedMediaFileDetails = mediaDataFor == "image" ? selectedMediaFileDetailsForImage : selectedMediaFileDetailsForDoc

    const modifiedSetSelectedMediaFile = mediaDataFor == "image" ? setSelectedMediaFileForImage : setSelectedMediaFileForDoc
    const modifiedSetSelectedMediaFileDetails = mediaDataFor == "image" ? setSelectedMediaFileDetailsForImage : setSelectedMediaFileDetailsForDoc

    const modifiedSelectedMediaFolderID = mediaDataFor == "image" ? selectedMediaFolderIDForImage : selectedMediaFolderIDFordoc
    const modifiedSetSelectedMediaFolderID = mediaDataFor == "image" ? setSelectedMediaFolderIDForImage : setSelectedMediaFolderIDForDoc

    const modifiedSetSelectedMediaType = mediaDataFor == "image" ? setSelectedMediaTypeForImage : setSelectedMediaTypeForDoc
    const modifiedSelectedMediaType = mediaDataFor == "image" ? selectedMediaTypeForImage : selectedMediaTypeForDoc

    const modifiedDownload = mediaDataFor == "image" ? downloadFileForImage : downloadFileFordoc

    const user = localStorage.getItem("user");
    const loggedUser = JSON.parse(user);

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles?.length > 0) {
            setShowRenderImage(true)
            const newFiles = acceptedFiles.map((file) => ({
                file,
                id: uuidv4(),
                url: URL.createObjectURL(file),
                type: file.type,
                name: file.name,
                isNew: true,
            }));
            setUploadedFile(newFiles[0])
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            "image/*": [], // Accept all image types
            "application/pdf": [], // Accept PDFs
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], // DOCX
            "application/vnd.openxmlformats-officedocument.presentationml.presentation": [], // PPTX
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], // XLSX
            "video/mp4": [], // MP4
        },
        multiple: false,
    });

    const renderPreview = () => {
        if (!uploadedFile) return null;

        // Handle image files
        if (uploadedFile?.type?.startsWith('image/')) {
            return (
                <div className="upload-img-wrapper" style={{ position: "relative" }}>
                    <img
                        src={uploadedFile?.url}
                        alt="Uploaded"
                    />
                    <IconButton
                        size="small"
                        style={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            color: "white",
                        }}
                        onClick={(e) => {
                            e.stopPropagation();  // Prevent the click event from propagating to the dropzone
                            setUploadedFile()
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </div>
            );
        }

        // Handle PDF files
        if (uploadedFile?.type === 'application/pdf') {
            return (
                <div className="upload-img-wrapper" >
                    <Card
                        key={"pdf"}
                        variant="outlined"
                        sx={{
                            p: 2,
                            mb: 2,
                            cursor: "pointer",
                        }}
                        className="pdf-doc"
                        style={{ position: "relative" }}
                    >
                        <Box
                            height={100}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <FontAwesomeIcon
                                size="3x"
                                icon={
                                    faFilePdf
                                }
                                color="#660C60"
                            />
                        </Box>
                        <Box
                            sx={{
                                paddingLeft: 1,
                            }}
                        >
                            {uploadedFile?.name}
                        </Box>
                    </Card>
                    <IconButton
                        size="small"
                        style={{
                            position: "absolute",
                            top: 130,
                            right: 30,
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            color: "white",
                        }}
                        onClick={(e) => {
                            e.stopPropagation();  // Prevent the click event from propagating to the dropzone
                            setUploadedFile()
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </div>
            );
        }

        // Handle DOCX files
        if (uploadedFile?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            return (
                <div className="upload-img-wrapper" >
                    <Card
                        key={"pdf"}
                        variant="outlined"
                        sx={{
                            p: 2,
                            mb: 2,
                            cursor: "pointer",
                        }}
                        className="pdf-doc"
                        style={{ position: "relative" }}

                    >
                        <Box
                            height={100}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <FontAwesomeIcon
                                size="3x"
                                icon={
                                    faFile
                                }
                                color="#660C60"
                            />
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                paddingLeft: 1,
                            }}
                        >
                            {uploadedFile?.name}
                        </Box>
                    </Card>
                    <IconButton
                        size="small"
                        style={{
                            position: "absolute",
                            top: 130,
                            right: 30,
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            color: "white",
                        }}
                        onClick={(e) => {
                            e.stopPropagation();  // Prevent the click event from propagating to the dropzone
                            setUploadedFile()
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </div>
            );
        }

        // Handle PPTX files
        if (uploadedFile?.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
            return (
                <div className="upload-img-wrapper" >
                    <Card
                        key={"pdf"}
                        variant="outlined"
                        sx={{
                            p: 2,
                            mb: 2,
                            cursor: "pointer",
                        }}
                        className="pdf-doc"
                        style={{ position: "relative" }}
                    >
                        <Box
                            height={100}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <FontAwesomeIcon
                                size="3x"
                                icon={
                                    faFile
                                }
                                color="#660C60"
                            />
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                paddingLeft: 1,
                            }}
                        >
                            {uploadedFile?.name}
                        </Box>
                    </Card>
                    <IconButton
                        size="small"
                        style={{
                            position: "absolute",
                            top: 130,
                            right: 30,
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            color: "white",
                        }}
                        onClick={(e) => {
                            e.stopPropagation();  // Prevent the click event from propagating to the dropzone
                            setUploadedFile()
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </div>
            );
        }

        if (uploadedFile?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return (
                <div className="upload-img-wrapper" >
                    <Card
                        key={"pdf"}
                        variant="outlined"
                        sx={{
                            p: 2,
                            mb: 2,
                            cursor: "pointer",
                        }}
                        className="pdf-doc"
                        style={{ position: "relative" }}
                    >
                        <Box
                            height={100}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <FontAwesomeIcon
                                size="3x"
                                icon={
                                    faFileExcel
                                }
                                color="#660C60"
                            />
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                paddingLeft: 1,
                            }}
                        >
                            {uploadedFile?.name}
                        </Box>
                    </Card>
                    <IconButton
                        size="small"
                        style={{
                            position: "absolute",
                            top: 130,
                            right: 30,
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            color: "white",
                        }}
                        onClick={(e) => {
                            e.stopPropagation();  // Prevent the click event from propagating to the dropzone
                            setUploadedFile()
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </div>
            );
        }

        // Handle MP4 files
        if (uploadedFile?.type === 'video/mp4') {
            return (
                <div className="upload-img-wrapper" >
                    <Card
                        key={"pdf"}
                        variant="outlined"
                        sx={{
                            p: 2,
                            mb: 2,
                            cursor: "pointer",
                        }}
                        className="pdf-doc"
                        style={{ position: "relative" }}
                    >
                        <Box
                            height={100}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <FontAwesomeIcon
                                size="3x"
                                icon={
                                    faVideo
                                }
                                color="#660C60"
                            />
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                paddingLeft: 1,
                            }}
                        >
                            {uploadedFile?.name}
                        </Box>
                    </Card>
                    <IconButton
                        size="small"
                        style={{
                            position: "absolute",
                            top: 130,
                            right: 30,
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            color: "white",
                        }}
                        onClick={(e) => {
                            e.stopPropagation();  // Prevent the click event from propagating to the dropzone
                            setUploadedFile()
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </div>
            );
        }

        return (
            <div className="upload-img-wrapper" >
                <Card
                    key={"pdf"}
                    variant="outlined"
                    sx={{
                        p: 2,
                        mb: 2,
                        cursor: "pointer",
                    }}
                    className="pdf-doc"
                    style={{ position: "relative" }}

                >
                    <Box
                        height={100}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <FontAwesomeIcon
                            size="3x"
                            icon={
                                faFilePdf
                            }
                            color="#660C60"
                        />
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            paddingLeft: 1,
                        }}
                    >
                        {uploadedFile?.name}
                    </Box>
                </Card>
                <IconButton
                    size="small"
                    style={{
                        position: "absolute",
                        top: 130,
                        right: 30,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        color: "white",
                    }}
                    onClick={(e) => {
                        e.stopPropagation();  // Prevent the click event from propagating to the dropzone
                        setUploadedFile()
                    }}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            </div>
        );
    };

    const storeMediaAtt = async () => {

        const formData = new FormData();
        let permissionData;
        formData.append("is_system", isSystemTypeMedia === true ? 1 : 0);
        formData.append("title", mediaTitle);
        formData.append("alt_text", mediaAltText);
        formData.append("description", mediaDescription);
        formData.append("advance_permission", 0);

        if (isAdvancePermissionForMedia === true) {
            permissionData = permissionsForMedia?.map((data) => {
                return {
                    user_group_id: data?.value,
                    type: data?.type,
                    can_view: 0,
                    can_deny: 0,
                    can_edit: 0,
                    can_delete: 0,
                    can_resource: 0,
                    label: data?.label,
                };
            });
        } else {
            permissionData = permissionsForMedia?.map((data) => {
                return {
                    user_group_id: data?.value,
                    type: data?.type,
                    can_view: 0,
                    can_deny: 0,
                    can_edit: 0,
                    can_delete: 0,
                    can_resource: 0,
                    label: data?.label,
                };
            });
        }
        if (modifiedSelectedMediaFolderID) {
            formData.append("folder_id", modifiedSelectedMediaFolderID)
        }

        formData.append("permission", JSON?.stringify(permissionData));

        if (
            uploadedFile?.file instanceof Blob ||
            uploadedFile?.file instanceof File
        ) {
            formData.append("file", uploadedFile?.file);
        }
        try {
            setLoading(true);
            const response = await api.post(
                `user/media/file/store`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success === true) {
                setLoading(false);
                setMediaList(prev => [response?.response, ...prev]);
                Toast.success(response?.message);
                setUploadedFile()
                setSelectedAnyMediaFile(response?.response?.id)
                setSelectedMediaFileForImage(response?.response?.id)
                setSelectedAnyMediaFileDetails(response?.response)
                setSelectedMediaFileDetailsForImage(response?.response)
                setSelectedMediaFileForUpdate(response?.response)
                modifiedSetSelectedMediaFile(response?.response?.id)
                setFieldValue("image", response?.response);
                modifiedSetSelectedMediaFileDetails(response?.response)
                setIsImageMedia(true)
                handleCloseForUploadMedia()
                setUploadedFile()
                setSelectedMediaFileDetailsForImages(prev => [...prev, response?.response]);
                setSelectedMediaFileForImages(prev => [...prev, response?.response?.id]);
            } else {
                setLoading(false);
                Toast.error(response?.message);
            }
        } catch (err) {
            console.log("error in ctreate news api::", err);
            setLoading(false);

            if (err?.response?.status === 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status === 422) {
                setLoading(false);
                const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
        }

    }

    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={openMediaUpload}
            maxWidth="sm"
            fullWidth={true}
            className="media-dialog"
        >
            <DialogTitle
                sx={{ m: 0, p: 2 }}
                id="customized-dialog-title"
                className="dialog-title"
            >
                Media Upload
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleCloseForUploadMedia}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                className="alert-close-btn"
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers className="media-preview">
                <Grid container spacing={2}>
                    <Grid item xs={4} className="media-folder-list-grid">
                        <AllFoldersList
                            myMediaFolderList={myMediaFolderList}
                            setSelectedMediaType={modifiedSetSelectedMediaType}
                            setSelectedMediaFolderID={modifiedSetSelectedMediaFolderID}
                            setSelectedMediaFile={modifiedSetSelectedMediaFile}
                            setSelectedMediaFileDetails={modifiedSetSelectedMediaFileDetails}
                            setPageForMedia={setPageForMedia}
                            selectedMediaFolderID={modifiedSelectedMediaFolderID}
                            selectedMediaType={modifiedSelectedMediaType}
                            systemMediaFolderList={systemMediaFolderList}
                            loggedUser={loggedUser}
                            setShowUploadBTN={setShowUploadBTN}
                        />
                    </Grid>
                    <Grid item xs={8} className="media-list-grid">
                        <Box className={`main-upload-media-div`}>
                            {showUploadBRN && allowedDrag && (
                                <Box className="upload-btn-div">
                                    <Button
                                        className="btn primary-btn"
                                        onClick={() => {
                                            handleCloseMediaModelAddMedia()
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faImage} />
                                        Upload
                                    </Button>
                                </Box>
                            )}

                        </Box>

                        <Box className="media-img-lists">
                            {allowedDrag === true && (
                                <Box className={`w-100 update-new-box-media border-media-upload-box`}>
                                    {!uploadedFile ? (
                                        <div
                                            {...getRootProps({
                                                className: `"dropzone-2"`,
                                                style: {
                                                    textAlign: "center",
                                                    cursor: "pointer",
                                                    position: "relative",
                                                    width: "100%",
                                                    height: "100%",
                                                    minHeight: "120px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                },
                                            })}
                                        >
                                            <input {...getInputProps()} />
                                            <p className="drag-drop-text">Drag 'n' drop image here, or click to select</p>
                                        </div>
                                    ) : (
                                        <div className="w-100">{renderPreview()}</div>
                                    )}

                                </Box>
                            )}

                            <Grid container spacing={2} className={`${allowedDrag === true && "mt-2"}`}>
                                {mediaList?.length > 0 ? (
                                    <>
                                        {mediaList?.map((data, index) =>
                                            data?.type === "png" || data?.type === "jpg" || data?.type === "jpeg" || data?.type === "gif" ? (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={4}
                                                    lg={2}
                                                    className="media-grid"
                                                >
                                                    <Card
                                                        key={index}
                                                        variant="outlined"
                                                        sx={{ p: 2, mb: 2 }}
                                                        className={`${isMultiImage
                                                            ? selectedMediaFileDetailsForImages.some(item => item.id === data.id)
                                                                ? "selected-media-file-2"
                                                                : ""
                                                            : modifiedSelectedMediaFile === data?.id
                                                                ? "selected-media-file-2"
                                                                : ""
                                                            } card-media-2`}
                                                        onClick={() => {
                                                            if (isMultiImage) {
                                                                if (selectedMediaFileDetailsForImages.length >= 10 && !selectedMediaFileDetailsForImages.some(item => item.id === data.id)) {
                                                                    return;
                                                                }
                                                                if (selectedMediaFileDetailsForImages.some(item => item.id === data.id)) {
                                                                    // Remove the image if it's already selected
                                                                    setSelectedMediaFileDetailsForImages(prev =>
                                                                        prev.filter(item => item.id !== data.id)
                                                                    );
                                                                    setSelectedMediaFileForImages(prev =>
                                                                        prev.filter(id => id !== data.id)
                                                                    );
                                                                } else {
                                                                    // Add the image to the selected list
                                                                    setSelectedMediaFileDetailsForImages(prev => [...prev, data]);
                                                                    setSelectedMediaFileForImages(prev => [...prev, data.id]);
                                                                }
                                                            } else {
                                                                // Single selection logic
                                                                modifiedSetSelectedMediaFile(data?.id);
                                                                modifiedSetSelectedMediaFileDetails(data);
                                                                setSelectedMediaFileDetailsForImage(data)
                                                            }
                                                        }}
                                                    >
                                                        <Box
                                                            height={174}
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            className="img-container-2"
                                                        >
                                                            <Image
                                                                src={
                                                                    data?.file_path
                                                                }
                                                                alt={data?.title}
                                                                className="media-img-2"
                                                            />

                                                        </Box>
                                                    </Card>
                                                </Grid>
                                            ) : data?.type === "pdf" ? (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={4}
                                                    lg={2}
                                                    className="media-grid"
                                                >
                                                    <Card
                                                        key={index}
                                                        variant="outlined"
                                                        sx={{ p: 2, mb: 2 }}
                                                        className={`${modifiedSelectedMediaFile == data?.id && "selected-media-file-2"} card-media-2`}
                                                        onClick={() => {
                                                            modifiedSetSelectedMediaFile(data?.id)
                                                            modifiedSetSelectedMediaFileDetails(data)
                                                            setSelectedMediaFileDetailsForImage(data)
                                                        }}
                                                    >
                                                        <Box
                                                            height={174}
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            className="img-container-2"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faFilePdf
                                                                }
                                                                color="#660C60"
                                                                className="folder-icon"
                                                            />
                                                        </Box>
                                                        <Divider />
                                                        <Box
                                                            className="media-item-name"
                                                        >
                                                            {data?.title}
                                                        </Box>
                                                    </Card>
                                                </Grid>
                                            ) : data?.type === "docx" ? (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={4}
                                                    lg={2}
                                                    className="media-grid"
                                                >
                                                    <Card
                                                        key={index}
                                                        variant="outlined"
                                                        sx={{ p: 2, mb: 2 }}
                                                        className={`${modifiedSelectedMediaFile == data?.id && "selected-media-file-2"} card-media-2`}
                                                        onClick={() => {
                                                            modifiedSetSelectedMediaFile(data?.id)
                                                            modifiedSetSelectedMediaFileDetails(data)
                                                            setSelectedMediaFileDetailsForImage(data)
                                                        }}
                                                    >
                                                        <Box
                                                            height={174}
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            className="img-container-2"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faFile
                                                                }
                                                                color="#660C60"
                                                                className="folder-icon"
                                                            />
                                                        </Box>
                                                        <Divider />
                                                        <Box
                                                            className="media-item-name"
                                                        >
                                                            {data?.title}
                                                        </Box>
                                                    </Card>
                                                </Grid>
                                            ) : data?.type === "pptx" ? (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={4}
                                                    lg={2}
                                                    className="media-grid"
                                                >
                                                    <Card
                                                        key={index}
                                                        variant="outlined"
                                                        sx={{ p: 2, mb: 2 }}
                                                        className={`${modifiedSelectedMediaFile == data?.id && "selected-media-file-2"} card-media-2`}
                                                        onClick={() => {
                                                            modifiedSetSelectedMediaFile(data?.id)
                                                            modifiedSetSelectedMediaFileDetails(data)
                                                            setSelectedMediaFileDetailsForImage(data)
                                                        }}
                                                    >
                                                        <Box
                                                            height={174}
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            className="img-container-2"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faFile
                                                                }
                                                                color="#660C60"
                                                                className="folder-icon"
                                                            />
                                                        </Box>
                                                        <Divider />
                                                        <Box
                                                            className="media-item-name"
                                                        >
                                                            {data?.title}
                                                        </Box>
                                                    </Card>
                                                </Grid>
                                            ) : data?.type === "xlsx" ? (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={4}
                                                    lg={2}
                                                    className="media-grid"
                                                >
                                                    <Card
                                                        key={index}
                                                        variant="outlined"
                                                        sx={{ p: 2, mb: 2 }}
                                                        className={`${modifiedSelectedMediaFile == data?.id && "selected-media-file-2"} card-media-2`}
                                                        onClick={() => {
                                                            modifiedSetSelectedMediaFile(data?.id)
                                                            modifiedSetSelectedMediaFileDetails(data)
                                                            setSelectedMediaFileDetailsForImage(data)
                                                        }}
                                                    >
                                                        <Box
                                                            height={174}
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            className="img-container-2"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faFileExcel
                                                                }
                                                                color="#660C60"
                                                                className="folder-icon"
                                                            />
                                                        </Box>
                                                        <Divider />
                                                        <Box
                                                            className="media-item-name"
                                                        >
                                                            {data?.title}
                                                        </Box>
                                                    </Card>
                                                </Grid>
                                            ) : data?.type === "mp4" ? (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={4}
                                                    lg={2}
                                                    className="media-grid"
                                                >
                                                    <Card
                                                        key={index}
                                                        variant="outlined"
                                                        sx={{ p: 2, mb: 2 }}
                                                        className={`${modifiedSelectedMediaFile == data?.id && "selected-media-file-2"} card-media-2`}
                                                        onClick={() => {
                                                            modifiedSetSelectedMediaFile(data?.id)
                                                            modifiedSetSelectedMediaFileDetails(data)
                                                            setSelectedMediaFileDetailsForImage(data)
                                                        }}
                                                    >
                                                        <Box
                                                            height={174}
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            className="img-container-2"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faVideo
                                                                }
                                                                color="#660C60"
                                                                className="folder-icon"
                                                            />
                                                        </Box>
                                                        <Divider />
                                                        <Box
                                                            className="media-item-name"
                                                        >
                                                            {data?.title}
                                                        </Box>
                                                    </Card>
                                                </Grid>
                                            ) : ""
                                        )}
                                    </>
                                ) : (
                                    <div className="no-data-media mt-5">
                                        <div>
                                            <Image
                                                src={NoDataFoundImg}
                                                className="text-center mx-auto no-data-img mt-4"
                                            />
                                        </div>
                                        <Typography component="h5" variant="h5">
                                            No data found
                                        </Typography>
                                    </div>
                                )}
                            </Grid>
                        </Box>
                        <Box className={"media-pagination-main-div"}>
                            <TablePagination
                                component="div"
                                className="media-pagination"
                                count={total_mediaFiles}
                                page={pageForMedia}
                                onPageChange={handleChangePageForMedia}
                                rowsPerPage={rowsPerPageForMedia}
                                onRowsPerPageChange={
                                    handleChangeRowsPerPageForMedia
                                }
                                labelDisplayedRows={({
                                    from,
                                    to,
                                    count,
                                }) => {
                                    const currentPage = pageForMedia + 1;
                                    const start =
                                        currentPage * rowsPerPageForMedia -
                                        rowsPerPageForMedia +
                                        1;

                                    const end = Math.min(
                                        currentPage * rowsPerPageForMedia,
                                        count
                                    );
                                    return (
                                        <>
                                            {`Showing ${start} to ${end} of `}
                                            <span>{count}</span>
                                            {" records"}
                                        </>
                                    );
                                }}
                                rowsPerPageOptions={media_Pagination_options}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className="d-flex flex-wrap justify-content-between gap-2 p-3 z-0 action-btn-model">
                <Button
                    className="btn delete-btn-model"
                    onClick={handleCloseForUploadMedia}
                >
                    <FontAwesomeIcon icon={faXmark} />
                    Cancel
                </Button>
                {!uploadedFile ? (
                    <Button
                        className="btn primary-btn"
                        onClick={() => {
                            if (mediaDataFor === "image") {
                                if (setFieldValue) {
                                    setFieldValue("image", modifiedSelectedMediaFileDetails);

                                } else {
                                    setSelectedMediaFileForUpdate(modifiedSelectedMediaFileDetails)
                                    setSelectedMediaFileDetailsForImage(modifiedSelectedMediaFileDetails)
                                    setIsImageMedia(true)
                                }
                            } else if (mediaDataFor === "doc") {
                                if (setFieldValue) {
                                    setFieldValue("file", modifiedSelectedMediaFileDetails);
                                    setFieldValue("title", modifiedSelectedMediaFileDetails?.title)
                                } else {
                                    setSelectedMediaFileForUpdate(modifiedSelectedMediaFileDetails)
                                    setSelectedMediaFileDetailsForImage(modifiedSelectedMediaFileDetails)
                                    setIsImageMedia(true)
                                }
                            }
                            handleCloseForUploadMedia()
                        }}
                    >
                        <FontAwesomeIcon icon={faFloppyDisk} />
                        Select Media
                    </Button>
                ) : (
                    <Button
                        className="btn primary-btn"
                        onClick={() => {
                            storeMediaAtt()
                        }}
                    >
                        <FontAwesomeIcon icon={faFloppyDisk} />
                        Select & Upload Media
                    </Button>
                )}

            </DialogActions>
        </Dialog>
    )
}

export default UploadMediaModel