import React from "react";
import DropDownMultiChoice from "./dropdown/dropdownMultiChoice";
import DropDownSingleChoice from "./dropdown/dropdownSingleChoice";
import LongText from "./text/longText";
import LinkComponent from "./text/link";
import TextEditor from "./text/textEditor";
import NumberComponent from "./text/number";
import EmailComponent from "./text/email";
import ImageComponent from "./file/image";
import RadioSingleChoice from "./radio/radioSingleChoice";
import CheckboxSingleChoice from "./checkbox/checkboxSingleChoice";
import CheckboxMultiChoice from "./checkbox/checkboxMultiChoice";
import DateComponent from "./date/date";
import TimeComponent from "./date/time";
import DateTimeComponent from "./date/datetime";
import DateRangeComponent from "./date/daterange";
import FileComponent from "./file/file";
import UsersList from "./list/userList";
import GroupList from "./list/groupList";
import LinkObjectComponent from "./text/linkObject";
import HTMLComponent from "./text/html";
import UserGroupListComponent from "./list/userGroupList";
import RadioSingleChoiceForACK from "./radio/radioSingleChoiceForACK";
import { Box, Typography } from "@mui/material";
import ShortTextComponent from "./text/shortText";
import { Button } from "react-bootstrap";
import {
    TextField,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import CheckboxMultiChoiceRepeatable from "./checkbox/checkboxMultiChoiceRepeat";
import ImageRepeatableComponent from "./file/imageRepeatable";
import ImageFileComponent from "./file/imageFile";

const AllContentAndAttributeComponent = React.memo(({
    attributesData,
    handleChangeForFormData,
    setOpenMediaModelFor,
    handleOpenUploadMediaForAtt,
    handleChangeForChildAtts,
    isChild,
    parentAttData,
    handleNestedChildChange,
    handleAddNestedTextField,
    additionalTextFieldsForNestedChildAtt1,
    handleChangeForNestedChildAdditionalTextField,
    removeNestedExtraFields,
    handleAddTextField,
    additionalTextFields,
    handleChangeForAdditionalTextField,
    removeExtraFields,
    handleClickForAtt,
    setFileForContent,
    fileForContent,
    handleCloseForUploadMediaForAtt
}) => {
    // console.log("attributesData 55::", attributesData)

    return (
        <>
            {attributesData?.map((attdata, index) => (
                <>
                    {attdata?.is_repeatable ===
                        true ? (
                        <>
                            <Box className="w-100 d-flex gap-1">
                                {/* {attdata?.fieldtype !==
                                    "checkbox_single_choice" && ( */}
                                <>
                                    {/* <div className="extra-links-div att-assigned-label-box text-wrap"> */}
                                    <Box className="att-assigned-label-box-2 text-wrap">
                                        <Typography
                                            className="fs-12-muted"
                                        >
                                            {attdata?.label}{" "}
                                            {attdata?.is_mandatory ==
                                                true && (
                                                    <span className="required">
                                                        *
                                                    </span>
                                                )}
                                        </Typography>
                                    </Box>
                                    <Box className="att-assigned-field-box-2">
                                        <Button
                                            className="btn primary-btn"
                                            onClick={() => {
                                                handleAddTextField(
                                                    attdata?.nameValue,
                                                    attdata?.label,
                                                    attdata?.section_id,
                                                    attdata?.fieldtype,
                                                    attdata?.options,
                                                    attdata?.is_mandatory,
                                                    attdata?.is_repeatable,
                                                    attdata?.value
                                                )
                                            }
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faPlus}
                                            />
                                            {`Add`}
                                        </Button>
                                    </Box>
                                    {/* </div> */}

                                </>
                                {/* )} */}
                            </Box>
                            <div>
                                {additionalTextFields?.map((textField,
                                    index) => (
                                    <div key={index}>
                                        {textField?.nameValue ===
                                            attdata?.nameValue && (
                                                <Box className="d-flex gap-1 w-100">
                                                    <div className={`${attdata?.fieldtype === "link_object" ? 'add-title-div-2' : 'add-title-div'}`}>
                                                        {textField?.fieldtype ===
                                                            "dropdodown_single_choice" ? (
                                                            <DropDownSingleChoice attdata={textField} handleChangeForAdditionalTextField={handleChangeForAdditionalTextField} isMulti={true} parentAttData={attdata} />
                                                        ) : textField?.fieldtype ===
                                                            "dropdodown_multiple_choice" ? (
                                                            <DropDownMultiChoice attdata={textField} handleChangeForAdditionalTextField={handleChangeForAdditionalTextField} isMulti={true} parentAttData={attdata} />
                                                        ) : textField?.fieldtype ===
                                                            "tags" ? (
                                                            <DropDownMultiChoice attdata={textField} handleChangeForAdditionalTextField={handleChangeForAdditionalTextField} isMulti={true} parentAttData={attdata} />
                                                        ) : textField?.fieldtype ===
                                                            "short_text" ? (
                                                            <ShortTextComponent attdata={textField} handleChangeForAdditionalTextField={handleChangeForAdditionalTextField} isMulti={true} parentAttData={attdata} />
                                                        ) : textField?.fieldtype ===
                                                            "long_text" ? (
                                                            <LongText attdata={textField} handleChangeForAdditionalTextField={handleChangeForAdditionalTextField} isMulti={true} parentAttData={attdata} />
                                                        ) : textField?.fieldtype ===
                                                            "link" ? (
                                                            <LinkComponent attdata={textField} handleChangeForAdditionalTextField={handleChangeForAdditionalTextField} isMulti={true} parentAttData={attdata} />
                                                        ) : textField?.fieldtype ===
                                                            "text_editor" ? (
                                                            <TextEditor attdata={textField} handleChangeForAdditionalTextField={handleChangeForAdditionalTextField} isMulti={true} parentAttData={attdata} />
                                                        ) : textField?.fieldtype ===
                                                            "number" ? (
                                                            <NumberComponent attdata={textField} handleChangeForAdditionalTextField={handleChangeForAdditionalTextField} isMulti={true} parentAttData={attdata} />
                                                        ) : textField?.fieldtype ===
                                                            "email" ? (
                                                            <EmailComponent attdata={textField} handleChangeForAdditionalTextField={handleChangeForAdditionalTextField} isMulti={true} parentAttData={attdata} />
                                                        ) : textField?.fieldtype ===
                                                            "radio_single_choice" ? (
                                                            <RadioSingleChoice attdata={textField} handleChangeForAdditionalTextField={handleChangeForAdditionalTextField} isMulti={true} parentAttData={attdata} />
                                                        ) : textField?.fieldtype ===
                                                            "checkbox_single_choice" ? (
                                                            <CheckboxSingleChoice attdata={textField} handleChangeForAdditionalTextField={handleChangeForAdditionalTextField} isMulti={true} parentAttData={attdata} />
                                                        ) : textField?.fieldtype ===
                                                            "checkbox_multiple_choice" ? (
                                                            <CheckboxMultiChoiceRepeatable attdata={textField} handleChangeForAdditionalTextField={handleChangeForAdditionalTextField} isMulti={true} parentAttData={attdata} attributesData={attributesData} />
                                                        ) : textField?.fieldtype ===
                                                            "date" ? (
                                                            <DateComponent attdata={textField} handleChangeForAdditionalTextField={handleChangeForAdditionalTextField} isMulti={true} parentAttData={attdata} />
                                                        ) : textField?.fieldtype ===
                                                            "time" ? (
                                                            <TimeComponent attdata={textField} handleChangeForAdditionalTextField={handleChangeForAdditionalTextField} isMulti={true} parentAttData={attdata} />
                                                        ) : textField?.fieldtype ===
                                                            "date_time" ? (
                                                            <DateTimeComponent attdata={textField} handleChangeForAdditionalTextField={handleChangeForAdditionalTextField} isMulti={true} parentAttData={attdata} />
                                                        ) : textField?.fieldtype ===
                                                            "date_range" ? (
                                                            <DateRangeComponent attdata={textField} handleChangeForAdditionalTextField={handleChangeForAdditionalTextField} isMulti={true} parentAttData={attdata} />
                                                        ) : textField?.fieldtype ===
                                                            "users_list" ? (
                                                            <UsersList attdata={textField} handleChangeForAdditionalTextField={handleChangeForAdditionalTextField} isMulti={true} parentAttData={attdata} />
                                                        ) : textField?.fieldtype ===
                                                            "group_list" ? (
                                                            <GroupList attdata={textField} handleChangeForAdditionalTextField={handleChangeForAdditionalTextField} isMulti={true} parentAttData={attdata} />
                                                        ) : textField?.fieldtype ===
                                                            "link_object" ? (
                                                            <LinkObjectComponent attdata={textField} handleChangeForAdditionalTextField={handleChangeForAdditionalTextField} isMulti={true} parentAttData={attdata} index={index} />
                                                        ) : textField?.fieldtype ===
                                                            "html" ? (
                                                            <HTMLComponent attdata={textField} handleChangeForAdditionalTextField={handleChangeForAdditionalTextField} isMulti={true} parentAttData={attdata} />
                                                        ) : textField?.fieldtype ===
                                                            "users_group_list" ? (
                                                            <UserGroupListComponent attdata={textField} handleChangeForAdditionalTextField={handleChangeForAdditionalTextField} isMulti={true} parentAttData={attdata} />
                                                        ) : textField?.fieldtype ===
                                                            "image" ? (
                                                            <ImageRepeatableComponent attdata={textField} handleClickForAtt={handleClickForAtt} />
                                                        ) : null}

                                                    </div>
                                                    <div className={`${attdata?.fieldtype === "link_object" ? "add-title-div-btn-2" : "add-title-div-btn"}`}>
                                                        <Button
                                                            className="btn btn-danger"
                                                            onClick={() => removeExtraFields(
                                                                textField.id,
                                                                attdata?.nameValue,
                                                                attdata?.section_id
                                                            )}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faTimes} />
                                                        </Button>
                                                    </div>
                                                </Box>
                                            )}
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <React.Fragment key={index}>
                            {/* {console.log("attdata::", attdata)} */}
                            <Box className="w-100 d-flex gap-1">
                                <Box className="att-assigned-label-box-2 text-wrap">
                                    <Typography className="fs-12-muted">
                                        {attdata?.label}
                                        {attdata?.is_mandatory ==
                                            true && (
                                                <span className="required">
                                                    *
                                                </span>
                                            )}
                                    </Typography>
                                    {/* {attdata?.is_mandatory ==
                                        true && (
                                            <span className="required">
                                                *
                                            </span>
                                        )} */}
                                </Box>
                                <Box className="att-assigned-field-box-2">
                                    {attdata?.fieldtype === "dropdodown_multiple_choice" ? (
                                        <DropDownMultiChoice attdata={attdata} handleChangeForFormData={handleChangeForFormData} handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild} parentAttData={parentAttData} />
                                    ) : attdata?.fieldtype === "tags" ? (
                                        <DropDownMultiChoice attdata={attdata} handleChangeForFormData={handleChangeForFormData} handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild} parentAttData={parentAttData} />
                                    ) : attdata?.fieldtype === "dropdodown_single_choice" ? (
                                        <DropDownSingleChoice attdata={attdata} handleChangeForFormData={handleChangeForFormData} handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild} parentAttData={parentAttData} />
                                    ) : attdata?.fieldtype === "short_text" ? (
                                        <ShortTextComponent attdata={attdata} handleChangeForFormData={handleChangeForFormData} handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild} parentAttData={parentAttData} />
                                    ) : attdata?.fieldtype === "long_text" ? (
                                        <LongText attdata={attdata} handleChangeForFormData={handleChangeForFormData} handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild} parentAttData={parentAttData} />
                                    ) : attdata?.fieldtype === "link" ? (
                                        <LinkComponent attdata={attdata} handleChangeForFormData={handleChangeForFormData} handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild} parentAttData={parentAttData} />
                                    ) : attdata?.fieldtype === "text_editor" ? (
                                        <TextEditor attdata={attdata} handleChangeForFormData={handleChangeForFormData} handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild} parentAttData={parentAttData} />
                                    ) : attdata?.fieldtype === "number" ? (
                                        <NumberComponent attdata={attdata} handleChangeForFormData={handleChangeForFormData} handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild} parentAttData={parentAttData} />
                                    ) : attdata?.fieldtype === "email" ? (
                                        <EmailComponent attdata={attdata} handleChangeForFormData={handleChangeForFormData} handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild} parentAttData={parentAttData} />
                                    ) : attdata?.fieldtype === "image" ? (
                                        <ImageComponent
                                            attdata={attdata}
                                            setOpenMediaModelFor={setOpenMediaModelFor}
                                            handleChangeForFormData={handleChangeForFormData}
                                            handleOpenUploadMediaForAtt={handleOpenUploadMediaForAtt}
                                            handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild} parentAttData={parentAttData}
                                        />
                                    ) : attdata?.fieldtype === "radio_single_choice" && attdata?.nameValue !== "radio_single_choice_acknowledgement_type" ? (
                                        <RadioSingleChoice attdata={attdata} handleChangeForFormData={handleChangeForFormData} handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild} parentAttData={parentAttData} />
                                    ) : attdata?.fieldtype === "radio_single_choice" && attdata?.nameValue === "radio_single_choice_acknowledgement_type" ? (
                                        <RadioSingleChoiceForACK attdata={attdata} handleChangeForFormData={handleChangeForFormData} handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild}
                                            parentAttData={parentAttData}
                                            handleNestedChildChange={handleNestedChildChange}
                                            handleAddNestedTextField={handleAddNestedTextField}
                                            additionalTextFieldsForNestedChildAtt1={additionalTextFieldsForNestedChildAtt1}
                                            handleChangeForNestedChildAdditionalTextField={handleChangeForNestedChildAdditionalTextField}
                                            removeNestedExtraFields={removeNestedExtraFields}
                                        />
                                    ) : attdata?.fieldtype === "checkbox_single_choice" ? (
                                        <CheckboxSingleChoice attdata={attdata} handleChangeForFormData={handleChangeForFormData} handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild} parentAttData={parentAttData} />
                                    ) : attdata?.fieldtype === "checkbox_multiple_choice" ? (
                                        <CheckboxMultiChoice
                                            attdata={attdata}
                                            handleChangeForFormData={handleChangeForFormData}
                                            attributesData={attributesData}
                                            handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild} parentAttData={parentAttData}
                                        />
                                    ) : attdata?.fieldtype === "date" ? (
                                        <DateComponent attdata={attdata} handleChangeForFormData={handleChangeForFormData} handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild} parentAttData={parentAttData} />
                                    ) : attdata?.fieldtype === "time" ? (
                                        <TimeComponent attdata={attdata} handleChangeForFormData={handleChangeForFormData} handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild} parentAttData={parentAttData} />
                                    ) : attdata?.fieldtype === "date_time" ? (
                                        <DateTimeComponent attdata={attdata} handleChangeForFormData={handleChangeForFormData} handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild} parentAttData={parentAttData} />
                                    ) : attdata?.fieldtype === "date_range" ? (
                                        <DateRangeComponent attdata={attdata} handleChangeForFormData={handleChangeForFormData} handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild} parentAttData={parentAttData} />
                                    ) : attdata?.fieldtype === "file" ? (
                                        <FileComponent
                                            attdata={attdata}
                                            setOpenMediaModelFor={setOpenMediaModelFor}
                                            handleChangeForFormData={handleChangeForFormData}
                                            handleOpenUploadMediaForAtt={handleOpenUploadMediaForAtt}
                                        />
                                    ) : attdata?.fieldtype === "image_file" ? (
                                        <ImageFileComponent
                                            attdata={attdata}
                                            setOpenMediaModelFor={setOpenMediaModelFor}
                                            handleChangeForFormData={handleChangeForFormData}
                                            handleOpenUploadMediaForAtt={handleOpenUploadMediaForAtt}
                                            setFileForContent={setFileForContent}
                                            handleCloseForUploadMediaForAtt={handleCloseForUploadMediaForAtt}
                                        />
                                    ) : attdata?.fieldtype === "users_list" ? (
                                        <UsersList attdata={attdata} handleChangeForFormData={handleChangeForFormData} handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild} parentAttData={parentAttData} />
                                    ) : attdata?.fieldtype === "group_list" ? (
                                        <GroupList attdata={attdata} handleChangeForFormData={handleChangeForFormData} handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild} parentAttData={parentAttData} />
                                    ) : attdata?.fieldtype === "link_object" ? (
                                        <LinkObjectComponent attdata={attdata} handleChangeForFormData={handleChangeForFormData} index={index} handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild} parentAttData={parentAttData} />
                                    ) : attdata?.fieldtype === "html" ? (
                                        <HTMLComponent attdata={attdata} handleChangeForFormData={handleChangeForFormData} handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild} parentAttData={parentAttData} />
                                    ) : attdata?.fieldtype === "users_group_list" ? (
                                        <UserGroupListComponent attdata={attdata} handleChangeForFormData={handleChangeForFormData} handleChangeForChildAtts={handleChangeForChildAtts} isChild={isChild} parentAttData={parentAttData} />
                                    ) : (
                                        ""
                                    )}
                                </Box>
                            </Box>
                            {/* Recursively render child attributes if they exist */}
                            {attdata?.child_attribute?.length > 0 && attdata?.nameValue !== "radio_single_choice_acknowledgement_type" && attdata?.value === true ? (
                                <AllContentAndAttributeComponent
                                    attributesData={attdata?.child_attribute}
                                    handleChangeForFormData={handleChangeForFormData}
                                    setOpenMediaModelFor={setOpenMediaModelFor}
                                    handleOpenUploadMediaForAtt={handleOpenUploadMediaForAtt}
                                    handleChangeForChildAtts={handleChangeForChildAtts}
                                    isChild={true}
                                    parentAttData={attdata}
                                    handleNestedChildChange={handleNestedChildChange}
                                    handleAddNestedTextField={handleAddNestedTextField}
                                    additionalTextFieldsForNestedChildAtt1={additionalTextFieldsForNestedChildAtt1}
                                    handleChangeForNestedChildAdditionalTextField={handleChangeForNestedChildAdditionalTextField}
                                    removeNestedExtraFields={removeNestedExtraFields}
                                />
                            ) : attdata?.child_attribute?.length > 0 && attdata?.nameValue !== "radio_single_choice_acknowledgement_type" && attdata?.nameValue === "dropdodown_single_choice_review" && attdata?.value !== 1 ? (
                                <AllContentAndAttributeComponent
                                    attributesData={attdata?.child_attribute}
                                    handleChangeForFormData={handleChangeForFormData}
                                    setOpenMediaModelFor={setOpenMediaModelFor}
                                    handleOpenUploadMediaForAtt={handleOpenUploadMediaForAtt}
                                    handleChangeForChildAtts={handleChangeForChildAtts}
                                    isChild={true}
                                    parentAttData={attdata}
                                    handleNestedChildChange={handleNestedChildChange}
                                    handleAddNestedTextField={handleAddNestedTextField}
                                    additionalTextFieldsForNestedChildAtt1={additionalTextFieldsForNestedChildAtt1}
                                    handleChangeForNestedChildAdditionalTextField={handleChangeForNestedChildAdditionalTextField}
                                    removeNestedExtraFields={removeNestedExtraFields}
                                />
                            ) : attdata?.child_attribute?.length > 0 && attdata?.nameValue !== "radio_single_choice_acknowledgement_type" && attdata?.nameValue === "dropdodown_single_choice_highlightasmodal" && attdata?.value === 1 ? (
                                <AllContentAndAttributeComponent
                                    attributesData={attdata?.child_attribute}
                                    handleChangeForFormData={handleChangeForFormData}
                                    setOpenMediaModelFor={setOpenMediaModelFor}
                                    handleOpenUploadMediaForAtt={handleOpenUploadMediaForAtt}
                                    handleChangeForChildAtts={handleChangeForChildAtts}
                                    isChild={true}
                                    parentAttData={attdata}
                                    handleNestedChildChange={handleNestedChildChange}
                                    handleAddNestedTextField={handleAddNestedTextField}
                                    additionalTextFieldsForNestedChildAtt1={additionalTextFieldsForNestedChildAtt1}
                                    handleChangeForNestedChildAdditionalTextField={handleChangeForNestedChildAdditionalTextField}
                                    removeNestedExtraFields={removeNestedExtraFields}
                                />
                            ) : attdata?.child_attribute?.length > 0 && attdata?.nameValue !== "radio_single_choice_acknowledgement_type" && attdata?.fieldtype === "radio_single_choice" && attdata?.value === 1 ? (
                                <AllContentAndAttributeComponent
                                    attributesData={attdata?.child_attribute}
                                    handleChangeForFormData={handleChangeForFormData}
                                    setOpenMediaModelFor={setOpenMediaModelFor}
                                    handleOpenUploadMediaForAtt={handleOpenUploadMediaForAtt}
                                    handleChangeForChildAtts={handleChangeForChildAtts}
                                    isChild={true}
                                    parentAttData={attdata}
                                    handleNestedChildChange={handleNestedChildChange}
                                    handleAddNestedTextField={handleAddNestedTextField}
                                    additionalTextFieldsForNestedChildAtt1={additionalTextFieldsForNestedChildAtt1}
                                    handleChangeForNestedChildAdditionalTextField={handleChangeForNestedChildAdditionalTextField}
                                    removeNestedExtraFields={removeNestedExtraFields}
                                />
                            ) : attdata?.child_attribute?.length > 0 && attdata?.nameValue === "radio_single_choice_acknowledgement_type" ? (
                                <>
                                    {attdata?.child_attribute?.map((nestedChildAtt1) => {
                                        if (attdata?.value === nestedChildAtt1?.show_option_value) {
                                            return (
                                                <>
                                                    {nestedChildAtt1?.fieldtype === "short_text" && nestedChildAtt1?.is_repeatable === false && (
                                                        <Box className="w-100 d-flex gap-2 justify-content-center align-items-center mb-4">
                                                            {nestedChildAtt1?.fieldtype !==
                                                                "checkbox_single_choice" && (
                                                                    <Box className="att-assigned-label-box-2 text-wrap">
                                                                        <Typography
                                                                            className="fs-12-muted"
                                                                        >
                                                                            {
                                                                                nestedChildAtt1?.label
                                                                            }{" "}
                                                                            {/* {nestedChildAtt1?.is_mandatory ==
                                                        true && (
                                                            <span className="required">
                                                                *
                                                            </span>
                                                        )} */}
                                                                        </Typography>
                                                                    </Box>
                                                                )}
                                                            <Box className="att-assigned-field-box-2">
                                                                <TextField
                                                                    className="input-field mt-2"
                                                                    fullWidth
                                                                    id="outlined-basic short summary"
                                                                    variant="outlined"
                                                                    name={
                                                                        nestedChildAtt1?.nameValue
                                                                    }
                                                                    placeholder={`Enters ${nestedChildAtt1?.label}...`}
                                                                    value={
                                                                        nestedChildAtt1?.value
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        const newValue =
                                                                            event
                                                                                .target
                                                                                .value;

                                                                        handleNestedChildChange(
                                                                            nestedChildAtt1?.nameValue,
                                                                            event.target.value,
                                                                            nestedChildAtt1?.section_id,
                                                                            attdata,
                                                                            parentAttData,
                                                                            nestedChildAtt1?.show_option_value
                                                                        );
                                                                        // Update the value in the childAtt directly
                                                                        nestedChildAtt1.value =
                                                                            newValue;
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    )}
                                                    {nestedChildAtt1?.fieldtype === "short_text" && nestedChildAtt1?.is_repeatable === true && (
                                                        <>
                                                            <Box className="w-100 d-flex gap-2 justify-content-center align-items-center mb-2">
                                                                {nestedChildAtt1?.fieldtype !==
                                                                    "checkbox_single_choice" && (
                                                                        <>
                                                                            {/* <div className="extra-links-div att-assigned-label-box text-wrap"> */}
                                                                            <Box className="att-assigned-label-box-2 text-wrap">
                                                                                <Typography
                                                                                    className="fs-12-muted"
                                                                                >
                                                                                    {nestedChildAtt1?.label}{" "}
                                                                                    {/* {nestedChildAtt1?.is_mandatory ==
                                                                            true && (
                                                                                <span className="required">
                                                                                    *
                                                                                </span>
                                                                            )} */}
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box className="att-assigned-field-box-2">
                                                                                <Button
                                                                                    className="btn primary-btn"
                                                                                    onClick={() => {
                                                                                        handleAddNestedTextField(
                                                                                            attdata,
                                                                                            nestedChildAtt1?.nameValue,
                                                                                            nestedChildAtt1?.label,
                                                                                            attdata?.section_id,
                                                                                            nestedChildAtt1?.fieldtype,
                                                                                            nestedChildAtt1?.options,
                                                                                            nestedChildAtt1?.is_mandatory,
                                                                                            true, // Set repeatable attribute to true
                                                                                            nestedChildAtt1?.value, // Pass current value
                                                                                            parentAttData,
                                                                                            nestedChildAtt1?.show_option_value,
                                                                                            nestedChildAtt1?.max_repeat,
                                                                                            nestedChildAtt1
                                                                                        )
                                                                                    }
                                                                                    }
                                                                                >
                                                                                    <FontAwesomeIcon
                                                                                        icon={faPlus}
                                                                                    />
                                                                                    {`Add`}
                                                                                </Button>
                                                                            </Box>
                                                                            {/* </div> */}

                                                                        </>
                                                                    )}
                                                            </Box>
                                                            <div>
                                                                {additionalTextFieldsForNestedChildAtt1?.map((textField,
                                                                    index) => (
                                                                    <div key={index}>
                                                                        {textField?.nameValue === nestedChildAtt1?.nameValue && (
                                                                            <>
                                                                                <div className={`${nestedChildAtt1?.fieldtype === "link_object" ? 'add-title-div-2' : 'add-title-div'}`}>
                                                                                    {textField?.fieldtype === "short_text" ? (
                                                                                        <>
                                                                                            <div className="w-100">
                                                                                                <TextField
                                                                                                    className="input-field"
                                                                                                    fullWidth
                                                                                                    id="outlined-basic short summary"
                                                                                                    variant="outlined"
                                                                                                    name={textField?.nameValue}
                                                                                                    placeholder={`Enter ${textField?.label}...`}
                                                                                                    value={textField?.value}
                                                                                                    onChange={(
                                                                                                        event
                                                                                                    ) => {
                                                                                                        handleChangeForNestedChildAdditionalTextField(
                                                                                                            textField?.id,
                                                                                                            event
                                                                                                                ?.target
                                                                                                                ?.value,
                                                                                                            textField?.nameValue,
                                                                                                            textField?.section_id,
                                                                                                            textField?.fieldtype,
                                                                                                            "",
                                                                                                            attdata,
                                                                                                            parentAttData,
                                                                                                            textField?.show_option_value,
                                                                                                            textField
                                                                                                        )
                                                                                                    }
                                                                                                    }
                                                                                                />
                                                                                                {textField?.is_mandatory ===
                                                                                                    true &&
                                                                                                    textField?.value ===
                                                                                                    "" && (
                                                                                                        <p
                                                                                                            style={{
                                                                                                                color: "#d32f2f",
                                                                                                            }}
                                                                                                            className="error-msg"
                                                                                                        >
                                                                                                            {`${textField?.label} ${index +
                                                                                                                1} is required.`}
                                                                                                        </p>
                                                                                                    )}
                                                                                            </div>
                                                                                            <div className={`${nestedChildAtt1?.fieldtype === "link_object" && "add-title-div-btn-2"}`}>
                                                                                                <Button
                                                                                                    className="btn btn-danger btn-dd"
                                                                                                    onClick={() => removeNestedExtraFields(
                                                                                                        textField.id,
                                                                                                        textField.nameValue,
                                                                                                        textField.section_id,
                                                                                                        attdata,
                                                                                                        textField?.show_option_value,
                                                                                                        parentAttData
                                                                                                    )}
                                                                                                >
                                                                                                    <FontAwesomeIcon
                                                                                                        icon={faTimes} />
                                                                                                </Button>
                                                                                            </div>

                                                                                        </>
                                                                                    ) : ""}
                                                                                </div>

                                                                            </>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            );
                                        }
                                        return null;
                                    })}
                                </>
                            ) : null}
                        </React.Fragment>
                    )}

                </>
            ))}
        </>
    );
});

export default AllContentAndAttributeComponent;