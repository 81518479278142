import React, { useState, useEffect, useRef } from "react";
import { Stack, Image as BootstrapImage, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock, faPlus, faGears, faUsers, faStar, faUser, faEye, faArrowRight, faAngleUp, faHeart,
  faSmile,
  faAngry,
  faThumbsUp,
  faClock,
  faGavel,
  faComment as faCommentSolid,
  faFile,
  faVideo,
  faBell as faBellSolid,
  faComments,
  faGripVertical
} from "@fortawesome/free-solid-svg-icons";
import SiteLogo from "../../assets/images/logo.png";
import logoutHelper from "../../helpers/logoutHelper";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import api from "../common/commonFunctionForApi";
import Loader from "../common/loader";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box, Popover, Typography, Grid, List, ListItem, ListItemIcon, ListItemText, ListItemButton, Divider, Badge, Tabs,
  Tab,
  Card
} from "@mui/material";
import { drawerMenuAction } from "../../redux/slices/drawer/drawer";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Toaster } from "react-hot-toast";
import Pusher from "pusher-js";
import { faBell, faComment } from "@fortawesome/free-regular-svg-icons";
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { headerAction } from "../../redux/slices/header/header";
import { Toast } from "../../utils/toast";
import albertLogo from "../../assets/images/albert.png"
import notificationSound from "../../assets/sounds/notification_sound1.mp3"
import { conversationAction } from "../../redux/slices/conversation/conversation";
import { useLocation } from "react-router-dom";
import CryptoJS from 'crypto-js';
import noChatImg from "../../assets/images/noChatImg.png"
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import "../../../src/styles/header.scss"
import GlobalSearch from "../common/search/globalSearch";
import ChatSkleton from "../common/chat/ChatSkleton";
import FileSaver from "file-saver";
import QuickMessageInput from "../common/chat/QuickMessageInput";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()
  const chatContainerRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElForApps, setAnchorElForApps] = React.useState(null);
  const [anchorElForAvatar, setAnchorElForAvatar] = React.useState(null);
  const [anchorElForCreateContent, setAnchorElForCreateContent] = React.useState(null);
  const [anchorElForCreateContent2, setAnchorElForCreateContent2] = React.useState(null);
  const [anchorElForPlusContentIcon, setAnchorElForPlusContentIcon] = useState(null)
  const [anchorElForReaction, setAnchorElForReaction] = useState(null);
  const [anchorElForChatEdit, setAnchorElForChatEdit] = useState(null);
  const [anchorElForPopupUser, setAnchorElForPopupUser] = useState(null);

  const { pusherMessage, updatedPusherMessage, deletePusherMessage, reactionPusherMessage } = useSelector((state) => state?.header);

  const [chatUserList, setChatUserList] = useState([])
  const [selectedChatRoom, setSelectedChatRoom] = useState()
  const [selectedChatRoomData, setSelectedChatRoomData] = useState()
  const [isChatRooomLoading, setIsChatRoomLoading] = useState(false);

  const [typedMessage, setTypedMessage] = useState("")
  const [isTyping, setIsTyping] = useState(false)

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(selectedChatRoomData?.links?.next !== null);
  const [isLoadingMorePressed, setIsLoadingMorePressed] = useState(false)

  const [searchResults, setSearchResults] = useState([]);
  const [navigatedChatId, setNavigatedChatId] = useState(null);
  const [isSelectedEdit, setIsSelectedEdit] = useState(false)
  const [isEditableChat, setIsEditableChat] = useState(false)
  const [editChatMsg, setEditChatMsg] = useState()
  const [currentChatID, setCurrentChatID] = useState()
  const [isReacting, setIsReacting] = useState(false); // To track if a reaction is being selected
  const [reactionUsersList, setReactionUsersList] = useState([])

  const [notificationTabCount, setNotificationTabCount] = useState({ "all": 0, "must action": 0, "mention": 0 })
  const [borderOnSendMessage, setBorderOnSendMessage] = useState(false)

  //states for notifications
  const [selectedTab, setSelectedTab] = useState("must action");

  const { anchorElForChat, anchorElForNotification, notificationFetched, notificationData, pusherInitialized } = useSelector((state) => state?.header);
  const { unReadMsgCount } = useSelector((state) => state?.conversation);

  const [getAllTypes, setGetAllTypes] = useState([]);

  const [redirectionChatID, setRedirectionChatID] = useState()
  const [isRedirectionChatIDMissing, setISRedirectionChatIDMissing] = useState(false)


  let allMessages = [];

  const openMenu = Boolean(anchorEl);
  const openMenuForApps = Boolean(anchorElForApps);
  const openMenuForAvatar = Boolean(anchorElForAvatar);
  const openMenuForChat = Boolean(anchorElForChat);
  const openForReaction = Boolean(anchorElForReaction);
  const openMenuForChatEdit = Boolean(anchorElForChatEdit);
  const openMenuForNotification = Boolean(anchorElForNotification);

  const openMenuForCreateContent = Boolean(anchorElForCreateContent)
  const openMenuForCreateContent2 = Boolean(anchorElForCreateContent2)

  const openMenuForPlusContentIcon = Boolean(anchorElForPlusContentIcon)

  const openForPopupUser = Boolean(anchorElForPopupUser);

  const idForReaction = openForReaction ? "simple-popover" : undefined;
  const idForChatEdit = openMenuForChatEdit ? "simple-popover" : undefined;

  const { open, drawerVariant,
    adminAccessible } = useSelector((state) => state?.drawerMenu);

  const userToken = localStorage.getItem("accessToken");
  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);

  const jsonPusherData = localStorage.getItem("pusher")
  const pusherData = JSON.parse(jsonPusherData)

  const jsonTenantData = localStorage.getItem("tenant")
  const tenantData = JSON.parse(jsonTenantData)

  const selectedChatRoomID = localStorage.getItem("selected-chat-room-id")

  const devMode = process.env.REACT_APP_MODE
  let apiURL;

  if (devMode !== "DEV") {
    apiURL = `${window?.location?.protocol}//${window?.location?.hostname}/api/v1`;
  } else {
    apiURL = `${window?.location?.protocol}//${window?.location?.hostname}:8000/api/v1`;
  }

  const [selectedView, setSelectedView] = React.useState("")

  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const chunkedTypes = chunkArray(getAllTypes, 2);

  const idNotification = openMenuForNotification ? "simple-popover" : undefined;
  const id = openMenu ? "simple-popover" : undefined;
  const idApps = openMenuForApps ? "simple-popover" : undefined;
  const idAvatar = openMenuForAvatar ? "simple-popover" : undefined;
  const idChat = openMenuForChat ? "simple-popover" : undefined;

  const idCreateContent = openMenuForCreateContent ? "simple-popover" : undefined
  const idCreateContent2 = openMenuForCreateContent2 ? "simple-popover" : undefined

  const idPlusContentIcon = openMenuForPlusContentIcon ? "simple-popover" : undefined

  const onLogout = async () => {
    setLoading(true);
    localStorage.removeItem("selected-folder-item")
    localStorage.removeItem("selected-folder-type")

    const result = await logoutHelper(dispatch, navigate, userToken);
    if (result == true) {
      setLoading(false);
    }
  };

  const handleClickForDetails = () => {
    if (loggedUser?.user_type === "admin") {
      localStorage.setItem("last-redirect-page", `/`)
      navigate("/");
    } else {
      localStorage.setItem("last-redirect-page-user", `/dashboard`)
      navigate("/dashboard");
    }
  };

  const getMimeType = (extension) => {
    const mimeTypes = {
      'png': 'image/png',
      'jpg': 'image/jpeg',
      'jpeg': 'image/jpeg',
      'pdf': 'application/pdf',
      'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'mp4': 'video/mp4',
      'mp3': 'audio/mpeg'
    };
    return mimeTypes[extension] || 'application/octet-stream';
  };

  const downloadFile = async (file_path, type, title) => {
    var blob = new Blob([file_path], {
      type: getMimeType(type)
    });
    FileSaver.saveAs(blob, title);
  };

  useEffect(() => {
    async function updateNotification() {
      await updateNotificationSeenStatus();
      await fetchAllNotification();
    }
    //update the notification when there is unseen count
    if (anchorElForNotification !== null && notificationTabCount[selectedTab] > 0) {
      updateNotification();
    }
  }, [selectedTab])

  const updateNotificationSeenStatus = async () => {
    try {
      const response = await api.post(
        "user/notification/update-status",
        { "tab": selectedTab },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (!response?.success) {
        console.log("error while updating seen status::", response?.error);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("err`or in fetch news api::", err);
    }
  }

  const handleClickForNotification = async (event) => {
    handleCloseForAvatar();
    handleCloseForCreateContent();
    handleCloseForCreateContent2();
    handleCloseForPlusContentIcon();
    handleCloseForApps();

    if (anchorElForNotification == null) {
      dispatch(
        headerAction.setAnchorElForNotification(event.currentTarget)
      );

      if (notificationTabCount[selectedTab] > 0) {
        await updateNotificationSeenStatus();
      }
      await fetchAllNotification();

    } else {
      dispatch(headerAction.setAnchorElForNotification(null));
    }
  };

  const handleCloseForNotification = () => {
    dispatch(headerAction.setAnchorElForNotification(null));
  };

  const handleClickForApps = (event) => {
    handleCloseForAvatar();
    handleCloseForCreateContent();
    handleCloseForChat()
    if (anchorElForApps == null) {
      setAnchorElForApps(event.currentTarget);
    } else {
      setAnchorElForApps(null);
    }
  };

  const handleClickForChat = (event) => {
    handleCloseForAvatar();
    handleCloseForCreateContent();
    handleCloseForCreateContent2();
    handleCloseForPlusContentIcon()
    handleCloseForApps()

    if (anchorElForChat == null) {
      dispatch(headerAction.setAnchorElForChat(event.currentTarget));

    } else {
      dispatch(headerAction.setAnchorElForChat(null));

    }
  }

  const handleCloseForApps = () => {
    setAnchorElForApps(null);
  };

  const handleCloseForChat = () => {
    dispatch(headerAction.setAnchorElForChat(null));
  };

  const formatTypingMessageOutput = () => {
    // Extract user names from currentUserTypingData
    const typingUserNames = Object.values(currentUserTypingData)
      .filter(user => user.id !== loggedUser?.id) // Exclude the logged-in user
      .map(user => user.name);

    // Generate the typing message based on the number of users typing
    if (typingUserNames.length === 0) return "";

    if (typingUserNames.length === 1) {
      return `${typingUserNames[0]} is typing...`;
    } else if (typingUserNames.length === 2) {
      return `${typingUserNames[0]} and ${typingUserNames[1]} are typing...`;
    } else {
      return `${typingUserNames.slice(0, -1).join(', ')}, and ${typingUserNames[typingUserNames.length - 1]} are typing...`;
    }
  };

  const [userTypingMessageData, setUserTypingMessageData] = useState("");

  const handleClickForCreateContent = (event) => {
    handleCloseForPlusContentIcon()
    handleCloseForAvatar();
    handleCloseForApps()
    handleCloseForChat()
    if (anchorElForCreateContent == null) {
      setAnchorElForCreateContent(event.currentTarget);
    } else {
      setAnchorElForCreateContent(null);
    }
  };

  const handleClickForCreateContent2 = (event) => {
    handleCloseForPlusContentIcon()
    handleCloseForAvatar();
    handleCloseForApps()
    handleCloseForCreateContent()
    handleCloseForChat()
    if (anchorElForCreateContent2 == null) {
      setAnchorElForCreateContent2(event.currentTarget);
    } else {
      setAnchorElForCreateContent2(null);
    }
  };

  const handleCloseForCreateContent = () => {
    setAnchorElForCreateContent(null);
  };

  const handleCloseForCreateContent2 = () => {
    setAnchorElForCreateContent2(null);
  };

  const handleClickForPlusContentIcon = (event) => {
    handleCloseForAvatar();
    handleCloseForApps()
    handleCloseForCreateContent()
    handleCloseForCreateContent2()
    handleCloseForChat()
    if (anchorElForPlusContentIcon == null) {
      setAnchorElForPlusContentIcon(event.currentTarget);
    } else {
      setAnchorElForPlusContentIcon(null);
    }
  };

  const handleCloseForPlusContentIcon = () => {
    setAnchorElForPlusContentIcon(null);
  };

  const handleClickForAvatar = (event) => {
    handleCloseForApps();
    handleCloseForCreateContent();
    handleCloseForChat()
    if (anchorElForAvatar == null) {
      setAnchorElForAvatar(event.currentTarget);
    } else {
      setAnchorElForAvatar(null);
    }
  };

  const handleCloseForAvatar = () => {
    setAnchorElForAvatar(null);
  };

  const handleOpenDrawer = () => {
    if (drawerVariant === "permanent") {
      if (window.innerWidth <= 768) {
        if (open == true) {
          dispatch(drawerMenuAction.handleDrawerVariant("persistent"));
          dispatch(drawerMenuAction.handleDrawer(false));
        }
      }
      dispatch(drawerMenuAction.handleDrawer(open == true ? false : true));
    } else if (drawerVariant === "persistent") {
      dispatch(drawerMenuAction.handleDrawerVariant("permanent"));
      dispatch(drawerMenuAction.handleDrawer(true));
    }
  };

  const handleChange = (data) => {
    if (data == true) {
      localStorage.setItem("admin-access-view", true)
      // fetchUserMenuList()
      setSelectedView("view")
      dispatch(drawerMenuAction.handleSelectMenu("user"))
      dispatch(drawerMenuAction.handleAdminAccessible(true))
      navigate(
        localStorage?.getItem("last-redirect-page-admin-user")
          ? localStorage?.getItem("last-redirect-page-admin-user")
          : "/dashboard"
      );
    } else {
      localStorage.setItem("admin-access-view", false)
      setSelectedView("edit")
      dispatch(drawerMenuAction.handleSelectMenu("admin"))
      dispatch(drawerMenuAction.handleAdminAccessible(false))
      navigate(
        localStorage?.getItem("last-redirect-page")
          ? localStorage?.getItem("last-redirect-page")
          : "/"
      );
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert UNIX timestamp to milliseconds

    // Format the date as "30 Aug"
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });

    // Format the time as "12:45 pm"
    const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).toLowerCase();

    return `${day} ${month}, ${time}`;
  };

  // Filter notifications based on the selected tab
  const filteredNotifications = notificationData?.filter((notification) => {
    if (selectedTab === "all") return true;
    return notification?.tab === selectedTab;
  });

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent the default behavior of Enter (adding a new line)
      if (typedMessage.trim()) {
        sendMessage();
      }
    } else if (e.key === 'Enter' && e.shiftKey) {
      setBorderOnSendMessage(true)
      // Allow default behavior to add a new line when Shift+Enter is pressed
    }
  }

  function linkifyMessage(message) {
    // Extended regular expression to match URLs including those that start with www.
    const urlRegex = /((https?:\/\/|www\.)[^\s]+)/g;

    return message?.replace(urlRegex, (url, index) => {
      // Add 'http://' if the URL starts with 'www.'
      const href = url?.startsWith('www.') ? `http://${url}` : url;

      return `<a href="${href}" class="url-link" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  }

  const decryptData = (encData) => {
    // The APP_KEY in .env file. Note that it is base64 encoded binary
    var key = process.env.REACT_APP_DECRYPTION_KEY;

    // Laravel creates a JSON to store iv, value and a mac and base64 encodes it.
    // So let's base64 decode the string to get them.
    let decodedData = atob(encData);

    // Parse the decoded data as JSON
    let encrypted = JSON.parse(decodedData);

    // console.log("Laravel encryption result", encrypted);
    const iv = CryptoJS?.enc?.Base64?.parse(encrypted?.iv);
    const value = encrypted?.value;

    key = CryptoJS?.enc?.Base64?.parse(key);

    var decrypted = CryptoJS?.AES?.decrypt(value, key, {
      iv: iv
    });

    // CryptoJS returns a word array which can be
    // converted to string like this
    decrypted = decrypted?.toString(CryptoJS?.enc?.Utf8);

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  const handlePopoverOpen = (event, chatID, editable, message) => {

    setIsEditableChat(editable)
    if (editable == true) {
      setEditChatMsg(message)
    }
    setAnchorElForReaction(event.currentTarget);
    setCurrentChatID(chatID);
  };

  const groupMessagesByRelativeDate = (messages) => {
    const groupedMessages = {};
    messages?.forEach((message) => {
      const timestamp = message?.message_time;

      const messageDate = new Date(timestamp * 1000);

      if (isNaN(messageDate.getTime())) {
        console.error("Invalid Date for message_time:", timestamp);
        return; // Skip this message if the date is invalid
      }

      const today = new Date();
      const yesterday = new Date();
      yesterday?.setDate(today?.getDate() - 1);

      const startOfWeek = new Date(today);
      startOfWeek?.setDate(today?.getDate() - today?.getDay());

      const endOfWeek = new Date(today);
      endOfWeek?.setDate(today?.getDate() + (6 - today?.getDay()));

      const formatDate = (date) => {
        const options = { weekday: 'long', day: 'numeric', month: 'long' };
        return new Intl.DateTimeFormat('en-US', options)?.format(date);
      };

      const formatDateWithYear = (date) => {
        const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options)?.format(date);
      };

      let dateLabel;
      if (messageDate?.toDateString() === today?.toDateString()) {
        dateLabel = 'Today';
      } else if (messageDate?.toDateString() === yesterday?.toDateString()) {
        dateLabel = 'Yesterday';
      } else if (messageDate >= startOfWeek && messageDate <= endOfWeek) {
        dateLabel = formatDate(messageDate);
      } else if (messageDate?.getFullYear() === today?.getFullYear()) {
        dateLabel = formatDate(messageDate);
      } else {
        dateLabel = formatDateWithYear(messageDate);
      }

      const timeLabel = messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

      if (!groupedMessages[dateLabel]) {
        groupedMessages[dateLabel] = {};
      }

      if (!groupedMessages[dateLabel][timeLabel]) {
        groupedMessages[dateLabel][timeLabel] = [];
      }

      groupedMessages[dateLabel][timeLabel].push(message);
    });
    return groupedMessages;
  };

  const formatMessageTime = (timestamp) => {
    const messageDate = new Date(timestamp * 1000);
    const today = new Date();

    const isSameDay = (date1, date2) =>
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();

    if (isSameDay(messageDate, today)) {
      return messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else {
      return messageDate.toLocaleDateString();
    }
  };

  const replaceMessageById = (messages, newMessage) => {
    return messages.map((msg) => (msg.id === newMessage.id ? newMessage : msg));
  };

  const loadMoreMessages = async () => {
    if (!hasMoreMessages || isLoadingMore) return;

    setIsLoadingMore(true);
    setIsLoadingMorePressed(true)

    try {
      const response = await api.get(selectedChatRoomData?.links?.next, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (response?.success == true) {
        let newMessages = response?.response?.data;
        newMessages = newMessages.flat(Infinity);
        // console.log("newMessages:::", newMessages)
        const flattenedMessages = Object.values(selectedChatRoomData?.data)
          .flatMap(group => Object.values(group).flat(Infinity))

        // Add the new message to the array
        const updatedMessages = [...newMessages?.slice()?.reverse(), ...flattenedMessages];

        // Re-group messages
        const regroupedMessages = groupMessagesByRelativeDate(updatedMessages);

        setSelectedChatRoomData((prevState) => ({
          ...prevState,
          data: regroupedMessages,
          links: response?.response?.links,
        }));

        setHasMoreMessages(response?.response?.links?.next !== null);
      } else if (response?.success == false) {
        // Toast.error(response?.data?.message);
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.error("Error loading more messages:", err);
    } finally {
      setIsLoadingMore(false);
    }
  };

  // Function to generate a favicon with a red dot
  const generateRedDotFavicon = (baseIcon) => {
    const canvas = document.createElement('canvas');
    const img = new window.Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      // Draw a glowing red dot
      ctx.beginPath();
      ctx.arc(canvas.width - 10, 10, 6, 0, 2 * Math.PI, false);
      ctx.fillStyle = 'red';
      ctx.shadowColor = 'red';
      ctx.shadowBlur = 10;
      ctx.fill();

      // Update the favicon
      const favicon = document.querySelector('link[rel="icon"]');
      favicon.href = canvas.toDataURL('image/png');
    };

    img.onerror = (error) => {
      console.error("Failed to load image:", error);
    };

    img.src = baseIcon;

    // Return a placeholder or the original favicon until the image is loaded
    return img.src;
  };

  const audioRef = useRef(null);// Store audio object to control playback
  const intervalRef = useRef(null);
  const [activeSenders, setActiveSenders] = useState(new Set());
  const originalTitle = 'Platform';
  const originalFavicon = albertLogo;

  // Function to manage flashy notifications
  const handleTabInactiveNotification = async () => {
    // Function to clear existing intervals
    const clearExistingInterval = async () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };

    if (document.visibilityState !== 'visible') {

      if (pusherMessage?.type == "private") {
        activeSenders.add(`${pusherMessage?.sender_detail?.full_name}`);
      } else if (pusherMessage?.type == "group") {
        activeSenders.add(`${pusherMessage?.group_name}`);
      }

      activeSenders.add('Platform');
      // Clear previous interval before setting a new one
      await clearExistingInterval();

      // Function to handle blinking title with multiple senders
      let senderIndex = 0;
      const senderNames = Array.from(activeSenders);

      intervalRef.current = setInterval(() => {
        document.title = senderNames[senderIndex];
        // document.title = document.title === originalTitle ? senderNames[senderIndex] : originalTitle;
        senderIndex = (senderIndex + 1) % senderNames.length; // Cycle through sender names
      }, 1000);

      if (!audioRef.current) {
        audioRef.current = new Audio(notificationSound);
      }
      audioRef.current.play().catch((err) => {
        console.log("audio err::", err);
        console.warn('Audio playback failed due to browser restrictions or no user interaction.');
      });

      // Change the favicon to a glowing red dot favicon
      const faviconElement = document.querySelector("link[rel~='icon']");
      const redDotFavicon = generateRedDotFavicon(albertLogo); // Function to generate red dot favicon
      faviconElement.href = redDotFavicon;

      // Show a desktop notification (optional)
      if (Notification.permission === 'granted') {
        new Notification(`New message from ${pusherMessage?.sender_detail?.full_name}`, {
          body: pusherMessage?.message ? renderLastMessage(pusherMessage?.message) : (pusherMessage?.media_id ? "Sent an attachment" : "Recieved message"),
          icon: albertLogo
        });
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            new Notification(`New message from ${pusherMessage?.sender_detail?.full_name}`, {
              body: pusherMessage?.message ? renderLastMessage(pusherMessage?.message) : (pusherMessage?.media_id ? "Sent an attachment" : "Recieved message"),
              icon: albertLogo
            });
          }
        });
      }
    }

    const revertChanges = () => {
      if (document.visibilityState === 'visible') {
        // Clear the blinking title interval
        clearExistingInterval();

        // Revert the title and favicon
        document.title = originalTitle;

        const faviconElement = document.querySelector("link[rel~='icon']");
        faviconElement.href = originalFavicon;

        // Reset active senders
        activeSenders.clear();

        // Remove the event listener since it's no longer needed
        document.removeEventListener('visibilitychange', revertChanges);
      }
    };

    // Add an event listener to detect when the tab becomes active again
    document.addEventListener('visibilitychange', revertChanges);

  };

  //This function is used to handle web notification if user will get any notification
  const handleTabInactiveForNotification = (data) => {
    const originalTitle = document.title;
    const originalFavicon = document.querySelector("link[rel~='icon']").href;

    if (document.visibilityState !== 'visible') {

      document.title = 'New notification - Platform';

      if (!audioRef.current) {
        audioRef.current = new Audio(notificationSound);
      }
      audioRef.current.play().catch(() => {
        console.warn('Audio playback failed due to browser restrictions or no user interaction.');
      });

      // Change the favicon to a glowing red dot favicon
      const faviconElement = document.querySelector("link[rel~='icon']");
      const redDotFavicon = generateRedDotFavicon(albertLogo); // Function to generate red dot favicon
      faviconElement.href = redDotFavicon;

      // Show a desktop notification (optional)
      if (Notification.permission === 'granted') {
        new Notification(`New notification received`, {
          body: (data?.title).replace(/<\/?[^>]+(>|$)/g, ""),
          icon: albertLogo
        });
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            new Notification(`New notification received`, {
              body: (data?.title).replace(/<\/?[^>]+(>|$)/g, ""),
              icon: albertLogo
            });
          }
        });
      }
    }

    const revertChanges = () => {
      if (document.visibilityState === 'visible') {
        // Revert the title and favicon
        document.title = originalTitle;
        const faviconElement = document.querySelector("link[rel~='icon']");
        faviconElement.href = originalFavicon;

        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0; // Reset to start
          audioRef.current = null; // Cleanup audio object
        }

        // Remove the event listener since it's no longer needed
        document.removeEventListener('visibilitychange', revertChanges);
      }
    };

    // Add an event listener to detect when the tab becomes active again
    document.addEventListener('visibilitychange', revertChanges);
  }

  const startTypingData = async () => {
    let data = {
      chat_room_id: selectedChatRoom
    }
    try {
      setLoading(false);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(`user/conversation/user-typing/`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setLoading(false);
      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in start typing::", err);
      setLoading(false);

      if (err?.response?.status == 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 422) {
        setLoading(false);

        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
    }
  }

  const stopTypingData = async () => {
    let data = {
      chat_room_id: selectedChatRoom
    }
    try {
      setLoading(false);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(`user/conversation/stopped-typing/`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setLoading(false);
      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      setLoading(false);

      if (err?.response?.status == 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 422) {
        setLoading(false);

        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
    }
  }

  const manageChatData = () => {
    let isChatRoomExist = chatUserList?.find((chatuserlist) => chatuserlist?.chat_room_id == pusherMessage?.chat_room_id)

    if (openMenuForChat == false) {
      if (anchorElForChat == null &&
        (location.pathname != "/chat" && location.pathname != "/chat")) {
        dispatch(conversationAction?.setUnReadMessageCount(unReadMsgCount + 1))
      }
    } else {
      // console.log("isChatRoomExist::", isChatRoomExist)
      if (selectedChatRoom == pusherMessage?.chat_room_id && isChatRoomExist) {
        const newMessage = pusherMessage;
        const flattenedMessages = Object?.values(selectedChatRoomData?.data)
          ?.flatMap(group => Object?.values(group).flat());

        // Add the new message to the array
        const updatedMessages = [...flattenedMessages, newMessage];

        // Re-group messages
        const regroupedMessages = groupMessagesByRelativeDate(updatedMessages);

        // Update your state or data structure here
        setSelectedChatRoomData({
          ...selectedChatRoomData,
          data: regroupedMessages
        });

        if (pusherMessage?.message === "" && pusherMessage?.media_id !== null) {
          if (pusherMessage?.media_type === "png" || pusherMessage?.media_type === "jpg" || pusherMessage?.media_type === "jpeg") {
            setChatUserList(prevState => {
              const updatedList = prevState.map(chatUser => {
                if (chatUser?.chat_room_id === selectedChatRoom) {
                  return {
                    ...chatUser,
                    last_message_sender: pusherMessage?.sender_detail?.full_name,
                    last_message: "Sent an attachment",
                    message_time: pusherMessage?.message_time,
                    last_message_id: pusherMessage?.id,
                  };
                }
                return chatUser;
              });

              // Move the updated chat room to the top
              const chatUserIndex = updatedList.findIndex(chatUser => chatUser?.chat_room_id === pusherMessage?.chat_room_id);
              if (chatUserIndex > -1) {
                const [updatedChatUser] = updatedList.splice(chatUserIndex, 1);
                updatedList.unshift(updatedChatUser);
              }

              return updatedList;
            });
          } else if (pusherMessage?.media_type === "mp4") {
            setChatUserList(prevState => {
              const updatedList = prevState.map(chatUser => {
                if (chatUser?.chat_room_id === selectedChatRoom) {
                  return {
                    ...chatUser,
                    last_message_sender: pusherMessage?.sender_detail?.full_name,
                    last_message: "Sent an attachment",
                    message_time: pusherMessage?.message_time,
                    last_message_id: pusherMessage?.id,
                  };
                }
                return chatUser;
              });

              // Move the updated chat room to the top
              const chatUserIndex = updatedList.findIndex(chatUser => chatUser?.chat_room_id === pusherMessage?.chat_room_id);
              if (chatUserIndex > -1) {
                const [updatedChatUser] = updatedList.splice(chatUserIndex, 1);
                updatedList.unshift(updatedChatUser);
              }

              return updatedList;
            });
          } else {
            setChatUserList(prevState => {
              const updatedList = prevState.map(chatUser => {
                if (chatUser?.chat_room_id === selectedChatRoom) {
                  return {
                    ...chatUser,
                    last_message_sender: pusherMessage?.sender_detail?.full_name,
                    last_message: "Sent an attachment",
                    message_time: pusherMessage?.message_time,
                    last_message_id: pusherMessage?.id,
                  };
                }
                return chatUser;
              });

              // Move the updated chat room to the top
              const chatUserIndex = updatedList.findIndex(chatUser => chatUser?.chat_room_id === pusherMessage?.chat_room_id);
              if (chatUserIndex > -1) {
                const [updatedChatUser] = updatedList.splice(chatUserIndex, 1);
                updatedList.unshift(updatedChatUser);
              }

              return updatedList;
            });
          }
        } else {
          setChatUserList(prevState => {
            const updatedList = prevState.map(chatUser => {
              if (chatUser?.chat_room_id === selectedChatRoom) {
                return {
                  ...chatUser,
                  last_message_sender: pusherMessage?.sender_detail?.full_name,
                  last_message: pusherMessage?.message,
                  message_time: pusherMessage?.message_time,
                  last_message_id: pusherMessage?.id,
                };
              }
              return chatUser;
            });

            // Move the updated chat room to the top
            const chatUserIndex = updatedList.findIndex(chatUser => chatUser?.chat_room_id === pusherMessage?.chat_room_id);
            if (chatUserIndex > -1) {
              const [updatedChatUser] = updatedList.splice(chatUserIndex, 1);
              updatedList.unshift(updatedChatUser);
            }

            return updatedList;
          });
        }
        if (
          anchorElForChat == null &&
          (location.pathname != "/chat" && location.pathname != "/chat")
        ) {
          dispatch(conversationAction?.setUnReadMessageCount(unReadMsgCount + 1));
        }
      }

      else if (selectedChatRoom != pusherMessage?.chat_room_id && isChatRoomExist) {
        const newMessage = pusherMessage;

        if (pusherMessage?.message === "" && pusherMessage?.media_id !== null) {
          if (pusherMessage?.media_type === "png" || pusherMessage?.media_type === "jpg" || pusherMessage?.media_type === "jpeg") {
            setChatUserList(prevState => {
              const updatedList = prevState.map(chatUser => {
                if (chatUser?.chat_room_id === pusherMessage?.chat_room_id) {
                  return {
                    ...chatUser,
                    last_message_sender: pusherMessage?.sender_detail?.full_name,
                    last_message: "Sent an attachment",
                    unread_count: chatUser?.chat_room_id == selectedChatRoom ? 0 : chatUser?.unread_count + 1,
                    message_time: pusherMessage?.message_time,
                    last_message_id: pusherMessage?.id,
                  };
                }
                return chatUser;
              });

              // Move the updated chat room to the top
              const chatUserIndex = updatedList.findIndex(chatUser => chatUser?.chat_room_id === pusherMessage?.chat_room_id);
              if (chatUserIndex > -1) {
                const [updatedChatUser] = updatedList.splice(chatUserIndex, 1);
                updatedList.unshift(updatedChatUser);
              }

              return updatedList;
            });
          } else if (pusherMessage?.media_type === "mp4") {
            setChatUserList(prevState => {
              const updatedList = prevState.map(chatUser => {
                if (chatUser?.chat_room_id === pusherMessage?.chat_room_id) {
                  return {
                    ...chatUser,
                    last_message_sender: pusherMessage?.sender_detail?.full_name,
                    last_message: "Sent an attachment",
                    unread_count: chatUser?.chat_room_id == selectedChatRoom ? 0 : chatUser?.unread_count + 1,
                    message_time: pusherMessage?.message_time,
                    last_message_id: pusherMessage?.id,
                  };
                }
                return chatUser;
              });

              // Move the updated chat room to the top
              const chatUserIndex = updatedList.findIndex(chatUser => chatUser?.chat_room_id === pusherMessage?.chat_room_id);
              if (chatUserIndex > -1) {
                const [updatedChatUser] = updatedList.splice(chatUserIndex, 1);
                updatedList.unshift(updatedChatUser);
              }

              return updatedList;
            });
          } else {
            setChatUserList(prevState => {
              const updatedList = prevState.map(chatUser => {
                if (chatUser?.chat_room_id === pusherMessage?.chat_room_id) {
                  return {
                    ...chatUser,
                    last_message_sender: pusherMessage?.sender_detail?.full_name,
                    last_message: "Sent an attachment",
                    message_time: pusherMessage?.message_time,
                    unread_count: chatUser?.chat_room_id == selectedChatRoom ? 0 : chatUser?.unread_count + 1,
                    last_message_id: pusherMessage?.id,
                  };
                }
                return chatUser;
              });

              // Move the updated chat room to the top
              const chatUserIndex = updatedList.findIndex(chatUser => chatUser?.chat_room_id === pusherMessage?.chat_room_id);
              if (chatUserIndex > -1) {
                const [updatedChatUser] = updatedList.splice(chatUserIndex, 1);
                updatedList.unshift(updatedChatUser);
              }

              return updatedList;
            });
          }
        } else {
          setChatUserList(prevState => {
            const updatedList = prevState.map(chatUser => {
              if (chatUser?.chat_room_id === pusherMessage?.chat_room_id) {
                return {
                  ...chatUser,
                  last_message_sender: pusherMessage?.sender_detail?.full_name,
                  last_message: pusherMessage?.message,
                  message_time: pusherMessage?.message_time,
                  unread_count: chatUser?.chat_room_id == selectedChatRoom ? 0 : chatUser?.unread_count + 1,
                  last_message_id: pusherMessage?.id,
                };
              }
              return chatUser;
            });

            // Move the updated chat room to the top
            const chatUserIndex = updatedList.findIndex(chatUser => chatUser?.chat_room_id === pusherMessage?.chat_room_id);
            if (chatUserIndex > -1) {
              const [updatedChatUser] = updatedList.splice(chatUserIndex, 1);
              updatedList.unshift(updatedChatUser);
            }

            return updatedList;
          });
        }

        if (anchorElForChat == null &&
          (location.pathname != "/chat" && location.pathname != "/chat")) {
          dispatch(conversationAction?.setUnReadMessageCount(unReadMsgCount + 1))

        }

      } else if (!isChatRoomExist && pusherMessage?.message != null) {
        fetchAllChatRoomList()
        if (anchorElForChat == null &&
          (location.pathname != "/chat" && location.pathname != "/chat")) {
          dispatch(conversationAction?.setUnReadMessageCount(unReadMsgCount + 1))
        }
      }
    }

  }

  const ManageChatRoomData = async (chatRoom) => {
    try {
      // setLoading(true);
      setIsChatRoomLoading(true);

      const response = await api.get(
        `user/conversation/message-list/${chatRoom}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success == true) {
        const chatRoomData = {
          data: groupMessagesByRelativeDate(response?.response?.data?.slice()?.reverse()),
          links: response?.response?.links,
          meta: response?.response?.meta,
          room_details: response?.response?.room_details
        }
        // Toast.success(response?.message);
        setIsChatRoomLoading(false);

        setLoading(false);
        setSelectedChatRoomData(chatRoomData)
        setHasMoreMessages(response?.response?.links?.next !== null);
        fetchUnreadMsgCount()
        setIsLoadingMorePressed(false)

      } else if (response?.success == false) {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 422) {
        setLoading(false);

        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    } finally {
      setIsChatRoomLoading(false);
    }
  }

  const manageUpdatedChatData = () => {
    let isChatRoomExist = chatUserList?.find((chatuserlist) => chatuserlist?.chat_room_id == updatedPusherMessage?.chat_room_id)
    // console.log("isChatRoomExist::", isChatRoomExist)
    if (selectedChatRoom == updatedPusherMessage?.chat_room_id && isChatRoomExist) {
      const updatedMessages = replaceMessageById(Object.values(selectedChatRoomData?.data)
        .flatMap(group => Object.values(group).flat()), updatedPusherMessage);

      const groupedMessages = groupMessagesByRelativeDate(updatedMessages);

      setSelectedChatRoomData((prevState) => ({
        ...prevState,
        data: groupedMessages,
      }));

      // If you also store messages separately in `selectedChatRoomDataOnly`

      if (updatedPusherMessage?.message === "" && updatedPusherMessage?.media_id !== null) {
        if (updatedPusherMessage?.media_type === "png" || updatedPusherMessage?.media_type === "jpg" || updatedPusherMessage?.media_type === "jpeg") {
          setChatUserList(prevState => {
            const updatedList = prevState.map(chatUser => {
              if (chatUser?.chat_room_id === selectedChatRoom && chatUser?.last_message_id == updatedPusherMessage?.id) {
                return {
                  ...chatUser,
                  last_message_sender: updatedPusherMessage?.sender_detail?.full_name,
                  last_message: "Sent an attachment",
                  message_time: updatedPusherMessage?.message_time,
                };
              }
              return chatUser;
            });

            return updatedList;
          });
        } else if (updatedPusherMessage?.media_type === "mp4") {
          setChatUserList(prevState => {
            const updatedList = prevState.map(chatUser => {
              if (chatUser?.chat_room_id === selectedChatRoom && chatUser?.last_message_id == updatedPusherMessage?.id) {
                return {
                  ...chatUser,
                  last_message_sender: updatedPusherMessage?.sender_detail?.full_name,
                  last_message: "Sent an attachment",
                  message_time: updatedPusherMessage?.message_time,
                };
              }
              return chatUser;
            });

            return updatedList;
          });
        } else {
          setChatUserList(prevState => {
            const updatedList = prevState.map(chatUser => {
              if (chatUser?.chat_room_id === selectedChatRoom && chatUser?.last_message_id == updatedPusherMessage?.id) {
                return {
                  ...chatUser,
                  last_message_sender: updatedPusherMessage?.sender_detail?.full_name,
                  last_message: "Sent an attachment",
                  message_time: updatedPusherMessage?.message_time,
                };
              }
              return chatUser;
            });
            return updatedList;
          });
        }
      } else {
        setChatUserList(prevState => {
          const updatedList = prevState.map(chatUser => {
            if (chatUser?.chat_room_id === selectedChatRoom && chatUser?.last_message_id == updatedPusherMessage?.id) {
              return {
                ...chatUser,
                last_message_sender: updatedPusherMessage?.sender_detail?.full_name,
                last_message: updatedPusherMessage?.message,
                message_time: updatedPusherMessage?.message_time,
              };
            }
            return chatUser;
          });

          return updatedList;
        });
      }
    }
    else if (selectedChatRoom != updatedPusherMessage?.chat_room_id && isChatRoomExist) {
      const newMessage = pusherMessage;

      if (updatedPusherMessage?.message === "" && updatedPusherMessage?.media_id !== null) {
        if (updatedPusherMessage?.media_type === "png" || updatedPusherMessage?.media_type === "jpg" || updatedPusherMessage?.media_type === "jpeg") {
          setChatUserList(prevState => {
            const updatedList = prevState.map(chatUser => {
              if (chatUser?.chat_room_id === updatedPusherMessage?.chat_room_id && chatUser?.last_message_id == updatedPusherMessage?.id) {
                return {
                  ...chatUser,
                  last_message_sender: updatedPusherMessage?.sender_detail?.full_name,
                  last_message: "Sent an attachment",
                  message_time: updatedPusherMessage?.message_time,
                };
              }
              return chatUser;
            });

            return updatedList;
          });
        } else if (updatedPusherMessage?.media_type === "mp4") {
          setChatUserList(prevState => {
            const updatedList = prevState.map(chatUser => {
              if (chatUser?.chat_room_id === updatedPusherMessage?.chat_room_id && chatUser?.last_message_id == updatedPusherMessage?.id) {
                return {
                  ...chatUser,
                  last_message_sender: updatedPusherMessage?.sender_detail?.full_name,
                  last_message: "Sent an attachment",
                  message_time: updatedPusherMessage?.message_time,
                };
              }
              return chatUser;
            });

            return updatedList;
          });
        } else {
          setChatUserList(prevState => {
            const updatedList = prevState.map(chatUser => {
              if (chatUser?.chat_room_id === updatedPusherMessage?.chat_room_id && chatUser?.last_message_id == updatedPusherMessage?.id) {
                return {
                  ...chatUser,
                  last_message_sender: updatedPusherMessage?.sender_detail?.full_name,
                  last_message: "Sent an attachment",
                  message_time: updatedPusherMessage?.message_time,
                };
              }
              return chatUser;
            });

            return updatedList;
          });
        }
      } else {
        setChatUserList(prevState => {
          const updatedList = prevState.map(chatUser => {
            if (chatUser?.chat_room_id === updatedPusherMessage?.chat_room_id && chatUser?.last_message_id == updatedPusherMessage?.id) {
              return {
                ...chatUser,
                last_message_sender: updatedPusherMessage?.sender_detail?.full_name,
                last_message: updatedPusherMessage?.message,
                message_time: updatedPusherMessage?.message_time,
              };
            }
            return chatUser;
          });

          return updatedList;
        });
      }


    }
  }

  const manageRemoveChat = () => {
    let isChatRoomExist = chatUserList?.find((chatuserlist) => chatuserlist?.chat_room_id == deletePusherMessage?.chat_room_id)
    // console.log("isChatRoomExist::", isChatRoomExist)
    if (selectedChatRoom == deletePusherMessage?.chat_room_id && isChatRoomExist) {

      const filteredMessages = Object.values(selectedChatRoomData?.data)
        .flatMap(group => Object.values(group).flat())
        .filter((msg) => msg?.id !== deletePusherMessage?.id);

      // Regroup the messages by relative date after filtering
      const groupedMessages = groupMessagesByRelativeDate(filteredMessages);

      // Update the state with the filtered messages
      setSelectedChatRoomData((prevState) => ({
        ...prevState,
        data: groupedMessages,
      }));

      // Update the separate list if needed

      // Check if the deleted message was the last message
      const isLastMessage = isChatRoomExist?.last_message_id == deletePusherMessage?.id;
      if (isLastMessage) {
        const lastMessage = filteredMessages.length > 0 ? filteredMessages[filteredMessages.length - 1] : null;

        // Update the chat user list with the previous message as the last message
        setChatUserList((prevChatUserList) =>
          prevChatUserList.map((chatUser) => {
            if (chatUser?.chat_room_id == deletePusherMessage?.chat_room_id) {
              return {
                ...chatUser,
                last_message: lastMessage?.message || '',
                last_message_id: lastMessage?.id || '',
                last_message_sender: lastMessage?.sender_detail?.full_name || '',
                message_time: lastMessage?.message_time || '',
              };
            }
            return chatUser;
          })
        );
      }
      ManageChatRoomData(selectedChatRoom)

    }
    else if (selectedChatRoom != deletePusherMessage?.chat_room_id && isChatRoomExist) {
      const newMessage = deletePusherMessage;
    }
  }

  const manageReactionData = () => {
    let isChatRoomExist = chatUserList?.find((chatuserlist) => chatuserlist?.chat_room_id == reactionPusherMessage?.chat_room_id)
    if (selectedChatRoom == reactionPusherMessage?.chat_room_id && isChatRoomExist) {
      // Update the selectedChatRoomData
      setSelectedChatRoomData((prevData) => {
        // Create a copy of the previous data
        let updatedData = { ...prevData };

        // Iterate over the date groups
        for (let date in updatedData.data) {
          // Iterate over the time groups within the current date
          for (let time in updatedData.data[date]) {
            // Iterate over each message in the current time group
            updatedData.data[date][time] = updatedData.data[date][time].map((message) => {
              if (message.id == reactionPusherMessage?.id) {
                // Update the message with the new reaction data
                return {
                  ...message,
                  total_reaction: reactionPusherMessage?.total_reaction,
                  user_self_reaction: reactionPusherMessage?.user_self_reaction,
                  updated_time: reactionPusherMessage?.updated_time || message.updated_time,
                };
              }
              return message;
            });
          }
        }

        return updatedData;
      });
    }
  }

  const sendMessage = async () => {
    if (typedMessage === "") {
      return Toast.error("Please enter message!")
    }

    const tempId = `temp_${Math.random().toString(36).substring(2, 15)}`;
    const tempMessageTime = Math.floor(Date.now() / 1000);
    const tempMessage = {
      id: tempId,
      sender_id: loggedUser?.id, // Assuming `user` is the logged-in user info
      receiver_id: null,
      message: typedMessage,
      chat_room_id: selectedChatRoomData?.room_details?.chat_room_id,
      message_time: tempMessageTime,
      sender_detail: {
        id: loggedUser?.id,
        full_name: loggedUser?.name || "You", // Assuming `user` has `full_name`
        name: loggedUser?.name,
        last_name: loggedUser?.last_name,
        username: loggedUser?.username,
        thumbnail: loggedUser?.thumbnail || "" // Assuming `user` has `thumbnail`
      },
      media_id: null,
      receiver_detail: null,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      isSent: false, // Mark as unsent initially
    };

    let data = {
      chat_room_id: selectedChatRoomData?.room_details?.chat_room_id,
      receiver_id: selectedChatRoomData?.room_details?.user_id,
      message: typedMessage,
      type: selectedChatRoomData?.room_details?.type,
      temp_id: tempId
    }
    // console.log("tempMessage:::", tempMessage)

    // Flatten existing grouped messages
    const flattenedMessages = Object.values(selectedChatRoomData?.data)
      .flatMap(group => Object.values(group).flat());

    // Add the new message to the array
    const updatedMessages = [...flattenedMessages, tempMessage];

    // Re-group messages
    const regroupedMessages = groupMessagesByRelativeDate(updatedMessages);

    // Update your state or data structure here
    setSelectedChatRoomData({
      ...selectedChatRoomData,
      data: regroupedMessages
    });
    setTypedMessage("");
    if (isTyping) {
      setIsTyping(false)
    }
    try {
      setIsLoadingMorePressed(false)
      const response = await api.post(
        `user/conversation/send-message`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response?.success == true) {
        // Toast.success(response?.message);
        setLoading(false);
        const actualMessage = {
          ...response?.response?.data,
          isSent: true, // Mark as sent
        };

        const updatedMessagesWithActual = Object.values(selectedChatRoomData?.data).flat().map((msg) =>
          msg?.id == tempId ? actualMessage : msg
        );
        // console.log("updatedMessagesWithActual::", updatedMessagesWithActual)

        const flattenedMessages = Object.values(selectedChatRoomData?.data)
          .flatMap(group => Object.values(group).flat());

        // Add the new message to the array
        const updatedMessages = [...flattenedMessages, actualMessage];

        // Re-group messages
        const regroupedMessages = groupMessagesByRelativeDate(updatedMessages);

        // Update your state or data structure here
        setSelectedChatRoomData({
          ...selectedChatRoomData,
          data: regroupedMessages
        });

        setTypedMessage("")

        setChatUserList(prevState => {
          const updatedList = prevState.map(chatUser => {
            if (chatUser?.chat_room_id == selectedChatRoom) {
              return {
                ...chatUser,
                last_message_sender: loggedUser?.name,
                last_message: typedMessage,
                message_time: actualMessage?.message_time,
                last_message_id: actualMessage?.id
              };
            }
            return chatUser;
          });

          // Move the updated chat room to the top
          const chatUserIndex = updatedList.findIndex(chatUser => chatUser?.chat_room_id == selectedChatRoom);
          if (chatUserIndex > -1) {
            const [updatedChatUser] = updatedList.splice(chatUserIndex, 1);
            updatedList.unshift(updatedChatUser);
          }

          return updatedList;
        });

        setIsSelectedEdit(false)
        setCurrentChatID()
        setIsEditableChat(false)

      } else if (response?.success == false) {
        setLoading(false);
        if (response?.response?.data?.member_exist == false) {
          Toast.error("you are not member of this conversation!")
          setSelectedChatRoom(undefined)
          setSelectedChatRoomData()
          fetchAllChatRoomList()
        } else {
          Toast.error(response?.message);

        }
      }
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 422) {
        setLoading(false);

        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  }

  const fetchAllChatRoomList = async () => {
    try {
      // setLoading(true)
      const response = await api.post(`user/conversation/chat-user-list`, {}, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setLoading(false)
        setChatUserList(response?.response?.data)
        if (selectedChatRoom == undefined) {
          setSelectedChatRoom(response?.response?.data[0]?.chat_room_id)
          ManageChatRoomData(response?.response?.data[0]?.chat_room_id)
          setIsLoadingMorePressed(false)

          setChatUserList(prevState => prevState.map(chatUser => {
            if (chatUser?.chat_room_id == selectedChatRoom) {
              return {
                ...chatUser,
                unread_count: 0
              };
            }
            return chatUser;
          }));
        }
      }
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  }

  const fetchUnreadMsgCount = async () => {
    try {
      // setLoading(true)
      const response = await api.get(`user/conversation/unread-message-count`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setLoading(false)
        dispatch(conversationAction?.setUnReadMessageCount(response?.response?.unread_message_count))
      }
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  }

  const fetchAllTypes = async () => {
    try {
      const response = await api.get(`user/type/list/menu`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setGetAllTypes(response?.data);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
    }
  };

  const isMessageHighlighted = (chatId) => {
    return searchResults.includes(chatId);
  };

  const isMessageNavigated = (chatId) => {
    return navigatedChatId === chatId;
  };

  const handlePopoverCloseForDataOnly = () => {
    if (isSelectedEdit == false) {
      setIsEditableChat(false)
      setEditChatMsg("")
      setCurrentChatID()
      setIsReacting(false);
    }

  };

  const handlePopoverClose = () => {
    setAnchorElForReaction(null);
    setIsEditableChat(false)
    setEditChatMsg("")
    setIsReacting(false);
  };

  const toggleReaction = (id, reaction) => {
    handleReactionAPI(id, reaction)
  }

  const handlePopoverOpenForUserReactionList = (e, reactions) => {
    setReactionUsersList(reactions)
    setAnchorElForPopupUser(e?.currentTarget);
  }

  const handlePopoverCloseForUserReactionList = () => {
    setAnchorElForPopupUser(null);
    setReactionUsersList([])
  };

  const handleReactionAPI = async (id, reaction) => {
    let data = {
      reaction: reaction
    };
    try {
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(`user/conversation/message-reaction/${id}`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (response?.success === true) {
        const updatedMessage = response?.response?.data;

        setSelectedChatRoomData((prevData) => {
          // Create a copy of the previous data
          let updatedData = { ...prevData };

          // Iterate over the date groups
          for (let date in updatedData.data) {
            // Iterate over the time groups within the current date
            for (let time in updatedData.data[date]) {
              // Iterate over each message in the current time group
              updatedData.data[date][time] = updatedData.data[date][time].map((message) => {
                if (message.id === id) {
                  // Update the message with the new reaction data
                  return {
                    ...message,
                    total_reaction: updatedMessage?.total_reaction,
                    user_self_reaction: updatedMessage?.user_self_reaction,
                    updated_time: updatedMessage?.updated_time || message.updated_time,
                  };
                }
                return message;
              });
            }
          }
          return updatedData;
        });

        setIsLoadingMorePressed(true);
        setLoading(false);
        handlePopoverClose();
      }
      else {
        handlePopoverClose();
        setLoading(false);
        Toast.error(response?.response);
      }
    } catch (err) {
      console.log("error in handleReactionAPI::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      } else if (err?.response?.status === 422) {
        handlePopoverClose();
        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
    }
  };

  const fetchAllNotification = async () => {
    try {
      const response = await api.get("user/notification?page=1", {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        if (response?.response?.data?.length > 0) {
          // Extract new notifications
          const newNotifications = response?.response?.data;
          dispatch(headerAction.setNotificationData([...newNotifications]));
        } else {
          dispatch(headerAction.setNotificationData([]));
        }
      } else {
        Toast.error(response?.message)
      }
    } catch (err) {
      console.log("Error in fetch notification API:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateNotificationActionApi = async (notification_id, type) => {
    try {
      const response = await api.post(
        "user/notification/update-action",
        {
          "type": type,
          "id": notification_id
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success) {
        const notificationIndex = notificationData.findIndex(notification => notification.id === notification_id);
        if (notificationIndex !== -1) {
          const strippedTitle = (notificationData[notificationIndex].title).replace(/<\/?[^>]+(>|$)/g, "")
          const updatedNotification = {
            ...notificationData[notificationIndex],
            title: strippedTitle,
            action: true
          }
          const updatedNotificationData = [
            ...notificationData.slice(0, notificationIndex),
            updatedNotification,
            ...notificationData.slice(notificationIndex + 1),
          ];
          dispatch(headerAction.setNotificationData(updatedNotificationData));
        }
      } else {
        console.log("error while updating seen action::", response?.error);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("err updating seen action api::", err);
    }
  }

  const handleNotificationClick = (data) => {

    //Managed for notification type post
    if (['post', 'post_created', 'acknowledge_post', 'post_mention'].includes(data?.type)) {
      const type = data?.post_data?.type_name || 'News'
      handleCloseForNotification();
      navigate(`/user-type/view/${type}/${data?.type_id}`);
    } else if (['mention'].includes(data?.type)) {
      const type = data?.post_data?.type_name || 'News';
      const comment_id = data?.type_id;
      handleCloseForNotification();

      //If not action yet then call action update api
      if (!data?.action) {
        handleUpdateNotificationActionApi(data?.id, 'mention');
      }

      if (comment_id) {
        navigate(`/user-type/view/${type}/${data?.post_data?.post_id}?comment=${comment_id}`);
      } else {
        navigate(`/user-type/view/${type}/${data?.post_data?.post_id}`);
      }
    } else if (['mention_conversation'].includes(data?.type?.toLowerCase())) {
      const chatRoomId = data?.chat_details?.chat_room_id;
      const messageId = data?.chat_details?.message_id;
      if (chatRoomId && messageId) {
        handleCloseForNotification();

        if (!data?.action) {
          handleUpdateNotificationActionApi(data?.id, 'mention_conversation');
        }
        localStorage.setItem("selected-chat-room-id", chatRoomId);
        window.open(`/chat?chat_room=${chatRoomId}&chat_id=${messageId}`, '_blank')
      }
    }
  }


  const renderMessage = (message) => {

    const urlRegex = /((https?:\/\/|www\.)[^\s]+)/g;
    const mentionRegex = /@\[(.*?)\]\((.*?)\)/g;


    const messageWithLinks = message?.replace(urlRegex, (url) => {
      const href = url?.startsWith('www.') ? `http://${url}` : url;
      return `<a href="${href}" class="url-link" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });

    const parts = messageWithLinks?.split(mentionRegex);


    return parts?.map((part, index) => {
      if (index % 3 === 1) {
        // Mention text
        const mentionId = parts[index + 1]; // The ID from the mention
        return (
          <span key={index} className="mention-text cursor-pointer" id={`mention-user-${mentionId}`}>
            @{part}
          </span>
        );
      } else if (index % 3 === 2) {
        // Mention identifier (UserID) - we skip rendering this
        return null;
      } else {
        // Normal text
        return (
          <span key={index} dangerouslySetInnerHTML={{ __html: part }} />
        );
      }
    });
  };

  const renderLastMessage = (message) => {
    const mentionRegex = /@\[(.*?)\]\((.*?)\)/g;
    const replacedMessage = message.replace(mentionRegex, (match, name) => {
      return `@${name}`;
    });
    return replacedMessage;
  }

  const handleScrollToQuotedMessage = async (messageId) => {

    let targetMessage = document.getElementById(`chat-${messageId}`);
    if (targetMessage) {
      targetMessage.scrollIntoView({ behavior: 'smooth', block: 'center' });
      // Optionally, highlight the quoted message for better visibility
      targetMessage.classList.add('highlight-quoted-message');
      setTimeout(() => {
        targetMessage.classList.remove('highlight-quoted-message');
      }, 2000); // Remove highlight after 2 seconds
      setRedirectionChatID()
      setISRedirectionChatIDMissing(false)
    }
  };

  const loadMoreMessagesForRedirection = async () => {
    if (!redirectionChatID || isRedirectionChatIDMissing === false) return;

    try {
      const response = await api.get(selectedChatRoomData?.links?.next, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (response?.success === true) {
        let newMessages = response?.response?.data;
        newMessages = newMessages.flat(Infinity);

        const flattenedMessages = Object.values(selectedChatRoomData?.data)
          .flatMap(group => Object.values(group).flat(Infinity))

        // Add the new message to the array
        const updatedMessages = [...newMessages?.slice()?.reverse(), ...flattenedMessages];

        // Re-group messages
        const regroupedMessages = groupMessagesByRelativeDate(updatedMessages);

        setSelectedChatRoomData((prevState) => ({
          ...prevState,
          data: regroupedMessages,
          links: response?.response?.links,
        }));

        setHasMoreMessages(response?.response?.links?.next !== null);

        const isChatIdMissing = response?.response?.data?.filter((data) => data?.id === redirectionChatID)

        if (isChatIdMissing?.length < 1) {
          setISRedirectionChatIDMissing(true)
        } else {
          setISRedirectionChatIDMissing(false)
        }

      } else if (response?.success === false) {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.error("Error loading more messages:", err);
    } finally {
      setIsLoadingMore(false);
    }
  };


  useEffect(() => {
    console.log("notification header useEffect pusherInitialized value is ", pusherInitialized);

    if (pusherInitialized == false && userToken) {
      const decPusherAppCluster = decryptData(pusherData?.app_cluster)
      const decPusherApKey = decryptData(pusherData?.app_key)
      const pusherInstance = new Pusher(decPusherApKey, {
        cluster: decPusherAppCluster,
        auth: {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Accept': 'application/json',
          }
        },
        authEndpoint: `${apiURL}/broadcasting/auth`,
        channelAuthorization: {
          endpoint: `${apiURL}/broadcasting/auth`,
          transport: "ajax",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      });

      const channel = pusherInstance.subscribe(`private-chat-${tenantData?.tenant_id}-${loggedUser?.id}`);

      const channelUpdateMsg = pusherInstance.subscribe(`private-update-chat-${tenantData?.tenant_id}-${loggedUser?.id}`)

      const channelDeleteMsg = pusherInstance.subscribe(`private-delete-chat-${tenantData?.tenant_id}-${loggedUser?.id}`)

      const channelReaction = pusherInstance.subscribe(`private-message-reaction-${tenantData?.tenant_id}-${loggedUser?.id}`)

      channel.bind('pusher:subscription_succeeded', () => {
        console.log('Subscription succeeded');
      });
      channel.bind('pusher:subscription_failed', (data) => {
        console.error('Subscription failed:', data);
      });
      channel.bind('MessageSent', (data) => {
        const message = data?.data;
        console.log("msgs data::", message);
        dispatch(headerAction.setPusherMessage(message));
      });
      channel.bind('pusher:subscription_error', (status) => {
        console.error('Subscription error:', status);
      });

      channelUpdateMsg.bind("MessageUpdate", (data) => {
        console.log("data for update msg::", data)
        dispatch(headerAction.setUpdatedPusherMessage(data?.data));

      })

      channelDeleteMsg.bind("MessageDelete", (data) => {
        console.log("data for delete msg::", data)
        dispatch(headerAction.setDeletePusherMessage(data?.data));
      })

      channelReaction.bind("MessageReaction", (data) => {
        console.log("data for reaction msg::", data)
        dispatch(headerAction.setReactionPusherMessage(data?.data));
      })


      //Channels for notifications
      const channelNotification = pusherInstance.subscribe(`private-notification-${tenantData?.tenant_id}-${loggedUser?.id}`);

      const channelAllNotification = pusherInstance.subscribe(`private-notification-vendor-${tenantData?.tenant_id}-${loggedUser?.vendor_id}`);

      channelNotification.bind("pusher:subscription_succeeded", function () {
        console.log("notification Subscription succeeded");
      });

      channelNotification.bind("pusher:subscription_failed", function (data) {
        console.error("notification Subscription failed:", data);
      });

      channelNotification.bind("SendNotification", function (data) {

        console.log("personal Notification data recieved::", data?.data);
        dispatch(headerAction.setAppendNotificationData([
          data?.data,
        ]));
        handleTabInactiveForNotification(data?.data);
      });

      channelNotification.bind("pusher:subscription_error", function (status) {
        console.error("notification Subscription error:", status);
      });

      channelAllNotification.bind(
        "pusher:subscription_error",
        function (status) {
          console.error("all notification Subscription error:", status);
        }
      );

      channelAllNotification.bind(
        "pusher:subscription_succeeded",
        function () {
        }
      );

      channelAllNotification.bind(
        "pusher:subscription_failed",
        function (data) {
          console.error("all notifcation Subscription failed:", data);
        }
      );

      channelAllNotification.bind(
        "sendNotificationToAllUsers",
        function (data) {
          dispatch(headerAction.setAppendNotificationData([
            data?.data,
          ]));
          handleTabInactiveForNotification(data?.data);
        }
      );

      channelAllNotification.bind(
        "pusher:subscription_error",
        function (status) {
          console.error("all notification Subscription error:", status);
        }
      );

      dispatch(headerAction?.setPusherInitialized(true))

      return () => {
        pusherInstance.unsubscribe(`private-chat-${tenantData?.tenant_id}-${loggedUser?.id}`);
        pusherInstance.unsubscribe(`private-update-chat-${tenantData?.tenant_id}-${loggedUser?.id}`)
        pusherInstance.unsubscribe(`private-delete-chat-${tenantData?.tenant_id}-${loggedUser?.id}`)
        pusherInstance.unsubscribe(`private-message-reaction-${tenantData?.tenant_id}-${loggedUser?.id}`)
        pusherInstance.unsubscribe(`private-notification-${tenantData?.tenant_id}-${loggedUser?.id}`);
        pusherInstance.unsubscribe(`private-notification-vendor-${tenantData?.tenant_id}-${loggedUser?.vendor_id}`);

        pusherInstance.disconnect();
      };
    }
  }, [])

  useEffect(() => {
    //filter out the count on the basis of seen status
    const newNotifications = notificationData?.filter((notification) => notification?.seen === false) || [];

    //Here all is used to display the total count near to the bell icon in header
    setNotificationTabCount({
      "all": newNotifications?.length || 0,
      "must action": newNotifications?.filter((notification) => notification?.tab === "must action").length || 0,
      "mention": newNotifications?.filter((notification) => notification?.tab === "mention").length || 0,
    })
  }, [notificationData]);

  useEffect(() => {
    if (openMenuForChat == true) {
      setLoading(true)
      fetchAllChatRoomList()
      if (selectedChatRoom !== undefined) {
        ManageChatRoomData(selectedChatRoom)
        setChatUserList(prevState => prevState.map(chatUser => {
          if (chatUser?.chat_room_id == selectedChatRoom) {
            return {
              ...chatUser,
              unread_count: 0
            };
          }
          return chatUser;
        }));
      }
    }
  }, [openMenuForChat])

  useEffect(() => {

    fetchAllTypes();
    fetchUnreadMsgCount();

    if (!notificationFetched) {
      fetchAllNotification();
    }

    const adminAccessView = localStorage.getItem("admin-access-view")

    if (adminAccessView === "true") {
      setSelectedView("view");
      dispatch(drawerMenuAction.handleSelectMenu("user"))
      dispatch(drawerMenuAction.handleAdminAccessible(true))
    } else {
      setSelectedView("edit");
      dispatch(drawerMenuAction.handleSelectMenu("admin"))
      dispatch(drawerMenuAction.handleAdminAccessible(false))
    }

  }, []);

  const [currentUserTypingData, setCurrentUserTypingData] = useState({});

  useEffect(() => {
    console.log("presence current typing data", currentUserTypingData)
    setUserTypingMessageData(formatTypingMessageOutput());
  }, [currentUserTypingData])

  useEffect(() => {
    console.log("presence current typing message", userTypingMessageData)
  }, [userTypingMessageData]);

  useEffect(() => {
    if (selectedChatRoom !== undefined && openMenuForChat == true) {
      setCurrentUserTypingData({});

      setTypedMessage("")

      setIsLoadingMorePressed(false)
      ManageChatRoomData(selectedChatRoom)
      fetchUnreadMsgCount()
      setChatUserList(prevState => prevState.map(chatUser => {
        if (chatUser?.chat_room_id == selectedChatRoom) {
          return {
            ...chatUser,
            unread_count: 0
          };
        }
        return chatUser;
      }));
    }
  }, [selectedChatRoom]);

  useEffect(() => {
    console.log("quick chat selected chat room id before typing initaiat", selectedChatRoom);
    if (selectedChatRoom && userToken) {
      const decPusherAppCluster = decryptData(pusherData?.app_cluster)
      const decPusherApKey = decryptData(pusherData?.app_key)
      const pusherInstanceForTyping = new Pusher(decPusherApKey, {
        cluster: decPusherAppCluster,
        auth: {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Accept': 'application/json',
          }
        },
        authEndpoint: `${apiURL}/broadcasting/auth`,
        channelAuthorization: {
          endpoint: `${apiURL}/broadcasting/auth`,
          transport: "ajax",
          params: {
            is_typing: isTyping ?? false
          },
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      });

      const presenceTypingChannel = pusherInstanceForTyping?.subscribe(`presence-typing-${tenantData?.tenant_id}-${selectedChatRoomID || selectedChatRoom}`)
      console.log("presence ::", presenceTypingChannel);

      presenceTypingChannel.bind("pusher:subscription_succeeded", (members) => {
        console.log("presence succeeded::", members);
        const userData = members?.members;

        let typingUsers = {};

        // Iterate over the users and store the ones who are typing
        Object.values(userData).forEach((user) => {
          if (user?.is_typing === 1 && !currentUserTypingData[user?.id]) {
            typingUsers = {
              ...typingUsers,
              [user?.id]: user,  // Store the user data in the temporary object
            };
          }
        });

        // After iteration, set the state if there are new typing users
        if (Object.keys(typingUsers).length > 0) {
          setCurrentUserTypingData((prev) => ({
            ...prev,
            ...typingUsers,  // Append all new typing users to the state
          }));
        }
      }
      );
      presenceTypingChannel.bind("pusher:member_added", (member) => {
        console.log("presence member added::", member)
        let typingUsers = {};
        if (member?.info?.is_typing === 1) {
          typingUsers = { [member?.id]: member?.info }
        }
        if (Object.keys(typingUsers).length > 0) {
          setCurrentUserTypingData((prev) => ({
            ...prev,
            ...typingUsers,
          }));
        }
      }

      );

      presenceTypingChannel.bind("UserTyping", (member) => {
        console.log("presence userTyping added::", member)
        let typingUsers = {};
        if (member?.info?.is_typing === 1) {
          typingUsers = { [member?.id]: member?.info }
        }
        if (Object.keys(typingUsers).length > 0) {
          setCurrentUserTypingData((prev) => ({
            ...prev,
            ...typingUsers,
          }));
        }
      });

      presenceTypingChannel.bind("pusher:member_removed", (member) => {
        console.log("presence member removed::", member);
        setCurrentUserTypingData((prev) => {
          const updatedState = { ...prev };

          // Check if the member ID exists in the current state
          if (member?.id && updatedState[member?.id]) {
            // Remove the member from the state
            delete updatedState[member.id];
          }

          return updatedState; // Return the updated state
        });
      });

      presenceTypingChannel.bind("UserStoppedTyping", (member) => {
        console.log("presence userstopped type removed::", member);
        setCurrentUserTypingData((prev) => {
          const updatedState = { ...prev };

          // Check if the member ID exists in the current state
          if (member?.id && updatedState[member?.id]) {
            // Remove the member from the state
            delete updatedState[member.id];
          }

          return updatedState; // Return the updated state
        });
      });

      return () => {
        console.log('quick presence inide clenup useefect', pusherInstanceForTyping);
        console.log('quick presence inide clenup useefect selected chat room id is', selectedChatRoomID);
        pusherInstanceForTyping?.unsubscribe(`presence-typing-${tenantData?.tenant_id}-${selectedChatRoomID}`)
        pusherInstanceForTyping.disconnect();
      };

    }
  }, [selectedChatRoom]);

  const typingTimeoutNewRef = useRef(null);

  useEffect(() => {
    if (selectedChatRoom !== undefined && openMenuForChat == true) {
      if (isTyping) {
        startTypingData();
      } else {
        stopTypingData();
      }
    }
  }, [isTyping]);

  const handleTyping = () => {
    clearTimeout(typingTimeoutNewRef.current);
    if (!isTyping) {
      setIsTyping(true);
    }

    typingTimeoutNewRef.current = setTimeout(() => {

      setIsTyping(false);  // Set to false after a delay (e.g., 5 seconds of inactivity)

    }, 7000);
  };

  useEffect(() => {
    if (isLoadingMorePressed == false) {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
    }
  }, [selectedChatRoomData]);

  useEffect(() => {
    console.log("pusher msg:::", pusherMessage)
    if (pusherMessage !== null) {
      manageChatData()
      handleTabInactiveNotification();
    }
  }, [pusherMessage])

  useEffect(() => {
    if (updatedPusherMessage !== undefined) {
      console.log('New Updated Pusher message:', updatedPusherMessage);
      manageUpdatedChatData()
    }
  }, [updatedPusherMessage])

  useEffect(() => {
    if (deletePusherMessage !== undefined) {
      console.log('New Updated Pusher message:', updatedPusherMessage);
      manageRemoveChat()
    }
  }, [deletePusherMessage])

  useEffect(() => {
    if (reactionPusherMessage !== undefined) {
      console.log('New Updated reaction Pusher message:', reactionPusherMessage);
      manageReactionData()
    }
  }, [reactionPusherMessage])

  useEffect(() => {
    if (openMenuForChat == true) {
      const handleScroll = () => {
        if (chatContainerRef?.current) {
          const { scrollTop, scrollHeight, clientHeight } = chatContainerRef?.current;
          const scrollPosition = scrollTop / (scrollHeight - clientHeight);

          // Load more messages if scrolled within the top 30%
          if (scrollPosition <= 0.3 && !isLoadingMorePressed) {
            loadMoreMessages();
          }
        }
      };

      const chatContainer = chatContainerRef?.current;
      chatContainer?.addEventListener('scroll', handleScroll);

      return () => {
        chatContainer?.removeEventListener('scroll', handleScroll);
      };
    }

  }, [loadMoreMessages, isLoadingMorePressed, openMenuForChat]);

  useEffect(() => {
    if (openMenuForChat) {
      console.log("open menu for chat and selected room is::", selectedChatRoom);
    }
  }, [openMenuForChat]);

  useEffect(() => {
    if (redirectionChatID !== undefined) {
      let targetMessage = document.getElementById(`chat-${redirectionChatID}`);

      if (!targetMessage) {
        setISRedirectionChatIDMissing(true)
      } else {
        handleScrollToQuotedMessage(redirectionChatID)
      }

    }
  }, [redirectionChatID])

  useEffect(() => {
    if (isRedirectionChatIDMissing === true && redirectionChatID !== undefined) {
      let targetMessage = document.getElementById(`chat-${redirectionChatID}`);
      loadMoreMessagesForRedirection()
    } else {
      handleScrollToQuotedMessage(redirectionChatID)
    }
  }, [isRedirectionChatIDMissing])

  return (
    <>
      {/* START :: HEADER */}
      <div className="position-fixed w-100 bg-white header-wrapper">
        <Stack
          direction="horizontal"
          className="justify-content-between header-content gap-2"
        >
          <Box className="drawer-div d-flex justify-content-center align-items-center gap-3">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleOpenDrawer}
              edge="start"
              sx={{
                marginRight: 2,
                // ...(open && { display: "none" }),
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              }}
              className="sidebar-dreawer-btn"
            >
              <MenuIcon className="drawer-menu-icon" />
            </IconButton>
            <BootstrapImage
              src={SiteLogo}
              width={135}
              onClick={() => {
                if (loggedUser?.user_type === "admin" && adminAccessible == false) {
                  localStorage.setItem("last-redirect-page", `/`);
                } else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
                  localStorage.setItem("last-redirect-page-admin-user", `/dashboard`);
                } else {
                  localStorage.setItem("last-redirect-page-user", `/`);
                }
                navigate("/");
              }}
              style={{ cursor: "pointer" }}
            />

            {/* {(loggedUser?.user_type === "admin") && (
              <Box className="main-switch-div">
                <Box className="switch-div-2">
                  <Box className={`${selectedView === "edit" ? "icon-div-selected cursor-pointer" : "icon-div-2 cursor-pointer"} "cusror-pointer"`} onClick={() => handleChange(false)}>
                    <FontAwesomeIcon icon={faPen} className="icon-switch" />
                  </Box>
                  <Box className={`${selectedView === "view" ? "icon-div-selected cursor-pointer" : "icon-div-2 cursor-pointer"} "cursor-pointer"`} onClick={() => handleChange(true)}>
                    <FontAwesomeIcon icon={faEye} className="icon-switch" />
                  </Box>
                </Box>
              </Box>
            )} */}

          </Box>

          <GlobalSearch />

          <Stack direction="horizontal" className="last-stack">
            {/* <Button
              className="create-content-btn"
              aria-controls={openMenuForCreateContent ? "account-create-content" : undefined}
              aria-expanded={openMenuForCreateContent ? "true" : undefined}
              aria-describedby={idCreateContent}
              onClick={handleClickForCreateContent}
            >
              Create Content
              <FontAwesomeIcon icon={faPlus} />
            </Button> */}
            {notificationTabCount?.all > 0 ? (
              <IconButton
                onClick={handleClickForNotification}
                aria-controls={openMenuForNotification ? "account-menus" : undefined}
                aria-haspopup="true"
                className="notification-icon-btn"
                aria-expanded={openMenuForNotification ? "true" : undefined}
                aria-describedby={idNotification}
              >
                <Badge badgeContent={notificationTabCount?.all} color="error" anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                  className="chat-badge"
                >
                  {/* <BootstrapImage
                    src={notificationSvg}
                    style={{ cursor: "pointer" }}
                    className="notification-icon-btn"
                  /> */}
                  <FontAwesomeIcon icon={faBellSolid} />
                </Badge>
              </IconButton>
            ) : (
              <IconButton
                onClick={handleClickForNotification}
                aria-controls={openMenuForNotification ? "account-menus" : undefined}
                aria-haspopup="true"
                className="notification-icon-btn"
                aria-expanded={openMenuForNotification ? "true" : undefined}
                aria-describedby={idNotification}
              >
                {/* <BootstrapImage
                  src={notificationSvg}
                  style={{ cursor: "pointer" }}
                  className="notification-icon-btn"
                /> */}
                <FontAwesomeIcon icon={faBellSolid} className="notification-icon-btn" style={{ cursor: "pointer" }} />

              </IconButton>
            )}

            {unReadMsgCount > 0 ? (
              <IconButton
                onClick={handleClickForChat}
                aria-controls={openMenuForChat ? "account-menus" : undefined}
                aria-haspopup="true"
                className="chat-icon-btn"
                aria-expanded={openMenuForChat ? "true" : undefined}
                aria-describedby={idChat}
              >
                <Badge badgeContent={unReadMsgCount} color="error" anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                  className="chat-badge"
                >
                  {/* <BootstrapImage
                    src={chatSvg}
                    style={{ cursor: "pointer" }}
                    className="chat-icon-btn"
                  /> */}
                  <FontAwesomeIcon icon={faComments} className="chat-icon-btn" style={{ cursor: "pointer" }} />
                </Badge>
              </IconButton>
            ) : (
              <IconButton
                onClick={handleClickForChat}
                aria-controls={openMenuForChat ? "account-menus" : undefined}
                aria-haspopup="true"
                className="chat-icon-btn"
                aria-expanded={openMenuForChat ? "true" : undefined}
                aria-describedby={idChat}
              >
                {/* <BootstrapImage
                  src={chatSvg}
                  style={{ cursor: "pointer" }}
                  className="chat-icon-btn"
                /> */}
                <FontAwesomeIcon icon={faComments} className="chat-icon-btn" style={{ cursor: "pointer" }} />

              </IconButton>
            )}

            <IconButton
              onClick={handleClickForPlusContentIcon}
              aria-controls={openMenuForPlusContentIcon ? "account-menus" : undefined}
              aria-haspopup="true"
              className="plus-icon-btn"
              aria-expanded={openMenuForPlusContentIcon ? "true" : undefined}
              aria-describedby={idPlusContentIcon}
            >
              <FontAwesomeIcon icon={faPlus} style={{ cursor: "pointer" }} className="plus-icon-btn" />
            </IconButton>

            <IconButton
              onClick={handleClickForApps}
              aria-controls={openMenuForApps ? "account-menus" : undefined}
              aria-haspopup="true"
              className="avatar-menu-icon-btn"
              aria-expanded={openMenuForApps ? "true" : undefined}
              aria-describedby={idApps}
            >
              {/* <BootstrapImage
                src={menusvg}
                style={{ cursor: "pointer" }}
                className="avatar-menu-icon-btn"
              /> */}
              <FontAwesomeIcon icon={faGripVertical} className="avatar-menu-icon-btn" style={{ cursor: "pointer" }} />

            </IconButton>

            {/* <IconButton
              onClick={handleClickForAvatar}
              aria-controls={openMenuForAvatar ? "account-menu" : undefined}
              aria-haspopup="true"
              className="avatar-menu-icon-btn"
              aria-expanded={openMenuForAvatar ? "true" : undefined}
              aria-describedby={idAvatar}
            >
              <Avatar
                aria-label="recipe"
                className="avatar"
                src={loggedUser?.thumbnail}
              >
                {loggedUser?.name?.charAt(0)}
              </Avatar>
            </IconButton> */}
          </Stack>
        </Stack>
      </div>

      <Popover
        id={idApps}
        anchorReference="anchorPosition"
        open={openMenuForApps}
        anchorEl={anchorElForApps}
        onClose={handleCloseForApps}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorPosition={{ top: 100, left: 2000 }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box className="p-2 main-app-icon-div">
          <Box className="serach-box w-100" sx={{ padding: "15px", borderBottom: "1px solid #EEEEEE" }}>
            <InputGroup className="">
              <Form.Control
                placeholder="Search Apps.."
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                className="search-input w-100"
              />
              {/* <Button className="search-btn-dash-5">
                <FontAwesomeIcon icon={faSearch} />
              </Button> */}
            </InputGroup>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              className="main-grid"
            >
              {chunkedTypes?.map((typeData, index) => (
                <>
                  {typeData?.map((type, index) => (
                    <>
                      <Grid
                        item
                        xs={2}
                        sm={4}
                        md={4}
                        className="main-grid-item"
                      >
                        <Box className="main-box-class-2 cursor-pointer d-flex justify-content-center flex-column align-items-center gap-2"
                          sx={{
                            "&:hover": {
                              backgroundColor: "#F9F9F9", // Change this to your desired hover background color
                            },
                          }}
                          onClick={() => {
                            localStorage.removeItem("selected-folder-item")

                            dispatch(drawerMenuAction.clickDrawerItem(""));
                            dispatch(
                              drawerMenuAction.clickDrawerItem("user-type")
                            );
                            dispatch(
                              drawerMenuAction.handleNameCollapsable(false)
                            );
                            dispatch(drawerMenuAction.handleActiveType(true));
                            dispatch(
                              drawerMenuAction.handleTypesCollapsable(true)
                            );
                            dispatch(
                              drawerMenuAction.handleUsersCollapsable(false)
                            );

                            if (loggedUser?.user_type == "admin" && adminAccessible == false) {
                              localStorage.setItem(
                                "type-selected-name",
                                type?.name
                              );
                              localStorage.setItem(
                                "type-selected-id",
                                type?.id
                              );
                              localStorage.setItem(
                                "type-selected-slug",
                                type?.slug
                              );
                              handleCloseForApps();
                              handleCloseForAvatar();
                              handleCloseForCreateContent();
                              localStorage.setItem("last-redirect-page", `/type/${type?.slug}`)
                              navigate(`/type/${type?.slug}`);
                            }
                            else if (loggedUser?.user_type == "admin" && adminAccessible == true) {

                              localStorage.setItem(
                                "type-selected-name-admin-user",
                                type?.name
                              );
                              localStorage.setItem(
                                "type-selected-id-admin-user",
                                type?.id
                              );
                              localStorage.setItem(
                                "type-selected-slug-admin-user",
                                type?.slug
                              );
                              handleCloseForApps();
                              handleCloseForAvatar();
                              handleCloseForCreateContent();
                              localStorage.setItem("last-redirect-page-admin-user", `/user-type/${type?.slug}`)
                              navigate(`/user-type/${type?.slug}`);
                            }
                            else {
                              localStorage.setItem(
                                "type-selected-name-user",
                                type?.name
                              );
                              localStorage.setItem(
                                "type-selected-id-user",
                                type?.id
                              );
                              localStorage.setItem(
                                "type-selected-slug-user",
                                type?.slug
                              );
                              handleCloseForApps();
                              handleCloseForAvatar();
                              handleCloseForCreateContent();
                              localStorage.setItem("last-redirect-page-user", `/user-type/${type?.slug}`)
                              navigate(`/user-type/${type?.slug}`);
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={type?.icon} className="fs-20-660C60" sx={{
                            "&:hover": {
                              color: "#660C60", // Change this to your desired hover background color
                            },
                          }} />
                          <Typography className="fs-14-op-50" sx={{
                            "&:hover": {
                              color: "#333333", // Change this to your desired hover background color
                            },
                          }}>{type?.name}</Typography>
                        </Box>
                      </Grid>
                    </>
                  ))}
                </>
              ))}
              {(loggedUser?.user_type === "user" || adminAccessible == true) && (
                <Grid
                  item
                  xs={2}
                  sm={4}
                  md={4}
                  className="main-grid-item"
                >
                  <Box className="main-box-class-2 cursor-pointer d-flex justify-content-center flex-column align-items-center gap-2"
                    sx={{
                      "&:hover": {
                        backgroundColor: "#F9F9F9", // Change this to your desired hover background color
                      },
                    }}
                    onClick={() => {
                      localStorage.removeItem("selected-folder-item")

                      dispatch(drawerMenuAction.clickDrawerItem(""));
                      dispatch(
                        drawerMenuAction.clickDrawerItem("user-type")
                      );
                      dispatch(
                        drawerMenuAction.handleNameCollapsable(false)
                      );
                      dispatch(drawerMenuAction.handleActiveType(true));
                      dispatch(
                        drawerMenuAction.handleTypesCollapsable(true)
                      );
                      dispatch(
                        drawerMenuAction.handleUsersCollapsable(false)
                      );
                      handleCloseForApps();
                      handleCloseForAvatar();
                      handleCloseForCreateContent();
                      if (loggedUser?.user_type === "admin" && adminAccessible == true) {
                        localStorage.setItem(
                          "type-selected-name-admin-user",
                          "People"
                        );
                        localStorage.setItem(
                          "type-selected-id-admin-user",
                          "people"
                        );
                        localStorage.setItem("last-redirect-page-admin-user", `/user-type/people`)
                      }

                      else {
                        localStorage.setItem(
                          "type-selected-name-user",
                          "People"
                        );
                        localStorage.setItem(
                          "type-selected-id-user",
                          "people"
                        );
                        localStorage.setItem(
                          "type-selected-slug-user",
                          "people"
                        );
                        localStorage.setItem("last-redirect-page-user", `/user-type/people`)
                      }
                      navigate(`/user-type/people`);
                    }}
                  >
                    <FontAwesomeIcon icon={faUsers} className="fs-20-660C60" />
                    <Typography className="fs-14-op-50">People</Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Popover>

      <Popover
        id={idCreateContent}
        open={openMenuForCreateContent}
        anchorEl={anchorElForCreateContent}
        onClose={handleCloseForCreateContent}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className="create-content-popup"
      >
        <Box className="p-2 main-app-icon-div-2">
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              className="main-grid"
            >
              <Stack direction="vertical" gap={1} className="w-100">
                <List className="list-item-menu-2" >
                  {getAllTypes.map((type, index) => (
                    <ListItem
                      key={type?.slug}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: "initial",
                          px: 2.5,
                          "&:hover": {
                            backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                          },
                        }}
                        onClick={() => {
                          dispatch(drawerMenuAction.clickDrawerItem(type?.slug));
                          dispatch(drawerMenuAction.handleNameCollapsable(false));
                          dispatch(drawerMenuAction.handleActiveType(true));
                          dispatch(drawerMenuAction.handleTypesCollapsable(true));
                          dispatch(drawerMenuAction.handleUsersCollapsable(false));

                          if (loggedUser?.user_type == "admin" && adminAccessible == false) {
                            handleCloseForApps();
                            handleCloseForAvatar();
                            handleCloseForCreateContent();
                            // localStorage.setItem("last-redirect-page", `/type/add/${type?.slug}`)
                            localStorage.setItem("last-redirect-page", `/type/add/${type?.slug}`)
                            localStorage.setItem("type-selected-name", type?.name);
                            localStorage.setItem("type-selected-id", type?.id);
                            localStorage.setItem("type-selected-slug", type?.slug);
                            // navigate(`/type/add/${type?.slug}`);
                            navigate(`/type/add/${type?.slug}`);
                          }
                          else if (loggedUser?.user_type == "admin" && adminAccessible == true) {
                            handleCloseForAvatar();
                            handleCloseForCreateContent();
                            handleCloseForApps();
                            // localStorage.setItem("last-redirect-page-admin-user", `/create-content`)
                            localStorage.setItem("last-redirect-page-user", `/type/add/${type?.slug}`)
                            localStorage.setItem("type-selected-name-user", type?.name);
                            localStorage.setItem("type-selected-id-user", type?.id);
                            localStorage.setItem("type-selected-slug-user", type?.slug);
                            // navigate(`/create-content`);
                            navigate(`/type/add/${type?.slug}`);
                          }
                          else {
                            handleCloseForAvatar();
                            handleCloseForCreateContent();
                            handleCloseForApps();
                            // localStorage.setItem("last-redirect-page-user", `/create-content`)
                            localStorage.setItem("last-redirect-page-user", `/type/add/${type?.slug}`)
                            localStorage.setItem("type-selected-name-user", type?.name);
                            localStorage.setItem("type-selected-id-user", type?.id);
                            localStorage.setItem("type-selected-slug-user", type?.slug);
                            // navigate(`/create-content`);
                            navigate(`/type/add/${type?.slug}`);

                          }
                        }}
                        className={"item-div"}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 2,
                            ml: 4,
                            justifyContent: "center",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={type?.icon !== null ? type?.icon : faStar}
                            className="fs-12-660C60"
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={type?.name}
                          className="fs-14-muted"
                          sx={{
                            color: type?.colour,
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Stack>
            </Grid>
          </Box>
        </Box>
      </Popover>

      <Popover
        id={idChat}
        anchorReference="anchorPosition"
        open={openMenuForChat}
        anchorEl={anchorElForChat}
        onClose={handleCloseForChat}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorPosition={{ top: 100, left: 1500 }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className="chat-popup-popover"
      >
        <Box className="popup-chat--box">
          <Typography className="chat-name-ty">Chat</Typography>
          <Box className="see-all-chat-box">
            <Typography className="see-all-chat-text cursor-pointer" onClick={() => {
              handleCloseForChat()
              dispatch(drawerMenuAction.clickDrawerItem("user-chat"))
              if (adminAccessible == true) {
                localStorage.setItem("last-redirect-page-admin-user", `/chat`)
              } else {
                localStorage.setItem("last-redirect-page-user", `/chat`)
              }
              navigate("/chat")

            }}>See All Chats</Typography>
            <FontAwesomeIcon icon={faArrowRight} className="see-all-chat-icon" />
          </Box>
        </Box>
        <Box className="main-app-icon-div-3 d-flex flex-wrap">
          <Box className="users-chat-list-div">
            {/* <Box sx={{ padding: "16px 16px 0 16px" }}>
              <Button
                className="btn primary-btn"
                type="submit"
                onClick={() => {
                  handleCloseForChat()
                  dispatch(drawerMenuAction.clickDrawerItem("user-chat"))
                  if (adminAccessible == true) {
                    localStorage.setItem("last-redirect-page-admin-user", `/user-conversation`)
                  } else {
                    localStorage.setItem("last-redirect-page-user", `/user-conversation`)
                  }
                  navigate("/user-conversation")

                }}
              >
                <FontAwesomeIcon
                  icon={faEye}
                  className=""
                />
                See All Chats
              </Button>
            </Box> */}
            <Box className="user-list-box">
              <List className="user-list" >
                {chatUserList?.map((chatUser) => (
                  <>
                    <ListItem alignItems="flex-start" className={`user-list-items-con cursor-pointer ${selectedChatRoom == chatUser?.chat_room_id && "selected-chat-room-div"}`} onClick={() => {
                      setSelectedChatRoom(chatUser?.chat_room_id)
                      localStorage.setItem("selected-chat-room-id", chatUser?.chat_room_id)
                    }} sx={{ padding: "15px" }}>
                      <ListItemAvatar className="user-list-avatar-div">
                        <Avatar alt={chatUser?.name} src={chatUser?.profile} className="user-list-avatar text-capitalize">{chatUser?.name?.charAt(0)}</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <>
                            <Box className="d-flex justify-content-between">
                              <Typography className="chat-usr-name">{chatUser?.name}</Typography>

                              <Typography className="chat-msg-time ">
                                {formatMessageTime(chatUser?.message_time)}
                              </Typography>
                            </Box>
                          </>
                        }
                        className="user-list-name chat-users-list"
                        secondary={
                          <>
                            <Box className="d-flex justify-content-between last-msg text-truncate">
                              {chatUser?.last_message_sender !== "" && (
                                <Typography sx={{ fontSize: "12px" }} className="last-chat-msg text-truncate">{`${chatUser?.last_message_sender}:  ${renderLastMessage(chatUser?.last_message)}`}</Typography>
                              )}
                              {chatUser?.last_message !== "" && chatUser?.unread_count > 0 && (
                                <Typography className="chat-unread-count">
                                  {chatUser?.unread_count}
                                </Typography>
                              )}
                            </Box>
                          </>
                        }
                      />
                    </ListItem>
                    {/* <Divider component="li" /> */}
                  </>
                ))}

              </List>
            </Box>
          </Box>
          <Box className="user-chat-div">
            {selectedChatRoomData !== undefined && !isChatRooomLoading && (
              <>
                <Box sx={{ padding: "15px" }} className="d-flex justify-content-between align-items-center user-info-div">
                  <Box className="d-flex gap-3 justify-content-center align-items-center">
                    <Avatar alt={selectedChatRoomData?.room_details?.name} src={selectedChatRoomData?.room_details?.profile} className="user-list-avatar">{selectedChatRoomData?.room_details?.name?.charAt(0)}</Avatar>
                    <Typography className="header-chat-room-name">{selectedChatRoomData?.room_details?.name}</Typography>
                  </Box>
                  <Box className="d-flex gap-3 justify-content-center align-items-center">
                    {selectedChatRoomData?.room_details?.type === "private" && (
                      <Box className="" onClick={() => {
                        if (loggedUser?.user_type === "user") {
                          handleCloseForChat()
                          localStorage.setItem("last-redirect-page-user", `/user-type/people/profile/view/${selectedChatRoomData?.room_details?.user_id}`);
                          navigate(`/user-type/people/profile/view/${selectedChatRoomData?.room_details?.user_id}`);
                        }
                        else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
                          handleCloseForChat()
                          localStorage.setItem("last-redirect-page-admin-user", `/user-type/people/profile/view/${selectedChatRoomData?.room_details?.user_id}`)
                          navigate(`/user-type/people/profile/view/${selectedChatRoomData?.room_details?.user_id}`);
                        } else if (loggedUser?.user_type === "admin" && adminAccessible == false) {
                          handleCloseForChat()
                          localStorage.setItem("last-redirect-page", `/user-type/people/profile/view/${selectedChatRoomData?.room_details?.user_id}`)
                          navigate(`/user-type/people/profile/view/${selectedChatRoomData?.room_details?.user_id}`);
                        }
                      }}>
                        <FontAwesomeIcon icon={faArrowRight} className="header-chat-room-arrow-icon" />
                      </Box>
                    )}
                  </Box>
                </Box>

                <Box sx={{ padding: "15px", maxHeight: "404px", overflow: "auto", minHeight: "404px", borderBottom: "1px solid #EEEEEE" }} className="user-chats-div-con" ref={chatContainerRef}>
                  <Box className="load-more-btn-div">
                    {hasMoreMessages && (
                      <Button onClick={loadMoreMessages} disabled={isLoadingMore} className="load-more-btn text-capitalize" >
                        <FontAwesomeIcon icon={faAngleUp} />
                        {isLoadingMore ? "Loading..." : "Load More"}
                      </Button>
                    )}
                  </Box>
                  {Object?.keys(selectedChatRoomData?.data)?.length > 0 ? (
                    <Box className="full-chat-msg" sx={{ position: "relative", height: "100%" }}>
                      {Object?.keys(selectedChatRoomData?.data).map((date) => {
                        const messagesByTime = selectedChatRoomData?.data[date];
                        const timeGroups = selectedChatRoomData?.data[date];

                        return (
                          <div key={date} aria-controls={openForReaction ? "account-create-content" : undefined}
                            aria-expanded={openForReaction ? "true" : undefined}
                            aria-describedby={idForReaction} >
                            <Divider className="message-date text-center mb-2">
                              {date}
                            </Divider>
                            {Object.keys(timeGroups).map((time, index) => {
                              const messages = timeGroups[time];
                              // Step 1: Filter messages to have unique ids
                              const uniqueMessages = messages.reduce((acc, currentMessage) => {
                                // Check if the current message id is already in the accumulator
                                const isDuplicate = acc.find((message) => message.id === currentMessage.id);

                                // If it's not a duplicate, add it to the accumulator
                                if (!isDuplicate) {
                                  acc.push(currentMessage);
                                }

                                return acc;
                              }, []);
                              let lastUser = null;
                              // console.log("messages:::", messages)
                              const lastMessageSentByUser = messages?.find(chat => chat?.sender_id === loggedUser?.id);
                              return (
                                <div>
                                  {uniqueMessages?.map((chat, index) => {
                                    const isSentByLoggedInUser = chat?.sender_id === loggedUser?.id;
                                    // const seenByAll = chat?.seen_by?.length >= selectedChatRoomData?.room_details?.total_user_count;
                                    const highlighted = isMessageHighlighted(chat?.id);
                                    const navigated = isMessageNavigated(chat?.id);
                                    const showTimeAndName = lastUser !== chat?.sender_id;
                                    lastUser = chat?.sender_id;

                                    return (
                                      <>
                                        <Box>
                                          <div key={chat?.id}
                                            id={`chat-${chat?.id}`}
                                            className={`chat-message-con ${isSentByLoggedInUser ? 'right' : 'left'} `}
                                            onMouseLeave={handlePopoverCloseForDataOnly}
                                          >
                                            <Box className="chat-data-con">
                                              {showTimeAndName && (
                                                <div className={`${!isSentByLoggedInUser ? "message-time-2 mb-1" : "mb-1"}`}>
                                                  <Typography variant="caption">
                                                    {!isSentByLoggedInUser && (
                                                      <>
                                                        <span className="message-sender message-sender-name">
                                                          {chat?.sender_detail?.name}
                                                        </span>
                                                        <span className="dot-separator">•</span>
                                                      </>
                                                    )}
                                                    <span className="dot-separator">
                                                      {formatTimestamp(chat?.message_time)}
                                                    </span>
                                                  </Typography>
                                                </div>
                                              )}

                                              <Box className="d-flex gap-1" onMouseLeave={() => { }}>
                                                {!isSentByLoggedInUser && (
                                                  <Avatar
                                                    aria-label="recipe"
                                                    className="avatar-chat text-capitalize"
                                                    src={chat?.sender_detail?.thumbnail}
                                                  >
                                                    {chat?.sender_detail?.name?.charAt(0)}
                                                  </Avatar>
                                                )}

                                                {chat?.quote_message ? (
                                                  <>
                                                    <div className="pl__chat-wrap-reply header-chat-wrap-reply cursor-pointer"
                                                      onMouseEnter={(e) => handlePopoverOpen(e, chat.id, isSentByLoggedInUser === true ? true : false, chat?.message)}
                                                    >
                                                      <Box className=" gap-1 reaction-popup-card" sx={{ display: 'flex', gap: '5px', background: 'white', border: '1px solid #EEEEEE', borderRadius: '6px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}
                                                        aria-controls={openMenuForChatEdit ? "account-create-content" : undefined}
                                                        aria-expanded={openMenuForChatEdit ? "true" : undefined}
                                                        aria-describedby={idForChatEdit}
                                                      >
                                                        <Box className="reaction-options" sx={{ padding: '5px', }}>
                                                          <Box onClick={() => {
                                                            toggleReaction(currentChatID, "love", true)
                                                          }}>
                                                            <FontAwesomeIcon icon={faHeart} className="reaction-icon fa-love-btn" />
                                                          </Box>
                                                          <Box onClick={() => {
                                                            toggleReaction(currentChatID, "smile", true)
                                                          }}>
                                                            <FontAwesomeIcon icon={faSmile} className="reaction-icon fa-smile-btn" />
                                                          </Box>
                                                          <Box onClick={() => {
                                                            toggleReaction(currentChatID, "angry", true)
                                                          }}>
                                                            <FontAwesomeIcon icon={faAngry} className="reaction-icon fa-angry-btn" />
                                                          </Box>
                                                          <Box className="" onClick={() => {
                                                            toggleReaction(currentChatID, "like", true)
                                                          }}>
                                                            <FontAwesomeIcon icon={faThumbsUp} className="reaction-icon fa-like-btn" />
                                                          </Box>
                                                        </Box>
                                                      </Box>
                                                      <div className="pl__msg-replay header-msg-reply"
                                                        onClick={() => {
                                                          setRedirectionChatID(chat?.quote_message?.message_id)
                                                        }}
                                                      >
                                                        <div className="pl__msg-block header-msg-blk">
                                                          <Box className="d-flex gap-3">
                                                            {chat?.quote_message?.attachment?.length > 0 && (
                                                              <>
                                                                {chat?.quote_message?.attachment?.map((data) => (
                                                                  <>
                                                                    {data?.media_type === "png" || data?.media_type === "jpg" || data?.media_type === "jpeg" ? (
                                                                      <img src={data?.media_url} className="media-img-3" />
                                                                    ) : data?.media_type === "mp4" ? (
                                                                      <>
                                                                        <Box
                                                                          display="flex"
                                                                          alignItems="center"
                                                                          justifyContent="center"
                                                                          className="other-type-media-box-header quote-other-media-box"
                                                                        >
                                                                          <FontAwesomeIcon
                                                                            size="2x"
                                                                            icon={
                                                                              faVideo
                                                                            }
                                                                            color="#660C60"
                                                                          />
                                                                          <Typography className="fs-12 text-center">{data?.media_title}</Typography>
                                                                        </Box>
                                                                      </>
                                                                    ) : (
                                                                      <>
                                                                        <Box
                                                                          display="flex"
                                                                          alignItems="center"
                                                                          justifyContent="center"
                                                                          className="other-type-media-box-header quote-other-media-box"
                                                                        >
                                                                          <FontAwesomeIcon
                                                                            size="2x"
                                                                            icon={
                                                                              faFile
                                                                            }
                                                                            color="#660C60"
                                                                          />
                                                                          <Typography className="fs-12 text-center">{data?.media_title}</Typography>
                                                                        </Box>
                                                                      </>
                                                                    )}
                                                                  </>
                                                                ))}
                                                              </>
                                                            )}
                                                            <Box className="header-quote-2-box">
                                                              {chat?.quote_message?.sender_detail?.id === loggedUser?.id ? (
                                                                <p className="pl__person-name">You</p>
                                                              ) : (
                                                                <p className="pl__person-name"><span>{chat?.quote_message?.sender_detail?.full_name?.charAt(0)}</span>{chat?.quote_message?.sender_detail?.full_name}</p>
                                                              )}
                                                              <p className="pl__msg-deatils">{chat?.quote_message?.message !== "" ? renderMessage(chat?.quote_message?.message) : "Sent an attachment"}</p>
                                                            </Box>
                                                          </Box>
                                                        </div>
                                                      </div>
                                                      <Box className="msgs w-100"
                                                        onClick={() => {
                                                          setRedirectionChatID(chat?.quote_message?.message_id)
                                                        }}
                                                      >
                                                        <Box className=" header-quote-msg-div-1">
                                                          <div className={`message-content`} key={chat?.id}
                                                          // onMouseEnter={(e) => handlePopoverOpen(e, chat.id, isSentByLoggedInUser == true ? true : false, chat?.message)}
                                                          >
                                                            <Box className="chat-msg-box-1">
                                                              {chat?.message !== "" && (
                                                                <Typography
                                                                  className={`${highlighted ? 'highlight' : ''} ${navigated ? 'navigated' : ''}`}
                                                                >
                                                                  {renderMessage(chat?.message)}
                                                                </Typography>
                                                              )}
                                                              {chat?.attachment?.length > 0 && (
                                                                <>
                                                                  {chat?.attachment?.map((chatData) => (
                                                                    <>
                                                                      {chatData?.media_type === "png" || chatData?.media_type === "jpg" || chatData?.media_type === "png" ? (
                                                                        <Box className={`mtha-1 chat-media-box ${chat?.message !== "" && "mt-2"}`}>
                                                                          <img src={chatData?.media_url} onClick={() => {
                                                                            downloadFile(chatData?.media_url, chatData?.media_type, chatData?.media_title)
                                                                          }}
                                                                          />
                                                                        </Box>
                                                                      ) : chatData?.media_type === "mp4" ? (
                                                                        <Box className={`reply-media-data-box-2 ${chat?.message !== "" && "mt-2"}`}>
                                                                          <Card
                                                                            key={"pdf"}
                                                                            variant="outlined"
                                                                            sx={{
                                                                              p: 2,
                                                                              // mb: 2,
                                                                              cursor: "pointer",
                                                                            }}
                                                                            onClick={() => {
                                                                              downloadFile(chatData?.media_url, chatData?.media_type, chatData?.media_title)

                                                                            }}
                                                                          >
                                                                            <Box
                                                                              height={70}
                                                                              width={140}
                                                                              display="flex"
                                                                              alignItems="center"
                                                                              justifyContent="center"
                                                                            >
                                                                              <FontAwesomeIcon
                                                                                size="3x"
                                                                                icon={
                                                                                  faVideo
                                                                                }
                                                                                color="#660C60"
                                                                              />
                                                                            </Box>
                                                                            <Divider />
                                                                            <Box
                                                                              sx={{
                                                                                paddingLeft: 1,
                                                                              }}
                                                                              className="d-flex justify-content-center"
                                                                            >
                                                                              {chatData?.media_title}
                                                                            </Box>
                                                                          </Card>
                                                                        </Box>
                                                                      ) : (
                                                                        <Box className={`reply-media-data-box-2 ${chat?.message !== "" && "mt-2"}`}>
                                                                          <Card
                                                                            key={"pdf"}
                                                                            variant="outlined"
                                                                            sx={{
                                                                              p: 2,
                                                                              // mb: 2,
                                                                              cursor: "pointer",
                                                                            }}
                                                                            onClick={() => {
                                                                              downloadFile(chatData?.media_url, chatData?.media_type, chatData?.media_title)

                                                                            }}
                                                                          >
                                                                            <Box
                                                                              height={70}
                                                                              width={140}
                                                                              display="flex"
                                                                              alignItems="center"
                                                                              justifyContent="center"
                                                                            >
                                                                              <FontAwesomeIcon
                                                                                size="3x"
                                                                                icon={
                                                                                  faFile
                                                                                }
                                                                                color="#660C60"
                                                                              />
                                                                            </Box>
                                                                            <Divider />
                                                                            <Box
                                                                              sx={{
                                                                                paddingLeft: 1,
                                                                              }}
                                                                              className="d-flex justify-content-center"
                                                                            >
                                                                              {chatData?.media_title}
                                                                            </Box>
                                                                          </Card>
                                                                        </Box>
                                                                      )}

                                                                    </>
                                                                  ))}
                                                                </>
                                                              )}
                                                            </Box>
                                                          </div>
                                                        </Box>
                                                      </Box>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <Box className="msgs">
                                                      <Box className="d-flex gap-2 justify-content-center align-items-center">
                                                        <div className={`message-content`} key={chat?.id}
                                                          onMouseEnter={(e) => handlePopoverOpen(e, chat.id, isSentByLoggedInUser == true ? true : false, chat?.message)}
                                                        >
                                                          {/* {chat?.media_id == null ? ( */}
                                                          <Box className="chat-msg-box-1">
                                                            {chat?.message !== "" && (
                                                              <Typography
                                                                className={`${highlighted ? 'highlight' : ''} ${navigated ? 'navigated' : ''}`}
                                                              >
                                                                {renderMessage(chat?.message)}
                                                              </Typography>
                                                            )}

                                                            {chat?.attachment?.length > 0 && (
                                                              <>
                                                                {chat?.attachment?.map((chatData) => (
                                                                  <>
                                                                    {chatData?.media_type === "png" || chatData?.media_type === "jpg" || chatData?.media_type === "png" ? (
                                                                      <Box className={`mtha-1 chat-media-box-2 ${chat?.message !== "" && "mt-2"}`}>
                                                                        <img src={chatData?.media_url} onClick={() => {
                                                                          downloadFile(chatData?.media_url, chatData?.media_type, chatData?.media_title)
                                                                        }}
                                                                        />
                                                                      </Box>
                                                                    ) : chatData?.media_type === "mp4" ? (
                                                                      <Box className={`reply-media-data-box-2-header ${chat?.message !== "" && "mt-2"}`}>
                                                                        <Card
                                                                          key={"pdf"}
                                                                          variant="outlined"
                                                                          sx={{
                                                                            p: 2,
                                                                            // mb: 2,
                                                                            cursor: "pointer",
                                                                          }}
                                                                          onClick={() => {
                                                                            downloadFile(chatData?.media_url, chatData?.media_type, chatData?.media_title)
                                                                          }}
                                                                          className="reply-media-data-card"
                                                                        >
                                                                          <Box
                                                                            height={70}
                                                                            width={140}
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                          >
                                                                            <FontAwesomeIcon
                                                                              size="3x"
                                                                              icon={
                                                                                faVideo
                                                                              }
                                                                              color="#660C60"
                                                                            />
                                                                          </Box>
                                                                          <Divider />
                                                                          <Box
                                                                            sx={{
                                                                              paddingLeft: 1,
                                                                            }}
                                                                            className="d-flex justify-content-center"
                                                                          >
                                                                            {chatData?.media_title}
                                                                          </Box>
                                                                        </Card>
                                                                      </Box>
                                                                    ) : (
                                                                      <Box className={`reply-media-data-box-2-header ${chat?.message !== "" && "mt-2"}`}>
                                                                        <Card
                                                                          key={"pdf"}
                                                                          variant="outlined"
                                                                          sx={{
                                                                            p: 2,
                                                                            // mb: 2,
                                                                            cursor: "pointer",
                                                                          }}
                                                                          onClick={() => {
                                                                            downloadFile(chatData?.media_url, chatData?.media_type, chatData?.media_title)
                                                                          }}
                                                                          className="reply-media-data-card"
                                                                        >
                                                                          <Box
                                                                            height={70}
                                                                            width={140}
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                          >
                                                                            <FontAwesomeIcon
                                                                              size="3x"
                                                                              icon={
                                                                                faFile
                                                                              }
                                                                              color="#660C60"
                                                                            />
                                                                          </Box>
                                                                          <Divider />
                                                                          <Box
                                                                            sx={{
                                                                              paddingLeft: 1,
                                                                            }}
                                                                            className="d-flex justify-content-center"
                                                                          >
                                                                            {chatData?.media_title}
                                                                          </Box>
                                                                        </Card>
                                                                      </Box>
                                                                    )
                                                                    }

                                                                  </>
                                                                ))}
                                                              </>
                                                            )}

                                                            {/* )
                                                         : ( */}

                                                          </Box>
                                                          {/* ) */}
                                                          {/* } */}
                                                          <Box className=" gap-1 reaction-popup-card" sx={{ display: 'flex', gap: '5px', background: 'white', border: '1px solid #EEEEEE', borderRadius: '6px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}
                                                            aria-controls={openMenuForChatEdit ? "account-create-content" : undefined}
                                                            aria-expanded={openMenuForChatEdit ? "true" : undefined}
                                                            aria-describedby={idForChatEdit}
                                                          >
                                                            <Box className="reaction-options" sx={{ padding: '5px', }}>
                                                              <Box onClick={() => {
                                                                toggleReaction(currentChatID, "love", true)
                                                              }}>
                                                                <FontAwesomeIcon icon={faHeart} className="reaction-icon fa-love-btn" />
                                                              </Box>
                                                              <Box onClick={() => {
                                                                toggleReaction(currentChatID, "smile", true)
                                                              }}>
                                                                <FontAwesomeIcon icon={faSmile} className="reaction-icon fa-smile-btn" />
                                                              </Box>
                                                              <Box onClick={() => {
                                                                toggleReaction(currentChatID, "angry", true)
                                                              }}>
                                                                <FontAwesomeIcon icon={faAngry} className="reaction-icon fa-angry-btn" />
                                                              </Box>
                                                              <Box className="" onClick={() => {
                                                                toggleReaction(currentChatID, "like", true)
                                                              }}>
                                                                <FontAwesomeIcon icon={faThumbsUp} className="reaction-icon fa-like-btn" />
                                                              </Box>
                                                            </Box>
                                                          </Box>

                                                        </div>
                                                        {chat?.sender_id === user?.id && !chat?.isSent && (
                                                          <FontAwesomeIcon icon={faClock} className="fa-clock-icon-send" />
                                                        )}
                                                      </Box>
                                                    </Box>
                                                  </>
                                                )}


                                              </Box>
                                              <Box className="d-flex gap-2 reaction-main-div">
                                                {Object.entries(chat?.total_reaction || {}).map(([reactionType, reactionsArray]) => (
                                                  <Box className="reaction" key={reactionType}
                                                    aria-owns={openForPopupUser ? 'mouse-over-popover' : undefined}
                                                    aria-haspopup="true"
                                                    onMouseEnter={(e) => handlePopoverOpenForUserReactionList(e, reactionsArray)}
                                                    onMouseLeave={handlePopoverCloseForUserReactionList}
                                                  >
                                                    <HtmlTooltip
                                                      title={
                                                        <React.Fragment>
                                                          <List>
                                                            {reactionsArray?.map(
                                                              (manager, index) => (
                                                                <>
                                                                  <ListItem>
                                                                    <ListItemAvatar>
                                                                      <Avatar
                                                                        alt={manager?.reaction_user?.name}
                                                                        src={manager?.reaction_user?.thumbnail}
                                                                        className="author-avtar-creator"
                                                                      />
                                                                    </ListItemAvatar>
                                                                    <ListItemText
                                                                      primary={manager?.reaction_user?.full_name}
                                                                    />
                                                                  </ListItem>
                                                                  {index !==
                                                                    reactionsArray?.length - 1 && (
                                                                      <Divider component="li" />
                                                                    )}
                                                                  {/* Conditionally render the divider */}
                                                                </>
                                                              )
                                                            )}
                                                          </List>
                                                        </React.Fragment>
                                                      }
                                                      placement="top"
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={
                                                          reactionType == "love" ? faHeart :
                                                            reactionType == "smile" ? faSmile :
                                                              reactionType == "angry" ? faAngry :
                                                                reactionType == "like" ? faThumbsUp :
                                                                  ""}
                                                        className={
                                                          reactionType == "love" ? "fa-love-btn-con" :
                                                            reactionType == "smile" ? "fa-smile-btn-con" :
                                                              reactionType == "angry" ? "fa-angry-btn-con" :
                                                                reactionType == "like" ? "fa-like-btn-con" :
                                                                  ""}
                                                      />
                                                      <span>{reactionsArray.length}</span>
                                                    </HtmlTooltip>
                                                  </Box>
                                                ))}
                                              </Box>
                                              {isSentByLoggedInUser && chat?.id === lastMessageSentByUser?.id && selectedChatRoomData?.room_details?.type === "group" && (
                                                <Box className="mt-0">
                                                  {chat?.seen_by?.length > 0 && (
                                                    <Typography className="seen-all-text">Seen by: {chat?.seen_by?.map(user => user?.name).join(", ")}</Typography>
                                                  )}
                                                </Box>
                                              )}
                                            </Box >
                                          </div>
                                        </Box>
                                      </>
                                    );
                                  })}
                                </div>
                              )
                            })}
                          </div>
                        );
                      })}
                    </Box>
                  ) : (
                    <div className="no-data mt-5">
                      <div>
                        <img src={noChatImg} className="text-center mx-auto no-chat-img mt-4" />
                      </div>
                    </div>
                  )}

                  {userTypingMessageData && (
                    <Box className="typing-indicator text-center">
                      <Box className="chat-msg-wrap">
                        <Typography className="typing-indicator-text">
                          {userTypingMessageData}
                        </Typography>
                      </Box>
                    </Box>
                  )}

                </Box>

                <Box sx={{ padding: "15px 0 !important" }} className="user-chat-send-div">
                  <Box className="serach-box w-100">
                    <InputGroup className="input-group-2">
                      <QuickMessageInput
                        typedMessage={typedMessage}
                        setTypedMessage={setTypedMessage}
                        handleTyping={handleTyping}
                        handleKeyDown={handleKeyDown}
                        chatRoomId={selectedChatRoom}
                        borderOnSendMessage={borderOnSendMessage}
                      />

                      <Button className="search-btn-dash" onClick={sendMessage}>
                        <FontAwesomeIcon icon={faTelegram} />
                      </Button>
                    </InputGroup>
                  </Box>
                </Box>
              </>
            )}
            {isChatRooomLoading && (<ChatSkleton />)}
          </Box>
        </Box>
      </Popover>

      <Popover
        id={idPlusContentIcon}
        open={openMenuForPlusContentIcon}
        anchorEl={anchorElForPlusContentIcon}
        onClose={handleCloseForPlusContentIcon}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ top: "20px" }}
      >
        <Box className="p-2 main-app-icon-div-2">
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              className="main-grid"
            >
              <Stack direction="vertical" gap={1} className="w-100">
                <List className="list-item-menu-2" >
                  <ListItem
                    key="create-content"
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: "initial",
                        px: 2.5,
                        "&:hover": {
                          backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                        },
                      }}
                      aria-controls={openMenuForCreateContent2 ? "account-create-content" : undefined}
                      aria-expanded={openMenuForCreateContent2 ? "true" : undefined}
                      aria-describedby={idCreateContent2}
                      onClick={(e) => {
                        handleClickForCreateContent2(e)
                      }}
                      className={"item-div"}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 2,
                          // ml: 4,
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          className="fs-12-660C60"
                        />

                      </ListItemIcon>
                      <ListItemText
                        primary="Create Content"
                        className="fs-14-muted"
                      // sx={{
                      //   color: type?.colour,
                      // }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    key="notification"
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: "initial",
                        px: 2.5,
                        "&:hover": {
                          backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                        },
                      }}
                      onClick={() => {
                        handleCloseForPlusContentIcon()
                      }}
                      className={"item-div"}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 2,
                          // ml: 4,
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faBell}
                          className="fs-12-660C60"
                        />

                      </ListItemIcon>
                      <ListItemText
                        primary="Notification"
                        className="fs-14-muted"
                      // sx={{
                      //   color: type?.colour,
                      // }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    key="chat"
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: "initial",
                        px: 2.5,
                        "&:hover": {
                          backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                        },
                      }}
                      onClick={() => {
                        handleCloseForPlusContentIcon()
                      }}
                      className={"item-div"}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 2,
                          // ml: 4,
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faComment}
                          className="fs-12-660C60"
                        />

                      </ListItemIcon>
                      <ListItemText
                        primary="Chat"
                        className="fs-14-muted"
                      // sx={{
                      //   color: type?.colour,
                      // }}
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Stack>
            </Grid>
          </Box>
        </Box>
      </Popover>

      <Popover
        id={idCreateContent2}
        open={openMenuForCreateContent2}
        anchorEl={anchorElForCreateContent2}
        onClose={handleCloseForCreateContent2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        // transformOrigin={{
        //   vertical: "top",
        //   horizontal: "right",
        // }}
        className="another-create-content-popup"
      >
        <Box className="p-2 main-app-icon-div-2">
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              className="main-grid"
            >
              <Stack direction="vertical" gap={1} className="w-100">
                <List className="list-item-menu-2" >
                  {getAllTypes.map((type, index) => (
                    <ListItem
                      key={type?.slug}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: "initial",
                          px: 2.5,
                          "&:hover": {
                            backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                          },
                        }}
                        onClick={() => {
                          dispatch(drawerMenuAction.clickDrawerItem(type?.slug));
                          dispatch(drawerMenuAction.handleNameCollapsable(false));
                          dispatch(drawerMenuAction.handleActiveType(true));
                          dispatch(drawerMenuAction.handleTypesCollapsable(true));
                          dispatch(drawerMenuAction.handleUsersCollapsable(false));

                          if (loggedUser?.user_type == "admin" && adminAccessible == false) {
                            handleCloseForApps();
                            handleCloseForAvatar();
                            handleCloseForCreateContent();
                            // localStorage.setItem("last-redirect-page", `/type/add/${type?.slug}`)
                            localStorage.setItem("last-redirect-page", `/type/add/${type?.slug}`)
                            localStorage.setItem("type-selected-name", type?.name);
                            localStorage.setItem("type-selected-id", type?.id);
                            localStorage.setItem("type-selected-slug", type?.slug);
                            // navigate(`/type/add/${type?.slug}`);
                            navigate(`/type/add/${type?.slug}`);
                          }
                          else if (loggedUser?.user_type == "admin" && adminAccessible == true) {
                            handleCloseForAvatar();
                            handleCloseForCreateContent();
                            handleCloseForApps();
                            // localStorage.setItem("last-redirect-page-admin-user", `/create-content`)
                            localStorage.setItem("last-redirect-page-admin-user", `/type/add/${type?.slug}`)
                            localStorage.setItem("type-selected-name-admin-user", type?.name);
                            localStorage.setItem("type-selected-id-admin-user", type?.id);
                            localStorage.setItem("type-selected-slug-admin-user", type?.slug);
                            // navigate(`/create-content`);
                            navigate(`/type/add/${type?.slug}`);
                          }
                          else {
                            handleCloseForAvatar();
                            handleCloseForCreateContent();
                            handleCloseForApps();
                            // localStorage.setItem("last-redirect-page-user", `/create-content`)
                            localStorage.setItem("last-redirect-page-user", `/type/add/${type?.slug}`)
                            localStorage.setItem("type-selected-name-user", type?.name);
                            localStorage.setItem("type-selected-id-user", type?.id);
                            localStorage.setItem("type-selected-slug-user", type?.slug);
                            // navigate(`/create-content`);
                            navigate(`/type/add/${type?.slug}`);

                          }
                        }}
                        className={"item-div"}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 2,
                            ml: 4,
                            justifyContent: "center",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={type?.icon !== null ? type?.icon : faStar}
                            className="fs-12-660C60"
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={type?.name}
                          className="fs-14-muted"
                          sx={{
                            color: type?.colour,
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Stack>
            </Grid>
          </Box>
        </Box>
      </Popover>

      <Popover
        id={idNotification}
        anchorReference="anchorPosition"
        open={openMenuForNotification}
        anchorEl={anchorElForNotification}
        onClose={handleCloseForNotification}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorPosition={{ top: 100, left: 2000 }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className="chat-popup-popover"
      >
        <Box className="p-2 notification-popover d-flex flex-wrap ">
          <Box className="users-notification-list-div">
            <Box>
              <Tabs
                value={selectedTab}
                onChange={(event, newValue) =>
                  setSelectedTab(newValue)
                }
                className="notification-tabs"
                variant="scrollable"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#660C60",
                  },
                }}
              >
                <Tab
                  value="must action"
                  label={
                    <Box gap={2} className="notification-tab-label">
                      <FontAwesomeIcon icon={faGavel} className="menu-icon"
                        style={{ color: selectedTab === "must action" ? "#660C60" : "inherit" }}
                      />
                      <span>
                        Must Action
                      </span>
                      {notificationTabCount["must action"] > 0 && (
                        <Box className="label-counter">
                          <Typography sx={{ fontWeight: 500, fontSize: "0.6rem", color: "#FFFFFF" }}>
                            <b>
                              {notificationTabCount["must action"]}
                            </b>
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  }
                />
                <Tab
                  value="mention"
                  label={
                    <Box gap={2} className="notification-tab-label">
                      <FontAwesomeIcon icon={faCommentSolid} className="menu-icon"
                        style={{ color: selectedTab === "mention" ? "#660C60" : "inherit" }}
                      />
                      <span>
                        Mentions
                      </span>
                      {notificationTabCount?.mention > 0 && (
                        <Box className="label-counter">
                          <Typography sx={{ fontWeight: 500, fontSize: "0.6rem", color: "#FFFFFF" }}>
                            <b>
                              {notificationTabCount?.mention}
                            </b>
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  }
                />
              </Tabs>
              <Divider />
            </Box>
            <Box className="mt-0">
              <List className="">
                {filteredNotifications.map(
                  (notification, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        alignItems="flex-start"
                        className="user-list-items notification-list-item"
                        onClick={() => { handleNotificationClick(notification) }}
                      >
                        <ListItemAvatar className="user-list-avatar-div">
                          {
                            ['post', 'acknowledge_post'].includes(notification?.type) ? (
                              <Avatar sx={{ bgcolor: '#660c60' }} className="user-list-avatar">
                                <FontAwesomeIcon icon={faFile} size="xs" />
                              </Avatar>
                            ) : (
                              <Avatar
                                alt={
                                  notification
                                    ?.sender_detail
                                    ?.full_name ||
                                  "Admin"
                                }
                                src={
                                  notification
                                    ?.sender_detail
                                    ?.thumbnail ||
                                  "/static/images/avatar/1.jpg"
                                }
                                className="user-list-avatar"
                              />
                            )
                          }
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  (notification?.title?.length > 120
                                    ? `${notification.title.substring(0, 120)}...`
                                    : notification?.title) ||
                                  "Notification received",
                              }}
                              className={notification?.action ? "fs-14-muted" : ""}
                            />
                          }
                          className="user-list-name chat-users-list"
                        />
                      </ListItem>
                      <Divider component="li" />
                    </React.Fragment>
                  )
                )}
                {filteredNotifications.length <= 0 && (<ListItem
                  alignItems="flex-start"
                  className="user-list-items"
                >
                  <ListItemText
                    primary={
                      <span>No Notification </span>
                    }
                    className="user-list-name chat-users-list"
                  />
                </ListItem>)}
              </List>
            </Box>
          </Box>
        </Box>
      </Popover>

      <Menu
        anchorEl={anchorElForAvatar}
        id="account-menu-avatar"
        open={openMenuForAvatar}
        onClose={handleCloseForAvatar}
        onClick={handleCloseForAvatar}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 2.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClickForDetails} className="menu-items-user">
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faUser}
              className="fs-12-660C60"
            />
          </ListItemIcon>
          <ListItemText
            primary={"My Details"}
            className="menu-name fs-14-muted"
          />
        </MenuItem>
        <MenuItem onClick={handleClickForDetails} className="menu-items-user">
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faGears}
              className="fs-12-660C60"
            />
          </ListItemIcon>
          <ListItemText
            primary={"Top Drawer"}
            className="menu-name fs-14-muted"
          />
        </MenuItem>
        <MenuItem onClick={onLogout} className="menu-items-user">
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faLock}
              className="fs-12-660C60"
            />
          </ListItemIcon>
          <ListItemText
            primary={"Log Out"}
            className="menu-name fs-14-muted"
          />
        </MenuItem>
      </Menu>

      <Loader isLoading={loading} />
      <Toaster />
      {/* END :: HEADER */}
    </>
  );
};

export default Header;
