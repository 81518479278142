import { createSlice } from "@reduxjs/toolkit";
const initialData = {
    selectedMenuTab: 0,
    attributeID: undefined,
    pagination: {
        page: 1,
        per_page: 10,
        meta_data: null
    }
};

const menuTab = createSlice({
    name: "menuTabDetails",
    initialState: initialData,
    reducers: {
        handleSelectedTab(state, action) {
            state.selectedMenuTab = action?.payload
        },
        setAttributeID(state, action) {
            state.attributeID = action?.payload
        },
        setPagination(state, action) {
            state.pagination = action.payload;
        },
    }
})

export const menuTabAction = menuTab.actions;
export default menuTab.reducer;