import React from "react";
import { Image, Button } from "react-bootstrap";
import {
    Stack,
    Box,
    Typography
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

const ImageComponent = ({ attdata, setOpenMediaModelFor, handleOpenUploadMediaForAtt, handleChangeForFormData }) => {
    return (
        <>
            <Stack gap={2}>
                {attdata?.value ? (
                    <>
                        <div className="upload-img-wrapper-3 d-flex">
                            <Box className="w-25">
                                <Image
                                    src={
                                        attdata?.url
                                    }
                                    className="h-100 w-100"
                                />
                            </Box>
                            <Box className="w-75 media-details-div">
                                <Typography className="media-title">{attdata?.title}</Typography>
                                <Typography className="media-size mt-1">{attdata?.file_size}</Typography>
                            </Box>
                        </div>
                        <Box>
                            <Typography className="remove-img cursor-pointer" onClick={() => {
                                // setFieldValue("image", "")
                                handleChangeForFormData(
                                    attdata?.nameValue,
                                    "",
                                    attdata?.section_id,
                                    "no",
                                )
                            }}>Remove Image</Typography>
                        </Box>
                    </>
                ) : (
                    <Button
                        className="btn ternary-btn w-100"
                        onClick={() => {
                            setOpenMediaModelFor("attribute")
                            handleOpenUploadMediaForAtt("image", attdata)
                        }
                        }
                    >
                        <FontAwesomeIcon
                            icon={faUpload}
                        />
                        {attdata?.value
                            ? "Change Image"
                            : "Upload Image"}
                    </Button>
                )}


            </Stack>
        </>
    )
}

export default ImageComponent;