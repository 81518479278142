import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tab, TextField, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import InputGroup from "react-bootstrap/InputGroup";
import { Toast } from "../../../utils/toast";
import { Button, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faFolder, faSearch } from "@fortawesome/free-solid-svg-icons";
import { BootstrapTooltip } from "../tooltip/bootstrapTooltip";

const AddAttributeMpdel = (props) => {
    let {
        openAddAttribute,
        handleCloseForAddAttributeModel,
        setIsTypingSearchAtt,
        searchAtt,
        setSearchAtt,
        fetchAllAttributes,
        isTypingSearchAtt,
        value,
        handleChange,
        getAllAttributeSections,
        a11yProps,
        getAllAttributes,
        handleSelectAttribute,
        attributesData,
        CustomTabPanel
    } = props;
    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={openAddAttribute}
            maxWidth="xl"
            className="add-att-model"
        >
            <DialogTitle
                sx={{ m: 0, p: 2 }}
                id="customized-dialog-title"
                className="dialog-title"
            >
                Add Attribute
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleCloseForAddAttributeModel}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                className="alert-close-btn"
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers className="media-preview">
                <Box className="d-flex gap-2 p-3">
                    <InputGroup className="">
                        <TextField
                            className="search-input w-100"
                            fullWidth
                            id="outlined-basic username"
                            variant="outlined"
                            size="small"
                            name="attributeTitle"
                            value={searchAtt}
                            placeholder="search attribute"
                            onChange={(e) => {
                                setIsTypingSearchAtt(true)
                                setSearchAtt(e.target.value);
                                if (e.target.value === "") {
                                    fetchAllAttributes(e.target.value);
                                }
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    if (e.target.value?.length > 2) {
                                        fetchAllAttributes(e.target.value)
                                    }
                                    else if (e.target.value === "" && isTypingSearchAtt === true) {
                                        fetchAllAttributes(e.target.value)
                                    }
                                    else if (e.target.value?.length < 3) {
                                        Toast.error("Please enter atleat 3 letter")
                                    }
                                }
                            }}
                        />
                    </InputGroup>
                    <Button
                        className="btn primary-btn"
                        onClick={() => {
                            if (searchAtt?.length > 2) {
                                fetchAllAttributes(searchAtt)
                            } else if (searchAtt === "" && isTypingSearchAtt === true) {
                                fetchAllAttributes(searchAtt)
                            }
                            else if (searchAtt?.length < 3) {
                                Toast.error("Please enter atleat 3 letter")
                            }
                        }}
                    >
                        Search
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>
                </Box>
                <Box sx={{ width: "100%" }}>
                    <Box
                        sx={{
                            borderBottom: 1,
                            borderColor: "divider",
                        }}
                        className="box-attribute-2"
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            variant="scrollable"
                            className={
                                "add-att-tab"
                            }
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor:
                                        "#1976d2",
                                },
                            }}
                        >
                            {getAllAttributeSections?.map(
                                (data, index) => (
                                    <Tab
                                        label={data?.name}
                                        iconPosition="start"
                                        className="att-tab"
                                        {...a11yProps(index)}
                                    />
                                )
                            )}
                        </Tabs>
                    </Box>
                    {getAllAttributeSections?.map((data, index) => (
                        <CustomTabPanel
                            value={value}
                            index={index}
                        >
                            <Grid container spacing={3}>
                                {getAllAttributes?.map((attdata, index) => (
                                    <>
                                        {(data.id === 'all' || attdata?.section_id === data?.id) && (
                                            <Grid item xs={12} sm={6} md={4} lg={3}
                                                className={`cursor-pointer`}
                                                onClick={() => handleSelectAttribute(attdata)} // Handle click
                                            >
                                                <BootstrapTooltip
                                                    title={attdata?.description !== "" ? attdata?.description : attdata?.label}
                                                    placement="top"
                                                    className="tooltip-desc-class"
                                                >
                                                    <Box className={`att-list-div ${attributesData.some((item) => item.attribute_id === attdata.attribute_id) ? "selected-att" : ""
                                                        }`}>
                                                        {attdata?.icon !== null ? (
                                                            <FontAwesomeIcon icon={attdata?.icon?.icon_value} className="att-icon" />
                                                        ) : (
                                                            <FontAwesomeIcon icon={faFolder} className="att-icon" />
                                                        )}
                                                        <Typography className="fs-12-muted">{attdata?.label}</Typography>
                                                    </Box>
                                                </BootstrapTooltip>
                                            </Grid>
                                        )}

                                    </>
                                ))}
                            </Grid>
                        </CustomTabPanel>
                    ))}
                </Box>
            </DialogContent>
            <DialogActions className="d-flex flex-wrap justify-content-center gap-2 p-3 z-0 action-btn-model">
                <Button
                    className="btn primary-btn"
                    onClick={handleCloseForAddAttributeModel}
                >
                    <FontAwesomeIcon icon={faAdd} />
                    Add Attribute
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddAttributeMpdel;