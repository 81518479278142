import {
  faAngry,
  faHeart,
  faSmile,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";

export const yesNoOptions = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

export const OneDayWeekMonthYearOptions = [
  { value: "day", label: "1 Day" },
  { value: "week", label: "1 Week" },
  { value: "month", label: "1 Month" },
  { value: "year", label: "1 Year" },
];

export const DayWeekMonthYearOptions = [
  { value: "day", label: "Day" },
  { value: "week", label: "Week" },
  { value: "month", label: "Month" },
  { value: "year", label: "Year" },
  { value: "none", label: "None" },
];

export const ModelBehaviourOptions = [
  { value: "min", label: "1 Min" },
  { value: "hour", label: "1 Hour" },
  { value: "day", label: "1 Day" },
  { value: "week", label: "1 Week" },
];

export const DismissModelBehaviourOption = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "unlimited", label: "Unlimited" },
];

export const PublishOptions = [
  { value: "saveAndPublish", label: "Save And Publish" },
  { value: "saveAsDraft", label: "Save As Draft" },
  { value: "preview", label: "Preview" },
];

export const configForContentEditor = {
  toolbar: {
    items: [
      "heading",
      "bold",
      "italic",
      "bulletedList",
      "alignment:left",
      "alignment:center",
      "alignment:right",
      "link",
    ],
    shouldNotGroupWhenFull: true,
  },
};

export const configForContentEditorForHTML = {
  toolbar: {
    items: ["heading", "bold", "italic", "Underline"],
    shouldNotGroupWhenFull: true,
  },
};

export const ReactionIcon = {
  like: faThumbsUp,
  love: faHeart,
  smile: faSmile,
  angry: faAngry,
  "": "",
};

export const ReactionClassName = {
  like: "liked-post-text",
  love: "love-post-text",
  smile: "smile-post-text",
  angry: "angry-post-text",
  "": "",
};

export const CommentReactionClassName = {
  love: "fa-love-btn-2",
  smile: "fa-smile-btn-2",
  angry: "fa-angry-btn-2",
  like: "fa-like-btn-2",
  "": "",
};
