import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import {
    TextField,
    Typography,
    Box,
    Breadcrumbs,
    Divider,
    Stack,
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    Grid,
    Avatar,
    Paper,
    IconButton,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faArrowRight,
    faSearch,
    faFileCsv,
    faXmark,
    faFloppyDisk,
    faFilePdf,
    faFile,
    faFileExcel,
    faTrash,
    faAdd,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import Select from "react-select";
import api from "../../components/common/commonFunctionForApi";
import Loader from "../../components/common/loader";
import { Toast } from "../../utils/toast";
import logoutHelper from "../../helpers/logoutHelper";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import FileUploadModal from "../../components/common/fileUploadModal.js";
import { addNewNewsvalidationSchema } from "../../helpers/validations/addNewNews/index.js";
import { WarningAlert } from "../../components/common/alert/warningAlert.js";
import { SuccessAlert } from "../../components/common/alert/successAlert.js";
import { useParams } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import FileUploadModalAttribue from "../../components/common/fileUploadModalAttribute.js";
import { AntSwitch } from "../../components/common/switch/antSwitch";
import { parseInt } from "lodash";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Accordion from "react-bootstrap/Accordion";
import TableHeaderForSorting from "../../components/common/TableHeaderForSorting.js";
import InputGroup from "react-bootstrap/InputGroup";
import TablePagination from "@mui/material/TablePagination";
import { columns_attribute_changelog_header } from "../../helpers/theaderColumns/attributes/attributeChangelog";
import { dateWithTime } from "../../helpers/DateTimeFormat/dateTimeFormat.js";
import { exportXlsx } from "../../helpers/Exports/FileExport.js";
import FileSaver from "file-saver";
import UploadMediaModel from "../../components/common/media/uploadMediaModel.js";
import UploadMediaModelAtt from "../../components/common/media/uploadMediaModelAtt.js";
import AddMedia from "../../components/common/media/addMedia.js";
import { useDropzone } from "react-dropzone";
import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AllComponent from "../../components/common/attribute/allComponent.js";
import AllContentComponent from "../../components/common/attribute/allContentCompoent.js";
import AllContentAndAttributeComponent from "../../components/common/attribute/allContentAndAttributeComponent.js";
import AddAttributeMpdel from "../../components/common/modal/AddAttributeModel.js";

const processedHTML = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent || ""; // Ensure there's a default value if htmlContent is undefined
    return tempDiv;
};

function CustomTabPanel(props) {
    const { children, value, className, index, errorTab, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
            {...other}
        >
            {value === index && <Box sx={{ padding: "15px" }}>{children}</Box>}
        </div>
    );
}

const columns_atttributes_headers = [
    {
        key: 1,
        disablePadding: true,
        id: "name",
        label: "Name",
        minWidth: 250,
        className: "other-color",
    },
    {
        key: 2,
        id: "view",
        disablePadding: true,
        label: "View",
        minWidth: 50,
        className: "other-color",
    },
    {
        key: 3,
        id: "deny",
        disablePadding: true,
        label: "Deny",
        minWidth: 50,
        className: "other-color",
    },
];

const columns_advace_atttributes_headers = [
    {
        key: 1,
        disablePadding: true,
        id: "name",
        label: "Name",
        minWidth: 250,
        className: "other-color",
    },
    {
        key: 2,
        id: "view",
        disablePadding: true,
        label: "View",
        minWidth: 50,
        className: "other-color",
    },
    {
        key: 3,
        id: "deny",
        disablePadding: true,
        label: "Deny",
        minWidth: 50,
        className: "other-color",
    },

    {
        key: 5,
        id: "edit",
        disablePadding: true,
        label: "Edit",
        minWidth: 50,
        className: "other-color",
    },
    {
        key: 6,
        id: "delete",
        disablePadding: true,
        label: "Delete",
        minWidth: 50,
        className: "other-color",
    },
    {
        key: 7,
        id: "redacted",
        disablePadding: true,
        label: "Redacted",
        minWidth: 50,
        className: "other-color",
    },
];

const AddNews2 = () => {
    let { id, type } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(0);
    const [openFileUploadModel, setOpenFileUploadModel] = useState(false);
    const [openRnameMediaModel, setOpenRenameModel] = useState(false)
    const [openRnameMediaModelAtt, setOpenRenameModelAtt] = useState(false)

    const [openFileUploadModelForAtt, setOpenFileUploadModelForAtt] = useState(false);

    const [contentForPost, setContentForPost] = useState("");
    const [contentForPostForAtt, setContentForPostForAtt] = useState("");

    const [publicationOption, setPublicationOption] = useState("");
    const [showError, setShowError] = useState(false);
    const [newsId, setNewsID] = useState(id ? parseInt(id) : "");
    const [isEdit, setIsEdit] = useState(newsId ? true : false);
    const [getNewsData, setGetNewsData] = useState();
    const [getAllTypes, setGetAllTypes] = useState([]);
    const [isWarningMessage, setIsWarningMessage] = useState(false);
    const [warningMessage, setWarningMessage] = useState([]);
    const [isSuccessMessage, setIsSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [getAllAttributeSections, setGetAllAttributeSections] = useState([]);
    const [getAllAttributes, setGetAllAttributes] = useState([])
    const [attributesData, setAttributes] = useState([]);
    const [contentAttributeData, setContentAttributeData] = useState([])
    const [conterntAndAttributeData, setContentAndAttributeData] = useState([])
    const [fileForContent, setFileForContent] = useState(false)

    const [attData, setAttData] = useState();
    const [additionalTextFields, setAdditionalTextFields] = useState([]);
    const [additionalTextFieldsContent, setAdditionalTextFieldsContent] = useState([]);
    const [additionalTextFieldsContentAttribute, setAdditionalTextFieldsContentAttribute] = useState([]);

    const [additionalTextFieldsForNestedChildAtt1, setAdditionalTextFieldsForNestedChildAtt1] = useState([]);
    const [additionalTextFieldsForNestedChildAtt2, setAdditionalTextFieldsForNestedChildAtt2] = useState([]);
    const [additionalTextFieldsForNestedChildAtt3, setAdditionalTextFieldsForNestedChildAtt3] = useState([]);

    const [showBelowError, setShowBelowError] = useState(false);
    const [permissions, setPermissions] = useState([]);

    const [isAdvancePermission, setIsAdvancePermission] = useState(false);
    const [getAllGroupsAndUsers, setGetAllGroupsAndUsers] = useState([]);

    const [getAllCategories, setGetAllCategories] = useState([])
    const [selectedCategories, setSelectedCategories] = useState({})

    const [search, setSearch] = useState("");
    const [istype, setIstype] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [getAllChangeLogs, setGetAllChangeLogs] = useState();

    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedImageFile, setSelectedImageFile] = useState(null);

    const [selectedFileForAtt, setSelectedFileForAtt] = useState(null);
    const [selectedImageFileForAtt, setSelectedImageFileForAtt] = useState(null);

    const [openMediaUpload, setOpenMediaUpload] = useState(false)
    const [folderList, setFolderList] = useState()
    const [myMediaFolderList, setMyFolderList] = useState([])
    const [systemMediaFolderList, setSystemFolderList] = useState([])
    const [selectedMediaTypeForImage, setSelectedMediaTypeForImage] = useState("")
    const [selectedMediaTypeForDoc, setSelectedMediaTypeForDoc] = useState("")

    const [selectedMediaFolderIDForImage, setSelectedMediaFolderIDForImage] = useState("")
    const [selectedMediaFolderIDFordoc, setSelectedMediaFolderIDForDoc] = useState("")

    const [mediaList, setMediaList] = useState([])
    const [mediaListResponse, setMediaListResponse] = useState()

    const [selectedMediaFileDetailsForImage, setSelectedMediaFileDetailsForImage] = useState()
    const [selectedMediaFileForImage, setSelectedMediaFileForImage] = useState("")

    const [selectedMediaFileDetailsForDoc, setSelectedMediaFileDetailsForDoc] = useState()
    const [selectedMediaFileForDoc, setSelectedMediaFileForDoc] = useState("")

    const [mediaDataFor, setMediaDataFor] = useState("")

    const [rowsPerPageForMedia, setRowsPerPageForMedia] = useState(18)
    const [pageForMedia, setPageForMedia] = useState(0);

    const [openMediaUploadForAtt, setOpenMediaUploadForAtt] = useState(false)
    const [folderListForAtt, setFolderListForAtt] = useState()
    const [myMediaFolderListForAtt, setMyFolderListForAtt] = useState([])
    const [systemMediaFolderListForAtt, setSystemFolderListForAtt] = useState([])
    const [selectedMediaTypeForImageAtt, setSelectedMediaTypeForImageAtt] = useState("")
    const [selectedMediaTypeForDocAtt, setSelectedMediaTypeForDocAtt] = useState("")

    const [selectedMediaFolderIDForImageAtt, setSelectedMediaFolderIDForImageAtt] = useState("")
    const [selectedMediaFolderIDFordocAtt, setSelectedMediaFolderIDForDocAtt] = useState("")

    const [mediaListAtt, setMediaListAtt] = useState([])
    const [mediaListResponseAtt, setMediaListResponseAtt] = useState()

    const [selectedMediaFileDetailsForImageAtt, setSelectedMediaFileDetailsForImageAtt] = useState()
    const [selectedMediaFileDetailsForAtt, setSelectedMediaFileDetailsForAtt] = useState()
    const [selectedMediaFileForImageAtt, setSelectedMediaFileForImageAtt] = useState("")
    const [selectedMediaFileForAtt, setSelectedMediaFileForAtt] = useState("")

    const [selectedMediaFileDetailsForDocAtt, setSelectedMediaFileDetailsForDocAtt] = useState()
    const [selectedMediaFileForDocAtt, setSelectedMediaFileForDocAtt] = useState("")

    const [mediaDataForAtt, setMediaDataForAtt] = useState("")

    const [openMediaModelFor, setOpenMediaModelFor] = useState("")

    const [openFileUploadModelMedia, setOpenFileUploadModelMedia] = useState(false)
    const [openFileUploadModelMediaAtt, setOpenFileUploadModelMediaAtt] = useState(false)

    const [mediaTitle, setMediaTitle] = useState("")
    const [mediaTitleAtt, setMediaTitleAtt] = useState("")

    const [mediaAltText, setMediaAltText] = useState("")
    const [mediaAltTextAtt, setMediaAltTextAtt] = useState("")

    const [mediaDescription, setMediaDescription] = useState("")
    const [mediaDescriptionAtt, setMediaDescriptionAtt] = useState("")

    const [mediaDirectUrl, setMediaDirectUrl] = useState("")
    const [mediaDirectUrlAtt, setMediaDirectUrlAtt] = useState("")

    const [isSystemTypeMedia, setIsSystemTypeMedia] = useState(false)
    const [isSystemTypeMediaAtt, setIsSystemTypeMediaAtt] = useState(false)

    const [responseForMedia, setResponseForMedia] = useState()
    const [responseForMediaAtt, setResponseForMediaAtt] = useState()

    const [valueForMediaTab, setValueForMediaTab] = useState(0);
    const [editMediaType, setEditMediType] = useState("")
    const [selectedMedia, setSelectedMedia] = useState("")
    const [selectedMediaAtt, setSelectedMediaAtt] = useState("")

    const [permissionsForMedia, setPermissionsForMedia] = useState([]);
    const [isAdvancePermissionForMedia, setIsAdvancePermissionForMedia] = useState(false);
    const [parentFolderId, setParentFolderId] = useState(0)
    const [parentFolderIdAtt, setParentFolderIdAtt] = useState(0)

    const [isParentFolderSystemType, setIsParentFolderSystemType] = useState(false)
    const [isParentFolderSystemTypeAtt, setIsParentFolderSystemTypeAtt] = useState(false)

    const [isEditMedia, setIsEditMedia] = useState(false)
    const [isEditMediaAtt, setIsEditMediaAtt] = useState(false)

    const [isRenameMedia, setIsRenameMedia] = useState(false)
    const [isRenameMediaAtt, setIsRenameMediaAtt] = useState(false)

    const [selectedFileMedia, setSelectedFileMedia] = useState(null);
    const [selectedFileMediaAtt, setSelectedFileMediaAtt] = useState(null);

    const [mediaFile, setMediaFile] = useState("")
    const [mediaFileAtt, setMediaFileAtt] = useState("")

    const [fetchAllUsers, setFetchAllUsers] = useState([])
    const [getAllGroupsAndUsersForMedia, setGetAllGroupsAndUsersForMedia] = useState([]);

    const [rowsPerPageForMediaAtt, setRowsPerPageForMediaAtt] = useState(18)
    const [pageForMediaAtt, setPageForMediaAtt] = useState(0);

    const [openAddAttribute, setOpenAddAttribute] = useState(false)

    const [isFolderEnable, setIsFolderEnable] = useState(false)
    const [folderListData, setFolderListData] = useState([])

    const [searchAtt, setSearchAtt] = useState("")
    const [isTypingSearchAtt, setIsTypingSearchAtt] = useState(false)

    const [showSaveDraft, setShowSaveDraft] = useState(true)

    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
    const [showRenderImage, setShowRenderImage] = useState(true)
    const [uploadedFile, setUploadedFile] = useState()
    const [selectedAnyMediaFile, setSelectedAnyMediaFile] = useState()
    const [selectedAnyMediaFileDetails, setSelectedAnyMediaFileDetails] = useState()

    const debounceTimeout = 500; // Adjust the debounce timeout as needed
    let debounceTimer;

    const media_Pagination_options = [{ label: "18", value: 18 },
    { label: "36", value: 36 },
    { label: "54", value: 54 },
    { label: "108", value: 108 }]

    const total_changelog = parseInt(getAllChangeLogs?.meta?.total);

    const total_mediaFiles = parseInt(mediaListResponse?.meta?.total)

    const total_mediaFilesAtt = parseInt(mediaListResponseAtt?.meta?.total)

    const {
        open,
        adminAccessible
    } = useSelector((state) => state?.drawerMenu);

    let selectedTypeSlug;
    let selectedTypeName;
    let selectedTypeID;
    let apiUser;

    let isPostImage;
    let isPostFile;

    const userToken = localStorage.getItem("accessToken");
    const user = localStorage.getItem("user");
    const loggedUser = JSON.parse(user);

    if (loggedUser?.user_type == "admin" && adminAccessible == false) {
        selectedTypeSlug = localStorage.getItem("type-selected-slug")
        selectedTypeName = localStorage.getItem("type-selected-name")
        selectedTypeID = localStorage.getItem("type-selected-id")
        apiUser = "admin";
    } else if (loggedUser?.user_type == "admin" && adminAccessible == true) {
        selectedTypeSlug = localStorage.getItem("type-selected-slug-user")
        selectedTypeName = localStorage.getItem("type-selected-name-user")
        selectedTypeID = localStorage.getItem("type-selected-id-user")
        apiUser = "user";
    } else {
        selectedTypeSlug = localStorage.getItem("type-selected-slug-user")
        selectedTypeName = localStorage.getItem("type-selected-name-user")
        selectedTypeID = localStorage.getItem("type-selected-id-user")
        apiUser = "user";
    }

    if (selectedTypeName === "Documents" || selectedTypeName === "Policy") {
        isPostImage = false;
        isPostFile = true
    } else {
        isPostImage = true;
        isPostFile = false
    }

    const initialValues = {
        title: newsId ? getNewsData?.title : "",
        content: newsId ? getNewsData?.content : "",
        image: newsId ? getNewsData?.image : "",
        typePoststatus: newsId ? getNewsData?.typePoststatus : true,
        file: newsId ? getNewsData?.file : "",
        fileDescription: newsId ? getNewsData?.fileDescription : "",
        postType: getAllTypes?.find((option) => option?.value == selectedTypeID)
            ?.value,
        folder: newsId ? getNewsData?.folder : "",
        typeSlug: type,
    };

    const getMimeType = (extension) => {
        const mimeTypes = {
            'png': 'image/png',
            'jpg': 'image/jpeg',
            'jpeg': 'image/jpeg',
            'pdf': 'application/pdf',
            'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'mp4': 'video/mp4',
            'mp3': 'audio/mpeg'
        };
        return mimeTypes[extension] || 'application/octet-stream';
    };

    const handleChangePage = (event, newPage) => {
        if (newPage > page) {
            setPage(newPage);
            fetchAllChangeLogs(newPage + 1);
        } else if (newPage < page) {
            setPage(newPage);
            fetchAllChangeLogs(newPage + 1);
        }
    };

    const handleChangePageForMedia = (event, newPage) => {
        if (newPage > pageForMedia) {
            setPageForMedia(newPage);
            let media_id;
            let folderID;
            if (mediaDataFor === "image") {
                media_id = selectedMediaFileForImage
                folderID = selectedMediaFolderIDForImage === "" ? 0 : selectedMediaFolderIDForImage
            } else if (mediaDataFor === "doc") {
                media_id = selectedMediaFileForDoc
                folderID = selectedMediaFolderIDFordoc === "" ? 0 : selectedMediaFolderIDFordoc
            }
            fetchMediaFiles(newPage + 1, rowsPerPageForMedia, mediaDataFor, media_id, folderID)
        } else if (newPage < pageForMedia) {
            setPageForMedia(newPage);
            let media_id;
            let folderID;
            if (mediaDataFor === "image") {
                media_id = selectedMediaFileForImage
                folderID = selectedMediaFolderIDForImage === "" ? 0 : selectedMediaFolderIDForImage
            } else if (mediaDataFor === "doc") {
                media_id = selectedMediaFileForDoc
                folderID = selectedMediaFolderIDFordoc === "" ? 0 : selectedMediaFolderIDFordoc
            }
            fetchMediaFiles(newPage + 1, rowsPerPageForMedia, mediaDataFor, media_id, folderID)
        }
    };

    const handleChangePageForMediaForAtt = (event, newPage) => {
        if (newPage > pageForMediaAtt) {
            setPageForMediaAtt(newPage);
            let type;
            let folder_id;
            if (mediaDataForAtt === "image") {
                folder_id = selectedMediaFolderIDForImageAtt
                if (selectedMediaTypeForImageAtt === "system") {
                    type = "system"
                } else {
                    type = "user"
                }
            } else if (mediaDataForAtt === "doc") {
                folder_id = selectedMediaFolderIDFordocAtt
                if (selectedMediaTypeForDocAtt === "system") {
                    type = "system"
                } else {
                    type = "user"
                }
            }
            fetchMediaFilesForAtt(newPage + 1, rowsPerPageForMediaAtt, mediaDataForAtt, folder_id, type)
        } else if (newPage < pageForMediaAtt) {
            setPageForMediaAtt(newPage);
            let type;
            let folder_id;
            if (mediaDataForAtt === "image") {
                folder_id = selectedMediaFolderIDForImageAtt
                if (selectedMediaTypeForImageAtt === "system") {
                    type = "system"
                } else {
                    type = "user"
                }
            } else if (mediaDataForAtt === "doc") {
                folder_id = selectedMediaFolderIDFordocAtt
                if (selectedMediaTypeForDocAtt === "system") {
                    type = "system"
                } else {
                    type = "user"
                }
            }
            fetchMediaFilesForAtt(newPage + 1, rowsPerPageForMediaAtt, mediaDataForAtt, folder_id, type)
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeRowsPerPageForMedia = (event) => {
        setRowsPerPageForMedia(event.target.value);
        setPageForMedia(0);
        let media_id;
        let folderID;
        if (mediaDataFor === "image") {
            media_id = selectedMediaFileForImage
            folderID = selectedMediaFolderIDForImage === "" ? 0 : selectedMediaFolderIDForImage
        } else if (mediaDataFor === "doc") {
            media_id = selectedMediaFileForDoc
            folderID = selectedMediaFolderIDFordoc === "" ? 0 : selectedMediaFolderIDFordoc
        }
        fetchMediaFiles(0, event.target.value, mediaDataFor, media_id, folderID)

    };

    const handleChangeRowsPerPageForMediaAtt = (event) => {
        setRowsPerPageForMediaAtt(event.target.value);
        setPageForMediaAtt(0);
        let type;
        let folder_id;

        if (mediaDataForAtt === "image") {
            folder_id = selectedMediaFolderIDForImageAtt
            if (selectedMediaTypeForImageAtt === "system") {
                type = "system"
            } else {
                type = "user"
            }
        } else if (mediaDataForAtt === "doc") {
            folder_id = selectedMediaFolderIDFordocAtt
            if (selectedMediaTypeForDocAtt === "system") {
                type = "system"
            } else {
                type = "user"
            }
        }
        fetchMediaFilesForAtt(0, event.target.value, mediaDataForAtt, folder_id, type)
    };

    const transformData = (data) => {
        const result = {};

        data?.forEach(category => {
            const termIds = category?.term_value?.map(Number);
            result[category?.slug] = category?.categoryterm?.filter(term =>
                termIds?.includes(term?.id)).map(term => ({
                    label: term?.name,
                    value: term?.id,
                    categoryId: term?.category_id
                }));
        });

        return result;
    };

    // Helper function to parse JSON safely
    const safeJsonParse = (jsonString) => {
        try {
            return JSON.parse(jsonString);
        } catch (error) {
            console.error("Error parsing JSON:", error);
            return null;
        }
    };

    // Helper function to handle date/time values
    const handleDateTimeValue = (value, fieldtype) => {
        if (!value) return "";

        switch (fieldtype) {
            case "date":
                return dayjs(value);
            case "date_time":
                return dayjs(value);
            case "time":
                return dayjs(value, "HH:mm A");
            default:
                return value;
        }
    };

    // Helper function to handle repeatable fields
    const handleRepeatableField = (value, fieldtype, setAdditionalTextFields) => {
        // If the value is already an array, use it directly
        if (Array.isArray(value)) {
            setAdditionalTextFields((prevFields) => [...prevFields, ...value]);
            return value;
        }

        // If the value is a string, attempt to parse it as JSON
        if (typeof value === "string" && value !== "") {
            const cleanString = value.replace(/value\s*:\s*/g, "");
            const resultArray = safeJsonParse(cleanString);

            if (Array.isArray(resultArray) && resultArray.length > 0) {
                setAdditionalTextFields((prevFields) => [...prevFields, ...resultArray]);
                return resultArray;
            }
        }

        // If the value is neither an array nor a valid JSON string, return it as-is
        return value;
    };

    const handleRepeatableFieldContent = (value, fieldtype, setAdditionalTextFields) => {
        // If the value is already an array, use it directly
        if (Array.isArray(value)) {
            setAdditionalTextFieldsContent((prevFields) => [...prevFields, ...value]);
            return value;
        }

        // If the value is a string, attempt to parse it as JSON
        if (typeof value === "string" && value !== "") {
            const cleanString = value.replace(/value\s*:\s*/g, "");
            const resultArray = safeJsonParse(cleanString);

            if (Array.isArray(resultArray) && resultArray.length > 0) {
                setAdditionalTextFieldsContent((prevFields) => [...prevFields, ...resultArray]);
                return resultArray;
            }
        }

        // If the value is neither an array nor a valid JSON string, return it as-is
        return value;
    };

    const handleRepeatableFieldContentAttribute = (value, fieldtype, setAdditionalTextFields) => {
        // If the value is already an array, use it directly
        if (Array.isArray(value)) {
            setAdditionalTextFieldsContentAttribute((prevFields) => [...prevFields, ...value]);
            return value;
        }

        // If the value is a string, attempt to parse it as JSON
        if (typeof value === "string" && value !== "") {
            const cleanString = value.replace(/value\s*:\s*/g, "");
            const resultArray = safeJsonParse(cleanString);

            if (Array.isArray(resultArray) && resultArray.length > 0) {
                setAdditionalTextFieldsContentAttribute((prevFields) => [...prevFields, ...resultArray]);
                return resultArray;
            }
        }

        // If the value is neither an array nor a valid JSON string, return it as-is
        return value;
    };

    // Recursive function to handle nested child attributes
    const handleChildAttributes = async (childAttributes, setAdditionalTextFieldsForNestedChildAtt1) => {
        if (!Array.isArray(childAttributes) || childAttributes.length === 0) return [];

        return await Promise.all(
            childAttributes.map(async (child) => {
                let contentValue = handleDateTimeValue(child?.value ?? "", child?.fieldtype);

                if (child?.is_repeatable) {
                    contentValue = handleRepeatableField(child?.value ?? "", child?.fieldtype, setAdditionalTextFieldsForNestedChildAtt1);
                }

                // Recursively process child attributes
                const nestedChildAttributes = await handleChildAttributes(child?.child_attribute || [], setAdditionalTextFieldsForNestedChildAtt1);

                return {
                    ...child,
                    value: contentValue,
                    child_attribute: nestedChildAttributes,
                };
            })
        );
    };

    const handleChildAttributesContent = async (childAttributes, setAdditionalTextFieldsForNestedChildAtt1) => {
        if (!Array.isArray(childAttributes) || childAttributes.length === 0) return [];

        return await Promise.all(
            childAttributes.map(async (child) => {
                let contentValue = handleDateTimeValue(child?.value ?? "", child?.fieldtype);

                if (child?.is_repeatable) {
                    contentValue = handleRepeatableFieldContent(child?.value ?? "", child?.fieldtype, setAdditionalTextFieldsForNestedChildAtt1);
                }

                // Recursively process child attributes
                const nestedChildAttributes = await handleChildAttributesContent(child?.child_attribute || [], setAdditionalTextFieldsForNestedChildAtt1);

                return {
                    ...child,
                    value: contentValue,
                    child_attribute: nestedChildAttributes,
                };
            })
        );
    };

    const handleChildAttributesContentAttribute = async (childAttributes, setAdditionalTextFieldsForNestedChildAtt1) => {
        if (!Array.isArray(childAttributes) || childAttributes.length === 0) return [];

        return await Promise.all(
            childAttributes.map(async (child) => {
                let contentValue = handleDateTimeValue(child?.value ?? "", child?.fieldtype);

                if (child?.is_repeatable) {
                    contentValue = handleRepeatableFieldContentAttribute(child?.value ?? "", child?.fieldtype, setAdditionalTextFieldsForNestedChildAtt1);
                }

                // Recursively process child attributes
                const nestedChildAttributes = await handleChildAttributesContentAttribute(child?.child_attribute || [], setAdditionalTextFieldsForNestedChildAtt1);

                return {
                    ...child,
                    value: contentValue,
                    child_attribute: nestedChildAttributes,
                };
            })
        );
    };

    // Main function to process attributes
    const processAttributesData = async (attributes, setAdditionalTextFields, setAdditionalTextFieldsForNestedChildAtt1) => {
        return await Promise.all(
            attributes.map(async (data) => {
                let contentValue = data?.value ?? "";
                let url = ""
                let media_folder_id = ""
                let is_system = ""
                let file_size = ""
                let title = ""
                let name = ""
                let type = ""
                const { fieldtype, is_repeatable, child_attribute } = data || {};

                // Handle date/time fields
                if (["date", "date_time", "time"].includes(fieldtype) && !is_repeatable) {
                    contentValue = handleDateTimeValue(data?.value ?? "", fieldtype);
                }

                // Handle image and file fields
                if (["image", "file"].includes(fieldtype) && !is_repeatable) {
                    contentValue = data?.value ?? "";
                    url = data?.attachment?.file_url || "";
                    media_folder_id = data?.attachment?.folder_id || "";
                    is_system = data?.attachment?.is_system || 0;
                    file_size = data?.attachment?.file_size || "96kb";
                    title = data?.attachment?.title || "";
                    name = data?.attachment?.title || "";
                }

                // Handle image-file fields
                if (["image_file"].includes(fieldtype) && !is_repeatable) {
                    contentValue = data?.value ?? "";
                    type = data?.attachment?.type || "";
                    url = data?.attachment?.file_url || "";
                    media_folder_id = data?.attachment?.folder_id || "";
                    is_system = data?.attachment?.is_system || 0;
                    file_size = data?.attachment?.file_size || "96kb";
                    title = data?.attachment?.title || "";
                    name = data?.attachment?.title || "";
                }

                // Handle dropdown and checkbox fields
                if (["dropdodown_single_choice", "checkbox_single_choice"].includes(fieldtype) && !is_repeatable) {
                    if (fieldtype === "checkbox_single_choice") {
                        // Explicitly convert to boolean
                        contentValue = data?.value === true || data?.value === "true";
                    } else {
                        contentValue = parseInt(data?.value) || "";
                    }
                }

                // Handle multiple choice fields
                if (["checkbox_multiple_choice", "dropdodown_multiple_choice", "users_list"].includes(fieldtype) && !is_repeatable) {
                    contentValue = Array.isArray(data?.value)
                        ? data.value.map((value) => (fieldtype === "users_list" ? parseInt(value, 10) || value : value))
                        : [];
                }

                // Handle repeatable fields
                if (is_repeatable) {
                    contentValue = handleRepeatableField(data?.value ?? "", fieldtype, setAdditionalTextFields);
                }

                // Handle date range fields
                if (fieldtype === "date_range" && !is_repeatable) {
                    const [startDateString, endDateString] = (data?.value || "").split(" - ");
                    contentValue = {
                        startDate: moment(startDateString, "YYYY-MM-DD").isValid() ? moment(startDateString, "YYYY-MM-DD") : "",
                        endDate: moment(endDateString, "YYYY-MM-DD").isValid() ? moment(endDateString, "YYYY-MM-DD") : "",
                    };
                }

                // Handle child attributes
                let childAttData = [];
                if (Array.isArray(child_attribute) && child_attribute.length > 0) {
                    childAttData = await handleChildAttributes(child_attribute, setAdditionalTextFieldsForNestedChildAtt1);
                }

                return {
                    attribute_id: data?.attribute_id || "",
                    fieldtype: data?.fieldtype || "",
                    id: data?.id || "",
                    is_mandatory: data?.is_mandatory || false,
                    is_repeatable: data?.is_repeatable || false,
                    label: data?.label || "",
                    nameValue: data?.nameValue || "",
                    options: data?.options || [],
                    section_id: data?.section_id || "",
                    value: contentValue,
                    url: url,
                    is_system: is_system || 0,
                    media_folder_id: media_folder_id || "",
                    file_size: file_size || "",
                    name: name || "",
                    title: title,
                    child_attribute: childAttData,
                    type: type
                };
            })
        );
    };

    const processAttributesDataContent = async (attributes, setAdditionalTextFields, setAdditionalTextFieldsForNestedChildAtt1) => {
        return await Promise.all(
            attributes.map(async (data) => {
                let contentValue = data?.value ?? "";
                let url = ""
                let media_folder_id = ""
                let is_system = ""
                let file_size = ""
                let title = ""
                let name = ""
                let type = data?.value?.type || ""
                const { fieldtype, is_repeatable, child_attribute } = data || {};
                console.log("data for edit::", data)
                // Handle date/time fields
                if (["date", "date_time", "time"].includes(fieldtype) && !is_repeatable) {
                    contentValue = handleDateTimeValue(data?.value ?? "", fieldtype);
                }

                // Handle image and file fields
                if (["image", "file"].includes(fieldtype) && !is_repeatable) {
                    contentValue = data?.value ?? "";
                    url = data?.attachment?.file_url || "";
                    media_folder_id = data?.attachment?.folder_id || "";
                    is_system = data?.attachment?.is_system || 0;
                    file_size = data?.attachment?.file_size || "96kb";
                    title = data?.attachment?.title || "";
                    name = data?.attachment?.title || "";
                }

                // Handle image-file fields
                if (["image_file"].includes(fieldtype) && !is_repeatable) {
                    contentValue = data?.value?.id ?? "";
                    type = data?.value?.type || "";
                    url = data?.value?.file_path || "";
                    media_folder_id = data?.value?.folder_id || "";
                    is_system = data?.value?.is_system || 0;
                    file_size = data?.value?.file_size || "96kb";
                    title = data?.value?.title || "";
                    name = data?.value?.title || "";
                }

                // Handle dropdown and checkbox fields
                if (["dropdodown_single_choice", "checkbox_single_choice"].includes(fieldtype) && !is_repeatable) {
                    if (fieldtype === "checkbox_single_choice") {
                        // Explicitly convert to boolean
                        contentValue = data?.value === true || data?.value === "true";
                    } else {
                        contentValue = parseInt(data?.value) || "";
                    }
                }

                // Handle multiple choice fields
                if (["checkbox_multiple_choice", "dropdodown_multiple_choice", "users_list"].includes(fieldtype) && !is_repeatable) {
                    contentValue = Array.isArray(data?.value)
                        ? data.value.map((value) => (fieldtype === "users_list" ? parseInt(value, 10) || value : value))
                        : [];
                }

                // Handle repeatable fields
                if (is_repeatable) {
                    contentValue = handleRepeatableFieldContent(data?.value ?? "", fieldtype, setAdditionalTextFields);
                }

                // Handle date range fields
                if (fieldtype === "date_range" && !is_repeatable) {
                    const [startDateString, endDateString] = (data?.value || "").split(" - ");
                    contentValue = {
                        startDate: moment(startDateString, "YYYY-MM-DD").isValid() ? moment(startDateString, "YYYY-MM-DD") : "",
                        endDate: moment(endDateString, "YYYY-MM-DD").isValid() ? moment(endDateString, "YYYY-MM-DD") : "",
                    };
                }

                // Handle child attributes
                let childAttData = [];
                if (Array.isArray(child_attribute) && child_attribute.length > 0) {
                    childAttData = await handleChildAttributesContent(child_attribute, setAdditionalTextFieldsForNestedChildAtt1);
                }

                return {
                    attribute_id: data?.attribute_id || "",
                    fieldtype: data?.fieldtype || "",
                    id: data?.id || "",
                    is_mandatory: data?.is_mandatory || false,
                    is_repeatable: data?.is_repeatable || false,
                    label: data?.label || "",
                    nameValue: data?.nameValue || "",
                    options: data?.options || [],
                    section_id: data?.section_id || "",
                    value: contentValue,
                    url: url,
                    is_system: is_system || 0,
                    media_folder_id: media_folder_id || "",
                    file_size: file_size || "",
                    name: name || "",
                    title: title,
                    child_attribute: childAttData,
                    type: type,
                };
            })
        );
    };

    const processAttributesDataContentAttribute = async (attributes, setAdditionalTextFields, setAdditionalTextFieldsForNestedChildAtt1) => {
        return await Promise.all(
            attributes.map(async (data) => {
                let contentValue = data?.value ?? "";
                let url = ""
                let media_folder_id = ""
                let is_system = ""
                let file_size = ""
                let title = ""
                let name = ""
                let type = ""
                const { fieldtype, is_repeatable, child_attribute } = data || {};

                // Handle date/time fields
                if (["date", "date_time", "time"].includes(fieldtype) && !is_repeatable) {
                    contentValue = handleDateTimeValue(data?.value ?? "", fieldtype);
                }

                // Handle image and file fields
                if (["image", "file"].includes(fieldtype) && !is_repeatable) {
                    contentValue = data?.value ?? "";
                    url = data?.attachment?.file_url || "";
                    media_folder_id = data?.attachment?.folder_id || "";
                    is_system = data?.attachment?.is_system || 0;
                    file_size = data?.attachment?.file_size || "96kb";
                    title = data?.attachment?.title || "";
                    name = data?.attachment?.title || "";
                }

                // Handle image-file fields
                if (["image_file"].includes(fieldtype) && !is_repeatable) {
                    contentValue = data?.value ?? "";
                    type = data?.attachment?.type || "";
                    url = data?.attachment?.file_url || "";
                    media_folder_id = data?.attachment?.folder_id || "";
                    is_system = data?.attachment?.is_system || 0;
                    file_size = data?.attachment?.file_size || "96kb";
                    title = data?.attachment?.title || "";
                    name = data?.attachment?.title || "";
                }

                // Handle dropdown and checkbox fields
                if (["dropdodown_single_choice", "checkbox_single_choice"].includes(fieldtype) && !is_repeatable) {
                    if (fieldtype === "checkbox_single_choice") {
                        // Explicitly convert to boolean
                        contentValue = data?.value === true || data?.value === "true";
                    } else {
                        contentValue = parseInt(data?.value) || "";
                    }
                }

                // Handle multiple choice fields
                if (["checkbox_multiple_choice", "dropdodown_multiple_choice", "users_list"].includes(fieldtype) && !is_repeatable) {
                    contentValue = Array.isArray(data?.value)
                        ? data.value.map((value) => (fieldtype === "users_list" ? parseInt(value, 10) || value : value))
                        : [];
                }

                // Handle repeatable fields
                if (is_repeatable) {
                    contentValue = handleRepeatableFieldContentAttribute(data?.value ?? "", fieldtype, setAdditionalTextFields);
                }

                // Handle date range fields
                if (fieldtype === "date_range" && !is_repeatable) {
                    const [startDateString, endDateString] = (data?.value || "").split(" - ");
                    contentValue = {
                        startDate: moment(startDateString, "YYYY-MM-DD").isValid() ? moment(startDateString, "YYYY-MM-DD") : "",
                        endDate: moment(endDateString, "YYYY-MM-DD").isValid() ? moment(endDateString, "YYYY-MM-DD") : "",
                    };
                }

                // Handle child attributes
                let childAttData = [];
                if (Array.isArray(child_attribute) && child_attribute.length > 0) {
                    childAttData = await handleChildAttributesContentAttribute(child_attribute, setAdditionalTextFieldsForNestedChildAtt1);
                }

                return {
                    attribute_id: data?.attribute_id || "",
                    fieldtype: data?.fieldtype || "",
                    id: data?.id || "",
                    is_mandatory: data?.is_mandatory || false,
                    is_repeatable: data?.is_repeatable || false,
                    label: data?.label || "",
                    nameValue: data?.nameValue || "",
                    options: data?.options || [],
                    section_id: data?.section_id || "",
                    value: contentValue,
                    url: url,
                    is_system: is_system || 0,
                    media_folder_id: media_folder_id || "",
                    file_size: file_size || "",
                    name: name || "",
                    title: title,
                    child_attribute: childAttData,
                    type: type
                };
            })
        );
    };

    const isValidURL = (url) => {
        const pattern = new RegExp(
            /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?$/
        );
        return !!pattern.test(url);
    };

    const isValidEmail = (email) => {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
    };

    const transformCategoryData = (categories) => {
        return categories?.map(category => ({
            label: category?.title,
            slug: category?.slug,
            id: category?.id,
            options: category?.categoryterm?.map(term => ({
                label: term?.name,
                value: term?.id,
                categoryId: category?.id
            }))
        }));
    };

    const handleCategoryChange = (categoryLabel, selectedOption) => {
        setSelectedCategories(prevState => ({
            ...prevState,
            [categoryLabel]: selectedOption
        }));
    };

    const transformSelectedCategories = (selectedCategories, allCategories) => {
        const payload = [];

        allCategories.forEach(category => {
            const categoryLabel = category?.slug?.toLowerCase();
            const terms = selectedCategories[categoryLabel] || [];
            const categoryId = category?.id;

            if (terms.length > 0) {
                payload.push({
                    category_id: categoryId,
                    term_values: terms.map(term => term?.value),
                });
            }
        });

        return payload;
    };

    const handleToggle = (value, key) => {
        setPermissions((prevPermissions) =>
            prevPermissions?.map((permission) => {
                if (permission?.value === value) {
                    if (key === 'deny') {
                        // If deny is toggled, set all other permissions to false
                        return {
                            ...permission,
                            deny: !permission.deny,
                            view: false,
                            edit: false,
                            delete: false,
                            redacted: false,
                        };
                    } else {
                        // If any other permission is toggled, set deny to false
                        return {
                            ...permission,
                            [key]: !permission[key],
                            deny: false,
                        };
                    }
                }
                return permission;
            })
        );

        setGetAllGroupsAndUsers((prevPermissions) =>
            prevPermissions.map((permission) => {
                if (permission?.value === value) {
                    if (key === 'deny') {
                        // If deny is toggled, set all other permissions to false
                        return {
                            ...permission,
                            deny: !permission.deny,
                            view: false,
                            edit: false,
                            delete: false,
                            redacted: false,
                        };
                    } else {
                        // If any other permission is toggled, set deny to false
                        return {
                            ...permission,
                            [key]: !permission[key],
                            deny: false,
                        };
                    }
                }
                return permission;
            })
        );
    };

    const handleAddTextField = (
        nameValue,
        label,
        section_id,
        fieldtype,
        options,
        is_mandatory,
        repeatableAtt,
        valueAtt
    ) => {
        const lastId =
            additionalTextFields.length > 0
                ? additionalTextFields[additionalTextFields.length - 1].id
                : null;

        const newTextField = {
            id: lastId + 1, // Generate a unique ID for the new text field
            nameValue: nameValue,
            value: "",
            label: label,
            section_id: section_id, // Set the appropriate tab value
            fieldtype: fieldtype,
            is_mandatory: is_mandatory,
            ...(options && { options: options }), // Conditionally include options if available
        };
        setAdditionalTextFields([...additionalTextFields, newTextField]);
        if (repeatableAtt === true) {
            const updatedValue = Array.isArray(valueAtt)
                ? [...valueAtt, newTextField]
                : newTextField;
            handleChangeForFormData(nameValue, updatedValue, section_id, false, "", true);
        }
    };

    const handleAddTextFieldContent = (
        nameValue,
        label,
        section_id,
        fieldtype,
        options,
        is_mandatory,
        repeatableAtt,
        valueAtt
    ) => {
        const lastId =
            additionalTextFieldsContent.length > 0
                ? additionalTextFieldsContent[additionalTextFieldsContent.length - 1].id
                : null;

        const newTextField = {
            id: lastId + 1, // Generate a unique ID for the new text field
            nameValue: nameValue,
            value: "",
            label: label,
            section_id: section_id, // Set the appropriate tab value
            fieldtype: fieldtype,
            is_mandatory: is_mandatory,
            ...(options && { options: options }), // Conditionally include options if available
        };
        setAdditionalTextFieldsContent([...additionalTextFieldsContent, newTextField]);
        if (repeatableAtt === true) {
            const updatedValue = Array.isArray(valueAtt)
                ? [...valueAtt, newTextField]
                : newTextField;
            handleChangeForFormDataContent(nameValue, updatedValue, section_id, false, "", true);
        }
    };

    const handleAddTextFieldContentAttribute = (
        nameValue,
        label,
        section_id,
        fieldtype,
        options,
        is_mandatory,
        repeatableAtt,
        valueAtt
    ) => {
        const lastId =
            additionalTextFieldsContentAttribute.length > 0
                ? additionalTextFieldsContentAttribute[additionalTextFieldsContentAttribute.length - 1].id
                : null;

        const newTextField = {
            id: lastId + 1, // Generate a unique ID for the new text field
            nameValue: nameValue,
            value: "",
            label: label,
            section_id: section_id, // Set the appropriate tab value
            fieldtype: fieldtype,
            is_mandatory: is_mandatory,
            ...(options && { options: options }), // Conditionally include options if available
        };
        setAdditionalTextFieldsContentAttribute([...additionalTextFieldsContentAttribute, newTextField]);
        if (repeatableAtt === true) {
            const updatedValue = Array.isArray(valueAtt)
                ? [...valueAtt, newTextField]
                : newTextField;
            handleChangeForFormDataContentAttribute(nameValue, updatedValue, section_id, false, "", true);
        }
    };

    const handleAddNestedTextField = (
        parentId,
        nameValue,
        label,
        section_id,
        fieldtype,
        options,
        is_mandatory,
        repeatableAtt,
        valueAtt,
        attdata,
        show_option_value,
        max_repeat,
        nestedChildAtt1
    ) => {

        const fetch_repeatableAtts = additionalTextFieldsForNestedChildAtt1?.filter((data) => data?.nameValue === nameValue)

        if (fetch_repeatableAtts?.length < max_repeat) {
            const lastId =
                additionalTextFieldsForNestedChildAtt1.length > 0
                    ? additionalTextFieldsForNestedChildAtt1[additionalTextFieldsForNestedChildAtt1.length - 1].id
                    : null;

            const newNestedTextField = {
                id: lastId + 1, // Generate a unique ID for the new text field
                parentId: nestedChildAtt1?.parent_id, // Parent ID to link with the parent attribute
                nameValue: nameValue,
                value: "",
                label: label,
                section_id: section_id, // Set the appropriate tab value
                fieldtype: fieldtype,
                is_mandatory: is_mandatory,
                show_option_value: show_option_value,
                ...(options && { options: options }), // Conditionally include options if available
            };

            setAdditionalTextFieldsForNestedChildAtt1([...additionalTextFieldsForNestedChildAtt1, newNestedTextField]);

            if (repeatableAtt === true) {
                const updatedValue = [...additionalTextFieldsForNestedChildAtt1, newNestedTextField]
                handleNestedChildChange(
                    nameValue,
                    updatedValue,
                    section_id,
                    parentId,
                    attdata,
                    show_option_value
                );
            }
        }
    };

    const handleAddNestedTextFieldContent = (
        parentId,
        nameValue,
        label,
        section_id,
        fieldtype,
        options,
        is_mandatory,
        repeatableAtt,
        valueAtt,
        attdata,
        show_option_value,
        max_repeat,
        nestedChildAtt1
    ) => {

        const fetch_repeatableAtts = additionalTextFieldsForNestedChildAtt2?.filter((data) => data?.nameValue === nameValue)

        if (fetch_repeatableAtts?.length < max_repeat) {
            const lastId =
                additionalTextFieldsForNestedChildAtt2.length > 0
                    ? additionalTextFieldsForNestedChildAtt2[additionalTextFieldsForNestedChildAtt2.length - 1].id
                    : null;

            const newNestedTextField = {
                id: lastId + 1, // Generate a unique ID for the new text field
                parentId: nestedChildAtt1?.parent_id, // Parent ID to link with the parent attribute
                nameValue: nameValue,
                value: "",
                label: label,
                section_id: section_id, // Set the appropriate tab value
                fieldtype: fieldtype,
                is_mandatory: is_mandatory,
                show_option_value: show_option_value,
                ...(options && { options: options }), // Conditionally include options if available
            };

            setAdditionalTextFieldsForNestedChildAtt2([...additionalTextFieldsForNestedChildAtt2, newNestedTextField]);

            if (repeatableAtt === true) {
                const updatedValue = [...additionalTextFieldsForNestedChildAtt2, newNestedTextField]
                handleNestedChildChangeContent(
                    nameValue,
                    updatedValue,
                    section_id,
                    parentId,
                    attdata,
                    show_option_value
                );
            }
        }
    };

    const handleAddNestedTextFieldContentAttribute = (
        parentId,
        nameValue,
        label,
        section_id,
        fieldtype,
        options,
        is_mandatory,
        repeatableAtt,
        valueAtt,
        attdata,
        show_option_value,
        max_repeat,
        nestedChildAtt1
    ) => {

        const fetch_repeatableAtts = additionalTextFieldsForNestedChildAtt3?.filter((data) => data?.nameValue === nameValue)

        if (fetch_repeatableAtts?.length < max_repeat) {
            const lastId =
                additionalTextFieldsForNestedChildAtt3.length > 0
                    ? additionalTextFieldsForNestedChildAtt3[additionalTextFieldsForNestedChildAtt3.length - 1].id
                    : null;

            const newNestedTextField = {
                id: lastId + 1, // Generate a unique ID for the new text field
                parentId: nestedChildAtt1?.parent_id, // Parent ID to link with the parent attribute
                nameValue: nameValue,
                value: "",
                label: label,
                section_id: section_id, // Set the appropriate tab value
                fieldtype: fieldtype,
                is_mandatory: is_mandatory,
                show_option_value: show_option_value,
                ...(options && { options: options }), // Conditionally include options if available
            };

            setAdditionalTextFieldsForNestedChildAtt3([...additionalTextFieldsForNestedChildAtt3, newNestedTextField]);

            if (repeatableAtt === true) {
                const updatedValue = [...additionalTextFieldsForNestedChildAtt3, newNestedTextField]
                handleNestedChildChangeContentAttribute(
                    nameValue,
                    updatedValue,
                    section_id,
                    parentId,
                    attdata,
                    show_option_value
                );
            }
        }
    };

    const handleChangeForFormData = (
        nameValue,
        value,
        section_id,
        isChildAtt,
        subField,
        isAppendDirectly,
        mediaData
    ) => {

        setAttributes((prevAttributes) => {
            const updatedAttributes = prevAttributes?.map((attribute) => {
                if (
                    attribute?.section_id === section_id &&
                    attribute?.nameValue === nameValue
                ) {
                    // Update the value of the attribute
                    let updatedAttribute = { ...attribute, value };

                    if (attribute?.fieldtype === "link_object" && isAppendDirectly === false) {
                        const currentLinkObject = attribute?.value || {};
                        updatedAttribute.value = {
                            ...currentLinkObject,
                            [subField]: value
                        };

                    } else {
                        updatedAttribute.value = value;
                        // If the fieldtype is radio_single_choice and options are provided
                        if (
                            attribute?.fieldtype === "radio_single_choice" &&
                            attribute?.options
                        ) {
                            const updatedOptions = attribute?.options?.map((option) => {
                                // Set the checked property based on the selected value
                                return { ...option, checked: option?.value == parseInt(value) };
                            });
                            // Update the options of the attribute
                            updatedAttribute = { ...updatedAttribute, options: updatedOptions };
                        }

                        if (attribute.fieldtype === "tags") {
                            // Initialize options with current options or an empty array
                            let options = attribute?.options || [];

                            // Separate existing and new options
                            const existingOptions = value?.filter((option) => !option.__isNew__);
                            const newOptions = value?.filter((option) => option?.__isNew__);

                            // Add new options to the existing options list
                            if (newOptions?.length > 0) {
                                options = [
                                    ...options,
                                    ...newOptions?.map((option) => ({
                                        value: option?.value,
                                        label: option?.label,
                                    })),
                                ];
                            }

                            // Extract selected values from the value array
                            const selectedValues = value?.map((option) => option?.value);

                            // Update the attribute with new options and selected values
                            updatedAttribute = {
                                ...updatedAttribute,
                                options: options,
                                value: selectedValues,
                            };
                        }

                        // If the fieldtype is checkbox_multiple_choice
                        if (
                            attribute?.fieldtype === "checkbox_multiple_choice" &&
                            attribute?.options
                        ) {
                            const updatedOptions = attribute?.options?.map((option) => {
                                // Set the checked property based on whether the option ID is included in the selected values array
                                return { ...option, checked: value?.includes(option?.id) };
                            });
                            // Update the options of the attribute
                            updatedAttribute = { ...updatedAttribute, options: updatedOptions };
                        }

                        if (attribute?.fieldtype === "image") {
                            updatedAttribute = {
                                ...updatedAttribute,
                                url: mediaData?.file_path,
                                media_folder_id: selectedMediaFolderIDForImageAtt,
                                is_system: selectedMediaTypeForImageAtt == "my-media" ? 0 : 1,
                                file_size: mediaData?.file_size,
                                title: mediaData?.title
                            };
                        }

                        if (attribute?.fieldtype === "file") {
                            updatedAttribute = {
                                ...updatedAttribute,
                                name: mediaData?.title,
                                media_folder_id: selectedMediaFolderIDFordocAtt,
                                is_system: selectedMediaTypeForDocAtt == "my-media" ? 0 : 1,
                                file_size: mediaData?.file_size,
                                title: mediaData?.title
                            };
                        }

                        if (attribute?.fieldtype === "image_file") {
                            console.log("line 1313")
                            updatedAttribute = {
                                ...updatedAttribute,
                                url: mediaData?.file_path,
                                media_folder_id: selectedMediaFolderIDForImageAtt,
                                is_system: selectedMediaTypeForImageAtt == "my-media" ? 0 : 1,
                                file_size: mediaData?.file_size,
                                title: mediaData?.title,
                                name: mediaData?.title,
                                type: mediaData?.type,
                                value: mediaData?.id
                            };
                        }

                        if (isChildAtt == true) {
                            updatedAttribute = { ...updatedAttribute, childAtts: value };
                        }
                    }

                    return updatedAttribute;
                }
                return attribute;
            });
            return updatedAttributes;
        });
    };

    const handleChangeForFormDataContent = (
        nameValue,
        value,
        section_id,
        isChildAtt,
        subField,
        isAppendDirectly,
        mediaData
    ) => {
        console.log("line 1224")
        console.log("value::", value)
        setContentAttributeData((prevAttributes) => {
            const updatedAttributes = prevAttributes?.map((attribute) => {
                if (
                    attribute?.nameValue === nameValue
                ) {
                    // Update the value of the attribute
                    let updatedAttribute = { ...attribute, value };
                    updatedAttribute.value = value;
                    console.log("value::", value)
                    // If the fieldtype is radio_single_choice and options are provided
                    if (
                        attribute?.fieldtype === "radio_single_choice" &&
                        attribute?.options
                    ) {
                        const updatedOptions = attribute?.options?.map((option) => {
                            // Set the checked property based on the selected value
                            return { ...option, checked: option?.value == parseInt(value) };
                        });
                        // Update the options of the attribute
                        updatedAttribute = { ...updatedAttribute, options: updatedOptions };
                    }

                    if (attribute.fieldtype === "tags") {
                        // Initialize options with current options or an empty array
                        let options = attribute?.options || [];

                        // Separate existing and new options
                        const existingOptions = value?.filter((option) => !option.__isNew__);
                        const newOptions = value?.filter((option) => option?.__isNew__);

                        // Add new options to the existing options list
                        if (newOptions?.length > 0) {
                            options = [
                                ...options,
                                ...newOptions?.map((option) => ({
                                    value: option?.value,
                                    label: option?.label,
                                })),
                            ];
                        }

                        // Extract selected values from the value array
                        const selectedValues = value?.map((option) => option?.value);

                        // Update the attribute with new options and selected values
                        updatedAttribute = {
                            ...updatedAttribute,
                            options: options,
                            value: selectedValues,
                        };
                    }

                    // If the fieldtype is checkbox_multiple_choice
                    if (
                        attribute?.fieldtype === "checkbox_multiple_choice" &&
                        attribute?.options
                    ) {
                        const updatedOptions = attribute?.options?.map((option) => {
                            // Set the checked property based on whether the option ID is included in the selected values array
                            return { ...option, checked: value?.includes(option?.id) };
                        });
                        // Update the options of the attribute
                        updatedAttribute = { ...updatedAttribute, options: updatedOptions };
                    }

                    if (attribute?.fieldtype === "image") {
                        updatedAttribute = {
                            ...updatedAttribute,
                            url: value?.file_path,
                            media_folder_id: selectedMediaFolderIDForImageAtt,
                            is_system: selectedMediaTypeForImageAtt == "my-media" ? 0 : 1,
                            file_size: value?.file_size,
                            title: value?.title
                        };
                    }

                    if (attribute?.fieldtype === "file") {
                        updatedAttribute = {
                            ...updatedAttribute,
                            name: value?.title,
                            media_folder_id: selectedMediaFolderIDFordocAtt,
                            is_system: selectedMediaTypeForDocAtt == "my-media" ? 0 : 1,
                            file_size: value?.file_size,
                            title: value?.title
                        };
                    }

                    if (attribute?.fieldtype === "image_file") {
                        console.log("line 1313")
                        updatedAttribute = {
                            ...updatedAttribute,
                            url: value?.file_path,
                            media_folder_id: selectedMediaFolderIDForImageAtt,
                            is_system: selectedMediaTypeForImageAtt == "my-media" ? 0 : 1,
                            file_size: value?.file_size,
                            title: value?.title,
                            name: value?.title,
                            type: value?.type,
                            value: value?.id
                        };
                    }

                    return updatedAttribute;
                }
                return attribute;
            });
            return updatedAttributes;
        });
        handleCloseForUploadMediaForAtt()
    };

    const handleChangeForFormDataContentAttribute = (
        nameValue,
        value,
        section_id,
        isChildAtt,
        subField,
        isAppendDirectly,
        mediaData
    ) => {

        setContentAndAttributeData((prevAttributes) => {
            const updatedAttributes = prevAttributes?.map((attribute) => {
                if (
                    attribute?.section_id === section_id &&
                    attribute?.nameValue === nameValue
                ) {
                    // Update the value of the attribute
                    let updatedAttribute = { ...attribute, value };

                    if (attribute?.fieldtype === "link_object" && isAppendDirectly == false) {
                        const currentLinkObject = attribute?.value || {};
                        updatedAttribute.value = {
                            ...currentLinkObject,
                            [subField]: value
                        };

                    } else {
                        updatedAttribute.value = value;
                        console.log("value::", value)
                        // If the fieldtype is radio_single_choice and options are provided
                        if (
                            attribute?.fieldtype === "radio_single_choice" &&
                            attribute?.options
                        ) {
                            const updatedOptions = attribute?.options?.map((option) => {
                                // Set the checked property based on the selected value
                                return { ...option, checked: option?.value == parseInt(value) };
                            });
                            // Update the options of the attribute
                            updatedAttribute = { ...updatedAttribute, options: updatedOptions };
                        }

                        if (attribute.fieldtype === "tags") {
                            // Initialize options with current options or an empty array
                            let options = attribute?.options || [];

                            // Separate existing and new options
                            const existingOptions = value?.filter((option) => !option.__isNew__);
                            const newOptions = value?.filter((option) => option?.__isNew__);

                            // Add new options to the existing options list
                            if (newOptions?.length > 0) {
                                options = [
                                    ...options,
                                    ...newOptions?.map((option) => ({
                                        value: option?.value,
                                        label: option?.label,
                                    })),
                                ];
                            }

                            // Extract selected values from the value array
                            const selectedValues = value?.map((option) => option?.value);

                            // Update the attribute with new options and selected values
                            updatedAttribute = {
                                ...updatedAttribute,
                                options: options,
                                value: selectedValues,
                            };
                        }

                        // If the fieldtype is checkbox_multiple_choice
                        if (
                            attribute?.fieldtype === "checkbox_multiple_choice" &&
                            attribute?.options
                        ) {
                            const updatedOptions = attribute?.options?.map((option) => {
                                // Set the checked property based on whether the option ID is included in the selected values array
                                return { ...option, checked: value?.includes(option?.id) };
                            });
                            // Update the options of the attribute
                            updatedAttribute = { ...updatedAttribute, options: updatedOptions };
                        }

                        if (attribute?.fieldtype === "image") {
                            updatedAttribute = {
                                ...updatedAttribute,
                                url: mediaData?.file_path,
                                media_folder_id: selectedMediaFolderIDForImageAtt,
                                is_system: selectedMediaTypeForImageAtt == "my-media" ? 0 : 1,
                                file_size: mediaData?.file_size,
                                title: mediaData?.title
                            };
                        }

                        if (attribute?.fieldtype === "file") {
                            updatedAttribute = {
                                ...updatedAttribute,
                                name: mediaData?.title,
                                media_folder_id: selectedMediaFolderIDFordocAtt,
                                is_system: selectedMediaTypeForDocAtt == "my-media" ? 0 : 1,
                                file_size: mediaData?.file_size,
                                title: mediaData?.title
                            };
                        }

                        if (attribute?.fieldtype === "image_file") {
                            // console.log("line 1313")
                            updatedAttribute = {
                                ...updatedAttribute,
                                url: mediaData?.file_path,
                                media_folder_id: selectedMediaFolderIDForImageAtt,
                                is_system: selectedMediaTypeForImageAtt == "my-media" ? 0 : 1,
                                file_size: mediaData?.file_size,
                                title: mediaData?.title,
                                name: mediaData?.title,
                                type: mediaData?.type,
                                value: mediaData?.id
                            };
                        }

                        if (isChildAtt == true) {
                            updatedAttribute = { ...updatedAttribute, childAtts: value };
                        }
                    }

                    return updatedAttribute;
                }
                return attribute;
            });
            return updatedAttributes;
        });
    };

    const handleChangeForChildAtts = (
        nameValue,
        value,
        section_id,
        childAtts,
        attdata
    ) => {
        const findAtt = attributesData
            .filter((data) => data?.id == attdata?.id)
            .map((data) => {
                return {
                    id: data?.id,
                    section_id: data?.section_id,
                    label: data?.label,
                    is_mandatory: data?.is_mandatory,
                    nameValue: data?.nameValue,
                    fieldtype: data?.fieldtype,
                    options: data?.options,
                    parentID: data?.parentID,
                    child_attribute: data?.child_attribute,
                    value: data?.value,
                };
            });

        const updatedChildAtts = findAtt.flatMap((att) => {
            return att?.child_attribute?.map((childAtt) => {
                console.log("childAtt data::", childAtt)
                if (
                    childAtt?.section_id === section_id &&
                    childAtt?.nameValue === nameValue
                ) {
                    // If the fieldtype is checkbox_multiple_choice
                    if (
                        childAtt?.fieldtype === "checkbox_multiple_choice" &&
                        childAtt?.options
                    ) {
                        const updatedOptions = childAtt?.options?.map((option) => {
                            // Set the checked property based on whether the option ID is included in the selected values array
                            return { ...option, checked: value?.includes(option?.id) };
                        });

                        // Update the options of the child attribute
                        return { ...childAtt, options: updatedOptions, value };
                    } else {
                        // If the fieldtype is not checkbox_multiple_choice, simply update the value
                        return { ...childAtt, value };
                    }
                }
                return childAtt;
            });
        });

        const updatedAttData = attributesData.map((att) => {
            if (att?.id == attdata?.id) {
                return { ...att, child_attribute: updatedChildAtts };
            }
            return att;
        });
        setAttributes(updatedAttData);
    };

    const handleNestedChildChange = (
        nameValue,
        value,
        section_id,
        parentChildAtt,
        attdata,
        show_option_value
    ) => {
        console.log("parentChildAtt::", parentChildAtt)
        console.log("attdata nested::", attdata)
        console.log("nameValue nested::", nameValue)
        console.log("value nested::", value)
        console.log("section_id nested::", section_id)
        console.log("show_option_value nested::", show_option_value)

        const findAtt = attributesData
            .filter((data) => data?.id == attdata?.id)
            .map((data) => {
                return {
                    id: data?.id,
                    section_id: data?.section_id,
                    label: data?.label,
                    is_mandatory: data?.is_mandatory,
                    nameValue: data?.nameValue,
                    fieldtype: data?.fieldtype,
                    options: data?.options,
                    parentID: data?.parentID,
                    child_attribute: data?.child_attribute,
                    value: data?.value,
                };
            });

        console.log("findAtt::", findAtt)

        const updatedChildAtts = findAtt?.flatMap((att) => {
            return att?.child_attribute?.map((childAtt) => {

                if (
                    childAtt?.section_id === section_id &&
                    childAtt?.nameValue === parentChildAtt?.nameValue
                ) {
                    const updatedNestedChildAtts = childAtt?.child_attribute?.map((nestedChildAtt) => {
                        if (
                            nestedChildAtt?.section_id === section_id &&
                            nestedChildAtt?.nameValue === nameValue &&
                            nestedChildAtt?.show_option_value == show_option_value
                        ) {
                            return { ...nestedChildAtt, value };
                        }
                        return nestedChildAtt;
                    });

                    return { ...childAtt, child_attribute: updatedNestedChildAtts };
                }
                return childAtt;
            });
        });

        const updatedAttData = attributesData.map((att) => {
            if (att?.id == attdata?.id) {
                return { ...att, child_attribute: updatedChildAtts };
            }
            return att;
        });

        setAttributes(updatedAttData);

    };

    const handleNestedChildChangeContent = (
        nameValue,
        value,
        section_id,
        parentChildAtt,
        attdata,
        show_option_value
    ) => {
        console.log("parentChildAtt::", parentChildAtt)
        console.log("attdata nested::", attdata)
        console.log("nameValue nested::", nameValue)
        console.log("value nested::", value)
        console.log("section_id nested::", section_id)
        console.log("show_option_value nested::", show_option_value)

        const findAtt = contentAttributeData
            .filter((data) => data?.id == attdata?.id)
            .map((data) => {
                return {
                    id: data?.id,
                    section_id: data?.section_id,
                    label: data?.label,
                    is_mandatory: data?.is_mandatory,
                    nameValue: data?.nameValue,
                    fieldtype: data?.fieldtype,
                    options: data?.options,
                    parentID: data?.parentID,
                    child_attribute: data?.child_attribute,
                    value: data?.value,
                };
            });

        console.log("findAtt::", findAtt)

        const updatedChildAtts = findAtt?.flatMap((att) => {
            return att?.child_attribute?.map((childAtt) => {

                if (
                    childAtt?.section_id === section_id &&
                    childAtt?.nameValue === parentChildAtt?.nameValue
                ) {
                    const updatedNestedChildAtts = childAtt?.child_attribute?.map((nestedChildAtt) => {
                        if (
                            nestedChildAtt?.section_id === section_id &&
                            nestedChildAtt?.nameValue === nameValue &&
                            nestedChildAtt?.show_option_value == show_option_value
                        ) {
                            return { ...nestedChildAtt, value };
                        }
                        return nestedChildAtt;
                    });

                    return { ...childAtt, child_attribute: updatedNestedChildAtts };
                }
                return childAtt;
            });
        });

        const updatedAttData = contentAttributeData.map((att) => {
            if (att?.id == attdata?.id) {
                return { ...att, child_attribute: updatedChildAtts };
            }
            return att;
        });

        setContentAttributeData(updatedAttData);

    }

    const handleNestedChildChangeContentAttribute = (
        nameValue,
        value,
        section_id,
        parentChildAtt,
        attdata,
        show_option_value
    ) => {
        console.log("parentChildAtt::", parentChildAtt)
        console.log("attdata nested::", attdata)
        console.log("nameValue nested::", nameValue)
        console.log("value nested::", value)
        console.log("section_id nested::", section_id)
        console.log("show_option_value nested::", show_option_value)

        const findAtt = conterntAndAttributeData
            .filter((data) => data?.id == attdata?.id)
            .map((data) => {
                return {
                    id: data?.id,
                    section_id: data?.section_id,
                    label: data?.label,
                    is_mandatory: data?.is_mandatory,
                    nameValue: data?.nameValue,
                    fieldtype: data?.fieldtype,
                    options: data?.options,
                    parentID: data?.parentID,
                    child_attribute: data?.child_attribute,
                    value: data?.value,
                };
            });

        console.log("findAtt::", findAtt)

        const updatedChildAtts = findAtt?.flatMap((att) => {
            return att?.child_attribute?.map((childAtt) => {

                if (
                    childAtt?.section_id === section_id &&
                    childAtt?.nameValue === parentChildAtt?.nameValue
                ) {
                    const updatedNestedChildAtts = childAtt?.child_attribute?.map((nestedChildAtt) => {
                        if (
                            nestedChildAtt?.section_id === section_id &&
                            nestedChildAtt?.nameValue === nameValue &&
                            nestedChildAtt?.show_option_value == show_option_value
                        ) {
                            return { ...nestedChildAtt, value };
                        }
                        return nestedChildAtt;
                    });

                    return { ...childAtt, child_attribute: updatedNestedChildAtts };
                }
                return childAtt;
            });
        });

        const updatedAttData = conterntAndAttributeData.map((att) => {
            if (att?.id == attdata?.id) {
                return { ...att, child_attribute: updatedChildAtts };
            }
            return att;
        });

        setContentAndAttributeData(updatedAttData);

    };

    const handleChangeForChildAtts2 = (
        nameValue,
        value,
        section_id,
        childAtts,
        attdata
    ) => {
        const updatedChildAtts = childAtts.map((childAtt) => {
            if (
                childAtt.section_id === section_id &&
                childAtt.nameValue === nameValue
            ) {
                if (
                    childAtt.fieldtype === "checkbox_multiple_choice" &&
                    childAtt.options
                ) {
                    const updatedOptions = childAtt.options.map((option) => {
                        return { ...option, checked: value.includes(option.id) };
                    });
                    return { ...childAtt, options: updatedOptions, value };
                } else {
                    return { ...childAtt, value };
                }
            }
            return childAtt;
        });

        const updatedAttData = attributesData.map((att) => {
            if (att.id === attdata.id) {
                return { ...att, childAtts: updatedChildAtts };
            }
            return att;
        });
    };

    const handleChangeForAdditionalTextField = (
        id,
        value,
        nameValue,
        section_id,
        fieldtype,
        subField
    ) => {
        let updatedTextFields;
        if (fieldtype === "link_object") {
            updatedTextFields = additionalTextFields?.map((field) =>
                field?.id == id && field?.nameValue == nameValue
                    ? { ...field, value: { ...field?.value, [subField]: value } }
                    : field
            );
        } else {
            updatedTextFields = additionalTextFields?.map((field) =>
                field?.id == id && field?.nameValue == nameValue
                    ? { ...field, value: value }
                    : field
            );
        }

        setAdditionalTextFields(updatedTextFields);

        const finalData = updatedTextFields?.filter(
            (data) => data?.nameValue == nameValue
        );

        handleChangeForFormData(nameValue, finalData, section_id, false, "", true);
    };

    const handleChangeForAdditionalTextFieldContent = (
        id,
        value,
        nameValue,
        section_id,
        fieldtype,
        subField
    ) => {
        let updatedTextFields;
        if (fieldtype === "link_object") {
            updatedTextFields = additionalTextFieldsContent?.map((field) =>
                field?.id == id && field?.nameValue == nameValue
                    ? { ...field, value: { ...field?.value, [subField]: value } }
                    : field
            );
        } else {
            updatedTextFields = additionalTextFieldsContent?.map((field) =>
                field?.id == id && field?.nameValue == nameValue
                    ? { ...field, value: value }
                    : field
            );
        }

        setAdditionalTextFieldsContent(updatedTextFields);

        const finalData = updatedTextFields?.filter(
            (data) => data?.nameValue == nameValue
        );

        handleChangeForFormDataContent(nameValue, finalData, section_id, false, "", true);
    };

    const handleChangeForAdditionalTextFieldContentAttribute = (
        id,
        value,
        nameValue,
        section_id,
        fieldtype,
        subField
    ) => {
        let updatedTextFields;
        if (fieldtype === "link_object") {
            updatedTextFields = additionalTextFieldsContentAttribute?.map((field) =>
                field?.id == id && field?.nameValue == nameValue
                    ? { ...field, value: { ...field?.value, [subField]: value } }
                    : field
            );
        } else {
            updatedTextFields = additionalTextFieldsContentAttribute?.map((field) =>
                field?.id == id && field?.nameValue == nameValue
                    ? { ...field, value: value }
                    : field
            );
        }

        setAdditionalTextFieldsContentAttribute(updatedTextFields);

        const finalData = updatedTextFields?.filter(
            (data) => data?.nameValue == nameValue
        );

        handleChangeForFormDataContentAttribute(nameValue, finalData, section_id, false, "", true);
    };

    const handleChangeForNestedChildAdditionalTextField = (
        id,
        value,
        nameValue,
        section_id,
        fieldtype,
        subField,
        parentId,
        attdata,
        show_option_value,
        textField
    ) => {
        console.log("parentId:::", parentId)
        console.log("attdata:::", attdata)
        console.log("value:::", value)
        console.log("id:::", id)
        console.log("nameValue:::", nameValue)


        let updatedTextFields;
        if (fieldtype === "link_object") {
            updatedTextFields = additionalTextFieldsForNestedChildAtt1?.map((field) =>
                field?.id == id && field?.nameValue == nameValue
                    ? { ...field, value: { ...field?.value, [subField]: value } }
                    : field
            );
        } else {
            updatedTextFields = additionalTextFieldsForNestedChildAtt1?.map((field) =>
                field?.id == id && field?.nameValue == nameValue
                    ? { ...field, value: value }
                    : field
            );
        }

        setAdditionalTextFieldsForNestedChildAtt1(updatedTextFields);

        const anotherUpdatedNestedTextFields = updatedTextFields?.filter(
            (field) => field?.nameValue == nameValue && field?.parentId == parentId?.attribute_id
        );

        console.log("anotherUpdatedNestedTextFields::", anotherUpdatedNestedTextFields)

        handleNestedChildChange(nameValue, anotherUpdatedNestedTextFields, section_id, parentId, attdata, show_option_value);
    };

    const handleChangeForNestedChildAdditionalTextFieldContent = (
        id,
        value,
        nameValue,
        section_id,
        fieldtype,
        subField,
        parentId,
        attdata,
        show_option_value,
        textField
    ) => {
        console.log("parentId:::", parentId)
        console.log("attdata:::", attdata)
        console.log("value:::", value)
        console.log("id:::", id)
        console.log("nameValue:::", nameValue)


        let updatedTextFields;
        if (fieldtype === "link_object") {
            updatedTextFields = additionalTextFieldsForNestedChildAtt2?.map((field) =>
                field?.id == id && field?.nameValue == nameValue
                    ? { ...field, value: { ...field?.value, [subField]: value } }
                    : field
            );
        } else {
            updatedTextFields = additionalTextFieldsForNestedChildAtt2?.map((field) =>
                field?.id == id && field?.nameValue == nameValue
                    ? { ...field, value: value }
                    : field
            );
        }

        setAdditionalTextFieldsForNestedChildAtt2(updatedTextFields);

        const anotherUpdatedNestedTextFields = updatedTextFields?.filter(
            (field) => field?.nameValue == nameValue && field?.parentId == parentId?.attribute_id
        );

        console.log("anotherUpdatedNestedTextFields::", anotherUpdatedNestedTextFields)

        handleNestedChildChangeContent(nameValue, anotherUpdatedNestedTextFields, section_id, parentId, attdata, show_option_value);
    };

    const handleChangeForNestedChildAdditionalTextFieldContentattribute = (
        id,
        value,
        nameValue,
        section_id,
        fieldtype,
        subField,
        parentId,
        attdata,
        show_option_value,
        textField
    ) => {
        console.log("parentId:::", parentId)
        console.log("attdata:::", attdata)
        console.log("value:::", value)
        console.log("id:::", id)
        console.log("nameValue:::", nameValue)


        let updatedTextFields;
        if (fieldtype === "link_object") {
            updatedTextFields = additionalTextFieldsForNestedChildAtt3?.map((field) =>
                field?.id == id && field?.nameValue == nameValue
                    ? { ...field, value: { ...field?.value, [subField]: value } }
                    : field
            );
        } else {
            updatedTextFields = additionalTextFieldsForNestedChildAtt3?.map((field) =>
                field?.id == id && field?.nameValue == nameValue
                    ? { ...field, value: value }
                    : field
            );
        }

        setAdditionalTextFieldsForNestedChildAtt3(updatedTextFields);

        const anotherUpdatedNestedTextFields = updatedTextFields?.filter(
            (field) => field?.nameValue == nameValue && field?.parentId == parentId?.attribute_id
        );

        console.log("anotherUpdatedNestedTextFields::", anotherUpdatedNestedTextFields)

        handleNestedChildChangeContentAttribute(nameValue, anotherUpdatedNestedTextFields, section_id, parentId, attdata, show_option_value);
    };

    const removeExtraFields = (id, nameValue, section_id) => {

        const updatedTextFields = additionalTextFields?.filter(
            (field) => !(field?.id == id && field?.nameValue == nameValue)
        );
        setAdditionalTextFields(updatedTextFields);
        const anotherupdatedTextFields = updatedTextFields?.filter((field) => field?.nameValue == nameValue)
        handleChangeForFormData(nameValue, anotherupdatedTextFields, section_id, false, "", true);
    };

    const removeExtraFieldsContent = (id, nameValue, section_id) => {

        const updatedTextFields = additionalTextFieldsContent?.filter(
            (field) => !(field?.id == id && field?.nameValue == nameValue)
        );
        setAdditionalTextFieldsContent(updatedTextFields);
        const anotherupdatedTextFields = updatedTextFields?.filter((field) => field?.nameValue == nameValue)
        handleChangeForFormDataContent(nameValue, anotherupdatedTextFields, section_id, false, "", true);
    };

    const removeExtraFieldsContentAttribute = (id, nameValue, section_id) => {

        const updatedTextFields = additionalTextFieldsContentAttribute?.filter(
            (field) => !(field?.id == id && field?.nameValue == nameValue)
        );
        setAdditionalTextFieldsContentAttribute(updatedTextFields);
        const anotherupdatedTextFields = updatedTextFields?.filter((field) => field?.nameValue == nameValue)
        handleChangeForFormDataContentAttribute(nameValue, anotherupdatedTextFields, section_id, false, "", true);
    };

    const removeNestedExtraFields = (id, nameValue, section_id, parentId, show_option_value, attdata) => {
        console.log("attdata remove nested::", attdata)
        // Filter out the nested text field to be removed
        const updatedNestedTextFields = additionalTextFieldsForNestedChildAtt1?.filter(
            (field) => !(field?.id === id && field?.nameValue === nameValue && field?.parentId === parentId?.attribute_id)
        );

        // Update the state
        setAdditionalTextFieldsForNestedChildAtt1(updatedNestedTextFields);

        // Filter the updated text fields for the specific nameValue

        const anotherUpdatedNestedTextFields = updatedNestedTextFields?.filter(
            (field) => field?.nameValue == nameValue && field?.parentId == parentId?.attribute_id
        );

        console.log("anotherUpdatedNestedTextFields:::", anotherUpdatedNestedTextFields)

        // Update the form data with the new list of nested text fields
        handleNestedChildChange(nameValue, anotherUpdatedNestedTextFields, section_id, parentId, attdata, show_option_value);
    };

    const removeNestedExtraFieldsContent = (id, nameValue, section_id, parentId, show_option_value, attdata) => {
        console.log("attdata remove nested::", attdata)
        // Filter out the nested text field to be removed
        const updatedNestedTextFields = additionalTextFieldsForNestedChildAtt2?.filter(
            (field) => !(field?.id === id && field?.nameValue === nameValue && field?.parentId === parentId?.attribute_id)
        );

        // Update the state
        setAdditionalTextFieldsForNestedChildAtt2(updatedNestedTextFields);

        // Filter the updated text fields for the specific nameValue

        const anotherUpdatedNestedTextFields = updatedNestedTextFields?.filter(
            (field) => field?.nameValue == nameValue && field?.parentId == parentId?.attribute_id
        );

        console.log("anotherUpdatedNestedTextFields:::", anotherUpdatedNestedTextFields)

        // Update the form data with the new list of nested text fields
        handleNestedChildChangeContent(nameValue, anotherUpdatedNestedTextFields, section_id, parentId, attdata, show_option_value);
    };

    const removeNestedExtraFieldsContentAttribute = (id, nameValue, section_id, parentId, show_option_value, attdata) => {
        console.log("attdata remove nested::", attdata)
        // Filter out the nested text field to be removed
        const updatedNestedTextFields = additionalTextFieldsForNestedChildAtt3?.filter(
            (field) => !(field?.id === id && field?.nameValue === nameValue && field?.parentId === parentId?.attribute_id)
        );

        // Update the state
        setAdditionalTextFieldsForNestedChildAtt3(updatedNestedTextFields);

        // Filter the updated text fields for the specific nameValue

        const anotherUpdatedNestedTextFields = updatedNestedTextFields?.filter(
            (field) => field?.nameValue == nameValue && field?.parentId == parentId?.attribute_id
        );

        console.log("anotherUpdatedNestedTextFields:::", anotherUpdatedNestedTextFields)

        // Update the form data with the new list of nested text fields
        handleNestedChildChangeContentAttribute(nameValue, anotherUpdatedNestedTextFields, section_id, parentId, attdata, show_option_value);
    };

    const handleCloseWarningMessage = (index) => {
        const updatedMessages = [...warningMessage];
        updatedMessages.splice(index, 1);
        setWarningMessage(updatedMessages);
    };

    const handleSelectAttribute = (attdata) => {
        setAttributes((prev) => {
            // Check if the clicked item is already in the state
            const exists = prev.some((item) => item.attribute_id === attdata.attribute_id);
            if (exists) {
                // Remove the item if it exists
                return prev.filter((item) => item.attribute_id !== attdata.attribute_id);
            } else {
                // Add the item if it doesn't exist
                return [...prev, attdata];
            }
        });
    }

    const handleOpen = () => setOpenFileUploadModel(true);
    const handleOpenForAtt = () => setOpenFileUploadModelForAtt(true);

    const handleClose = () => setOpenFileUploadModel(false);
    const handleCloseForAtt = () => {
        setOpenFileUploadModelForAtt(false)
        setFileForContent(false)
    };

    const typeName = localStorage.getItem("type-selected-name");
    const typeID = localStorage.getItem("type-selected-id");
    const typeSlug = localStorage.getItem("type-selected-slug");

    const imageFileTypes = ["JPG", "PNG", "GIF"];

    const fileTypes = ["docx", "pdf"];

    let newWarningMessages = [];

    const downloadFileForImage = async () => {

        var blob = new Blob([selectedMediaFileDetailsForImage?.file_path], {
            type: getMimeType(selectedMediaFileDetailsForImage?.type)
        });
        FileSaver.saveAs(blob, selectedMediaFileDetailsForImage?.title);

    };

    const downloadFileForImageAtt = async () => {

        var blob = new Blob([selectedMediaFileDetailsForImageAtt?.file_path], {
            type: getMimeType(selectedMediaFileDetailsForImageAtt?.type)
        });
        FileSaver.saveAs(blob, selectedMediaFileDetailsForImageAtt?.title);

    };

    const downloadFileFordoc = async () => {

        var blob = new Blob([selectedMediaFileDetailsForDoc?.file_path], {
            type: getMimeType(selectedMediaFileDetailsForDoc?.type)
        });
        FileSaver.saveAs(blob, selectedMediaFileDetailsForDoc?.title);

    };

    const downloadFileFordocAtt = async () => {

        var blob = new Blob([selectedMediaFileDetailsForDocAtt?.file_path], {
            type: getMimeType(selectedMediaFileDetailsForDocAtt?.type)
        });
        FileSaver.saveAs(blob, selectedMediaFileDetailsForDocAtt?.title);

    };

    const handleFileChange = (file, setFieldValue) => {
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (contentForPost === "image") {
                    setIsEdit(false);
                    setSelectedImageFile(file);
                } else if (contentForPost === "file") {
                    setSelectedFile(file);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImageFileValue = (setFieldValue) => {
        if (
            selectedImageFile instanceof Blob ||
            selectedImageFile instanceof File ||
            selectedFile instanceof Blob ||
            selectedFile instanceof File
        ) {
            if (contentForPost == "image") {
                setFieldValue("image", URL.createObjectURL(selectedImageFile));
                handleChangeForFormData(
                    attData?.nameValue,
                    URL.createObjectURL(selectedImageFile),
                    attData?.section_id
                );
                setOpenFileUploadModel(false);

            } else {
                setFieldValue("file", selectedFile);
                handleChangeForFormData(
                    attData?.nameValue,
                    selectedFile,
                    attData?.section_id
                );

                setOpenFileUploadModel(false);

            }
        } else {
            console.error("Selected file is not a valid Blob or File object");
        }
    };

    const handleClick = (content, attdatas) => {
        setContentForPost(content);
        setAttData(attdatas);
        setOpenFileUploadModel(true);
        if (
            selectedImageFile instanceof Blob ||
            selectedImageFile instanceof File ||
            selectedFile instanceof Blob ||
            selectedFile instanceof File
        ) {
            setIsEdit(false)
        } else {
            setIsEdit(true)
        }
    };

    const handleClickForAtt = (content, attdatas) => {
        setContentForPostForAtt(content);
        setAttData(attdatas);
        setOpenFileUploadModelForAtt(true);
        if (
            selectedImageFileForAtt instanceof Blob ||
            selectedImageFileForAtt instanceof File ||
            selectedFileForAtt instanceof Blob ||
            selectedFileForAtt instanceof File
        ) {
            setIsEdit(false)
        } else {
            setIsEdit(true)
        }
        if (attdatas?.value !== "") {
            if (content == "image") {
                setSelectedImageFileForAtt(selectedImageFileForAtt);
            }
            if (content == "file") {
                setSelectedFileForAtt(selectedFileForAtt);
            }
        }
    };

    const handleClickForAttContent = (content, attdatas) => {
        setContentForPostForAtt(content);
        setAttData(attdatas);
        setOpenFileUploadModelForAtt(true);
        if (
            selectedImageFileForAtt instanceof Blob ||
            selectedImageFileForAtt instanceof File ||
            selectedFileForAtt instanceof Blob ||
            selectedFileForAtt instanceof File
        ) {
            setIsEdit(false)
        } else {
            setIsEdit(true)
        }
        if (attdatas?.value !== "") {
            if (content == "image") {
                setSelectedImageFileForAtt(selectedImageFileForAtt);
            }
            if (content == "file") {
                setSelectedFileForAtt(selectedFileForAtt);
            }
        }
    };

    const handleClickForAttContentAttribute = (content, attdatas) => {
        setContentForPostForAtt(content);
        setAttData(attdatas);
        setOpenFileUploadModelForAtt(true);
        if (
            selectedImageFileForAtt instanceof Blob ||
            selectedImageFileForAtt instanceof File ||
            selectedFileForAtt instanceof Blob ||
            selectedFileForAtt instanceof File
        ) {
            setIsEdit(false)
        } else {
            setIsEdit(true)
        }
        if (attdatas?.value !== "") {
            if (content == "image") {
                setSelectedImageFileForAtt(selectedImageFileForAtt);
            }
            if (content == "file") {
                setSelectedFileForAtt(selectedFileForAtt);
            }
        }
    };

    const handleFileChangeForAtt = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (contentForPostForAtt == "image") {
                    setIsEdit(false);
                    setSelectedImageFileForAtt(file);
                } else if (contentForPostForAtt == "file") {
                    setSelectedFileForAtt(file);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImageFileValueForAtt = () => {
        if (fileForContent === true) {
            handleChangeForFormDataContent(
                attData?.nameValue,
                selectedMediaFileDetailsForAtt,
                attData?.section_id
            );

            setOpenFileUploadModelForAtt(false);

        } else {
            if (
                selectedImageFileForAtt instanceof Blob ||
                selectedImageFileForAtt instanceof File ||
                selectedFileForAtt instanceof Blob ||
                selectedFileForAtt instanceof File
            ) {
                if (contentForPostForAtt == "image") {
                    handleChangeForFormData(
                        attData?.nameValue,
                        URL.createObjectURL(selectedImageFileForAtt),
                        attData?.section_id
                    );
                    setOpenFileUploadModelForAtt(false);
                } else {
                    handleChangeForFormData(
                        attData?.nameValue,
                        selectedFileForAtt,
                        attData?.section_id
                    );

                    setOpenFileUploadModelForAtt(false);

                }
            } else {
                console.error("Selected file is not a valid Blob or File object");
            }
        }

    };

    const handleImageFileValueCustAttForAtt = () => {
        console.log("line 1831")
        if (mediaDataForAtt === "image") {
            handleChangeForFormData(
                attData?.nameValue,
                selectedMediaFileForImageAtt,
                attData?.section_id,
                false,
                "",
                true,
                selectedMediaFileDetailsForImageAtt
            );
        } else if (mediaDataForAtt === "doc") {
            handleChangeForFormData(
                attData?.nameValue,
                selectedMediaFileForDocAtt,
                attData?.section_id,
                false,
                "",
                true,
                selectedMediaFileDetailsForDocAtt
            );
        } else {
            console.log("selectedMediaFileDetailsForDocAtt:::", selectedMediaFileDetailsForDocAtt)
            handleChangeForFormDataContent(
                attData?.nameValue,
                // selectedMediaFileDetailsForDocAtt || uploadedFile,
                selectedAnyMediaFileDetails || uploadedFile,
                attData?.section_id
            );
        }
    };

    const handleCloseSuccessMessage = () => {
        setIsSuccessMessage(false);
        setIsSuccessMessage("");
    };

    const handleOpenUploadMedia = (dataFor) => {
        let folderID;
        if (dataFor === "image") {
            folderID = selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage
        } else if (dataFor === "doc") {
            folderID = selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc
        }
        setMediaDataFor(dataFor, folderID)
        fetchMediaFolders(dataFor)
        const allUserOption = fetchAllUsers.find(
            (user) => user.value == "all_users"
        );
        setPermissionsForMedia([allUserOption])
    }

    const handleOpenUploadMediaForAtt = (dataFor, attdatas) => {
        if (dataFor === "image") {
            setSelectedMediaFileForImageAtt(attdatas?.value)
            setSelectedMediaFolderIDForImageAtt(attdatas?.media_folder_id === "" ? 0 : attdatas?.media_folder_id)
            setSelectedMediaTypeForImageAtt(attdatas?.is_system === 0 ? "my-media" : attdatas?.is_system === 1 ? "system" : "")
        } else if (dataFor === "doc") {
            setSelectedMediaFileForDocAtt(attdatas?.value)
            setSelectedMediaFolderIDForDocAtt(attdatas?.media_folder_id === "" ? 0 : attdatas?.media_folder_id)
            setSelectedMediaTypeForDocAtt(attdatas?.is_system === 0 ? "my-media" : attdatas?.is_system === 1 ? "system" : "")
        }
        setMediaDataForAtt(dataFor)
        console.log("dataFor 1893::", dataFor)
        fetchMediaFoldersForAtt(dataFor, attdatas?.is_system === 0 ? "my-media" : attdatas?.is_system === 1 ? "system" : "", attdatas?.media_folder_id === "" ? 0 : attdatas?.media_folder_id)
        setAttData(attdatas);
        const allUserOption = fetchAllUsers.find(
            (user) => user.value == "all_users"
        );
        setPermissionsForMedia([allUserOption])
    }

    const handleCloseForUploadMedia = () => {
        setOpenMediaUpload(false)
        setFolderList()
        setMyFolderList([])
        setSystemFolderList([])
        setMediaDataFor("")
    }

    const handleCloseForUploadMediaForAtt = () => {
        console.log("line 1913")
        setOpenMediaUploadForAtt(false)
        setFolderListForAtt()
        setMyFolderListForAtt([])
        setSystemFolderListForAtt([])
        setMediaDataForAtt("")

        setSelectedMediaFileForImageAtt("")
        setSelectedMediaFolderIDForImageAtt("")
        setSelectedMediaTypeForImageAtt("")
        setSelectedMediaTypeForDocAtt("")
        setSelectedMediaFolderIDForDocAtt("")
        setSelectedMediaTypeForImageAtt("")

        setSelectedMediaFileForImage("")
        setSelectedMediaFileForDoc("")
        setSelectedMediaFileForAtt("")

        setSelectedMediaFileDetailsForImage()
        setSelectedMediaFileDetailsForDoc()
        setSelectedMediaFileDetailsForAtt()
    }

    const handleCloseMediaModelAddMedia = () => {
        setOpenFileUploadModelMedia(true)
        let folder_id;
        if (mediaDataFor === "image") {
            folder_id = selectedMediaFolderIDForImage
            if (selectedMediaTypeForImage === "system") {
                setIsSystemTypeMedia(true)
                setIsParentFolderSystemType(true)
            } else {
                setIsSystemTypeMedia(false)
                setIsParentFolderSystemType(true)
            }

        }
        else if (mediaDataFor === "doc") {
            folder_id = selectedMediaFolderIDFordoc
            if (selectedMediaTypeForDoc === "system") {
                setIsSystemTypeMedia(true)
                setIsParentFolderSystemType(true)
            } else {
                setIsSystemTypeMedia(false)
                setIsParentFolderSystemType(true)
            }
        }
        setParentFolderId(folder_id)
    }

    const handleCloseMediaModelAddMediaAtt = () => {
        setOpenFileUploadModelMediaAtt(true)
        let folder_id;
        if (mediaDataForAtt === "image") {
            folder_id = selectedMediaFolderIDForImageAtt
            if (selectedMediaTypeForImageAtt === "system") {
                setIsSystemTypeMediaAtt(true)
                setIsParentFolderSystemTypeAtt(true)
            } else {
                setIsSystemTypeMediaAtt(false)
                setIsParentFolderSystemTypeAtt(true)
            }

        }
        else if (mediaDataForAtt === "doc") {
            folder_id = selectedMediaFolderIDFordocAtt
            if (selectedMediaTypeForDocAtt === "system") {
                setIsSystemTypeMediaAtt(true)
                setIsParentFolderSystemTypeAtt(true)
            } else {
                setIsSystemTypeMediaAtt(false)
                setIsParentFolderSystemTypeAtt(true)
            }
        }
        setParentFolderIdAtt(folder_id)
    }

    const handleCloseForMediaModel = () => {

        setOpenFileUploadModelMedia(false)
        setMediaTitle("")
        setMediaAltText("")
        setMediaDescription("")
        setMediaDirectUrl("")
        setIsSystemTypeMedia(false)
        setSelectedFileMedia(null)
        setMediaFile("")
        setValueForMediaTab(0)
        setEditMediType("")
        setSelectedMedia("")
        setResponseForMedia()
        setIsEditMedia(false)
        setIsRenameMedia(false)
        setOpenRenameModel(false)

    }

    const handleCloseForMediaModelAtt = () => {

        setOpenFileUploadModelMediaAtt(false)
        setMediaTitleAtt("")
        setMediaAltTextAtt("")
        setMediaDescriptionAtt("")
        setMediaDirectUrlAtt("")
        setIsSystemTypeMediaAtt(false)
        setSelectedFileMediaAtt(null)
        setMediaFileAtt("")
        setValueForMediaTab(0)
        setEditMediType("")
        setSelectedMediaAtt("")
        setResponseForMediaAtt()
        setIsEditMediaAtt(false)
        setIsRenameMediaAtt(false)
        setOpenRenameModelAtt(false)
        setOpenMediaModelFor("")
    }

    const handleCloseForAddAttributeModel = () => {
        setOpenAddAttribute(false)
        setValue(0)
    }

    const handleChangeForMedia = (event, newValue) => {
        setValueForMediaTab(newValue);
    };

    const handleCloseDeleteAlertModel = () => {
        setOpenDeleteAlert(false);
    };

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles && acceptedFiles?.length > 0) {
            const file = acceptedFiles[0];
            const fileUrl = URL?.createObjectURL(file); // Create a URL for the file

            // Set the selected file with both file object and its URL
            if (openMediaModelFor === "normal") {
                setSelectedFileMedia({
                    file,
                    url: fileUrl,
                    type: file?.type, // Get the MIME type from the dropped file
                    name: file?.name // Use the file's name for display
                });
            } else {
                setSelectedFileMediaAtt({
                    file,
                    url: fileUrl,
                    type: file?.type, // Get the MIME type from the dropped file
                    name: file?.name // Use the file's name for display
                });
            }

        }
    }, [openMediaModelFor]);

    const onDropAtt = useCallback((acceptedFiles) => {
        if (acceptedFiles && acceptedFiles?.length > 0) {
            const file = acceptedFiles[0];
            const fileUrl = URL?.createObjectURL(file); // Create a URL for the file

            // Set the selected file with both file object and its URL
            setSelectedFileMediaAtt({
                file,
                url: fileUrl,
                type: file?.type, // Get the MIME type from the dropped file
                name: file?.name // Use the file's name for display
            });
        }
    }, []);

    const acceptedFilesForUpload = () => {
        if (mediaDataFor === "image") {
            return {
                "image/*": [], // Accept all image types
            }
        } else if (mediaDataFor === "doc") {
            return {
                "application/pdf": [], // Accept PDFs
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], // DOCX
                "application/vnd.openxmlformats-officedocument.presentationml.presentation": [], // PPTX
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], // XLSX
            }
        }
    }

    const acceptedFilesForUploadAtt = () => {
        if (mediaDataForAtt === "image") {
            return {
                "image/*": [], // Accept all image types
            }
        } else if (mediaDataForAtt === "doc") {
            return {
                "application/pdf": [], // Accept PDFs
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], // DOCX
                "application/vnd.openxmlformats-officedocument.presentationml.presentation": [], // PPTX
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], // XLSX
            }
        }
    }

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: openMediaModelFor === "normal" ? acceptedFilesForUpload : acceptedFilesForUploadAtt,
        multiple: false,
    });

    const renderPreview = () => {
        if (!selectedFileMedia) return null;

        // Handle image files
        if (selectedFileMedia?.type?.startsWith('image/')) {
            return (
                <div className="image-preview">
                    <img
                        src={selectedFileMedia?.url}
                        alt="Uploaded"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover", // Maintain aspect ratio
                        }}
                    />
                </div>
            );
        }

        // Handle PDF files
        if (selectedFileMedia?.type === 'application/pdf') {
            // return <p>PDF file selected: {selectedFile.name}</p>;
            return (
                <Card
                    key={"pdf"}
                    variant="outlined"
                    sx={{
                        p: 2,
                        mb: 2,
                        cursor: "pointer",
                    }}
                    className="pdf-doc"
                >
                    <Box
                        height={150}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <FontAwesomeIcon
                            size="3x"
                            icon={
                                faFilePdf
                            }
                            color="#660C60"
                        />
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            paddingLeft: 1,
                        }}
                    >
                        {selectedFileMedia?.name}
                    </Box>
                </Card>
            );
        }

        // Handle DOCX files
        if (selectedFileMedia?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            // return <p>DOCX file selected: {selectedFile.name}</p>;
            return (
                <Card
                    key={"pdf"}
                    variant="outlined"
                    sx={{
                        p: 2,
                        mb: 2,
                        cursor: "pointer",
                    }}
                    className="pdf-doc"
                >
                    <Box
                        height={150}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <FontAwesomeIcon
                            size="3x"
                            icon={
                                faFile
                            }
                            color="#660C60"
                        />
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            paddingLeft: 1,
                        }}
                    >
                        {selectedFileMedia?.name}
                    </Box>
                </Card>
            );
        }

        // Handle PPTX files
        if (selectedFileMedia?.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
            return (
                <Card
                    key={"pdf"}
                    variant="outlined"
                    sx={{
                        p: 2,
                        mb: 2,
                        cursor: "pointer",
                    }}
                    className="pdf-doc"
                >
                    <Box
                        height={150}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <FontAwesomeIcon
                            size="3x"
                            icon={
                                faFile
                            }
                            color="#660C60"
                        />
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            paddingLeft: 1,
                        }}
                    >
                        {selectedFileMedia?.name}
                    </Box>
                </Card>
            );
        }

        if (selectedFileMedia?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return (
                <Card
                    key={"pdf"}
                    variant="outlined"
                    sx={{
                        p: 2,
                        mb: 2,
                        cursor: "pointer",
                    }}
                    className="pdf-doc"
                >
                    <Box
                        height={150}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <FontAwesomeIcon
                            size="3x"
                            icon={
                                faFileExcel
                            }
                            color="#660C60"
                        />
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            paddingLeft: 1,
                        }}
                    >
                        {selectedFileMedia?.name}
                    </Box>
                </Card>
            );
        }

        // Handle MP4 files
        if (selectedFileMedia?.type === 'video/mp4') {
            return (
                <Box className="w-100 h-100">
                    <Typography>{selectedFileMedia?.name}</Typography>
                    <ReactPlayer
                        url={selectedFileMedia?.url}
                        className="react-player"
                        playing={true}
                        width="100%"
                        height="100%"
                        controls={true}
                    />
                </Box>
            );
        }

        // Handle MP3 files
        if (selectedFileMedia?.type === 'audio/mpeg') {
            return (
                <Box className="w-100 h-100 audio-file">
                    <Typography>{selectedFileMedia?.name}</Typography>
                    <ReactAudioPlayer
                        src={selectedFileMedia?.url}
                        controls
                    />
                </Box>
            );
        }

        return (
            <Card
                key={"pdf"}
                variant="outlined"
                sx={{
                    p: 2,
                    mb: 2,
                    cursor: "pointer",
                }}
                className="pdf-doc"
            >
                <Box
                    height={150}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <FontAwesomeIcon
                        size="3x"
                        icon={
                            faFilePdf
                        }
                        color="#660C60"
                    />
                </Box>
                <Divider />
                <Box
                    sx={{
                        paddingLeft: 1,
                    }}
                >
                    {selectedFileMedia?.name}
                </Box>
            </Card>
        );
    };

    const renderPreviewAtt = () => {
        if (!selectedFileMediaAtt) return null;

        // Handle image files
        if (selectedFileMediaAtt?.type?.startsWith('image/')) {
            return (
                <div className="image-preview">
                    <img
                        src={selectedFileMediaAtt?.url}
                        alt="Uploaded"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover", // Maintain aspect ratio
                        }}
                    />
                </div>
            );
        }

        // Handle PDF files
        if (selectedFileMediaAtt?.type === 'application/pdf') {
            // return <p>PDF file selected: {selectedFile.name}</p>;
            return (
                <Card
                    key={"pdf"}
                    variant="outlined"
                    sx={{
                        p: 2,
                        mb: 2,
                        cursor: "pointer",
                    }}
                    className="pdf-doc"
                >
                    <Box
                        height={150}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <FontAwesomeIcon
                            size="3x"
                            icon={
                                faFilePdf
                            }
                            color="#660C60"
                        />
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            paddingLeft: 1,
                        }}
                    >
                        {selectedFileMediaAtt?.name}
                    </Box>
                </Card>
            );
        }

        // Handle DOCX files
        if (selectedFileMediaAtt?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            // return <p>DOCX file selected: {selectedFile.name}</p>;
            return (
                <Card
                    key={"pdf"}
                    variant="outlined"
                    sx={{
                        p: 2,
                        mb: 2,
                        cursor: "pointer",
                    }}
                    className="pdf-doc"
                >
                    <Box
                        height={150}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <FontAwesomeIcon
                            size="3x"
                            icon={
                                faFile
                            }
                            color="#660C60"
                        />
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            paddingLeft: 1,
                        }}
                    >
                        {selectedFileMediaAtt?.name}
                    </Box>
                </Card>
            );
        }

        // Handle PPTX files
        if (selectedFileMediaAtt?.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
            return (
                <Card
                    key={"pdf"}
                    variant="outlined"
                    sx={{
                        p: 2,
                        mb: 2,
                        cursor: "pointer",
                    }}
                    className="pdf-doc"
                >
                    <Box
                        height={150}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <FontAwesomeIcon
                            size="3x"
                            icon={
                                faFile
                            }
                            color="#660C60"
                        />
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            paddingLeft: 1,
                        }}
                    >
                        {selectedFileMediaAtt?.name}
                    </Box>
                </Card>
            );
        }

        if (selectedFileMediaAtt?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return (
                <Card
                    key={"pdf"}
                    variant="outlined"
                    sx={{
                        p: 2,
                        mb: 2,
                        cursor: "pointer",
                    }}
                    className="pdf-doc"
                >
                    <Box
                        height={150}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <FontAwesomeIcon
                            size="3x"
                            icon={
                                faFileExcel
                            }
                            color="#660C60"
                        />
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            paddingLeft: 1,
                        }}
                    >
                        {selectedFileMediaAtt?.name}
                    </Box>
                </Card>
            );
        }

        // Handle MP4 files
        if (selectedFileMediaAtt?.type === 'video/mp4') {
            return (
                <Box className="w-100 h-100">
                    <Typography>{selectedFileMediaAtt?.name}</Typography>
                    <ReactPlayer
                        url={selectedFileMediaAtt?.url}
                        className="react-player"
                        playing={true}
                        width="100%"
                        height="100%"
                        controls={true}
                    />
                </Box>
            );
        }

        // Handle MP3 files
        if (selectedFileMediaAtt?.type === 'audio/mpeg') {
            return (
                <Box className="w-100 h-100 audio-file">
                    <Typography>{selectedFileMediaAtt?.name}</Typography>
                    <ReactAudioPlayer
                        src={selectedFileMediaAtt?.url}
                        controls
                    />
                </Box>
            );
        }

        return (
            <Card
                key={"pdf"}
                variant="outlined"
                sx={{
                    p: 2,
                    mb: 2,
                    cursor: "pointer",
                }}
                className="pdf-doc"
            >
                <Box
                    height={150}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <FontAwesomeIcon
                        size="3x"
                        icon={
                            faFilePdf
                        }
                        color="#660C60"
                    />
                </Box>
                <Divider />
                <Box
                    sx={{
                        paddingLeft: 1,
                    }}
                >
                    {selectedFileMediaAtt?.name}
                </Box>
            </Card>
        );
    };

    const validateMedia = () => {
        let isValid = true;

        // if (mediaTitle === "") {
        //     Toast.error("Please enter a title for media");
        //     isValid = false;
        // }
        // if (mediaAltText === "") {
        //     Toast.error("Please enter a alt text for media");
        //     isValid = false;
        // }
        // if (mediaDescription === "") {
        //     Toast.error("Please enter a description for media");
        //     isValid = false;
        // }
        // if (permissionsForMedia?.length < 1) {
        //   Toast.error("Please select at least one user/group for access permission");
        //   isValid = false;
        // }
        if (selectedFileMedia === null) {
            Toast.error("Please upload a media file");
            isValid = false;
        }
        return isValid;

    }

    const validateMediaAtt = () => {
        let isValid = true;

        if (mediaTitleAtt === "") {
            Toast.error("Please enter a title for media");
            isValid = false;
        }
        if (mediaAltTextAtt === "") {
            Toast.error("Please enter a alt text for media");
            isValid = false;
        }
        if (mediaDescriptionAtt === "") {
            Toast.error("Please enter a description for media");
            isValid = false;
        }
        // if (permissionsForMedia?.length < 1) {
        //   Toast.error("Please select at least one user/group for access permission");
        //   isValid = false;
        // }
        if (selectedFileMediaAtt === null) {
            Toast.error("Please upload a media file");
            isValid = false;
        }
        return isValid;

    }

    const fetchMediaDetails = async () => {
        try {
            setLoading(true);
            const response = await api.get(
                `user/media/file/edit/${selectedMedia}`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success === true) {
                const responseData = response?.response
                setLoading(false);
                setMediaTitle(responseData?.title)
                setMediaAltText(responseData?.alt_text)
                setMediaDescription(responseData?.description)
                const permissions = responseData?.permission;
                setIsSystemTypeMedia(responseData?.is_system === 0 ? false : true)
                setResponseForMedia(responseData)
                const finalPermissions = permissions?.map((data) => {
                    return {
                        label: data?.label,
                        value: data?.user_group_id,
                        view: data?.can_view === 0 ? false : true,
                        deny: data?.can_deny === 0 ? false : true,
                        edit: data?.can_edit === 0 ? false : true,
                        delete: data?.can_delete === 0 ? false : true,
                        redacted: data?.can_resource === 0 ? false : true,
                        type: data?.type,
                    };
                });
                const fileExtension = responseData?.file_path?.split('.').pop().toLowerCase();
                const mimeType = getMimeType(fileExtension);  // You can create a helper function to get mime type from file extension
                setMediaDirectUrl(responseData?.file_path)
                setPermissionsForMedia(finalPermissions)
                setIsAdvancePermissionForMedia(
                    responseData?.advance_permission === 0 ? false : true
                );
                setSelectedFileMedia({
                    url: responseData?.file_path,
                    type: mimeType,
                    name: responseData?.title || "Uploaded File"
                });
                setOpenFileUploadModelMedia(true)
                Toast.success(response?.message);

            } else {
                setLoading(false);
                Toast.error(response?.message);
            }
        } catch (err) {
            console.log("error in ctreate news api::", err);
            setLoading(false);

            if (err?.response?.status === 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status === 422) {
                setLoading(false);
                const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
        }
    }

    const fetchMediaDetailsAtt = async () => {
        try {
            setLoading(true);
            const response = await api.get(
                `user/media/file/edit/${selectedMediaAtt}`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success === true) {
                const responseData = response?.response
                setLoading(false);
                setMediaTitleAtt(responseData?.title)
                setMediaAltTextAtt(responseData?.alt_text)
                setMediaDescriptionAtt(responseData?.description)
                const permissions = responseData?.permission;
                setIsSystemTypeMediaAtt(responseData?.is_system === 0 ? false : true)
                setResponseForMediaAtt(responseData)
                const finalPermissions = permissions?.map((data) => {
                    return {
                        label: data?.label,
                        value: data?.user_group_id,
                        view: data?.can_view === 0 ? false : true,
                        deny: data?.can_deny === 0 ? false : true,
                        edit: data?.can_edit === 0 ? false : true,
                        delete: data?.can_delete === 0 ? false : true,
                        redacted: data?.can_resource === 0 ? false : true,
                        type: data?.type,
                    };
                });
                const fileExtension = responseData?.file_path?.split('.').pop().toLowerCase();
                const mimeType = getMimeType(fileExtension);  // You can create a helper function to get mime type from file extension
                setMediaDirectUrlAtt(responseData?.file_path)
                setPermissionsForMedia(finalPermissions)
                setIsAdvancePermissionForMedia(
                    responseData?.advance_permission === 0 ? false : true
                );
                setSelectedFileMediaAtt({
                    url: responseData?.file_path,
                    type: mimeType,
                    name: responseData?.title || "Uploaded File"
                });
                setOpenFileUploadModelMediaAtt(true)
                Toast.success(response?.message);

            } else {
                setLoading(false);
                Toast.error(response?.message);
            }
        } catch (err) {
            console.log("error in ctreate news api::", err);
            setLoading(false);

            if (err?.response?.status === 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status === 422) {
                setLoading(false);
                const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
        }
    }

    const fetchMediaDetailsForRename = async () => {
        try {
            setLoading(true);
            const response = await api.get(
                `user/media/file/edit/${selectedMedia}`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success === true) {
                const responseData = response?.response
                setLoading(false);
                setMediaTitle(responseData?.title)
                setMediaAltText(responseData?.alt_text)
                setMediaDescription(responseData?.description)
                const permissions = responseData?.permission;
                setIsSystemTypeMedia(responseData?.is_system === 0 ? false : true)
                setResponseForMedia(responseData)
                const finalPermissions = permissions?.map((data) => {
                    return {
                        label: data?.label,
                        value: data?.user_group_id,
                        view: data?.can_view === 0 ? false : true,
                        deny: data?.can_deny === 0 ? false : true,
                        edit: data?.can_edit === 0 ? false : true,
                        delete: data?.can_delete === 0 ? false : true,
                        redacted: data?.can_resource === 0 ? false : true,
                        type: data?.type,
                    };
                });
                const fileExtension = responseData?.file_path?.split('.').pop().toLowerCase();
                const mimeType = getMimeType(fileExtension);  // You can create a helper function to get mime type from file extension
                setMediaDirectUrl(responseData?.file_path)
                setPermissionsForMedia(finalPermissions)
                setIsAdvancePermissionForMedia(
                    responseData?.advance_permission === 0 ? false : true
                );
                setSelectedFileMedia({
                    url: responseData?.file_path,
                    type: mimeType,
                    name: responseData?.title || "Uploaded File"
                });
                setOpenRenameModel(true)
                Toast.success(response?.message);

            } else {
                setLoading(false);
                Toast.error(response?.message);
            }
        } catch (err) {
            console.log("error in ctreate news api::", err);
            setLoading(false);

            if (err?.response?.status === 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status === 422) {
                setLoading(false);
                const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
        }
    }

    const fetchMediaDetailsForRenameAtt = async () => {
        try {
            setLoading(true);
            const response = await api.get(
                `user/media/file/edit/${selectedMediaAtt}`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success === true) {
                const responseData = response?.response
                setLoading(false);
                setMediaTitleAtt(responseData?.title)
                setMediaAltTextAtt(responseData?.alt_text)
                setMediaDescriptionAtt(responseData?.description)
                const permissions = responseData?.permission;
                setIsSystemTypeMediaAtt(responseData?.is_system === 0 ? false : true)
                setResponseForMediaAtt(responseData)
                const finalPermissions = permissions?.map((data) => {
                    return {
                        label: data?.label,
                        value: data?.user_group_id,
                        view: data?.can_view === 0 ? false : true,
                        deny: data?.can_deny === 0 ? false : true,
                        edit: data?.can_edit === 0 ? false : true,
                        delete: data?.can_delete === 0 ? false : true,
                        redacted: data?.can_resource === 0 ? false : true,
                        type: data?.type,
                    };
                });
                const fileExtension = responseData?.file_path?.split('.').pop().toLowerCase();
                const mimeType = getMimeType(fileExtension);  // You can create a helper function to get mime type from file extension
                setMediaDirectUrlAtt(responseData?.file_path)
                setPermissionsForMedia(finalPermissions)
                setIsAdvancePermissionForMedia(
                    responseData?.advance_permission === 0 ? false : true
                );
                setSelectedFileMediaAtt({
                    url: responseData?.file_path,
                    type: mimeType,
                    name: responseData?.title || "Uploaded File"
                });
                setOpenRenameModelAtt(true)
                Toast.success(response?.message);

            } else {
                setLoading(false);
                Toast.error(response?.message);
            }
        } catch (err) {
            console.log("error in ctreate news api::", err);
            setLoading(false);

            if (err?.response?.status === 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status === 422) {
                setLoading(false);
                const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
        }
    }

    const storeMedia = async () => {
        const validate = await validateMedia()

        if (validate) {
            const formData = new FormData();
            let permissionData;
            formData.append("is_system", isSystemTypeMedia === true ? 1 : 0);
            formData.append("title", mediaTitle);
            formData.append("alt_text", mediaAltText);
            formData.append("description", mediaDescription);
            formData.append("advance_permission", 0);

            if (isAdvancePermissionForMedia === true) {
                permissionData = permissionsForMedia?.map((data) => {
                    return {
                        user_group_id: data?.value,
                        type: data?.type,
                        can_view: 0,
                        can_deny: 0,
                        can_edit: 0,
                        can_delete: 0,
                        can_resource: 0,
                        label: data?.label,
                    };
                });
            } else {
                permissionData = permissionsForMedia?.map((data) => {
                    return {
                        user_group_id: data?.value,
                        type: data?.type,
                        can_view: 0,
                        can_deny: 0,
                        can_edit: 0,
                        can_delete: 0,
                        can_resource: 0,
                        label: data?.label,
                    };
                });
            }
            if (parentFolderId) {
                formData.append("folder_id", parentFolderId)
            }

            if (isEditMedia === true) {
                formData.append("direct_url", responseForMedia?.file_path)
            }

            formData.append("permission", JSON?.stringify(permissionData));

            if (
                selectedFileMedia?.file instanceof Blob ||
                selectedFileMedia?.file instanceof File
            ) {
                formData.append("file", selectedFileMedia?.file);
            }

            if (isEditMedia === true) {
                try {
                    setLoading(true);
                    const response = await api.post(
                        `user/media/file/update/${selectedMedia}`,
                        formData,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: `Bearer ${userToken}`,
                            },
                        }
                    );
                    if (response?.success === true) {
                        setLoading(false);
                        let media_id;
                        let folderID;
                        if (mediaDataFor === "image") {
                            setSelectedMediaFileForImage(response?.response?.id)
                            media_id = response?.response?.id;
                            folderID = selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage
                            setSelectedMediaFileDetailsForImage(response?.response)
                        } else if (mediaDataFor === "doc") {
                            setSelectedMediaFileForDoc(response?.response?.id)
                            media_id = response?.response?.id;
                            folderID = selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc
                            setSelectedMediaFileDetailsForDoc(response?.response)

                        }

                        await fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, media_id, folderID)
                        Toast.success(response?.message);
                        handleCloseForMediaModel()

                    } else {
                        setLoading(false);
                        Toast.error(response?.message);
                    }
                } catch (err) {
                    console.log("error in ctreate news api::", err);
                    setLoading(false);

                    if (err?.response?.status === 401) {
                        setLoading(false);
                        logoutHelper(dispatch, navigate, userToken);
                    }
                    if (err?.response?.status === 422) {
                        setLoading(false);
                        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
                    }
                    if (err?.response?.status === 429) {
                        Toast.error("Too many attempts wait for 2 min.")
                    }
                }
            } else {
                try {
                    setLoading(true);
                    const response = await api.post(
                        `user/media/file/store`,
                        formData,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: `Bearer ${userToken}`,
                            },
                        }
                    );
                    if (response?.success === true) {
                        setLoading(false);

                        Toast.success(response?.message);
                        let media_id;
                        let folderID;
                        if (mediaDataFor === "image") {
                            console.log("2282", response?.response?.id)
                            setSelectedMediaFileForImage(response?.response?.id)
                            media_id = response?.response?.id
                            folderID = selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage
                            setSelectedMediaFileDetailsForImage(response?.response)
                        } else if (mediaDataFor === "doc") {
                            setSelectedMediaFileForDoc(response?.response?.id)
                            media_id = response?.response?.id
                            folderID = selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc
                            setSelectedMediaFileDetailsForDoc(response?.response)
                        }

                        fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, media_id, folderID)
                        handleCloseForMediaModel()

                    } else {
                        setLoading(false);
                        Toast.error(response?.message);
                    }
                } catch (err) {
                    console.log("error in ctreate news api::", err);
                    setLoading(false);

                    if (err?.response?.status === 401) {
                        setLoading(false);
                        logoutHelper(dispatch, navigate, userToken);
                    }
                    if (err?.response?.status === 422) {
                        setLoading(false);
                        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
                    }
                    if (err?.response?.status === 429) {
                        Toast.error("Too many attempts wait for 2 min.")
                    }
                }
            }

        }
    }

    const storeMediaAtt = async () => {
        const validate = await validateMediaAtt()

        if (validate) {
            const formData = new FormData();
            let permissionData;
            formData.append("is_system", isSystemTypeMediaAtt === true ? 1 : 0);
            formData.append("title", mediaTitleAtt);
            formData.append("alt_text", mediaAltTextAtt);
            formData.append("description", mediaDescriptionAtt);
            formData.append("advance_permission", 0);

            if (isAdvancePermissionForMedia === true) {
                permissionData = permissionsForMedia?.map((data) => {
                    return {
                        user_group_id: data?.value,
                        type: data?.type,
                        can_view: 0,
                        can_deny: 0,
                        can_edit: 0,
                        can_delete: 0,
                        can_resource: 0,
                        label: data?.label,
                    };
                });
            } else {
                permissionData = permissionsForMedia?.map((data) => {
                    return {
                        user_group_id: data?.value,
                        type: data?.type,
                        can_view: 0,
                        can_deny: 0,
                        can_edit: 0,
                        can_delete: 0,
                        can_resource: 0,
                        label: data?.label,
                    };
                });
            }

            formData.append("folder_id", parentFolderIdAtt)

            if (isEditMediaAtt === true) {
                formData.append("direct_url", responseForMediaAtt?.file_path)
            }

            formData.append("permission", JSON?.stringify(permissionData));

            if (
                selectedFileMediaAtt?.file instanceof Blob ||
                selectedFileMediaAtt?.file instanceof File
            ) {
                formData.append("file", selectedFileMediaAtt?.file);
            }

            if (isEditMediaAtt === true) {
                try {
                    setLoading(true);
                    const response = await api.post(
                        `user/media/file/update/${selectedMediaAtt}`,
                        formData,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: `Bearer ${userToken}`,
                            },
                        }
                    );
                    if (response?.success === true) {
                        setLoading(false);
                        let media_id;
                        let folder_id;
                        let type;
                        if (mediaDataForAtt === "image") {
                            folder_id = selectedMediaFolderIDForImageAtt
                            setSelectedMediaFileForImageAtt(response?.response?.id)
                            media_id = response?.response?.id;
                            setSelectedMediaFileDetailsForImageAtt(response?.response)
                            if (selectedMediaTypeForImageAtt === "system") {
                                type = "system"
                            } else {
                                type = "user"
                            }
                            // setSelectedFileMedia(response?.response?.id)
                        } else if (mediaDataForAtt === "doc") {
                            folder_id = selectedMediaFolderIDFordocAtt
                            setSelectedMediaFileForDocAtt(response?.response?.id)
                            media_id = response?.response?.id;
                            setSelectedMediaFileDetailsForDocAtt(response?.response)
                            if (selectedMediaTypeForDoc === "system") {
                                type = "system"
                            } else {
                                type = "user"
                            }
                            // setSelectedFileMedia(response?.response?.id)

                        }

                        await fetchMediaFilesForAtt(pageForMedia, rowsPerPageForMedia, mediaDataForAtt, folder_id, type)
                        Toast.success(response?.message);
                        handleCloseForMediaModelAtt()

                    } else {
                        setLoading(false);
                        Toast.error(response?.message);
                    }
                } catch (err) {
                    console.log("error in ctreate news api::", err);
                    setLoading(false);

                    if (err?.response?.status === 401) {
                        setLoading(false);
                        logoutHelper(dispatch, navigate, userToken);
                    }
                    if (err?.response?.status === 422) {
                        setLoading(false);
                        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
                    }
                    if (err?.response?.status === 429) {
                        Toast.error("Too many attempts wait for 2 min.")
                    }
                }
            } else {
                try {
                    setLoading(true);
                    const response = await api.post(
                        `user/media/file/store`,
                        formData,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: `Bearer ${userToken}`,
                            },
                        }
                    );
                    if (response?.success === true) {
                        setLoading(false);

                        Toast.success(response?.message);
                        let media_id;
                        let folder_id;
                        let type;
                        if (mediaDataForAtt === "image") {
                            folder_id = selectedMediaFolderIDForImageAtt
                            console.log("2282", response?.response?.id)
                            setSelectedMediaFileForImageAtt(response?.response?.id)
                            media_id = response?.response?.id
                            setSelectedMediaFileDetailsForImageAtt(response?.response)
                            if (selectedMediaTypeForImageAtt === "system") {
                                type = "system"
                            } else {
                                type = "user"
                            }
                        } else if (mediaDataForAtt === "doc") {
                            folder_id = selectedMediaFolderIDFordocAtt
                            setSelectedMediaFileForDocAtt(response?.response?.id)
                            media_id = response?.response?.id
                            setSelectedMediaFileDetailsForDocAtt(response?.response)
                            if (selectedMediaTypeForDocAtt === "system") {
                                type = "system"
                            } else {
                                type = "user"
                            }
                        }

                        fetchMediaFilesForAtt(pageForMedia, rowsPerPageForMedia, mediaDataForAtt, folder_id, type)
                        handleCloseForMediaModelAtt()

                    } else {
                        setLoading(false);
                        Toast.error(response?.message);
                    }
                } catch (err) {
                    console.log("error in ctreate news api::", err);
                    setLoading(false);

                    if (err?.response?.status === 401) {
                        setLoading(false);
                        logoutHelper(dispatch, navigate, userToken);
                    }
                    if (err?.response?.status === 422) {
                        setLoading(false);
                        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
                    }
                    if (err?.response?.status === 429) {
                        Toast.error("Too many attempts wait for 2 min.")
                    }
                }
            }

        }
    }

    const renameMedia = async () => {
        const validate = await validateMedia()

        if (validate) {
            const formData = new FormData();
            let permissionData;
            formData.append("is_system", isSystemTypeMedia === true ? 1 : 0);
            formData.append("title", mediaTitle);
            formData.append("alt_text", mediaAltText);
            formData.append("description", mediaDescription);
            formData.append("advance_permission", 0);

            if (isAdvancePermissionForMedia === true) {
                permissionData = permissionsForMedia?.map((data) => {
                    return {
                        user_group_id: data?.value,
                        type: data?.type,
                        can_view: 0,
                        can_deny: 0,
                        can_edit: 0,
                        can_delete: 0,
                        can_resource: 0,
                        label: data?.label,
                    };
                });
            } else {
                permissionData = permissionsForMedia?.map((data) => {
                    return {
                        user_group_id: data?.value,
                        type: data?.type,
                        can_view: 0,
                        can_deny: 0,
                        can_edit: 0,
                        can_delete: 0,
                        can_resource: 0,
                        label: data?.label,
                    };
                });
            }

            if (parentFolderId) {
                formData.append("folder_id", parentFolderId)
            }

            formData.append("direct_url", responseForMedia?.file_path)

            formData.append("permission", JSON?.stringify(permissionData));

            if (
                selectedFileMedia?.file instanceof Blob ||
                selectedFileMedia?.file instanceof File
            ) {
                formData.append("file", selectedFileMedia?.file);
            }
            try {
                setLoading(true);
                const response = await api.post(
                    `user/media/file/update/${selectedMedia}`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Bearer ${userToken}`,
                        },
                    }
                );
                if (response?.success === true) {
                    setLoading(false);
                    let media_id;
                    let folderID;
                    if (mediaDataFor === "image") {
                        setSelectedMediaFileForImage(response?.response?.id)
                        media_id = response?.response?.id;
                        folderID = selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage
                        setSelectedMediaFileDetailsForImage(response?.response)
                    } else if (mediaDataFor === "doc") {
                        setSelectedMediaFileForDoc(response?.response?.id)
                        media_id = response?.response?.id;
                        folderID = selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc
                        setSelectedMediaFileDetailsForDoc(response?.response)

                    }

                    await fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, media_id, folderID)
                    Toast.success(response?.message);
                    handleCloseForMediaModel()

                } else {
                    setLoading(false);
                    Toast.error(response?.message);
                }
            } catch (err) {
                console.log("error in ctreate news api::", err);
                setLoading(false);

                if (err?.response?.status === 401) {
                    setLoading(false);
                    logoutHelper(dispatch, navigate, userToken);
                }
                if (err?.response?.status === 422) {
                    setLoading(false);
                    const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
                }
                if (err?.response?.status === 429) {
                    Toast.error("Too many attempts wait for 2 min.")
                }
            }
        }
    }

    const renameMediaAtt = async () => {
        const validate = await validateMediaAtt()

        if (validate) {
            const formData = new FormData();
            let permissionData;
            formData.append("is_system", isSystemTypeMediaAtt === true ? 1 : 0);
            formData.append("title", mediaTitleAtt);
            formData.append("alt_text", mediaAltTextAtt);
            formData.append("description", mediaDescriptionAtt);
            formData.append("advance_permission", 0);

            if (isAdvancePermissionForMedia === true) {
                permissionData = permissionsForMedia?.map((data) => {
                    return {
                        user_group_id: data?.value,
                        type: data?.type,
                        can_view: 0,
                        can_deny: 0,
                        can_edit: 0,
                        can_delete: 0,
                        can_resource: 0,
                        label: data?.label,
                    };
                });
            } else {
                permissionData = permissionsForMedia?.map((data) => {
                    return {
                        user_group_id: data?.value,
                        type: data?.type,
                        can_view: 0,
                        can_deny: 0,
                        can_edit: 0,
                        can_delete: 0,
                        can_resource: 0,
                        label: data?.label,
                    };
                });
            }

            formData.append("folder_id", parentFolderIdAtt)

            formData.append("direct_url", responseForMediaAtt?.file_path)

            formData.append("permission", JSON?.stringify(permissionData));

            if (
                selectedFileMediaAtt?.file instanceof Blob ||
                selectedFileMediaAtt?.file instanceof File
            ) {
                formData.append("file", selectedFileMediaAtt?.file);
            }
            try {
                setLoading(true);
                const response = await api.post(
                    `user/media/file/update/${selectedMediaAtt}`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Bearer ${userToken}`,
                        },
                    }
                );
                if (response?.success === true) {
                    setLoading(false);
                    let media_id;
                    let folder_id;
                    let type;
                    if (mediaDataForAtt === "image") {
                        folder_id = selectedMediaFolderIDForImageAtt
                        setSelectedMediaFileForImageAtt(response?.response?.id)
                        media_id = response?.response?.id;
                        setSelectedMediaFileDetailsForImageAtt(response?.response)
                        if (selectedMediaTypeForImageAtt === "system") {
                            type = "system"
                        } else {
                            type = "user"
                        }
                        // setSelectedFileMedia(response?.response?.id)
                    } else if (mediaDataForAtt === "doc") {
                        folder_id = selectedMediaFolderIDFordocAtt
                        setSelectedMediaFileForDocAtt(response?.response?.id)
                        media_id = response?.response?.id;
                        setSelectedMediaFileDetailsForDocAtt(response?.response)
                        if (selectedMediaTypeForDoc === "system") {
                            type = "system"
                        } else {
                            type = "user"
                        }
                        // setSelectedFileMedia(response?.response?.id)

                    }

                    await fetchMediaFilesForAtt(pageForMedia, rowsPerPageForMedia, mediaDataForAtt, folder_id, type)
                    Toast.success(response?.message);
                    handleCloseForMediaModelAtt()

                } else {
                    setLoading(false);
                    Toast.error(response?.message);
                }
            } catch (err) {
                console.log("error in ctreate news api::", err);
                setLoading(false);

                if (err?.response?.status === 401) {
                    setLoading(false);
                    logoutHelper(dispatch, navigate, userToken);
                }
                if (err?.response?.status === 422) {
                    setLoading(false);
                    const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
                }
                if (err?.response?.status === 429) {
                    Toast.error("Too many attempts wait for 2 min.")
                }
            }
        }
    }

    const validateAttributes = () => {
        let isValid = true;
        let errorMessages = [];

        const validateField = (attribute, labelPrefix = "") => {
            if (attribute?.is_mandatory === true) {
                if (!attribute?.is_repeatable) {
                    if (
                        attribute?.value === null ||
                        attribute?.value === "" ||
                        attribute?.value == NaN && attribute?.fieldtype !== "link_object"
                    ) {
                        isValid = false;
                        // isValid = true;

                        Toast.error(
                            `The field "${labelPrefix}${attribute.label}" is required.`
                        );

                        errorMessages.push(
                            `The field1 "${labelPrefix}${attribute.label}" is required.`
                        );
                    }
                    // Email validation for non-repeatable fields
                    if (attribute?.fieldtype === "email" && attribute?.value) {
                        if (!isValidEmail(attribute.value)) {
                            isValid = false;
                            Toast.error(
                                `The field "${labelPrefix}${attribute.label}" must be a valid email.`
                            );
                            errorMessages.push(
                                `The field "${labelPrefix}${attribute.label}" must be a valid email.`
                            );
                        }
                    }
                    if (attribute?.fieldtype === "url" && attribute?.value) {
                        if (!isValidURL(attribute.value)) {
                            isValid = false;
                            Toast.error(
                                `The field "${labelPrefix}${attribute.label}" must be a valid url.`
                            );
                            errorMessages.push(
                                `The field "${labelPrefix}${attribute.label}" must be a valid url.`
                            );
                        }
                    }
                    if (
                        attribute?.fieldtype === "checkbox_multiple_choice" &&
                        attribute?.value
                    ) {
                        if (attribute?.value?.length < 1) {
                            isValid = false;
                            Toast.error(
                                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
                            );
                            errorMessages.push(
                                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
                            );
                        }
                    }
                    if (
                        attribute?.fieldtype === "dropdodown_multiple_choice" &&
                        attribute?.value
                    ) {
                        if (attribute?.value?.length < 1) {
                            isValid = false;
                            Toast.error(
                                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
                            );
                            errorMessages.push(
                                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
                            );
                        }
                    }
                    if (attribute?.fieldtype === "tags" && attribute?.value) {
                        if (attribute?.value?.length < 1) {
                            isValid = false;
                            Toast.error(
                                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
                            );
                            errorMessages.push(
                                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
                            );
                        }
                    }
                    if (attribute.fieldtype === "link_object" && attribute?.value) {
                        if (!attribute.value?.title || attribute.value.title === "") {
                            isValid = false;
                            Toast.error(`The field "${labelPrefix}${attribute.label}  Title" is required.`);
                            errorMessages.push(`The field "${labelPrefix}${attribute.label}  Title" is required.`);
                        }
                        if (attribute.value?.url && !isValidURL(attribute.value.url)) {
                            isValid = false;
                            Toast.error(`The field "${labelPrefix}${attribute.label}  URL" must be a valid URL.`);
                            errorMessages.push(`The field "${labelPrefix}${attribute.label} URL" must be a valid URL.`);
                        }
                    }
                } else {
                    if (
                        !Array.isArray(attribute?.value) ||
                        attribute?.value.length === 0
                    ) {
                        isValid = false;
                        // isValid = true;

                        Toast.error(
                            `At least one "${labelPrefix}${attribute.label}" is required.`
                        );
                        errorMessages.push(
                            `At least one "${labelPrefix}${attribute.label}" is required.`
                        );
                    }

                    else {
                        attribute.value.forEach((item, index) => {
                            if (
                                item?.is_mandatory &&
                                (!item.value || item.value === "" || item.value == NaN)
                            ) {
                                isValid = false;
                                // isValid = true;

                                Toast.error(
                                    `The field "${labelPrefix}${attribute.label} #${index + 1
                                    }" is required.`
                                );
                                errorMessages.push(
                                    `The field2 "${labelPrefix}${attribute.label} #${index + 1
                                    }" is required.`
                                );
                            }

                            if (attribute.fieldtype === "link_object") {
                                if (!item.value?.title || item.value.title === "") {
                                    isValid = false;
                                    Toast.error(`The field "${labelPrefix}${attribute.label} #${index + 1} Title" is required.`);
                                    errorMessages.push(`The field "${labelPrefix}${attribute.label} #${index + 1} Title" is required.`);
                                }
                                if (item.value?.url && !isValidURL(item.value.url)) {
                                    isValid = false;
                                    Toast.error(`The field "${labelPrefix}${attribute.label} #${index + 1} URL" must be a valid URL.`);
                                    errorMessages.push(`The field "${labelPrefix}${attribute.label} #${index + 1} URL" must be a valid URL.`);
                                }
                            }
                        });
                    }
                }
            }

            if (attribute?.nameValue === "checkbox_single_choice_acknowledgement" && attribute?.value == true) {
                console.log("attribute::", attribute)
                if (attribute?.child_attribute[0]?.value?.length < 1) {
                    isValid = false;
                    Toast.error("Please add atleast one user for acknowledge")
                } if (attribute?.child_attribute[1]?.value == "") {
                    isValid = false;
                    Toast.error("Please select due by date for acknowledge")
                } if (attribute?.child_attribute[2]?.value == "") {
                    isValid = false;
                    Toast.error("Please select due by expiry action for acknowledge")
                } if (attribute?.child_attribute[3]?.value == "") {
                    isValid = false;
                    Toast.error("Please select reminder notification for acknowledge")
                } if (attribute?.child_attribute[4]?.value == 1) {
                    if (attribute?.child_attribute[4]?.child_attribute[0]?.value == "") {
                        isValid = false;
                        Toast.error("Please enter acknowledgement texts")
                    }
                }
                if (attribute?.child_attribute[4]?.value == 2) {
                    if (attribute?.child_attribute[4]?.child_attribute[1]?.value?.length < 1) {
                        isValid = false;
                        Toast.error("Please add atleast one acknowledgement question")
                    } else if (attribute?.child_attribute[4]?.child_attribute[1]?.value?.length > 0) {
                        attribute?.child_attribute[4]?.child_attribute[1]?.value?.forEach((childAtt, index) => {
                            if (childAtt?.value == "") {
                                isValid = false;
                                Toast.error(
                                    `Please add acknowledgement question for ${index + 1} is required.`
                                );
                            }
                        })
                    }
                }
            }
            // Check child attributes if they exist
            if (
                (attribute?.value === true ||
                    attribute?.value === 1 ||
                    attribute?.value == NaN) &&
                attribute?.child_attribute?.length > 0
            ) {
                attribute?.child_attribute?.forEach((childAtt) =>
                    validateField(childAtt, `${labelPrefix}${attribute?.label} - `)
                );
            }
        };

        attributesData?.forEach((attribute) => validateField(attribute));

        return { isValid, errorMessages };
    };

    const validateContentAttributes = () => {
        let isValid = true;
        let errorMessages = [];

        const validateField = (attribute, labelPrefix = "") => {
            if (attribute?.is_mandatory === true) {
                if (!attribute?.is_repeatable) {
                    if (
                        attribute?.value === null ||
                        attribute?.value === "" ||
                        attribute?.value == NaN && attribute?.fieldtype !== "link_object"
                    ) {
                        isValid = false;
                        // isValid = true;

                        Toast.error(
                            `The field "${labelPrefix}${attribute.label}" is required.`
                        );

                        errorMessages.push(
                            `The field1 "${labelPrefix}${attribute.label}" is required.`
                        );
                    }
                    // Email validation for non-repeatable fields
                    if (attribute?.fieldtype === "email" && attribute?.value) {
                        if (!isValidEmail(attribute.value)) {
                            isValid = false;
                            Toast.error(
                                `The field "${labelPrefix}${attribute.label}" must be a valid email.`
                            );
                            errorMessages.push(
                                `The field "${labelPrefix}${attribute.label}" must be a valid email.`
                            );
                        }
                    }
                    if (attribute?.fieldtype === "url" && attribute?.value) {
                        if (!isValidURL(attribute.value)) {
                            isValid = false;
                            Toast.error(
                                `The field "${labelPrefix}${attribute.label}" must be a valid url.`
                            );
                            errorMessages.push(
                                `The field "${labelPrefix}${attribute.label}" must be a valid url.`
                            );
                        }
                    }
                    if (
                        attribute?.fieldtype === "checkbox_multiple_choice" &&
                        attribute?.value
                    ) {
                        if (attribute?.value?.length < 1) {
                            isValid = false;
                            Toast.error(
                                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
                            );
                            errorMessages.push(
                                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
                            );
                        }
                    }
                    if (
                        attribute?.fieldtype === "dropdodown_multiple_choice" &&
                        attribute?.value
                    ) {
                        if (attribute?.value?.length < 1) {
                            isValid = false;
                            Toast.error(
                                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
                            );
                            errorMessages.push(
                                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
                            );
                        }
                    }
                    if (attribute?.fieldtype === "tags" && attribute?.value) {
                        if (attribute?.value?.length < 1) {
                            isValid = false;
                            Toast.error(
                                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
                            );
                            errorMessages.push(
                                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
                            );
                        }
                    }
                    if (attribute.fieldtype === "link_object" && attribute?.value) {
                        if (!attribute.value?.title || attribute.value.title === "") {
                            isValid = false;
                            Toast.error(`The field "${labelPrefix}${attribute.label}  Title" is required.`);
                            errorMessages.push(`The field "${labelPrefix}${attribute.label}  Title" is required.`);
                        }
                        if (attribute.value?.url && !isValidURL(attribute.value.url)) {
                            isValid = false;
                            Toast.error(`The field "${labelPrefix}${attribute.label}  URL" must be a valid URL.`);
                            errorMessages.push(`The field "${labelPrefix}${attribute.label} URL" must be a valid URL.`);
                        }
                    }
                } else {
                    if (
                        !Array.isArray(attribute?.value) ||
                        attribute?.value.length === 0
                    ) {
                        isValid = false;
                        // isValid = true;

                        Toast.error(
                            `At least one "${labelPrefix}${attribute.label}" is required.`
                        );
                        errorMessages.push(
                            `At least one "${labelPrefix}${attribute.label}" is required.`
                        );
                    }

                    else {
                        attribute.value.forEach((item, index) => {
                            if (
                                item?.is_mandatory &&
                                (!item.value || item.value === "" || item.value == NaN)
                            ) {
                                isValid = false;
                                // isValid = true;

                                Toast.error(
                                    `The field "${labelPrefix}${attribute.label} #${index + 1
                                    }" is required.`
                                );
                                errorMessages.push(
                                    `The field2 "${labelPrefix}${attribute.label} #${index + 1
                                    }" is required.`
                                );
                            }

                            if (attribute.fieldtype === "link_object") {
                                if (!item.value?.title || item.value.title === "") {
                                    isValid = false;
                                    Toast.error(`The field "${labelPrefix}${attribute.label} #${index + 1} Title" is required.`);
                                    errorMessages.push(`The field "${labelPrefix}${attribute.label} #${index + 1} Title" is required.`);
                                }
                                if (item.value?.url && !isValidURL(item.value.url)) {
                                    isValid = false;
                                    Toast.error(`The field "${labelPrefix}${attribute.label} #${index + 1} URL" must be a valid URL.`);
                                    errorMessages.push(`The field "${labelPrefix}${attribute.label} #${index + 1} URL" must be a valid URL.`);
                                }
                            }
                        });
                    }
                }
            }

            if (attribute?.nameValue === "checkbox_single_choice_acknowledgement" && attribute?.value == true) {
                console.log("attribute::", attribute)
                if (attribute?.child_attribute[0]?.value?.length < 1) {
                    isValid = false;
                    Toast.error("Please add atleast one user for acknowledge")
                } if (attribute?.child_attribute[1]?.value == "") {
                    isValid = false;
                    Toast.error("Please select due by date for acknowledge")
                } if (attribute?.child_attribute[2]?.value == "") {
                    isValid = false;
                    Toast.error("Please select due by expiry action for acknowledge")
                } if (attribute?.child_attribute[3]?.value == "") {
                    isValid = false;
                    Toast.error("Please select reminder notification for acknowledge")
                } if (attribute?.child_attribute[4]?.value == 1) {
                    if (attribute?.child_attribute[4]?.child_attribute[0]?.value == "") {
                        isValid = false;
                        Toast.error("Please enter acknowledgement texts")
                    }
                }
                if (attribute?.child_attribute[4]?.value == 2) {
                    if (attribute?.child_attribute[4]?.child_attribute[1]?.value?.length < 1) {
                        isValid = false;
                        Toast.error("Please add atleast one acknowledgement question")
                    } else if (attribute?.child_attribute[4]?.child_attribute[1]?.value?.length > 0) {
                        attribute?.child_attribute[4]?.child_attribute[1]?.value?.forEach((childAtt, index) => {
                            if (childAtt?.value == "") {
                                isValid = false;
                                Toast.error(
                                    `Please add acknowledgement question for ${index + 1} is required.`
                                );
                            }
                        })
                    }
                }
            }
            // Check child attributes if they exist
            if (
                (attribute?.value === true ||
                    attribute?.value === 1 ||
                    attribute?.value == NaN) &&
                attribute?.child_attribute?.length > 0
            ) {
                attribute?.child_attribute?.forEach((childAtt) =>
                    validateField(childAtt, `${labelPrefix}${attribute?.label} - `)
                );
            }
        };

        conterntAndAttributeData?.forEach((attribute) => validateField(attribute));

        return { isValid, errorMessages };
    };

    function extractFileName(url) {
        const baseUrl =
            "https://staging-intranet.s3.ap-southeast-2.amazonaws.com/images/";
        return url.replace(baseUrl, "");
    }

    function processAttributes(attributes) {
        const result = [];

        attributes.forEach(attribute => {
            const { nameValue, fieldtype, attribute_id, value, is_repeatable, id, child_attribute = [], ...rest } = attribute;
            let attributeValue = value;

            // Handle different field types
            if (
                fieldtype === "checkbox_multiple_choice" ||
                fieldtype === "dropdodown_multiple_choice" ||
                fieldtype === "users_list" ||
                fieldtype === "group_list" ||
                fieldtype === "tags"
            ) {
                attributeValue = Array.isArray(value) ? value : [];
            }

            if (fieldtype === "checkbox_single_choice") {
                attributeValue = String(value);
            }

            if (fieldtype === "date" && value !== "") {
                const date = dayjs(value).format("YYYY-MM-DD");
                attributeValue = date;
            }

            if (fieldtype === "time" && value !== "") {
                const time = dayjs(value, "HH:mm A").format("HH:mm A");
                attributeValue = time;
            }

            if (fieldtype === "date_time" && value !== "") {
                const dateTime = dayjs(value).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
                attributeValue = dateTime;
            }

            if (fieldtype === "date_range") {
                const startDate = moment(value.startDate);
                const endDate = moment(value.endDate);
                const formattedStartDate = startDate.format("YYYY-MM-DD");
                const formattedEndDate = endDate.format("YYYY-MM-DD");
                attributeValue = `${formattedStartDate} - ${formattedEndDate}`;
            }

            if (fieldtype === "image") {
                attributeValue = value
            }

            if (fieldtype === "file") {
                attributeValue = value
            }

            if (is_repeatable === true) {
                if (Array.isArray(value)) {
                    console.log("line 3460")
                    attributeValue = value;
                }
                else if (value === null) {
                    attributeValue = ""
                }
                else {
                    console.log("line 3463")
                    attributeValue = [value];
                }
            }

            // Create the main attribute object
            const attributeObject = {
                attribute_id: attribute_id,
                attribute_core_name: nameValue,
                attribute_data_type:
                    fieldtype === "checkbox_multiple_choice" ||
                        fieldtype === "dropdodown_multiple_choice" ||
                        fieldtype === "users_group_list" ||
                        fieldtype === "link_object"
                        ? "array"
                        : fieldtype === "file"
                            ? "file"
                            : fieldtype === "image"
                                ? "image"
                                : "string",
                attribute_value: attributeValue,
                is_child: "no",
                child_attribute: [],
                id: id
            };

            // Recursively process child attributes if any
            if (child_attribute.length > 0) {
                attributeObject.child_attribute = processAttributes(child_attribute);
                attributeObject.is_child = "yes";
            }

            // Add the attribute object to the result array
            result.push(attributeObject);
        });

        return result;
    }

    function generatePayload(attributeData) {
        const payload = processAttributes(attributeData);
        return payload;
    }

    const onSubmit2 = async (values) => {
        const validate = await validateAttributes();
        const validateContentAtt = await validateContentAttributes()

        if (validate?.isValid == false) {
            setShowBelowError(true);
        }
        else if (validateContentAtt?.isValid === false) {
            setShowBelowError(true);
        }
        else {
            setShowBelowError(false);
            const formData = new FormData();
            let permissionData;
            const fileAttribute = attributesData?.find(
                (data) => data?.fieldtype == "file"
            );
            const imageAttribute = attributesData?.find(
                (data) => data?.fieldtype == "image"
            );
            if (isAdvancePermission == true) {
                permissionData = permissions?.map((data) => {
                    return {
                        user_group_id: data?.value,
                        type: data?.type,
                        can_view: data?.view == true ? 1 : 0,
                        can_deny: data?.deny == true ? 1 : 0,
                        can_edit: data?.edit == true ? 1 : 0,
                        can_delete: data?.delete == true ? 1 : 0,
                        can_resource: data?.redacted == true ? 1 : 0,
                        label: data?.label,
                    };
                });
            } else {
                permissionData = permissions?.map((data) => {
                    return {
                        user_group_id: data?.value,
                        type: data?.type,
                        can_view: data?.view == true ? 1 : 0,
                        can_deny: data?.deny == true ? 1 : 0,
                        can_edit: 0,
                        can_delete: 0,
                        can_resource: 0,
                        label: data?.label,
                    };
                });
            }
            const categoryData = transformSelectedCategories(selectedCategories, getAllCategories);
            const payload = generatePayload(attributesData)
            const payload2 = generatePayload(conterntAndAttributeData)
            const assignContent = contentAttributeData.reduce((acc, item) => {
                acc[item.nameValue] = item.value;
                return acc;
            }, {});
            let data = {
                // title: values?.title,
                // editor_content: values?.content,
                assign_content: assignContent,
                status: values?.typePoststatus == true ? "enable" : "disable",
                // file_summary: values?.fileDescription,
                // type_master_id: values?.postType,
                type_master_id: selectedTypeID,
                publish: publicationOption == "draft" ? 0 : 1,
                category_data: categoryData,
                permission: permissionData,
                advance_permission: isAdvancePermission == false ? 0 : 1,
                attributes: payload,
                folder_id: values?.folder,
                assign_attribute: payload2
            }
            // formData.append("title", values?.title);
            // formData.append("editor_content", values?.content);
            if (id) {
                // formData.append("id", id);
                data.id = id
            }
            // formData.append(
            //     "status",
            //     values?.typePoststatus == true ? "enable" : "disable"
            // );


            if (selectedMediaFileForImage !== "") {
                console.log("line 3485")
                // formData.append("image", selectedMediaFileDetailsForImage?.id);
                data.image = selectedMediaFileDetailsForImage?.id
            }
            if (selectedMediaFileForDoc !== "") {
                // formData.append("file", selectedMediaFileDetailsForDoc?.id)
                data.file = selectedMediaFileDetailsForDoc?.id
            }


            if (
                (fileAttribute?.value !== "" && selectedFileForAtt instanceof Blob) ||
                selectedFileForAtt instanceof File
            ) {
                // formData.append(`${fileAttribute?.nameValue}`, selectedFileForAtt);
                // data.fileAttribute.nameValue = selectedFileForAtt
                if (data.fileAttribute) {
                    data.fileAttribute.nameValue = selectedFileForAtt;
                }
            }

            if (
                (imageAttribute?.value !== "" &&
                    selectedImageFileForAtt instanceof Blob) ||
                selectedImageFileForAtt instanceof File
            ) {
                // formData.append(
                //     `${imageAttribute?.nameValue}`,
                //     selectedImageFileForAtt
                // );
                if (data.imageAttribute) {
                    data.imageAttribute.nameValue = selectedImageFileForAtt;
                }
            }
            console.log("data for payload::", data)

            if (newsId) {
                try {
                    setLoading(true);
                    const response = await api.post(
                        `${apiUser}/post/update/${id}`,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${userToken}`,
                            },
                        }
                    );
                    if (response?.success == true) {
                        setLoading(false);
                        setPublicationOption("");

                        Toast.success(response?.message);
                        setSuccessMessage(response?.message);
                        localStorage.setItem("last-redirect-page", `/type/${type}`);
                        navigate(`/type/${type}`);
                        setPublicationOption("");
                    } else {
                        setLoading(false);
                        setPublicationOption("");
                        setIsWarningMessage(true);
                        Toast.error(response?.message);
                    }
                } catch (err) {
                    console.log("error in ctreate news api::", err);
                    setLoading(false);
                    setPublicationOption("");

                    if (err?.response?.status === 401) {
                        setLoading(false);
                        logoutHelper(dispatch, navigate, userToken);
                    }
                    if (err?.response?.status === 422) {
                        setLoading(false);
                        setIsWarningMessage(true);
                        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
                    }
                    if (err?.response?.status === 429) {
                        Toast.error("Too many attempts wait for 2 min.")
                    }
                }
            } else {
                try {
                    setLoading(true);
                    const response = await api.post(`${apiUser}/post/store`, data, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${userToken}`,
                        },
                    });
                    if (response?.success == true) {
                        setLoading(false);
                        setPublicationOption("");

                        Toast.success(response?.message);
                        setSuccessMessage(response?.message);
                        localStorage.setItem("last-redirect-page", `/type/${type}`);
                        navigate(`/type/${type}`);
                        setPublicationOption("");
                    } else {
                        setLoading(false);
                        setPublicationOption("");
                        setIsWarningMessage(true);
                        Toast.error(response?.message);
                    }
                } catch (err) {
                    console.log("error in ctreate news api::", err);
                    setLoading(false);
                    setPublicationOption("");

                    if (err?.response?.status === 401) {
                        setLoading(false);
                        logoutHelper(dispatch, navigate, userToken);
                    }
                    if (err?.response?.status === 422) {
                        setLoading(false);
                        setIsWarningMessage(true);
                        const errorMessages = Object.values(err?.response?.data?.errors);
                        errorMessages.forEach((message) => {
                            console.log(message);
                            // Optionally, you can display the message using your Toast library or other UI elements
                            Toast.error(message);
                        });
                    }
                    if (err?.response?.status === 429) {
                        Toast.error("Too many attempts wait for 2 min.")
                    }
                }
            }
        }
    };

    const fetchAllTypes = async (type) => {
        try {
            setLoading(true);
            const response = await api.get(`${apiUser}/type/master/list/${type}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            let types = response?.data?.map((type) => ({
                value: type?.id,
                label: type?.name,
            }));
            setGetAllTypes(types);
            setLoading(false);
            setLoading(false);
        } catch (err) {
            console.log("err`or in fetch news api::", err);
            if (err?.response?.status === 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
            setLoading(false);
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            className:
                value == index
                    ? `selected-tab-attribute`
                    : `non-selected-tab-attribute`,
        };
    }

    const fetchElementList = async () => {
        try {
            setLoading(true);
            const response = await api.get(`${apiUser}/post/other-data-list/${selectedTypeID}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success == true) {
                let acknowledgementQuestionsArray = [];

                const users = response?.response?.data?.users;
                const groups = response?.response?.data?.groups;
                const type = response?.response?.data?.type;
                const folder = response?.response?.data?.folder;

                const categories = response?.response?.data?.category

                // setAttributes(response?.response?.attributes);
                const finalGroups = groups?.map((data) => {
                    return {
                        label: data?.label,
                        value: data?.value,
                        view: true,
                        deny: false,
                        edit: false,
                        delete: false,
                        redacted: false,
                        type: data?.type,
                    };
                });
                const finalUsers = users?.map((data) => {
                    return {
                        label: data?.label,
                        value: data?.value,
                        view: true,
                        deny: false,
                        edit: false,
                        delete: false,
                        redacted: false,
                        type: data?.type,
                    };
                });

                const allGroupsAndUsersData = [
                    { label: "Users", options: finalUsers },
                    { label: "Groups", options: finalGroups },
                ];
                // Find the "all_user" option
                const allUserOption = finalUsers.find(
                    (user) => user.value == "all_users"
                );

                const allCategories = transformCategoryData(categories);
                // setContentAttributeData([
                //     ...(response?.response?.data?.assign_content || []),
                //     ...(response?.response?.data?.assign_attribute || [])
                // ]);
                if (!id) {
                    setContentAttributeData(response?.response?.data?.assign_content)
                    setContentAndAttributeData(response?.response?.data?.assign_attribute)
                }


                setGetAllGroupsAndUsers(allGroupsAndUsersData);
                setGetAllCategories(allCategories)

                // Preselect "all_user"
                if (allUserOption && !id) {
                    setPermissions([allUserOption]);
                }

                setIsFolderEnable(type?.is_folder === "enable" ? true : false)
                if (type?.is_folder === "enable") {
                    const data = folder?.map((data) => {
                        return {
                            label: data?.name,
                            value: data?.id
                        }
                    })
                    setFolderListData(data)
                }
                setLoading(false);
            } else if (response?.success == false) {
                setLoading(false);
                Toast.warning(response?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                setIsWarningMessage(true);
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                setIsWarningMessage(true);
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
        }
    };

    const fetchElementListForUser = async () => {
        try {
            setLoading(true);
            const response = await api.get(`${apiUser}/type-post/attribute/${type}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success == true) {
                let acknowledgementQuestionsArray = [];

                const users = response?.response?.users;
                const groups = response?.response?.groups;

                const categories = response?.response?.category

                const finalGroups = groups?.map((data) => {
                    return {
                        label: data?.label,
                        value: data?.value,
                        view: true,
                        deny: false,
                        edit: false,
                        delete: false,
                        redacted: false,
                        type: data?.type,
                    };
                });
                const finalUsers = users?.map((data) => {
                    return {
                        label: data?.label,
                        value: data?.value,
                        view: true,
                        deny: false,
                        edit: false,
                        delete: false,
                        redacted: false,
                        type: data?.type,
                    };
                });

                const allGroupsAndUsersData = [
                    { label: "Users", options: finalUsers },
                    { label: "Groups", options: finalGroups },
                ];
                // Find the "all_user" option
                const allUserOption = finalUsers.find(
                    (user) => user?.value == "all_users"
                );

                setGetAllGroupsAndUsers(allGroupsAndUsersData);

                // Preselect "all_user"
                if (allUserOption && !id) {
                    setPermissions([allUserOption]);
                }
                setLoading(false);
            } else if (response?.success == false) {
                setLoading(false);
                Toast.warning(response?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                setIsWarningMessage(true);
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                setIsWarningMessage(true);
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
        }
    };

    const handleInt = (data) => {
        return parseInt(data);
    };

    const processDateField = (fieldtype, value) => {
        if (value === "") return "";
        if (fieldtype === "date" || fieldtype === "date_time") {
            return dayjs(value);
        }
        if (fieldtype === "time") {
            return dayjs(value, "HH:mm A");
        }
    };

    const processImageField = (value) => {
        setSelectedImageFileForAtt(value);
    };

    const processFileField = (value) => {
        setSelectedFileForAtt(value);
    };

    const processDropDownSingleChoice = (value) => {
        return parseInt(value);
    };

    const processCheckboxSingleChoice = (value) => {
        if (typeof value === "string") {
            return value === "true";
        }
        // Add other data type checks if needed
        return Boolean(value);
    };

    const processMultipleChoice = (value) => {
        return value?.map(v => v);
    };

    const processUsersList = (value) => {
        return value?.map(item => {
            const parsedItem = parseInt(item, 10);
            return isNaN(parsedItem) ? item : parsedItem;
        });
    };

    const processRepeatableField = (data) => {
        const jsonString = data?.value;
        const cleanString = jsonString?.replace(/value\s*:\s*/g, "");
        const resultArray = JSON?.parse(cleanString);
        if (resultArray?.length > 0) {
            setAdditionalTextFields(prevFields => [...prevFields, ...resultArray]);
        }
        return resultArray;
    };

    const processDateRange = (value) => {
        const [startDateString, endDateString] = value?.split(" - ");
        const startDate = moment(startDateString, "YYYY-MM-DD");
        const endDate = moment(endDateString, "YYYY-MM-DD");
        return {
            startDate: startDate?.isValid() ? startDate : null,
            endDate: endDate?.isValid() ? endDate : null,
        };
    };

    const processAttribute = async (data) => {
        let contentValue = data?.value;

        switch (data?.fieldtype) {
            case "date":
            case "date_time":
            case "time":
                contentValue = processDateField(data?.fieldtype, data?.value);
                break;
            case "image":
                processImageField(data?.value);
                break;
            case "file":
                processFileField(data?.value);
                break;
            case "dropdodown_single_choice":
                contentValue = processDropDownSingleChoice(data?.value);
                break;
            case "checkbox_single_choice":
                contentValue = processCheckboxSingleChoice(data?.value);
                break;
            case "checkbox_multiple_choice":
            case "dropdodown_multiple_choice":
                contentValue = processMultipleChoice(data?.value);
                break;
            case "users_list":
                contentValue = processUsersList(data?.value);
                break;
            case "date_range":
                contentValue = processDateRange(data?.value);
                break;
            default:
                if (data?.is_repeatable) {
                    if (data?.fieldtype === "link_object" && data?.value?.length > 0) {
                        setAdditionalTextFields(prevFields => [...prevFields, ...data?.value]);
                    } else {
                        contentValue = processRepeatableField(data);
                    }
                }
                break;
        }

        if (data?.childAtts?.length > 0) {
            contentValue = await Promise.all(data?.childAtts?.map(processAttribute));
        }

        return {
            attribute_id: data?.attribute_id,
            fieldtype: data?.fieldtype,
            id: data?.id,
            is_mandatory: data?.is_mandatory,
            is_repeatable: data?.is_repeatable,
            label: data?.label,
            nameValue: data?.nameValue,
            options: data?.options,
            section_id: data?.section_id,
            value: contentValue,
            childAtts: data?.childAtts ? await Promise.all(data?.childAtts?.map(processAttribute)) : [],
        };
    };

    const fetchTypePostDetails = async () => {
        try {
            setLoading(true);
            const response = await api.get(`${apiUser}/post/edit/${id}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });

            if (response?.success == true) {
                let childAttData;
                const categories = response?.response?.category_data

                const permissions = response?.response?.data?.permission;

                const finalPermissions = permissions?.map((data) => {
                    return {
                        label: data?.label,
                        value: data?.user_group_id,
                        view: data?.can_view == 0 ? false : true,
                        deny: data?.can_deny == 0 ? false : true,
                        edit: data?.can_edit == 0 ? false : true,
                        delete: data?.can_delete == 0 ? false : true,
                        redacted: data?.can_resource == 0 ? false : true,
                        type: data?.type,
                    };
                });
                setPermissions(finalPermissions);
                setIsAdvancePermission(
                    response?.response?.data?.advance_permission == 0 ? false : true
                );

                // const allCategories = transformCategoryData(categories);
                // setGetAllCategories(allCategories);

                const transformedData = transformData(response?.response?.data?.category_data);
                // console.log("transformedData:::", transformedData)
                setSelectedCategories(transformedData)


                let fileData = {
                    id: response?.response?.file?.id,
                    title: response?.response?.file?.file_title
                }
                let imgFileData = {
                    id: response?.response?.media_id,
                    file_path: response?.response?.image,
                    type: "png"
                }
                let typePostData = {
                    title: response?.response?.data?.title,
                    content: response?.response?.data?.editor_content,
                    typePoststatus: response?.response?.data?.status == "enable" ? true : false,
                    image: response?.response?.data?.image,
                    published_date: response?.response?.data?.published_date,
                    file: response?.response?.data?.file,
                    fileDescription: response?.response?.data?.file?.file_summary,
                    folder: folderListData?.find((option) => option?.value == response?.response?.data?.folder_id)
                        ?.value
                };
                setGetNewsData(typePostData);
                setShowSaveDraft(response?.response?.data?.published_date ? false : true)
                setSelectedMediaTypeForImage(response?.response?.is_system === 0 ? "my-media" : "system")
                setSelectedMediaFolderIDForImage(response?.response?.media_folder_id)

                setSelectedMediaTypeForDoc(response?.response?.file?.is_system === 0 ? "my-media" : "system")
                setSelectedMediaFolderIDForDoc(response?.response?.file?.media_folder_id)
                setSelectedMediaFileForImage(response?.response?.data?.image?.id)
                setSelectedMediaFileForDoc(response?.response?.data?.file?.id)
                setSelectedMediaFileDetailsForDoc(response?.response?.data?.file)
                setSelectedMediaFileDetailsForImage(response?.response?.data?.image)
                const contentData = await processAttributesData(
                    response?.response?.data?.attributes,
                    setAdditionalTextFields,
                    setAdditionalTextFieldsForNestedChildAtt1
                );

                const contentAttData = await processAttributesDataContent(response?.response?.data?.assign_content,
                    setAdditionalTextFieldsContent,
                    setAdditionalTextFieldsForNestedChildAtt2
                )
                console.log("contentAttData:::", contentAttData)
                const contentAndAttData = await processAttributesDataContentAttribute(response?.response?.data?.assign_attribute,
                    setAdditionalTextFieldsContentAttribute,
                    setAdditionalTextFieldsForNestedChildAtt3
                )
                setContentAttributeData(contentAttData)
                setContentAndAttributeData(contentAndAttData)
                setAttributes(contentData);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);

            if (err?.response?.status === 401) {
                setIsWarningMessage(true);
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/`);
                navigate("/");
            }

            if (err?.response?.status === 403) {
                setIsWarningMessage(true);
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/`);
                navigate("/");
            }

            if (err?.response?.status == 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
        }
    };

    const fetchAllChangeLogs = async (page) => {
        try {
            setLoading(true);
            const response = await api.get(
                `${apiUser}/post/changelog/${parseInt(
                    id
                )}?per_page=${rowsPerPage}&page=${page}&search=${search}`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success == true) {
                response.response.data = response?.response?.data.map((log) => ({
                    ...log,
                    changed_at: dateWithTime(log.changed_at, true, true, true) || log.changed_at,
                }));
                setGetAllChangeLogs(response?.response);

                setLoading(false);
            } else if (response?.success == false) {
                setLoading(false);
                Toast.warning(response?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.message);
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data[0]?.message);
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
        }
    };

    const handleChangeLogCsvDownload = async () => {
        try {
            setLoading(true);

            const result = await exportXlsx(`${apiUser}/post/changelog/${parseInt(id)}?search=${search}&pagination=no`, 'post_change_log');
            if (!result) {
                Toast.error("Failed to download file");
            }

        } catch (error) {
            console.error("Error while downloading csv changelog::", error);
            Toast.error("Failed to download file");
        } finally {
            setLoading(false);
        }
    }

    const deleteMedia = async (id) => {
        let mediID;
        if (mediaDataFor === "image") {
            mediID = selectedMediaFileForImage
        } else if (mediaDataFor === "doc") {
            mediID = selectedMediaFileForDoc
        }
        try {
            setLoading(true);
            const response = await api.post(
                `user/media/file/delete/${mediID}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success === true) {
                setLoading(false);

                Toast.success(response?.message);
                let media_id;
                let folderID;
                if (mediaDataFor === "image") {
                    setSelectedMediaFileForImage("")
                    media_id = "";
                    folderID = selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage
                    setSelectedMediaFileDetailsForImage()
                } else if (mediaDataFor === "doc") {
                    setSelectedMediaFileForDoc("")
                    media_id = "";
                    folderID = selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc
                    setSelectedMediaFileDetailsForDoc()
                }

                await fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, media_id, folderID)
                handleCloseForMediaModel()

            } else {
                setLoading(false);
                Toast.error(response?.message);
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.message);
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data[0]?.message);
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
        }
    }

    const deleteMediaAtt = async (id) => {
        let mediID;
        if (mediaDataForAtt === "image") {
            mediID = selectedMediaFileForImageAtt
        } else if (mediaDataForAtt === "doc") {
            mediID = selectedMediaFileForDocAtt
        }
        try {
            setLoading(true);
            const response = await api.post(
                `user/media/file/delete/${mediID}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success === true) {
                setLoading(false);

                Toast.success(response?.message);
                let media_id;
                let folder_id;
                let type;
                if (mediaDataForAtt === "image") {
                    folder_id = selectedMediaFolderIDForImageAtt
                    setSelectedMediaFileForImageAtt("")
                    media_id = "";
                    setSelectedMediaFileDetailsForImageAtt()
                    if (selectedMediaTypeForImageAtt === "system") {
                        type = "system"
                    } else {
                        type = "user"
                    }
                } else if (mediaDataForAtt === "doc") {
                    folder_id = selectedMediaFolderIDFordocAtt
                    setSelectedMediaFileForDocAtt("")
                    media_id = "";
                    setSelectedMediaFileDetailsForDocAtt()
                    if (selectedMediaTypeForDocAtt === "system") {
                        type = "system"
                    } else {
                        type = "user"
                    }
                }

                await fetchMediaFilesForAtt(pageForMedia, rowsPerPageForMedia, mediaDataForAtt, folder_id, type)
                handleCloseForMediaModel()

            } else {
                setLoading(false);
                Toast.error(response?.message);
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.message);
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data[0]?.message);
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
        }
    }

    const fetchMediaFolders = async (dataFor, folderID) => {
        try {
            setLoading(true);
            const response = await api.get(
                `user/media/browse/folders`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success == true) {
                setFolderList(response?.response)
                setMyFolderList(response?.response?.my_media_folder)
                setSystemFolderList(response?.response?.system_media_folder)
                let media_id;
                if (dataFor == "image") {
                    setSelectedMediaTypeForImage(selectedMediaTypeForImage == "" ? "my-media" : selectedMediaTypeForImage)
                    media_id = selectedMediaFileForImage
                } else if (dataFor == "doc") {
                    setSelectedMediaTypeForDoc(selectedMediaTypeForDoc == "" ? "my-media" : selectedMediaTypeForDoc)
                    media_id = selectedMediaFileForDoc
                }
                fetchMediaFiles(pageForMedia, rowsPerPageForMedia, dataFor, media_id, folderID)
                setOpenMediaUpload(true)
                setLoading(false)
            } else if (response?.success == false) {
                setLoading(false)
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.message);
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data[0]?.message);
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
        }
    }

    const fetchMediaFoldersForAtt = async (dataFor, mediaType, folder_id) => {
        console.log("dataFor 4804:::", dataFor)
        try {
            setLoading(true);
            const response = await api.get(
                `user/media/browse/folders`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success === true) {
                setFolderListForAtt(response?.response)
                setMyFolderListForAtt(response?.response?.my_media_folder)
                setSystemFolderListForAtt(response?.response?.system_media_folder)
                let type;

                if (dataFor === "image") {
                    if (dataFor === "image") {
                        if (mediaType === "system") {
                            type = "system"
                        } else {
                            type = "user"
                        }
                    }
                } else if (dataFor === "doc") {
                    if (dataFor === "doc") {
                        if (mediaType === "system") {
                            type = "system"
                        } else {
                            type = "user"
                        }
                    }
                }
                await fetchMediaFilesForAtt(pageForMediaAtt, rowsPerPageForMediaAtt, dataFor, folder_id, type)
                setOpenMediaUploadForAtt(true)
                setLoading(false)
            } else if (response?.success === false) {
                setLoading(false)
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.message);
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data[0]?.message);
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
        }
    }

    const fetchMediaFiles = async (pageNo, perPage, dataFor, mediaFile, folderID) => {
        let type;
        if (dataFor === "image") {
            if (selectedMediaTypeForImage === "system") {
                type = "system"
            } else {
                type = "user"
            }
        } else if (dataFor === "doc") {
            if (selectedMediaTypeForDoc === "system") {
                type = "system"
            } else {
                type = "user"
            }
        }
        try {
            setLoading(true);
            const response = await api.post(
                `user/media/browse/files?page=${pageNo}`,
                {
                    folder_id: folderID,
                    type: type,
                    per_page: perPage,
                    file_type: dataFor === "image" ? "image" : "doc"
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success === true) {

                setMediaListResponse(response?.response)
                setMediaList(response?.response?.data)
                if (dataFor === "image" && mediaFile !== "") {
                    console.log("line 3928", mediaFile)
                    setSelectedMediaFileForImage(mediaFile)
                    setSelectedMediaFolderIDForImage(selectedMediaFolderIDForImage)
                } else if (dataFor === "doc" && selectedMediaFileForDoc !== "") {
                    setSelectedMediaFileForDoc(selectedMediaFileForDoc)
                    setSelectedMediaFolderIDForDoc(selectedMediaFolderIDFordoc)
                }
                setLoading(false)
            } else if (response?.success === false) {
                setLoading(false)
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.message);
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data[0]?.message);
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
        }
    }

    const fetchMediaFilesForAtt = async (pageNo, perPage, dataFor, folder_id, mediatype) => {
        let type;
        if (dataFor === "image") {
            if (mediatype === "system") {
                type = "system"
            } else {
                type = "user"
            }
        } else if (dataFor === "doc") {
            if (mediatype === "system") {
                type = "system"
            } else {
                type = "user"
            }
        }
        console.log("dataFor 4960::", dataFor)
        try {
            setLoading(true);
            const response = await api.post(
                `user/media/browse/files?page=${pageNo}`,
                {
                    folder_id: folder_id,
                    type: type,
                    per_page: perPage,
                    file_type: ""
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success === true) {
                setMediaListResponseAtt(response?.response)
                setMediaListAtt(response?.response?.data)
                if (dataFor === "image" && selectedMediaFileForImageAtt !== "") {

                } else if (dataFor === "doc" && selectedMediaFileForDocAtt !== "") {

                }
                setLoading(false)
            } else if (response?.success === false) {
                setLoading(false)
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.message);
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data[0]?.message);
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
        }
    }

    const fetchGroupsAndUsersList = async () => {
        setLoading(true)
        try {
            const response = await api.get(`/group-users-list`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success == true) {
                let acknowledgementQuestionsArray = [];

                const users = response?.response?.users;
                const groups = response?.response?.groups;

                const categories = response?.response?.category

                const finalGroups = groups?.map((data) => {
                    return {
                        label: data?.label,
                        value: data?.value,
                        view: false,
                        deny: false,
                        edit: false,
                        delete: false,
                        redacted: false,
                        type: data?.type,
                    };
                });
                const finalUsers = users?.map((data) => {
                    return {
                        label: data?.label,
                        value: data?.value,
                        view: false,
                        deny: false,
                        edit: false,
                        delete: false,
                        redacted: false,
                        type: data?.type,
                    };
                });
                setFetchAllUsers(finalUsers)

                const allGroupsAndUsersData = [
                    { label: "Users", options: finalUsers },
                    { label: "Groups", options: finalGroups },
                ];
                // Find the "all_user" option
                const allUserOption = finalUsers.find(
                    (user) => user.value == "all_users"
                );

                setGetAllGroupsAndUsersForMedia(allGroupsAndUsersData)

                // Preselect "all_user"
                if (allUserOption && !isEdit) {
                    setPermissionsForMedia([allUserOption])
                }
                setLoading(false);
            } else if (response?.success == false) {
                setLoading(false);
                Toast.warning(response?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                Toast.warning(err?.response?.message);
                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                Toast.warning(err?.response?.data[0]?.message);
                navigate("/");
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
        }
    }

    const fetchAllAttributes = async (searchData) => {
        let data = {
            search: searchData,
            type_id: typeID
        }
        setLoading(true)
        try {
            const response = await api.post(`/${apiUser}/post/attribute-list`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success == true) {
                const sections = [{ id: 'all', name: 'All' }, ...response?.response?.data?.section];
                setGetAllAttributeSections(sections)
                setGetAllAttributes(response?.response?.data?.attribute)
                setIsTypingSearchAtt(false)
                setLoading(false);
            } else if (response?.success == false) {
                setLoading(false);
                Toast.warning(response?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                Toast.warning(err?.response?.message);
                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                Toast.warning(err?.response?.data[0]?.message);
                navigate("/");
            }
            if (err?.response?.status == 429) {
                setLoading(false)
                Toast.error("Too many attempts wait for 2 min.")
            }
        }
    }

    const deleteTypePostAPI = async () => {
        try {
            setLoading(true);
            const response = await api.delete(`${apiUser}/post/delete/${newsId} `, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success == true) {
                handleCloseDeleteAlertModel();
                Toast.success(response?.message);
                setLoading(false);
                setIsSuccessMessage(true);
                setSuccessMessage(response?.message);
                localStorage.setItem("last-redirect-page", `/type/${selectedTypeName}`);
                navigate(`/type/${selectedTypeName}`)
            } else if (response?.success == false) {
                handleCloseDeleteAlertModel();
                Toast.warning(response?.message);
                setIsWarningMessage(true);
                setWarningMessage(response?.message);
                setLoading(false);
            }
        } catch (err) {
            if (err?.response?.status == 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        setWarningMessage(newWarningMessages);
    }, [newWarningMessages]);

    useEffect(() => {
        const apiCall = async () => {
            if (id) {
                setGetAllAttributeSections([]);
                setAttributes([]);
                setAttData();
                setAdditionalTextFields([]);
                setSelectedFile(null);
                setSelectedFileForAtt(null);
                setSelectedImageFile(null);
                setSelectedImageFileForAtt(null);
                setSelectedCategories([])
                await fetchElementList();
                await fetchAllTypes("menu");
                // await fetchAttributeSection();
                // await fetchElementListForUser();
                await fetchTypePostDetails();
                if (loggedUser?.user_type === "admin") {
                    await fetchAllChangeLogs(page + 1);
                }
                await fetchGroupsAndUsersList()
                await fetchAllAttributes(searchAtt)

            } else {
                setGetAllAttributeSections([]);
                setAttributes([]);
                setAttData();
                setAdditionalTextFields([]);
                setSelectedFile(null);
                setSelectedFileForAtt(null);
                setSelectedImageFile(null);
                setSelectedImageFileForAtt(null);
                setSelectedCategories([])
                fetchAllTypes("menu");
                // fetchAttributeSection();
                fetchElementList();
                fetchGroupsAndUsersList()
                fetchAllAttributes(searchAtt)
            }
        };
        apiCall();
    }, [id, typeID]);

    useEffect(() => {
        setGetAllAttributeSections([]);
        setAttributes([]);
        setAttData();
        setAdditionalTextFields([]);
        setSelectedFile(null);
        setSelectedFileForAtt(null);
        setSelectedImageFile(null);
        setSelectedImageFileForAtt(null);
        setSelectedCategories([])
        fetchAllTypes("menu");
        // fetchAttributeSection();
        fetchElementList();
        fetchGroupsAndUsersList()
        fetchAllAttributes(searchAtt)
    }, [type])

    useEffect(() => {
        if (search.length >= 3) {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => {
                setPage(0);
                fetchAllChangeLogs(page);
            }, debounceTimeout);
        }
        else if (search === "" && istype == true) {
            setPage(0);
            fetchAllChangeLogs(page);
        }
        else {
            clearTimeout(debounceTimer);
        }

        return () => clearTimeout(debounceTimer);
    }, [search]);

    useEffect(() => {
        if (selectedMediaFolderIDForImage !== "" && selectedMediaTypeForImage !== "") {
            fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, selectedMediaFileForImage, selectedMediaFolderIDForImage)
        }
    }, [selectedMediaFolderIDForImage, selectedMediaTypeForImage])

    useEffect(() => {
        if (selectedMediaFolderIDForImageAtt !== "" && selectedMediaTypeForImageAtt !== "") {
            let type;
            if (mediaDataForAtt === "image") {
                if (selectedMediaTypeForImageAtt === "system") {
                    type = "system"
                } else {
                    type = "user"
                }
            }
            fetchMediaFilesForAtt(pageForMediaAtt, rowsPerPageForMediaAtt, mediaDataForAtt, selectedMediaFolderIDForImageAtt == "" ? 0 : selectedMediaFolderIDForImageAtt, type)
        }
    }, [selectedMediaFolderIDForImageAtt, selectedMediaTypeForImageAtt])

    useEffect(() => {
        if (selectedMediaFolderIDFordoc !== "" && selectedMediaTypeForDoc !== "") {
            fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, selectedMediaFileForDoc, selectedMediaFolderIDFordoc)
        }
    }, [selectedMediaFolderIDFordoc, selectedMediaTypeForDoc])

    useEffect(() => {
        if (selectedMediaFolderIDFordocAtt !== "" && selectedMediaTypeForDocAtt !== "") {
            let type;

            if (mediaDataForAtt === "doc") {
                if (selectedMediaTypeForDocAtt === "system") {
                    type = "system"
                } else {
                    type = "user"
                }
            }
            fetchMediaFilesForAtt(pageForMediaAtt, rowsPerPageForMediaAtt, mediaDataForAtt, selectedMediaFolderIDFordocAtt === "" ? 0 : selectedMediaFolderIDFordocAtt, type)
        }
    }, [selectedMediaFolderIDFordocAtt, selectedMediaTypeForDocAtt])

    useEffect(() => {
        if (selectedMedia !== "" && isEditMedia) {
            fetchMediaDetails()
        }
    }, [selectedMedia])

    useEffect(() => {
        if (selectedMedia !== "" && isRenameMedia) {
            fetchMediaDetailsForRename()
        }
    }, [selectedMedia])

    useEffect(() => {
        if (selectedMediaAtt !== "" && isEditMediaAtt) {
            fetchMediaDetailsAtt()
        }
    }, [selectedMediaAtt])

    useEffect(() => {
        if (selectedMediaAtt !== "" && isRenameMediaAtt) {
            fetchMediaDetailsForRenameAtt()
        }
    }, [selectedMediaAtt])

    const observerErrorHandler = (e) => {
        if (e.message === "ResizeObserver loop completed with undelivered notifications.") {
            e.stopImmediatePropagation();
        }
    };

    useEffect(() => {
        window.addEventListener('error', observerErrorHandler);
        return () => {
            window.removeEventListener('error', observerErrorHandler);
        };
    }, []);

    useEffect(() => {
        handleImageFileValueCustAttForAtt()
    }, [selectedAnyMediaFileDetails])

    return (
        <>
            {/* START :: MAIN CONTENT WRAPPER */}
            <div
                className={`main-content-wrapper-2 ${open === true ? "drawer-open" : "drawer-close"
                    }  ${window.innerWidth <= 768 && "small-screen"}`}
            >
                <Container
                    className={`${window.innerWidth <= 768 && "small-screen-container"}`}
                >
                    {isSuccessMessage === true && (
                        <SuccessAlert
                            message={successMessage}
                            onClose={handleCloseSuccessMessage}
                        />
                    )}

                    {isWarningMessage === true && warningMessage?.length > 0 && (
                        <>
                            {warningMessage?.map((message, index) => (
                                <>
                                    <WarningAlert
                                        message={message}
                                        onClose={() => handleCloseWarningMessage(index)}
                                    />
                                </>
                            ))}
                        </>
                    )}
                    <Box className="">
                        <Stack spacing={2}>
                            <Breadcrumbs
                                separator={<NavigateNextIcon fontSize="small" />}
                                aria-label="breadcrumb"
                            >
                                {/* <Link
                                    underline="hover"
                                    key="1"
                                    color="inherit"
                                    href="/"
                                    component="h3"
                                >
                                    Admin
                                </Link>
                                <Typography
                                    key="3"
                                    component="h3"
                                    color="inherit"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        localStorage.setItem("last-redirect-page", `/type/${type}`);

                                        navigate(`/type/${type}`);
                                    }}
                                >
                                    {selectedTypeName}
                                </Typography> */}
                                <Typography key="3" component="h5" color="text.primary" className="add-new-title">
                                    {newsId
                                        ? `Edit ${selectedTypeName}`
                                        : `Add New ${selectedTypeName}`}
                                </Typography>
                            </Breadcrumbs>
                        </Stack>
                    </Box>
                    <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
                    <Formik
                        enableReinitialize
                        validateOnChange
                        initialValues={initialValues}
                        validationSchema={addNewNewsvalidationSchema}
                        onSubmit={(values) => {
                            onSubmit2(values);
                        }}
                    >
                        {({
                            values,
                            setFieldValue,
                            touched,
                            errors,
                            handleSubmit,
                            handleBlur,
                            isValid,
                            dirty,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault(); // Prevent form submission on Enter key
                                        }
                                    }}
                                >
                                    <Box className="publish-bar-box">
                                        <Box className="delete-div-box">

                                            {newsId && (
                                                <Box className="d-flex gap-2 align-items-center justify-content-center cursor-pointer" onClick={() => {
                                                    setOpenDeleteAlert(true);
                                                }}>
                                                    <FontAwesomeIcon icon={faTrash} className="delete-div-box-icon" />
                                                    <Typography className="delete-div-box-text">Delete</Typography>
                                                </Box>
                                            )}

                                        </Box>
                                        <Box className="last-div-box">
                                            {/* <Box className="preview-div-box">
                                                <FontAwesomeIcon icon={faEye} className="preview-div-box-icon" />
                                                <Typography className="preview-div-box-text">Preview</Typography>
                                            </Box> */}
                                            {showSaveDraft === true && (
                                                <Box className="save-draft-div-box">
                                                    {/* <FontAwesomeIcon icon={faFloppyDisk} className="save-draft-div-box-icon" />
                                                <Typography className="save-draft-div-box-text">Save Draft</Typography> */}
                                                    <Button
                                                        className="save-draft-btn"
                                                        type="submit"
                                                        onClick={() => {
                                                            setPublicationOption("draft");
                                                            setShowError(true);
                                                        }
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faFloppyDisk} className="save-draft-div-box-icon" />
                                                        Save Draft
                                                    </Button>
                                                </Box>
                                            )}

                                            <Box className="publish-btn-div-box">
                                                <Button
                                                    className="primary-btn"
                                                    type="submit"
                                                    onClick={() => {
                                                        setShowError(true);
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faCheck}
                                                        className="d-md-block d-sm-none d-none"
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faArrowRight}
                                                        className="d-md-none d-sm-block"
                                                    />
                                                    Publish Page
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>

                                    {/* START :: PAGE TITLE WRAPPER */}

                                    <Row className="mt-1 form-row">
                                        <Col lg="12" md="12" className="form-col">
                                            <Stack direction="column" className="gap-30">
                                                <Accordion
                                                    // defaultActiveKey={["0", "1", "2", "3"]}
                                                    alwaysOpen
                                                    className="w-100"
                                                >
                                                    <Accordion.Item
                                                        eventKey="0"
                                                        className="bg-white card-wrapper w-100"
                                                    >
                                                        <Accordion.Header className="bg-white fw-700 primary-text">
                                                            Content
                                                        </Accordion.Header>
                                                        <Accordion.Body className="content-card-body">
                                                            <Row>
                                                                {isFolderEnable === true && (
                                                                    <Col lg="12">
                                                                        <Box className="d-flex w-100">
                                                                            <Typography className="fw-400 dark-text fs-12-muted w-20">
                                                                                folder
                                                                            </Typography>
                                                                            <Box className="w-100">
                                                                                <Select
                                                                                    closeMenuOnSelect={true}
                                                                                    options={folderListData}
                                                                                    menuPlacement="auto"
                                                                                    name="folder"
                                                                                    value={folderListData.find(
                                                                                        (option) =>
                                                                                            option.value === values.folder
                                                                                    )}
                                                                                    placeholder="Select folder..."
                                                                                    onChange={(selectedOption) => {
                                                                                        setFieldValue(
                                                                                            "folder",
                                                                                            selectedOption
                                                                                                ? selectedOption.value
                                                                                                : ""
                                                                                        );
                                                                                    }}
                                                                                    className="muilt-select-field w-100"
                                                                                    classNamePrefix="select"

                                                                                    styles={{
                                                                                        option: (provided, state) => {
                                                                                            return {
                                                                                                ...provided,
                                                                                                backgroundColor: state.isSelected
                                                                                                    ? "#660c605c"
                                                                                                    : provided.backgroundColor,
                                                                                                color: state.isSelected
                                                                                                    ? "var(--dark-color)"
                                                                                                    : provided.color,
                                                                                            };
                                                                                        },
                                                                                    }}
                                                                                />
                                                                                {errors?.folder && touched.folder && (
                                                                                    <p
                                                                                        style={{ color: "#d32f2f" }}
                                                                                        className="error-msg"
                                                                                    >
                                                                                        {errors?.folder}
                                                                                    </p>
                                                                                )}
                                                                            </Box>
                                                                        </Box>
                                                                    </Col>
                                                                )}
                                                                {/* <Col lg="12">
                                                                    <Box className="d-flex w-100">
                                                                        <Typography className="fw-400 dark-text fs-12-muted w-20">
                                                                            Page Type<span className="required">*</span>
                                                                        </Typography>
                                                                        <Box className="w-100">
                                                                            <Select
                                                                                closeMenuOnSelect={true}
                                                                                options={getAllTypes}
                                                                                menuPlacement="auto"
                                                                                name="postType"
                                                                                value={getAllTypes.find(
                                                                                    (option) =>
                                                                                        option.value === values.postType
                                                                                )}
                                                                                placeholder="Select page type..."
                                                                                onChange={(selectedOption) => {
                                                                                    setFieldValue(
                                                                                        "postType",
                                                                                        selectedOption
                                                                                            ? selectedOption.value
                                                                                            : ""
                                                                                    );
                                                                                }}
                                                                                className="muilt-select-field w-100"
                                                                                classNamePrefix="select"
                                                                                isDisabled
                                                                                styles={{
                                                                                    option: (provided, state) => {
                                                                                        return {
                                                                                            ...provided,
                                                                                            backgroundColor: state.isSelected
                                                                                                ? "#660c605c"
                                                                                                : provided.backgroundColor,
                                                                                            color: state.isSelected
                                                                                                ? "var(--dark-color)"
                                                                                                : provided.color,
                                                                                        };
                                                                                    },
                                                                                }}
                                                                            />
                                                                            {errors?.postType && touched.postType && (
                                                                                <p
                                                                                    style={{ color: "#d32f2f" }}
                                                                                    className="error-msg"
                                                                                >
                                                                                    {errors?.postType}
                                                                                </p>
                                                                            )}
                                                                        </Box>
                                                                    </Box>
                                                                </Col> */}

                                                                {/* <Col lg="12">
                                                                    <Box className="d-flex w-100">
                                                                        <Typography className="fw-400 dark-text fs-12-muted w-20">
                                                                            Page Title
                                                                            <span className="required">*</span>
                                                                        </Typography>
                                                                        <TextField
                                                                            className="input-field w-80"
                                                                            fullWidth
                                                                            id="outlined-basic username"
                                                                            variant="outlined"
                                                                            size="small"
                                                                            name="title"
                                                                            placeholder="Enter title here..."
                                                                            value={values?.title}
                                                                            onChange={(event) => {
                                                                                setFieldValue(
                                                                                    "title",
                                                                                    event.target.value
                                                                                );
                                                                            }}
                                                                            helperText={touched.title && errors.title}
                                                                            error={
                                                                                touched.title && Boolean(errors.title)
                                                                            }
                                                                        />
                                                                    </Box>
                                                                </Col>

                                                                {isPostImage === true && (
                                                                    <Col lg="12">
                                                                        <Box className="d-flex w-100">
                                                                            <Typography className="fw-400 dark-text fs-12-muted w-20">
                                                                                Image<span className="required">*</span>
                                                                            </Typography>
                                                                            <Box className="w-100">
                                                                                <ImageUploadNew
                                                                                    value={values?.image}
                                                                                    handleClick={() => {
                                                                                        setOpenMediaModelFor("normal")
                                                                                        handleOpenUploadMedia("image")
                                                                                    }
                                                                                    }
                                                                                    setFieldValue={setFieldValue}
                                                                                />
                                                                                {errors?.image && touched.image && (
                                                                                    <p
                                                                                        style={{ color: "#d32f2f" }}
                                                                                        className="error-msg"
                                                                                    >
                                                                                        {errors?.image}
                                                                                    </p>
                                                                                )}
                                                                            </Box>
                                                                        </Box>
                                                                    </Col>
                                                                )}

                                                                {isPostFile === true && (
                                                                    <Col lg="12">
                                                                        <Box className="d-flex w-100">
                                                                            <Typography className="fw-400 dark-text fs-12-muted w-20">
                                                                                File
                                                                            </Typography>
                                                                            <Box className="w-100">
                                                                                <FileUploadNew
                                                                                    value={values?.file}
                                                                                    handleClick={() => {
                                                                                        setOpenMediaModelFor("normal")
                                                                                        handleOpenUploadMedia("doc")
                                                                                    }}
                                                                                    handleFileChange={handleFileChange}
                                                                                    setFieldValue={setFieldValue}
                                                                                    values={values}
                                                                                    touched={touched}
                                                                                    errors={errors}
                                                                                />
                                                                                {errors?.file && touched.file && (
                                                                                    <p
                                                                                        style={{ color: "#d32f2f" }}
                                                                                        className="error-msg"
                                                                                    >
                                                                                        {errors?.file}
                                                                                    </p>
                                                                                )}
                                                                            </Box>
                                                                        </Box>
                                                                    </Col>
                                                                )}
                                                                <Col lg="12">
                                                                    <Box className="d-flex w-100">
                                                                        <Typography className="fw-400 dark-text fs-12-muted w-20">
                                                                            Main Content<span className="required">*</span>
                                                                        </Typography>
                                                                        <Box className="w-100">
                                                                            <CustomEditor
                                                                                setFieldValue={setFieldValue}
                                                                                value={values?.content}
                                                                                editorConfiguration={configForContentEditor}
                                                                                setFieldValueFor="content"

                                                                            />
                                                                            {errors?.content && touched.content && (
                                                                                <p
                                                                                    style={{ color: "#d32f2f" }}
                                                                                    className="error-msg"
                                                                                >
                                                                                    {errors?.content}
                                                                                </p>
                                                                            )}
                                                                        </Box>
                                                                    </Box>
                                                                </Col> */}
                                                                <AllContentComponent
                                                                    attributesData={contentAttributeData}
                                                                    handleChangeForFormData={handleChangeForFormDataContent}
                                                                    setOpenMediaModelFor={setOpenMediaModelFor}
                                                                    handleOpenUploadMediaForAtt={handleOpenUploadMediaForAtt}
                                                                    handleChangeForChildAtts={handleChangeForChildAtts}
                                                                    isChild={false}
                                                                    parentAttData={contentAttributeData}
                                                                    handleNestedChildChange={handleNestedChildChangeContentAttribute}
                                                                    handleAddNestedTextField={handleAddNestedTextFieldContent}
                                                                    additionalTextFieldsForNestedChildAtt1={additionalTextFieldsForNestedChildAtt2}
                                                                    handleChangeForNestedChildAdditionalTextField={handleChangeForNestedChildAdditionalTextFieldContent}
                                                                    removeNestedExtraFields={removeNestedExtraFieldsContent}
                                                                    handleAddTextField={handleAddTextFieldContent}
                                                                    additionalTextFields={additionalTextFieldsContent}
                                                                    handleChangeForAdditionalTextField={handleChangeForAdditionalTextFieldContent}
                                                                    removeExtraFields={removeExtraFieldsContent}
                                                                    handleClickForAtt={handleClickForAttContent}
                                                                    setFileForContent={setFileForContent}
                                                                    fileForContent={fileForContent}
                                                                    handleCloseForUploadMediaForAtt={handleCloseForUploadMediaForAtt}
                                                                />

                                                                {conterntAndAttributeData?.length > 0 && (
                                                                    <AllContentAndAttributeComponent
                                                                        attributesData={conterntAndAttributeData}
                                                                        handleChangeForFormData={handleChangeForFormDataContentAttribute}
                                                                        setOpenMediaModelFor={setOpenMediaModelFor}
                                                                        handleOpenUploadMediaForAtt={handleOpenUploadMediaForAtt}
                                                                        handleChangeForChildAtts={handleChangeForChildAtts}
                                                                        isChild={false}
                                                                        parentAttData={conterntAndAttributeData}
                                                                        handleNestedChildChange={handleNestedChildChange}
                                                                        handleAddNestedTextField={handleAddNestedTextFieldContentAttribute}
                                                                        additionalTextFieldsForNestedChildAtt1={additionalTextFieldsForNestedChildAtt3}
                                                                        handleChangeForNestedChildAdditionalTextField={handleChangeForNestedChildAdditionalTextFieldContentattribute}
                                                                        removeNestedExtraFields={removeNestedExtraFieldsContentAttribute}
                                                                        handleAddTextField={handleAddTextFieldContentAttribute}
                                                                        additionalTextFields={additionalTextFieldsContentAttribute}
                                                                        handleChangeForAdditionalTextField={handleChangeForAdditionalTextFieldContentAttribute}
                                                                        removeExtraFields={removeExtraFieldsContentAttribute}
                                                                        handleClickForAtt={handleClickForAttContentAttribute}
                                                                        setFileForContent={setFileForContent}
                                                                        fileForContent={fileForContent}
                                                                        handleCloseForUploadMediaForAtt={handleCloseForUploadMediaForAtt}
                                                                    />
                                                                )}
                                                                {/* <Col lg="12">
                                                                    <Box className="d-flex w-100 gap-2">
                                                                        <Typography className="fw-400 dark-text fs-12-muted w-20">
                                                                            Enable {typeName}
                                                                        </Typography>
                                                                        <Box className="w-100">
                                                                            <AntSwitch
                                                                                name="typePoststatus"
                                                                                checked={values?.typePoststatus}
                                                                                onChange={(event) => {
                                                                                    setFieldValue(
                                                                                        "typePoststatus",
                                                                                        event.target.checked
                                                                                    );
                                                                                }}
                                                                                inputProps={{
                                                                                    "aria-label": "ant design",
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                </Col> */}
                                                            </Row>
                                                        </Accordion.Body>
                                                    </Accordion.Item>

                                                    <Accordion.Item
                                                        eventKey="1"
                                                        className="bg-white card-wrapper w-100 mt-4"
                                                    >
                                                        <Accordion.Header className="bg-white fw-700 primary-text ">
                                                            Attributes
                                                        </Accordion.Header>
                                                        <Accordion.Body className="att-acc-body">
                                                            <Box className="att-list-box">
                                                                <Box className="d-flex flex-column gap-3 justify-content-center att-card-body">
                                                                    <Box>
                                                                        {attributesData?.length > 0 ? (
                                                                            <>
                                                                                <AllComponent
                                                                                    attributesData={attributesData}
                                                                                    handleChangeForFormData={handleChangeForFormData}
                                                                                    setOpenMediaModelFor={setOpenMediaModelFor}
                                                                                    handleOpenUploadMediaForAtt={handleOpenUploadMediaForAtt}
                                                                                    handleChangeForChildAtts={handleChangeForChildAtts}
                                                                                    isChild={false}
                                                                                    parentAttData={attributesData}
                                                                                    handleNestedChildChange={handleNestedChildChange}
                                                                                    handleAddNestedTextField={handleAddNestedTextField}
                                                                                    additionalTextFieldsForNestedChildAtt1={additionalTextFieldsForNestedChildAtt1}
                                                                                    handleChangeForNestedChildAdditionalTextField={handleChangeForNestedChildAdditionalTextField}
                                                                                    removeNestedExtraFields={removeNestedExtraFields}
                                                                                    handleAddTextField={handleAddTextField}
                                                                                    additionalTextFields={additionalTextFields}
                                                                                    handleChangeForAdditionalTextField={handleChangeForAdditionalTextField}
                                                                                    removeExtraFields={removeExtraFields}
                                                                                    handleClickForAtt={handleClickForAtt}
                                                                                />
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Typography className="no-att-text text-center">There are no current attributes selected.</Typography>
                                                                            </>
                                                                        )}
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            <Box className="att-action-box">
                                                                <Button
                                                                    className="btn primary-btn"
                                                                    // type="submit"
                                                                    onClick={() => {
                                                                        // setPublicationOption("draft");
                                                                        // setShowError(true);
                                                                        setOpenAddAttribute(true)
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon icon={faAdd} />
                                                                    Add Attributes
                                                                </Button>
                                                            </Box>
                                                        </Accordion.Body>
                                                    </Accordion.Item>

                                                    <Accordion.Item
                                                        eventKey="2"
                                                        className="bg-white card-wrapper w-100 mt-4"
                                                    >
                                                        <Accordion.Header className="bg-white fw-700 primary-text">
                                                            Category
                                                        </Accordion.Header>
                                                        <Accordion.Body className="content-card-body">
                                                            <Row>
                                                                {getAllCategories?.length > 0 && (
                                                                    <>
                                                                        {getAllCategories?.map((category) => (
                                                                            <>
                                                                                <Col lg="12" className="">
                                                                                    <Box className="d-flex w-100">
                                                                                        <Typography className="fw-400 dark-text fs-12-muted w-20">
                                                                                            {category?.label}
                                                                                        </Typography>
                                                                                        <Box className="w-100">
                                                                                            <Select
                                                                                                closeMenuOnSelect={false}
                                                                                                options={category?.options}
                                                                                                menuPlacement="top"
                                                                                                name="groupManagers"
                                                                                                value={selectedCategories[category.slug] || []}
                                                                                                placeholder={`Select Terms For ${category?.label}...`}
                                                                                                menuPosition="fixed"
                                                                                                isMulti
                                                                                                onChange={(selectedOption) => handleCategoryChange(category.slug, selectedOption)}

                                                                                                className={`muilt-select-field w-100`}
                                                                                                classNamePrefix="select"
                                                                                                styles={{
                                                                                                    option: (provided, state) => {
                                                                                                        return {
                                                                                                            ...provided,
                                                                                                            backgroundColor: state.isSelected
                                                                                                                ? "#660c605c"
                                                                                                                : provided.backgroundColor,
                                                                                                            color: state.isSelected
                                                                                                                ? "var(--dark-color)"
                                                                                                                : provided.color,
                                                                                                        };
                                                                                                    },
                                                                                                }}
                                                                                            />
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Col>
                                                                            </>
                                                                        ))}
                                                                    </>
                                                                )}
                                                            </Row>
                                                        </Accordion.Body>
                                                    </Accordion.Item>

                                                    <Accordion.Item
                                                        eventKey="3"
                                                        className="bg-white card-wrapper w-100 mt-4"
                                                    >
                                                        <Accordion.Header className="bg-white fw-700 primary-text">
                                                            Access and Permissions
                                                        </Accordion.Header>
                                                        <Accordion.Body className="content-card-body">
                                                            <Row>
                                                                <Col lg="12" className="">
                                                                    <Typography className="fw-400 fs-14 dark-text">
                                                                        Select Users
                                                                    </Typography>
                                                                    <Select
                                                                        closeMenuOnSelect={false}
                                                                        options={getAllGroupsAndUsers}
                                                                        menuPlacement="bottom"
                                                                        name="users"
                                                                        value={permissions}
                                                                        placeholder="Select users..."
                                                                        isMulti
                                                                        menuPosition="fixed"
                                                                        onChange={(selectedOption) => {
                                                                            const users = selectedOption.map(
                                                                                (option) => option
                                                                            );
                                                                            setPermissions(users);
                                                                        }}
                                                                        className={`muilt-select-field mt-2 `}
                                                                        classNamePrefix="select"
                                                                        styles={{
                                                                            option: (provided, state) => ({
                                                                                ...provided,
                                                                                backgroundColor: state.isSelected
                                                                                    ? "#660c605c"
                                                                                    : provided.backgroundColor,
                                                                                color: state.isSelected
                                                                                    ? "var(--dark-color)"
                                                                                    : provided.color,
                                                                            }),
                                                                            multiValueLabel: (provided, state) => {
                                                                                return {
                                                                                    ...provided,
                                                                                    color:
                                                                                        state.data?.type === "group"
                                                                                            ? "rgb(0, 135, 90)"
                                                                                            : "rgb(0, 82, 204)",
                                                                                };
                                                                            },
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col lg="12" className="repeatable-col">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={isAdvancePermission === true}
                                                                                name="allowEdit"
                                                                                onChange={() =>
                                                                                    setIsAdvancePermission(
                                                                                        !isAdvancePermission
                                                                                    )
                                                                                }
                                                                            />
                                                                        }
                                                                        label="Show Advanced Permissions"
                                                                        className="font-inter fs-14"
                                                                    />
                                                                </Col>
                                                                <Col lg="12" className="repeatable-col">
                                                                    <TableContainer className="z-0">
                                                                        <Table
                                                                            // sx={{ minWidth: 750 }}
                                                                            aria-labelledby="tableTitle"
                                                                            stickyHeader
                                                                        >
                                                                            <TableHeaderForSorting
                                                                                columns={
                                                                                    isAdvancePermission === false
                                                                                        ? columns_atttributes_headers
                                                                                        : columns_advace_atttributes_headers
                                                                                }
                                                                            />
                                                                            <TableBody>
                                                                                {permissions?.length > 0 && (
                                                                                    <>
                                                                                        {permissions?.map(
                                                                                            (permission, index) => (
                                                                                                <>
                                                                                                    <TableRow
                                                                                                        hover
                                                                                                        role="checkbox"
                                                                                                        tabIndex={-1}
                                                                                                        key={permission.value}
                                                                                                        className="cursor-pointer tbl-row-attribute-row"
                                                                                                    >
                                                                                                        <TableCell
                                                                                                            align="left"
                                                                                                            className={` tbl-border-left tbl-cell-attribute-2`}
                                                                                                            style={{
                                                                                                                width: "100%",
                                                                                                                top: 0,
                                                                                                                left: 0,
                                                                                                            }}
                                                                                                        >
                                                                                                            <Typography className="fs-14">
                                                                                                                {permission?.label}
                                                                                                            </Typography>
                                                                                                        </TableCell>

                                                                                                        <TableCell
                                                                                                            align="right"
                                                                                                            className={`tbl-cell-attribute-2`}
                                                                                                            style={{
                                                                                                                width: "100%",
                                                                                                                top: 0,
                                                                                                                left: 0,
                                                                                                            }}
                                                                                                        >
                                                                                                            <AntSwitch
                                                                                                                name="view"
                                                                                                                checked={
                                                                                                                    permission.view
                                                                                                                }
                                                                                                                onChange={() =>
                                                                                                                    handleToggle(
                                                                                                                        permission.value,
                                                                                                                        "view"
                                                                                                                    )
                                                                                                                }
                                                                                                                inputProps={{
                                                                                                                    "aria-label":
                                                                                                                        "ant design",
                                                                                                                }}
                                                                                                            />
                                                                                                        </TableCell>
                                                                                                        <TableCell
                                                                                                            align="right"
                                                                                                            className={`tbl-cell-attribute-2`}
                                                                                                            style={{
                                                                                                                width: "100%",
                                                                                                                top: 0,
                                                                                                                left: 0,
                                                                                                            }}
                                                                                                        >
                                                                                                            <AntSwitch
                                                                                                                name="deny"
                                                                                                                checked={
                                                                                                                    permission.deny
                                                                                                                }
                                                                                                                onChange={() =>
                                                                                                                    handleToggle(
                                                                                                                        permission.value,
                                                                                                                        "deny"
                                                                                                                    )
                                                                                                                }
                                                                                                                inputProps={{
                                                                                                                    "aria-label":
                                                                                                                        "ant design",
                                                                                                                }}
                                                                                                            />
                                                                                                        </TableCell>

                                                                                                        {isAdvancePermission && (
                                                                                                            <>
                                                                                                                <TableCell
                                                                                                                    align="right"
                                                                                                                    className={`tbl-cell-attribute-2`}
                                                                                                                    style={{
                                                                                                                        width: "100%",
                                                                                                                        top: 0,
                                                                                                                        left: 0,
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <AntSwitch
                                                                                                                        name="edit"
                                                                                                                        checked={
                                                                                                                            permission.edit
                                                                                                                        }
                                                                                                                        onChange={() =>
                                                                                                                            handleToggle(
                                                                                                                                permission.value,
                                                                                                                                "edit"
                                                                                                                            )
                                                                                                                        }
                                                                                                                        inputProps={{
                                                                                                                            "aria-label":
                                                                                                                                "ant design",
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </TableCell>
                                                                                                                <TableCell
                                                                                                                    align="right"
                                                                                                                    className={`tbl-cell-attribute-2`}
                                                                                                                    style={{
                                                                                                                        width: "100%",
                                                                                                                        top: 0,
                                                                                                                        left: 0,
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <AntSwitch
                                                                                                                        name="delete"
                                                                                                                        checked={
                                                                                                                            permission.delete
                                                                                                                        }
                                                                                                                        onChange={() =>
                                                                                                                            handleToggle(
                                                                                                                                permission.value,
                                                                                                                                "delete"
                                                                                                                            )
                                                                                                                        }
                                                                                                                        inputProps={{
                                                                                                                            "aria-label":
                                                                                                                                "ant design",
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </TableCell>
                                                                                                                <TableCell
                                                                                                                    align="right"
                                                                                                                    className={`tbl-border-right tbl-cell-attribute-2`}
                                                                                                                    style={{
                                                                                                                        width: "100%",
                                                                                                                        top: 0,
                                                                                                                        left: 0,
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <AntSwitch
                                                                                                                        name="redacted"
                                                                                                                        checked={
                                                                                                                            permission.redacted
                                                                                                                        }
                                                                                                                        onChange={() =>
                                                                                                                            handleToggle(
                                                                                                                                permission.value,
                                                                                                                                "redacted"
                                                                                                                            )
                                                                                                                        }
                                                                                                                        inputProps={{
                                                                                                                            "aria-label":
                                                                                                                                "ant design",
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </TableCell>
                                                                                                            </>
                                                                                                        )}
                                                                                                    </TableRow>
                                                                                                    <TableRow className="extra-row"></TableRow>
                                                                                                </>
                                                                                            )
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </Col>
                                                            </Row>
                                                        </Accordion.Body>
                                                    </Accordion.Item>

                                                    {id && loggedUser?.user_type === "admin" && (
                                                        <Accordion.Item
                                                            eventKey="4"
                                                            className="bg-white card-wrapper mt-4"
                                                        >
                                                            <Accordion.Header className="bg-white fw-700 primary-text">
                                                                Changelog
                                                            </Accordion.Header>
                                                            <Accordion.Body className="p-0 changelog-body">
                                                                <Grid
                                                                    container
                                                                    spacing={1}
                                                                    className="changelog-div  "
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        md={12}
                                                                        sm={12}
                                                                        lg={9}
                                                                        xl={9}
                                                                    >
                                                                        <Box className="">
                                                                            <InputGroup className="">
                                                                                <TextField
                                                                                    className="search-changelog-input"
                                                                                    fullWidth
                                                                                    id="outlined-basic username"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    name="attributeTitle"
                                                                                    value={search}
                                                                                    placeholder="search changelog"
                                                                                    onChange={(e) => {
                                                                                        setIstype(true);
                                                                                        setSearch(e.target.value);
                                                                                        if (e.target.value === "") {
                                                                                            fetchAllChangeLogs(page);
                                                                                        }
                                                                                    }}
                                                                                    onKeyDown={(e) => {
                                                                                        if (e.key === "Enter") {
                                                                                            e.preventDefault();
                                                                                            fetchAllChangeLogs(page);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                <Button
                                                                                    className="search-changelog"
                                                                                    onClick={() => {
                                                                                        fetchAllChangeLogs(page);
                                                                                    }}
                                                                                >
                                                                                    <FontAwesomeIcon
                                                                                        icon={faSearch}
                                                                                        className="search-changelog-icon"
                                                                                    />
                                                                                </Button>
                                                                            </InputGroup>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        md={12}
                                                                        sm={12}
                                                                        lg={3}
                                                                        xl={3}
                                                                    >
                                                                        <div className="w-100">

                                                                            <Button
                                                                                variant="outlined"
                                                                                startIcon={
                                                                                    <FontAwesomeIcon icon={faFileCsv} />
                                                                                }
                                                                                className="export-csv-btn"
                                                                                onClick={handleChangeLogCsvDownload}
                                                                            >
                                                                                Export as CSV
                                                                            </Button>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                                <Paper
                                                                    sx={{ width: "100%" }}
                                                                    className="changelog-table-div mt-4"
                                                                >
                                                                    <TableContainer className="">
                                                                        <Table
                                                                            sx={{ minWidth: 750 }}
                                                                            aria-labelledby="tableTitle"
                                                                            stickyHeader
                                                                        >
                                                                            <TableHeaderForSorting
                                                                                columns={
                                                                                    columns_attribute_changelog_header
                                                                                }
                                                                                className="z-0"
                                                                            />
                                                                            <TableBody>
                                                                                {getAllChangeLogs?.data?.map(
                                                                                    (data, index) => (
                                                                                        <>
                                                                                            <TableRow
                                                                                                hover
                                                                                                role="checkbox"
                                                                                                tabIndex={-1}
                                                                                                key={index + 1}
                                                                                                className=" tbl-changelog mt-2"
                                                                                            >
                                                                                                <TableCell
                                                                                                    align="left"
                                                                                                    className="tbl-border-left tbl-cell-5"
                                                                                                >
                                                                                                    <Box>
                                                                                                        <Stack
                                                                                                            direction="row"
                                                                                                            alignContent="center"
                                                                                                            alignItems="center"
                                                                                                            spacing={1}
                                                                                                            className="z-n1"
                                                                                                        >
                                                                                                            <Avatar
                                                                                                                alt={data?.changed_by?.name}
                                                                                                                src={data?.changed_by?.thumbnail}
                                                                                                                className="author-avtar-type-post"
                                                                                                            >
                                                                                                                {data?.changed_by?.name?.charAt(0)}
                                                                                                            </Avatar>
                                                                                                            <Typography className="fw-400 fs-12">
                                                                                                                {data?.changed_by?.name
                                                                                                                    ? data?.changed_by?.name
                                                                                                                    : "-"}
                                                                                                            </Typography>
                                                                                                        </Stack>
                                                                                                    </Box>
                                                                                                </TableCell>
                                                                                                <TableCell
                                                                                                    align="left"
                                                                                                    className="text-nowrap fs-12"
                                                                                                >
                                                                                                    {data?.changed_at
                                                                                                        ? data?.changed_at
                                                                                                        : "-"}
                                                                                                </TableCell>
                                                                                                <TableCell
                                                                                                    align="left"
                                                                                                    className="text-nowrap fs-12"
                                                                                                >
                                                                                                    {data?.area
                                                                                                        ? data?.area
                                                                                                        : "-"}
                                                                                                </TableCell>
                                                                                                <TableCell
                                                                                                    align="left"
                                                                                                    className="tbl-border-right"
                                                                                                >
                                                                                                    <Typography
                                                                                                        className="fw-400 fs-12"
                                                                                                        dangerouslySetInnerHTML={{
                                                                                                            __html: data?.description
                                                                                                                ? processedHTML(
                                                                                                                    data?.description
                                                                                                                ).innerHTML
                                                                                                                : "-",
                                                                                                        }}
                                                                                                    ></Typography>
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                            <TableRow className="extra-row"></TableRow>
                                                                                        </>
                                                                                    )
                                                                                )}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </Paper>
                                                                {total_changelog > rowsPerPage && (
                                                                    <TablePagination
                                                                        component="div"
                                                                        count={total_changelog}
                                                                        page={page}
                                                                        onPageChange={handleChangePage}
                                                                        rowsPerPage={rowsPerPage}
                                                                        onRowsPerPageChange={
                                                                            handleChangeRowsPerPage
                                                                        }
                                                                        labelDisplayedRows={({
                                                                            from,
                                                                            to,
                                                                            count,
                                                                        }) => {
                                                                            const currentPage = page + 1;
                                                                            const start =
                                                                                currentPage * rowsPerPage -
                                                                                rowsPerPage +
                                                                                1;
                                                                            const end = Math.min(
                                                                                currentPage * rowsPerPage,
                                                                                count
                                                                            );
                                                                            return (
                                                                                <>
                                                                                    {`Showing ${start} to ${end} of `}
                                                                                    <span>{count}</span>
                                                                                    {" records"}
                                                                                </>
                                                                            );
                                                                        }}
                                                                    />
                                                                )}
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    )}
                                                </Accordion>
                                            </Stack>
                                        </Col>

                                    </Row>
                                    <FileUploadModal
                                        open={openFileUploadModel}
                                        setOpen={setOpenFileUploadModel}
                                        handleOpen={handleOpen}
                                        handleClose={handleClose}
                                        title={contentForPost}
                                        setFieldValue={setFieldValue}
                                        fileTypes={
                                            contentForPost === "image" ? imageFileTypes : fileTypes
                                        }
                                        handleFileChange={handleFileChange}
                                        file={
                                            contentForPost === "image"
                                                ? selectedImageFile
                                                : selectedFile
                                        }
                                        setFileValue={() => handleImageFileValue(setFieldValue)}
                                        isImage={contentForPost === "image" ? true : false}
                                        isImageEditable={isEdit}
                                    />
                                    <FileUploadModalAttribue
                                        open={openFileUploadModelForAtt}
                                        setOpen={setOpenFileUploadModelForAtt}
                                        handleOpen={handleOpenForAtt}
                                        handleClose={handleCloseForAtt}
                                        title={contentForPostForAtt}
                                        setFieldValue={setFieldValue}
                                        fileTypes={
                                            contentForPostForAtt === "image"
                                                ? imageFileTypes
                                                : fileTypes
                                        }
                                        handleFileChange={handleFileChangeForAtt}
                                        file={
                                            contentForPostForAtt === "image"
                                                ? selectedImageFileForAtt
                                                : selectedFileForAtt
                                        }
                                        setFileValue={handleImageFileValueForAtt}
                                        isImage={contentForPostForAtt === "image" ? true : false}
                                        isImageEditable={isEdit}
                                    />

                                    <UploadMediaModel
                                        openMediaUpload={openMediaUpload}
                                        handleCloseForUploadMedia={handleCloseForUploadMedia}
                                        selectedMediaTypeForImage={selectedMediaTypeForImage}
                                        selectedMediaTypeForDoc={selectedMediaTypeForDoc}
                                        setSelectedMediaTypeForImage={setSelectedMediaTypeForImage}
                                        setSelectedMediaTypeForDoc={setSelectedMediaTypeForDoc}
                                        setSelectedMediaFolderIDForImage={setSelectedMediaFolderIDForImage}
                                        setSelectedMediaFolderIDForDoc={setSelectedMediaFolderIDForDoc}
                                        systemMediaFolderList={systemMediaFolderList}
                                        selectedMediaFolderIDForImage={selectedMediaFolderIDForImage}
                                        selectedMediaFolderIDFordoc={selectedMediaFolderIDFordoc}
                                        myMediaFolderList={myMediaFolderList}
                                        downloadFileForImage={downloadFileForImage}
                                        downloadFileFordoc={downloadFileFordoc}
                                        selectedMediaFileDetailsForImage={selectedMediaFileDetailsForImage}
                                        setSelectedMediaFileDetailsForImage={setSelectedMediaFileDetailsForImage}
                                        selectedMediaFileForImage={selectedMediaFileForImage}
                                        setSelectedMediaFileForImage={setSelectedMediaFileForImage}
                                        selectedMediaFileDetailsForDoc={selectedMediaFileDetailsForDoc}
                                        setSelectedMediaFileDetailsForDoc={setSelectedMediaFileDetailsForDoc}
                                        selectedMediaFileForDoc={selectedMediaFileForDoc}
                                        setSelectedMediaFileForDoc={setSelectedMediaFileForDoc}
                                        setFieldValue={setFieldValue}
                                        mediaList={mediaList}
                                        total_mediaFiles={total_mediaFiles}
                                        pageForMedia={pageForMedia}
                                        setPageForMedia={setPageForMedia}
                                        handleChangePageForMedia={handleChangePageForMedia}
                                        rowsPerPageForMedia={rowsPerPageForMedia}
                                        handleChangeRowsPerPageForMedia={handleChangeRowsPerPageForMedia}
                                        media_Pagination_options={media_Pagination_options}
                                        mediaDataFor={mediaDataFor}
                                        handleCloseMediaModelAddMedia={handleCloseMediaModelAddMedia}
                                        setSelectedFileMedia={setSelectedFileMedia}
                                        selectedFileMedia={selectedFileMedia}
                                        isEditMedia={isEditMedia}
                                        setIsEditMedia={setIsEditMedia}
                                        setSelectedMedia={setSelectedMedia}
                                        selectedMedia={selectedMedia}
                                        deleteMedia={deleteMedia}
                                        setOpenRenameModel={setOpenRenameModel}
                                        setIsRenameMedia={setIsRenameMedia}
                                    />

                                    <UploadMediaModelAtt
                                        openMediaUpload={openMediaUploadForAtt}
                                        handleCloseForUploadMedia={handleCloseForUploadMediaForAtt}
                                        selectedMediaTypeForImage={selectedMediaTypeForImageAtt}
                                        selectedMediaTypeForDoc={selectedMediaTypeForDocAtt}
                                        setSelectedMediaTypeForImage={setSelectedMediaTypeForImageAtt}
                                        setSelectedMediaTypeForDoc={setSelectedMediaTypeForDocAtt}
                                        setSelectedMediaFolderIDForImage={setSelectedMediaFolderIDForImageAtt}
                                        setSelectedMediaFolderIDForDoc={setSelectedMediaFolderIDForDocAtt}
                                        systemMediaFolderList={systemMediaFolderListForAtt}
                                        selectedMediaFolderIDForImage={selectedMediaFolderIDForImageAtt}
                                        selectedMediaFolderIDFordoc={selectedMediaFolderIDFordocAtt}
                                        myMediaFolderList={myMediaFolderListForAtt}
                                        downloadFileForImage={downloadFileForImageAtt}
                                        downloadFileFordoc={downloadFileFordocAtt}
                                        selectedMediaFileDetailsForImage={selectedMediaFileDetailsForImageAtt}
                                        setSelectedMediaFileDetailsForImage={setSelectedMediaFileDetailsForImageAtt}
                                        selectedMediaFileForImage={selectedMediaFileForImageAtt}
                                        setSelectedMediaFileForImage={setSelectedMediaFileForImageAtt}
                                        selectedMediaFileDetailsForDoc={selectedMediaFileDetailsForDocAtt}
                                        setSelectedMediaFileDetailsForDoc={setSelectedMediaFileDetailsForDocAtt}
                                        selectedMediaFileForDoc={selectedMediaFileForDocAtt}
                                        setSelectedMediaFileForDoc={setSelectedMediaFileForDocAtt}
                                        mediaList={mediaListAtt}
                                        total_mediaFiles={total_mediaFilesAtt}
                                        pageForMedia={pageForMediaAtt}
                                        setPageForMedia={setPageForMediaAtt}
                                        handleChangePageForMedia={handleChangePageForMediaForAtt}
                                        rowsPerPageForMedia={rowsPerPageForMediaAtt}
                                        handleChangeRowsPerPageForMedia={handleChangeRowsPerPageForMediaAtt}
                                        media_Pagination_options={media_Pagination_options}
                                        mediaDataFor={mediaDataForAtt}
                                        handleImageFileValueCustAttForAtt={handleImageFileValueCustAttForAtt}
                                        handleCloseMediaModelAddMedia={handleCloseMediaModelAddMediaAtt}
                                        setSelectedFileMedia={setSelectedFileMediaAtt}
                                        selectedFileMedia={selectedFileMediaAtt}
                                        isEditMedia={isEditMediaAtt}
                                        setIsEditMedia={setIsEditMediaAtt}
                                        setSelectedMedia={setSelectedMediaAtt}
                                        selectedMedia={selectedMediaAtt}
                                        deleteMedia={deleteMediaAtt}
                                        setOpenRenameModel={setOpenRenameModelAtt}
                                        setIsRenameMedia={setIsRenameMediaAtt}
                                        setSelectedMediaFileDetailsForAtt={setSelectedMediaFileDetailsForAtt}
                                        selectedMediaFileDetailsForAtt={selectedMediaFileDetailsForAtt}
                                        setSelectedMediaFileForAtt={setSelectedMediaFileForAtt}
                                        selectedMediaFileForAtt={selectedMediaFileForAtt}
                                        setShowRenderImage={setShowRenderImage}
                                        showRenderImage={showRenderImage}
                                        setUploadedFile={setUploadedFile}
                                        uploadedFile={uploadedFile}
                                        isSystemTypeMediaAtt={isSystemTypeMediaAtt}
                                        mediaTitleAtt={mediaTitleAtt}
                                        mediaAltTextAtt={mediaAltTextAtt}
                                        mediaDescriptionAtt={mediaDescriptionAtt}
                                        isAdvancePermissionForMedia={isAdvancePermissionForMedia}
                                        permissionsForMedia={permissionsForMedia}
                                        parentFolderIdAtt={parentFolderIdAtt}
                                        setLoading={setLoading}
                                        userToken={userToken}
                                        mediaDataForAtt={mediaDataForAtt}
                                        dispatch={dispatch}
                                        navigate={navigate}
                                        setSelectedAnyMediaFile={setSelectedAnyMediaFile}
                                        selectedAnyMediaFile={selectedAnyMediaFile}
                                        setSelectedAnyMediaFileDetails={setSelectedAnyMediaFileDetails}
                                        selectedAnyMediaFileDetails={selectedAnyMediaFileDetails}
                                    />

                                    <AddMedia
                                        openFileUploadModel={openMediaModelFor === "normal" ? openFileUploadModelMedia : openFileUploadModelMediaAtt}
                                        isEdit={openMediaModelFor === "normal" ? isEditMedia : isEditMediaAtt}
                                        handleCloseForMediaModel={openMediaModelFor === "normal" ? handleCloseForMediaModel : handleCloseForMediaModelAtt}
                                        getRootProps={getRootProps}
                                        selectedFile={openMediaModelFor === "normal" ? selectedFileMedia : selectedFileMediaAtt}
                                        getInputProps={getInputProps}
                                        renderPreview={openMediaModelFor === "normal" ? renderPreview : renderPreviewAtt}
                                        valueForMediaTab={valueForMediaTab}
                                        handleChangeForMedia={handleChangeForMedia}
                                        mediaTitle={openMediaModelFor === "normal" ? mediaTitle : mediaTitleAtt}
                                        setMediaTitle={openMediaModelFor === "normal" ? setMediaTitle : setMediaTitleAtt}
                                        mediaAltText={openMediaModelFor === "normal" ? mediaAltText : mediaAltTextAtt}
                                        setMediaAltText={openMediaModelFor === "normal" ? setMediaAltText : setMediaAltTextAtt}
                                        mediaDescription={openMediaModelFor === "normal" ? mediaDescription : mediaDescriptionAtt}
                                        setMediaDescription={openMediaModelFor === "normal" ? setMediaDescription : setMediaDescriptionAtt}
                                        mediaDirectUrl={openMediaModelFor === "normal" ? mediaDirectUrl : mediaDirectUrlAtt}
                                        setMediaDirectUrl={openMediaModelFor === "normal" ? setMediaDirectUrl : setMediaDirectUrlAtt}
                                        loggedUser={loggedUser}
                                        isSystemTypeMedia={openMediaModelFor === "normal" ? isSystemTypeMedia : isSystemTypeMediaAtt}
                                        isParentFolderSystemType={openMediaModelFor === "normal" ? isParentFolderSystemType : isParentFolderSystemTypeAtt}
                                        permissionsForMedia={permissionsForMedia}
                                        setPermissionsForMedia={setPermissionsForMedia}
                                        isAdvancePermissionForMedia={isAdvancePermissionForMedia}
                                        setIsAdvancePermissionForMedia={setIsAdvancePermissionForMedia}
                                        columns_atttributes_headers={columns_atttributes_headers}
                                        columns_advace_atttributes_headers={columns_advace_atttributes_headers}
                                        responseForMedia={openMediaModelFor === "normal" ? responseForMedia : responseForMediaAtt}
                                        storeMedia={openMediaModelFor === "normal" ? storeMedia : storeMediaAtt}
                                        showSystemMediaCheckbox={false}
                                    />

                                    <Dialog
                                        aria-labelledby="customized-dialog-title"
                                        open={openMediaModelFor === "normal" ? openRnameMediaModel : openRnameMediaModelAtt}
                                        maxWidth="sm"
                                        fullWidth={true}
                                        className="media-dialog-2"
                                    >
                                        <DialogTitle
                                            sx={{ m: 0, p: 2 }}
                                            id="customized-dialog-title"
                                            className="dialog-title"
                                        >
                                            Rename Media
                                        </DialogTitle>
                                        <IconButton
                                            aria-label="close"
                                            onClick={openMediaModelFor === "normal" ? handleCloseForMediaModel : handleCloseForMediaModelAtt}
                                            sx={{
                                                position: "absolute",
                                                right: 8,
                                                top: 8,
                                                color: (theme) => theme.palette.grey[500],
                                            }}
                                            className="alert-close-btn"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <DialogContent dividers className="media-preview">
                                            <Box className=" main-app-icon-div-4 d-flex flex-wrap">
                                                <Box className="media-info-div-2">
                                                    <Paper sx={{ width: "100%" }} className="type-list-paper-2">
                                                        <Typography className="fs-14">Title</Typography>
                                                        <TextField
                                                            className="input-field mt-2"
                                                            fullWidth
                                                            id="outlined-basic short summary"
                                                            variant="outlined"
                                                            placeholder={`write media title`}
                                                            value={openMediaModelFor === "normal" ? mediaTitle : mediaTitleAtt}
                                                            onChange={(event) => {
                                                                if (openMediaModelFor === "normal") {
                                                                    setMediaTitle(event?.target?.value)
                                                                } else {
                                                                    setMediaTitleAtt(event?.target?.value)
                                                                }
                                                            }}
                                                        />

                                                    </Paper>
                                                </Box>
                                            </Box>
                                        </DialogContent>
                                        <DialogActions className="d-flex flex-wrap justify-content-between gap-2 p-3 z-0 action-btn-model">
                                            <Button
                                                className="btn delete-btn-model"
                                                onClick={openMediaModelFor === "normal" ? handleCloseForMediaModel : handleCloseForMediaModelAtt}
                                            >
                                                <FontAwesomeIcon icon={faXmark} />
                                                Cancel
                                            </Button>
                                            <Button
                                                className="btn primary-btn"
                                                onClick={openMediaModelFor === "normal" ? renameMedia : renameMediaAtt}
                                            >
                                                <FontAwesomeIcon icon={faFloppyDisk} />
                                                Save Changes
                                            </Button>
                                        </DialogActions>
                                    </Dialog>

                                    <AddAttributeMpdel
                                        openAddAttribute={openAddAttribute}
                                        handleCloseForAddAttributeModel={handleCloseForAddAttributeModel}
                                        setIsTypingSearchAtt={setIsTypingSearchAtt}
                                        searchAtt={searchAtt}
                                        setSearchAtt={setSearchAtt}
                                        fetchAllAttributes={fetchAllAttributes}
                                        isTypingSearchAtt={isTypingSearchAtt}
                                        value={value}
                                        handleChange={handleChange}
                                        getAllAttributeSections={getAllAttributeSections}
                                        a11yProps={a11yProps}
                                        getAllAttributes={getAllAttributes}
                                        handleSelectAttribute={handleSelectAttribute}
                                        attributesData={attributesData}
                                        CustomTabPanel={CustomTabPanel}
                                    />

                                    <Dialog
                                        aria-labelledby="customized-dialog-title"
                                        open={openDeleteAlert}
                                        maxWidth="sm"
                                        fullWidth={true}
                                    >
                                        <DialogTitle
                                            sx={{ m: 0, p: 2 }}
                                            id="customized-dialog-title"
                                            className="dialog-title"
                                        >
                                            Are you sure?
                                        </DialogTitle>
                                        <IconButton
                                            aria-label="close"
                                            onClick={handleCloseDeleteAlertModel}
                                            sx={{
                                                position: "absolute",
                                                right: 8,
                                                top: 8,
                                                color: (theme) => theme.palette.grey[500],
                                            }}
                                            className="alert-close-btn"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <DialogContent dividers className="">
                                            {" "}
                                            Are you sure want to delete post?
                                        </DialogContent>
                                        <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
                                            <Button
                                                className="btn delete-btn-model"
                                                onClick={handleCloseDeleteAlertModel}
                                            >
                                                <FontAwesomeIcon icon={faXmark} />
                                                Cancel
                                            </Button>
                                            <Button
                                                className="btn primary-btn"
                                                onClick={() => {
                                                    deleteTypePostAPI()
                                                }
                                                }
                                            >
                                                <FontAwesomeIcon icon={faFloppyDisk} />
                                                Confirm
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Form>
                            );
                        }}
                    </Formik>

                    {/* START :: PAGE TITLE WRAPPER */}
                </Container>
            </div>

            <Loader isLoading={loading} />
            {/* <Toaster /> */}

            {/* END :: MAIN CONTENT WRAPPER */}
        </>
    );
};

export default AddNews2;
