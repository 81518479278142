import { Typography } from "@mui/material";

const EllipsisTypography = ({ lines, children, ...props }) => {
  const clampStyle = {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: lines,
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return (
    <Typography style={clampStyle} {...props}>
      {children}
    </Typography>
  );
};

export default EllipsisTypography;
