import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Stack,
    Breadcrumbs,
    Link,
    Divider,
    Paper,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    Avatar,
    TableBody,
    Menu,
    MenuItem,
    Tabs,
    Tab,
    IconButton,
    Typography,
    CircularProgress,
    TableHead,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import api from "../../components/common/commonFunctionForApi";
import logoutHelper from "../../helpers/logoutHelper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Image } from "react-bootstrap";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";
import { Toast } from "../../utils/toast";
import Loader from "../../components/common/loader";
import { Toaster } from "react-hot-toast";
import StatusIcon from "../../components/common/StatusIcon";
import { pageAction } from "../../redux/slices/page/page";
import PaginationCustom from "../../components/common/pagination/paginationCustom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

function CustomTabPanel(props) {
    const { children, value, className, index, errorTab, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
            {...other}
        >
            {value === index && <Box className="tab-box">{children}</Box>}
        </div>
    );
}

const UserPageList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { selectedPageTypeTab, paginationForUser } = useSelector((state) => state?.page);

    const [isWarningMessage, setIsWarningMessage] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");
    const [isSuccessMessage, setIsSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [value, setValue] = useState(selectedPageTypeTab);
    const [loading, setLoading] = useState(false);

    const [allPages, setAllPages] = useState([])

    const ITEM_HEIGHT = 48;

    const [categoryId, setCategoryId] = useState();

    const [anchorElMenu, setAnchorElForMenu] = useState(null);

    const openMenuFoEdit = Boolean(anchorElMenu);

    const user = localStorage.getItem("user");
    const loggedUser = JSON.parse(user);
    const userToken = localStorage.getItem("accessToken");

    //states for paginations
    const [page, setPage] = useState(1);
    const [isDataFetching, setIsDataFetching] = useState(false);
    const [sortTypeForAdmin, setSortTypeForAdmin] = useState({
        name: null,
        updated_at: null,
        created_by: null,
    })

    const handleClick = (event, row) => {
        setAnchorElForMenu(event.currentTarget);
        setCategoryId(row);
    };

    const handleCloseWarningMessage = () => {
        setIsWarningMessage(false);
        setWarningMessage("");
    };

    const handleCloseSuccessMessage = () => {
        setIsSuccessMessage(false);
        setIsSuccessMessage("");
    };

    const handleClose = () => {
        setAnchorElForMenu(null);
    };

    const getStatusValue = (value) => {
        return value === 0 ? "all"
            : value === 1 ? "enable"
                : value === 2 ? "disable"
                    : "deleted";
    };

    const handleChange = (event, newValue) => {
        dispatch(pageAction.handleSelectedTab(newValue));
        setValue(newValue);
        setPage(1);
        setAllPages([]);
        fetchAllPages(getStatusValue(newValue), 1, paginationForUser?.per_page);
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
        dispatch(pageAction.setPagination({
            ...paginationForUser,
            page: newPage
        }))

        fetchAllPages(getStatusValue(value), newPage, paginationForUser?.per_page)
    };

    const handleChangeRowsPerPage = (event) => {

        dispatch(pageAction.setPagination({
            ...paginationForUser,
            per_page: event.target.value,
            page: 1
        }));
        fetchAllPages(getStatusValue(value), page, event.target.value)
    };

    const {
        open,
    } = useSelector((state) => state?.drawerMenu);

    function a11yProps1(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            className:
                value === index
                    ? `selected-tab-attribute`
                    : `non-selected-tab-attribute`,
        };
    }

    const fetchAllPages = async (type, page, per_page) => {
        try {
            setLoading(true)
            let order_by
            const userToken = localStorage.getItem("accessToken");
            const firstNonNull = Object.entries(sortTypeForAdmin).find(([key, value]) => value !== null);
            if (firstNonNull) {
                order_by = firstNonNull[0] + '_' + firstNonNull[1];
            }
            const response = await api.get(
                `user/page/page-list/${type}?${order_by ? `order_by=${order_by}` : ""}&page=${page ?? 1}&per_page=${per_page}`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            const newPages = response?.response?.data;
            setAllPages(newPages)
            dispatch(pageAction.setPaginationForUser({
                ...paginationForUser,
                page: response?.response?.meta?.current_page,
                per_page: response?.response?.meta?.per_page,
                meta_data: response?.response?.meta
            }));
        } catch (err) {
            console.log("error in fetch posts api::", err);
            if (err?.response?.status === 401) {
                localStorage.setItem("last-redirect-page", `/users`);
                logoutHelper(dispatch, navigate, userToken);
            }

            if (err?.response?.status === 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
        } finally {
            setIsDataFetching(false); // Reset fetching state after the API call
            setLoading(false);
        }
    };

    const handleSort = (key) => {
        let sortTypeValue = null;
        if (sortTypeForAdmin[key] === null) {
            sortTypeValue = 'asc';
        } else if (sortTypeForAdmin[key] === 'asc') {
            sortTypeValue = 'desc';
        } else if (sortTypeForAdmin[key] === 'desc') {
            sortTypeValue = null;
        }
        const data = {
            name: null,
            updated_at: null,
            created_by: null,
            [key]: sortTypeValue
        };
        setSortTypeForAdmin(data)
    }

    useEffect(() => {
        dispatch(pageAction?.setPageProcessing(false))

        fetchAllPages(
            getStatusValue(selectedPageTypeTab), 1, paginationForUser?.per_page
        );
        setValue(selectedPageTypeTab);
    }, []); //run only once after page load

    useEffect(() => {
        fetchAllPages(getStatusValue(value), page, paginationForUser?.per_page)
    }, [sortTypeForAdmin])

    return (
        <>
            <div
                className={`main-content-wrapper-2 ${open === true ? "drawer-open" : "drawer-close"
                    }  ${window.innerWidth <= 768 && "small-screen"}`}
            >
                <Container
                    className={`${window.innerWidth <= 768 && "small-screen-container"}`}
                >
                    {isSuccessMessage && (
                        <SuccessAlert
                            message={successMessage}
                            onClose={handleCloseSuccessMessage}
                        />
                    )}

                    {isWarningMessage && (
                        <WarningAlert
                            message={warningMessage}
                            onClose={handleCloseWarningMessage}
                        />
                    )}
                    <div className="main-div-2-types">
                        <Box className="">
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    <Link
                                        underline="hover"
                                        key="1"
                                        color="inherit"
                                        href="/"
                                        component="h3"
                                        className="cursor-pointer"
                                    >
                                        User
                                    </Link>
                                    <Typography key="3" component="h3" color="text.primary">
                                        Pages
                                    </Typography>
                                </Breadcrumbs>
                            </Stack>
                        </Box>
                        <div className="d-flex flex-wrap gap-3">
                        </div>
                    </div>
                    <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
                    <Box
                        sx={{
                            width: "100%",
                        }}
                    >
                        <Box
                            sx={{ borderBottom: 1, borderColor: "divider" }}
                            className="box-attribute"
                        >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                                variant="scrollable"
                                className={"tabs type-post-tab"}
                                TabIndicatorProps={{
                                    style: {
                                        backgroundColor: "#660C60",
                                    },
                                }}
                            >
                                <Tab
                                    label="All"
                                    iconPosition="start"
                                    {...a11yProps1(0)}
                                />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={value}>
                            <Paper sx={{ width: "100%" }} className="type-list-paper">
                                <TableContainer component={Paper} className="type-list-container">
                                    <Table
                                        aria-labelledby="tableTitle"
                                        stickyHeader
                                    >
                                        {allPages?.length > 0 && (
                                            <>
                                                <TableHead className='type-list-header'>
                                                    <TableRow>
                                                        <TableCell className='type-list-header-data cursor-pointer type-list-first-header'
                                                            onClick={
                                                                () => {
                                                                    handleSort('name');
                                                                }
                                                            }
                                                        >Name
                                                            {(sortTypeForAdmin?.name && sortTypeForAdmin?.name === 'asc') ? <ExpandLess className="sort-icon" /> : ((sortTypeForAdmin?.name && sortTypeForAdmin?.name === 'desc') ? <ExpandMore className="sort-icon" /> : <ExpandLess className="sort-icon text-muted opacity-50" />)}
                                                        </TableCell>
                                                        <TableCell className='type-list-header-data type-list-second-header-att-post' align="left">Date Created</TableCell>
                                                        <TableCell className='type-list-header-data type-list-third-header' align="left">Creator</TableCell>
                                                        <TableCell className='type-list-header-data type-list-last-header-att-post' align="right">Status</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableRow style={{ height: '10px', backgroundColor: 'transparent' }} />
                                                <TableBody className='type-list-body'>
                                                    {allPages?.map((row, index) => (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={index + 1}
                                                            className='type-list-body-row'
                                                        >
                                                            <TableCell
                                                                align="left"
                                                                className={`tbl-body-cell tbl-border-left ${row?.status === "deleted" && "text-muted"
                                                                    }`}
                                                            >
                                                                <div
                                                                    className="d-flex flex-warp gap-3 align-items-center"
                                                                    style={{ fontSize: "14px" }}
                                                                >

                                                                    <Typography className={`type-list-title ${row?.status === "deleted" &&
                                                                        "text-muted fw-700"
                                                                        }`} variant="h4" component="div">
                                                                        {row?.title}
                                                                    </Typography>
                                                                </div>
                                                            </TableCell>

                                                            <TableCell
                                                                align="left"
                                                                className='type-list-text'
                                                            >
                                                                {
                                                                    row?.date_created
                                                                }
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className="tbl-body-cell-3 "
                                                            >
                                                                <Box>
                                                                    <Stack
                                                                        direction="row"
                                                                        alignContent="center"
                                                                        alignItems="center"
                                                                        spacing={1}
                                                                        className="z-n1"
                                                                    >
                                                                        <Avatar
                                                                            src={row?.created_by?.thumbnail}
                                                                            className="type-list-created-avatar"
                                                                            sx={{ fontSize: "14px" }}
                                                                        >
                                                                            {row?.created_by?.full_name?.charAt(0)}
                                                                        </Avatar>
                                                                        <Typography className="fw-400 fs-14">
                                                                            {row?.created_by?.full_name}
                                                                        </Typography>
                                                                    </Stack>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell
                                                                align="center"
                                                                className="tbl-body-cell-2 tbl-border-right"
                                                            >
                                                                <div>
                                                                    <Stack
                                                                        direction="row"
                                                                        alignContent="center"
                                                                        alignItems="center"
                                                                        spacing={1}
                                                                        className="gap-1"
                                                                    >
                                                                        <StatusIcon status={row?.status} />
                                                                        <IconButton
                                                                            aria-label="more"
                                                                            id="long-button"
                                                                            aria-haspopup="true"
                                                                            onClick={(e) => handleClick(e, row)}
                                                                            className="status-icon"
                                                                        >
                                                                            <MoreVertIcon />
                                                                        </IconButton>
                                                                    </Stack>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </>
                                        )}
                                    </Table>
                                </TableContainer>
                                {allPages?.length > 0 && (
                                    <>
                                        <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
                                        <PaginationCustom
                                            per_page={paginationForUser?.per_page}
                                            per_page_options={[10, 20, 50, 100]}
                                            from={paginationForUser?.meta_data?.from}
                                            to={paginationForUser?.meta_data?.to}
                                            last_page={paginationForUser?.meta_data?.last_page}
                                            total={paginationForUser?.meta_data?.total}
                                            page={paginationForUser?.page}
                                            handleChangePage={handleChangePage}
                                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </>
                                )}
                                {isDataFetching && (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: 2
                                        }}
                                    >
                                        <CircularProgress sx={{ color: "#660C60" }} />
                                    </Box>
                                )}
                            </Paper>
                            {allPages?.length < 1 && (
                                <div className="no-data mt-5">
                                    <div>
                                        <Image
                                            src={NoDataFoundImg}
                                            className="text-center mx-auto no-data-img mt-4"
                                        />
                                    </div>
                                    <Typography component="h5" variant="h5">
                                        No data found
                                    </Typography>
                                </div>
                            )}
                        </CustomTabPanel>
                    </Box>
                </Container>

                <Menu
                    anchorEl={anchorElMenu}
                    id="long-menu"
                    MenuListProps={{
                        "aria-labelledby": "long-button",
                    }}
                    open={openMenuFoEdit}
                    anchorOrigin={"right"}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "20ch",
                            zIndex: "1",
                        },
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            localStorage.setItem(
                                "last-redirect-page",
                                `/page/${categoryId?.slug}`
                            );
                            navigate(`/page/${categoryId?.slug}`);
                        }}
                    >
                        View
                    </MenuItem>
                </Menu>
                <Loader isLoading={loading} />
                <Toaster />
            </div>
        </>
    )
}

export default UserPageList