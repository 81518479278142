import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pagination: {
        page: 1,
        per_page: 12,
        meta_data: null
    }
}

const mediaSlice = createSlice({
    name: "media",
    initialState,
    reducers: {
        setPagination(state, action) {
            state.pagination = action.payload;
        },
    }
})

export const mediaSliceAction = mediaSlice.actions;

export default mediaSlice.reducer;