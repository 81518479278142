import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  Divider,
  TextField,
  Tabs,
  Tab,
  Paper,
  Stack,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Grid,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import "../../styles/attribute.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faCircleInfo,
  faGripVertical,
  faSearch,
  faFileCsv,
  faTrash,
  faCheck,
  faArrowRight,
  faXmark,
  faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import AddIcon from "@mui/icons-material/Add";
import TableHeaderForSorting from "../../components/common/TableHeaderForSorting";
import { Form, Formik, ErrorMessage } from "formik";
import { addNewAttributesvalidationSchema } from "../../helpers/validations/addAttributes";
import DeleteIcon from "@mui/icons-material/Delete";
import logoutHelper from "../../helpers/logoutHelper";
import api from "../../components/common/commonFunctionForApi";
import Loader from "../../components/common/loader";
import { useParams } from "react-router-dom";
import { Toast } from "../../utils/toast";
import Checkbox from "@mui/material/Checkbox";
import { Toaster } from "react-hot-toast";
import InputGroup from "react-bootstrap/InputGroup";
import TablePagination from "@mui/material/TablePagination";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { attributeTabAction } from "../../redux/slices/attribute/attribute";
import { columns_attribute_changelog_header } from "../../helpers/theaderColumns/attributes/attributeChangelog";
import { AntSwitch } from "../../components/common/switch/antSwitch";
import { BootstrapTooltip } from "../../components/common/tooltip/bootstrapTooltip";
import { columns_attribute_select_fields_header } from "../../helpers/theaderColumns/attributes/attributeFields";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import { dateWithTime } from "../../helpers/DateTimeFormat/dateTimeFormat";
import { exportXlsx } from "../../helpers/Exports/FileExport";
import { MuiColorInput } from "mui-color-input";
import CloseIcon from "@mui/icons-material/Close";

function CustomTabPanel(props) {
  const { children, value, className, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const processedHTML = (htmlContent) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent || ""; // Ensure there's a default value if htmlContent is undefined
  return tempDiv;
};

const AddAttribute = () => {
  let { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElForEnable, setAnchorElForEnable] = useState(null);

  const [loading, setLoading] = useState(false);
  const [getAllAttributeSections, setGetAllAttributeSections] = useState([]);
  const [getAllFormElements, setGetAllFormElements] = useState([]);
  const [getAttributeDetails, setGetAttributeDetails] = useState();
  const [visibleAddOption, setVisibleAddOption] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getAllChangeLogs, setGetAllChangeLogs] = useState();
  const [search, setSearch] = useState("");
  const [istype, setIstype] = useState(false);
  const [isWarningMessage, setIsWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [getAllIcons, setGetAllIcons] = useState([]);
  const [openDeleteTypeAlert, setOpenDeleteTypeAlert] = useState(false)

  const debounceTimeout = 500; // Adjust the debounce timeout as needed
  let debounceTimer;

  const total_changelog = parseInt(getAllChangeLogs?.meta?.total);

  const userToken = localStorage.getItem("accessToken");

  const initialValues = {
    attributeTitle: id ? getAttributeDetails?.attributeTitle : "",
    attributeDescription: id ? getAttributeDetails?.description : "",
    tabShowUnder: id ? getAttributeDetails?.tabShowUnder : "",
    attributeRepeatable: id ? getAttributeDetails?.attributeRepeatable : false,
    fieldType: id ? getAttributeDetails?.fieldType : "",
    rowsData: id
      ? getAttributeDetails?.rowsData?.length > 0
        ? getAttributeDetails?.rowsData
        : [{ id: 1, key: "", value: "", default: true }]
      : [{ id: 1, key: "", value: "", default: true }],

    lastId: id ? getAttributeDetails?.lastId : 1,
    attributeStatus: id ? getAttributeDetails?.attributeStatus : true,
    fieldMandatory: id ? getAttributeDetails?.fieldMandatory : false,
    showOnUserPage: id ? getAttributeDetails?.showOnUserPage : false,
    iconName: id ? getAttributeDetails?.iconName : "",
    iconColor: id ? getAttributeDetails?.iconColor : "#959595",
    iconBGColor: id ? getAttributeDetails?.iconBGColor : "#660C60",
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setPage(newPage);
      fetchAllChangeLogs(newPage + 1);
    } else if (newPage < page) {
      setPage(newPage);
      fetchAllChangeLogs(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openInfo = Boolean(anchorEl);
  const openInfoEnable = Boolean(anchorElForEnable);

  const handleChangeForTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseWarningMessage = () => {
    setIsWarningMessage(false);
    setWarningMessage("");
  };

  const handleCloseSuccessMessage = () => {
    setIsSuccessMessage(false);
    setIsSuccessMessage("");
  };

  const handleCloseDeleteTypeModel = () => {
    setOpenDeleteTypeAlert(false)
  }

  const { open } = useSelector((state) => state?.drawerMenu);

  function a11yProps1(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value === index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }

  const onSubmit = async (values) => {
    if (values?.attributeRepeatable && values?.fieldType === "image") {
      Toast.error("Image field type is not supported for repeatable attribute");
      return false;
    } else if (values?.attributeRepeatable && values?.fieldType === "file") {
      Toast.error(
        "File upload field type is not supported for repeatable attribute"
      );
      return false;
    } else {
      let dataForSelection = {
        id: id && parseInt(id),
        title: values?.attributeTitle,
        description: values?.attributeDescription,
        section_id: values?.tabShowUnder,
        is_repeatable: values?.attributeRepeatable ? 1 : 0,
        element_key: values?.fieldType,
        attribute_options:
          values?.fieldType === "radio_single_choice" ||
            values?.fieldType === "checkbox_single_choice" ||
            values?.fieldType === "dropdodown_single_choice" ||
            values?.fieldType === "checkbox_multiple_choice" ||
            values?.fieldType === "dropdodown_multiple_choice"
            ? {
              options: values?.rowsData?.map((row) => {
                return {
                  option_id: row?.id,
                  option_name: row?.key,
                  option_value: row?.value,
                  default: row?.default,
                };
              }),
            }
            : {
              options: [],
            },
        is_mandatory: values?.fieldMandatory ? 1 : 0,
        status: values?.attributeStatus ? "enable" : "disable",
        show_userpage: values?.showOnUserPage ? 1 : 0,
        icon_id: values?.iconName,
        color: values?.iconColor,
        background_color: values?.iconBGColor,
      };
      if (id) {
        try {
          setLoading(true);
          const response = await api.post(
            `admin/attribute/update/${id}`,
            dataForSelection,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
          if (response?.success) {
            Toast.success(response?.message);
            setIsSuccessMessage(true);
            setSuccessMessage(response?.message);
            setLoading(false);
            localStorage.setItem("last-redirect-page", `/attribute`);
            navigate("/attribute");
          } else if (!response?.success) {
            setLoading(false);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("err`or in fetch news api::", err);
          if (err?.response?.status === 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status === 422) {
            setLoading(false);
            setIsWarningMessage(true);
            setWarningMessage(err?.response?.data?.errors?.title);
          }
          if (err?.response?.status === 429) {
            Toast.error("Too many attempts wait for 2 min.");
          }
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);
          const response = await api.post(
            `admin/attribute/store`,
            dataForSelection,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
          setLoading(false);
          if (response?.success) {
            setLoading(false);
            setIsSuccessMessage(true);
            setSuccessMessage(response?.message);
            Toast.success(response?.message);
            localStorage.setItem("last-redirect-page", `/attribute`);
            dispatch(attributeTabAction.handleSelectedTab(0));
            navigate("/attribute");
          } else if (!response?.success) {
            setLoading(false);
            setIsWarningMessage(true);
            setWarningMessage(response?.message);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("err`or in fetch news api::", err);
          if (err?.response?.status === 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status === 422) {
            setLoading(false);
            setIsWarningMessage(true);
            setWarningMessage(err?.response?.data?.errors?.title);
            Object.values(err?.response?.data?.errors).forEach((value) =>
              Toast.error(value)
            );
          }
          if (err?.response?.status === 429) {
            Toast.error("Too many attempts wait for 2 min.");
          }
          setLoading(false);
        }
      }
    }
  };

  const fetchAttributeSection = async () => {
    try {
      setLoading(true);
      const response = await api.get(`admin/attribute-section`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setGetAllAttributeSections(response?.response);
      setLoading(false);
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.");
      }
      setLoading(false);
    }
  };

  const fetchformElements = async () => {
    try {
      setLoading(true);
      const response = await api.get(`admin/form-element`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      let types = response?.response?.map((type) => ({
        value: type?.key,
        label: type?.label,
      }));
      setGetAllFormElements(types);
      setLoading(false);
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.");
      }
      setLoading(false);
    }
  };

  const fetchAttributesDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(`admin/attribute/show/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success) {
        let attribute = {
          attributeTitle: response?.response?.title,
          description: response?.response?.description,
          tabShowUnder: response?.response?.section_id,
          attributeRepeatable:
            response?.response?.is_repeatable == 0 ? false : true,
          fieldType: response?.response?.element_key,
          rowsData: response?.response?.attribute_options?.options?.map(
            (option) => {
              return {
                id: option?.option_id,
                key: option?.option_name,
                value: option?.option_value,
                default: option?.default,
              };
            }
          ),
          lastId: response?.response?.attribute_options?.options?.length,
          attributeStatus:
            response?.response?.status === "enable" ? true : false,
          fieldMandatory: response?.response?.is_mandatory == 0 ? false : true,
          showOnUserPage: response?.response?.show_userpage == 0 ? false : true,
          iconName: response?.response?.icon_id,
          iconColor: response?.response?.color,
          iconBGColor: response?.response?.background_color,
        };
        setGetAttributeDetails(attribute);
        setLoading(false);
      } else if (!response?.success) {
        setLoading(false);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/`);
        navigate("/");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);
        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        setWarningMessage(err?.response?.data?.message);
        Toast.warning(err?.response?.data?.message);
        localStorage.setItem("last-redirect-page", `/attribute`);
        navigate("/attribute");
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.");
      }
    }
  };

  const fetchAllIcons = async () => {
    try {
      setLoading(true);
      const response = await api.get(`admin/icon-list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success) {
        setGetAllIcons(response?.response);
        setLoading(false);
      } else if (!response?.success) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/`);
        navigate("/");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);
        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/`);
        navigate("/");
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.");
      }
    }
  };

  const fetchAllChangeLogs = async (page) => {
    try {
      setLoading(true);
      const response = await api.get(
        `admin/attribute/changelog/${id}?per_page=${rowsPerPage}&page=${page}&search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success) {
        response.response.data = response?.response?.data.map((log) => ({
          ...log,
          changed_at:
            dateWithTime(log.changed_at, true, true, true) || log.changed_at,
        }));
        setGetAllChangeLogs(response?.response);

        setLoading(false);
      } else if (!response?.success) {
        setLoading(false);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/`);
        navigate("/");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);
        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/`);
        navigate("/");
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.");
      }
    }
  };

  const fetchAllChangeLogsForExportCSV = async (pagination) => {
    try {
      setLoading(true);
      const result = await exportXlsx(
        `admin/attribute/changelog/${id}?per_page=${rowsPerPage}&page=${page}&search=${search}&pagination=no`,
        "Attribute_change_log"
      );
      if (!result) {
        Toast.error("Failed to download file");
      }
    } catch (err) {
      console.error("Error while downloading csv changelog::", err);
      Toast.error("Failed to download file");
    } finally {
      setLoading(false);
    }
  };

  const deleteAttributeAPI = async () => {
    let data = {
      id: id,
    };
    try {
      setLoading(true);
      const response = await api.post(`admin/attribute/delete `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        setLoading(false);
        setOpenDeleteTypeAlert(false)
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        navigate("/attribute")
      }
      else if (response?.success === false) {
        setLoading(false)
        Toast.warning(response?.message)
        setIsWarningMessage(true)
        setWarningMessage(response?.message)
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    const apiCall = async () => {
      if (id) {
        await fetchAttributesDetails();
        await fetchAttributeSection();
        await fetchformElements();
        await fetchAllChangeLogs(page + 1);
        await fetchAllIcons();
      } else {
        await fetchAttributeSection();
        await fetchformElements();
        await fetchAllIcons();
      }
    };
    apiCall();
  }, [id]);

  useEffect(() => {
    if (search.length >= 3) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        setPage(0);
        fetchAllChangeLogs(page);
      }, debounceTimeout);
    } else if (search === "") {
      setPage(0);
      fetchAllChangeLogs(page);
    } else {
      clearTimeout(debounceTimer);
    }

    return () => clearTimeout(debounceTimer);
  }, [search]);

  return (
    <>
      <div
        className={`main-content-wrapper-2 ${open === true ? "drawer-open" : "drawer-close"
          } ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          {isSuccessMessage && (
            <SuccessAlert
              message={successMessage}
              onClose={handleCloseSuccessMessage}
            />
          )}

          {isWarningMessage && (
            <WarningAlert
              message={warningMessage}
              onClose={handleCloseWarningMessage}
            />
          )}
          <Box className="">
            <Stack spacing={2}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Link
                  underline="hover"
                  key="1"
                  color="inherit"
                  href="/"
                  component="h3"
                >
                  Admin
                </Link>
                <Typography
                  key="3"
                  component="h3"
                  color="inherit"
                  className="cursor-pointer"
                  onClick={() => {
                    localStorage.setItem("last-redirect-page", `/attribute`);

                    navigate("/attribute");
                  }}
                >
                  Attributes
                </Typography>
                <Typography key="3" component="h3" color="text.primary">
                  {id ? "Edit" : "Add"} Attribute
                </Typography>
              </Breadcrumbs>
            </Stack>
          </Box>
          <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
          <Formik
            enableReinitialize
            validateOnChange
            initialValues={initialValues}
            validationSchema={addNewAttributesvalidationSchema}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {({
              values,
              setFieldValue,
              touched,
              errors,
              handleBlur,
              handleChange,
            }) => {
              if (values?.fieldType === "checkbox_single_choice") {
                if (values?.rowsData?.length > 0) {
                  setVisibleAddOption(false);
                } else {
                  setVisibleAddOption(true);
                }
              } else {
                setVisibleAddOption(true);
              }

              const handleAddOption = () => {
                if (values?.fieldType === "checkbox_single_choice") {
                  if (values?.rowsData?.length < 2) {
                    const newId = values?.lastId + 1;
                    const newRow = {
                      id: newId,
                      key: "",
                      value: "",
                      default: false,
                    };
                    setFieldValue("rowsData", [...values?.rowsData, newRow]);
                    setFieldValue("lastId", newId);
                  }
                }
                const newId = values?.lastId + 1;
                const newRow = {
                  id: newId,
                  key: "",
                  value: "",
                  default: false,
                };
                setFieldValue("rowsData", [...values?.rowsData, newRow]);
                setFieldValue("lastId", newId);
              };

              if (id && values?.rowsData?.length < 1) {
                handleAddOption();
              }
              const handleRowDataChange = (id, field, value) => {
                let updatedRowsData;
                if (field === "default") {
                  updatedRowsData = values?.rowsData.map((row) =>
                    row.id === id
                      ? { ...row, default: true }
                      : { ...row, default: false }
                  );
                } else {
                  updatedRowsData = values?.rowsData.map((row) =>
                    row.id === id ? { ...row, [field]: value } : row
                  );
                }
                setFieldValue("rowsData", updatedRowsData);
              };

              const handleDeleteRow = (idToDelete) => {
                const updatedRowsData = values.rowsData.filter(
                  (row) => row.id !== idToDelete
                );
                setFieldValue("rowsData", updatedRowsData);
              };

              const handleDragEnd = (result) => {
                if (!result.destination) {
                  return;
                }

                const updatedRowsData = Array.from(values.rowsData);
                const [removed] = updatedRowsData.splice(
                  result.source.index,
                  1
                );
                updatedRowsData.splice(result.destination.index, 0, removed);

                setFieldValue("rowsData", updatedRowsData);
              };

              const handleChangeForIconColor = (newValue) => {
                setFieldValue("iconColor", newValue);
              };

              const handleChangeForIconBGColor = (newValue) => {
                setFieldValue("iconBGColor", newValue);
              };

              return (
                <Form>
                  <Row className="mt-1">
                    <Box className="publish-bar-box">
                      <Box className="delete-div-box">

                        {id && (
                          <Box className="d-flex gap-2 align-items-center justify-content-center cursor-pointer" onClick={() => {
                            setOpenDeleteTypeAlert(true);
                          }}>
                            <FontAwesomeIcon icon={faTrash} className="delete-div-box-icon" />
                            <Typography className="delete-div-box-text">Delete</Typography>
                          </Box>
                        )}

                      </Box>
                      <Box className="last-div-box">
                        <Box className="publish-btn-div-box">
                          <Button
                            className="btn primary-btn w-100"
                            type="submit"
                            onClick={() => {
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="d-md-block d-sm-none d-none"
                            />
                            <FontAwesomeIcon
                              icon={faArrowRight}
                              className="d-md-none d-sm-block"
                            />
                            Publish
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                    <Col xs={12} md={{ span: 8, offset: 2 }}>
                      <Accordion className="w-100 d-flex gap-4 flex-column">
                        <Accordion.Item
                          eventKey="0"
                          className="bg-white card-wrapper"
                        >
                          <Accordion.Header className="bg-white fw-700 primary-text">
                            Content
                          </Accordion.Header>
                          <Accordion.Body>
                            <Row>
                              <Col lg="12">
                                <Typography className="fw-400 dark-text font-inter">
                                  Attribute Title
                                  <span className="required">*</span>
                                </Typography>
                                <TextField
                                  className="input-field mt-2"
                                  fullWidth
                                  id="outlined-basic username"
                                  variant="outlined"
                                  size="small"
                                  name="attributeTitle"
                                  placeholder="Enter attribute title here..."
                                  value={values?.attributeTitle}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "attributeTitle",
                                      event.target.value
                                    );
                                  }}
                                  helperText={
                                    touched.attributeTitle &&
                                    errors.attributeTitle
                                  }
                                  error={
                                    touched.attributeTitle &&
                                    Boolean(errors.attributeTitle)
                                  }
                                />
                              </Col>
                              <Col lg="12">
                                <Typography className="fw-400 dark-text font-inter">
                                  Attribute Description
                                  <span className="required">*</span>
                                </Typography>
                                <TextField
                                  className="input-field mt-2"
                                  fullWidth
                                  id="outlined-basic username"
                                  variant="outlined"
                                  size="small"
                                  name="attributeDescription"
                                  placeholder="Enter attribute title here..."
                                  value={values?.attributeDescription}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "attributeDescription",
                                      event.target.value
                                    );
                                  }}
                                  helperText={
                                    touched.attributeDescription &&
                                    errors.attributeDescription
                                  }
                                  error={
                                    touched.attributeDescription &&
                                    Boolean(errors.attributeDescription)
                                  }
                                />
                              </Col>
                              <Col lg="12">
                                <Typography className="fw-400 dark-text font-inter">
                                  Tab to Show Under
                                  <span className="required">*</span>
                                </Typography>

                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="tabShowUnder"
                                  className="mt-1"
                                  value={values?.tabShowUnder}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  {getAllAttributeSections?.map((attribute) => (
                                    <FormControlLabel
                                      key={attribute.id}
                                      value={attribute.id}
                                      control={
                                        <Radio
                                          size="small"
                                          checked={
                                            values?.tabShowUnder ==
                                            attribute?.id
                                          }
                                          value={attribute?.id}
                                        />
                                      }
                                      label={attribute?.name}
                                      className="font-inter"
                                    />
                                  ))}
                                </RadioGroup>
                                {errors?.tabShowUnder &&
                                  touched.tabShowUnder && (
                                    <p
                                      style={{ color: "#d32f2f" }}
                                      className="error-msg"
                                    >
                                      {errors?.tabShowUnder}
                                    </p>
                                  )}
                              </Col>
                              <Col lg="12" className="m-0">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values?.showOnUserPage === true}
                                      name="showOnUserPage"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  }
                                  label="Show on User page"
                                  className="font-inter"
                                />
                                {errors?.showOnUserPage &&
                                  touched.showOnUserPage && (
                                    <p
                                      style={{ color: "#d32f2f" }}
                                      className="error-msg"
                                    >
                                      {errors?.showOnUserPage}
                                    </p>
                                  )}
                              </Col>
                              <Col lg="12" className="m-0">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        values?.attributeRepeatable === true
                                      }
                                      name="attributeRepeatable"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  }
                                  label="This attribute is repeatable"
                                  className="font-inter"
                                />
                                {errors?.attributeRepeatable &&
                                  touched.attributeRepeatable && (
                                    <p
                                      style={{ color: "#d32f2f" }}
                                      className="error-msg"
                                    >
                                      {errors?.attributeRepeatable}
                                    </p>
                                  )}
                              </Col>
                              <Divider sx={{ my: 1 }} />
                              <Col lg="12" className="m-0 py-2">
                                <Stack direction="row" gap={2}>
                                  <div className="d-flex align-items-center gap-2">
                                    <Typography
                                      className="fw-400 dark-text font-inter "
                                      whiteSpace="nowrap"
                                    >
                                      Enable Attribute
                                    </Typography>
                                    <BootstrapTooltip
                                      title="You can enable/disable attribute."
                                      placement="bottom"
                                    >
                                      <FontAwesomeIcon
                                        icon={faCircleInfo}
                                        className="mandatory-icon fw-900"
                                        aria-owns={
                                          openInfoEnable
                                            ? "mouse-over-popover"
                                            : undefined
                                        }
                                        aria-haspopup="true"
                                      />
                                    </BootstrapTooltip>
                                  </div>
                                  <AntSwitch
                                    name="attributeStatus"
                                    checked={values?.attributeStatus}
                                    onChange={(event) => {
                                      setFieldValue(
                                        "attributeStatus",
                                        event.target.checked
                                      );
                                    }}
                                    inputProps={{
                                      "aria-label": "ant design",
                                    }}
                                  />
                                </Stack>
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                          <Accordion.Header className="bg-white fw-700 primary-text">
                            Customization
                          </Accordion.Header>
                          <Accordion.Body>
                            <Stack gap={2}>
                              <Stack>
                                <Typography component="p" className="fs-14">
                                  Icon
                                </Typography>
                                <Stack
                                  direction="row"
                                  className="d-flex flex-wrap gap-1 mt-2"
                                >
                                  {getAllIcons?.map((icon, index) => (
                                    <div
                                      className={`icon-div d-flex justify-content-center align-items-center icons-div ${values?.iconName === icon?.id &&
                                        "selected-icon"
                                        }`}
                                      key={index}
                                      onClick={() => {
                                        setFieldValue("iconName", icon?.id);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={icon?.icon_value}
                                        className="icon"
                                      />
                                    </div>
                                  ))}
                                  {errors?.iconName && touched.iconName && (
                                    <p
                                      style={{ color: "#d32f2f" }}
                                      className="error-msg"
                                    >
                                      {errors?.iconName}
                                    </p>
                                  )}
                                </Stack>
                              </Stack>
                              <Stack
                                gap={2}
                                direction={{ xs: "column", md: "row" }}
                              >
                                <Box component={"div"}>
                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    gap={2}
                                  >
                                    <Typography className="fw-400 dark-text font-inter">
                                      Color
                                    </Typography>
                                    <MuiColorInput
                                      format="hex"
                                      value={values?.iconColor}
                                      onChange={handleChangeForIconColor}
                                      className="icon-color-picker"
                                      fullWidth
                                      id="outlined-basic username"
                                      variant="outlined"
                                      size="small"
                                      name="iconColor"
                                    />
                                  </Stack>
                                  {errors?.iconColor && touched.iconColor && (
                                    <p
                                      style={{ color: "#d32f2f" }}
                                      className="error-msg"
                                    >
                                      {errors?.iconColor}
                                    </p>
                                  )}
                                </Box>
                                <Box component={"div"}>
                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    gap={2}
                                  >
                                    <Typography className="fw-400 dark-text font-inter">
                                      Background
                                    </Typography>
                                    <MuiColorInput
                                      format="hex"
                                      value={values?.iconBGColor}
                                      onChange={handleChangeForIconBGColor}
                                      className="icon-color-picker2"
                                      fullWidth
                                      id="outlined-basic username"
                                      variant="outlined"
                                      size="small"
                                      name="iconBGColor"
                                    />
                                  </Stack>
                                  {errors?.iconBGColor &&
                                    touched.iconBGColor && (
                                      <p
                                        style={{ color: "#d32f2f" }}
                                        className="error-msg"
                                      >
                                        {errors?.iconBGColor}
                                      </p>
                                    )}
                                </Box>
                              </Stack>
                            </Stack>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item
                          eventKey="2"
                          className="bg-white card-wrapper"
                        >
                          <Accordion.Header className="bg-white fw-700 primary-text">
                            Fields & Actions
                          </Accordion.Header>
                          <Accordion.Body className="p-0">
                            <Box
                              sx={{
                                width: "100%",
                              }}
                            >
                              {" "}
                              <Box
                                sx={{
                                  borderBottom: 1,
                                  borderColor: "divider",
                                }}
                                className=""
                              >
                                <Tabs
                                  value={value}
                                  onChange={handleChangeForTab}
                                  aria-label="basic tabs example"
                                  variant="scrollable"
                                  className={"tabs"}
                                  TabIndicatorProps={{
                                    style: {
                                      backgroundColor: "#660C60",
                                    },
                                  }}
                                >
                                  <Tab
                                    icon={<FontAwesomeIcon icon={faGear} />}
                                    label="Field Settings"
                                    iconPosition="start"
                                    {...a11yProps1(0)}
                                  />
                                </Tabs>
                              </Box>
                              <CustomTabPanel value={value} index={value}>
                                <Paper
                                  sx={{ width: "100%" }}
                                  className="type-list-paper"
                                >
                                  <Box className="select-fields-type-wrapper">
                                    <div className="field-wrapper">
                                      <Typography className="fw-400 dark-text select-field-type">
                                        Select Field Type
                                        <span className="required">*</span>
                                      </Typography>

                                      <Select
                                        closeMenuOnSelect={true}
                                        options={getAllFormElements}
                                        menuPlacement="auto"
                                        name="fieldType"
                                        value={getAllFormElements.find(
                                          (option) =>
                                            option.value === values.fieldType
                                        )}
                                        placeholder="Select page type..."
                                        onChange={(selectedOption) => {
                                          setFieldValue(
                                            "fieldType",
                                            selectedOption
                                              ? selectedOption.value
                                              : ""
                                          );
                                        }}
                                        className="muilt-select-field mt-2 z-3 fs-14"
                                        classNamePrefix="select"
                                        styles={{
                                          option: (provided, state) => {
                                            return {
                                              ...provided,
                                              backgroundColor: state.isSelected
                                                ? "#660c605c"
                                                : provided.backgroundColor,
                                              color: state.isSelected
                                                ? "var(--dark-color)"
                                                : provided.color,
                                            };
                                          },
                                        }}
                                      />
                                      {errors?.fieldType &&
                                        touched.fieldType && (
                                          <p
                                            style={{ color: "#d32f2f" }}
                                            className="error-msg"
                                          >
                                            {errors?.fieldType}
                                          </p>
                                        )}
                                    </div>
                                    <div className="d-flex flex-wrap align-items-cente gap-3 pt-4">
                                      <Typography className="fw-400 dark-text font-inter">
                                        Mandatory?
                                      </Typography>
                                      <BootstrapTooltip
                                        title=" This is required field"
                                        placement="right-start"
                                      >
                                        <FontAwesomeIcon
                                          icon={faCircleInfo}
                                          className="mandatory-icon fw-900"
                                          aria-owns={
                                            openInfo
                                              ? "mouse-over-popover"
                                              : undefined
                                          }
                                          aria-haspopup="true"
                                        />
                                      </BootstrapTooltip>

                                      <Stack
                                        direction="row"
                                        spacing={1}
                                        alignItems="center"
                                        className="h-100"
                                      >
                                        <AntSwitch
                                          name="fieldMandatory"
                                          checked={values?.fieldMandatory}
                                          onChange={(event) => {
                                            setFieldValue(
                                              "fieldMandatory",
                                              event.target.checked
                                            );
                                          }}
                                          inputProps={{
                                            "aria-label": "ant design",
                                          }}
                                        />
                                      </Stack>
                                    </div>
                                  </Box>
                                  {values?.fieldType == "radio_single_choice" ||
                                    values?.fieldType ==
                                    "checkbox_single_choice" ||
                                    values?.fieldType ==
                                    "checkbox_multiple_choice" ||
                                    values?.fieldType ==
                                    "dropdodown_single_choice" ||
                                    values?.fieldType ==
                                    "dropdodown_multiple_choice" ? (
                                    <>
                                      <Row className="mt-2">
                                        <TableContainer className="z-0">
                                          {" "}
                                          <Table
                                            aria-labelledby="tableTitle"
                                            stickyHeader
                                          >
                                            <TableHeaderForSorting
                                              columns={
                                                columns_attribute_select_fields_header
                                              }
                                            />
                                            <DragDropContext
                                              onDragEnd={handleDragEnd}
                                            >
                                              <Droppable droppableId="rowsData">
                                                {(provided) => (
                                                  <TableBody
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={{
                                                      width: "100%",
                                                      top: 0,
                                                      left: 0,
                                                    }}
                                                  >
                                                    {values?.rowsData?.map(
                                                      (row, index) => (
                                                        <Draggable
                                                          key={row.id}
                                                          draggableId={row.id.toString()}
                                                          index={index}
                                                        >
                                                          {(provided) => (
                                                            <>
                                                              <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                key={index}
                                                                className="cursor-pointer tbl-row-attribute-row"
                                                                ref={
                                                                  provided.innerRef
                                                                }
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                              >
                                                                <TableCell
                                                                  align="left"
                                                                  className={` tbl-border-left tbl-cell-attribute`}
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                    top: 0,
                                                                    left: 0,
                                                                  }}
                                                                >
                                                                  <div className="main-div-icon-text d-flex align-items-center gap-2">
                                                                    <FontAwesomeIcon
                                                                      icon={
                                                                        faGripVertical
                                                                      }
                                                                      className="attribute-td-icon"
                                                                    />
                                                                    <div className="d-flex flex-wrap flex-column w-100">
                                                                      <TextField
                                                                        className="input-field mt-2 text-filed-attribute"
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        size="small"
                                                                        placeholder="Enter key here..."
                                                                        value={
                                                                          row.key
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleRowDataChange(
                                                                            row.id,
                                                                            "key",
                                                                            e
                                                                              .target
                                                                              .value
                                                                          )
                                                                        }
                                                                        name={`rowsData[${index}].key`}
                                                                      />

                                                                      <ErrorMessage
                                                                        name={`rowsData[${index}].key`}
                                                                        component="p"
                                                                        className="error-msg"
                                                                      />
                                                                    </div>
                                                                    <div className="d-flex flex-wrap flex-column w-100">
                                                                      <TextField
                                                                        className="input-field mt-2 text-filed-attribute"
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        size="small"
                                                                        placeholder="Enter value here..."
                                                                        value={
                                                                          row.value
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleRowDataChange(
                                                                            row.id,
                                                                            "value",
                                                                            e
                                                                              .target
                                                                              .value
                                                                          )
                                                                        }
                                                                        name={`rowsData[${index}].value`}
                                                                      />
                                                                      <ErrorMessage
                                                                        name={`rowsData[${index}].value`}
                                                                        component="p"
                                                                        className="error-msg"
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                </TableCell>
                                                                <TableCell
                                                                  align="left"
                                                                  className={` tbl-border-right tbl-cell-attribute`}
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                    top: 0,
                                                                    left: 0,
                                                                  }}
                                                                >
                                                                  <div className="d-flex fex-wrap justify-content-beewteen gap-2 align-items-center ">
                                                                    <AntSwitch
                                                                      checked={
                                                                        row.default
                                                                      }
                                                                      onChange={(
                                                                        event
                                                                      ) =>
                                                                        handleRowDataChange(
                                                                          row.id,
                                                                          "default",
                                                                          event
                                                                            .target
                                                                            .checked
                                                                        )
                                                                      }
                                                                      inputProps={{
                                                                        "aria-label":
                                                                          "ant design",
                                                                      }}
                                                                    />
                                                                    <IconButton
                                                                      aria-label="delete"
                                                                      color="error"
                                                                      className="action-btn-icon-btn"
                                                                      onClick={() =>
                                                                        handleDeleteRow(
                                                                          row.id
                                                                        )
                                                                      }
                                                                    >
                                                                      <DeleteIcon />
                                                                    </IconButton>
                                                                  </div>
                                                                </TableCell>
                                                              </TableRow>
                                                              <TableRow className="extra-row"></TableRow>
                                                            </>
                                                          )}
                                                        </Draggable>
                                                      )
                                                    )}
                                                    {provided.placeholder}
                                                  </TableBody>
                                                )}
                                              </Droppable>
                                            </DragDropContext>
                                          </Table>
                                        </TableContainer>
                                      </Row>
                                      {values?.rowsData?.length < 1 &&
                                        (values?.fieldType ===
                                          "radio_single_choice" ||
                                          values?.fieldType ===
                                          "checkbox_single_choice") && (
                                          <p className="error-msg">
                                            Please Add at least one option
                                          </p>
                                        )}
                                      {values?.rowsData?.length < 2 &&
                                        (values?.fieldType ===
                                          "checkbox_multiple_choice" ||
                                          values?.fieldType ===
                                          "dropdodown_multiple_choice" ||
                                          values?.fieldType ===
                                          "dropdodown_single_choice") && (
                                          <p className="error-msg">
                                            Please Add at least two options
                                          </p>
                                        )}
                                      {visibleAddOption && (
                                        <div className="mt-3 d-flex flex-wrap justify-content-end">
                                          <Button
                                            variant="outlined"
                                            startIcon={<AddIcon />}
                                            className="add-option-btn"
                                            onClick={handleAddOption}
                                          >
                                            Add Option
                                          </Button>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </Paper>
                              </CustomTabPanel>
                            </Box>
                          </Accordion.Body>
                        </Accordion.Item>
                        {id && (
                          <Accordion.Item
                            eventKey="3"
                            className="bg-white card-wrapper"
                          >
                            <Accordion.Header className="bg-white fw-700 primary-text">
                              Changelog
                            </Accordion.Header>
                            <Accordion.Body className="p-0 changelog-body">
                              <Grid
                                container
                                spacing={1}
                                className="changelog-div"
                              >
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  sm={12}
                                  lg={9}
                                  xl={9}
                                >
                                  <Box className="">
                                    <InputGroup className="">
                                      <TextField
                                        className="search-changelog-input"
                                        fullWidth
                                        id="outlined-basic username"
                                        variant="outlined"
                                        size="small"
                                        name="attributeTitle"
                                        value={search}
                                        placeholder="search changelog"
                                        onChange={(e) => {
                                          setIstype(true);
                                          setSearch(e.target.value);
                                          if (e.target.value === "") {
                                            fetchAllChangeLogs(page);
                                          }
                                        }}
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                            fetchAllChangeLogs(page);
                                          }
                                        }}
                                      />
                                      <Button
                                        className="search-changelog"
                                        onClick={() => {
                                          fetchAllChangeLogs(page);
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faSearch}
                                          className="search-changelog-icon"
                                        />
                                      </Button>
                                    </InputGroup>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  sm={12}
                                  lg={3}
                                  xl={3}
                                >
                                  <div className="d-flex flex-wrap w-100 h-100 align-items-center">
                                    <Button
                                      variant="outlined"
                                      startIcon={
                                        <FontAwesomeIcon icon={faFileCsv} />
                                      }
                                      className="export-csv-btn d-flex justify-content-center"
                                      onClick={fetchAllChangeLogsForExportCSV}
                                    >
                                      Export as CSV
                                    </Button>
                                  </div>
                                </Grid>
                              </Grid>
                              <Paper
                                sx={{ width: "100%" }}
                                className="changelog-table-div mt-4"
                              >
                                <TableContainer className="z-0">
                                  <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    stickyHeader
                                  >
                                    <TableHeaderForSorting
                                      columns={
                                        columns_attribute_changelog_header
                                      }
                                    />
                                    <TableBody>
                                      {getAllChangeLogs?.data?.map((data) => (
                                        <>
                                          <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            className=" tbl-changelog mt-2"
                                          >
                                            <TableCell
                                              align="left"
                                              className="tbl-border-left tbl-cell-5"
                                            >
                                              <Box>
                                                <Stack
                                                  direction="row"
                                                  alignContent="center"
                                                  alignItems="center"
                                                  spacing={1}
                                                  className="z-n1"
                                                >
                                                  <Avatar
                                                    alt={data?.changed_by}
                                                    src="/static/images/avatar/1.jpg"
                                                    className="author-avtar"
                                                  />
                                                  <Typography className="fw-400 fs-14 ">
                                                    {data?.changed_by
                                                      ? data?.changed_by
                                                      : "-"}
                                                  </Typography>
                                                </Stack>
                                              </Box>
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className="text-nowrap"
                                            >
                                              {data?.changed_at
                                                ? data?.changed_at
                                                : "-"}
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className="text-nowrap"
                                            >
                                              {data?.area ? data?.area : "-"}
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className="tbl-border-right"
                                            >
                                              <Typography
                                                className="fw-400 fs-14"
                                                dangerouslySetInnerHTML={{
                                                  __html: data?.description
                                                    ? processedHTML(
                                                      data?.description
                                                    ).innerHTML
                                                    : "-",
                                                }}
                                              ></Typography>
                                            </TableCell>
                                          </TableRow>
                                          <TableRow className="extra-row"></TableRow>
                                        </>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Paper>
                              {total_changelog > rowsPerPage && (
                                <TablePagination
                                  component="div"
                                  count={total_changelog}
                                  page={page}
                                  sx={{
                                    "& .MuiToolbar-root": {
                                      display: "flex",
                                      gap: "0.8rem !important",
                                      flexDirection: {
                                        xs: "column",
                                        md: "row",
                                      },
                                    },
                                  }}
                                  onPageChange={handleChangePage}
                                  rowsPerPage={rowsPerPage}
                                  onRowsPerPageChange={handleChangeRowsPerPage}
                                  labelDisplayedRows={({ from, to, count }) => {
                                    const currentPage = page + 1;
                                    const start =
                                      currentPage * rowsPerPage -
                                      rowsPerPage +
                                      1;
                                    const end = Math.min(
                                      currentPage * rowsPerPage,
                                      count
                                    );
                                    return (
                                      <>
                                        {`Showing ${start} to ${end} of `}
                                        <span>{count}</span>
                                        {" records"}
                                      </>
                                    );
                                  }}
                                />
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        )}
                      </Accordion>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Container>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openDeleteTypeAlert}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            Are you Sure ?
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDeleteTypeModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent
            dividers
          >
            <DialogContentText id="alert-dialog-description">
              Are you sure want to delete the attribute ?
            </DialogContentText>
          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
            <Button
              className="btn delete-btn-model"
              onClick={handleCloseDeleteTypeModel}
            >
              <FontAwesomeIcon icon={faXmark} />
              No
            </Button>
            <Button
              className="btn primary-btn"
              onClick={() => {
                deleteAttributeAPI()
              }
              }
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Loader isLoading={loading} />
        <Toaster />
      </div>
    </>
  );
};

export default AddAttribute;

