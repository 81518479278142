import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import {
    TextField,
} from "@mui/material";
import moment from "moment";

const DateRangeComponent = ({ attdata, handleChangeForFormData, handleChangeForChildAtts, isChild, parentAttData, handleChangeForAdditionalTextField, isMulti }) => {
    return (
        <>
            <DateRangePicker
                initialSettings={{
                    startDate:
                        attdata?.value
                            ?.startDate,
                    endDate:
                        attdata?.value
                            ?.endDate,
                    autoUpdateInput: false,
                }}
                onApply={(_, range) => {
                    const startDate =
                        range?.startDate;
                    const endDate =
                        range?.endDate;
                    let data = {
                        startDate:
                            startDate,
                        endDate: endDate,
                    };
                    if (isChild === true) {
                        handleChangeForChildAtts(
                            attdata?.nameValue,
                            data,
                            attdata?.section_id,
                            attdata,
                            parentAttData
                        );
                    }
                    else if (isMulti === true) {
                        handleChangeForAdditionalTextField(
                            attdata?.id,
                            data,
                            parentAttData?.nameValue,
                            parentAttData?.section_id,
                            attdata?.fieldtype
                        )
                    }
                    else {
                        handleChangeForFormData(
                            attdata?.nameValue,
                            data,
                            attdata?.section_id
                        );
                    }

                }}
            >
                <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic short summary"
                    variant="outlined"
                    placeholder={`Enter ${attdata?.label}...`}
                    value={
                        attdata?.value
                            ? `${moment(
                                attdata?.value
                                    ?.startDate
                            ).format(
                                "DD/MM/YYYY"
                            )} - ${moment(
                                attdata?.value
                                    ?.endDate
                            ).format(
                                "DD/MM/YYYY"
                            )}`
                            : ""
                    }
                    type="text"
                />
            </DateRangePicker>
        </>
    )
}

export default DateRangeComponent;