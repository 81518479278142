import signInUser from "../slices/auth/auth";
import drawerMenu from "../slices/drawer/drawer";
import typeTab from "../slices/type/type";
import attributeTab from "../slices/attribute/attribute";
import categoryTab from "../slices/category/category";
import groupTab from "../slices/groups/groups";
import userTab from "../slices/users/users";
import header from "../slices/header/header";
import conversation from "../slices/conversation/conversation";
import menuTab from "../slices/menu/menu"
import link from "../slices/link/link";
import page from "../slices/page/page";
import insight from "../slices/insight/insight"
import filterSlice from "../slices/userTypePage/filterSlice"
import mediaSlice from "../slices/media/media"

const slices = {
  signInUser: signInUser,
  drawerMenu: drawerMenu,
  typeTab: typeTab,
  attributeTab: attributeTab,
  categoryTab: categoryTab,
  groupTab: groupTab,
  userTab: userTab,
  header: header,
  conversation: conversation,
  menuTab: menuTab,
  link: link,
  page: page,
  filter: filterSlice,
  insight: insight,
  media: mediaSlice
};

export default slices;
