import React from 'react'
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Avatar, IconButton, Typography, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper, Tooltip, Box } from "@mui/material";
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { filterSliceAction } from '../../../redux/slices/userTypePage/filterSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFolder } from '@fortawesome/free-solid-svg-icons';
import { onlyDateFormat } from '../../../helpers/DateTimeFormat/dateTimeFormat';


function TypeList(props) {
  const { folderAndPosts, handleClickActionIcon, setSelectedFolderId, setSelectedFolderName, setBreadcrumbData, updateBreadcrumbs, page, fetchAllPostList } = props;
  const dispatch = useDispatch();
  const { sortType } = useSelector((state) => state.filter);

  const handleSort = (key) => {
    let sortTypeValue = null;
    if (sortType[key] === null) {
      sortTypeValue = 'asc';
    } else if (sortType[key] === 'asc') {
      sortTypeValue = 'desc';
    } else if (sortType[key] === 'desc') {
      sortTypeValue = null;
    }
    const data = {
      name: null,
      updated_at: null,
      created_by: null,
      [key]: sortTypeValue
    };
    dispatch(filterSliceAction.setSortType(data));
  }

  return (
    <TableContainer className="mt-3 type-list-container" component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead className='type-list-header'>
          <TableRow>
            <TableCell className='type-list-header-data cursor-pointer type-list-first-header'
              onClick={
                () => {
                  handleSort('name');
                }
              }
            >Name {(sortType?.name && sortType?.name === 'asc') ? <ExpandLess className="sort-icon" /> : ((sortType?.name && sortType?.name === 'desc') ? <ExpandMore className="sort-icon" /> : <ExpandLess className="sort-icon text-muted opacity-50" />)}</TableCell>
            <TableCell className='type-list-header-data type-list-second-header' align="left">Last Updated</TableCell>
            <TableCell className='type-list-header-data type-list-third-header' align="left">Creator</TableCell>
            <TableCell className='type-list-header-data type-list-last-header' align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableRow style={{ height: '10px', backgroundColor: 'transparent' }} />
        <TableBody className='type-list-body'>
          {folderAndPosts.map((post) => (
            <TableRow className='type-list-body-row' key={post?.id}>
              <TableCell className='' component="td" scope="row">
                <Box className={`d-flex gap-2 align-items-center ${post?.list_type === 'folder' && "cursor-pointer"}`} onClick={() => {
                  if (post?.list_type === 'folder') {
                    setSelectedFolderId(post?.id)
                    fetchAllPostList(page, post?.id, post?.title)
                  }
                }}>
                  {post?.list_type === 'folder' ? (
                    <FontAwesomeIcon className='type-list-title-icon' icon={faFolder} />
                  ) : (
                    <FontAwesomeIcon className='type-list-title-icon' icon={faFile} />
                  )}
                  <Tooltip title={post?.title?.length > 50 ? post?.title : ''} placement="top">
                    <Typography className="type-list-title" variant="h4" component="div">
                      {post?.title?.length > 50 ? post?.title.substr(0, 50) + '...' : post?.title}
                    </Typography>
                  </Tooltip>
                </Box>
              </TableCell>
              <TableCell className='type-list-text' align="left">{onlyDateFormat(post?.created_at, false, false, true) ?? '-'}</TableCell>
              <TableCell className='' align="left">
                <Box className='type-list-text d-flex align-items-center type-list-createdby'>
                  <Avatar className="type-list-created-avatar"
                    src={post?.created_by?.image}
                    alt={post?.created_by?.name} />
                  <span className>
                    {`${(post?.created_by?.last_name && post?.created_by?.last_name?.trim() !== '') ? post?.created_by?.name[0] + '.' : post?.created_by?.name ?? ''} ${post?.created_by?.last_name ?? ''}` ?? '-'}
                  </span>
                </Box>
              </TableCell>
              <TableCell align="right">
                {/* {post?.list_type !== "folder" && ( */}
                <IconButton
                  sx={{ height: 12, width: 3 }}
                  aria-label="more"
                  id="long-button"
                  aria-haspopup="true"
                  onClick={(e) => handleClickActionIcon(e, post)}
                >
                  <MoreVertIcon />
                </IconButton>
                {/* )} */}

              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TypeList