import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { TableHead, Typography } from "@mui/material";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import {
  Box,
  Stack,
  Breadcrumbs,
  Link,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Avatar,
  TableBody,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPlus } from "@fortawesome/free-solid-svg-icons";
import "../../styles/categories.scss";
import { categoryTabAction } from "../../redux/slices/category/category";
import Loader from "../../components/common/loader";
import { Toast } from "../../utils/toast";
import { Toaster } from "react-hot-toast";
import api from "../../components/common/commonFunctionForApi";
import logoutHelper from "../../helpers/logoutHelper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";
import { CSVLink } from "react-csv";
import StatusIcon from "../../components/common/StatusIcon";
import { exportXlsx } from "../../helpers/Exports/FileExport";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import PaginationCustom from "../../components/common/pagination/paginationCustom";

function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box className="tab-box">{children}</Box>}
    </div>
  );
}

const Categories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ITEM_HEIGHT = 48;

  const [isWarningMessage, setIsWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [dataOrder, setDataOrder] = useState("asc");
  const [loading, setLoading] = useState(false);

  const [anchorElMenu, setAnchorElForMenu] = useState(null);

  const openMenuFoEdit = Boolean(anchorElMenu);

  const { selectedCategoryTab, pagination } = useSelector((state) => state?.categoryTab);

  const [value, setValue] = useState(selectedCategoryTab);
  const [getAllCategories, setGetAllCategories] = useState([]);
  const [categoryId, setCategoryId] = useState();

  //states for csv data
  const [getChangelogCsvData, setGetChangelogCsvData] = useState([]);
  const [isCsvReady, setIsCsvReady] = useState(false);

  //states for paginations
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [nextPage, setNextPage] = useState(false);
  const [isDataFetching, setIsDataFetching] = useState(false);

  const [sortTypeForAdmin, setSortTypeForAdmin] = useState({
    name: null,
    updated_at: null,
    created_by: null,
  })

  const user = localStorage.getItem("user");
  const userToken = localStorage.getItem("accessToken");

  const handleOpenWarningMessage = () => {
    setIsWarningMessage(true);
    setIsWarningMessage("");
  };

  const handleCloseWarningMessage = () => {
    setIsWarningMessage(false);

    setWarningMessage("");
  };

  const handleOpenSuccessMessage = () => {
    setIsSuccessMessage(true);
    setIsSuccessMessage("");
  };

  const handleCloseSuccessMessage = () => {
    setIsSuccessMessage(false);
    setIsSuccessMessage("");
  };

  const handleClick = (event, row) => {
    setAnchorElForMenu(event.currentTarget);
    setCategoryId(row);
  };

  const handleClose = () => {
    setAnchorElForMenu(null);
  };

  const header_csv = [
    { label: "No", key: "no" },
    { label: "Name", key: "name" },
    { label: "Category-type", key: "catType" },
    { label: "Used", key: "used" },
    { label: "Creator", key: "creator" },
    { label: "Status", key: "status" },
  ];

  const getStatusValue = (value) => {
    return value === 0 ? "enable"
      : value === 1 ? "disable"
        : value === 3 ? "deleted"
          : "system";
  };

  const handleChangeDataOrdr = () => {
    let order = dataOrder === "asc" ? "desc" : "asc";
    setDataOrder(order);

    setPage(1);
    setGetAllCategories([]);

    fetchAllCategories(
      getStatusValue(value),
      order, 1
    );
  };

  const handleChange = (event, newValue) => {
    dispatch(categoryTabAction.handleSelectedTab(newValue));
    setValue(newValue);
    setPage(1);
    setGetAllCategories([]);
    fetchAllCategories(getStatusValue(newValue), dataOrder, 1, pagination?.per_page);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    dispatch(categoryTabAction.setPagination({
      ...pagination,
      page: newPage
    }))

    fetchAllCategories(getStatusValue(value), dataOrder, newPage, pagination?.per_page)
  };

  const handleChangeRowsPerPage = (event) => {

    dispatch(categoryTabAction.setPagination({
      ...pagination,
      per_page: event.target.value,
      page: 1
    }));
    fetchAllCategories(getStatusValue(value), dataOrder, 1, event.target.value)
  };

  const {
    open,
  } = useSelector((state) => state?.drawerMenu);

  function a11yProps2(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }

  const fetchAllCategories = async (type, order, page, per_page) => {
    try {
      if (page == 1) {
        setLoading(true);
      }
      let order_by
      const userToken = localStorage.getItem("accessToken");
      const firstNonNull = Object.entries(sortTypeForAdmin).find(([key, value]) => value !== null);
      if (firstNonNull) {
        order_by = firstNonNull[0] + '_' + firstNonNull[1];
      }
      const response = await api.get(
        `admin/category/${type}?${order_by ? `order_by=${order_by}` : ""}&page=${page ?? 1}&per_page=${per_page}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const newCategories = response?.response?.data;

      setGetAllCategories(newCategories)
      dispatch(categoryTabAction.setPagination({
        ...pagination,
        page: response?.response?.meta?.current_page,
        per_page: response?.response?.meta?.per_page,
        meta_data: response?.response?.meta
      }));
    } catch (err) {
      console.log("error in fetch posts api::", err);
      if (err?.response?.status === 401) {
        localStorage.setItem("last-redirect-page", `/categories`);
        logoutHelper(dispatch, navigate, userToken);
      }

      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
    } finally {
      setIsDataFetching(false); // Reset fetching state after the API call
      setLoading(false);
    }
  };

  const handleSort = (key) => {
    let sortTypeValue = null;
    if (sortTypeForAdmin[key] === null) {
      sortTypeValue = 'asc';
    } else if (sortTypeForAdmin[key] === 'asc') {
      sortTypeValue = 'desc';
    } else if (sortTypeForAdmin[key] === 'desc') {
      sortTypeValue = null;
    }
    const data = {
      name: null,
      updated_at: null,
      created_by: null,
      [key]: sortTypeValue
    };
    setSortTypeForAdmin(data)
  }

  const fetchAllForExportCSV = async (type, order) => {
    try {
      setLoading(true);
      const result = await exportXlsx(`admin/category/${type}${order ? `?order=${order}` : ""}&pagination=no`, 'all_category_data');
      if (!result) {
        Toast.error("Failed to download file");
      }
    } catch (err) {
      console.error("Error while downloading csv changelog::", err);
      Toast.error("Failed to download file");
    } finally {
      setLoading(false);
    }
  };

  const changeCategoryStatus = async (id, status) => {
    try {
      let data = {
        status: status,
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/category/status `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {

        if (value === 2) {
          setGetAllCategories((prevCategories) =>
            prevCategories.map((category) =>
              category.id === id ? { ...category, status: status } : category
            )
          );
        } else {
          setGetAllCategories((prevCategories) =>
            prevCategories.filter((category) => category.id !== id)
          );
        }
        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const RestoreCategory = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/category/restore `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        if (value === 2) {
          setGetAllCategories((prevCategories) =>
            prevCategories.map((category) =>
              category.id === id ? { ...category, status: response?.response?.status } : category
            )
          );
        } else {
          setGetAllCategories((prevCategories) =>
            prevCategories.filter((category) => category.id !== id)
          );
        }
        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
      } else if (response?.success == false) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        setLoading(false);
        handleClose();
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const deleteCategoryAPI = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/category/delete `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        if (value === 2) {
          setGetAllCategories((prevCategories) =>
            prevCategories.map((category) =>
              category.id === id ? { ...category, status: 'deleted' } : category
            )
          );
        } else {
          setGetAllCategories((prevCategories) =>
            prevCategories.filter((category) => category.id !== id)
          );
        }
        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const permanantlyDeleteCategoryAPI = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/category/forcedelete `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);

        setGetAllCategories([]);
        setPage(1);

        fetchAllCategories(
          getStatusValue(value),
          dataOrder, page, pagination?.per_page
        );
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllCategories(
      getStatusValue(selectedCategoryTab),
      dataOrder, 1, 10
    );
    setValue(selectedCategoryTab);
  }, []);

  const csvLinkRef = useRef();

  const handleExportClick = async () => {
    await fetchAllForExportCSV(getStatusValue(selectedCategoryTab),
      dataOrder);
  };

  useEffect(() => {
    if (isCsvReady && getChangelogCsvData.length > 0) {
      csvLinkRef.current.link.click();
      setIsCsvReady(false);
    }
  }, [isCsvReady, getChangelogCsvData]);

  useEffect(() => {
    fetchAllCategories(getStatusValue(value), dataOrder, page, pagination?.per_page)
  }, [sortTypeForAdmin])

  return (
    <>
      <div
        className={`main-content-wrapper-2 ${open === true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          {isSuccessMessage && (
            <SuccessAlert
              message={successMessage}
              onClose={handleCloseSuccessMessage}
            />
          )}

          {isWarningMessage && (
            <WarningAlert
              message={warningMessage}
              onClose={handleCloseWarningMessage}
            />
          )}
          <div className="main-div-2-types">
            <Box className="">
              <Stack spacing={2}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link
                    underline="hover"
                    key="1"
                    color="inherit"
                    href="/"
                    component="h3"
                    className="cursor-pointer"
                  >
                    Admin
                  </Link>
                  <Typography key="3" component="h3" color="text.primary">
                    Categories
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Box>
            <div className="d-flex flex-wrap gap-3">
              <Button className="btn export-btn" variant="outlined" onClick={handleExportClick}>
                Export
                <FontAwesomeIcon icon={faDownload} />
              </Button>
              <CSVLink
                data={getChangelogCsvData}
                headers={header_csv}
                filename={`Category data.csv`}
                target="_blank"
                className="csv-btn-a"
                ref={csvLinkRef} // Refrence for auto downlaod
                style={{ display: "none" }} // Hide the CSVLink
              />
              <Button
                className="btn primary-btn"
                onClick={() => {
                  localStorage.setItem("last-redirect-page", `/add/categories`);
                  navigate("/add/categories");
                }}
              >
                Add New
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          </div>
          <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="box-attribute"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                className={"tabs type-post-tab"}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#660C60",
                  },
                }}
              >
                <Tab
                  label="Active"
                  iconPosition="start"
                  {...a11yProps2(0)}
                />
                <Tab
                  label="Inactive"
                  {...a11yProps2(1)}
                  iconPosition="start"
                />

                <Tab
                  label="System"
                  {...a11yProps2(2)}
                  iconPosition="start"
                />
                <Tab
                  label="Deleted"
                  {...a11yProps2(3)}
                  iconPosition="start"
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={value}>
              <Paper sx={{ width: "100%" }} className="type-list-paper">
                <TableContainer component={Paper} className="type-list-container">
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                  >
                    {getAllCategories?.length > 0 && (
                      <>
                        <TableHead className='type-list-header'>
                          <TableRow>
                            <TableCell className='type-list-header-data cursor-pointer type-list-first-header'
                              onClick={
                                () => {
                                  handleSort('name');
                                }
                              }
                            >Name
                              {(sortTypeForAdmin?.name && sortTypeForAdmin?.name === 'asc') ? <ExpandLess className="sort-icon" /> : ((sortTypeForAdmin?.name && sortTypeForAdmin?.name === 'desc') ? <ExpandMore className="sort-icon" /> : <ExpandLess className="sort-icon text-muted opacity-50" />)}
                            </TableCell>
                            <TableCell className='type-list-header-data type-list-second-header-group' align="left">Category Type</TableCell>
                            <TableCell className='type-list-header-data type-list-second-header-group' align="left">Used</TableCell>
                            <TableCell className='type-list-header-data type-list-third-header' align="left">Creator</TableCell>
                            <TableCell className='type-list-header-data type-list-last-header-att-post' align="right">Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableRow style={{ height: '10px', backgroundColor: 'transparent' }} />

                        <TableBody className='type-list-body'>
                          {getAllCategories?.map((row, index) => (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index + 1}
                              className='type-list-body-row'
                            >
                              <TableCell
                                className='' component="td" scope="row"
                              >
                                <div
                                  className="d-flex flex-warp gap-3"
                                  style={{ fontSize: "14px" }}
                                >
                                  <Typography className="type-list-title" variant="h4" component="div">
                                    {row?.title}
                                  </Typography>
                                </div>
                              </TableCell>
                              <TableCell
                                align="left"
                                className='type-list-text'
                              >
                                {row?.category_type}
                              </TableCell>
                              <TableCell
                                align="left"
                                className='type-list-text'
                              >
                                {row?.used}
                              </TableCell>
                              <TableCell align="left" className="tbl-body-cell">
                                <Box className='type-list-text d-flex align-items-center type-list-createdby'>
                                  <Avatar
                                    className="type-list-created-avatar"
                                    src={row?.created_by}
                                    alt={row?.created_by}
                                  >
                                    {row?.created_by?.charAt(0)}
                                  </Avatar>
                                  <span className>
                                    {row?.created_by}
                                  </span>
                                </Box>
                              </TableCell>
                              <TableCell
                                align="center"
                                className="tbl-body-cell-2 tbl-border-right"
                              >
                                <div>
                                  <Stack
                                    direction="row"
                                    alignContent="center"
                                    alignItems="center"
                                    spacing={1}
                                    className="gap-1"
                                  >
                                    <StatusIcon status={row?.status} />
                                    {row?.is_system !== 1 && (
                                      <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={
                                          openMenuFoEdit ? "long-menu" : undefined
                                        }
                                        aria-expanded={
                                          openMenuFoEdit ? "true" : undefined
                                        }
                                        aria-haspopup="true"
                                        onClick={(e) => handleClick(e, row)}
                                        className="status-icon"

                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                    )}

                                  </Stack>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </>
                    )}
                  </Table>
                </TableContainer>
                {getAllCategories?.length > 0 && (
                  <>
                    <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
                    <PaginationCustom
                      per_page={pagination?.per_page}
                      per_page_options={[10, 20, 50, 100]}
                      from={pagination?.meta_data?.from}
                      to={pagination?.meta_data?.to}
                      last_page={pagination?.meta_data?.last_page}
                      total={pagination?.meta_data?.total}
                      page={pagination?.page}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </>
                )}
                {isDataFetching && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 2
                    }}
                  >
                    <CircularProgress sx={{ color: "#660C60" }} />
                  </Box>
                )}
              </Paper>
              {getAllCategories?.length < 1 && (
                <div className="no-data mt-5">
                  <div>
                    <Image
                      src={NoDataFoundImg}
                      className="text-center mx-auto no-data-img mt-4"
                    />
                  </div>
                  <Typography component="h5" variant="h5">
                    No data found
                  </Typography>
                </div>
              )}
            </CustomTabPanel>
          </Box>
        </Container>
        <Menu
          anchorEl={anchorElMenu}
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          open={openMenuFoEdit}
          anchorOrigin={"right"}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
              zIndex: "1",
            },
          }}
        >
          {categoryId?.status !== "deleted" && (
            <MenuItem
              onClick={() => {
                localStorage.setItem("last-redirect-page", `/categories/edit/${categoryId?.id}`);

                navigate(`/categories/edit/${categoryId?.id}`);
              }}
            >
              Edit
            </MenuItem>
          )}

          {categoryId?.status !== "enable" &&
            categoryId?.status !== "deleted" && (
              <MenuItem
                onClick={() => {
                  changeCategoryStatus(categoryId?.id, "enable");
                }}
              >
                Active
              </MenuItem>
            )}
          {categoryId?.status !== "disable" &&
            categoryId?.status !== "deleted" && (
              <MenuItem
                onClick={() => {
                  changeCategoryStatus(categoryId?.id, "disable");
                }}
              >
                Inactive
              </MenuItem>
            )}
          {categoryId?.used < 1 && categoryId?.status !== "deleted" && (
            <MenuItem
              onClick={() => {
                deleteCategoryAPI(categoryId?.id);
              }}
            >
              Delete
            </MenuItem>
          )}
          {categoryId?.status === "deleted" && (
            <MenuItem
              onClick={() => {
                RestoreCategory(categoryId?.id);
              }}
            >
              Restore
            </MenuItem>
          )}
          {categoryId?.status === "deleted" && (
            <MenuItem
              onClick={() => {
                permanantlyDeleteCategoryAPI(categoryId?.id);
              }}
            >
              Permanantly Delete
            </MenuItem>
          )}
        </Menu>
      </div>
      <Loader isLoading={loading} />
      <Toaster />
    </>
  );
};

export default Categories;
