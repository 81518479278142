import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Image } from "react-bootstrap";
import {
  Box,
  Stack,
  Breadcrumbs,
  Link,
  Typography,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Avatar,
  TableBody,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Chip,
  TableHead,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "../../styles/types.scss";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import logoutHelper from "../../helpers/logoutHelper";
import Loader from "../../components/common/loader";
import api from "../../components/common/commonFunctionForApi";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";
import { Toast } from "../../utils/toast";

import { Toaster } from "react-hot-toast";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import { menuTabAction } from "../../redux/slices/menu/menu";
import StatusIcon from "../../components/common/StatusIcon";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import PaginationCustom from "../../components/common/pagination/paginationCustom";

function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box className="tab-box">{children}</Box>}
    </div>
  );
}

const MenusList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorElMenu, setAnchorElForMenu] = useState(null);

  const openMenuFoEdit = Boolean(anchorElMenu);
  const [loading, setLoading] = useState(false);
  const [getAllMenus, setGetAllMenus] = useState([])
  const { selectedMenuTab } = useSelector((state) => state?.menuTab);

  const [value, setValue] = useState(selectedMenuTab);
  const [menuId, setmenuId] = useState();

  const [isWarningMessage, setIsWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [sortTypeForAdmin, setSortTypeForAdmin] = useState({
    name: null,
    updated_at: null,
    created_by: null,
  })
  const [page, setPage] = useState(1);


  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);
  const userToken = localStorage.getItem("accessToken");

  const {
    open,
  } = useSelector((state) => state?.drawerMenu);

  const { pagination } = useSelector((state) => state?.menuTab)

  const ITEM_HEIGHT = 48;

  const handleClick = (event, row) => {
    setAnchorElForMenu(event.currentTarget);
    setmenuId(row);
  };

  const handleClose = () => {
    setAnchorElForMenu(null);
  };

  const handleCloseWarningMessage = () => {
    setIsWarningMessage(false);

    setWarningMessage("");
  };

  const handleCloseSuccessMessage = () => {
    setIsSuccessMessage(false);
    setIsSuccessMessage("");
  };

  const getStatusValue = (value) => {
    return value === 0 ? "all"
      : value === 1 ? "system"
        : value === 2 ? "enable"
          : "disable";
  };

  const handleChange = (event, newValue) => {
    dispatch(menuTabAction.handleSelectedTab(newValue))
    setValue(newValue);

    fetchAllMenus(getStatusValue(newValue), pagination?.per_page, 1)

  };

  const handleSort = (key) => {
    let sortTypeValue = null;
    if (sortTypeForAdmin[key] === null) {
      sortTypeValue = 'asc';
    } else if (sortTypeForAdmin[key] === 'asc') {
      sortTypeValue = 'desc';
    } else if (sortTypeForAdmin[key] === 'desc') {
      sortTypeValue = null;
    }
    const data = {
      name: null,
      updated_at: null,
      created_by: null,
      [key]: sortTypeValue
    };
    setSortTypeForAdmin(data)
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    dispatch(menuTabAction.setPagination({
      ...pagination,
      page: newPage
    }))

    fetchAllMenus(getStatusValue(value), pagination?.per_page, newPage)
  };

  const handleChangeRowsPerPage = (event) => {

    dispatch(menuTabAction.setPagination({
      ...pagination,
      per_page: event.target.value,
      page: 1
    }));

    fetchAllMenus(getStatusValue(value), event?.target?.value, 1)
  };

  const fetchAllMenus = async (type, perPage, page) => {

    let order_by
    const firstNonNull = Object.entries(sortTypeForAdmin).find(([key, value]) => value !== null);
    if (firstNonNull) {
      order_by = firstNonNull[0] + '_' + firstNonNull[1];
    }
    try {
      setLoading(true);
      const response = await api.get(
        `admin/menu/${type}?${order_by ? `order_by=${order_by}&` : ""}&per_page=${perPage}&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setGetAllMenus(response?.data);
      dispatch(menuTabAction.setPagination({
        ...pagination,
        page: response?.meta?.current_page,
        per_page: response?.meta?.per_page,
        meta_data: response?.meta
      }));
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch menu api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.");
      }
      setLoading(false);
    }
  }

  const changeMenuStatus = async (id, status) => {
    try {
      let data = {
        status: status,
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/menu/change/status `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success) {
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
        setmenuId();
        fetchAllMenus(getStatusValue(value), pagination?.per_page, page)
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.");
      }
      setLoading(false);
    }
  };

  function a11yProps2(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value === index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }

  useEffect(() => {
    fetchAllMenus(getStatusValue(selectedMenuTab), 10, 1)
    setValue(selectedMenuTab);
  }, []);

  useEffect(() => {
    fetchAllMenus(getStatusValue(value), pagination?.per_page, page)
  }, [sortTypeForAdmin])

  return (
    <>
      <div
        className={`main-content-wrapper-2 ${open === true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"
            }`}
        >
          {isSuccessMessage && (
            <SuccessAlert
              message={successMessage}
              onClose={handleCloseSuccessMessage}
            />
          )}
          {isWarningMessage && (
            <WarningAlert
              message={warningMessage}
              onClose={handleCloseWarningMessage}
            />
          )}
          <div className="main-div-2-types">
            <Box className="">
              <Stack spacing={2}>
                <Breadcrumbs
                  separator={
                    <NavigateNextIcon fontSize="small" />
                  }
                  aria-label="breadcrumb"
                >
                  <Link
                    underline="hover"
                    key="1"
                    color="inherit"
                    href="/"
                    component="h3"
                    className="cursor-pointer"
                  >
                    Admin
                  </Link>
                  <Typography
                    key="3"
                    component="h3"
                    color="text.primary"
                  >
                    Menus
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Box>
            <Button
              className="btn primary-btn"
              onClick={() => {
                localStorage.setItem(
                  "last-redirect-page",
                  `/menu/add-new`
                );

                navigate("/menu/add-new");
              }}
            >
              Add New
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
          <Divider
            className="mt-4"
            style={{ background: "#EEEEEE" }}
          />
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="box-attribute"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                className={"tabs type-post-tab"}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#660C60",
                  },
                }}
              >
                <Tab
                  label="All"
                  iconPosition="start"
                  {...a11yProps2(0)}
                />
                <Tab
                  label="System"
                  iconPosition="start"
                  {...a11yProps2(1)}
                />
                <Tab
                  label="Active"
                  {...a11yProps2(2)}
                  iconPosition="start"
                />

                <Tab
                  label="Inactive"
                  {...a11yProps2(3)}
                  iconPosition="start"
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={value}>
              <Paper
                sx={{ width: "100%" }}
                className="type-list-paper"
              >
                <TableContainer component={Paper} className="type-list-container">
                  <Table
                    aria-labelledby="tableTitle"
                  >
                    {getAllMenus?.length > 0 && (
                      <>
                        <TableHead className='type-list-header'>
                          <TableRow>
                            <TableCell className='type-list-header-data cursor-pointer type-list-first-header'
                              onClick={
                                () => {
                                  handleSort('name');
                                }
                              }
                            >Name
                              {(sortTypeForAdmin?.name && sortTypeForAdmin?.name === 'asc') ? <ExpandLess className="sort-icon" /> : ((sortTypeForAdmin?.name && sortTypeForAdmin?.name === 'desc') ? <ExpandMore className="sort-icon" /> : <ExpandLess className="sort-icon text-muted opacity-50" />)}
                            </TableCell>
                            <TableCell className='type-list-header-data type-list-second-header-att-post' align="left">Date Created</TableCell>
                            <TableCell className='type-list-header-data type-list-third-header' align="left">Creator</TableCell>
                            <TableCell className='type-list-header-data type-list-last-header-att-post' align="right">Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableRow style={{ height: '10px', backgroundColor: 'transparent' }} />
                        <TableBody className='type-list-body'>
                          {getAllMenus?.map(
                            (row, index) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={index + 1}
                                className='type-list-body-row'
                              >
                                <TableCell
                                  className='' component="td" scope="row"
                                >
                                  <div
                                    className="d-flex flex-warp gap-3"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <Typography className="type-list-title" variant="h4" component="div">
                                      {row?.title}
                                    </Typography>
                                    {row?.system_type ===
                                      1 && (
                                        <Chip
                                          label="System Type"
                                          className="chip-label-div"
                                        />
                                      )}
                                  </div>
                                </TableCell>

                                <TableCell className='type-list-text' align="left">
                                  {
                                    row?.date_created
                                  }
                                </TableCell>

                                <TableCell className='' align="left">
                                  <Box className='type-list-text d-flex align-items-center type-list-createdby'>

                                    <Avatar
                                      className="type-list-created-avatar"
                                      src={row?.author?.thumbnail}
                                      alt={row?.author?.name}
                                    >
                                      {row?.author?.name?.charAt(0)}
                                    </Avatar>

                                    <span className>
                                      {row?.author?.name}
                                    </span>
                                  </Box>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className="tbl-body-cell-2 tbl-border-right"
                                >
                                  <div>
                                    <Stack
                                      direction="row"
                                      alignContent="center"
                                      alignItems="center"
                                      spacing={1}
                                      className="gap-1"
                                    >
                                      <StatusIcon status={row?.status} />

                                      <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={
                                          openMenuFoEdit
                                            ? "long-menu"
                                            : undefined
                                        }
                                        aria-expanded={
                                          openMenuFoEdit
                                            ? "true"
                                            : undefined
                                        }
                                        aria-haspopup="true"
                                        onClick={(
                                          e
                                        ) =>
                                          handleClick(
                                            e,
                                            row
                                          )
                                        }
                                        className="status-icon"
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                    </Stack>
                                  </div>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>

                      </>
                    )}
                  </Table>
                </TableContainer>
                {getAllMenus?.length > 0 && (
                  <>
                    <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
                    <PaginationCustom
                      per_page={pagination?.per_page}
                      per_page_options={[10, 20, 50, 100]}
                      from={pagination?.meta_data?.from}
                      to={pagination?.meta_data?.to}
                      last_page={pagination?.meta_data?.last_page}
                      total={pagination?.meta_data?.total}
                      page={pagination?.page}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </>
                )}

              </Paper>

              {getAllMenus?.length < 1 && (
                <div className="no-data mt-5">
                  <div>
                    <Image
                      src={NoDataFoundImg}
                      className="text-center mx-auto no-data-img mt-4"
                    />
                  </div>
                  <Typography component="h5" variant="h5">
                    No data found
                  </Typography>
                </div>
              )}
            </CustomTabPanel>
          </Box>
        </Container>
        <Menu
          anchorEl={anchorElMenu}
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          open={openMenuFoEdit}
          anchorOrigin={"right"}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
              zIndex: "1",
            },
          }}
        >
          {menuId?.status !== "deleted" && (
            <MenuItem
              onClick={() => {
                localStorage.setItem(
                  "last-redirect-page",
                  `/menu/edit/${menuId?.id}`
                );

                navigate(`/menu/edit/${menuId?.id}`);
              }}
            >
              Edit
            </MenuItem>
          )}

          {menuId?.status !== "enable" &&
            menuId?.status !== "deleted" && (
              <MenuItem
                onClick={() =>
                  changeMenuStatus(menuId?.id, "enable")
                }
              >
                Active
              </MenuItem>
            )}
          {menuId?.status !== "disable" &&
            menuId?.status !== "deleted" && (
              <MenuItem
                onClick={() =>
                  changeMenuStatus(menuId?.id, "disable")
                }
              >
                Inactive
              </MenuItem>
            )}
        </Menu>
      </div>

      <Loader isLoading={loading} />
      <Toaster />
    </>
  );
};

export default MenusList;
