import { createSlice } from "@reduxjs/toolkit";
const initialData = {
    selectedChatRoom: null,
    unReadMsgCount: 0,
    isEditableChat: false,
    isEditChat: false,
};

const conversation = createSlice({
    name: "conversationDetails",
    initialState: initialData,
    reducers: {
        setSelectedChatRoom(state, action) {
            state.selectedChatRoom = action?.payload
        },
        setUnReadMessageCount(state, action) {
            state.unReadMsgCount = action?.payload
        },
        setIsEditableChat(state, action) {
            state.isEditableChat = action?.payload
        },
        setIsEditChat(state, action) {
            state.isEditChat = action?.payload
        },
    }
})

export const conversationAction = conversation.actions;
export default conversation.reducer;