import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
  Stack,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import ReactionGroup from "../../../components/ui/ReactionGroup";
import { InputGroup } from "react-bootstrap";
import { Mention, MentionsInput } from "react-mentions";
import {
  faBriefcase,
  faClock,
  faComments,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DummyPostImg from "../../../assets/images/dummy-post.jpg";
import {
  CommentReactionClassName,
  ReactionClassName,
  ReactionIcon,
} from "../../../helpers/constants/common.js";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import mentionStyle from "../../../helpers/styles/dashboard/mentionStyle.js";
import mentionsInputStyle from "../../../helpers/styles/dashboard/mentionsInputStyle.js";
import { formatDateToReadable } from "../../../helpers/DateTimeFormat/dateTimeFormat.js";
import EllipsisTypography from "../../../components/common/EllipsisTypography.jsx";

const maxVisibleCategory = 1;

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: 0,
  },
}));

function PostCard({
  post,
  openMenuForPost,
  handleClickForMenuPost,
  setAckQuestions,
  setPostIdForAckQue,
  setOpenAckQuePopup,
  handleCheckboxChange,
  handlePopoverClose,
  handlePopoverCloseForReplyLike,
  handleCommentsAPI,
  handleCommentText,
  handleCommentToggle,
  handlePopoverOpen,
  toggleReaction,
  setIsSelectingSuggestion,
  handlePaste,
  handleEngagementModalOpen,
  getMentionUsers,
  renderCommentText,
  toggleLikeForCommentsReaction,
  setCurrentPostId,
  setCurrentPostIdForReply,
  handlePopoverOpenForReplyLike,
  handleCommentReplyToggle,
  handleCommentReplyText,
  handleCommentsReplyAPI,
  setFetchAll,
  fetchLoadMoreCommentsAPI,
}) {
  const navigate = useNavigate();
  const { open, adminAccessible } = useSelector((state) => state?.drawerMenu);
  const [elements, setElements] = useState([]);
  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);

  const setElementsCallback = useCallback(
    (el) => {
      if (el && !elements.includes(el)) {
        setElements((prev) => [...prev, el]);
      }
    },
    [elements]
  );

  return (
    <Card
      className="mt-4"
      data-post-id={post?.id + "seen-post-other"}
      data-post-view={post?.is_post_seen}
      ref={setElementsCallback}
    >
      <CardHeader
        avatar={
          <Avatar
            aria-label="recipe"
            className="author-avtar-creator-post"
            src={post?.created_by?.thumbnail}
          >
            {post?.created_by?.name?.charAt(0)}
          </Avatar>
        }
        title={
          <Stack direction="row" className="justify-content-between">
            <Stack
              flex={1}
              className="flex-md-row justify-content-between align-items-md-center"
            >
              <span className="post-posted-label">
                <b>{post?.created_by?.name}</b> posted a{" "}
                <b>{post?.type?.name}</b>
              </span>
              <Box>
                <Typography variant="caption" className="post-date-label">
                  {formatDateToReadable(post?.published_date_time)}
                </Typography>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={openMenuForPost ? "long-menu" : undefined}
                  aria-expanded={openMenuForPost ? "true" : undefined}
                  aria-haspopup="true"
                  className="edit-post-icon-btn"
                  onClick={(e) => handleClickForMenuPost(e, post)}
                  sx={{ display: { xs: "none", md: "inline-flex" } }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            </Stack>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={openMenuForPost ? "long-menu" : undefined}
              aria-expanded={openMenuForPost ? "true" : undefined}
              aria-haspopup="true"
              className="edit-post-icon-btn"
              onClick={(e) => handleClickForMenuPost(e, post)}
              sx={{ display: { xs: "inline-flex", md: "none" } }}
            >
              <MoreVertIcon />
            </IconButton>
          </Stack>
        }
        className="card-header-content"
      />
      <Box className="d-flex flex-column flex-md-row card-content-box">
        <CardMedia
          sx={{ height: 222 }}
          image={post?.image !== "" ? post?.image : DummyPostImg}
          className="cursor-pointer post-img w-100"
          onClick={() => {
            if (loggedUser?.user_type === "user") {
              localStorage.setItem(
                "last-redirect-page-user",
                `/user-type/view/${post?.type?.name}/${post?.id}`
              );
              navigate(`/user-type/view/${post?.type?.name}/${post?.id}`);
            } else if (loggedUser?.user_type === "admin" && adminAccessible) {
              localStorage.setItem(
                "last-redirect-page-admin-user",
                `/user-type/view/${post?.type?.name}/${post?.id}`
              );
              navigate(`/user-type/view/${post?.type?.name}/${post?.id}`);
            }
          }}
        />
        <CardContent className="content-div-body">
          <div className="main-content-div-1">
            <div className="main-div-posted">
              <FontAwesomeIcon icon={faClock} className="posted-date-icon" />
              <Typography className="posted-date-text opacity-50 ">
                {`${formatDateToReadable(post?.published_date_time)}`}
              </Typography>
            </div>
            {post?.category?.length > 0 && (
              <div className="main-div-workspace">
                <FontAwesomeIcon
                  icon={faBriefcase}
                  className="posted-date-icon"
                />
                {post?.category
                  ?.slice(0, maxVisibleCategory)
                  .map((cate, index) => (
                    <Typography
                      className="posted-date-text opacity-50"
                      key={index}
                    >
                      {cate}
                    </Typography>
                  ))}
                {post?.category?.length > maxVisibleCategory && (
                  <>
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <List>
                            {post?.category?.map((cate, index) => (
                              <>
                                <ListItem>
                                  <ListItemText
                                    primary={cate}
                                    className="fs-12"
                                  />
                                </ListItem>
                                {index !== post?.category?.length - 1 && (
                                  <Divider component="li" />
                                )}
                              </>
                            ))}
                          </List>
                        </React.Fragment>
                      }
                      placement="left-start"
                    >
                      <Chip
                        label={`+${
                          post?.category.length - maxVisibleCategory
                        } others`}
                        className="categoty-label-chip cursor-pointer"
                      />
                    </HtmlTooltip>
                  </>
                )}
              </div>
            )}
          </div>
          <Box className="post-content-data">
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="content-title text-break"
            >
              {post?.title}
            </Typography>
            <EllipsisTypography
              lines={2}
              variant="body2"
              color="text.secondary"
              className="fs-14 content-desc text-break"
            >
              {post?.content}
            </EllipsisTypography>
          </Box>
        </CardContent>
      </Box>
      <Box className="post-ack-box">
        {post?.is_require_acknowledge && (
          <Box
            className={`custom-checkbox ${
              post?.acknowledged_on !== "" && "active-checkbox"
            }`}
          >
            <label className="checkbox-style">
              {post?.acknowledged_on !== ""
                ? `You acknowledged this on ${formatDateToReadable(
                    post?.acknowledged_date_time
                  )}`
                : post?.acknowledge_data &&
                  typeof post?.acknowledge_data === "string"
                ? `${post?.acknowledge_data}`
                : `This requires your acknowledgement`}
              <input
                type="checkbox"
                checked={post?.acknowledged_on !== ""}
                onChange={() => {
                  if (post?.acknowledge_type === "question") {
                    setAckQuestions(post?.acknowledge_data);
                    setPostIdForAckQue(post?.id);
                    setOpenAckQuePopup(true);
                  } else if (post?.acknowledge_type === "checkbox") {
                    handleCheckboxChange(post?.id);
                  }
                }}
                disabled={post?.acknowledged_on !== ""}
              />
              <span className="checkmark"></span>
            </label>
          </Box>
        )}
      </Box>
      {post?.is_post_comment || post?.is_post_like ? (
        <>
          <CardActions
            className="bg-white"
            sx={{
              border: "1px solid #EEEEEE",
              padding: "10px",
            }}
            onMouseEnter={() => {
              handlePopoverClose();
              handlePopoverCloseForReplyLike();
            }}
            onMouseLeave={() => {
              handlePopoverClose();
              handlePopoverCloseForReplyLike();
            }}
          >
            {post?.is_post_like && (
              <Box
                className="action-like w-100 d-flex flex-wrap align-items-center gap-2 cursor-pointer"
                onClick={() => handleEngagementModalOpen(post?.id)}
              >
                <ReactionGroup reactions={post?.reactions} />
                <Box className="like-counts-box">
                  <Typography className="fs-12-action-btn">
                    {post?.like_count}
                  </Typography>
                </Box>
              </Box>
            )}
            {post?.is_post_comment && (
              <Box className="w-100 ms-0">
                <Typography className="fs-12-action-btn d-flex  justify-content-end">
                  {`${post?.comment_count} comments`}
                </Typography>
              </Box>
            )}
          </CardActions>
          <CardActions
            className="bg-white"
            sx={{
              border: "1px solid #EEEEEE",
              padding: "10px",
            }}
          >
            {post?.is_post_like && (
              <Box
                className="action-like w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                onClick={() => {
                  toggleReaction(
                    post?.id,
                    post?.user_has_liked === "no" ? "like" : "no",
                    post?.user_has_liked === "no"
                  );
                  handlePopoverClose();
                }}
                onMouseEnter={(e) => handlePopoverOpen(e, post.id)}
              >
                <FontAwesomeIcon
                  icon={ReactionIcon[post?.reaction || "like"]}
                  className={`fs-12-action-btn ${
                    ReactionClassName[post?.reaction || ""]
                  }`}
                />
                <Typography
                  className={`fs-12-action-btn text-capitalize ${
                    ReactionClassName[post?.reaction || ""]
                  }`}
                >
                  {post?.user_has_liked !== "no" ? post.reaction : "Like"}
                </Typography>
              </Box>
            )}
            {post?.is_post_comment && (
              <Box
                className="action-comments w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                onClick={() => handleCommentToggle(post?.id)}
                onMouseEnter={() => {
                  handlePopoverClose();
                  handlePopoverCloseForReplyLike();
                }}
                onMouseLeave={() => {
                  handlePopoverClose();
                  handlePopoverCloseForReplyLike();
                }}
              >
                <FontAwesomeIcon
                  icon={faComments}
                  className="fs-12-action-btn"
                />
                <Typography className="fs-12-action-btn"> Comments </Typography>
              </Box>
            )}
          </CardActions>
          {post?.is_post_comment && post?.commentToggle && (
            <CardActions
              className="bg-white card-action-2"
              sx={{
                border: "1px solid #EEEEEE",
                padding: "15px",
              }}
              onMouseEnter={handlePopoverClose}
              onMouseLeave={handlePopoverClose}
            >
              <Box
                className="w-100"
                onMouseEnter={() => {
                  handlePopoverClose();
                  handlePopoverCloseForReplyLike();
                }}
                onMouseLeave={() => {
                  handlePopoverClose();
                  handlePopoverCloseForReplyLike();
                }}
                sx={{ height: "40px" }}
              >
                <Box
                  className="d-flex justify-content-start align-items-center gap-2"
                  sx={{ height: "40px" }}
                >
                  <Avatar
                    alt={loggedUser?.name}
                    className="author-avtar-creator-3"
                    src={loggedUser?.thumbnail}
                  >
                    {loggedUser?.name?.charAt(0)}
                  </Avatar>
                  <InputGroup style={{ height: "40px" }}>
                    <MentionsInput
                      placeholder="Add Comment. Use '@' for mention"
                      value={post?.commentText}
                      singleLine={true}
                      onChange={(event) =>
                        handleCommentText(event.target.value, post?.id)
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleCommentsAPI(post?.id, "yes");
                        }
                      }}
                      className="input-comments"
                      style={mentionsInputStyle}
                      a11ySuggestionsListLabel={"Suggested mentions"}
                      onPaste={handlePaste}
                      suggestionsPortalHost={document.body}
                    >
                      <Mention
                        style={mentionStyle}
                        className="mention-popup-class"
                        data={getMentionUsers}
                        onAdd={() => setIsSelectingSuggestion(true)} // Set the flag when a suggestion is selected
                        appendSpaceOnAdd={true}
                        renderSuggestion={(suggestion, index) => (
                          <div className="list-class" key={index}>
                            <div className="list-class-div-1">
                              <img
                                src={suggestion.photo}
                                alt={suggestion.display}
                                className="list-class-div-1-img"
                              />
                              <div className="list-class-div-2">
                                <span style={{ fontWeight: "bold" }}>
                                  {suggestion?.full_name}
                                </span>
                                <br />
                                <span> {suggestion?.job_title} </span>
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    </MentionsInput>
                  </InputGroup>
                </Box>
              </Box>
            </CardActions>
          )}

          {post?.is_post_comment && post?.comments?.length > 0 && (
            <>
              <CardActions
                className="bg-white card-action-3"
                sx={{
                  border: "1px solid #EEEEEE",
                  padding: "10px",
                }}
              >
                {post?.comments?.map((comment) => (
                  <>
                    {comment?.is_parent === "yes" && (
                      <>
                        <Box
                          className="w-100 ms-0"
                          onMouseEnter={handlePopoverClose}
                          onMouseLeave={handlePopoverClose}
                        >
                          <Box className="d-flex justify-content-start align-items-center gap-2">
                            <Avatar
                              alt={comment?.user?.name}
                              className="author-avtar-creator-4 mb-auto"
                              src={comment?.user?.thumbnail}
                            >
                              {comment?.user?.name?.charAt(0)}
                            </Avatar>
                            <Box
                              sx={{ padding: "15px" }}
                              className="comment-box"
                            >
                              <Typography className="comment-user-name">
                                {comment?.user?.name}
                              </Typography>
                              <Typography className="fs-14 mt-2">
                                {renderCommentText(comment?.comment)}
                              </Typography>
                            </Box>
                          </Box>
                          <Stack
                            direction={{ xs: "column", md: "row" }}
                            divider={
                              <Divider
                                orientation="vertical"
                                flexItem
                                className="nested-divider"
                              />
                            }
                            className="mt-2 align-items-md-center"
                            spacing={1}
                            style={{ margin: "0 0 0 44px" }}
                          >
                            <Box>
                              <Box className="d-flex gap-2 align-items-center">
                                <Typography className="fs-10">
                                  {formatDateToReadable(
                                    comment?.comment_date_time
                                  )}
                                </Typography>
                                <Typography className="fs-10 add-dot" />

                                <Typography
                                  className={`fs-10 cursor-pointer ${
                                    comment?.has_user_liked_comment === "yes" &&
                                    "liked-post-text"
                                  }`}
                                  onClick={() => {
                                    toggleLikeForCommentsReaction(
                                      post?.id,
                                      comment?.has_user_liked_comment === "no"
                                        ? "like"
                                        : "no",
                                      comment?.id,
                                      comment?.has_user_liked_comment === "no"
                                    );
                                  }}
                                  onMouseEnter={(e) => {
                                    setCurrentPostId(post?.id);

                                    setCurrentPostIdForReply(comment?.id);
                                    handlePopoverOpenForReplyLike(
                                      e,
                                      post.id,
                                      comment?.id
                                    );
                                  }}
                                  onMouseLeave={() => {
                                    setCurrentPostId(post?.id);

                                    setCurrentPostIdForReply(comment?.id);
                                  }}
                                >
                                  Like
                                </Typography>
                                <Box
                                  className="action-like d-flex flex-wrap align-items-center gap-1 cursor-pointer"
                                  onMouseEnter={() => {
                                    handlePopoverClose();
                                    handlePopoverCloseForReplyLike();
                                  }}
                                  onMouseLeave={() => {
                                    handlePopoverClose();
                                    handlePopoverCloseForReplyLike();
                                  }}
                                  onClick={() => {
                                    handleEngagementModalOpen(
                                      comment?.id,
                                      "comment"
                                    );
                                  }}
                                >
                                  {comment?.user_reactions?.map((reaction) => (
                                    <FontAwesomeIcon
                                      icon={ReactionIcon[reaction || ""]}
                                      className={
                                        CommentReactionClassName[reaction || ""]
                                      }
                                    />
                                  ))}

                                  <Box className="like-counts-box">
                                    <Typography className="fs-10">
                                      {comment?.comment_likes_count}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              className="m-0"
                              onMouseEnter={() => {
                                handlePopoverClose();
                                handlePopoverCloseForReplyLike();
                              }}
                              onMouseLeave={() => {
                                handlePopoverClose();
                                handlePopoverCloseForReplyLike();
                              }}
                            >
                              <Box className="w-100 d-flex gap-2 align-items-center">
                                <Typography
                                  className="fs-10 cursor-pointer"
                                  onClick={() =>
                                    handleCommentReplyToggle(
                                      post?.id,
                                      comment?.id
                                    )
                                  }
                                >
                                  Reply
                                </Typography>
                                <Typography className="fs-10 cursor-pointer">
                                  {`${comment?.replies_count} reply`}
                                </Typography>
                              </Box>
                            </Box>
                          </Stack>
                        </Box>

                        {comment?.commentReplyToggle && (
                          <Box
                            className="w-100 ms-0"
                            sx={{
                              paddingLeft: "62px",
                            }}
                            onMouseEnter={() => {
                              handlePopoverClose();
                              handlePopoverCloseForReplyLike();
                            }}
                            onMouseLeave={() => {
                              handlePopoverClose();
                              handlePopoverCloseForReplyLike();
                            }}
                          >
                            <Box className="w-100 mt-2 mb-1">
                              <Box className="d-flex justify-content-start align-items-center gap-2">
                                <Avatar
                                  alt={loggedUser?.name}
                                  className="author-avtar-creator-4"
                                  src={loggedUser?.thumbnail}
                                >
                                  {loggedUser?.name?.charAt(0)}
                                </Avatar>
                                <InputGroup
                                  className=""
                                  style={{ height: "40px" }}
                                >
                                  <MentionsInput
                                    placeholder="Add Comment. Use '@' for mention"
                                    value={comment?.commentReplyText}
                                    singleLine={true}
                                    onChange={(event) => {
                                      handleCommentReplyText(
                                        event?.target?.value,
                                        post?.id,
                                        comment?.id
                                      );
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        e.preventDefault();
                                        handleCommentsReplyAPI(
                                          post?.id,
                                          "no",
                                          comment?.id
                                        );
                                      }
                                    }}
                                    className="input-comments"
                                    style={mentionsInputStyle}
                                    a11ySuggestionsListLabel={
                                      "Suggested mentions"
                                    }
                                    onPaste={handlePaste}
                                  >
                                    <Mention
                                      style={mentionStyle}
                                      className="mention-popup-class"
                                      data={getMentionUsers}
                                      appendSpaceOnAdd={true}
                                      onAdd={() =>
                                        setIsSelectingSuggestion(true)
                                      } // Set the flag when a suggestion is selected
                                      renderSuggestion={(suggestion, index) => (
                                        <div className="list-class" key={index}>
                                          <div className="list-class-div-1">
                                            <img
                                              src={suggestion.photo}
                                              alt={suggestion.display}
                                              className="list-class-div-1-img"
                                            />
                                            <div className="list-class-div-2">
                                              <span
                                                style={{ fontWeight: "bold" }}
                                              >
                                                {suggestion?.full_name}
                                              </span>
                                              <br />
                                              <span>
                                                {" "}
                                                {suggestion?.job_title}{" "}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    />
                                  </MentionsInput>
                                </InputGroup>
                              </Box>
                            </Box>
                          </Box>
                        )}

                        {(comment?.replies || [])?.map((reply) => (
                          <Box
                            className="w-100 ms-0"
                            sx={{ paddingLeft: "62px" }}
                            onMouseEnter={() => {
                              handlePopoverClose();
                              handlePopoverCloseForReplyLike();
                            }}
                            onMouseLeave={() => {
                              handlePopoverClose();
                              handlePopoverCloseForReplyLike();
                            }}
                          >
                            <Box className="w-100 mt-2">
                              <Box className="d-flex justify-content-start align-items-center gap-2">
                                <Avatar
                                  alt={reply?.user?.name}
                                  className="author-avtar-creator-4 mb-auto"
                                  src={reply?.user?.thumbnail}
                                >
                                  {reply?.user?.name?.charAt(0)}
                                </Avatar>
                                <Box
                                  sx={{ padding: "10px" }}
                                  className="comment-box"
                                >
                                  <Typography className="comment-user-name">
                                    {reply?.user?.name}
                                  </Typography>
                                  <Typography className="fs-14 mt-2">
                                    {renderCommentText(reply?.comment)}
                                  </Typography>
                                </Box>
                              </Box>
                              <Stack
                                direction="row"
                                divider={
                                  <Divider
                                    orientation="vertical"
                                    flexItem
                                    className="nested-divider"
                                  />
                                }
                                className="mt-2 align-items-md-center"
                                spacing={1}
                                style={{ margin: "0 0 0 44px" }}
                              >
                                <Box>
                                  <Box className="d-flex gap-2 align-items-center">
                                    <Typography className="fs-10">
                                      {formatDateToReadable(
                                        reply?.comment_date_time
                                      )}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Stack>
                            </Box>
                          </Box>
                        ))}
                      </>
                    )}
                  </>
                ))}
                {post?.comments?.length < post?.parent_comment_count && (
                  <Box
                    className="w-100 ms-0 d-flex justify-content-center"
                    onMouseEnter={() => {
                      handlePopoverClose();
                      handlePopoverCloseForReplyLike();
                    }}
                    onMouseLeave={() => {
                      handlePopoverClose();
                      handlePopoverCloseForReplyLike();
                    }}
                  >
                    <Button
                      className="mt-4 btn-load-comments text-capitalize"
                      onClick={() => {
                        setFetchAll(true);
                        fetchLoadMoreCommentsAPI(post);
                      }}
                    >
                      <FontAwesomeIcon icon={faEye} />
                      Load More Comments
                    </Button>
                  </Box>
                )}
              </CardActions>
            </>
          )}
        </>
      ) : (
        ""
      )}
    </Card>
  );
}

export default PostCard;

