import { createSlice } from "@reduxjs/toolkit";
const initialData = {
  selectedGroupTab: 0,
  pagination: {
    page: 1,
    per_page: 10,
    meta_data: null
  }
};

const groupTab = createSlice({
  name: "groupTabDetails",
  initialState: initialData,
  reducers: {
    handleSelectedTab(state, action) {
      state.selectedGroupTab = action?.payload;
    },
    setPagination(state, action) {
      state.pagination = action.payload;
    },
  },
});

export const groupTabAction = groupTab.actions;
export default groupTab.reducer;
