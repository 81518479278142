import React from "react";
import Select from "react-select";

const DropDownSingleChoice = ({ attdata, handleChangeForFormData, handleChangeForChildAtts, isChild, parentAttData, handleChangeForAdditionalTextField, isMulti }) => {
    // console.log("attdata::", attdata)
    return (
        <>
            <Select
                closeMenuOnSelect={true}
                options={
                    attdata?.options
                }
                menuPlacement="auto"
                name={
                    attdata?.nameValue
                }
                placeholder={`Select ${attdata?.label}...`}
                className="muilt-select-field w-100"
                classNamePrefix="select"
                value={attdata?.options?.find(
                    (option) =>
                        option?.value ==
                        attdata?.value
                )}
                onChange={(
                    selectedOption
                ) => {
                    if (isChild === true) {
                        handleChangeForChildAtts(
                            attdata?.nameValue,
                            selectedOption?.value,
                            attdata?.section_id,
                            attdata,
                            parentAttData
                        );
                    } else if (isMulti === true) {
                        handleChangeForAdditionalTextField(
                            attdata?.id,
                            selectedOption?.value,
                            parentAttData?.nameValue,
                            parentAttData?.section_id,
                            attdata?.fieldtype
                        );
                    }
                    else {
                        handleChangeForFormData(
                            attdata?.nameValue,
                            selectedOption?.value,
                            attdata?.section_id
                        );
                    }

                }}
                styles={{
                    option: (
                        provided,
                        state
                    ) => {
                        return {
                            ...provided,
                            backgroundColor:
                                state.isSelected
                                    ? "#660c605c"
                                    : provided.backgroundColor,
                            color:
                                state.isSelected
                                    ? "var(--dark-color)"
                                    : provided.color,
                        };
                    },
                }}
            />
        </>
    )
}

export default DropDownSingleChoice;