import React from "react";
import { Button, Stack, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { Box, Typography } from "@mui/material";

const ImageRepeatableComponent = ({ attdata, setOpenMediaModelFor, handleOpenUploadMediaForAtt, handleClickForAtt }) => {
    return (
        <>
            <Stack gap={2}>
                {attdata?.value ? (
                    <>
                        <div className="upload-img-wrapper-3 d-flex">
                            <Box className="w-25">
                                <Image src={attdata?.value?.file_path} className="h-100 w-100" />
                            </Box>
                            <Box className="w-75 media-details-div">
                                <Typography className="media-title">{attdata?.value?.title}</Typography>
                                <Typography className="media-size mt-1">{attdata?.value?.file_size}</Typography>

                            </Box>
                        </div>
                        <Box>
                            <Typography className="remove-img cursor-pointer" onClick={() => {
                                // setFieldValue("image", "")
                            }}>Remove Image</Typography>
                        </Box>
                    </>
                ) : (
                    <Button className="ternary-btn w-100" onClick={() => {
                        handleClickForAtt(
                            "image",
                            attdata
                        )
                    }}>
                        <FontAwesomeIcon icon={faUpload} />
                        {attdata?.value ? "Change Image" : "Upload Image"}
                    </Button>
                )}

            </Stack>
        </>
    )
}

export default ImageRepeatableComponent