import React from "react";
import { Button, Stack, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

const ImageUpload = (porps) => {
  let { value, handleClick } = porps;
  console.log("value for image::", value)
  return (
    <>
      <Stack gap={4}>
        {value && (
          <div className="upload-img-wrapper">
            <Image src={value?.file_path} />
          </div>
        )}

        <Button className="ternary-btn w-100" onClick={handleClick}>
          <FontAwesomeIcon icon={faUpload} />
          {value ? "Change Image" : "Upload Image"}
        </Button>
      </Stack>
    </>
  );
};

export default ImageUpload;
