import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Image } from "react-bootstrap";
import {
  Box,
  Stack,
  Breadcrumbs,
  Link,
  Typography,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Avatar,
  TableBody,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Chip,
  CircularProgress,
  TableHead,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";
import { Toast } from "../../utils/toast";
import {
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import logoutHelper from "../../helpers/logoutHelper";
import Loader from "../../components/common/loader";
import api from "../../components/common/commonFunctionForApi";
import { attributeTabAction } from "../../redux/slices/attribute/attribute";
import { Toaster } from "react-hot-toast";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import StatusIcon from "../../components/common/StatusIcon";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import PaginationCustom from "../../components/common/pagination/paginationCustom";

function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box className="tab-box">{children}</Box>}
    </div>
  );
}

const Attributes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedAttributeTab, pagination } = useSelector((state) => state?.attributeTab);

  const [value, setValue] = useState(0);
  const [dataOrder, setDataOrder] = useState("asc");
  const [loading, setLoading] = useState(false);
  const [getAllAttributes, setGetAllAttributes] = useState([]);
  const [anchorElMenu, setAnchorElForMenu] = useState(null);
  const [attributeID, setAttributeID] = useState();
  const [isWarningMessage, setIsWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const openMenuFoEdit = Boolean(anchorElMenu);

  //states for paginations
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [isDataFetching, setIsDataFetching] = useState(false);

  const [sortTypeForAdmin, setSortTypeForAdmin] = useState({
    name: null,
    updated_at: null,
    created_by: null,
  })

  const ITEM_HEIGHT = 48;

  const user = localStorage.getItem("user");
  const userToken = localStorage.getItem("accessToken");

  const {
    open,
  } = useSelector((state) => state?.drawerMenu);

  const handleClick = (event, row) => {
    setAnchorElForMenu(event.currentTarget);
    setAttributeID(row);
  };
  const handleClose = () => {
    setAnchorElForMenu(null);
  };

  const getStatusValue = (value) => {
    return value === 0 ? "enable"
      : value === 1 ? "disable"
        : value === 3 ? "deleted"
          : "system";
  };

  const handleChange = (event, newValue) => {
    dispatch(attributeTabAction.handleSelectedTab(newValue));
    setValue(newValue);
    setPage(1);
    setGetAllAttributes([]);
    fetchAllAttributes(getStatusValue(newValue), dataOrder, 1, pagination?.per_page);
  };

  const handleChangeDataOrdr = () => {
    let order = dataOrder === "asc" ? "desc" : "asc";
    setDataOrder(dataOrder === "asc" ? "desc" : "asc");

    setPage(1);
    setGetAllAttributes([]);

    fetchAllAttributes(
      getStatusValue(value),
      order,
      1
    );
  };

  const handleOpenWarningMessage = () => {
    setIsWarningMessage(true);
    setIsWarningMessage("");
  };

  const handleCloseWarningMessage = () => {
    setIsWarningMessage(false);

    setWarningMessage("");
  };

  const handleOpenSuccessMessage = () => {
    setIsSuccessMessage(true);
    setIsSuccessMessage("");
  };

  const handleCloseSuccessMessage = () => {
    setIsSuccessMessage(false);
    setIsSuccessMessage("");
  };

  const changeAttributeStatus = async (id, status) => {
    try {
      let data = {
        status: status,
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/attribute/status `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        if (value === 2) {
          setGetAllAttributes((prevCategories) =>
            prevCategories.map((category) =>
              category.id === id ? { ...category, status: status } : category
            )
          );
        } else {
          setGetAllAttributes((prevAtt) =>
            prevAtt.filter((att) => att.id !== id)
          );
        }
        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.");
      }
      setLoading(false);
    }
  };

  const deleteAttributeAPI = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/attribute/delete `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        if (value === 2) {
          setGetAllAttributes((prevCategories) =>
            prevCategories.map((category) =>
              category.id === id ? { ...category, status: "deleted" } : category
            )
          );
        } else {
          setGetAllAttributes((prevAtt) =>
            prevAtt.filter((att) => att.id !== id)
          );
        }
        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
        setDataOrder(dataOrder);
      } else {
        if (response?.message) {
          Toast.error(response?.message);
        }
        setLoading(false);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.");
      }
      setLoading(false);
    }
  };

  const RestoreAttribute = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/attribute/restore `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        if (value === 2) {
          setGetAllAttributes((prevCategories) =>
            prevCategories.map((category) =>
              category.id === id
                ? { ...category, status: response?.response?.status }
                : category
            )
          );
        } else {
          setGetAllAttributes((prevAtt) =>
            prevAtt.filter((att) => att.id !== id)
          );
        }
        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.");
      }
      setLoading(false);
    }
  };

  const permanantlyDeleteAttributeAPI = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/attribute/forcedelete `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);

        setGetAllAttributes([]);
        setPage(1);

        fetchAllAttributes(
          getStatusValue(value),
          dataOrder,
          page,
          pagination?.per_page
        );
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.");
      }
      setLoading(false);
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value === index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }

  const fetchAllAttributes = async (type, order, page, per_page) => {
    try {
      let order_by
      const userToken = localStorage.getItem("accessToken");
      const firstNonNull = Object.entries(sortTypeForAdmin).find(([key, value]) => value !== null);
      if (firstNonNull) {
        order_by = firstNonNull[0] + '_' + firstNonNull[1];
      }
      const response = await api.get(
        `admin/attribute/list/${type}?${order_by ? `order_by=${order_by}` : ""}&page=${page ?? 1
        }&per_page=${per_page}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const newCategories = response?.response?.data;

      setGetAllAttributes(newCategories)
      dispatch(attributeTabAction.setPagination({
        ...pagination,
        page: response?.response?.meta?.current_page,
        per_page: response?.response?.meta?.per_page,
        meta_data: response?.response?.meta
      }));

    } catch (err) {
      console.log("error in fetch posts api::", err);
      if (err?.response?.status === 401) {
        localStorage.setItem("last-redirect-page", `/categories`);
        logoutHelper(dispatch, navigate, userToken);
      }

      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.");
      }
    } finally {
      setIsDataFetching(false); // Reset fetching state after the API call
      setLoading(false);
    }
  };

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  const handleScroll = async () => {
    const scrollableHeight = document.documentElement.scrollHeight;
    const scrollPosition = window.innerHeight + window.scrollY;
    const remainingScrollThreshold = scrollableHeight * 0.3;

    if (
      scrollableHeight - scrollPosition < remainingScrollThreshold &&
      !isDataFetching &&
      page < totalPages
    ) {
      setIsDataFetching(true);
      const nextPageNum = page + 1;
      setPage(nextPageNum);
      await fetchAllAttributes(
        getStatusValue(value),
        dataOrder,
        nextPageNum,
        pagination?.per_page
      );
    }
  };

  const handleSort = (key) => {
    let sortTypeValue = null;
    if (sortTypeForAdmin[key] === null) {
      sortTypeValue = 'asc';
    } else if (sortTypeForAdmin[key] === 'asc') {
      sortTypeValue = 'desc';
    } else if (sortTypeForAdmin[key] === 'desc') {
      sortTypeValue = null;
    }
    const data = {
      name: null,
      updated_at: null,
      created_by: null,
      [key]: sortTypeValue
    };
    setSortTypeForAdmin(data)

  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    dispatch(attributeTabAction.setPagination({
      ...pagination,
      page: newPage
    }))

    fetchAllAttributes(getStatusValue(value), dataOrder, newPage, pagination?.per_page)
  };

  const handleChangeRowsPerPage = (event) => {

    dispatch(attributeTabAction.setPagination({
      ...pagination,
      per_page: event.target.value,
      page: 1
    }));
    fetchAllAttributes(getStatusValue(value), dataOrder, 1, event.target.value)
  };

  useEffect(() => {
    fetchAllAttributes(
      getStatusValue(selectedAttributeTab),
      dataOrder, 1, pagination?.per_page
    );
    setValue(selectedAttributeTab);
  }, []);

  useEffect(() => {
    fetchAllAttributes(getStatusValue(value), dataOrder, page, pagination?.per_page)
  }, [sortTypeForAdmin])

  return (
    <>
      <div
        className={`main-content-wrapper-2 ${open === true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          {isSuccessMessage && (
            <SuccessAlert
              message={successMessage}
              onClose={handleCloseSuccessMessage}
            />
          )}

          {isWarningMessage && (
            <WarningAlert
              message={warningMessage}
              onClose={handleCloseWarningMessage}
            />
          )}
          <div className="main-div-2-types">
            <Box className="">
              <Stack spacing={2}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link
                    underline="hover"
                    key="1"
                    color="inherit"
                    href="/"
                    component="h3"
                    className="cursor-pointer"
                  >
                    Admin
                  </Link>
                  <Typography key="3" component="h3" color="text.primary">
                    Attributes
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Box>
            <Button
              className="btn primary-btn"
              onClick={() => {
                localStorage.setItem(
                  "last-redirect-page",
                  `/attribute/add-new`
                );

                navigate("/attribute/add-new");
              }}
            >
              Add New
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
          <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="box-attribute"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                className={"tabs type-post-tab"}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#660C60",
                  },
                }}
              >
                <Tab label="Active" iconPosition="start" {...a11yProps(0)} />
                <Tab label="Inactive" {...a11yProps(1)} iconPosition="start" />
                <Tab label="System" {...a11yProps(2)} iconPosition="start" />
                <Tab label="Deleted" {...a11yProps(3)} iconPosition="start" />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={value}>
              <Paper sx={{ width: "100%" }} className="type-list-paper">
                <TableContainer component={Paper} className="type-list-container">
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                  >
                    {getAllAttributes?.length > 0 && (
                      <>
                        <TableHead className='type-list-header-2'>
                          <TableRow>
                            <TableCell className='type-list-header-data cursor-pointer type-list-first-header-group-name'
                              onClick={
                                () => {
                                  handleSort('name');
                                }
                              }
                            >Name
                              {(sortTypeForAdmin?.name && sortTypeForAdmin?.name === 'asc') ? <ExpandLess className="sort-icon" /> : ((sortTypeForAdmin?.name && sortTypeForAdmin?.name === 'desc') ? <ExpandMore className="sort-icon" /> : <ExpandLess className="sort-icon text-muted opacity-50" />)}
                            </TableCell>
                            <TableCell className='type-list-header-data type-list-second-header-group' align="left">Tab</TableCell>
                            <TableCell className='type-list-header-data type-list-second-header-group' align="left">Field Type</TableCell>
                            <TableCell className='type-list-header-data type-list-second-header-group' align="left">Repeats</TableCell>
                            <TableCell className='type-list-header-data type-list-third-header' align="left">Creator</TableCell>
                            <TableCell className='type-list-header-data type-list-last-header-att-post' align="right">Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableRow style={{ height: '10px', backgroundColor: 'transparent' }} />

                        <TableBody className='type-list-body'>
                          {getAllAttributes?.map((row, index) => (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index + 1}
                              className="cursor-pointer"
                            >
                              <TableCell
                                align="left"
                                className={`tbl-body-cell tbl-border-left ${row?.status === "deleted" && "text-muted"
                                  }`}
                              >
                                <div
                                  className="d-flex flex-warp gap-3"
                                  style={{ fontSize: "14px" }}
                                >
                                  <p
                                    className={`${row?.status === "deleted" &&
                                      "text-muted fw-700"
                                      }`}
                                  >
                                    {row?.title}
                                  </p>
                                  {row?.is_system == 1 && (
                                    <Chip
                                      label="System"
                                      className="chip-label-div"
                                    />
                                  )}
                                </div>
                              </TableCell>
                              <TableCell
                                align="left"
                                className="tbl-body-cell-3 "
                              >
                                {row?.section_name}
                              </TableCell>
                              <TableCell
                                align="left"
                                className="tbl-body-cell-3 "
                              >
                                {row?.element_name}
                              </TableCell>
                              <TableCell
                                align="left"
                                className="tbl-body-cell-4"
                              >
                                <FontAwesomeIcon
                                  icon={
                                    row?.is_repeatable != 1 ? faXmark : faCheck
                                  }
                                  className={`${row?.is_repeatable == 1
                                    ? "check-icon"
                                    : "cross-icon"
                                    }`}
                                />
                              </TableCell>
                              <TableCell align="left" className="tbl-body-cell">
                                <Box className='type-list-text d-flex align-items-center type-list-createdby'>

                                  <Avatar
                                    className="type-list-created-avatar"
                                    src={row?.created_by}
                                    alt={row?.created_by}
                                  >
                                    {row?.created_by?.charAt(0)}
                                  </Avatar>
                                  <span className>
                                    {row?.created_by}
                                  </span>
                                </Box>
                              </TableCell>
                              <TableCell
                                align="center"
                                className="tbl-body-cell-2 tbl-border-right"
                              >
                                <div>
                                  <Stack
                                    direction="row"
                                    alignContent="center"
                                    alignItems="center"
                                    spacing={1}
                                    className="gap-1"
                                  >
                                    <StatusIcon
                                      status={
                                        row?.is_archived === 1
                                          ? "archived"
                                          : row?.status
                                      }
                                    />
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={
                                        openMenuFoEdit ? "long-menu" : undefined
                                      }
                                      aria-expanded={
                                        openMenuFoEdit ? "true" : undefined
                                      }
                                      aria-haspopup="true"
                                      onClick={(e) => handleClick(e, row)}
                                      className="status-icon"
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </Stack>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </>
                    )}
                  </Table>
                </TableContainer>
                {getAllAttributes?.length > 0 && (
                  <>
                    <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
                    <PaginationCustom
                      per_page={pagination?.per_page}
                      per_page_options={[10, 20, 50, 100]}
                      from={pagination?.meta_data?.from}
                      to={pagination?.meta_data?.to}
                      last_page={pagination?.meta_data?.last_page}
                      total={pagination?.meta_data?.total}
                      page={pagination?.page}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </>
                )}
                {isDataFetching && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 2,
                    }}
                  >
                    <CircularProgress sx={{ color: "#660C60" }} />
                  </Box>
                )}
              </Paper>
              {getAllAttributes?.length < 1 && (
                <div className="no-data mt-5">
                  <div>
                    <Image
                      src={NoDataFoundImg}
                      className="text-center mx-auto no-data-img mt-4"
                    />
                  </div>
                  <Typography component="h5" variant="h5">
                    No data found
                  </Typography>
                </div>
              )}
            </CustomTabPanel>
          </Box>
        </Container>
        <Menu
          anchorEl={anchorElMenu}
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          open={openMenuFoEdit}
          anchorOrigin={"right"}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
              zIndex: "1",
            },
          }}
        >
          {attributeID?.status !== "deleted" && (
            <MenuItem
              onClick={() => {
                localStorage.setItem(
                  "last-redirect-page",
                  `/attribute/edit/${attributeID?.id}`
                );
                navigate(`/attribute/edit/${attributeID?.id}`);
              }}
            >
              Edit
            </MenuItem>
          )}

          {attributeID?.status !== "enable" &&
            attributeID?.status !== "deleted" && (
              <MenuItem
                onClick={() => changeAttributeStatus(attributeID?.id, "enable")}
              >
                Active
              </MenuItem>
            )}
          {attributeID?.status !== "disable" &&
            attributeID?.status !== "deleted" && (
              <MenuItem
                onClick={() =>
                  changeAttributeStatus(attributeID?.id, "disable")
                }
              >
                Inactive
              </MenuItem>
            )}
          {attributeID?.status !== "deleted" &&
            attributeID?.is_system !== 1 && (
              <MenuItem onClick={() => deleteAttributeAPI(attributeID?.id)}>
                Delete
              </MenuItem>
            )}
          {attributeID?.status === "deleted" &&
            attributeID?.is_system !== 1 && (
              <>
                <MenuItem onClick={() => RestoreAttribute(attributeID?.id)}>
                  Restore
                </MenuItem>
                <MenuItem
                  onClick={() => permanantlyDeleteAttributeAPI(attributeID?.id)}
                >
                  Permanantly Delete
                </MenuItem>
              </>
            )}
        </Menu>
        <Loader isLoading={loading} />
        <Toaster />
      </div>
    </>
  );
};

export default Attributes;

