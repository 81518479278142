import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Grid,
  Avatar,
  Paper,
  Button,
  Stack,
  Chip,
  Menu,
  MenuItem,
  DialogContentText,
  Radio,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { Row, Col, Image } from "react-bootstrap";
import { TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faBurst,
  faCheck,
  faFloppyDisk,
  faFolder,
  faPlus,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Toast } from "../../utils/toast";
import Loader from "../../components/common/loader";
import { useParams } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import InputGroup from "react-bootstrap/InputGroup";
import TablePagination from "@mui/material/TablePagination";
import {
  faCircleInfo,
  faGripVertical,
  faSearch,
  faFileCsv,
} from "@fortawesome/free-solid-svg-icons";
import TableHeaderForSorting from "../../components/common/TableHeaderForSorting";
import Accordion from "react-bootstrap/Accordion";
import { AntSwitch } from "../../components/common/switch/antSwitch";
import { BootstrapTooltip } from "../../components/common/tooltip/bootstrapTooltip";
import { columns_types_ack_fields_header } from "../../helpers/theaderColumns/types/typeAcknowledgeField";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import activeType from "../../assets/images/active.png";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import isEqual from "lodash/isEqual";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import RadioGroup from "@mui/material/RadioGroup";
import CreatableSelect from "react-select/creatable";
import { columns_category_headers_headers } from "../../helpers/theaderColumns/category/termsHeader";
import { addNewCategoryvalidationSchema } from "../../helpers/validations/addCategory";
import { Form, Formik } from "formik";
import api from "../../components/common/commonFunctionForApi";
import logoutHelper from "../../helpers/logoutHelper";
import { createEvent } from "@testing-library/dom";
import { columns_attribute_changelog_header } from "../../helpers/theaderColumns/attributes/attributeChangelog";
import { categoryTabAction } from "../../redux/slices/category/category";
import StatusIcon from "../../components/common/StatusIcon";
import { dateWithTime } from "../../helpers/DateTimeFormat/dateTimeFormat";
import { exportXlsx } from "../../helpers/Exports/FileExport";

function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}


const processedHTML = (htmlContent) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent || ""; // Ensure there's a default value if htmlContent is undefined
  return tempDiv;
};

const AddCategory = () => {
  let { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getAllChangeLogs, setGetAllChangeLogs] = useState();
  const [search, setSearch] = useState("");
  const [termSearch, setTermSearch] = useState("");
  const [istermSearchTyping, setIsTermSearchTyping] = useState(false);

  const [getCategoryData, setCategoryData] = useState();

  const [isWarningMessage, setIsWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [getAllCategoryTypes, setGetAllCategoryTypes] = useState([]);
  const [getAllUsers, setGetAllUsers] = useState([]);
  const [anchorElMenu, setAnchorElForMenu] = useState(null);
  const [opnAddattributeModel, setOpenAttributeModel] = useState(false);
  const [opnEditattributeModel, setOpenEditAttributeModel] = useState(false);

  const [selectAttributes, setSelectedAttributes] = useState([]);

  const [getAllAttributes, setGetAllAttributes] = useState([]);
  const [previousSelectedOptions, setPreviousSelectedOptions] = useState([]);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [attributeID, setAttributeID] = useState();

  const [istype, setIstype] = useState(false);

  const [categoryType, setCategoryType] = useState("");
  const [categoryAppend, setCategoryAppend] = useState(false);

  const [termsUsers, setTermsUsers] = useState([]);
  const [terms, setTerms] = useState([]);
  const [newTerms, setNewTerms] = useState([]);
  const [finalTerms, setFinalTerms] = useState([]);
  const [existingterms, setExistingTerms] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [categoryId, setCategoryId] = useState();

  const [anchorElForEnable, setAnchorElForEnable] = useState(null);
  const [anchorElForTerms, setAnchorElForTerms] = useState(null);
  const [rowsData, setRowsData] = useState([])
  const [openDeleteTypeAlert, setOpenDeleteTypeAlert] = useState(false)


  const debounceTimeout = 500; // Adjust the debounce timeout as needed
  let debounceTimer;

  const initialValues = {
    categoryTitle: id ? getCategoryData?.categoryTitle : "",
    categoryType: id ? getCategoryData?.categoryType : 1,
    categoryStatus: id ? getCategoryData?.categoryStatus : true,
    categoryAppend: id ? getCategoryData?.categoryAppend : false,
    users: id
      ? getCategoryData?.users
      : getAllUsers
        ?.filter((option) => option?.value === "all_registered")
        .map((option) => option.value),
    terms: id ? getCategoryData?.terms : [],
    rowsData: id ? getCategoryData?.rowsData : [],
    selectAttributes: id ? getCategoryData?.selectAttributes : [],
    termName: categoryId ? categoryId?.label : "",
    termStatus:
      categoryId && categoryId?.status === "enable"
        ? true
        : categoryId && categoryId?.status === "disable"
          ? false
          : false,
  };

  const {
    open,
  } = useSelector((state) => state?.drawerMenu);

  const userToken = localStorage.getItem("accessToken");
  const user = localStorage.getItem("user");
  const ITEM_HEIGHT = 48;

  const header_csv = [
    { label: "No", key: "no" },
    { label: "User", key: "user" },
    { label: "Date", key: "date" },
    { label: "Area", key: "area" },
    { label: "Details", key: "details" },
  ];
  let existingCatAppend;

  const openMenuFoEdit = Boolean(anchorElMenu);
  const openMenuEditTerms = Boolean(anchorElForTerms);

  const openInfoEnable = Boolean(anchorElForEnable);

  const total_changelog = parseInt(getAllChangeLogs?.meta?.total);

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setPage(newPage);
      fetchAllChangeLogs(newPage + 1);
    } else if (newPage < page) {
      setPage(newPage);
      fetchAllChangeLogs(newPage + 1);
    }
  };

  const handleChangePageForTerms = (event, newPage) => {
    if (newPage > page) {
      setPage(newPage);
    } else if (newPage < page) {
      setPage(newPage);
    }
  };


  const handlePopoverOpenForEnable = (event) => {
    setAnchorElForEnable(event.currentTarget);
  };

  const handlePopoverCloseForEnable = () => {
    setAnchorElForEnable(null);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeRowsPerPageForTerms = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCategoryChange = (event) => {
    setCategoryType(event.target.value);
  };

  const handleopnAlertDeleteModel = () => {
    setOpenAlertDialog(true);
  };

  const handleCloseAlertDeleteModel = () => {
    setOpenAlertDialog(false);
  };

  const handleCloseDeleteTypeModel = () => {
    setOpenDeleteTypeAlert(false)
  }

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      const updatedRowsData = terms?.map((data) => data);
      setFinalTerms(updatedRowsData);
    } else if (newValue === 1) {
      const updatedRowsData = terms?.filter(
        (data) => data?.status !== "disable" && data?.status !== "deleted"
      );
      setFinalTerms(updatedRowsData);
    } else if (newValue === 2) {
      const updatedRowsData = terms?.filter(
        (data) => data?.status !== "enable" && data?.status !== "deleted"
      );
      setFinalTerms(updatedRowsData);
    } else if (newValue === 3) {
      const updatedRowsData = terms?.filter(
        (data) => data?.status === "deleted"
      );
      setFinalTerms(updatedRowsData);
    }
  };

  const getFormattedDateTime = () => {
    const currentDate = new Date();

    // Get the date components
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // Months are zero-indexed
    const year = currentDate.getFullYear();

    // Get the time components
    let hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format

    // Format the date and time
    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes
      .toString()
      .padStart(2, "0")}${ampm}`;

    return `${formattedDate}, ${formattedTime}`;
  };

  const handleOpenWarningMessage = () => {
    setIsWarningMessage(true);
    setIsWarningMessage("");
  };

  const handleCloseWarningMessage = () => {
    setIsWarningMessage(false);

    setWarningMessage("");
  };

  const handleOpenSuccessMessage = () => {
    setIsSuccessMessage(true);
    setIsSuccessMessage("");
  };

  const handleCloseSuccessMessage = () => {
    setIsSuccessMessage(false);
    setIsSuccessMessage("");
  };

  const handleClick = (event, row) => {
    setAnchorElForMenu(event.currentTarget);
    setAttributeID(row);
  };

  const handleClickForTerms = (event, row) => {
    event.stopPropagation();
    setAnchorElForTerms(event.currentTarget);
    addCategoryData(row);
  };

  const addCategoryData = (row) => {
    setCategoryId(row);
  };

  const handleopnAddattributeModel = () => {
    setOpenAttributeModel(true);
  };

  const handleopnEditattributeModel = () => {
    setAnchorElForTerms(null);
    setOpenEditAttributeModel(true);
  };

  const handleCloseAddattributeModel = () => {
    setOpenAttributeModel(false);
  };

  const handleCloseEditattributeModel = () => {
    setOpenEditAttributeModel(false);
  };

  const handleClose = () => {
    const scrollPosition = window.scrollY;
    setAnchorElForMenu(null);
    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
    }, 0);
  };

  const handleCloseForTerms = () => {
    setAnchorElForTerms(null);
  };

  function a11yProps1(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        tabValue === index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }

  const visibleRows = React.useMemo(
    () =>
      stableSort(finalTerms).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [page, rowsPerPage, finalTerms, setFinalTerms]
  )

  const validate = () => {
    if (categoryType === "") {
      setIsWarningMessage(true)
      setWarningMessage("Please select category type")
      Toast.error("Please select category type")
      return false
    }
    else if (categoryType === 1 && terms?.length < 1) {
      setIsWarningMessage(true)
      setWarningMessage("At least one term is required")
      Toast.error("At least one term is required")
      return false
    } else if (categoryType === 2 && rowsData?.length < 1) {
      setIsWarningMessage(true)
      setWarningMessage("Please add minimum 1 attribute")
      Toast.error("Please add minimum 1 attribute")
      return false
    } else {
      setIsWarningMessage(false)
      setWarningMessage("")
      return true
    }
  }

  const onSubmit = async (values) => {
    let validateData = validate()
    if (validateData) {
      setIsWarningMessage(false)
      setWarningMessage("")
      if (id) {
        let data = {
          title: values?.categoryTitle,
          id: id,
          category_type_id: categoryType,
          status: values?.categoryStatus === true ? "enable" : "disable",
          append_to:
            categoryAppend === true && categoryType === 1
              ? "enable"
              : categoryAppend === false && categoryType === 1
                ? "disable"
                : "none",
        };
        if (categoryType === "1" || categoryType == 1) {
          const viewUsers = termsUsers?.map((user) => user);
          const usersString = viewUsers.join(",");
          data.terms_users =
            termsUsers?.length > 0 && categoryAppend === true
              ? usersString
              : categoryAppend === false
                ? "none"
                : "";
          data.terms = terms?.map((term) => {
            return {
              id: term?.id,
              value: term?.value,
              restore: term?.restore === true ? true : false,
              status: term?.status,
            };
          });

          data.associate_attribute = [];
        } else if (categoryType === "2" || categoryType == 2) {
          data.terms_users = "none";
          const updatedRows = values?.rowsData
            .filter((row) => {
              // Find rows that have changed or are newly added
              const originalRow = getCategoryData?.rowsData.find(
                (original) => original.id === row.id
              );
              return !originalRow || !isEqual(originalRow, row);
            })
            .map((data) => ({
              id: data.id,
              name: data.name,
              attrId: data.attrId,
              status: data.status,
              chip: data.chip,
              is_system: data.is_system,
            }));

          const updatedRows2 = rowsData
            .filter((row) => {
              // Find rows that have changed or are newly added
              const originalRow = getCategoryData?.rowsData.find(
                (original) => original.id === row.id
              );
              return !originalRow || !isEqual(originalRow, row);
            })
            .map((data) => ({
              id: data.id,
              name: data.name,
              attrId: data.attrId,
              status: data.status,
              chip: data.chip,
              is_system: data.is_system,
            }));

          const deletedRowIds = getCategoryData?.rowsData
            .filter(
              (originalRow) =>
                !values?.rowsData.some((row) => row.attrId === originalRow.attrId)
            )
            .map((deletedRow) => ({
              id: deletedRow?.id,
              name: deletedRow?.name,
              attrId: deletedRow?.attrId,
              status: "Detach", // Add a flag to indicate the row is deleted
              chip: deletedRow?.chip,
              is_system: deletedRow.is_system,
            }));

          const deletedRowIds2 = getCategoryData?.rowsData
            .filter(
              (originalRow) =>
                !rowsData.some((row) => row.attrId === originalRow.attrId)
            )
            .map((deletedRow) => ({
              id: deletedRow?.id,
              name: deletedRow?.name,
              attrId: deletedRow?.attrId,
              status: "Detach", // Add a flag to indicate the row is deleted
              chip: deletedRow?.chip,
              is_system: deletedRow.is_system,
            }));

          // const updatedData = [...updatedRows, ...deletedRowIds];
          const updatedData = [...updatedRows2, ...deletedRowIds2];

          data.associate_attribute = updatedData;
          data.terms = [];
        }
        try {
          setLoading(true);
          const response = await api.post(`/admin/category/update/${id}`, data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          });
          if (response?.success === true) {
            setLoading(false);
            setIsSuccessMessage(true);
            setSuccessMessage(response?.message);
            setIsSuccessMessage(true);
            setSuccessMessage(response?.message);
            Toast.success(response?.message);
            localStorage.setItem("last-redirect-page", `/categories`);
            dispatch(categoryTabAction.handleSelectedTab(0));
            navigate("/categories");
          } else if (response?.success === false) {
            setLoading(false);
            dispatch(categoryTabAction.handleSelectedTab(0));

            setIsWarningMessage(true);
            setWarningMessage(response?.message);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("error in ctreate news api::", err);
          setLoading(false);

          if (err?.response?.status === 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status === 422) {
            setLoading(false);
            setIsWarningMessage(true);
            setWarningMessage(err?.response?.data?.errors?.title);
            setWarningMessage(err?.response?.data?.errors?.terms_users);
            Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
          }
          if (err?.response?.status === 429) {
            Toast.error("Too many attempts wait for 2 min.")
          }
        }
      } else {
        let data = {
          title: values?.categoryTitle,
          category_type_id: categoryType,
          status: values?.categoryStatus === true ? "enable" : "disable",
          append_to:
            categoryAppend === true && categoryType === 1
              ? "enable"
              : categoryAppend == false && categoryType === 1
                ? "disable"
                : "none",
        };
        if (categoryType === "1" || categoryType == 1) {
          const viewUsers = termsUsers?.map((user) => user);
          const usersString = viewUsers.join(",");
          data.terms_users =
            termsUsers?.length > 0 && categoryAppend === true
              ? usersString
              : categoryAppend == false
                ? "none"
                : "";
          data.terms = terms?.map((term) => {
            return {
              id: term?.id,
              value: term?.value,
              status: term?.status,
            };
          });

          data.associate_attribute = [];
        } else if (categoryType === "2" || categoryType === 2) {
          data.terms_users = "none"
          data.associate_attribute = rowsData?.map((data) => {
            return {
              id: data?.id,
              name: data?.name,
              attrId: data?.attrId,
              status: data?.status,
              chip: data?.chip,
              is_system: data.is_system,
            };
          });
          data.terms = [];
        }
        try {
          setLoading(true);
          const response = await api.post("/admin/category/store", data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          });
          if (response?.success === true) {
            setLoading(false);
            setIsSuccessMessage(true);
            setSuccessMessage(response?.message);
            setIsSuccessMessage(true);
            setSuccessMessage(response?.message);
            Toast.success(response?.message);
            localStorage.setItem("last-redirect-page", `/categories`);
            dispatch(categoryTabAction.handleSelectedTab(0));

            navigate("/categories");
          } else if (response?.success === false) {
            setLoading(false);
            dispatch(categoryTabAction.handleSelectedTab(0));
            setIsWarningMessage(true);
            setWarningMessage(response?.message);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("error in ctreate news api::", err);
          setLoading(false);

          if (err?.response?.status === 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status === 422) {
            setLoading(false);
            setIsWarningMessage(true);
            setWarningMessage(err?.response?.data?.errors?.title);
            setWarningMessage(err?.response?.data?.errors?.terms_users);
            Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
          }
          if (err?.response?.status === 429) {
            Toast.error("Too many attempts wait for 2 min.")
          }
        }
      }
    }

  };

  const fetchCategoryDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(`admin/category/show/${id}}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        let categoryData = {
          categoryTitle: response?.response?.title,
          categoryType: response?.response?.category_type_id,
          categoryStatus:
            response?.response?.status === "enable" ? true : false,
          categoryAppend:
            response?.response?.category_type_id === 1 &&
              response?.response?.append_to === "enable"
              ? true
              : response?.response?.category_type_id === 1 &&
                response?.response?.append_to === "disable"
                ? false
                : false,
          users:
            response?.response?.category_type_id === 1 &&
              response?.response?.terms_users === "all_registered"
              ? ["all_registered"]
              : response?.response?.category_type_id === 1 &&
                response?.response?.terms_users === "selected"
                ? response?.response?.category_terms_user?.map(
                  (data) => data?.user_id
                )
                : [],
          terms:
            response?.response?.category_type_id === 1 &&
              response?.response?.categoryterm?.length > 0
              ? response?.response?.categoryterm?.map((term) => {
                return {
                  id: term?.id,
                  label: term?.name,
                  value: term?.name,
                  status: term?.status,
                  date: term?.created_at,
                  used: term?.post_category_terms_count + term?.user_category_terms_count,
                  addedOnEdit: false,
                };
              })
              : [],
          rowsData: response?.response?.category_high_attributes?.map(
            (data) => {
              return {
                id: data?.fe_highlightId,
                name: data?.name,
                attrId: data?.attrId,
                status: data?.status,
                chip: data?.chip,
                is_system: data.is_system,
              };
            }
          ),
          selectAttributes: response?.response?.category_high_attributes?.map(
            (data) => {
              return {
                value: data?.attrId,
                label: data?.name,
                isdetach: data?.status !== "Detach" ? false : true,
                status: data?.status,
                chip: data?.chip,
                is_system: data.is_system,
              };
            }
          ),
        };
        setPreviousSelectedOptions(
          response?.response?.category_high_attributes?.map((data) => {
            return {
              value: data?.attrId,
              label: data?.name,
              isdetach: data?.status !== "Detach" ? false : true,
              status: data?.status,
              chip: data?.chip,
              is_system: data.is_system,
            };
          })
        );
        setCategoryType(response?.response?.category_type_id)
        setCategoryData(categoryData);
        setCategoryAppend(categoryData?.categoryAppend);
        setTermsUsers(categoryData?.users);
        setTerms(categoryData?.terms);
        setRowsData(categoryData?.rowsData)
        setFinalTerms(categoryData?.terms);
        setExistingTerms(categoryData?.terms);
        setLoading(false);
      } else if (response?.success === false) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/categories`);

        navigate("/categories");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);

        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);

        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        setWarningMessage(err?.response?.data?.message);
        Toast.warning(err?.response?.data?.message);
        localStorage.setItem("last-redirect-page", `/categories`);

        navigate("/categories");
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
    }
  };

  const fetchCategoryTypes = async () => {
    try {
      setLoading(true);
      const response = await api.get(`admin/category-type-list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setGetAllCategoryTypes(response?.response);
      setLoading(false);
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const fetchAllUser = async () => {
    try {
      const response = await api.get(`users-list?pagination=0`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        let users = response?.response?.data?.map((user) => ({
          value: user?.id,
          label: user?.name,
        }));
        let allusers = [
          {
            value: "all_registered",
            label: "All Registered",
          },
          ...users,
        ];
        setGetAllUsers(allusers);
      }
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const fetchAllAttributes = async (type, order) => {
    try {
      const response = await api.get(`admin/attribute-list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        const data = response?.response?.map((attributeData) => {
          return {
            value: attributeData?.id,
            label: attributeData?.title,
            isdetach: false,
            status: "Mandatory",
            chip: attributeData?.chip,
            is_system: attributeData?.is_system,
          };
        });
        const systemAttributes = data.filter((attr) => attr.is_system === 1);
        const addedAttributes = data.filter((attr) => attr.is_system === 0);

        const allAttributes = [
          { label: "Added Attributes", options: addedAttributes },
          { label: "System Attributes", options: systemAttributes },
        ];
        setGetAllAttributes(allAttributes);
      } else if (response?.success === false) {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const searchTerms = () => {
    // Convert the value to lowercase for case-insensitive search
    const query = termSearch?.toLowerCase();

    // Filter the terms array based on label or value containing the query
    if (tabValue === 0) {
      const updatedRowsData = terms?.map((data) => data);
      const searchResults = updatedRowsData?.filter(
        (term) =>
          term?.label?.toLowerCase()?.includes(query) ||
          term?.value?.toLowerCase()?.includes(query)
      );

      setFinalTerms(searchResults);
    } else if (tabValue === 1) {
      const updatedRowsData = terms?.filter(
        (data) => data?.status !== "disable" && data?.status !== "deleted"
      );
      const searchResults = updatedRowsData?.filter(
        (term) =>
          term?.label?.toLowerCase()?.includes(query) ||
          term?.value?.toLowerCase()?.includes(query)
      );

      setFinalTerms(searchResults);
    } else if (tabValue === 2) {
      const updatedRowsData = terms?.filter(
        (data) => data?.status !== "enable" && data?.status !== "deleted"
      );
      const searchResults = updatedRowsData?.filter(
        (term) =>
          term?.label?.toLowerCase()?.includes(query) ||
          term?.value?.toLowerCase()?.includes(query)
      );

      setFinalTerms(searchResults);
    } else if (tabValue === 3) {
      const updatedRowsData = terms?.filter(
        (data) => data?.status === "deleted"
      );
      const searchResults = updatedRowsData?.filter(
        (term) =>
          term?.label?.toLowerCase()?.includes(query) ||
          term?.value?.toLowerCase()?.includes(query)
      );

      setFinalTerms(searchResults);
    }
  };

  const fetchAllChangeLogs = async (page) => {
    try {
      setLoading(true);
      const response = await api.get(
        `admin/category/changelog/${parseInt(
          id
        )}}?per_page=${rowsPerPage}&page=${page}&search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        response.response.data = response?.response?.data.map((log) => ({
          ...log,
          changed_at: dateWithTime(log.changed_at, true, true, true) || log.changed_at,
        }));
        setGetAllChangeLogs(response?.response);

        setLoading(false);
      } else if (response?.success === false) {
        setLoading(false);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
    }
  };

  const fetchAllChangeLogsForExportCSV = async (page, pagination) => {
    try {
      setLoading(true);

      const result = await exportXlsx(`admin/category/changelog/${parseInt(id
      )}}?per_page=${rowsPerPage}&page=${page}&search=${search}&pagination=no`, 'category_change_log');
      if (!result) {
        Toast.error("Failed to download file");
      }
    } catch (err) {
      console.error("Error while downloading csv changelog::", err);
      Toast.error("Failed to download file");
    } finally {
      setLoading(false);
    }
  };

  const deleteCategoryAPI = async () => {
    let data = {
      id: id,
    };
    try {
      setLoading(true);
      const response = await api.post(`admin/category/delete `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success) {
        setLoading(false);
        setOpenDeleteTypeAlert(false)
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        navigate("/categories")
      }
      else {
        setLoading(false)
        Toast.warning(response?.message)
        setIsWarningMessage(true)
        setWarningMessage(response?.message)
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    const apiCall = async () => {
      if (id) {
        await fetchCategoryTypes();

        await fetchAllUser();

        await fetchAllAttributes();
        await fetchCategoryDetails();
        await fetchAllChangeLogs(page + 1);
        setTabValue(0);
      } else {
        await fetchCategoryTypes();
        await fetchAllUser();

        await fetchAllAttributes();
      }
    };
    apiCall();
  }, [id]);

  useEffect(() => {
    if (search.length >= 3 && istype == true) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        setPage(0);
        fetchAllChangeLogs(page);
      }, debounceTimeout);
    }
    else if (search === "" && istype == true) {
      setPage(0);
      fetchAllChangeLogs(page);
    }
    else {
      clearTimeout(debounceTimer);
    }

    return () => clearTimeout(debounceTimer);
  }, [search]);

  useEffect(() => {
    if (termSearch !== "" && istermSearchTyping === true) {
      searchTerms();
    } else if (termSearch === "" && istermSearchTyping === true) {
      if (tabValue == 0) {
        const updatedRowsData = terms?.map((data) => data);
        setFinalTerms(updatedRowsData);
      } else if (tabValue === 1) {
        const updatedRowsData = terms?.filter(
          (data) => data?.status !== "disable" && data?.status !== "deleted"
        );
        setFinalTerms(updatedRowsData);
      } else if (tabValue === 2) {
        const updatedRowsData = terms?.filter(
          (data) => data?.status !== "enable" && data?.status !== "deleted"
        );
        setFinalTerms(updatedRowsData);
      } else if (tabValue === 3) {
        const updatedRowsData = terms?.filter(
          (data) => data?.status === "deleted"
        );
        setFinalTerms(updatedRowsData);
      }
    }
  }, [termSearch, handleChangeTab]);

  useEffect(() => {
    const allUsers = getAllUsers
      ?.filter((option) => option?.value === "all_registered")
      .map((option) => option.value);
    setTermsUsers(allUsers);
  }, [getAllUsers]);

  return (
    <>
      <div
        className={`main-content-wrapper-2 ${open === true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          {isSuccessMessage && (
            <SuccessAlert
              message={successMessage}
              onClose={handleCloseSuccessMessage}
            />
          )}

          {isWarningMessage && (
            <WarningAlert
              message={warningMessage}
              onClose={handleCloseWarningMessage}
            />
          )}
          <Box className="">
            <Stack spacing={2}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Link
                  underline="hover"
                  key="1"
                  color="inherit"
                  href="/"
                  component="h3"
                >
                  Admin
                </Link>
                <Typography
                  key="3"
                  component="h3"
                  color="inherit"
                  className="cursor-pointer"
                  onClick={() => {
                    localStorage.setItem("last-redirect-page", `/categories`);

                    navigate("/categories");
                  }}
                >
                  Categories
                </Typography>
                <Typography key="3" component="h3" color="text.primary">
                  {id ? "Edit" : "Add"} Category
                </Typography>
              </Breadcrumbs>
            </Stack>
          </Box>
          <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
          <Formik
            enableReinitialize
            validateOnChange
            initialValues={initialValues}
            validationSchema={addNewCategoryvalidationSchema}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {({
              values,
              setFieldValue,
              touched,
              errors,
              handleSubmit,
              handleBlur,
              isValid,
              dirty,
              handleChange,
            }) => {
              const handleChangeForCategoryAppend = (event) => {
                setCategoryAppend(event.target.checked);
                setFieldValue("categoryAppend", event.target.checked);
              };
              const habdleAddTerms = () => {
                const normalizedNewTerms = newTerms
                  ?.filter((data) => data?.__isNew__ === true)
                  .map((data) => {
                    const normalizedLabel = data?.label.replace(/\s+/g, ' ').trim();
                    const normalizedValue = data?.value.replace(/\s+/g, ' ').trim();
                    return {
                      id: null,
                      label: normalizedLabel,
                      value: normalizedValue,
                      status: "enable",
                      date: getFormattedDateTime(),
                      used: 0,
                      addedOnEdit: true,
                    };
                  });

                // Remove duplicates by comparing the normalized label or value
                const newTermsData = normalizedNewTerms.filter((newTerm, index, self) =>
                  index === self.findIndex((term) =>
                    term.label.toLowerCase() === newTerm.label.toLowerCase()
                  )
                );

                // Update the final terms state with the new terms
                setFinalTerms((prevFinalTerms) => [
                  ...prevFinalTerms,
                  ...newTermsData,
                ]);

                setNewTerms([]);
                setTerms((prevFinalTerms) => [
                  ...prevFinalTerms,
                  ...newTermsData,
                ]);
                setExistingTerms((prevFinalTerms) => [
                  ...prevFinalTerms,
                  ...newTermsData,
                ]);

                // Update the form field value with the updated terms
                setFieldValue("terms", [...values.terms, ...newTermsData]);
              };

              const handleChangeForCatAppend = (event) => {
                setFieldValue("categoryAppend", event.target.checked);
                existingCatAppend = values?.categoryAppend;
              };

              const handleAddAttributes = () => {
                const attributes = values?.selectAttributes?.map(
                  (attribute, index) => ({
                    id: index + 1, // Use the id counter for each attribute
                    name: attribute.label,
                    attrId: attribute.value,
                    status: attribute.status || "Mandatory",
                    chip: attribute?.chip,
                    is_system: attribute.is_system,
                  })
                );
                setFieldValue("rowsData", attributes);
                setRowsData(attributes)
                handleCloseAddattributeModel();
              };

              const handleRowDataChange = (
                id,
                field,
                value,
                attId,
                oldStatus
              ) => {
                let updatedRowsData;
                const detchRecords = rowsData?.filter(
                  (rows) => rows?.status !== "Detach"
                );
                handleClose();
                if (detchRecords?.length < 2 && value === "Detach") {
                  setIsWarningMessage(true);
                  setWarningMessage("You need to assign minimum one attribute");
                  return Toast.error(
                    "You need to assign minimum one attribute"
                  );
                }
                if (value === "Detach") {
                  let updatedSelectedAttributes;
                  let updatedRowsData;
                  updatedRowsData = rowsData
                    .filter((row) => row.id !== id)
                    .map((attribute, index) => ({
                      id: index + 1, // Use the id counter for each attribute
                      name: attribute.name,
                      attrId: attribute.attrId,
                      status: attribute.status,
                      chip: attribute?.chip,
                      is_system: attribute.is_system,
                    }));

                  updatedSelectedAttributes = values?.selectAttributes.map(
                    (row, index) =>
                      row.value === attId
                        ? {
                          ...row,
                          isdetach: true,
                          [field]: oldStatus,
                          id: index + 1,
                        }
                        : row
                  );
                  setSelectedAttributes(updatedSelectedAttributes);

                  setFieldValue("rowsData", updatedRowsData);
                  setRowsData(updatedRowsData)
                  updatedSelectedAttributes = values?.selectAttributes?.filter(
                    (attributes) => attributes?.value !== attId
                  );
                  setFieldValue("selectAttributes", updatedSelectedAttributes);
                  setPreviousSelectedOptions(updatedSelectedAttributes);

                  handleClose();
                  handleCloseAlertDeleteModel();
                  setLoading(false);
                } else {
                  updatedRowsData = rowsData.map((row, index) =>
                    row.id === id ? { ...row, [field]: value } : row
                  );
                  setFieldValue("rowsData", updatedRowsData);
                  setRowsData(updatedRowsData)
                  let updatedSelectedAttributes;
                  updatedSelectedAttributes = values?.selectAttributes.map(
                    (row, index) =>
                      row.value === attId ? { ...row, [field]: value } : row
                  );
                  setFieldValue("selectAttributes", updatedSelectedAttributes);
                  setPreviousSelectedOptions(updatedSelectedAttributes);
                  setLoading(false);
                  handleClose();
                  handleCloseAlertDeleteModel();
                }
                handleClose();
                handleCloseAlertDeleteModel();
              };

              const handleDragEnd = (result) => {
                if (!result.destination) {
                  return;
                }

                const updatedRowsData = Array.from(rowsData);
                const [removed] = updatedRowsData.splice(
                  result.source.index,
                  1
                );
                updatedRowsData.splice(result.destination.index, 0, removed);

                // Update the IDs based on the new positions
                const updatedRowsWithNewIds = updatedRowsData.map(
                  (row, index) => ({
                    ...row,
                    id: index + 1, // Assign new IDs based on the index position (+1 to start from 1)
                  })
                );

                setFieldValue("rowsData", updatedRowsWithNewIds);
                setRowsData(updatedRowsWithNewIds)
              };

              const handleSaveChanges = () => {
                // Update the corresponding data in finalTerms based on the changes
                // For example, find the term with the matching category ID and update its properties
                const trimmedTitle = values?.termName.trim();
                const updatedFinalTerms = terms.map((term) => {
                  if (term.id === categoryId?.id) {
                    return {
                      ...term,
                      label: trimmedTitle || term.lable, // Update title if it's not empty, otherwise keep the original title
                      value: trimmedTitle || term.lable,
                      status:
                        values?.termStatus == true
                          ? "enable"
                          : "disable" || term.status, // Update active status if it's not empty, otherwise keep the original status
                    };
                  }
                  return term; // Return unchanged term if it doesn't match the category ID
                });

                // Set the updated finalTerms state
                setFinalTerms(updatedFinalTerms);
                setTerms(updatedFinalTerms);
                setFieldValue("terms", updatedFinalTerms);

                // Close the dialog
                handleCloseEditattributeModel();
                if (tabValue === 0) {
                  const updatedRowsData = updatedFinalTerms?.map(
                    (data) => data
                  );
                  setFinalTerms(updatedRowsData);
                } else if (tabValue === 1) {
                  const updatedRowsData = updatedFinalTerms?.filter(
                    (data) =>
                      data?.status !== "disable" && data?.status !== "deleted"
                  );
                  setFinalTerms(updatedRowsData);
                } else if (tabValue === 2) {
                  const updatedRowsData = updatedFinalTerms?.filter(
                    (data) =>
                      data?.status !== "enable" && data?.status !== "deleted"
                  );
                  setFinalTerms(updatedRowsData);
                } else if (tabValue === 3) {
                  const updatedRowsData = updatedFinalTerms?.filter(
                    (data) => data?.status === "deleted"
                  );
                  setFinalTerms(updatedRowsData);
                }
              };

              const handleDeleteTerm = (id, status) => {
                const enableRecords = terms?.filter(
                  (data) => data?.status === "enable" && id !== data?.id
                );
                const inActiveRecord = terms?.filter(
                  (data) => data?.status === "disabled"
                );

                // Update the corresponding data in finalTerms based on the changes
                // For example, find the term with the matching category ID and update its properties

                if (enableRecords?.length < 1 && terms?.length > 0) {
                  setIsWarningMessage(true);
                  setWarningMessage(
                    "You need to maintain minimum one active term"
                  );
                  return Toast.error(
                    "You need to maintain minimum one active term"
                  );
                }

                const updatedFinalTerms = terms.map((term) => {
                  if (term.id === id) {
                    return {
                      ...term,
                      status: "deleted" || term.status, // Update active status if it's not empty, otherwise keep the original status
                    };
                  }
                  return term; // Return unchanged term if it doesn't match the category ID
                });

                // Set the updated finalTerms state
                setFinalTerms(updatedFinalTerms);
                setTerms(updatedFinalTerms);
                setFieldValue("terms", updatedFinalTerms);

                handleCloseEditattributeModel();
                handleCloseForTerms();
                if (tabValue === 0) {
                  const updatedRowsData = updatedFinalTerms?.map(
                    (data) => data
                  );
                  setFinalTerms(updatedRowsData);
                } else if (tabValue === 1) {
                  const updatedRowsData = updatedFinalTerms?.filter(
                    (data) =>
                      data?.status !== "disable" && data?.status !== "deleted"
                  );
                  setFinalTerms(updatedRowsData);
                } else if (tabValue === 2) {
                  const updatedRowsData = updatedFinalTerms?.filter(
                    (data) =>
                      data?.status !== "enable" && data?.status !== "deleted"
                  );
                  setFinalTerms(updatedRowsData);
                } else if (tabValue === 3) {
                  const updatedRowsData = updatedFinalTerms?.filter(
                    (data) => data?.status === "deleted"
                  );
                  setFinalTerms(updatedRowsData);
                }
                // Close the dialog
              };

              const changeCategoryStatus = (status) => {
                const enableRecords = terms?.filter(
                  (data) => data?.status === "enable"
                );
                if (enableRecords?.length < 2 && status === "disable") {
                  setIsWarningMessage(true);
                  setWarningMessage(
                    "You need to maintain minimum one active term"
                  );
                  return Toast.error(
                    "You need to maintain minimum one active term"
                  );
                }
                if (enableRecords?.length < 1 && status === "disable") {
                  setIsWarningMessage(true);
                  setWarningMessage(
                    "You need to maintain minimum one active term"
                  );
                  return Toast.error(
                    "You need to maintain minimum one active term"
                  );
                }
                const updatedFinalTerms = terms.map((term) => {
                  if (term.id === categoryId?.id) {
                    return {
                      ...term,

                      status: status || term.status, // Update active status if it's not empty, otherwise keep the original status
                    };
                  }
                  return term; // Return unchanged term if it doesn't match the category ID
                });

                // Set the updated finalTerms state
                setFinalTerms(updatedFinalTerms);
                setTerms(updatedFinalTerms);
                setFieldValue("terms", updatedFinalTerms);

                handleCloseEditattributeModel();
                handleCloseForTerms();
                if (tabValue === 0) {
                  const updatedRowsData = updatedFinalTerms?.map(
                    (data) => data
                  );
                  setFinalTerms(updatedRowsData);
                } else if (tabValue === 1) {
                  const updatedRowsData = updatedFinalTerms?.filter(
                    (data) =>
                      data?.status !== "disable" && data?.status !== "deleted"
                  );
                  setFinalTerms(updatedRowsData);
                } else if (tabValue === 2) {
                  const updatedRowsData = updatedFinalTerms?.filter(
                    (data) =>
                      data?.status !== "enable" && data?.status !== "deleted"
                  );
                  setFinalTerms(updatedRowsData);
                } else if (tabValue === 3) {
                  const updatedRowsData = updatedFinalTerms?.filter(
                    (data) => data?.status === "deleted"
                  );
                  setFinalTerms(updatedRowsData);
                }
              };

              const RestoreCategory = (id) => {
                const updatedFinalTerms = terms.map((term) => {
                  if (term.id === id) {
                    // Update status to "enable" if the term ID matches the provided ID
                    return {
                      ...term,
                      restore: true,
                      status: "enable",
                    };
                  }
                  // Return unchanged term if it doesn't match the category ID
                  return term;
                });

                // Set the updated finalTerms state
                setFinalTerms(updatedFinalTerms);
                setTerms(updatedFinalTerms);
                setFieldValue("terms", updatedFinalTerms);

                handleCloseEditattributeModel();
                handleCloseForTerms();
                if (tabValue === 0) {
                  const updatedRowsData = updatedFinalTerms?.map(
                    (data) => data
                  );
                  setFinalTerms(updatedRowsData);
                } else if (tabValue === 1) {
                  const updatedRowsData = updatedFinalTerms?.filter(
                    (data) =>
                      data?.status !== "disable" && data?.status !== "deleted"
                  );
                  setFinalTerms(updatedRowsData);
                } else if (tabValue === 2) {
                  const updatedRowsData = updatedFinalTerms?.filter(
                    (data) =>
                      data?.status !== "enable" && data?.status !== "deleted"
                  );
                  setFinalTerms(updatedRowsData);
                } else if (tabValue === 3) {
                  const updatedRowsData = updatedFinalTerms?.filter(
                    (data) => data?.status === "deleted"
                  );
                  setFinalTerms(updatedRowsData);
                }
              };

              const removeTermsData = (label) => {
                const enableRecords = terms?.filter(
                  (data) => data?.status === "enable"
                );
                if (enableRecords?.length < 2) {
                  setIsWarningMessage(true);
                  setWarningMessage(
                    "You need to maintain minimum one active term"
                  );
                  return Toast.error(
                    "You need to maintain minimum one active term"
                  );
                }
                if (enableRecords?.length < 1) {
                  setIsWarningMessage(true);
                  setWarningMessage(
                    "You need to maintain minimum one active term"
                  );
                  return Toast.error(
                    "You need to maintain minimum one active term"
                  );
                }
                const updatedFinalTerms = terms.filter(
                  (term) => term?.label !== label
                );
                setFinalTerms(updatedFinalTerms);
                setTerms(updatedFinalTerms);
                setExistingTerms(updatedFinalTerms);
                setFieldValue("terms", updatedFinalTerms);

                handleCloseEditattributeModel();
                handleCloseForTerms();
                if (tabValue === 0) {
                  const updatedRowsData = updatedFinalTerms?.map(
                    (data) => data
                  );
                  setFinalTerms(updatedRowsData);
                } else if (tabValue === 1) {
                  const updatedRowsData = updatedFinalTerms?.filter(
                    (data) =>
                      data?.status !== "disable" && data?.status !== "deleted"
                  );
                  setFinalTerms(updatedRowsData);
                } else if (tabValue === 2) {
                  const updatedRowsData = updatedFinalTerms?.filter(
                    (data) =>
                      data?.status !== "enable" && data?.status !== "deleted"
                  );
                  setFinalTerms(updatedRowsData);
                } else if (tabValue === 3) {
                  const updatedRowsData = updatedFinalTerms?.filter(
                    (data) => data?.status === "deleted"
                  );
                  setFinalTerms(updatedRowsData);
                }
              };

              const handleKeyDown = (e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent default form submission behavior on Enter key press
                }
              };
              return (
                <Form onKeyDown={handleKeyDown}>
                  <Box className="publish-bar-box">
                    <Box className="delete-div-box">

                      {id && (
                        <Box className="d-flex gap-2 align-items-center justify-content-center cursor-pointer" onClick={() => {
                          setOpenDeleteTypeAlert(true);
                        }}>
                          <FontAwesomeIcon icon={faTrash} className="delete-div-box-icon" />
                          <Typography className="delete-div-box-text">Delete</Typography>
                        </Box>
                      )}

                    </Box>
                    <Box className="last-div-box">
                      <Box className="publish-btn-div-box">
                        <Button
                          className="btn primary-btn w-100"
                          type="submit"
                          onClick={() => {
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="d-md-block d-sm-none d-none"
                          />
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className="d-md-none d-sm-block"
                          />
                          Publish
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  <Row className="mt-1 form-row">
                    <Col lg="12" md="12" className="form-col">
                      <Stack direction="row" className="gap-30">
                        <Accordion
                          defaultActiveKey={["0", "1", "2", "3"]}
                          alwaysOpen
                          className="w-100"
                        >
                          <Accordion.Item
                            eventKey="0"
                            className="bg-white card-wrapper w-100"
                          >
                            <Accordion.Header className="bg-white fw-700 primary-text">
                              Content
                            </Accordion.Header>
                            <Accordion.Body>
                              <Row>
                                <Col lg="12">
                                  <Typography className="fw-400 dark-text">
                                    Title
                                    <span className="required">*</span>
                                  </Typography>
                                  <TextField
                                    className="input-field mt-2"
                                    fullWidth
                                    id="outlined-basic username"
                                    variant="outlined"
                                    size="small"
                                    name="categoryTitle"
                                    placeholder="Enter title here..."
                                    onChange={(event) => {
                                      setFieldValue(
                                        "categoryTitle",
                                        event.target.value
                                      );
                                    }}
                                    value={values?.categoryTitle}
                                    helperText={
                                      touched.categoryTitle &&
                                      errors.categoryTitle
                                    }
                                    error={
                                      touched.categoryTitle &&
                                      Boolean(errors.categoryTitle)
                                    }
                                  />
                                </Col>
                                <Col lg="12">
                                  <Stack direction="row" gap={2}>
                                    <div className="d-flex flex-wrap align-items-center gap-3">
                                      <Typography
                                        className="fw-400 dark-text font-inter "
                                        whiteSpace="nowrap"
                                      >
                                        Enable Category
                                      </Typography>
                                      <BootstrapTooltip
                                        title="You can enable/disable group."
                                        placement="bottom"
                                      >
                                        <FontAwesomeIcon
                                          icon={faCircleInfo}
                                          className="mandatory-icon fw-900"
                                          aria-haspopup="true"
                                        />
                                      </BootstrapTooltip>
                                    </div>
                                    <Stack
                                      direction="row"
                                      spacing={1}
                                      alignItems="center"
                                      className="h-100"
                                    >
                                      <AntSwitch
                                        name="categoryStatus"
                                        checked={values?.categoryStatus}
                                        onChange={(event) => {
                                          setFieldValue(
                                            "categoryStatus",
                                            event.target.checked
                                          );
                                        }}
                                        inputProps={{
                                          "aria-label": "ant design",
                                        }}
                                      />
                                    </Stack>
                                  </Stack>
                                </Col>
                                <Col lg="12">
                                  <Typography className="fw-400 dark-text font-inter">
                                    Category Type
                                    <span className="required">*</span>
                                  </Typography>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="categoryType"
                                    className="mt-1"
                                    value={categoryType}
                                    onChange={handleCategoryChange}
                                  >
                                    {getAllCategoryTypes?.map((category) => (
                                      <FormControlLabel
                                        key={category?.id}
                                        value={category?.id}
                                        control={
                                          <Radio
                                            size="small"
                                            checked={
                                              categoryType ==
                                              category?.id
                                            }
                                            value={category?.id}
                                          />
                                        }
                                        label={category?.name}
                                        className="font-inter"
                                      />
                                    ))}
                                  </RadioGroup>
                                  {errors?.categoryType &&
                                    touched.categoryType && (
                                      <p
                                        style={{ color: "#d32f2f" }}
                                        className="error-msg"
                                      >
                                        {errors?.categoryType}
                                      </p>
                                    )}
                                </Col>
                                {categoryType == 1 && (
                                  <>
                                    <Col lg="12" className="repeatable-col">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={categoryAppend == true}
                                            name="categoryAppend"
                                            onChange={
                                              handleChangeForCategoryAppend
                                            }
                                          />
                                        }
                                        label="This category can be appended to"
                                        className="font-inter"
                                      />
                                    </Col>
                                    {categoryAppend == true && (
                                      <Col lg="12" className="repeatable-col">
                                        <Typography className="fw-400 dark-text">
                                          Select users who can append{" "}
                                          <span>
                                            <strong>terms</strong>
                                          </span>{" "}
                                          into this{" "}
                                          <span>
                                            <strong>category</strong>
                                          </span>
                                        </Typography>
                                        <Select
                                          closeMenuOnSelect={false}
                                          options={getAllUsers}
                                          isMulti={
                                            termsUsers != "all_registered"
                                          }
                                          menuPlacement="bottom"
                                          name="users"
                                          placeholder="Select users..."
                                          menuPosition="fixed"
                                          onChange={(selectedOption) => {
                                            if (Array.isArray(selectedOption)) {
                                              // Check if selectedOption is an array
                                              const users = selectedOption.map(
                                                (option) => option.value
                                              );

                                              if (
                                                users?.includes(
                                                  "all_registered"
                                                )
                                              ) {
                                                setFieldValue("users", [
                                                  "all_registered",
                                                ]);
                                                setTermsUsers([
                                                  "all_registered",
                                                ]);
                                              } else {
                                                setFieldValue("users", [
                                                  ...users,
                                                ]);
                                                setTermsUsers([...users]);
                                              }
                                            } else {
                                              // Handle single option selection
                                              if (
                                                selectedOption?.value ===
                                                "all_registered"
                                              ) {
                                                // If "all_users" is selected, set IRViewUsers to ["all_registered"]
                                                setFieldValue("users", [
                                                  selectedOption.value,
                                                ]);
                                                setTermsUsers([
                                                  selectedOption.value,
                                                ]);
                                              } else {
                                                // If a single user is selected, set IRViewUsers to [loggedUser's ID, selected user's ID]
                                                setFieldValue(
                                                  "users",
                                                  selectedOption
                                                    ? [selectedOption.value]
                                                    : []
                                                );
                                                setTermsUsers(
                                                  selectedOption
                                                    ? [selectedOption.value]
                                                    : []
                                                );
                                              }
                                            }
                                          }}
                                          value={getAllUsers?.filter((option) =>
                                            termsUsers?.includes(option?.value)
                                          )}
                                          className="mt-2 z-index-100"
                                          classNamePrefix="select"
                                          styles={{
                                            option: (provided, state) => ({
                                              ...provided,
                                              backgroundColor: state.isSelected
                                                ? "#660c605c"
                                                : provided.backgroundColor,
                                              color: state.isSelected
                                                ? "var(--dark-color)"
                                                : provided.color,
                                            }),
                                            multiValueLabel: (
                                              provided,
                                              state
                                            ) => {
                                              return {
                                                ...provided,
                                                color:
                                                  state.data?.is_system === 1
                                                    ? "rgb(0, 135, 90)"
                                                    : "rgb(0, 82, 204)",
                                              };
                                            },
                                          }}
                                        />

                                        {termsUsers?.length < 1 && (
                                          <p
                                            style={{ color: "#d32f2f" }}
                                            className="error-msg"
                                          >
                                            You need to select atleast one user
                                          </p>
                                        )}
                                      </Col>
                                    )}
                                  </>
                                )}
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                          {categoryType == 1 && (
                            <Accordion.Item
                              eventKey="1"
                              className="bg-white card-wrapper w-100 mt-4"
                            >
                              <Accordion.Header className="bg-white fw-700 primary-text">
                                Terms
                              </Accordion.Header>
                              <Accordion.Body className="p-0">
                                <Grid
                                  container
                                  spacing={1}
                                  className="select-terms-div z-0"
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    sm={12}
                                    lg={9}
                                    xl={9}
                                  >
                                    <CreatableSelect
                                      closeMenuOnSelect={false}
                                      options={existingterms}
                                      isMulti
                                      menuPlacement="bottom"
                                      placeholder="Add new term, separate multiple terms with commas..."
                                      menuPosition="fixed"
                                      className="z-3"
                                      onChange={(selectedOption) => {
                                        const selectedValues =
                                          selectedOption.map((option) => {
                                            const normalizedLabel = option?.label.replace(/\s+/g, ' ').trim();
                                            const normalizedValue = option?.value.replace(/\s+/g, ' ').trim();
                                            return {
                                              id: null,
                                              label: normalizedLabel,
                                              value: normalizedValue,
                                              status: "enable",
                                              date: getFormattedDateTime(),
                                              used: option?.used,
                                              addedOnEdit: true,
                                              ...option,
                                            };
                                          });
                                        setNewTerms(selectedValues);
                                      }}
                                      onKeyDown={(e) => {
                                        if (e.key === ",") {
                                          e.preventDefault();
                                          // Get the value from the input field
                                          const inputValue = e.target.value.replace(/\s+/g, ' ').trim();

                                          if (inputValue) {

                                            // Check if the term already exists in the currently selected terms
                                            const existsInNewTerms = newTerms.some(
                                              (term) => term.label.replace(/\s+/g, ' ').toLowerCase() === inputValue.toLowerCase()
                                            );

                                            if (!existsInNewTerms) {
                                              // Create a new option from the input value
                                              const newOption = {
                                                id: null,
                                                label: inputValue,
                                                value: inputValue,
                                                status: "enable",
                                                date: getFormattedDateTime(),
                                                addedOnEdit: true,
                                                used: 0,
                                              };
                                              // Add the new option to the terms array

                                              setNewTerms((prevTerms) => [
                                                ...prevTerms,
                                                newOption,
                                              ]);
                                              // Simulate Enter key press
                                              const enterEvent =
                                                createEvent.keyDown(e.target, {
                                                  key: "Enter",
                                                });
                                              e.target.dispatchEvent(enterEvent);
                                            } else {
                                              e.target.value = '';
                                            }
                                          }
                                        }
                                      }}
                                      value={newTerms}
                                      classNamePrefix="select"
                                      isValidNewOption={(inputValue, selectValue, selectOptions) => {
                                        const trimmedInput = inputValue.replace(/\s+/g, ' ').trim();
                                        // Disable creation of new option if the trimmed value already exists
                                        const existsInExistingTerms = existingterms.some(
                                          (term) => term.value.toLowerCase() === trimmedInput.toLowerCase()
                                        );

                                        const existsInSelectedValues = selectValue.some(
                                          (data) => data.label.toLowerCase() === trimmedInput.toLowerCase()
                                        );

                                        return trimmedInput.length > 0 && !existsInExistingTerms && !existsInSelectedValues;
                                      }}
                                      styles={{
                                        option: (provided, state) => ({
                                          ...provided,
                                          backgroundColor: state.isSelected
                                            ? "#660c605c"
                                            : provided.backgroundColor,
                                          color: state.isSelected
                                            ? "var(--dark-color)"
                                            : provided.color,
                                        }),
                                      }}
                                    />
                                    {errors?.terms && touched.terms && (
                                      <p
                                        style={{ color: "#d32f2f" }}
                                        className="error-msg"
                                      >
                                        {errors?.terms}
                                      </p>
                                    )}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    sm={12}
                                    lg={3}
                                    xl={3}
                                  >
                                    <Button
                                      variant="outlined"
                                      startIcon={
                                        <FontAwesomeIcon icon={faPlus} />
                                      }
                                      className="export-csv-btn d-flex justify-content-center w-100"
                                      onClick={habdleAddTerms}
                                    >
                                      Add Terms
                                    </Button>
                                  </Grid>
                                </Grid>
                                <Box
                                  sx={{
                                    width: "100%",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      borderBottom: 1,
                                      borderColor: "divider",
                                    }}
                                    className="box-attribute"
                                  >
                                    <Tabs
                                      value={tabValue}
                                      onChange={handleChangeTab}
                                      aria-label="basic tabs example"
                                      variant="scrollable"
                                      className={"tabs"}
                                      TabIndicatorProps={{
                                        style: {
                                          backgroundColor: "#660C60",
                                        },
                                      }}
                                    >
                                      <Tab
                                        icon={
                                          <FontAwesomeIcon icon={faFolder} />
                                        }
                                        label="All Terms"
                                        iconPosition="start"
                                        {...a11yProps1(0)}
                                      />

                                      <Tab
                                        label="Active Terms"
                                        icon={
                                          <FontAwesomeIcon icon={faBurst} />
                                        }
                                        iconPosition="start"
                                        {...a11yProps1(1)}
                                      />
                                      <Tab
                                        label="Inactive Terms"
                                        {...a11yProps1(2)}
                                        icon={
                                          <FontAwesomeIcon icon={faCheck} />
                                        }
                                        iconPosition="start"
                                      />
                                      <Tab
                                        label="Deleted Terms"
                                        {...a11yProps1(3)}
                                        icon={
                                          <FontAwesomeIcon icon={faTrash} />
                                        }
                                        iconPosition="start"
                                      />
                                    </Tabs>
                                  </Box>
                                  <CustomTabPanel
                                    value={tabValue}
                                    index={tabValue}
                                  >
                                    <Box className="">
                                      <InputGroup className="">
                                        <TextField
                                          className="search-changelog-input"
                                          fullWidth
                                          id="outlined-basic username"
                                          variant="outlined"
                                          size="small"
                                          name="attributeTitle"
                                          value={termSearch}
                                          placeholder="search Terms"
                                          onChange={(e) => {
                                            setIsTermSearchTyping(true);
                                            setTermSearch(e.target.value);
                                            if (e.target.value === "") {
                                              setFinalTerms(terms);
                                            }
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                              e.preventDefault();
                                              searchTerms();
                                            }
                                          }}
                                        />
                                        <Button
                                          className="search-changelog"
                                          onClick={() => {
                                            searchTerms();
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faSearch}
                                            className="search-changelog-icon"
                                          />
                                        </Button>
                                      </InputGroup>
                                    </Box>
                                    <Paper
                                      sx={{ width: "100%" }}
                                      className="type-list-paper"
                                    >
                                      <TableContainer className="z-0">
                                        <Table
                                          aria-labelledby="tableTitle"
                                          stickyHeader
                                          className="mt-3 z-0"
                                        >
                                          {finalTerms?.length > 0 && (
                                            <>
                                              <TableHeaderForSorting
                                                columns={
                                                  columns_category_headers_headers
                                                }
                                                key="thead"
                                              />
                                              <TableBody>
                                                {visibleRows?.map(
                                                  (row, index) => (
                                                    <>
                                                      <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={index + 1}
                                                        className="cursor-pointer z-0"
                                                      >
                                                        <TableCell
                                                          align="left"
                                                          className={`tab-body-cel-terms-cell tbl-border-left`}
                                                        >
                                                          <div
                                                            className=""
                                                            style={{
                                                              fontSize: "14px",
                                                            }}
                                                          >
                                                            <p
                                                              className={`${row?.status ===
                                                                "deleted" &&
                                                                "text-muted fw-400"
                                                                } fw-400`}
                                                            >
                                                              {row?.label}
                                                            </p>
                                                          </div>
                                                        </TableCell>
                                                        <TableCell
                                                          align="left"
                                                          className={`tab-body-cel-terms-cell`}
                                                        >
                                                          <div
                                                            className=""
                                                            style={{
                                                              fontSize: "14px",
                                                            }}
                                                          >
                                                            <Typography className="fw-400 fs-14 ms-2">
                                                              {row?.used}
                                                            </Typography>
                                                          </div>
                                                        </TableCell>
                                                        <TableCell
                                                          align="left"
                                                          className={`tab-body-cel-terms-cell`}
                                                        >
                                                          <div
                                                            className=""
                                                            style={{
                                                              fontSize: "14px",
                                                            }}
                                                          >
                                                            <Typography className="fw-400 fs-14 ms-1">
                                                              {row?.date}
                                                            </Typography>
                                                          </div>
                                                        </TableCell>
                                                        <TableCell
                                                          align="left"
                                                          className="tab-body-cel-terms-cell"
                                                        >
                                                          <Box className="ms-1">
                                                            <Stack
                                                              direction="row"
                                                              alignContent="center"
                                                              alignItems="center"
                                                              spacing={1}
                                                              className="z-n1"
                                                            >
                                                              <Avatar
                                                                alt="Admin"
                                                                src="/static/images/avatar/1.jpg"
                                                                className="author-avtar"
                                                              />
                                                              <Typography className="fw-400 fs-14-muted">
                                                                Admin
                                                              </Typography>
                                                            </Stack>
                                                          </Box>
                                                        </TableCell>
                                                        <TableCell
                                                          align="center"
                                                          className="tab-body-cel-terms-cell-2 tbl-border-right"
                                                        >
                                                          <div>
                                                            <Stack
                                                              direction="row"
                                                              alignContent="center"
                                                              alignItems="center"
                                                              spacing={1}
                                                              className="gap-1"
                                                            >
                                                              <StatusIcon status={row?.status} />

                                                              <IconButton
                                                                aria-label="more"
                                                                id="long-button-1"
                                                                aria-controls={
                                                                  openMenuEditTerms
                                                                    ? "long-menu"
                                                                    : undefined
                                                                }
                                                                aria-expanded={
                                                                  openMenuEditTerms
                                                                    ? "true"
                                                                    : undefined
                                                                }
                                                                aria-haspopup="true"
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  e.stopPropagation(); // Stop event propagation here
                                                                  handleClickForTerms(
                                                                    e,
                                                                    row
                                                                  );
                                                                }}
                                                              >
                                                                <MoreVertIcon />
                                                              </IconButton>
                                                            </Stack>
                                                          </div>
                                                        </TableCell>
                                                      </TableRow>
                                                      <TableRow className="extra-row"></TableRow>
                                                    </>
                                                  )
                                                )}
                                              </TableBody>
                                            </>
                                          )}
                                        </Table>
                                      </TableContainer>
                                      {finalTerms?.length > rowsPerPage && (
                                        <TablePagination
                                          component="div"
                                          count={finalTerms?.length}
                                          page={page}
                                          onPageChange={
                                            handleChangePageForTerms
                                          }
                                          rowsPerPage={rowsPerPage}
                                          onRowsPerPageChange={
                                            handleChangeRowsPerPageForTerms
                                          }
                                          labelDisplayedRows={({
                                            from,
                                            to,
                                            count,
                                          }) => {
                                            const currentPage = page + 1;
                                            const start =
                                              currentPage * rowsPerPage -
                                              rowsPerPage +
                                              1;
                                            const end = Math.min(
                                              currentPage * rowsPerPage,
                                              count
                                            );
                                            return (
                                              <>
                                                {`Showing ${start} to ${end} of `}
                                                <span>{count}</span>
                                                {" records"}
                                              </>
                                            );
                                          }}
                                        />
                                      )}
                                    </Paper>
                                  </CustomTabPanel>
                                </Box>
                              </Accordion.Body>
                            </Accordion.Item>
                          )}
                          {categoryType == 2 && (
                            <Accordion.Item
                              eventKey="2"
                              className="bg-white card-wrapper w-100 mt-4"
                            >
                              <Accordion.Header className="bg-white fw-700 primary-text ">
                                Highlighted Attributes
                              </Accordion.Header>
                              <Accordion.Body>
                                {" "}
                                <Row className="">
                                  <TableContainer className="z-0">
                                    <Table
                                      aria-labelledby="tableTitle"
                                      stickyHeader
                                    >
                                      <TableHeaderForSorting
                                        columns={
                                          columns_types_ack_fields_header
                                        }
                                      />
                                      <DragDropContext
                                        onDragEnd={handleDragEnd}
                                      >
                                        <Droppable droppableId="rowsData">
                                          {(provided) => (
                                            <TableBody
                                              style={{
                                                width: "100%",
                                                top: 0,
                                                left: 0,
                                              }}
                                              {...provided.droppableProps}
                                              ref={provided.innerRef}
                                            >
                                              {rowsData?.length > 0 && (
                                                <>
                                                  {rowsData?.map(
                                                    (rows, index) => (
                                                      <Draggable
                                                        key={rows.id}
                                                        draggableId={rows.id.toString()}
                                                        index={index}
                                                      >
                                                        {(provided) => (
                                                          <>
                                                            {rows?.status !==
                                                              "Detach" && (
                                                                <>
                                                                  <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    tabIndex={-1}
                                                                    key={index}
                                                                    className="cursor-pointer tbl-row-attribute-row"
                                                                    ref={
                                                                      provided.innerRef
                                                                    }
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                  >
                                                                    <TableCell
                                                                      align="left"
                                                                      className={` tbl-border-left tbl-cell-attribute-2`}
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                        top: 0,
                                                                        left: 0,
                                                                      }}
                                                                    >
                                                                      <div className="main-div-icon-text d-flex align-items-center gap-2">
                                                                        <FontAwesomeIcon
                                                                          icon={
                                                                            faGripVertical
                                                                          }
                                                                          className="attribute-td-icon"
                                                                        />
                                                                        <div className="d-flex flex-wrap gap-2 ps-1">
                                                                          <Typography className="fs-14">
                                                                            {
                                                                              rows?.name
                                                                            }
                                                                          </Typography>
                                                                          <Chip
                                                                            label={
                                                                              rows?.chip
                                                                            }
                                                                            className="chip-label-div-2"
                                                                          />
                                                                        </div>
                                                                      </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                      align="right"
                                                                      className={` tbl-border-right tbl-cell-attribute-2`}
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                        top: 0,
                                                                        left: 0,
                                                                      }}
                                                                    >
                                                                      <div className="d-flex fex-wrap justify-content-between align-items-center gap-2 w-100">
                                                                        {rows?.status ===
                                                                          "Mandatory" ? (
                                                                          <Image
                                                                            src={
                                                                              activeType
                                                                            }
                                                                            width={
                                                                              "10px"
                                                                            }
                                                                            height={
                                                                              "10px"
                                                                            }
                                                                            className="ms-5"
                                                                          />
                                                                        ) : (
                                                                          <FontAwesomeIcon
                                                                            icon={
                                                                              faXmark
                                                                            }
                                                                            className={`cross-icon ms-5`}
                                                                          />
                                                                        )}

                                                                        <IconButton
                                                                          aria-label="delete"
                                                                          className="action-btn-icon-btn"
                                                                          color="error"
                                                                          aria-controls={
                                                                            openMenuFoEdit
                                                                              ? "long-menu-1"
                                                                              : undefined
                                                                          }
                                                                          aria-expanded={
                                                                            openMenuFoEdit
                                                                              ? "true"
                                                                              : undefined
                                                                          }
                                                                          aria-haspopup="true"
                                                                          onClick={(
                                                                            e
                                                                          ) => {
                                                                            handleClick(
                                                                              e,
                                                                              rows
                                                                            )
                                                                          }
                                                                          }
                                                                        >
                                                                          <MoreVertIcon className="action-btn" />
                                                                        </IconButton>
                                                                      </div>
                                                                    </TableCell>
                                                                  </TableRow>
                                                                  <TableRow className="extra-row"></TableRow>
                                                                </>
                                                              )}
                                                          </>
                                                        )}
                                                      </Draggable>
                                                    )
                                                  )}
                                                </>
                                              )}
                                              {errors?.rowsData &&
                                                touched.rowsData && (
                                                  <p
                                                    style={{
                                                      color: "#d32f2f",
                                                    }}
                                                    className="error-msg"
                                                  >
                                                    {errors?.rowsData}
                                                  </p>
                                                )}
                                            </TableBody>
                                          )}
                                        </Droppable>
                                      </DragDropContext>
                                    </Table>
                                  </TableContainer>
                                </Row>
                                <div className="mt-4 d-flex flex-wrap justify-content-end">
                                  <Button
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    className="add-option-btn"
                                    onClick={handleopnAddattributeModel}
                                  >
                                    Add Attributes
                                  </Button>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          )}
                          {id && (
                            <Accordion.Item
                              eventKey="3"
                              className="bg-white card-wrapper mt-4 "
                            >
                              <Accordion.Header className="bg-white fw-700 primary-text">
                                Changelog
                              </Accordion.Header>
                              <Accordion.Body className="p-0 changelog-body">
                                <Grid
                                  container
                                  spacing={1}
                                  className="changelog-div"
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    sm={12}
                                    lg={9}
                                    xl={9}
                                  >
                                    <Box className="">
                                      <InputGroup className="">
                                        <TextField
                                          className="search-changelog-input"
                                          fullWidth
                                          id="outlined-basic username"
                                          variant="outlined"
                                          size="small"
                                          name="attributeTitle"
                                          value={search}
                                          placeholder="search changelog"
                                          onChange={(e) => {
                                            setIstype(true);
                                            setSearch(e.target.value);
                                            if (e.target.value === "") {
                                              fetchAllChangeLogs(page);
                                            }
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                              e.preventDefault();
                                              fetchAllChangeLogs(page);
                                            }
                                          }}
                                        />
                                        <Button
                                          className="search-changelog"
                                          onClick={() => {
                                            fetchAllChangeLogs(page);
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faSearch}
                                            className="search-changelog-icon"
                                          />
                                        </Button>
                                      </InputGroup>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    sm={12}
                                    lg={3}
                                    xl={3}
                                  >
                                    <div className="w-100">
                                      <Button
                                        variant="outlined"
                                        startIcon={
                                          <FontAwesomeIcon icon={faFileCsv} />
                                        }
                                        className="export-csv-btn"
                                        onClick={fetchAllChangeLogsForExportCSV}
                                      >
                                        Export as CSV
                                      </Button>
                                    </div>
                                  </Grid>
                                </Grid>
                                <Paper
                                  sx={{ width: "100%" }}
                                  className="changelog-table-div mt-4"
                                >
                                  <TableContainer className="z-0">
                                    <Table
                                      aria-labelledby="tableTitle"
                                      stickyHeader
                                    >
                                      <TableHeaderForSorting
                                        columns={
                                          columns_attribute_changelog_header
                                        }
                                      />
                                      <TableBody>
                                        {getAllChangeLogs?.data?.map((data) => (
                                          <>
                                            <TableRow
                                              hover
                                              role="checkbox"
                                              tabIndex={-1}
                                              className=" tbl-changelog mt-2"
                                            >
                                              <TableCell
                                                align="left"
                                                className="tbl-border-left tbl-cell-5"
                                              >
                                                <Box>
                                                  <Stack
                                                    direction="row"
                                                    alignContent="center"
                                                    alignItems="center"
                                                    spacing={1}
                                                    className="z-n1"
                                                  >
                                                    <Avatar
                                                      alt={data?.changed_by}
                                                      src="/static/images/avatar/1.jpg"
                                                      className="author-avtar"
                                                    />
                                                    <Typography className="fw-400 fs-14 ">
                                                      {data?.changed_by
                                                        ? data?.changed_by
                                                        : "-"}
                                                    </Typography>
                                                  </Stack>
                                                </Box>
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                className="text-nowrap"
                                              >
                                                {data?.changed_at
                                                  ? data?.changed_at
                                                  : "-"}
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                className="text-nowrap"
                                              >
                                                {data?.area ? data?.area : "-"}
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                className="tbl-border-right"
                                              >
                                                <Typography
                                                  className="fw-400 fs-14"
                                                  dangerouslySetInnerHTML={{
                                                    __html: data?.description
                                                      ? processedHTML(
                                                        data?.description
                                                      ).innerHTML
                                                      : "-",
                                                  }}
                                                ></Typography>
                                              </TableCell>
                                            </TableRow>
                                            <TableRow className="extra-row"></TableRow>
                                          </>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Paper>
                                {total_changelog > rowsPerPage && (
                                  <TablePagination
                                    component="div"
                                    count={total_changelog}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={
                                      handleChangeRowsPerPage
                                    }
                                    sx={{
                                      "& .MuiToolbar-root": {
                                        display: "flex",
                                        flexDirection: {
                                          xs: "column",
                                          md: "row",
                                        },
                                      },
                                    }}
                                    labelDisplayedRows={({
                                      from,
                                      to,
                                      count,
                                    }) => {
                                      const currentPage = page + 1;
                                      const start =
                                        currentPage * rowsPerPage -
                                        rowsPerPage +
                                        1;
                                      const end = Math.min(
                                        currentPage * rowsPerPage,
                                        count
                                      );
                                      return (
                                        <>
                                          {`Showing ${start} to ${end} of `}
                                          <span>{count}</span>
                                          {" records"}
                                        </>
                                      );
                                    }}
                                  />
                                )}
                              </Accordion.Body>
                            </Accordion.Item>
                          )}
                        </Accordion>
                      </Stack>
                    </Col>

                  </Row>
                  <Dialog
                    aria-labelledby="customized-dialog-title"
                    open={opnAddattributeModel}
                    maxWidth="sm"
                    fullWidth={true}
                  >
                    <DialogTitle
                      sx={{ m: 0, p: 2 }}
                      id="customized-dialog-title"
                      className="dialog-title"
                    >
                      Add Attribute
                    </DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={handleCloseAddattributeModel}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                      className="alert-close-btn"
                    >
                      <CloseIcon />
                    </IconButton>
                    <DialogContent dividers className="">
                      {" "}
                      <Select
                        closeMenuOnSelect={false}
                        options={getAllAttributes}
                        isMulti
                        menuPlacement="bottom"
                        name="IRNotViewUsers"
                        placeholder="Select attributes..."
                        menuPosition="fixed"
                        onChange={(selectedOption) => {
                          // If id is present, merge new selection with previous selection
                          if (id) {
                            const mergedOptions = [
                              ...previousSelectedOptions, // Retain previous selection
                              ...selectedOption, // Add newly selected options
                            ];
                            // Filter out duplicate options by value
                            const uniqueOptions = mergedOptions.filter(
                              (option, index, self) =>
                                index ===
                                self.findIndex((o) => o.value === option.value)
                            );
                            // Update state with merged and unique options
                            setFieldValue("selectAttributes", uniqueOptions);
                          } else {
                            // If id is not present, update state with new selection only
                            setFieldValue("selectAttributes", selectedOption);
                          }
                        }}
                        value={values?.selectAttributes}
                        className=""
                        classNamePrefix="select"
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#660c605c"
                              : provided.backgroundColor,
                            color: state.isSelected
                              ? "var(--dark-color)"
                              : provided.color,
                          }),
                          multiValueLabel: (provided, state) => {
                            return {
                              ...provided,
                              color:
                                state.data?.is_system === 1
                                  ? "rgb(0, 135, 90)"
                                  : "rgb(0, 82, 204)",
                            };
                          },
                        }}
                      />
                    </DialogContent>
                    <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
                      <Button
                        className="btn delete-btn-model"
                        onClick={handleCloseAddattributeModel}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                        Cancel
                      </Button>
                      <Button
                        className="btn primary-btn"
                        onClick={handleAddAttributes}
                      >
                        <FontAwesomeIcon icon={faFloppyDisk} />
                        Save Changes
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    aria-labelledby="customized-dialog-title"
                    open={openAlertDialog}
                    maxWidth="sm"
                    fullWidth={true}
                  >
                    <DialogTitle
                      sx={{ m: 0, p: 2 }}
                      id="customized-dialog-title"
                      className="dialog-title"
                    >
                      Are you Sure ?
                    </DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={handleCloseAlertDeleteModel}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                      className="alert-close-btn"
                    >
                      <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                      <DialogContentText id="alert-dialog-description">
                        Are you sure want to detached the attribute ?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
                      <Button
                        className="btn delete-btn-model"
                        onClick={handleCloseAlertDeleteModel}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                        No
                      </Button>
                      <Button
                        className="btn primary-btn"
                        onClick={() =>
                          handleRowDataChange(
                            attributeID?.id,
                            "status",
                            "Detach",
                            attributeID?.attrId,
                            attributeID?.status
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faFloppyDisk} />
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    aria-labelledby="customized-dialog-title"
                    open={opnEditattributeModel}
                    maxWidth="sm"
                    fullWidth={true}
                  >
                    <DialogTitle
                      sx={{ m: 0, p: 2 }}
                      id="customized-dialog-title"
                      className="dialog-title"
                    >
                      Edit Terms
                    </DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={handleCloseEditattributeModel}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                      className="alert-close-btn"
                    >
                      <CloseIcon />
                    </IconButton>
                    <DialogContent dividers className="">
                      <Row>
                        <Col lg="12">
                          <Typography className="fw-400 dark-text">
                            Title
                            <span className="required">*</span>
                          </Typography>
                          <TextField
                            className="input-field mt-2"
                            fullWidth
                            id="outlined-basic username"
                            variant="outlined"
                            size="small"
                            name="termName"
                            placeholder="Enter title here..."
                            onChange={(event) => {
                              setFieldValue("termName", event.target.value);
                            }}
                            value={values?.termName}
                          />
                        </Col>
                        <Col lg="12">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values?.termStatus === true}
                                name="termStatus"
                                onChange={handleChange}
                              />
                            }
                            label="Active"
                            className="font-inter"
                          />
                        </Col>
                      </Row>
                    </DialogContent>
                    <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
                      <Button
                        className="btn delete-btn-model"
                        onClick={handleCloseEditattributeModel}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                        Cancel
                      </Button>
                      <Button
                        className="btn primary-btn"
                        onClick={handleSaveChanges}
                      >
                        <FontAwesomeIcon icon={faFloppyDisk} />
                        Save Changes
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Menu
                    anchorEl={anchorElMenu}
                    id="long-menu-1"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    open={openMenuFoEdit}
                    anchorOrigin={"right"}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                        zIndex: "1",
                      },
                    }}
                  >
                    {attributeID?.status == "Mandatory" && (
                      <MenuItem
                        onClick={() => {
                          handleRowDataChange(
                            attributeID?.id,
                            "status",
                            "Optional",
                            attributeID?.attrId,
                            attributeID?.status
                          );
                        }}
                      >
                        Optional
                      </MenuItem>
                    )}
                    {attributeID?.status == "Optional" && (
                      <MenuItem
                        onClick={() =>
                          handleRowDataChange(
                            attributeID?.id,
                            "status",
                            "Mandatory",
                            attributeID?.attrId,
                            attributeID?.status
                          )
                        }
                      >
                        Mandatory
                      </MenuItem>
                    )}
                    {id && (
                      <MenuItem
                        onClick={() => {
                          const detchRecords = rowsData?.filter(
                            (rows) => rows?.status !== "Detach"
                          );
                          handleClose();
                          if (detchRecords?.length < 2) {
                            setIsWarningMessage(true);
                            setWarningMessage(
                              "You need to assign minimum one attribute"
                            );
                            handleClose();
                            return Toast.error(
                              "You need to assign minimum one attribute"
                            );
                          }
                          handleopnAlertDeleteModel();
                        }}
                      >
                        Detach
                      </MenuItem>
                    )}
                  </Menu>
                  <Menu
                    anchorEl={anchorElForTerms}
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button-1",
                    }}
                    open={openMenuEditTerms}
                    anchorOrigin={"right"}
                    onClose={handleCloseForTerms}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                        zIndex: "1",
                      },
                    }}
                  >
                    {categoryId?.status !== "deleted" &&
                      categoryId?.addedOnEdit == false && (
                        <MenuItem
                          onClick={() => {
                            localStorage.setItem(
                              "last-redirect-page",
                              `/categories/edit/1`
                            );

                            handleopnEditattributeModel();
                          }}
                        >
                          Edit
                        </MenuItem>
                      )}

                    {categoryId?.status !== "enable" &&
                      categoryId?.status !== "deleted" &&
                      categoryId?.addedOnEdit == false && (
                        <MenuItem
                          onClick={() => {
                            changeCategoryStatus("enable");
                          }}
                        >
                          Active
                        </MenuItem>
                      )}
                    {categoryId?.status !== "disable" &&
                      categoryId?.status !== "deleted" &&
                      categoryId?.addedOnEdit == false && (
                        <MenuItem
                          onClick={() => {
                            changeCategoryStatus("disable");
                          }}
                        >
                          Inactive
                        </MenuItem>
                      )}
                    {categoryId?.used < 1 &&
                      categoryId?.status !== "deleted" &&
                      categoryId?.addedOnEdit == false && (
                        <MenuItem
                          onClick={() => {
                            handleDeleteTerm(categoryId?.id, "delete");
                          }}
                        >
                          Delete
                        </MenuItem>
                      )}
                    {categoryId?.status === "deleted" &&
                      categoryId?.addedOnEdit == false && (
                        <MenuItem
                          onClick={() => {
                            RestoreCategory(categoryId?.id);
                          }}
                        >
                          Restore
                        </MenuItem>
                      )}
                    {categoryId?.addedOnEdit == true && (
                      <MenuItem
                        onClick={() => {
                          removeTermsData(categoryId?.label);
                        }}
                      >
                        Remove
                      </MenuItem>
                    )}
                  </Menu>
                </Form>
              );
            }}
          </Formik>
        </Container>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openDeleteTypeAlert}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            Are you Sure ?
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDeleteTypeModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent
            dividers
          >
            <DialogContentText id="alert-dialog-description">
              Are you sure want to delete the category ?
            </DialogContentText>
          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
            <Button
              className="btn delete-btn-model"
              onClick={handleCloseDeleteTypeModel}
            >
              <FontAwesomeIcon icon={faXmark} />
              No
            </Button>
            <Button
              className="btn primary-btn"
              onClick={() => {
                deleteCategoryAPI()
              }
              }
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Loader isLoading={loading} />
      <Toaster />
    </>
  );
};

export default AddCategory;
