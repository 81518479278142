import React, { useEffect, useRef, useState } from "react";
import { Container, Image, Stack } from "react-bootstrap";
import { ClickAwayListener, Grow, MenuItem, MenuList, Popper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  Button,
  Paper,
  IconButton,
  Divider,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Popover,
  Breadcrumbs,
  Link
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faFolder,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import logoutHelper from "../../helpers/logoutHelper";
import Loader from "../../components/common/loader";
import { Toaster } from "react-hot-toast";
import api from "../../components/common/commonFunctionForApi";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";
import { useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../../styles/userTypePageNew.scss";
import { Toast } from "../../utils/toast";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { faXmark, faFloppyDisk, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Filter from "../../components/common/userTypePage/filter";
import { filterSliceAction } from "../../redux/slices/userTypePage/filterSlice";
import PeopleListing from "../../components/common/userTypePage/peopleListing";
import PaginationCustom from "../../components/common/pagination/paginationCustom";
import TypeListing from "../../components/common/userTypePage/typeListing";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const UserTypePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { type } = useParams();
  const [loading, setLoading] = useState(false);
  const [getAllTypePosts, setGetAllTypePosts] = useState([]);
  const [apiRes, setAPIRes] = useState();
  const [search, setSearch] = useState("");
  const [istype, setIstype] = useState(false);

  const [page, setPage] = useState(1);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [getAllCategories, setGetAllCategories] = useState([])
  const [getAllGroups, setGetAllGroups] = useState([])

  const [openAddFolder, setOpenAddFolder] = useState(false)
  const [newFolderName, setNewFolderName] = useState()

  const [selectedFolderId, setSelectedFolderId] = useState(null);

  const [selectedFolderName, setSelectedFolderName] = useState("");
  const [anchorElAction, setAnchorElForAction] = useState(null);

  const {
    open,
    adminAccessible
  } = useSelector((state) => state?.drawerMenu);

  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);
  const userToken = localStorage.getItem("accessToken");

  let typeName;
  let typeID;
  let typeSlug;

  if (loggedUser?.user_type === "admin" && adminAccessible === false) {
    typeName = localStorage.getItem("type-selected-name")
    typeID = localStorage.getItem("type-selected-id")
    typeSlug = localStorage.getItem("type-selected-slug");
  } else if (loggedUser?.user_type == "admin" && adminAccessible == true) {
    typeName = localStorage.getItem("type-selected-name-admin-user")
    typeID = localStorage.getItem("type-selected-id-admin-user")
    typeSlug = localStorage.getItem("type-selected-slug-admin-user");
  } else {
    typeName = localStorage.getItem("type-selected-name-user")
    typeID = localStorage.getItem("type-selected-id-user")
    typeSlug = localStorage.getItem("type-selected-slug-user");
  }
  const [breadcrumbData, setBreadcrumbData] = useState({ 0: typeName });

  const [selectedPostData, setSelectedPostData] = useState()

  const debounceTimeout = 500; // Adjust the debounce timeout as needed
  const debounceTimeoutForCatFilter = 1200; // Adjust the debounce timeout as needed

  const { selectedGroups, selectedCategory, isArchive, sortPeople, sortType, peoplePagination, selectedShowValue, filterToggle } = useSelector((state) => state.filter);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openAddPopup, setOpenAddPopup] = useState(false);

  let debounceTimer;

  const transformCategoryData = (categories) => {
    return categories.map(category => ({
      label: category.title,
      slug: category.slug,
      id: category.id,
      options: category.categoryterm.map(term => ({
        label: term.name,
        value: term.id,
        categoryId: category.id
      }))
    }));
  };

  const calculateTotalPosts = (folder) => {
    let totalPosts = folder.posts_count;
    if (folder.child_sub_folder.length > 0) {
      folder.child_sub_folder.forEach((subFolder) => {
        totalPosts += calculateTotalPosts(subFolder);
      });
    }
    return totalPosts;
  };

  const FolderItem = ({ folder, parentId = null, level = 0, openFolderIds, selectedFolderId, handleClick }) => {
    const isOpen = openFolderIds.includes(folder.id);
    const totalPosts = calculateTotalPosts(folder);

    return (
      <>
        <ListItem disablePadding sx={{ display: 'block', pl: level === 0 ? 2 : 2, mt: 1 }}>
          <ListItemButton
            onClick={() => handleClick(folder)}
            sx={{
              pl: 2 * level,
              minHeight: 48,
              justifyContent: 'center',
            }}
            className={
              selectedFolderId === folder.id
                ? 'selected-menu-item w-48px'
                : 'non-selected-menu-item w-48px'
            }
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 2,
                ml: 2,
                justifyContent: 'center',
              }}
            >
              <FontAwesomeIcon icon={faFolder} className="menu-icon" />
            </ListItemIcon>
            <ListItemText sx={{ opacity: 1 }} >
              <Box className="d-flex align-items-center gap-4">
                <Typography className="folder-name-text">{folder?.folder_name}</Typography>
                <Typography className="folder-name-text">({totalPosts})</Typography>
              </Box>
            </ListItemText>
            {folder.child_sub_folder.length > 0 ? (isOpen ? <ExpandMore /> : <ExpandLess />) : null}
          </ListItemButton>
        </ListItem>
        {folder.child_sub_folder.length > 0 && (
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List disablePadding>
              {folder.child_sub_folder.map((subFolder) => (
                <FolderItem
                  key={subFolder.id}
                  folder={subFolder}
                  parentId={folder.id}
                  level={level + 1}
                  openFolderIds={openFolderIds}
                  selectedFolderId={selectedFolderId}
                  handleClick={handleClick}
                />
              ))}
            </List>
          </Collapse>
        )}
      </>
    );
  };

  const updateBreadcrumbs = (folderId, folderName) => {
    if (!folderId) return;  // Exit early if folderId is null or undefined
    setBreadcrumbData((prevData) => {
      const newBreadcrumbs = { ...prevData };

      // Check if folderId exists in the breadcrumb data
      if (newBreadcrumbs.hasOwnProperty(folderId)) {
        // If it exists, remove that entry and everything after it
        const updatedBreadcrumbs = {};
        Object.keys(newBreadcrumbs).forEach((key) => {
          if (parseInt(key, 10) <= folderId) {
            updatedBreadcrumbs[key] = newBreadcrumbs[key];
          }
        });
        return updatedBreadcrumbs;
      } else {
        // If it doesn't exist, append the new folderId and folderName
        const newKey =
          Math.max(...Object.keys(newBreadcrumbs).map(Number)) + 1;
        return { ...newBreadcrumbs, [folderId]: folderName };
      }
    });
  };

  const handleChangePage = (newPage) => {
    dispatch(filterSliceAction.setPeoplePagination({
      ...peoplePagination,
      page: newPage
    }));
    fetchAllPostList(newPage, selectedFolderId, selectedFolderName);
  };

  const handleCloseDeleteAlertModel = () => {
    setOpenDeleteAlert(false);
    setSelectedPostData()
  };

  const handleCloseAddFolderModel = () => {
    setOpenAddFolder(false);
    setNewFolderName()
  };

  const handleDeletePostClick = (deletePostId) => {
    deleteTypePostAPI(deletePostId);
    setOpenDeleteAlert(false);
  }

  const fetchAllPostList = async (page, folder_id, folder_name) => {
    setSelectedFolderId(folder_id)
    try {
      setLoading(true);
      let groups
      if (selectedGroups?.length > 0 && type === "people") {
        const viewManagers = selectedGroups?.map((user) => user);
        const usersStringManager = viewManagers.join(",");

        groups = usersStringManager;
      } else {
        groups = "";
      }
      let data = {
        search: search,
        page: page,
        category_data: selectedCategory,
        group_data: selectedGroups,
        folder_id: folder_id
      };

      if (type !== "people") {
        // data.is_archive = selectedArchive?.archive?.value;
        data.is_archive = isArchive;
        if (apiRes?.type?.is_folder === "enable") {
          const firstNonNull = Object.entries(sortType).find(([key, value]) => value !== null);
          if (firstNonNull) {
            data.order_by = firstNonNull[0] + '_' + firstNonNull[1];
          }
        }
      } else {
        //addition data payload for people
        const firstNonNull = Object.entries(sortPeople).find(([key, value]) => value !== null);
        if (firstNonNull) {
          data.order_by = firstNonNull[0] + '_' + firstNonNull[1];
        }
      }
      data.per_page = peoplePagination.per_page;
      if (!page) {
        data.page = peoplePagination.page;
      }

      const response = await api.post(`user/post/post-list/${type}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });

      setAPIRes(response?.response);
      setGetAllTypePosts(response?.response?.data);
      updateBreadcrumbs(folder_id, folder_name)

      dispatch(filterSliceAction.setPeoplePagination({
        ...peoplePagination,
        page: response?.response?.meta?.current_page,
        per_page: response?.response?.meta?.per_page,
        meta_data: response?.response?.meta
      }));

      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        localStorage.setItem("last-redirect-page", `/type/${type}`);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const deleteTypePostAPI = async (id) => {
    try {

      setLoading(true);
      const response = await api.delete(`user/post/delete/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        if (getAllTypePosts?.length < 2) {
          setPage(page - 1)
          fetchAllPostList(page - 1, selectedFolderId, selectedFolderName)
        } else {
          setPage(page)
          fetchAllPostList(page, selectedFolderId, selectedFolderName)
        }
        handleCloseDeleteAlertModel()
        Toast.success(response?.message);
        setLoading(false);

      } else if (response?.success === false) {
        Toast.warning(response?.message);
        setLoading(false);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const fetchAllCategoryData = async () => {
    try {
      setLoading(true);
      const response = await api.get(`user/people-filter-list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        const categories = response?.response?.data?.category_filter
        const groups = response?.response?.data?.group_filter
        const allCategories = transformCategoryData(categories);
        const finalGroups = groups?.map((group) => {
          return {
            label: group?.title,
            value: group?.id
          }
        })
        setGetAllCategories(allCategories);
        setGetAllGroups(finalGroups)
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  }

  const addNewFolderAPI = async () => {
    if (!newFolderName) {
      return Toast.error("Please enter folder name")
    }
    let data = {
      folder_name: newFolderName,
      parent_id: selectedFolderId
    }

    try {
      setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(
        `/user/post/store-folder/${typeID}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        Toast.success(response?.message);
        setLoading(false);
        handleCloseAddFolderModel()
        setNewFolderName()
        fetchAllPostList(page, selectedFolderId, selectedFolderName)
      } else {
        setLoading(false);
        Toast.error(response?.message || response?.response)
      }
    } catch (err) {
      console.log("error in acknowledge user::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      Toast.error(err?.response?.data?.message)
    }
  }

  const updateFolderAPI = async () => {
    if (!newFolderName) {
      return Toast.error("Please enter folder name")
    }
    let data = {
      folder_name: newFolderName,
    }

    try {
      setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(
        `/user/post/update-folder/${selectedPostData?.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        Toast.success(response?.message);
        setLoading(false);
        handleCloseAddFolderModel()
        setSelectedPostData()
        setNewFolderName()
        setAnchorElForAction(null)
        fetchAllPostList(page, selectedFolderId, selectedFolderName)
      } else {
        setLoading(false);
        Toast.error(response?.response?.message || response?.response)
      }
    } catch (err) {
      console.log("error in acknowledge user::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      Toast.error(err?.response?.data?.message || err?.response?.data?.response)
    }
  }

  const deleteFolderAPI = async (id) => {
    try {
      setLoading(true);
      const response = await api.delete(`user/post/delete-folder/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        setGetAllTypePosts((prevPosts) => prevPosts.filter((post) => post.id !== id));
        setLoading(false);
        Toast.success(response?.message);
        handleCloseDeleteAlertModel();
        setSelectedPostData()
      } else if (response?.success === false) {
        Toast.warning(response?.message);
        handleCloseDeleteAlertModel();
        setLoading(false);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  };

  const [openViewToggle, setOpenViewToggle] = useState(false);
  const anchorFilterListRef = useRef(null);

  const handleMenuItemClick = (event, value) => {
    dispatch(filterSliceAction?.setSelectedShowValue(value))
    setOpenViewToggle(false);
  };

  const handleToggle = () => {
    setOpenViewToggle((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorFilterListRef.current && anchorFilterListRef.current.contains(event.target)) {
      return;
    }

    setOpenViewToggle(false);
  };

  const handleAddClick = (event) => {
    setOpenAddPopup(true)
    setAnchorEl(event.currentTarget);
  };

  const handleCloseForAdd = () => {
    setAnchorEl(null);
    setOpenAddPopup(false)
  };

  const handleFilterToggle = () => {
    dispatch(filterSliceAction?.setFilterToggle(!filterToggle))
  }

  const handleChangeRowsPerPage = (event) => {
    console.log({
      ...peoplePagination,
      per_page: event.target.value,
      page: 1
    });
    dispatch(filterSliceAction.setPeoplePagination({
      ...peoplePagination,
      per_page: event.target.value,
      page: 1
    }));
  };

  useEffect(() => {
    fetchAllPostList(1, selectedFolderId, selectedFolderName);
    setPage(1);
    fetchAllCategoryData()
  }, [selectedFolderId])

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }
    if (search.length >= 3) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        fetchAllPostList(1, selectedFolderId, selectedFolderName);
      }, debounceTimeout);
    }
    else if (search === "" && istype === true) {
      console.log("fetchall 661")
      fetchAllPostList(1, selectedFolderId, selectedFolderName);
    }
    else {
      clearTimeout(debounceTimer);
    }

    return () => clearTimeout(debounceTimer);
  }, [search]);

  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }
    if ((selectedGroups && Object.getOwnPropertyNames(selectedGroups)?.length > 0) || (selectedCategory && Object.getOwnPropertyNames(selectedCategory)?.length > 0) || Object.entries(sortPeople).find(([key, value]) => value !== null) || Object.entries(sortType).find(([key, value]) => value !== null) || peoplePagination.per_page) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        fetchAllPostList(1, selectedFolderId, selectedFolderName);
      }, debounceTimeoutForCatFilter);
      return () => clearTimeout(debounceTimer);
    }
  }, [selectedGroups, selectedCategory, sortPeople, sortType, peoplePagination.per_page]);

  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }
    fetchAllPostList(1, selectedFolderId, selectedFolderName);
  }, [isArchive]);

  useEffect(() => {
    setLoading(true);
    setGetAllTypePosts([]);
    setAPIRes();
    setSearch("");
    setIstype(false);
    setTimeout(() => {
      setLoading(false);
    }, 3000)
  }, [type]);

  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }

    dispatch(filterSliceAction.setSelectedCategory([]))
    dispatch(filterSliceAction.setSelectedGroups([]))
    setBreadcrumbData({ 0: typeName })

  }, [typeName])

  useEffect(() => {
    // This effect will run only after the first render

    isFirstRender.current = false;
    setBreadcrumbData({ 0: typeName })
  }, []);

  return (
    <>
      <div
        className={`main-content-wrapper-2 ${open === true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          <Box className="main-div-2-types">
            <Box className="">
              {typeName?.toLowerCase() !== "people" ? (
                <Stack spacing={2}>
                  <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                  >
                    {Object.entries(breadcrumbData).map(
                      ([key, value], index, array) => {
                        const isLast =
                          index === array.length - 1;
                        return isLast ? (
                          <Typography
                            key={key}
                            component="h3"
                            color="text.primary"
                            className="cursor-pointer"
                            underline="hover"
                          >
                            {value}
                          </Typography>
                        ) : (
                          <Link
                            key={key}
                            color="inherit"
                            onClick={() => {
                              fetchAllPostList(page, key, value)
                            }
                            }
                            component="h3"
                            className="cursor-pointer"
                            underline="hover"
                          >
                            {value}
                          </Link>
                        );
                      }
                    )}
                  </Breadcrumbs>
                </Stack>
              ) : (
                <Typography variant="h6" className="title">People Directory</Typography>
              )}

            </Box>
            <div className="d-flex flex-wrap gap-3">
              {typeName?.toLowerCase() !== "people" && (
                <>
                  <Button
                    className="btn primary-btn"
                    onClick={handleAddClick}
                  >
                    Add New
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                  <Popover
                    id={openAddPopup ? 'simple-popover' : undefined}
                    open={openAddPopup}
                    anchorEl={anchorEl}
                    onClose={handleCloseForAdd}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    className="add-new-popover"
                  >
                    <Box>
                      <List component="nav" className="p-0" aria-label="main mailbox folders">
                        <ListItem
                          disablePadding
                          sx={{ display: 'block' }}
                        >
                          <ListItemButton
                            onClick={() => {
                              handleCloseForAdd();
                              if (loggedUser?.user_type === "admin" && adminAccessible === false) {
                                localStorage.setItem("last-redirect-page", `/type/add/${apiRes?.type?.slug}`)
                                localStorage.setItem("type-selected-name", apiRes?.type?.name);
                                localStorage.setItem("type-selected-id", apiRes?.type?.id);
                                localStorage.setItem("type-selected-slug", apiRes?.type?.slug);
                                navigate(`/type/add/${apiRes?.type?.slug}`);
                              }
                              else if (loggedUser?.user_type == "admin" && adminAccessible == true) {
                                localStorage.setItem("last-redirect-page-user", `/type/add/${apiRes?.type?.slug}`)
                                localStorage.setItem("type-selected-name-user", apiRes?.type?.name);
                                localStorage.setItem("type-selected-id-user", apiRes?.type?.id);
                                localStorage.setItem("type-selected-slug-user", apiRes?.type?.slug);
                                navigate(`/type/add/${apiRes?.type?.slug}`);
                              }
                              else {
                                localStorage.setItem("last-redirect-page-user", `/type/add/${apiRes?.type?.slug}`)
                                localStorage.setItem("type-selected-name-user", apiRes?.type?.name);
                                localStorage.setItem("type-selected-id-user", apiRes?.type?.id);
                                localStorage.setItem("type-selected-slug-user", apiRes?.type?.slug);
                                navigate(`/type/add/${apiRes?.type?.slug}`);
                              }
                            }}
                          >
                            <ListItemIcon sx={{ minWidth: 0, mr: 2, justifyContent: "center" }}>
                              <FontAwesomeIcon icon={apiRes?.type?.icons?.icon_value} className="new-icon" />
                            </ListItemIcon>
                            <ListItemText primary={`${typeName}`} className="new-text" />
                          </ListItemButton>
                        </ListItem>
                        {apiRes?.type?.is_folder === "enable" && (
                          <>
                            <Divider className="divider" />
                            <ListItem
                              disablePadding
                              sx={{ display: 'block' }}
                            >
                              <ListItemButton
                                onClick={() => {
                                  handleCloseForAdd();
                                  setOpenAddFolder(true)
                                }}
                              >
                                <ListItemIcon sx={{ minWidth: 0, mr: 2, justifyContent: "center" }}>
                                  <FontAwesomeIcon icon={faFolder} className="new-icon" />
                                </ListItemIcon>
                                <ListItemText primary="Folder" className="new-text" />
                              </ListItemButton>
                            </ListItem>
                          </>
                        )}
                      </List>
                    </Box>
                  </Popover>
                </>
              )}
            </div>
          </Box>

          <Divider className="mt-3" style={{ background: "#EEEEEE" }} />

          <Box className="d-flex justify-content-end align-items-center mt-3">
            <Box className="filter-listing d-flex gap-2">
              <Button varient="text" className="filter-btn"
                endIcon={<FontAwesomeIcon
                  icon={faFilter}
                  className="hide-option-btn-svg"
                />}
                onClick={handleFilterToggle}
              >
                {filterToggle ? 'Show' : 'Hide'} Filters
              </Button>
              {typeName?.toLowerCase() === "people" && (

                <Box>
                  <Typography className="ps-2 fw-medium" varient="h3" component="span">Show As: </Typography>
                  <Button ref={anchorFilterListRef}
                    variant="text"
                    className="filter-btn pl-0 justify-content-start"
                    endIcon={<FontAwesomeIcon
                      icon={faChevronDown}
                      className="hide-option-btn-svg"
                    />}
                    onClick={handleToggle}
                  >
                    {selectedShowValue}
                  </Button>
                  <Popper
                    sx={{ zIndex: 1 }}
                    open={openViewToggle}
                    anchorEl={anchorFilterListRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu" autoFocusItem>
                              {['Lists', 'Cards'].map((option, index) => (
                                <MenuItem
                                  key={option}
                                  disabled={index === 2}
                                  selected={option === selectedShowValue}
                                  onClick={(event) => handleMenuItemClick(event, option)}
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Box>

              )}
            </Box>
          </Box>
          <Grid container spacing={2}>
            {!filterToggle && (
              <Grid item xs={12} md={3} lg={3} xl={3} sm={12} className="mt-4 mb-4 p-0">
                <Filter type={typeName?.toLowerCase()} categories={getAllCategories} groups={getAllGroups} />
              </Grid>
            )}
            <Grid item xs className="mb-4">

              {apiRes?.type?.is_folder === "disable" ? (
                <>
                  <TypeListing
                    folderEnable={apiRes?.type?.is_folder === "enable"}
                    selectedShowValue={selectedShowValue}
                    getAllTypes={getAllTypePosts}
                    filterToggle={!filterToggle}
                    typeName={typeName}
                    deleteTypePostAPI={deleteTypePostAPI}
                    setAnchorElForAction={setAnchorElForAction}
                    anchorElAction={anchorElAction}
                    setOpenDeleteAlert={setOpenDeleteAlert}
                    openDeleteAlert={openDeleteAlert}
                  />

                  {getAllTypePosts?.length < 1 && (
                    <div className="no-data mt-5">
                      <div>
                        <Image
                          src={NoDataFoundImg}
                          className="text-center mx-auto no-data-img mt-4"
                        />
                      </div>
                      <Typography component="h5" variant="h5">
                        No data found
                      </Typography>
                    </div>
                  )}
                </>
              ) : apiRes?.type?.is_folder === "enable" ? (
                <>
                  <TypeListing
                    folderEnable={apiRes?.type?.is_folder === "enable"}
                    selectedShowValue={selectedShowValue}
                    getAllTypes={getAllTypePosts}
                    filterToggle={!filterToggle}
                    typeName={typeName}
                    deleteTypePostAPI={deleteTypePostAPI}
                    setSelectedFolderId={setSelectedFolderId}
                    setSelectedFolderName={setSelectedFolderName}
                    setBreadcrumbData={setBreadcrumbData}
                    updateBreadcrumbs={updateBreadcrumbs}
                    page={page}
                    fetchAllPostList={fetchAllPostList}
                    setNewFolderName={setNewFolderName}
                    setSelectedPostData={setSelectedPostData}
                    setOpenAddFolder={setOpenAddFolder}
                    selectedFolderId={selectedFolderId}
                    selectedFolderName={selectedFolderName}
                    selectedPostData={selectedPostData}
                    deleteFolderAPI={deleteFolderAPI}
                    setAnchorElForAction={setAnchorElForAction}
                    anchorElAction={anchorElAction}
                    setOpenDeleteAlert={setOpenDeleteAlert}
                    openDeleteAlert={openDeleteAlert}
                  />

                  {getAllTypePosts?.length < 1 && (
                    <div className="no-data mt-5">
                      <div>
                        <Image
                          src={NoDataFoundImg}
                          className="text-center mx-auto no-data-img mt-4"
                        />
                      </div>
                      <Typography component="h5" variant="h5">
                        No data found
                      </Typography>
                    </div>
                  )}
                </>
              ) : typeName?.toLowerCase() === "people" ? (
                <>
                  <PeopleListing selectedShowValue={selectedShowValue} getAllPeoples={getAllTypePosts} filterToggle={!filterToggle} />

                  {getAllTypePosts?.length < 1 && (
                    <div className="no-data mt-5">
                      <div>
                        <Image
                          src={NoDataFoundImg}
                          className="text-center mx-auto no-data-img mt-4"
                        />
                      </div>
                      <Typography component="h5" variant="h5">
                        No data found
                      </Typography>
                    </div>
                  )}
                </>
              ) : ""}
            </Grid>
          </Grid>
          {getAllTypePosts?.length > 0 && (
            <>
              <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
              <PaginationCustom
                per_page={peoplePagination?.per_page}
                per_page_options={[12, 24, 36, 48, 60]}
                from={peoplePagination?.meta_data?.from}
                to={peoplePagination?.meta_data?.to}
                last_page={peoplePagination?.meta_data?.last_page}
                total={peoplePagination?.meta_data?.total}
                page={peoplePagination?.page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          )
          }
        </Container>
        {openDeleteAlert && (
          <Dialog
            aria-labelledby="customized-dialog-title"
            open={openDeleteAlert}
            maxWidth="sm"
            fullWidth={true}
          >
            <DialogTitle
              sx={{ m: 0, p: 2 }}
              id="customized-dialog-title"
              className="dialog-title"
            >
              Are you sure?
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseDeleteAlertModel}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              className="alert-close-btn"
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers className="">
              {" "}
              Are you sure want to delete {selectedPostData?.list_type === "folder" ? "folder" : "post"}?
            </DialogContent>
            <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
              <Button
                className="btn delete-btn-model"
                onClick={handleCloseDeleteAlertModel}
              >
                <FontAwesomeIcon icon={faXmark} />
                Cancel
              </Button>
              <Button
                className="btn primary-btn"
                onClick={() => {
                  if (selectedPostData?.list_type === "folder") {
                    deleteFolderAPI(selectedPostData?.id)
                  } else {
                    handleDeletePostClick(selectedPostData?.id);

                  }
                }}
              >
                <FontAwesomeIcon icon={faFloppyDisk} />
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        )}

        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openAddFolder}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            {selectedPostData !== undefined ? "Edit" : "Add"} Folder
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseAddFolderModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="">
            <Box>
              <Typography className="fs-14 mt-2">Folder Name</Typography>
              <TextField
                className="input-field mt-2"
                fullWidth
                id="outlined-basic short summary"
                variant="outlined"
                placeholder={`write folder name`}
                value={newFolderName}
                onChange={(event) => setNewFolderName(event?.target?.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
            <Button
              className="btn delete-btn-model"
              onClick={handleCloseAddFolderModel}
            >
              <FontAwesomeIcon icon={faXmark} />
              Cancel
            </Button>
            <Button
              className="btn primary-btn"
              onClick={() => {
                if (selectedPostData !== undefined) {
                  updateFolderAPI()
                } else {
                  addNewFolderAPI()
                }
              }}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              {selectedPostData !== undefined ? "Edit" : "Add"} Folder
            </Button>
          </DialogActions>
        </Dialog>
        <Loader isLoading={loading} />
        <Toaster />
      </div >
    </>
  );
};

export default UserTypePage;
