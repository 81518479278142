import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Form as BootstrapForm } from "react-bootstrap";
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Grid,
  Avatar,
  Paper,
  Button,
  Stack,
  Chip,
  Menu,
  MenuItem,
  DialogContentText,
  Radio,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  OutlinedInput,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Image } from "react-bootstrap";
import { TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faBullhorn,
  faBurst,
  faCheck,
  faFloppyDisk,
  faFolder,
  faPhone,
  faPlus,
  faShield,
  faShieldHalved,
  faTrash,
  faUser,
  faXmark,
  faSearch,
  faCircleInfo,
  faFileCsv,
  faTimes,
  faUpload,
  faFile,
  faFilePdf,
  faFileExcel,
  faArrowRight,
  faAdd
} from "@fortawesome/free-solid-svg-icons";
import { Toast } from "../../utils/toast";
import Loader from "../../components/common/loader";
import { useParams } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import InputGroup from "react-bootstrap/InputGroup";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";
import RadioGroup from "@mui/material/RadioGroup";
import CreatableSelect from "react-select/creatable";
import PropTypes from "prop-types";
import {
  ErrorMessage,
  FastField,
  Field,
  FieldArray,
  Form,
  Formik,
  useFormikContext
} from "formik";
import api from "../../components/common/commonFunctionForApi";
import logoutHelper from "../../helpers/logoutHelper";
import deleteType from "../../assets/images/delete.png";
import Accordion from "react-bootstrap/Accordion";
import { columns_users_contact_header } from "../../helpers/theaderColumns/users/usersFields";
import TableHeaderForSorting from "../../components/common/TableHeaderForSorting";
import { BootstrapTooltip } from "../../components/common/tooltip/bootstrapTooltip";
import { AntSwitch } from "../../components/common/switch/antSwitch";
import ImageUpload from "../../components/common/imageUpload";
import FileUploadModal from "../../components/common/fileUploadModal";
import { addNewUsersvalidationSchema } from "../../helpers/validations/users/addUsers";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "../../styles/users.scss";
import { EditNewUsersvalidationSchema } from "../../helpers/validations/users/editUsers";
import { CSVLink } from "react-csv";
import TablePagination from "@mui/material/TablePagination";

import { columns_attribute_changelog_header } from "../../helpers/theaderColumns/attributes/attributeChangelog";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import moment from "moment";
import FileUploadModalAttribue from "../../components/common/fileUploadModalAttribute.js";
import { parseInt } from "lodash";
import { configForContentEditor, configForContentEditorForHTML } from "../../helpers/constants/common.js/index.js";
import { dateWithTime } from "../../helpers/DateTimeFormat/dateTimeFormat.js";
import { exportXlsx } from "../../helpers/Exports/FileExport.js";
import { useDropzone } from "react-dropzone";
import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';
import FileSaver from "file-saver";
import UploadMediaModel from "../../components/common/media/uploadMediaModel.js";
import AddMedia from "../../components/common/media/addMedia.js";
import UploadMediaModelAtt from "../../components/common/media/uploadMediaModelAtt.js";
import ImageUploadNew from "../../components/common/imageUploadNew.js";
import AllComponent from "../../components/common/attribute/allComponent.js";

function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box sx={{ padding: "15px" }}>{children}</Box>}
    </div>
  );
}

const processedHTML = (htmlContent) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent || ""; // Ensure there's a default value if htmlContent is undefined
  return tempDiv;
};

const columns_atttributes_headers = [
  {
    key: 1,
    disablePadding: true,
    id: "name",
    label: "Name",
    minWidth: 250,
    className: "other-color",
  },
  {
    key: 2,
    id: "view",
    disablePadding: true,
    label: "View",
    minWidth: 50,
    className: "other-color",
  },
  {
    key: 3,
    id: "deny",
    disablePadding: true,
    label: "Deny",
    minWidth: 50,
    className: "other-color",
  },
];

const columns_advace_atttributes_headers = [
  {
    key: 1,
    disablePadding: true,
    id: "name",
    label: "Name",
    minWidth: 250,
    className: "other-color",
  },
  {
    key: 2,
    id: "view",
    disablePadding: true,
    label: "View",
    minWidth: 50,
    className: "other-color",
  },
  {
    key: 3,
    id: "deny",
    disablePadding: true,
    label: "Deny",
    minWidth: 50,
    className: "other-color",
  },

  {
    key: 5,
    id: "edit",
    disablePadding: true,
    label: "Edit",
    minWidth: 50,
    className: "other-color",
  },
  {
    key: 6,
    id: "delete",
    disablePadding: true,
    label: "Delete",
    minWidth: 50,
    className: "other-color",
  },
  {
    key: 7,
    id: "redacted",
    disablePadding: true,
    label: "Redacted",
    minWidth: 50,
    className: "other-color",
  },
];

const AddUsers = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getAllChangeLogs, setGetAllChangeLogs] = useState();
  const [search, setSearch] = useState("");
  const [getChangelogCsvData, setGetChangelogCsvData] = useState([]);
  const [getCategoryData, setCategoryData] = useState();
  const [isWarningMessage, setIsWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState([]);

  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [errorTab, setErrorTab] = useState(-1);
  const [isErrorOnClassificationTab, SetIsErrorOnClassificationTab] =
    useState(false);
  const [isErrorOnIRTab, setIsErrorOnIRTab] = useState(false);
  const [isErrorOnEngTab, SetIsErrorOnEngTab] = useState(false);
  const [isErrorOnProfileTab, setIsErrorOnProfileTab] = useState(false);
  const [isErrorOnContactTab, setIsErrorOnContactTab] = useState(false);
  const [isErrorOnAuthenticationTab, setIsErrorOnAuthenticationTab] =
    useState(false);
  const [isErroronOrganisationTab, setIsErrorOnOrganisationTab] =
    useState(false);
  const [contactData, setContactData] = useState([]);
  const [openFileUploadModel, setOpenFileUploadModel] = useState(false);
  const [contentForPost, setContentForPost] = useState("");
  const [isEdit, setIsEdit] = useState(id ? true : false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [accountTypeList, setAccountTypeList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [getAllUsers, setGetAllUsers] = useState([]);
  const [showError, setShowError] = useState(false);

  const [openRnameMediaModel, setOpenRenameModel] = useState(false)
  const [openRnameMediaModelAtt, setOpenRenameModelAtt] = useState(false)

  const [openMediaUpload, setOpenMediaUpload] = useState(false)
  const [folderList, setFolderList] = useState()
  const [myMediaFolderList, setMyFolderList] = useState([])
  const [systemMediaFolderList, setSystemFolderList] = useState([])
  const [selectedMediaTypeForImage, setSelectedMediaTypeForImage] = useState("")
  const [selectedMediaTypeForDoc, setSelectedMediaTypeForDoc] = useState("")

  const [selectedMediaFolderIDForImage, setSelectedMediaFolderIDForImage] = useState("")
  const [selectedMediaFolderIDFordoc, setSelectedMediaFolderIDForDoc] = useState("")

  const [mediaList, setMediaList] = useState([])
  const [mediaListResponse, setMediaListResponse] = useState()

  const [selectedMediaFileDetailsForImage, setSelectedMediaFileDetailsForImage] = useState()
  const [selectedMediaFileForImage, setSelectedMediaFileForImage] = useState("")

  const [selectedMediaFileDetailsForDoc, setSelectedMediaFileDetailsForDoc] = useState()
  const [selectedMediaFileForDoc, setSelectedMediaFileForDoc] = useState("")

  const [mediaDataFor, setMediaDataFor] = useState("")

  const [rowsPerPageForMedia, setRowsPerPageForMedia] = useState(18)
  const [pageForMedia, setPageForMedia] = useState(0);

  const [openMediaUploadForAtt, setOpenMediaUploadForAtt] = useState(false)
  const [folderListForAtt, setFolderListForAtt] = useState()
  const [myMediaFolderListForAtt, setMyFolderListForAtt] = useState([])
  const [systemMediaFolderListForAtt, setSystemFolderListForAtt] = useState([])
  const [selectedMediaTypeForImageAtt, setSelectedMediaTypeForImageAtt] = useState("")
  const [selectedMediaTypeForDocAtt, setSelectedMediaTypeForDocAtt] = useState("")

  const [selectedMediaFolderIDForImageAtt, setSelectedMediaFolderIDForImageAtt] = useState("")
  const [selectedMediaFolderIDFordocAtt, setSelectedMediaFolderIDForDocAtt] = useState("")

  const [mediaListAtt, setMediaListAtt] = useState([])
  const [mediaListResponseAtt, setMediaListResponseAtt] = useState()

  const [selectedMediaFileDetailsForImageAtt, setSelectedMediaFileDetailsForImageAtt] = useState()
  const [selectedMediaFileForImageAtt, setSelectedMediaFileForImageAtt] = useState("")

  const [selectedMediaFileDetailsForDocAtt, setSelectedMediaFileDetailsForDocAtt] = useState()
  const [selectedMediaFileForDocAtt, setSelectedMediaFileForDocAtt] = useState("")

  const [mediaDataForAtt, setMediaDataForAtt] = useState("")

  const [openMediaModelFor, setOpenMediaModelFor] = useState("")

  const [openFileUploadModelMedia, setOpenFileUploadModelMedia] = useState(false)
  const [openFileUploadModelMediaAtt, setOpenFileUploadModelMediaAtt] = useState(false)

  const [mediaTitle, setMediaTitle] = useState("")
  const [mediaTitleAtt, setMediaTitleAtt] = useState("")

  const [mediaAltText, setMediaAltText] = useState("")
  const [mediaAltTextAtt, setMediaAltTextAtt] = useState("")

  const [mediaDescription, setMediaDescription] = useState("")
  const [mediaDescriptionAtt, setMediaDescriptionAtt] = useState("")

  const [mediaDirectUrl, setMediaDirectUrl] = useState("")
  const [mediaDirectUrlAtt, setMediaDirectUrlAtt] = useState("")

  const [isSystemTypeMedia, setIsSystemTypeMedia] = useState(false)
  const [isSystemTypeMediaAtt, setIsSystemTypeMediaAtt] = useState(false)

  const [responseForMedia, setResponseForMedia] = useState()
  const [responseForMediaAtt, setResponseForMediaAtt] = useState()

  const [valueForMediaTab, setValueForMediaTab] = useState(0);
  const [editMediaType, setEditMediType] = useState("")
  const [selectedMedia, setSelectedMedia] = useState("")
  const [selectedMediaAtt, setSelectedMediaAtt] = useState("")

  const [permissionsForMedia, setPermissionsForMedia] = useState([]);
  const [isAdvancePermissionForMedia, setIsAdvancePermissionForMedia] = useState(false);
  const [isChild, setIsChild] = useState(false)
  const [parentFolderId, setParentFolderId] = useState(0)
  const [parentFolderIdAtt, setParentFolderIdAtt] = useState(0)

  const [isParentFolderSystemType, setIsParentFolderSystemType] = useState(false)
  const [isParentFolderSystemTypeAtt, setIsParentFolderSystemTypeAtt] = useState(false)

  const [isEditMedia, setIsEditMedia] = useState(false)
  const [isEditMediaAtt, setIsEditMediaAtt] = useState(false)

  const [isRenameMedia, setIsRenameMedia] = useState(false)
  const [isRenameMediaAtt, setIsRenameMediaAtt] = useState(false)

  const [selectedFileMedia, setSelectedFileMedia] = useState(null);
  const [selectedFileMediaAtt, setSelectedFileMediaAtt] = useState(null);

  const [mediaFile, setMediaFile] = useState("")
  const [mediaFileAtt, setMediaFileAtt] = useState("")

  const [rowsPerPageForMediaAtt, setRowsPerPageForMediaAtt] = useState(18)
  const [pageForMediaAtt, setPageForMediaAtt] = useState(0);
  const [fetchAllUsers, setFetchAllUsers] = useState([])
  const [getAllGroupsAndUsersForMedia, setGetAllGroupsAndUsersForMedia] = useState([]);


  const debounceTimeout = 500; // Adjust the debounce timeout as needed
  let debounceTimer;

  const media_Pagination_options = [{ label: "18", value: 18 },
  { label: "36", value: 36 },
  { label: "54", value: 54 },
  { label: "108", value: 108 }]

  const total_mediaFiles = parseInt(mediaListResponse?.meta?.total)
  const total_mediaFilesAtt = parseInt(mediaListResponseAtt?.meta?.total)

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] =
    React.useState(false);
  const [getUserData, setGetUserData] = useState();
  const [istype, setIstype] = useState(false);
  const [userNameExist, setUserNameExist] = useState();
  const [emailExist, setEmailExist] = useState();

  const [attributesData, setAttributes] = useState([]);

  const [getAllCategories, setGetAllCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState({})

  const [attData, setAttData] = useState();
  const [additionalTextFields, setAdditionalTextFields] = useState([]);
  const [additionalTextFieldsForNestedChildAtt1, setAdditionalTextFieldsForNestedChildAtt1] = useState([]);

  const [showBelowError, setShowBelowError] = useState(false);
  const [formData, setFormData] = useState({});

  const [selectedFileForAtt, setSelectedFileForAtt] = useState(null);
  const [selectedImageFileForAtt, setSelectedImageFileForAtt] = useState(null);

  const [contentForPostForAtt, setContentForPostForAtt] = useState("");
  const [openFileUploadModelForAtt, setOpenFileUploadModelForAtt] =
    useState(false);

  const [value, setValue] = useState(0);

  const [showRenderImage, setShowRenderImage] = useState(true)
  const [uploadedFile, setUploadedFile] = useState()
  const [selectedAnyMediaFile, setSelectedAnyMediaFile] = useState()
  const [selectedAnyMediaFileDetails, setSelectedAnyMediaFileDetails] = useState()
  const [selectedMediaFileForUpdate, setSelectedMediaFileForUpdate] = useState()
  const [isImageMedia, setIsImageMedia] = useState(false)
  const [getAllAttributes, setGetAllAttributes] = useState([])
  const [openAddAttribute, setOpenAddAttribute] = useState(false)
  const [searchAtt, setSearchAtt] = useState("")
  const [isTypingSearchAtt, setIsTypingSearchAtt] = useState(false)
  const [getAllAttributeSections, setGetAllAttributeSections] = useState([]);
  const [selectedMediaFileDetailsForAtt, setSelectedMediaFileDetailsForAtt] = useState()
  const [selectedMediaFileForAtt, setSelectedMediaFileForAtt] = useState("")


  const total_changelog = parseInt(getAllChangeLogs?.meta?.total);

  const usernameRegex = /^(?=.{3,20}$)(?!.*[_.]{2})[a-zA-Z0-9._]+$/; // Username regex for 4-8 characters with at least one special character

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  let emailExists;
  let usernameExist;

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmedPassword = () =>
    setShowConfirmedPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const servicesSections = [
    { label: "Email", value: "email" },
    { label: "Phone", value: "phone" },
    { label: "Twitter", value: "twitter" },
    { label: "Address", value: "address" },
    { label: "Linkdein", value: "linkdein" },
  ];

  const handleOpen = () => setOpenFileUploadModel(true);
  const handleClose = () => setOpenFileUploadModel(false);

  const {
    open,
    selectedItem,
    nameCollapsable,
    typeCollapsable,
    drawerVariant,
    usersCollapsable,
  } = useSelector((state) => state?.drawerMenu);

  let newWarningMessages = [];

  const userToken = localStorage.getItem("accessToken");
  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);
  const ITEM_HEIGHT = 48;

  const header_csv = [
    { label: "No", key: "no" },
    { label: "User", key: "user" },
    { label: "Date", key: "date" },
    { label: "Area", key: "area" },
    { label: "Details", key: "details" },
  ];

  const initialValues = {
    profileAccountType: id ? getUserData?.profileAccountType : "1",
    profileFirstName: id ? getUserData?.profileFirstName : "",
    profileLastName: id ? getUserData?.profileLastName : "",
    profileJobTitle: id ? getUserData?.profileJobTitle : "",
    profileBio: id ? getUserData?.profileBio : "",
    profileGroups: id ? getUserData?.profileGroups : [],
    contactDetails: id
      ? getUserData?.contactDetails
      : [
        { id: 1, service: "email", value: "" },
        { id: 2, service: "phone", value: "" },
      ],
    authenticationUserName: id ? getUserData?.authenticationUserName : "",
    authenticationPassword: "",
    authenticationConfirmPassword: "",
    authenticationNextLogin: id ? getUserData?.authenticationNextLogin : false,
    authenticationLoginDetails: id
      ? getUserData?.authenticationLoginDetails
      : false,
    organisationManagers: id ? getUserData?.organisationManagers : "",
    organisationDirectReports: id ? getUserData?.organisationDirectReports : "",
    userStatus: id ? getUserData?.userStatus : true,
    image: id ? getUserData?.image : "",
  };

  const imageFileTypes = ["JPG", "PNG", "GIF"];
  const fileTypes = ["docx", "pdf"];
  const handleOpenForAtt = () => setOpenFileUploadModelForAtt(true);
  const handleCloseForAtt = () => setOpenFileUploadModelForAtt(false);

  const handleOpenWarningMessage = () => {
    setIsWarningMessage(true);
    setIsWarningMessage("");
  };

  const getMimeType = (extension) => {
    const mimeTypes = {
      'png': 'image/png',
      'jpg': 'image/jpeg',
      'jpeg': 'image/jpeg',
      'pdf': 'application/pdf',
      'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'mp4': 'video/mp4',
      'mp3': 'audio/mpeg'
    };
    return mimeTypes[extension] || 'application/octet-stream';
  };

  const handleChangePageForMedia = (event, newPage) => {
    if (newPage > pageForMedia) {
      setPageForMedia(newPage);
      let media_id;
      let folderID;
      if (mediaDataFor === "image") {
        media_id = selectedMediaFileForImage
        folderID = selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage
      } else if (mediaDataFor === "doc") {
        media_id = selectedMediaFileForDoc
        folderID = selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc
      }
      fetchMediaFiles(newPage + 1, rowsPerPageForMedia, mediaDataFor, media_id, folderID)
    } else if (newPage < pageForMedia) {
      setPageForMedia(newPage);
      let media_id;
      let folderID;

      if (mediaDataFor === "image") {
        media_id = selectedMediaFileForImage
        folderID = selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage
      } else if (mediaDataFor === "doc") {
        media_id = selectedMediaFileForDoc
        folderID = selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc
      }
      fetchMediaFiles(newPage + 1, rowsPerPageForMedia, mediaDataFor, media_id, folderID)
    }
  };

  const handleChangePageForMediaForAtt = (event, newPage) => {
    if (newPage > pageForMediaAtt) {
      setPageForMediaAtt(newPage);
      let type;
      let folder_id;
      if (mediaDataForAtt === "image") {
        folder_id = selectedMediaFolderIDForImageAtt
        if (selectedMediaTypeForImageAtt === "system") {
          type = "system"
        } else {
          type = "user"
        }
      } else if (mediaDataForAtt === "doc") {
        folder_id = selectedMediaFolderIDFordocAtt
        if (selectedMediaTypeForDocAtt == "system") {
          type = "system"
        } else {
          type = "user"
        }
      }
      fetchMediaFilesForAtt(newPage + 1, rowsPerPageForMediaAtt, mediaDataForAtt, folder_id, type)
    } else if (newPage < pageForMediaAtt) {
      setPageForMediaAtt(newPage);
      let type;
      let folder_id;
      if (mediaDataForAtt === "image") {
        folder_id = selectedMediaFolderIDForImageAtt
        if (selectedMediaTypeForImageAtt === "system") {
          type = "system"
        } else {
          type = "user"
        }
      } else if (mediaDataForAtt === "doc") {
        folder_id = selectedMediaFolderIDFordocAtt
        if (selectedMediaTypeForDocAtt === "system") {
          type = "system"
        } else {
          type = "user"
        }
      }
      fetchMediaFilesForAtt(newPage + 1, rowsPerPageForMediaAtt, mediaDataForAtt, folder_id, type)
    }
  };

  const handleChangeRowsPerPageForMedia = (event) => {
    setRowsPerPageForMedia(event.target.value);
    setPageForMedia(0);
    let media_id;
    let folderID;

    if (mediaDataFor === "image") {
      media_id = selectedMediaFileForImage
      folderID = selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage
    } else if (mediaDataFor === "doc") {
      media_id = selectedMediaFileForDoc
      folderID = selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc
    }
    fetchMediaFiles(0, event.target.value, mediaDataFor, media_id, folderID)

  };

  const handleChangeRowsPerPageForMediaAtt = (event) => {
    setRowsPerPageForMediaAtt(event.target.value);
    setPageForMediaAtt(0);
    let type;
    let folder_id;

    if (mediaDataForAtt === "image") {
      folder_id = selectedMediaFolderIDForImageAtt
      if (selectedMediaTypeForImageAtt === "system") {
        type = "system"
      } else {
        type = "user"
      }
    } else if (mediaDataForAtt === "doc") {
      folder_id = selectedMediaFolderIDFordocAtt
      if (selectedMediaTypeForDocAtt === "system") {
        type = "system"
      } else {
        type = "user"
      }
    }
    fetchMediaFilesForAtt(0, event.target.value, mediaDataForAtt, folder_id, type)

  };

  const downloadFileForImage = async () => {

    var blob = new Blob([selectedMediaFileDetailsForImage?.file_path], {
      type: getMimeType(selectedMediaFileDetailsForImage?.type)
    });
    FileSaver.saveAs(blob, selectedMediaFileDetailsForImage?.title);

  };

  const downloadFileForImageAtt = async () => {

    var blob = new Blob([selectedMediaFileDetailsForImageAtt?.file_path], {
      type: getMimeType(selectedMediaFileDetailsForImageAtt?.type)
    });
    FileSaver.saveAs(blob, selectedMediaFileDetailsForImageAtt?.title);

  };

  const downloadFileFordoc = async () => {

    var blob = new Blob([selectedMediaFileDetailsForDoc?.file_path], {
      type: getMimeType(selectedMediaFileDetailsForDoc?.type)
    });
    FileSaver.saveAs(blob, selectedMediaFileDetailsForDoc?.title);

  };

  const downloadFileFordocAtt = async () => {

    var blob = new Blob([selectedMediaFileDetailsForDocAtt?.file_path], {
      type: getMimeType(selectedMediaFileDetailsForDocAtt?.type)
    });
    FileSaver.saveAs(blob, selectedMediaFileDetailsForDocAtt?.title);

  };

  const handleOpenUploadMedia = (dataFor) => {
    let folderID;
    setMediaDataFor(dataFor)
    if (dataFor === "image") {
      folderID = selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage
    } else if (dataFor === "doc") {
      folderID = selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc
    }
    fetchMediaFolders(dataFor, folderID)
  }

  const handleOpenUploadMediaForAtt = (dataFor, attdatas) => {
    if (dataFor === "image") {
      setSelectedMediaFileForImageAtt(attdatas?.value)
      setSelectedMediaFolderIDForImageAtt(attdatas?.media_folder_id === "" ? 0 : attdatas?.media_folder_id)
      setSelectedMediaTypeForImageAtt(attdatas?.is_system === 0 ? "my-media" : attdatas?.is_system === 1 ? "system" : "")
    } else if (dataFor === "doc") {
      setSelectedMediaFileForDocAtt(attdatas?.value)
      setSelectedMediaFolderIDForDocAtt(attdatas?.media_folder_id === "" ? 0 : attdatas?.media_folder_id)
      setSelectedMediaTypeForDocAtt(attdatas?.is_system === 0 ? "my-media" : attdatas?.is_system === 1 ? "system" : "")
    }
    setMediaDataForAtt(dataFor)
    console.log("dataFor 1893::", dataFor)
    fetchMediaFoldersForAtt(dataFor, attdatas?.is_system === 0 ? "my-media" : attdatas?.is_system === 1 ? "system" : "", attdatas?.media_folder_id === "" ? 0 : attdatas?.media_folder_id)
    setAttData(attdatas);
    const allUserOption = fetchAllUsers.find(
      (user) => user.value == "all_users"
    );
    setPermissionsForMedia([allUserOption])
  }

  const handleCloseForUploadMedia = () => {
    setOpenMediaUpload(false)
    setFolderList()
    setMyFolderList([])
    setSystemFolderList([])
    setMediaDataFor("")
  }

  const handleCloseForUploadMediaForAtt = () => {
    setOpenMediaUploadForAtt(false)
    setFolderListForAtt()
    setMyFolderListForAtt([])
    setSystemFolderListForAtt([])
    setMediaDataForAtt("")

    setSelectedMediaFileForImageAtt("")
    setSelectedMediaFolderIDForImageAtt("")
    setSelectedMediaTypeForImageAtt("")
    setSelectedMediaTypeForDocAtt("")
    setSelectedMediaFolderIDForDocAtt("")
    setSelectedMediaTypeForImageAtt("")

    setSelectedMediaFileDetailsForAtt()
    setSelectedMediaFileForAtt("")

  }

  const handleCloseMediaModelAddMedia = () => {
    setOpenFileUploadModelMedia(true)
    let folder_id;
    if (mediaDataFor === "image") {
      folder_id = selectedMediaFolderIDForImage
      if (selectedMediaTypeForImage === "system") {
        setIsSystemTypeMedia(true)
        setIsParentFolderSystemType(true)
      } else {
        setIsSystemTypeMedia(false)
        setIsParentFolderSystemType(true)
      }

    }
    else if (mediaDataFor === "doc") {
      folder_id = selectedMediaFolderIDFordoc
      if (selectedMediaTypeForDoc === "system") {
        setIsSystemTypeMedia(true)
        setIsParentFolderSystemType(true)
      } else {
        setIsSystemTypeMedia(false)
        setIsParentFolderSystemType(true)
      }
    }
    setParentFolderId(folder_id)
  }

  const handleCloseMediaModelAddMediaAtt = () => {
    setOpenFileUploadModelMediaAtt(true)
    let folder_id;
    if (mediaDataForAtt === "image") {
      folder_id = selectedMediaFolderIDForImageAtt
      if (selectedMediaTypeForImageAtt === "system") {
        setIsSystemTypeMediaAtt(true)
        setIsParentFolderSystemTypeAtt(true)
      } else {
        setIsSystemTypeMediaAtt(false)
        setIsParentFolderSystemTypeAtt(true)
      }

    }
    else if (mediaDataForAtt === "doc") {
      folder_id = selectedMediaFolderIDFordocAtt
      if (selectedMediaTypeForDocAtt === "system") {
        setIsSystemTypeMediaAtt(true)
        setIsParentFolderSystemTypeAtt(true)
      } else {
        setIsSystemTypeMediaAtt(false)
        setIsParentFolderSystemTypeAtt(true)
      }
    }
    setParentFolderIdAtt(folder_id)
  }

  const handleImageFileValueCustAttForAtt = () => {
    if (mediaDataForAtt === "image") {
      handleChangeForFormData(
        attData?.nameValue,
        selectedMediaFileForImageAtt,
        attData?.section_id,
        false,
        "",
        true,
        selectedMediaFileDetailsForImageAtt
      );
    } else if (mediaDataForAtt === "doc") {
      handleChangeForFormData(
        attData?.nameValue,
        selectedMediaFileForDocAtt,
        attData?.section_id,
        false,
        "",
        true,
        selectedMediaFileDetailsForDocAtt
      );
    }
  };

  const handleCloseForMediaModel = () => {

    setOpenFileUploadModelMedia(false)
    setMediaTitle("")
    setMediaAltText("")
    setMediaDescription("")
    setMediaDirectUrl("")
    setIsSystemTypeMedia(false)
    setSelectedFileMedia(null)
    setMediaFile("")
    setValueForMediaTab(0)
    setEditMediType("")
    setSelectedMedia("")
    setResponseForMedia()
    setIsEditMedia(false)
    setIsRenameMedia(false)
    setOpenRenameModel(false)
    setOpenMediaModelFor("")
  }

  const handleCloseForMediaModelAtt = () => {

    setOpenFileUploadModelMediaAtt(false)
    setMediaTitleAtt("")
    setMediaAltTextAtt("")
    setMediaDescriptionAtt("")
    setMediaDirectUrlAtt("")
    setIsSystemTypeMediaAtt(false)
    setSelectedFileMediaAtt(null)
    setMediaFileAtt("")
    setValueForMediaTab(0)
    setEditMediType("")
    setSelectedMediaAtt("")
    setResponseForMediaAtt()
    setIsEditMediaAtt(false)
    setIsRenameMediaAtt(false)
    setOpenRenameModelAtt(false)
    setOpenMediaModelFor("")
  }

  const handleChangeForMedia = (event, newValue) => {
    setValueForMediaTab(newValue);
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles?.length > 0) {
      const file = acceptedFiles[0];
      const fileUrl = URL?.createObjectURL(file); // Create a URL for the file

      // Set the selected file with both file object and its URL
      if (openMediaModelFor === "normal") {
        setSelectedFileMedia({
          file,
          url: fileUrl,
          type: file?.type, // Get the MIME type from the dropped file
          name: file?.name // Use the file's name for display
        });
      } else {
        setSelectedFileMediaAtt({
          file,
          url: fileUrl,
          type: file?.type, // Get the MIME type from the dropped file
          name: file?.name // Use the file's name for display
        });
      }

    }
  }, [openMediaModelFor]);

  const acceptedFilesForUpload = () => {
    if (mediaDataFor === "image") {
      return {
        "image/*": [], // Accept all image types
      }
    } else if (mediaDataFor === "doc") {
      return {
        "application/pdf": [], // Accept PDFs
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], // DOCX
        "application/vnd.openxmlformats-officedocument.presentationml.presentation": [], // PPTX
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], // XLSX
      }
    }
  }

  const acceptedFilesForUploadAtt = () => {
    if (mediaDataForAtt === "image") {
      return {
        "image/*": [], // Accept all image types
      }
    } else if (mediaDataForAtt === "doc") {
      return {
        "application/pdf": [], // Accept PDFs
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], // DOCX
        "application/vnd.openxmlformats-officedocument.presentationml.presentation": [], // PPTX
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], // XLSX
      }
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: openMediaModelFor === "normal" ? acceptedFilesForUpload : acceptedFilesForUploadAtt,
    multiple: false,
  });

  const renderPreview = () => {
    if (!selectedFileMedia) return null;

    // Handle image files
    if (selectedFileMedia?.type?.startsWith('image/')) {
      return (
        <div className="image-preview">
          <img
            src={selectedFileMedia?.url}
            alt="Uploaded"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover", // Maintain aspect ratio
            }}
          />
        </div>
      );
    }

    // Handle PDF files
    if (selectedFileMedia?.type === 'application/pdf') {
      return (
        <Card
          key={"pdf"}
          variant="outlined"
          sx={{
            p: 2,
            mb: 2,
            cursor: "pointer",
          }}
          className="pdf-doc"
        >
          <Box
            height={150}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon
              size="3x"
              icon={
                faFilePdf
              }
              color="#660C60"
            />
          </Box>
          <Divider />
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            {selectedFileMedia?.name}
          </Box>
        </Card>
      );
    }

    // Handle DOCX files
    if (selectedFileMedia?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      return (
        <Card
          key={"pdf"}
          variant="outlined"
          sx={{
            p: 2,
            mb: 2,
            cursor: "pointer",
          }}
          className="pdf-doc"
        >
          <Box
            height={150}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon
              size="3x"
              icon={
                faFile
              }
              color="#660C60"
            />
          </Box>
          <Divider />
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            {selectedFileMedia?.name}
          </Box>
        </Card>
      );
    }

    // Handle PPTX files
    if (selectedFileMedia?.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
      return (
        <Card
          key={"pdf"}
          variant="outlined"
          sx={{
            p: 2,
            mb: 2,
            cursor: "pointer",
          }}
          className="pdf-doc"
        >
          <Box
            height={150}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon
              size="3x"
              icon={
                faFile
              }
              color="#660C60"
            />
          </Box>
          <Divider />
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            {selectedFileMedia?.name}
          </Box>
        </Card>
      );
    }

    if (selectedFileMedia?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return (
        <Card
          key={"pdf"}
          variant="outlined"
          sx={{
            p: 2,
            mb: 2,
            cursor: "pointer",
          }}
          className="pdf-doc"
        >
          <Box
            height={150}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon
              size="3x"
              icon={
                faFileExcel
              }
              color="#660C60"
            />
          </Box>
          <Divider />
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            {selectedFileMedia?.name}
          </Box>
        </Card>
      );
    }

    // Handle MP4 files
    if (selectedFileMedia?.type === 'video/mp4') {
      return (
        <Box className="w-100 h-100">
          <Typography>{selectedFileMedia?.name}</Typography>
          <ReactPlayer
            url={selectedFileMedia?.url}
            className="react-player"
            playing={true}
            width="100%"
            height="100%"
            controls={true}
          />
        </Box>
      );
    }

    // Handle MP3 files
    if (selectedFileMedia?.type === 'audio/mpeg') {
      return (
        <Box className="w-100 h-100 audio-file">
          <Typography>{selectedFileMedia?.name}</Typography>
          <ReactAudioPlayer
            src={selectedFileMedia?.url}
            controls
          />
        </Box>
      );
    }

    return (
      <Card
        key={"pdf"}
        variant="outlined"
        sx={{
          p: 2,
          mb: 2,
          cursor: "pointer",
        }}
        className="pdf-doc"
      >
        <Box
          height={150}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <FontAwesomeIcon
            size="3x"
            icon={
              faFilePdf
            }
            color="#660C60"
          />
        </Box>
        <Divider />
        <Box
          sx={{
            paddingLeft: 1,
          }}
        >
          {selectedFileMedia?.name}
        </Box>
      </Card>
    );
  }

  const renderPreviewAtt = () => {
    if (!selectedFileMediaAtt) return null;

    // Handle image files
    if (selectedFileMediaAtt?.type?.startsWith('image/')) {
      return (
        <div className="image-preview">
          <img
            src={selectedFileMediaAtt?.url}
            alt="Uploaded"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover", // Maintain aspect ratio
            }}
          />
        </div>
      );
    }

    // Handle PDF files
    if (selectedFileMediaAtt?.type === 'application/pdf') {
      return (
        <Card
          key={"pdf"}
          variant="outlined"
          sx={{
            p: 2,
            mb: 2,
            cursor: "pointer",
          }}
          className="pdf-doc"
        >
          <Box
            height={150}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon
              size="3x"
              icon={
                faFilePdf
              }
              color="#660C60"
            />
          </Box>
          <Divider />
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            {selectedFileMediaAtt?.name}
          </Box>
        </Card>
      );
    }

    // Handle DOCX files
    if (selectedFileMediaAtt?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      return (
        <Card
          key={"pdf"}
          variant="outlined"
          sx={{
            p: 2,
            mb: 2,
            cursor: "pointer",
          }}
          className="pdf-doc"
        >
          <Box
            height={150}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon
              size="3x"
              icon={
                faFile
              }
              color="#660C60"
            />
          </Box>
          <Divider />
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            {selectedFileMediaAtt?.name}
          </Box>
        </Card>
      );
    }

    // Handle PPTX files
    if (selectedFileMediaAtt?.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
      return (
        <Card
          key={"pdf"}
          variant="outlined"
          sx={{
            p: 2,
            mb: 2,
            cursor: "pointer",
          }}
          className="pdf-doc"
        >
          <Box
            height={150}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon
              size="3x"
              icon={
                faFile
              }
              color="#660C60"
            />
          </Box>
          <Divider />
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            {selectedFileMediaAtt?.name}
          </Box>
        </Card>
      );
    }

    if (selectedFileMediaAtt?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return (
        <Card
          key={"pdf"}
          variant="outlined"
          sx={{
            p: 2,
            mb: 2,
            cursor: "pointer",
          }}
          className="pdf-doc"
        >
          <Box
            height={150}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon
              size="3x"
              icon={
                faFileExcel
              }
              color="#660C60"
            />
          </Box>
          <Divider />
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            {selectedFileMediaAtt?.name}
          </Box>
        </Card>
      );
    }

    // Handle MP4 files
    if (selectedFileMediaAtt?.type === 'video/mp4') {
      return (
        <Box className="w-100 h-100">
          <Typography>{selectedFileMediaAtt?.name}</Typography>
          <ReactPlayer
            url={selectedFileMediaAtt?.url}
            className="react-player"
            playing={true}
            width="100%"
            height="100%"
            controls={true}
          />
        </Box>
      );
    }

    // Handle MP3 files
    if (selectedFileMediaAtt?.type === 'audio/mpeg') {
      return (
        <Box className="w-100 h-100 audio-file">
          <Typography>{selectedFileMediaAtt?.name}</Typography>
          <ReactAudioPlayer
            src={selectedFileMediaAtt?.url}
            controls
          />
        </Box>
      );
    }

    return (
      <Card
        key={"pdf"}
        variant="outlined"
        sx={{
          p: 2,
          mb: 2,
          cursor: "pointer",
        }}
        className="pdf-doc"
      >
        <Box
          height={150}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <FontAwesomeIcon
            size="3x"
            icon={
              faFilePdf
            }
            color="#660C60"
          />
        </Box>
        <Divider />
        <Box
          sx={{
            paddingLeft: 1,
          }}
        >
          {selectedFileMediaAtt?.name}
        </Box>
      </Card>
    );
  };

  const validateMedia = () => {
    let isValid = true;

    if (selectedFileMedia === null) {
      Toast.error("Please upload a media file");
      isValid = false;
    }
    return isValid;

  }

  const validateMediaAtt = () => {
    let isValid = true;

    if (mediaTitleAtt === "") {
      Toast.error("Please enter a title for media");
      isValid = false;
    }
    if (mediaAltTextAtt === "") {
      Toast.error("Please enter a alt text for media");
      isValid = false;
    }
    if (mediaDescriptionAtt === "") {
      Toast.error("Please enter a description for media");
      isValid = false;
    }
    if (selectedFileMediaAtt === null) {
      Toast.error("Please upload a media file");
      isValid = false;
    }
    return isValid;

  }

  const fetchMediaDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `user/media/file/edit/${selectedMedia}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        const responseData = response?.response
        setLoading(false);
        setMediaTitle(responseData?.title)
        setMediaAltText(responseData?.alt_text)
        setMediaDescription(responseData?.description)
        const permissions = responseData?.permission;
        setIsSystemTypeMedia(responseData?.is_system === 0 ? false : true)
        setResponseForMedia(responseData)
        const finalPermissions = permissions?.map((data) => {
          return {
            label: data?.label,
            value: data?.user_group_id,
            view: data?.can_view === 0 ? false : true,
            deny: data?.can_deny === 0 ? false : true,
            edit: data?.can_edit === 0 ? false : true,
            delete: data?.can_delete === 0 ? false : true,
            redacted: data?.can_resource === 0 ? false : true,
            type: data?.type,
          };
        });
        const fileExtension = responseData?.file_path?.split('.').pop().toLowerCase();
        const mimeType = getMimeType(fileExtension);  // You can create a helper function to get mime type from file extension
        setMediaDirectUrl(responseData?.file_path)
        setPermissionsForMedia(finalPermissions)
        setIsAdvancePermissionForMedia(
          responseData?.advance_permission === 0 ? false : true
        );
        setSelectedFileMedia({
          url: responseData?.file_path,
          type: mimeType,
          name: responseData?.title || "Uploaded File"
        });
        setOpenFileUploadModelMedia(true)
        Toast.success(response?.message);

      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);
        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
    }
  }

  const fetchMediaDetailsAtt = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `user/media/file/edit/${selectedMediaAtt}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        const responseData = response?.response
        setLoading(false);
        setMediaTitleAtt(responseData?.title)
        setMediaAltTextAtt(responseData?.alt_text)
        setMediaDescriptionAtt(responseData?.description)
        const permissions = responseData?.permission;
        setIsSystemTypeMediaAtt(responseData?.is_system === 0 ? false : true)
        setResponseForMediaAtt(responseData)
        const finalPermissions = permissions?.map((data) => {
          return {
            label: data?.label,
            value: data?.user_group_id,
            view: data?.can_view === 0 ? false : true,
            deny: data?.can_deny === 0 ? false : true,
            edit: data?.can_edit === 0 ? false : true,
            delete: data?.can_delete === 0 ? false : true,
            redacted: data?.can_resource === 0 ? false : true,
            type: data?.type,
          };
        });
        const fileExtension = responseData?.file_path?.split('.').pop().toLowerCase();
        const mimeType = getMimeType(fileExtension);  // You can create a helper function to get mime type from file extension
        setMediaDirectUrlAtt(responseData?.file_path)
        setPermissionsForMedia(finalPermissions)
        setIsAdvancePermissionForMedia(
          responseData?.advance_permission === 0 ? false : true
        );
        setSelectedFileMediaAtt({
          url: responseData?.file_path,
          type: mimeType,
          name: responseData?.title || "Uploaded File"
        });
        setOpenFileUploadModelMediaAtt(true)
        Toast.success(response?.message);

      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);
        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
    }
  }

  const fetchMediaDetailsForRename = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `user/media/file/edit/${selectedMedia}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        const responseData = response?.response
        setLoading(false);
        setMediaTitle(responseData?.title)
        setMediaAltText(responseData?.alt_text)
        setMediaDescription(responseData?.description)
        const permissions = responseData?.permission;
        setIsSystemTypeMedia(responseData?.is_system === 0 ? false : true)
        setResponseForMedia(responseData)
        const finalPermissions = permissions?.map((data) => {
          return {
            label: data?.label,
            value: data?.user_group_id,
            view: data?.can_view === 0 ? false : true,
            deny: data?.can_deny === 0 ? false : true,
            edit: data?.can_edit === 0 ? false : true,
            delete: data?.can_delete === 0 ? false : true,
            redacted: data?.can_resource === 0 ? false : true,
            type: data?.type,
          };
        });
        const fileExtension = responseData?.file_path?.split('.').pop().toLowerCase();
        const mimeType = getMimeType(fileExtension);  // You can create a helper function to get mime type from file extension
        setMediaDirectUrl(responseData?.file_path)
        setPermissionsForMedia(finalPermissions)
        setIsAdvancePermissionForMedia(
          responseData?.advance_permission === 0 ? false : true
        );
        setSelectedFileMedia({
          url: responseData?.file_path,
          type: mimeType,
          name: responseData?.title || "Uploaded File"
        });
        setOpenRenameModel(true)
        Toast.success(response?.message);

      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);
        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
    }
  }

  const fetchMediaDetailsForRenameAtt = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `user/media/file/edit/${selectedMediaAtt}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        const responseData = response?.response
        setLoading(false);
        setMediaTitleAtt(responseData?.title)
        setMediaAltTextAtt(responseData?.alt_text)
        setMediaDescriptionAtt(responseData?.description)
        const permissions = responseData?.permission;
        setIsSystemTypeMediaAtt(responseData?.is_system === 0 ? false : true)
        setResponseForMediaAtt(responseData)
        const finalPermissions = permissions?.map((data) => {
          return {
            label: data?.label,
            value: data?.user_group_id,
            view: data?.can_view === 0 ? false : true,
            deny: data?.can_deny === 0 ? false : true,
            edit: data?.can_edit === 0 ? false : true,
            delete: data?.can_delete === 0 ? false : true,
            redacted: data?.can_resource === 0 ? false : true,
            type: data?.type,
          };
        });
        const fileExtension = responseData?.file_path?.split('.').pop().toLowerCase();
        const mimeType = getMimeType(fileExtension);  // You can create a helper function to get mime type from file extension
        setMediaDirectUrlAtt(responseData?.file_path)
        setPermissionsForMedia(finalPermissions)
        setIsAdvancePermissionForMedia(
          responseData?.advance_permission === 0 ? false : true
        );
        setSelectedFileMediaAtt({
          url: responseData?.file_path,
          type: mimeType,
          name: responseData?.title || "Uploaded File"
        });
        setOpenRenameModelAtt(true)
        Toast.success(response?.message);

      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);
        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
    }
  }

  const storeMedia = async () => {
    const validate = await validateMedia()

    if (validate) {
      const formData = new FormData();
      let permissionData;
      formData.append("is_system", isSystemTypeMedia === true ? 1 : 0);
      formData.append("title", mediaTitle);
      formData.append("alt_text", mediaAltText);
      formData.append("description", mediaDescription);
      formData.append("advance_permission", 0);

      if (isAdvancePermissionForMedia === true) {
        permissionData = permissionsForMedia?.map((data) => {
          return {
            user_group_id: data?.value,
            type: data?.type,
            can_view: 0,
            can_deny: 0,
            can_edit: 0,
            can_delete: 0,
            can_resource: 0,
            label: data?.label,
          };
        });
      } else {
        permissionData = permissionsForMedia?.map((data) => {
          return {
            user_group_id: data?.value,
            type: data?.type,
            can_view: 0,
            can_deny: 0,
            can_edit: 0,
            can_delete: 0,
            can_resource: 0,
            label: data?.label,
          };
        });
      }
      if (parentFolderId) {
        formData.append("folder_id", parentFolderId)
      }

      if (isEditMedia === true) {
        formData.append("direct_url", responseForMedia?.file_path)
      }

      formData.append("permission", JSON?.stringify(permissionData));

      if (
        selectedFileMedia?.file instanceof Blob ||
        selectedFileMedia?.file instanceof File
      ) {
        formData.append("file", selectedFileMedia?.file);
      }

      if (isEditMedia === true) {
        try {
          setLoading(true);
          const response = await api.post(
            `user/media/file/update/${selectedMedia}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
          if (response?.success === true) {
            setLoading(false);
            let media_id;
            let folderID;

            if (mediaDataFor === "image") {
              setSelectedMediaFileForImage(response?.response?.id)
              media_id = response?.response?.id;
              setSelectedMediaFileDetailsForImage(response?.response)
              folderID = selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage
            } else if (mediaDataFor === "doc") {
              setSelectedMediaFileForDoc(response?.response?.id)
              media_id = response?.response?.id;
              setSelectedMediaFileDetailsForDoc(response?.response)
              folderID = selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc

            }

            await fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, media_id, folderID)
            Toast.success(response?.message);
            handleCloseForMediaModel()

          } else {
            setLoading(false);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("error in ctreate news api::", err);
          setLoading(false);

          if (err?.response?.status === 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status === 422) {
            setLoading(false);
            const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
          }
          if (err?.response?.status === 429) {
            Toast.error("Too many attempts wait for 2 min.")
          }
        }
      } else {
        try {
          setLoading(true);
          const response = await api.post(
            `user/media/file/store`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
          if (response?.success === true) {
            setLoading(false);

            Toast.success(response?.message);
            let media_id;
            let folderID;

            if (mediaDataFor === "image") {
              console.log("2282", response?.response?.id)
              setSelectedMediaFileForImage(response?.response?.id)
              media_id = response?.response?.id
              folderID = selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage
              setSelectedMediaFileDetailsForImage(response?.response)
            } else if (mediaDataFor === "doc") {
              setSelectedMediaFileForDoc(response?.response?.id)
              media_id = response?.response?.id
              folderID = selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc
              setSelectedMediaFileDetailsForDoc(response?.response)
            }

            fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, media_id, folderID)
            handleCloseForMediaModel()

          } else {
            setLoading(false);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("error in ctreate news api::", err);
          setLoading(false);

          if (err?.response?.status === 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status === 422) {
            setLoading(false);
            const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
          }
          if (err?.response?.status === 429) {
            Toast.error("Too many attempts wait for 2 min.")
          }
        }
      }

    }
  }

  const storeMediaAtt = async () => {
    const validate = await validateMediaAtt()

    if (validate) {
      const formData = new FormData();
      let permissionData;
      formData.append("is_system", isSystemTypeMediaAtt === true ? 1 : 0);
      formData.append("title", mediaTitleAtt);
      formData.append("alt_text", mediaAltTextAtt);
      formData.append("description", mediaDescriptionAtt);
      formData.append("advance_permission", 0);

      if (isAdvancePermissionForMedia === true) {
        permissionData = permissionsForMedia?.map((data) => {
          return {
            user_group_id: data?.value,
            type: data?.type,
            can_view: 0,
            can_deny: 0,
            can_edit: 0,
            can_delete: 0,
            can_resource: 0,
            label: data?.label,
          };
        });
      } else {
        permissionData = permissionsForMedia?.map((data) => {
          return {
            user_group_id: data?.value,
            type: data?.type,
            can_view: 0,
            can_deny: 0,
            can_edit: 0,
            can_delete: 0,
            can_resource: 0,
            label: data?.label,
          };
        });
      }
      if (parentFolderIdAtt) {
        formData.append("folder_id", parentFolderIdAtt)
      }

      if (isEditMediaAtt === true) {
        formData.append("direct_url", responseForMediaAtt?.file_path)
      }

      formData.append("permission", JSON?.stringify(permissionData));

      if (
        selectedFileMediaAtt?.file instanceof Blob ||
        selectedFileMediaAtt?.file instanceof File
      ) {
        formData.append("file", selectedFileMediaAtt?.file);
      }

      if (isEditMediaAtt === true) {
        try {
          setLoading(true);
          const response = await api.post(
            `user/media/file/update/${selectedMediaAtt}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
          if (response?.success === true) {
            setLoading(false);
            let media_id;
            let folder_id;
            let type;
            if (mediaDataForAtt === "image") {
              folder_id = selectedMediaFolderIDForImageAtt
              setSelectedMediaFileForImageAtt(response?.response?.id)
              media_id = response?.response?.id;
              setSelectedMediaFileDetailsForImageAtt(response?.response)
              if (selectedMediaTypeForImageAtt === "system") {
                type = "system"
              } else {
                type = "user"
              }
              // setSelectedFileMedia(response?.response?.id)
            } else if (mediaDataForAtt === "doc") {
              folder_id = selectedMediaFolderIDFordocAtt
              setSelectedMediaFileForDocAtt(response?.response?.id)
              media_id = response?.response?.id;
              setSelectedMediaFileDetailsForDocAtt(response?.response)
              if (selectedMediaTypeForDoc === "system") {
                type = "system"
              } else {
                type = "user"
              }
            }

            await fetchMediaFilesForAtt(pageForMedia, rowsPerPageForMedia, mediaDataForAtt, folder_id, type)
            Toast.success(response?.message);
            handleCloseForMediaModelAtt()

          } else {
            setLoading(false);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("error in ctreate news api::", err);
          setLoading(false);

          if (err?.response?.status === 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status === 422) {
            setLoading(false);
            const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
          }
          if (err?.response?.status === 429) {
            Toast.error("Too many attempts wait for 2 min.")
          }
        }
      } else {
        try {
          setLoading(true);
          const response = await api.post(
            `user/media/file/store`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
          if (response?.success === true) {
            setLoading(false);

            Toast.success(response?.message);
            let media_id;
            let folder_id;
            let type;
            if (mediaDataForAtt === "image") {
              folder_id = selectedMediaFolderIDForImageAtt
              console.log("2282", response?.response?.id)
              setSelectedMediaFileForImageAtt(response?.response?.id)
              media_id = response?.response?.id
              setSelectedMediaFileDetailsForImageAtt(response?.response)
              if (selectedMediaTypeForImageAtt === "system") {
                type = "system"
              } else {
                type = "user"
              }
            } else if (mediaDataForAtt === "doc") {
              folder_id = selectedMediaFolderIDFordocAtt
              setSelectedMediaFileForDocAtt(response?.response?.id)
              media_id = response?.response?.id
              setSelectedMediaFileDetailsForDocAtt(response?.response)
              if (selectedMediaTypeForDocAtt === "system") {
                type = "system"
              } else {
                type = "user"
              }
            }

            fetchMediaFilesForAtt(pageForMedia, rowsPerPageForMedia, mediaDataForAtt, folder_id, type)
            handleCloseForMediaModelAtt()

          } else {
            setLoading(false);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("error in ctreate news api::", err);
          setLoading(false);

          if (err?.response?.status === 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status === 422) {
            setLoading(false);
            const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
          }
          if (err?.response?.status === 429) {
            Toast.error("Too many attempts wait for 2 min.")
          }
        }
      }

    }
  }

  const renameMedia = async () => {
    const validate = await validateMedia()

    if (validate) {
      const formData = new FormData();
      let permissionData;
      formData.append("is_system", isSystemTypeMedia === true ? 1 : 0);
      formData.append("title", mediaTitle);
      formData.append("alt_text", mediaAltText);
      formData.append("description", mediaDescription);
      formData.append("advance_permission", 0);

      if (isAdvancePermissionForMedia === true) {
        permissionData = permissionsForMedia?.map((data) => {
          return {
            user_group_id: data?.value,
            type: data?.type,
            can_view: 0,
            can_deny: 0,
            can_edit: 0,
            can_delete: 0,
            can_resource: 0,
            label: data?.label,
          };
        });
      } else {
        permissionData = permissionsForMedia?.map((data) => {
          return {
            user_group_id: data?.value,
            type: data?.type,
            can_view: 0,
            can_deny: 0,
            can_edit: 0,
            can_delete: 0,
            can_resource: 0,
            label: data?.label,
          };
        });
      }

      formData.append("folder_id", parentFolderId)

      formData.append("direct_url", responseForMedia?.file_path)

      formData.append("permission", JSON?.stringify(permissionData));

      if (
        selectedFileMedia?.file instanceof Blob ||
        selectedFileMedia?.file instanceof File
      ) {
        formData.append("file", selectedFileMedia?.file);
      }
      try {
        setLoading(true);
        const response = await api.post(
          `user/media/file/update/${selectedMedia}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        if (response?.success === true) {
          setLoading(false);
          let media_id;
          let folderID;

          if (mediaDataFor === "image") {
            setSelectedMediaFileForImage(response?.response?.id)
            media_id = response?.response?.id;
            folderID = selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage
            setSelectedMediaFileDetailsForImage(response?.response)
          } else if (mediaDataFor === "doc") {
            setSelectedMediaFileForDoc(response?.response?.id)
            media_id = response?.response?.id;
            setSelectedMediaFileDetailsForDoc(response?.response)
            folderID = selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc

          }

          await fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, media_id, folderID)
          Toast.success(response?.message);
          handleCloseForMediaModel()

        } else {
          setLoading(false);
          Toast.error(response?.message);
        }
      } catch (err) {
        console.log("error in ctreate news api::", err);
        setLoading(false);

        if (err?.response?.status === 401) {
          setLoading(false);
          logoutHelper(dispatch, navigate, userToken);
        }
        if (err?.response?.status === 422) {
          setLoading(false);
          const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
        }
        if (err?.response?.status === 429) {
          Toast.error("Too many attempts wait for 2 min.")
        }
      }
    }
  }

  const renameMediaAtt = async () => {
    const validate = await validateMediaAtt()

    if (validate) {
      const formData = new FormData();
      let permissionData;
      formData.append("is_system", isSystemTypeMediaAtt === true ? 1 : 0);
      formData.append("title", mediaTitleAtt);
      formData.append("alt_text", mediaAltTextAtt);
      formData.append("description", mediaDescriptionAtt);
      formData.append("advance_permission", 0);

      if (isAdvancePermissionForMedia === true) {
        permissionData = permissionsForMedia?.map((data) => {
          return {
            user_group_id: data?.value,
            type: data?.type,
            can_view: 0,
            can_deny: 0,
            can_edit: 0,
            can_delete: 0,
            can_resource: 0,
            label: data?.label,
          };
        });
      } else {
        permissionData = permissionsForMedia?.map((data) => {
          return {
            user_group_id: data?.value,
            type: data?.type,
            can_view: 0,
            can_deny: 0,
            can_edit: 0,
            can_delete: 0,
            can_resource: 0,
            label: data?.label,
          };
        });
      }

      formData.append("folder_id", parentFolderIdAtt)

      formData.append("direct_url", responseForMediaAtt?.file_path)

      formData.append("permission", JSON?.stringify(permissionData));

      if (
        selectedFileMediaAtt?.file instanceof Blob ||
        selectedFileMediaAtt?.file instanceof File
      ) {
        formData.append("file", selectedFileMediaAtt?.file);
      }
      try {
        setLoading(true);
        const response = await api.post(
          `user/media/file/update/${selectedMediaAtt}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        if (response?.success === true) {
          setLoading(false);
          let media_id;
          let folder_id;
          let type;
          if (mediaDataForAtt === "image") {
            folder_id = selectedMediaFolderIDForImageAtt
            setSelectedMediaFileForImageAtt(response?.response?.id)
            media_id = response?.response?.id;
            setSelectedMediaFileDetailsForImageAtt(response?.response)
            if (selectedMediaTypeForImageAtt === "system") {
              type = "system"
            } else {
              type = "user"
            }
          } else if (mediaDataForAtt === "doc") {
            folder_id = selectedMediaFolderIDFordocAtt
            setSelectedMediaFileForDocAtt(response?.response?.id)
            media_id = response?.response?.id;
            setSelectedMediaFileDetailsForDocAtt(response?.response)
            if (selectedMediaTypeForDoc === "system") {
              type = "system"
            } else {
              type = "user"
            }

          }

          await fetchMediaFilesForAtt(pageForMedia, rowsPerPageForMedia, mediaDataForAtt, folder_id, type)
          Toast.success(response?.message);
          handleCloseForMediaModelAtt()

        } else {
          setLoading(false);
          Toast.error(response?.message);
        }
      } catch (err) {
        console.log("error in ctreate news api::", err);
        setLoading(false);

        if (err?.response?.status === 401) {
          setLoading(false);
          logoutHelper(dispatch, navigate, userToken);
        }
        if (err?.response?.status === 422) {
          setLoading(false);
          const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
        }
        if (err?.response?.status === 429) {
          Toast.error("Too many attempts wait for 2 min.")
        }
      }
    }
  }

  const deleteMedia = async (id) => {
    let mediID;
    if (mediaDataFor === "image") {
      mediID = selectedMediaFileForImage
    } else if (mediaDataFor === "doc") {
      mediID = selectedMediaFileForDoc
    }
    try {
      setLoading(true);
      const response = await api.post(
        `user/media/file/delete/${mediID}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        setLoading(false);

        Toast.success(response?.message);
        let media_id;
        let folderID;

        if (mediaDataFor === "image") {
          setSelectedMediaFileForImage("")
          media_id = "";
          folderID = selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage
          setSelectedMediaFileDetailsForImage()
        } else if (mediaDataFor === "doc") {
          setSelectedMediaFileForDoc("")
          media_id = "";
          folderID = selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc
          setSelectedMediaFileDetailsForDoc()
        }

        await fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, media_id, folderID)
        handleCloseForMediaModel()

      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
    }
  }

  const deleteMediaAtt = async (id) => {
    let mediID;
    if (mediaDataForAtt === "image") {
      mediID = selectedMediaFileForImageAtt
    } else if (mediaDataForAtt === "doc") {
      mediID = selectedMediaFileForDocAtt
    }
    try {
      setLoading(true);
      const response = await api.post(
        `user/media/file/delete/${mediID}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        setLoading(false);

        Toast.success(response?.message);
        let media_id;
        let folder_id;
        let type;
        if (mediaDataForAtt === "image") {
          folder_id = selectedMediaFolderIDForImageAtt
          setSelectedMediaFileForImageAtt("")
          media_id = "";
          setSelectedMediaFileDetailsForImageAtt()
          if (selectedMediaTypeForImageAtt === "system") {
            type = "system"
          } else {
            type = "user"
          }
        } else if (mediaDataForAtt === "doc") {
          folder_id = selectedMediaFolderIDFordocAtt
          setSelectedMediaFileForDocAtt("")
          media_id = "";
          setSelectedMediaFileDetailsForDocAtt()
          if (selectedMediaTypeForDocAtt === "system") {
            type = "system"
          } else {
            type = "user"
          }
        }

        await fetchMediaFilesForAtt(pageForMedia, rowsPerPageForMedia, mediaDataForAtt, folder_id, type)
        handleCloseForMediaModel()

      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
    }
  }

  const fetchMediaFolders = async (dataFor, folderID) => {
    try {
      setLoading(true);
      const response = await api.get(
        `user/media/browse/folders`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success == true) {
        setFolderList(response?.response)
        setMyFolderList(response?.response?.my_media_folder)
        setSystemFolderList(response?.response?.system_media_folder)
        let media_id;
        if (dataFor == "image") {
          setSelectedMediaTypeForImage(selectedMediaTypeForImage == "" ? "my-media" : selectedMediaTypeForImage)
          media_id = selectedMediaFileForImage
        } else if (dataFor == "doc") {
          setSelectedMediaTypeForDoc(selectedMediaTypeForDoc == "" ? "my-media" : selectedMediaTypeForDoc)
          media_id = selectedMediaFileForDoc
        }
        fetchMediaFiles(pageForMedia, rowsPerPageForMedia, dataFor, media_id, folderID)
        setOpenMediaUpload(true)
        setLoading(false)
      } else if (response?.success == false) {
        setLoading(false)
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
    }
  }

  const fetchMediaFoldersForAtt = async (dataFor, mediaType, folder_id) => {
    try {
      setLoading(true);
      const response = await api.get(
        `user/media/browse/folders`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        setFolderListForAtt(response?.response)
        setMyFolderListForAtt(response?.response?.my_media_folder)
        setSystemFolderListForAtt(response?.response?.system_media_folder)
        let type;

        if (dataFor === "image") {
          if (dataFor === "image") {
            if (mediaType === "system") {
              type = "system"
            } else {
              type = "user"
            }
          }
        } else if (dataFor === "doc") {
          if (dataFor === "doc") {
            if (mediaType === "system") {
              type = "system"
            } else {
              type = "user"
            }
          }
        }
        await fetchMediaFilesForAtt(pageForMediaAtt, rowsPerPageForMediaAtt, dataFor, folder_id, type)
        setOpenMediaUploadForAtt(true)
        setLoading(false)
      } else if (response?.success === false) {
        setLoading(false)
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
    }
  }

  const fetchMediaFiles = async (pageNo, perPage, dataFor, mediaFile, folderID) => {
    let type;
    if (dataFor === "image") {
      if (selectedMediaTypeForImage === "system") {
        type = "system"
      } else {
        type = "user"
      }
    } else if (dataFor === "doc") {
      if (selectedMediaTypeForDoc === "system") {
        type = "system"
      } else {
        type = "user"
      }
    }
    try {
      setLoading(true);
      const response = await api.post(
        `user/media/browse/files?page=${pageNo}`,
        {
          folder_id: folderID,
          type: type,
          per_page: perPage,
          file_type: dataFor
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {

        setMediaListResponse(response?.response)
        setMediaList(response?.response?.data)
        if (dataFor === "image" && mediaFile !== "") {
          console.log("line 3928", mediaFile)
          setSelectedMediaFileForImage(mediaFile)
          setSelectedMediaFolderIDForImage(selectedMediaFolderIDForImage)
        } else if (dataFor === "doc" && selectedMediaFileForDoc !== "") {
          setSelectedMediaFileForDoc(selectedMediaFileForDoc)
          setSelectedMediaFolderIDForDoc(selectedMediaFolderIDFordoc)
        }
        setLoading(false)
      } else if (response?.success === false) {
        setLoading(false)
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
    }
  }

  const fetchMediaFilesForAtt = async (pageNo, perPage, dataFor, folder_id, mediatype) => {
    let type;
    if (dataFor === "image") {
      if (mediatype === "system") {
        type = "system"
      } else {
        type = "user"
      }
    } else if (dataFor === "doc") {
      if (mediatype === "system") {
        type = "system"
      } else {
        type = "user"
      }
    }
    try {
      setLoading(true);
      const response = await api.post(
        `user/media/browse/files?page=${pageNo}`,
        {
          folder_id: folder_id,
          type: type,
          per_page: perPage,
          file_type: dataFor
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        setMediaListResponseAtt(response?.response)
        setMediaListAtt(response?.response?.data)
        if (dataFor === "image" && selectedMediaFileForImageAtt !== "") {

        } else if (dataFor === "doc" && selectedMediaFileForDocAtt !== "") {

        }
        setLoading(false)
      } else if (response?.success === false) {
        setLoading(false)
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
    }
  }

  const fetchGroupsAndUsersList = async () => {
    try {
      const response = await api.get(`/group-users-list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        let acknowledgementQuestionsArray = [];

        const users = response?.response?.users;
        const groups = response?.response?.groups;

        const categories = response?.response?.category

        const finalGroups = groups?.map((data) => {
          return {
            label: data?.label,
            value: data?.value,
            view: false,
            deny: false,
            edit: false,
            delete: false,
            redacted: false,
            type: data?.type,
          };
        });
        const finalUsers = users?.map((data) => {
          return {
            label: data?.label,
            value: data?.value,
            view: false,
            deny: false,
            edit: false,
            delete: false,
            redacted: false,
            type: data?.type,
          };
        });
        setFetchAllUsers(finalUsers)

        const allGroupsAndUsersData = [
          { label: "Users", options: finalUsers },
          { label: "Groups", options: finalGroups },
        ];
        // Find the "all_user" option
        const allUserOption = finalUsers.find(
          (user) => user.value == "all_users"
        );

        setGetAllGroupsAndUsersForMedia(allGroupsAndUsersData)

        // Preselect "all_user"
        if (allUserOption && !isEdit) {
          setPermissionsForMedia([allUserOption])
        }
        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        Toast.warning(err?.response?.message);
        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        Toast.warning(err?.response?.data[0]?.message);
        navigate("/");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
    }
  }

  const handleFileChange = (file, setFieldValue) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (contentForPost === "image") {
          setIsEdit(false);
          setSelectedImageFile(file);
        } else if (contentForPost === "file") {
          setSelectedFile(file);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCloseWarningMessage = (index) => {
    const updatedMessages = [...warningMessage];
    updatedMessages.splice(index, 1);
    setWarningMessage(updatedMessages);
  };

  const handleOpenSuccessMessage = () => {
    setIsSuccessMessage(true);
    setIsSuccessMessage("");
  };

  const handleCloseSuccessMessage = () => {
    setIsSuccessMessage(false);
    setIsSuccessMessage("");
  };

  const handleCloseForAddAttributeModel = () => {
    setOpenAddAttribute(false)
    setValue(0)
  }

  const handleChangeForTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setPage(newPage);
      fetchAllChangeLogs(newPage + 1);
    } else if (newPage < page) {
      setPage(newPage);
      fetchAllChangeLogs(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const transformData = (data) => {
    const result = {};

    data?.forEach(category => {
      const termIds = category?.term_value?.map(Number);
      result[category?.slug] = category?.categoryterm?.filter(term =>
        termIds?.includes(term?.id)).map(term => ({
          label: term?.name,
          value: term?.id,
          categoryId: term?.category_id
        }));
    });

    return result;
  };


  const isValidURL = (url) => {
    const pattern = new RegExp(
      /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?$/
    );
    return !!pattern.test(url);
  };

  const isValidEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const transformCategoryData = (categories) => {
    return categories?.map(category => ({
      label: category?.title,
      slug: category?.slug,
      id: category?.id,
      options: category?.categoryterm?.map(term => ({
        label: term?.name,
        value: term?.id,
        categoryId: category?.id
      }))
    }));
  };

  const handleCategoryChange = (categoryLabel, selectedOption) => {
    setSelectedCategories(prevState => ({
      ...prevState,
      [categoryLabel]: selectedOption
    }));
  };

  const handleAddTextField = (
    nameValue,
    label,
    section_id,
    fieldtype,
    options,
    is_mandatory,
    repeatableAtt,
    valueAtt
  ) => {
    const lastId =
      additionalTextFields.length > 0
        ? additionalTextFields[additionalTextFields.length - 1].id
        : null;

    console.log("lastId::", lastId)

    const newTextField = {
      id: lastId + 1, // Generate a unique ID for the new text field
      nameValue: nameValue,
      value: "",
      label: label,
      section_id: section_id, // Set the appropriate tab value
      fieldtype: fieldtype,
      is_mandatory: is_mandatory,
      ...(options && { options: options }), // Conditionally include options if available
    };
    setAdditionalTextFields([...additionalTextFields, newTextField]);
    if (repeatableAtt == true) {
      const updatedValue = Array.isArray(valueAtt)
        ? [...valueAtt, newTextField]
        : newTextField;
      handleChangeForFormData(nameValue, updatedValue, section_id, false, "", true);
    }
  };

  const handleAddNestedTextField = (
    parentId,
    nameValue,
    label,
    section_id,
    fieldtype,
    options,
    is_mandatory,
    repeatableAtt,
    valueAtt,
    attdata,
    show_option_value,
    max_repeat,
    nestedChildAtt1
  ) => {
    console.log("attdata add nested::", attdata)
    console.log("nestedChildAtt1::", nestedChildAtt1)

    const fetch_repeatableAtts = additionalTextFieldsForNestedChildAtt1?.filter((data) => data?.nameValue == nameValue)

    if (fetch_repeatableAtts?.length < max_repeat) {
      const lastId =
        additionalTextFieldsForNestedChildAtt1.length > 0
          ? additionalTextFieldsForNestedChildAtt1[additionalTextFieldsForNestedChildAtt1.length - 1].id
          : null;

      const newNestedTextField = {
        id: lastId + 1, // Generate a unique ID for the new text field
        parentId: nestedChildAtt1?.parent_id, // Parent ID to link with the parent attribute
        nameValue: nameValue,
        value: "",
        label: label,
        section_id: section_id, // Set the appropriate tab value
        fieldtype: fieldtype,
        is_mandatory: is_mandatory,
        show_option_value: show_option_value,
        ...(options && { options: options }), // Conditionally include options if available
      };

      setAdditionalTextFieldsForNestedChildAtt1([...additionalTextFieldsForNestedChildAtt1, newNestedTextField]);

      if (repeatableAtt == true) {
        const updatedValue = [...additionalTextFieldsForNestedChildAtt1, newNestedTextField]
        console.log("updatedValue::", updatedValue)
        handleNestedChildChange(
          nameValue,
          updatedValue,
          section_id,
          parentId,
          attdata,
          show_option_value
        );
      }
    }
  };

  const handleChangeForFormData = (
    nameValue,
    value,
    section_id,
    isChildAtt,
    subField,
    isAppendDirectly,
    mediaData
  ) => {

    setAttributes((prevAttributes) => {
      const updatedAttributes = prevAttributes?.map((attribute) => {
        if (
          attribute?.section_id === section_id &&
          attribute?.nameValue === nameValue
        ) {
          // Update the value of the attribute
          let updatedAttribute = { ...attribute, value };

          if (attribute?.fieldtype === "link_object" && isAppendDirectly == false) {
            const currentLinkObject = attribute?.value || {};
            updatedAttribute.value = {
              ...currentLinkObject,
              [subField]: value
            };

          } else {
            updatedAttribute.value = value;
            // If the fieldtype is radio_single_choice and options are provided
            if (
              attribute?.fieldtype === "radio_single_choice" &&
              attribute?.options
            ) {
              const updatedOptions = attribute?.options?.map((option) => {
                // Set the checked property based on the selected value
                return { ...option, checked: option?.value == parseInt(value) };
              });
              // Update the options of the attribute
              updatedAttribute = { ...updatedAttribute, options: updatedOptions };
            }

            if (attribute.fieldtype === "tags") {
              // Initialize options with current options or an empty array
              let options = attribute?.options || [];

              // Separate existing and new options
              const existingOptions = value?.filter((option) => !option.__isNew__);
              const newOptions = value?.filter((option) => option?.__isNew__);

              // Add new options to the existing options list
              if (newOptions?.length > 0) {
                options = [
                  ...options,
                  ...newOptions?.map((option) => ({
                    value: option?.value,
                    label: option?.label,
                  })),
                ];
              }

              // Extract selected values from the value array
              const selectedValues = value?.map((option) => option?.value);

              // Update the attribute with new options and selected values
              updatedAttribute = {
                ...updatedAttribute,
                options: options,
                value: selectedValues,
              };
            }

            // If the fieldtype is checkbox_multiple_choice
            if (
              attribute?.fieldtype === "checkbox_multiple_choice" &&
              attribute?.options
            ) {
              const updatedOptions = attribute?.options?.map((option) => {
                // Set the checked property based on whether the option ID is included in the selected values array
                return { ...option, checked: value?.includes(option?.id) };
              });
              // Update the options of the attribute
              updatedAttribute = { ...updatedAttribute, options: updatedOptions };
            }

            if (attribute?.fieldtype === "image") {
              updatedAttribute = {
                ...updatedAttribute,
                url: mediaData?.file_path,
                media_folder_id: selectedMediaFolderIDForImageAtt,
                is_system: selectedMediaTypeForImageAtt == "my-media" ? 0 : 1,
                file_size: mediaData?.file_size,
                title: mediaData?.title
              };
            }

            if (attribute?.fieldtype === "file") {
              updatedAttribute = {
                ...updatedAttribute,
                name: mediaData?.title,
                media_folder_id: selectedMediaFolderIDFordocAtt,
                is_system: selectedMediaTypeForDocAtt == "my-media" ? 0 : 1,
                file_size: mediaData?.file_size,
                title: mediaData?.title
              };
            }

            if (attribute?.fieldtype === "image_file") {
              updatedAttribute = {
                ...updatedAttribute,
                url: mediaData?.file_path,
                media_folder_id: selectedMediaFolderIDForImageAtt,
                is_system: selectedMediaTypeForImageAtt == "my-media" ? 0 : 1,
                file_size: mediaData?.file_size,
                title: mediaData?.title,
                name: mediaData?.title,
                type: mediaData?.type,
                value: mediaData?.id
              };
            }

            if (isChildAtt == true) {
              updatedAttribute = { ...updatedAttribute, childAtts: value };
            }
          }

          return updatedAttribute;
        }
        return attribute;
      });
      return updatedAttributes;
    });
  };

  const handleChangeForChildAtts = (
    nameValue,
    value,
    section_id,
    childAtts,
    attdata
  ) => {
    const findAtt = attributesData
      .filter((data) => data?.id == attdata?.id)
      .map((data) => {
        return {
          id: data?.id,
          section_id: data?.section_id,
          label: data?.label,
          is_mandatory: data?.is_mandatory,
          nameValue: data?.nameValue,
          fieldtype: data?.fieldtype,
          options: data?.options,
          parentID: data?.parentID,
          child_attribute: data?.child_attribute,
          value: data?.value,
        };
      });

    const updatedChildAtts = findAtt.flatMap((att) => {
      return att?.child_attribute?.map((childAtt) => {
        if (
          childAtt?.section_id === section_id &&
          childAtt?.nameValue === nameValue
        ) {
          // If the fieldtype is checkbox_multiple_choice
          if (
            childAtt?.fieldtype === "checkbox_multiple_choice" &&
            childAtt?.options
          ) {
            const updatedOptions = childAtt?.options?.map((option) => {
              // Set the checked property based on whether the option ID is included in the selected values array
              return { ...option, checked: value?.includes(option?.id) };
            });

            // Update the options of the child attribute
            return { ...childAtt, options: updatedOptions, value };
          } else {
            // If the fieldtype is not checkbox_multiple_choice, simply update the value
            return { ...childAtt, value };
          }
        }
        return childAtt;
      });
    });

    const updatedAttData = attributesData.map((att) => {
      if (att?.id == attdata?.id) {
        return { ...att, child_attribute: updatedChildAtts };
      }
      return att;
    });
    setAttributes(updatedAttData);
  };

  const handleNestedChildChange = (
    nameValue,
    value,
    section_id,
    parentChildAtt,
    attdata,
    show_option_value
  ) => {

    const findAtt = attributesData
      .filter((data) => data?.id == attdata?.id)
      .map((data) => {
        return {
          id: data?.id,
          section_id: data?.section_id,
          label: data?.label,
          is_mandatory: data?.is_mandatory,
          nameValue: data?.nameValue,
          fieldtype: data?.fieldtype,
          options: data?.options,
          parentID: data?.parentID,
          child_attribute: data?.child_attribute,
          value: data?.value,
        };
      });

    const updatedChildAtts = findAtt?.flatMap((att) => {
      return att?.child_attribute?.map((childAtt) => {

        if (
          childAtt?.section_id === section_id &&
          childAtt?.nameValue === parentChildAtt?.nameValue
        ) {
          const updatedNestedChildAtts = childAtt?.child_attribute?.map((nestedChildAtt) => {
            if (
              nestedChildAtt?.section_id === section_id &&
              nestedChildAtt?.nameValue === nameValue &&
              nestedChildAtt?.show_option_value == show_option_value
            ) {
              return { ...nestedChildAtt, value };
            }
            return nestedChildAtt;
          });

          return { ...childAtt, child_attribute: updatedNestedChildAtts };
        }
        return childAtt;
      });
    });

    const updatedAttData = attributesData.map((att) => {
      if (att?.id == attdata?.id) {
        return { ...att, child_attribute: updatedChildAtts };
      }
      return att;
    });

    setAttributes(updatedAttData);

  };

  const handleChangeForAdditionalTextField = (
    id,
    value,
    nameValue,
    section_id,
    fieldtype,
    subField
  ) => {
    let updatedTextFields;
    if (fieldtype === "link_object") {
      updatedTextFields = additionalTextFields?.map((field) =>
        field?.id == id && field?.nameValue == nameValue
          ? { ...field, value: { ...field?.value, [subField]: value } }
          : field
      );
    } else {
      updatedTextFields = additionalTextFields?.map((field) =>
        field?.id == id && field?.nameValue == nameValue
          ? { ...field, value: value }
          : field
      );
    }

    setAdditionalTextFields(updatedTextFields);

    const finalData = updatedTextFields?.filter(
      (data) => data?.nameValue == nameValue
    );

    handleChangeForFormData(nameValue, finalData, section_id, false, "", true);
  };

  const removeExtraFields = (id, nameValue, section_id) => {

    const updatedTextFields = additionalTextFields?.filter(
      (field) => !(field?.id == id && field?.nameValue == nameValue)
    );
    setAdditionalTextFields(updatedTextFields);
    const anotherupdatedTextFields = updatedTextFields?.filter((field) => field?.nameValue == nameValue)
    handleChangeForFormData(nameValue, anotherupdatedTextFields, section_id, false, "", true);
  };

  const handleImageFileValue = (setFieldValue) => {
    if (
      selectedImageFile instanceof Blob ||
      selectedImageFile instanceof File ||
      selectedFile instanceof Blob ||
      selectedFile instanceof File
    ) {
      if (contentForPost === "image") {
        setFieldValue("image", URL.createObjectURL(selectedImageFile));
        setOpenFileUploadModel(false);
      } else {
        setFieldValue("file", selectedFile);
        setOpenFileUploadModel(false);
      }
    } else {
      console.error("Selected file is not a valid Blob or File object");
    }
  };

  const handleClickForAtt = (content, attdatas) => {
    setContentForPostForAtt(content);
    setAttData(attdatas);
    setOpenFileUploadModelForAtt(true);
    if (
      selectedImageFileForAtt instanceof Blob ||
      selectedImageFileForAtt instanceof File ||
      selectedFileForAtt instanceof Blob ||
      selectedFileForAtt instanceof File
    ) {
      setIsEdit(false)
    } else {
      setIsEdit(true)
    }
    if (attdatas?.value !== "") {
      if (content == "image") {
        setSelectedImageFileForAtt(selectedImageFileForAtt);
      }
      if (content == "file") {
        setSelectedFileForAtt(selectedFileForAtt);
      }
    }
  };

  const handleFileChangeForAtt = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (contentForPostForAtt === "image") {
          setIsEdit(false);
          setSelectedImageFileForAtt(file);
        } else if (contentForPostForAtt === "file") {
          setSelectedFileForAtt(file);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageFileValueForAtt = () => {
    if (
      selectedImageFileForAtt instanceof Blob ||
      selectedImageFileForAtt instanceof File ||
      selectedFileForAtt instanceof Blob ||
      selectedFileForAtt instanceof File
    ) {
      if (contentForPostForAtt == "image") {
        handleChangeForFormData(
          attData?.nameValue,
          URL.createObjectURL(selectedImageFileForAtt),
          attData?.section_id
        );
        setOpenFileUploadModelForAtt(false);
      } else {
        handleChangeForFormData(
          attData?.nameValue,
          selectedFileForAtt,
          attData?.section_id
        );

        setOpenFileUploadModelForAtt(false);

      }
    } else {
      console.error("Selected file is not a valid Blob or File object");
    }
  };

  const validateAttributes = () => {
    let isValid = true;
    let errorMessages = [];

    const validateField = (attribute, labelPrefix = "") => {
      if (attribute?.is_mandatory === true) {
        if (!attribute?.is_repeatable) {
          if (
            attribute?.value === null ||
            attribute?.value === "" ||
            attribute?.value == NaN && attribute?.fieldtype !== "link_object"
          ) {
            isValid = false;

            Toast.error(
              `The field "${labelPrefix}${attribute.label}" is required.`
            );

            errorMessages.push(
              `The field1 "${labelPrefix}${attribute.label}" is required.`
            );
          }
          // Email validation for non-repeatable fields
          if (attribute?.fieldtype === "email" && attribute?.value) {
            if (!isValidEmail(attribute.value)) {
              isValid = false;
              Toast.error(
                `The field "${labelPrefix}${attribute.label}" must be a valid email.`
              );
              errorMessages.push(
                `The field "${labelPrefix}${attribute.label}" must be a valid email.`
              );
            }
          }
          if (attribute?.fieldtype === "url" && attribute?.value) {
            if (!isValidURL(attribute.value)) {
              isValid = false;
              Toast.error(
                `The field "${labelPrefix}${attribute.label}" must be a valid url.`
              );
              errorMessages.push(
                `The field "${labelPrefix}${attribute.label}" must be a valid url.`
              );
            }
          }
          if (
            attribute?.fieldtype === "checkbox_multiple_choice" &&
            attribute?.value
          ) {
            if (attribute?.value?.length < 1) {
              isValid = false;
              Toast.error(
                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
              );
              errorMessages.push(
                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
              );
            }
          }
          if (
            attribute?.fieldtype === "dropdodown_multiple_choice" &&
            attribute?.value
          ) {
            if (attribute?.value?.length < 1) {
              isValid = false;
              Toast.error(
                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
              );
              errorMessages.push(
                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
              );
            }
          }
          if (attribute?.fieldtype === "tags" && attribute?.value) {
            if (attribute?.value?.length < 1) {
              isValid = false;
              Toast.error(
                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
              );
              errorMessages.push(
                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
              );
            }
          }
          if (attribute.fieldtype === "link_object" && attribute?.value) {
            if (!attribute.value?.title || attribute.value.title === "") {
              isValid = false;
              Toast.error(`The field "${labelPrefix}${attribute.label}  Title" is required.`);
              errorMessages.push(`The field "${labelPrefix}${attribute.label}  Title" is required.`);
            }
            if (attribute.value?.url && !isValidURL(attribute.value.url)) {
              isValid = false;
              Toast.error(`The field "${labelPrefix}${attribute.label}  URL" must be a valid URL.`);
              errorMessages.push(`The field "${labelPrefix}${attribute.label} URL" must be a valid URL.`);
            }
          }
        } else {
          if (
            !Array.isArray(attribute?.value) ||
            attribute?.value.length === 0
          ) {
            isValid = false;

            Toast.error(
              `At least one "${labelPrefix}${attribute.label}" is required.`
            );
            errorMessages.push(
              `At least one "${labelPrefix}${attribute.label}" is required.`
            );
          }

          else {
            attribute.value.forEach((item, index) => {
              if (
                item?.is_mandatory &&
                (!item.value || item.value === "" || item.value == NaN)
              ) {
                isValid = false;

                Toast.error(
                  `The field "${labelPrefix}${attribute.label} #${index + 1
                  }" is required.`
                );
                errorMessages.push(
                  `The field2 "${labelPrefix}${attribute.label} #${index + 1
                  }" is required.`
                );
              }

              if (attribute.fieldtype === "link_object") {
                if (!item.value?.title || item.value.title === "") {
                  isValid = false;
                  Toast.error(`The field "${labelPrefix}${attribute.label} #${index + 1} Title" is required.`);
                  errorMessages.push(`The field "${labelPrefix}${attribute.label} #${index + 1} Title" is required.`);
                }
                if (item.value?.url && !isValidURL(item.value.url)) {
                  isValid = false;
                  Toast.error(`The field "${labelPrefix}${attribute.label} #${index + 1} URL" must be a valid URL.`);
                  errorMessages.push(`The field "${labelPrefix}${attribute.label} #${index + 1} URL" must be a valid URL.`);
                }
              }
            });
          }
        }
      }

      if (attribute?.nameValue === "checkbox_single_choice_acknowledgement" && attribute?.value == true) {

        if (attribute?.childAtts[0]?.value?.length < 1) {
          isValid = false;
          Toast.error("Please add atleast one user for acknowledge")
        } if (attribute?.childAtts[1]?.value == "") {
          isValid = false;
          Toast.error("Please select due by date for acknowledge")
        } if (attribute?.childAtts[2]?.value == "") {
          isValid = false;
          Toast.error("Please select due by expiry action for acknowledge")
        } if (attribute?.childAtts[3]?.value == "") {
          isValid = false;
          Toast.error("Please select reminder notification for acknowledge")
        } if (attribute?.childAtts[4]?.value == 1) {
          if (attribute?.childAtts[4]?.childAtts[0]?.value == "") {
            isValid = false;
            Toast.error("Please enter acknowledgement texts")
          }
        }
        if (attribute?.childAtts[4]?.value == 2) {
          if (attribute?.childAtts[4]?.childAtts[1]?.value?.length < 1) {
            isValid = false;
            Toast.error("Please add atleast one acknowledgement question")
          } else if (attribute?.childAtts[4]?.childAtts[1]?.value?.length > 0) {
            attribute?.childAtts[4]?.childAtts[1]?.value?.forEach((childAtt, index) => {
              if (childAtt?.value == "") {
                isValid = false;
                Toast.error(
                  `Please add acknowledgement question for ${index + 1} is required.`
                );
              }
            })
          }
        }
      }
      // Check child attributes if they exist
      if (
        (attribute?.value === true ||
          attribute?.value === 1 ||
          attribute?.value == NaN) &&
        attribute?.childAtts?.length > 0
      ) {
        attribute?.childAtts?.forEach((childAtt) =>
          validateField(childAtt, `${labelPrefix}${attribute?.label} - `)
        );
      }
    };

    attributesData?.forEach((attribute) => validateField(attribute));

    return { isValid, errorMessages };
  };

  const handleChangeForNestedChildAdditionalTextField = (
    id,
    value,
    nameValue,
    section_id,
    fieldtype,
    subField,
    parentId,
    attdata,
    show_option_value,
    textField
  ) => {

    let updatedTextFields;
    if (fieldtype === "link_object") {
      updatedTextFields = additionalTextFieldsForNestedChildAtt1?.map((field) =>
        field?.id == id && field?.nameValue == nameValue
          ? { ...field, value: { ...field?.value, [subField]: value } }
          : field
      );
    } else {
      updatedTextFields = additionalTextFieldsForNestedChildAtt1?.map((field) =>
        field?.id == id && field?.nameValue == nameValue
          ? { ...field, value: value }
          : field
      );
    }

    setAdditionalTextFieldsForNestedChildAtt1(updatedTextFields);

    const anotherUpdatedNestedTextFields = updatedTextFields?.filter(
      (field) => field?.nameValue == nameValue && field?.parentId == parentId?.attribute_id
    );

    handleNestedChildChange(nameValue, anotherUpdatedNestedTextFields, section_id, parentId, attdata, show_option_value);
  };

  const removeNestedExtraFields = (id, nameValue, section_id, parentId, show_option_value, attdata) => {
    console.log("attdata remove nested::", attdata)
    // Filter out the nested text field to be removed
    const updatedNestedTextFields = additionalTextFieldsForNestedChildAtt1?.filter(
      (field) => !(field?.id === id && field?.nameValue === nameValue && field?.parentId === parentId?.attribute_id)
    );

    // Update the state
    setAdditionalTextFieldsForNestedChildAtt1(updatedNestedTextFields);

    // Filter the updated text fields for the specific nameValue

    const anotherUpdatedNestedTextFields = updatedNestedTextFields?.filter(
      (field) => field?.nameValue == nameValue && field?.parentId == parentId?.attribute_id
    );

    console.log("anotherUpdatedNestedTextFields:::", anotherUpdatedNestedTextFields)

    // Update the form data with the new list of nested text fields
    handleNestedChildChange(nameValue, anotherUpdatedNestedTextFields, section_id, parentId, attdata, show_option_value);
  };

  const handleSelectAttribute = (attdata) => {
    setAttributes((prev) => {
      // Check if the clicked item is already in the state
      const exists = prev.some((item) => item.attribute_id === attdata.attribute_id);
      if (exists) {
        // Remove the item if it exists
        return prev.filter((item) => item.attribute_id !== attdata.attribute_id);
      } else {
        // Add the item if it doesn't exist
        return [...prev, attdata];
      }
    });
  }

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (content) => {
    setContentForPost(content);
    setOpenFileUploadModel(true);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }

  function processAttributes(attributes) {
    const result = [];

    attributes.forEach(attribute => {
      const { nameValue, fieldtype, attribute_id, value, is_repeatable, id, child_attribute = [], ...rest } = attribute;
      let attributeValue = value;

      // Handle different field types
      if (
        fieldtype === "checkbox_multiple_choice" ||
        fieldtype === "dropdodown_multiple_choice" ||
        fieldtype === "users_list" ||
        fieldtype === "group_list" ||
        fieldtype === "tags"
      ) {
        attributeValue = Array.isArray(value) ? value : [];
      }

      if (fieldtype === "checkbox_single_choice") {
        attributeValue = String(value);
      }

      if (fieldtype === "date" && value !== "") {
        const date = dayjs(value).format("YYYY-MM-DD");
        attributeValue = date;
      }

      if (fieldtype === "time" && value !== "") {
        const time = dayjs(value, "HH:mm A").format("HH:mm A");
        attributeValue = time;
      }

      if (fieldtype === "date_time" && value !== "") {
        const dateTime = dayjs(value).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        attributeValue = dateTime;
      }

      if (fieldtype === "date_range") {
        const startDate = moment(value.startDate);
        const endDate = moment(value.endDate);
        const formattedStartDate = startDate.format("YYYY-MM-DD");
        const formattedEndDate = endDate.format("YYYY-MM-DD");
        attributeValue = `${formattedStartDate} - ${formattedEndDate}`;
      }

      if (fieldtype === "image") {
        attributeValue = value
      }

      if (fieldtype === "file") {
        attributeValue = value
      }

      if (is_repeatable === true) {
        if (Array.isArray(value)) {
          console.log("line 3460")
          attributeValue = value;
        }
        else if (value === null) {
          attributeValue = ""
        }
        else {
          console.log("line 3463")
          attributeValue = [value];
        }
      }

      // Create the main attribute object
      const attributeObject = {
        attribute_id: attribute_id,
        attribute_core_name: nameValue,
        attribute_data_type:
          fieldtype === "checkbox_multiple_choice" ||
            fieldtype === "dropdodown_multiple_choice" ||
            fieldtype === "users_group_list" ||
            fieldtype === "link_object"
            ? "array"
            : fieldtype === "file"
              ? "file"
              : fieldtype === "image"
                ? "image"
                : "string",
        attribute_value: attributeValue,
        is_child: "no",
        child_attribute: [],
        id: id
      };

      // Recursively process child attributes if any
      if (child_attribute.length > 0) {
        attributeObject.child_attribute = processAttributes(child_attribute);
        attributeObject.is_child = "yes";
      }

      // Add the attribute object to the result array
      result.push(attributeObject);
    });

    return result;
  }

  function generatePayload(attributeData) {
    const payload = processAttributes(attributeData);
    return payload;
  }

  const transformSelectedCategories = (selectedCategories, allCategories) => {
    const payload = [];

    allCategories.forEach(category => {
      const categoryLabel = category?.slug?.toLowerCase();
      const terms = selectedCategories[categoryLabel] || [];
      const categoryId = category?.id;

      if (terms.length > 0) {
        payload.push({
          category_id: categoryId,
          term_values: terms.map(term => term?.value),
        });
      }
    });

    return payload;
  };

  const onSubmit = async (values) => {
    const validate = await validateAttributes();
    if (validate?.isValid == false) {
      setShowBelowError(true);
    } else {
      setShowBelowError(false);

      const formData = new FormData();

      let groupsString = "";
      let managersString;
      let directReportsString;
      let data;

      if (values?.organisationManagers !== "") {
        const managers = values?.organisationManagers?.map((user) => user);
        managersString = managers;
      }

      if (values?.organisationDirectReports !== "") {
        const directReports = values?.organisationDirectReports?.map(
          (user) => user
        );
        directReportsString = directReports;
      }

      const contactDetails = JSON.stringify(values?.contactDetails);
      const contactEmail = values?.contactDetails?.filter(
        (data) => data?.service === "email"
      );

      const fileAttribute = attributesData?.find(
        (data) => data?.fieldtype == "file"
      );
      const imageAttribute = attributesData?.find(
        (data) => data?.fieldtype == "image"
      );

      if (values?.profileGroups?.length > 0) {
        groupsString = values?.profileGroups;
      } else {
        groupsString = " ";
      }

      function extractFileName(url) {
        const baseUrl =
          "https://staging-intranet.s3.ap-southeast-2.amazonaws.com/images/";
        return url.replace(baseUrl, "");
      }

      const payload = generatePayload(attributesData);

      const categoryData = transformSelectedCategories(selectedCategories, getAllCategories);

      data = {
        user_manager: managersString,
        user_report: directReportsString,
        account_type_id: values?.profileAccountType,
        first_name: values?.profileFirstName,
        last_name: values?.profileLastName,
        job_title: values?.profileJobTitle,
        bio: values?.profileBio,
        status: values?.userStatus == true ? "enable" : "disable",
        groups: groupsString,
        contact_details: contactDetails,
        email: contactEmail[0]?.value,
        thumbnail_id: selectedMediaFileDetailsForImage?.id,
        attribute_data: payload,
        category_data: categoryData
      }

      if (values?.profileAccountType == "1") {

        data = {
          ...data, // Spread existing properties
          username: values?.authenticationUserName,
          password: values?.authenticationPassword,
          force_pwd_changed: values?.authenticationNextLogin == true ? "enable" : "disable",
          email_login_detail: values?.authenticationLoginDetails == true ? "enable" : "disable"
        };
      }

      if (id) {
        data = {
          ...data, // Spread existing properties
          id: id
        };
      }

      if (id) {
        try {
          setLoading(true);
          const response = await api.post(`admin/user/update/${id}`, data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          });
          if (response?.success == true) {
            setLoading(false);
            setIsSuccessMessage(true);
            setSuccessMessage(response?.message);
            Toast.success(response?.message);
            localStorage.setItem("last-redirect-page", `/users`);
            navigate("/users");
          } else if (response?.success == false) {
            setLoading(false);
            setIsWarningMessage(true);
            setWarningMessage([...warningMessage, response?.message]);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("error in ctreate news api::", err);
          setLoading(false);
          if (err?.response?.status == 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status == 422) {
            setLoading(false);
            setIsWarningMessage(true);
            const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));

          }
          if (err?.response?.status == 429) {
            Toast.error("Too many attempts wait for 2 min.")
          }
        }
      } else {
        try {
          setLoading(true);
          const response = await api.post("admin/user/store", data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          });
          if (response?.success == true) {
            setLoading(false);
            setIsSuccessMessage(true);
            setSuccessMessage(response?.message);
            Toast.success(response?.message);
            localStorage.setItem("last-redirect-page", `/users`);
            navigate("/users");
          } else if (response?.success == false) {
            setLoading(false);
            setIsWarningMessage(true);
            setWarningMessage([...warningMessage, response?.message]);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("error in ctreate news api::", err);
          setLoading(false);
          if (err?.response?.status == 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status == 422) {
            setLoading(false);
            setIsWarningMessage(true);
            const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));

          }
          if (err?.response?.status == 429) {
            Toast.error("Too many attempts wait for 2 min.")
          }
        }
      }
    }
  };

  const fetchAccountTypes = async () => {
    try {
      setLoading(true);
      const response = await api.get(`admin/account-type-list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setAccountTypeList(response?.response);
      setLoading(false);
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const fetchGroupList = async () => {
    try {
      setLoading(true);
      const response = await api.get(`admin/group/enable`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        let groups = response?.response?.data?.map((data) => {
          return {
            value: data?.id,
            label: data?.title,
          };
        });
        setGroupList(groups);
      }
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const fetchAllUser = async () => {
    setLoading(true);

    try {
      const response = await api.get(`users-list?pagination=0`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        let users = response?.response?.data?.map((user) => ({
          value: user?.id,
          label: user?.name,
        }));

        setGetAllUsers(users);
      }
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  };


  // Helper function to parse JSON safely
  const safeJsonParse = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return null;
    }
  };

  // Helper function to handle date/time values
  const handleDateTimeValue = (value, fieldtype) => {
    if (!value) return "";

    switch (fieldtype) {
      case "date":
        return dayjs(value);
      case "date_time":
        return dayjs(value);
      case "time":
        return dayjs(value, "HH:mm A");
      default:
        return value;
    }
  };

  // Helper function to handle repeatable fields
  const handleRepeatableField = (value, fieldtype, setAdditionalTextFields) => {
    // If the value is already an array, use it directly
    if (Array.isArray(value)) {
      setAdditionalTextFields((prevFields) => [...prevFields, ...value]);
      return value;
    }

    // If the value is a string, attempt to parse it as JSON
    if (typeof value === "string" && value !== "") {
      const cleanString = value.replace(/value\s*:\s*/g, "");
      const resultArray = safeJsonParse(cleanString);

      if (Array.isArray(resultArray) && resultArray.length > 0) {
        setAdditionalTextFields((prevFields) => [...prevFields, ...resultArray]);
        return resultArray;
      }
    }

    // If the value is neither an array nor a valid JSON string, return it as-is
    return value;
  };

  const processAttributesData = async (attributes, setAdditionalTextFields, setAdditionalTextFieldsForNestedChildAtt1) => {
    return await Promise.all(
      attributes.map(async (data) => {
        let contentValue = data?.value ?? "";
        let url = ""
        let media_folder_id = ""
        let is_system = ""
        let file_size = ""
        let title = ""
        let name = ""
        let type = ""
        const { fieldtype, is_repeatable, child_attribute } = data || {};

        // Handle date/time fields
        if (["date", "date_time", "time"].includes(fieldtype) && !is_repeatable) {
          contentValue = handleDateTimeValue(data?.value ?? "", fieldtype);
        }

        // Handle image and file fields
        if (["image", "file"].includes(fieldtype) && !is_repeatable) {
          contentValue = data?.value ?? "";
          url = data?.attachment?.file_url || "";
          media_folder_id = data?.attachment?.folder_id || "";
          is_system = data?.attachment?.is_system || 0;
          file_size = data?.attachment?.file_size || "96kb";
          title = data?.attachment?.title || "";
          name = data?.attachment?.title || "";
        }

        // Handle image-file fields
        if (["image_file"].includes(fieldtype) && !is_repeatable) {
          console.log("line 1022")
          contentValue = data?.value ?? "";
          type = data?.attachment?.type || "";
          url = data?.attachment?.file_url || "";
          media_folder_id = data?.attachment?.folder_id || "";
          is_system = data?.attachment?.is_system || 0;
          file_size = data?.attachment?.file_size || "96kb";
          title = data?.attachment?.title || "";
          name = data?.attachment?.title || "";
        }

        // Handle dropdown and checkbox fields
        if (["dropdodown_single_choice", "checkbox_single_choice"].includes(fieldtype) && !is_repeatable) {
          if (fieldtype === "checkbox_single_choice") {
            // Explicitly convert to boolean
            contentValue = data?.value === true || data?.value === "true";
          } else {
            contentValue = parseInt(data?.value) || "";
          }
        }

        // Handle multiple choice fields
        if (["checkbox_multiple_choice", "dropdodown_multiple_choice", "users_list"].includes(fieldtype) && !is_repeatable) {
          contentValue = Array.isArray(data?.value)
            ? data.value.map((value) => (fieldtype === "users_list" ? parseInt(value, 10) || value : value))
            : [];
        }

        // Handle repeatable fields
        if (is_repeatable) {
          contentValue = handleRepeatableField(data?.value ?? "", fieldtype, setAdditionalTextFields);
        }

        // Handle date range fields
        if (fieldtype === "date_range" && !is_repeatable) {
          const [startDateString, endDateString] = (data?.value || "").split(" - ");
          contentValue = {
            startDate: moment(startDateString, "YYYY-MM-DD").isValid() ? moment(startDateString, "YYYY-MM-DD") : "",
            endDate: moment(endDateString, "YYYY-MM-DD").isValid() ? moment(endDateString, "YYYY-MM-DD") : "",
          };
        }

        // Handle child attributes
        let childAttData = [];
        if (Array.isArray(child_attribute) && child_attribute.length > 0) {
          childAttData = await handleChildAttributes(child_attribute, setAdditionalTextFieldsForNestedChildAtt1);
        }

        return {
          attribute_id: data?.attribute_id || "",
          fieldtype: data?.fieldtype || "",
          id: data?.id || "",
          is_mandatory: data?.is_mandatory || false,
          is_repeatable: data?.is_repeatable || false,
          label: data?.label || "",
          nameValue: data?.nameValue || "",
          options: data?.options || [],
          section_id: data?.section_id || "",
          value: contentValue,
          url: url,
          is_system: is_system || 0,
          media_folder_id: media_folder_id || "",
          file_size: file_size || "",
          name: name || "",
          title: title,
          child_attribute: childAttData,
          type: type
        };
      })
    );
  };

  // Recursive function to handle nested child attributes
  const handleChildAttributes = async (childAttributes, setAdditionalTextFieldsForNestedChildAtt1) => {
    if (!Array.isArray(childAttributes) || childAttributes.length === 0) return [];

    return await Promise.all(
      childAttributes.map(async (child) => {
        let contentValue = handleDateTimeValue(child?.value ?? "", child?.fieldtype);

        if (child?.is_repeatable) {
          contentValue = handleRepeatableField(child?.value ?? "", child?.fieldtype, setAdditionalTextFieldsForNestedChildAtt1);
        }

        // Recursively process child attributes
        const nestedChildAttributes = await handleChildAttributes(child?.child_attribute || [], setAdditionalTextFieldsForNestedChildAtt1);

        return {
          ...child,
          value: contentValue,
          child_attribute: nestedChildAttributes,
        };
      })
    );
  };


  const fetchGroupDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(`admin/user/show/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        let childAttData;

        const contactDetailsData = JSON.parse(
          response?.response?.contact_details
        );
        const categories = response?.response?.category

        const contentData = await processAttributesData(
          response?.response?.attributes,
          setAdditionalTextFields,
          setAdditionalTextFieldsForNestedChildAtt1
        );

        const transformedData = transformData(response?.response?.category);
        setSelectedCategories(transformedData)

        let userData = {
          profileAccountType: response?.response?.account_type_id,
          profileFirstName: response?.response?.name,
          profileLastName: response?.response?.last_name,
          profileJobTitle: response?.response?.job_title,
          profileBio: response?.response?.bio,
          profileGroups: response?.response?.group_member?.map(
            (data) => data
          ),
          contactDetails:
            contactDetailsData?.length > 0
              ? contactDetailsData
              : [
                { id: 1, service: "email", value: "" },
                { id: 2, service: "phone", value: "" },
              ],
          userStatus: response?.response?.status === "enable" ? true : false,
          image: response?.response?.image,
          authenticationUserName: response?.response?.username,
          authenticationNextLogin:
            response?.response?.force_pwd_changed === "enable" ? true : false,
          authenticationLoginDetails:
            response?.response?.email_login_detail === "enable" ? true : false,
          organisationManagers: response?.response?.user_managers?.map(
            (data) => data
          ),
          organisationDirectReports:
            response?.response?.direct_report_user?.map(
              (data) => data
            ),
        };

        let fileData = {
          id: response?.response?.file?.id,
          title: response?.response?.file?.file_title
        }
        let imgFileData = {
          id: response?.response?.media_id,
          file_path: response?.response?.image,
          type: "png"
        }

        setSelectedMediaTypeForImage(response?.response?.image?.is_system === 0 ? "my-media" : "system")
        setSelectedMediaFolderIDForImage(response?.response?.image?.folder_id)

        setSelectedMediaTypeForDoc(response?.response?.file?.is_system === 0 ? "my-media" : "system")
        setSelectedMediaFolderIDForDoc(response?.response?.file?.media_folder_id)

        setSelectedMediaFileForDoc(fileData?.id)
        setSelectedMediaFileDetailsForDoc(fileData)
        setSelectedMediaFileForImage(response?.response?.image?.id)
        setSelectedMediaFileDetailsForImage(response?.response?.image)
        setGetUserData(userData);
        setSelectedImageFile(userData?.image);
        setAttributes(contentData);
        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/users`);

        navigate("/users");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);

        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);

        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        setWarningMessage(err?.response?.data?.message);
        Toast.warning(err?.response?.data?.message);
        localStorage.setItem("last-redirect-page", `/users`);

        navigate("/users");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
    }
  };

  const fetchAllChangeLogs = async (page) => {
    try {
      setLoading(true);
      const response = await api.get(
        `admin/user/changelog/${id}?per_page=${rowsPerPage}&page=${page}&search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success == true) {
        response.response.data = response?.response?.data.map((log) => ({
          ...log,
          changed_at: dateWithTime(log.changed_at, true, true, true) || log.changed_at,
        }));
        setGetAllChangeLogs(response?.response);

        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
    }
  };

  const handleChangeLogCsvDownload = async () => {
    try {
      setLoading(true);

      const result = await exportXlsx(`admin/user/changelog/${id}?search=${search}&pagination=no`, 'user_change_log');
      if (!result) {
        Toast.error("Failed to download file");
      }

    } catch (error) {
      console.error("Error while downloading csv changelog::", error);
      Toast.error("Failed to download file");
    } finally {
      setLoading(false);
    }
  }

  const [checkExistData, setcheckExistData] = useState()

  const handleCheckExistApi = async (data) => {
    try {
      const response = await api.post("/admin/user/check-exist", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (data?.column === "username") {
        setUserNameExist(response);
      } else if (data?.column === "email") {
        setEmailExist(response);
      }
      return response;
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
    }
  }

  useEffect(() => {
    const getData = setTimeout(() => {
      if (checkExistData && checkExistData?.search.length > 3) {
        handleCheckExistApi(checkExistData)
      }
    }, 1000)

    return () => clearTimeout(getData)

  }, [checkExistData])

  const checkExist = async (search, column) => {
    let data = {
      search: search,
      column: column,
    };
    setcheckExistData(data);
  };

  const fetchAllAttributes = async (searchData) => {
    let data = {
      search: searchData,
    }
    setLoading(true)
    try {
      const response = await api.post(`admin/user/attribute-list`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        const sections = [{ id: 'all', name: 'All' }, ...response?.response?.data?.section];
        setGetAllAttributeSections(sections)
        setGetAllAttributes(response?.response?.data?.attributes)
        const categories = response?.response?.data?.category
        const allCategories = transformCategoryData(categories);
        setGetAllCategories(allCategories)

        setIsTypingSearchAtt(false)
        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        Toast.warning(err?.response?.message);
        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        Toast.warning(err?.response?.data[0]?.message);
        navigate("/");
      }
      if (err?.response?.status == 429) {
        setLoading(false)
        Toast.error("Too many attempts wait for 2 min.")
      }
    }
  }

  useEffect(() => {
    const apiCall = async () => {
      if (id) {
        await fetchAccountTypes();
        await fetchGroupList();
        await fetchAllUser();
        await fetchGroupDetails();
        await fetchAllChangeLogs(page + 1);
        await fetchGroupsAndUsersList()
        await fetchAllAttributes(searchAtt)
        setTabValue(0)
      } else {
        await fetchAccountTypes();
        await fetchGroupList();
        await fetchAllUser();
        await fetchAllAttributes(searchAtt)
        await fetchGroupsAndUsersList()
        setTabValue(0);

      }
    };
    apiCall();
  }, [id]);

  useEffect(() => {
    setWarningMessage(newWarningMessages);
  }, [newWarningMessages]);

  useEffect(() => {
    if (search.length >= 3 && istype == true) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        fetchAllChangeLogs(page + 1);
      }, debounceTimeout);
    }
    else if (search === "" && istype == true) {
      fetchAllChangeLogs(page + 1);
    }
    else {
      clearTimeout(debounceTimer);
    }

    return () => clearTimeout(debounceTimer);
  }, [search]);

  useEffect(() => {
    if (selectedMediaFolderIDForImage !== "" && selectedMediaTypeForImage !== "") {
      fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, selectedMediaFileForImage, selectedMediaFolderIDForImage)
    }
  }, [selectedMediaFolderIDForImage, selectedMediaTypeForImage])

  useEffect(() => {
    if (selectedMediaFolderIDForImageAtt !== "" && selectedMediaTypeForImageAtt !== "") {
      let type;
      if (mediaDataForAtt === "image") {
        if (selectedMediaTypeForImageAtt === "system") {
          type = "system"
        } else {
          type = "user"
        }
      }
      fetchMediaFilesForAtt(pageForMediaAtt, rowsPerPageForMediaAtt, mediaDataForAtt, selectedMediaFolderIDForImageAtt == "" ? 0 : selectedMediaFolderIDForImageAtt, type)
    }
  }, [selectedMediaFolderIDForImageAtt, selectedMediaTypeForImageAtt])

  useEffect(() => {
    if (selectedMediaFolderIDFordoc !== "" && selectedMediaTypeForDoc !== "") {
      fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, selectedMediaFileForDoc, selectedMediaFolderIDFordoc)
    }
  }, [selectedMediaFolderIDFordoc, selectedMediaTypeForDoc])

  useEffect(() => {
    if (selectedMediaFolderIDFordocAtt !== "" && selectedMediaTypeForDocAtt !== "") {
      let type;

      if (mediaDataForAtt === "doc") {
        if (selectedMediaTypeForDocAtt === "system") {
          type = "system"
        } else {
          type = "user"
        }
      }
      fetchMediaFilesForAtt(pageForMediaAtt, rowsPerPageForMediaAtt, mediaDataForAtt, selectedMediaFolderIDFordocAtt == "" ? 0 : selectedMediaFolderIDFordocAtt, type)
    }
  }, [selectedMediaFolderIDFordocAtt, selectedMediaTypeForDocAtt])

  useEffect(() => {
    if (selectedMedia !== "" && isEditMedia) {
      fetchMediaDetails()
    }
  }, [selectedMedia])

  useEffect(() => {
    if (selectedMedia !== "" && isRenameMedia) {
      fetchMediaDetailsForRename()
    }
  }, [selectedMedia])

  useEffect(() => {
    if (selectedMediaAtt !== "" && isEditMediaAtt) {
      fetchMediaDetailsAtt()
    }
  }, [selectedMediaAtt])

  useEffect(() => {
    if (selectedMediaAtt !== "" && isRenameMediaAtt) {
      fetchMediaDetailsForRenameAtt()
    }
  }, [selectedMediaAtt])

  return (
    <>
      <div
        className={`main-content-wrapper-2 ${open == true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >

          <Box className="">
            <Stack spacing={2}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Link
                  underline="hover"
                  key="1"
                  color="inherit"
                  href="/"
                  component="h3"
                >
                  Admin
                </Link>
                <Typography
                  key="3"
                  component="h3"
                  color="inherit"
                  className="cursor-pointer"
                  onClick={() => {
                    localStorage.setItem("last-redirect-page", `/users`);

                    navigate("/users");
                  }}
                >
                  Users
                </Typography>
                <Typography key="3" component="h3" color="text.primary">
                  {id ? "Edit" : "Add"} User
                </Typography>
              </Breadcrumbs>
            </Stack>
          </Box>
          <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
          {isSuccessMessage && (
            <SuccessAlert
              message={successMessage}
              onClose={handleCloseSuccessMessage}
            />
          )}

          {isWarningMessage && (
            <>
              {warningMessage?.map((message, index) => (
                <>
                  <WarningAlert
                    message={message}
                    onClose={() => handleCloseWarningMessage(index)}
                  />
                </>
              ))}
            </>
          )}
          <Formik
            enableReinitialize
            validateOnChange
            initialValues={initialValues}
            validationSchema={
              id ? EditNewUsersvalidationSchema : addNewUsersvalidationSchema
            }
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {({
              values,
              setFieldValue,
              touched,
              errors,
              handleSubmit,
              handleBlur,
              isValid,
              dirty,
              handleChange,
            }) => {
              let profileDetailsErrors = {};
              let contactDetailsErrors = {};
              let authenticationErrors = {};
              let organisationErrors = {};
              let messages = [];

              if (Object.keys(errors).length > 0) {
                for (let key in errors) {
                  if (key.startsWith("profile")) {
                    profileDetailsErrors[key] = errors[key];
                  } else if (key.startsWith("contact")) {
                    contactDetailsErrors[key] = errors[key];
                  } else if (key.startsWith("authentication")) {
                    authenticationErrors[key] = errors[key];
                  } else if (key.startsWith("organisation")) {
                    organisationErrors[key] = errors[key];
                  }
                }
              }

              const errorTypes = [
                {
                  errors: profileDetailsErrors,
                  errorMessage:
                    "Please enter required field for Profile Details",
                  setErrorFlag: setIsErrorOnProfileTab,
                },
                {
                  errors: contactDetailsErrors,
                  errorMessage:
                    "Please enter required field for Contact Details",
                  setErrorFlag: setIsErrorOnContactTab,
                },
                {
                  errors: authenticationErrors,
                  errorMessage:
                    "Please enter required field for Authentication",
                  setErrorFlag: setIsErrorOnAuthenticationTab,
                },
                {
                  errors: organisationErrors,
                  errorMessage: "Please enter required field for Organisation",
                  setErrorFlag: setIsErrorOnOrganisationTab,
                },
              ];

              errorTypes.forEach(({ errors, errorMessage, setErrorFlag }) => {
                if (Object.keys(errors).length > 0) {
                  if (showError == true) {
                    console.log("line 4797")
                    setIsWarningMessage(true);
                    messages.push(errorMessage);
                    setErrorFlag(true);
                  }
                } else {
                  setErrorFlag(false);
                }
              });

              newWarningMessages = messages;

              const handleAddOption = () => {
                const newId = values?.contactDetails?.length + 1;
                const newRow = {
                  id: newId,
                  service: "",
                  value: "",
                };
                setContactData([...contactData, newRow]);
                setFieldValue("contactDetails", [
                  ...values?.contactDetails,
                  newRow,
                ]);
              };

              const handleSelection = (id, selectedOption) => {
                let updatedRowsData;

                updatedRowsData = values?.contactDetails.map((details) => {
                  if (details.id === id) {
                    return {
                      ...details,
                      service: selectedOption,
                    };
                  }
                  return details;
                });

                setFieldValue("contactDetails", updatedRowsData);
              };

              const handleSelectionForValue = (id, selectedOption) => {
                let updatedRowsData;

                updatedRowsData = values?.contactDetails.map((details) => {
                  if (details.id === id) {
                    return {
                      ...details,
                      value: selectedOption,
                    };
                  }
                  return details;
                });

                setFieldValue("contactDetails", updatedRowsData);
              };

              return (
                <Form>
                  <Box className="publish-bar-box">
                    <Box className="delete-div-box">

                      {id && (
                        <Box className="d-flex gap-2 align-items-center justify-content-center cursor-pointer">
                          <FontAwesomeIcon icon={faTrash} className="delete-div-box-icon" />
                          <Typography className="delete-div-box-text">Delete</Typography>
                        </Box>
                      )}

                    </Box>
                    <Box className="last-div-box">


                      <Box className="publish-btn-div-box">
                        <Button
                          className="primary-btn publish-btn"
                          type="submit"
                          onClick={() => {
                            setShowError(true);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="d-md-block d-sm-none d-none me-2"
                          />
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className="d-md-none d-sm-block me-2"
                          />
                          Publish Page
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  <Row className="mt-1 form-row">
                    <Col lg="12" md="12" className="form-col">
                      <Stack direction="row" className="gap-30">
                        <Accordion
                          className="w-100"
                        >
                          {" "}
                          <Accordion.Item
                            eventKey="0"
                            className="bg-white card-wrapper w-100"
                          >
                            <Accordion.Header className="bg-white fw-700 primary-text">
                              Profile Details
                            </Accordion.Header>
                            <Accordion.Body className="content-card-body">
                              <Row>
                                <Col lg="12">
                                  <Box className="d-flex w-100 ">
                                    <Typography className="fw-400 dark-text fs-12-muted w-20">
                                      Account Type
                                      <span className="required">*</span>
                                    </Typography>
                                    <Box className="w-100">
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="profileAccountType"
                                        className=""
                                        value={values?.profileAccountType}
                                        onChange={handleChange}
                                      >
                                        {accountTypeList?.map((account) => (
                                          <FormControlLabel
                                            key={account?.id}
                                            value={account?.id}
                                            control={
                                              <Radio
                                                size="small"
                                                checked={
                                                  values?.profileAccountType ==
                                                  account?.id
                                                }
                                                className="radio-acc-type"
                                                value={account?.id}
                                              />
                                            }
                                            label={account?.name}
                                            className="font-inter form-ctrl-label"
                                          />
                                        ))}
                                      </RadioGroup>
                                    </Box>
                                    {errors?.profileAccountType &&
                                      touched.profileAccountType && (
                                        <p
                                          style={{ color: "#d32f2f" }}
                                          className="error-msg"
                                        >
                                          {errors?.profileAccountType}
                                        </p>
                                      )}

                                  </Box>
                                </Col>
                                <Col lg="12">
                                  <Box className="d-flex w-100 ">
                                    <Typography className="fw-400 dark-text fs-12-muted w-20">
                                      First Name
                                      <span className="required">*</span>
                                    </Typography>
                                    <Box className="w-100">
                                      <TextField
                                        className="input-field"
                                        fullWidth
                                        id="outlined-basic username"
                                        variant="outlined"
                                        size="small"
                                        name="profileFirstName"
                                        placeholder="Enter first name..."
                                        onChange={(event) => {
                                          setFieldValue(
                                            "profileFirstName",
                                            event.target.value
                                          );
                                        }}
                                        value={values?.profileFirstName}
                                        helperText={
                                          touched.profileFirstName &&
                                          errors.profileFirstName
                                        }
                                        error={
                                          touched.profileFirstName &&
                                          Boolean(errors.profileFirstName)
                                        }
                                      />
                                    </Box>
                                  </Box>
                                </Col>
                                <Col lg="12">
                                  <Box className="d-flex w-100 ">
                                    <Typography className="fw-400 dark-text fs-12-muted w-20">
                                      Last Name
                                      <span className="required">*</span>
                                    </Typography>
                                    <Box className="w-100">
                                      <TextField
                                        className="input-field"
                                        fullWidth
                                        id="outlined-basic username"
                                        variant="outlined"
                                        size="small"
                                        name="profileLastName"
                                        placeholder="Enter last name..."
                                        onChange={(event) => {
                                          setFieldValue(
                                            "profileLastName",
                                            event.target.value
                                          );
                                        }}
                                        value={values?.profileLastName}
                                        helperText={
                                          touched.profileLastName &&
                                          errors.profileLastName
                                        }
                                        error={
                                          touched.profileLastName &&
                                          Boolean(errors.profileLastName)
                                        }
                                      />
                                    </Box>
                                  </Box>
                                </Col>
                                <Col lg="12">
                                  <Box className="d-flex w-100 ">
                                    <Typography className="fw-400 dark-text fs-12-muted w-20">
                                      Job Title
                                      <span className="required">*</span>
                                    </Typography>
                                    <Box className="w-100">
                                      <TextField
                                        className="input-field"
                                        fullWidth
                                        id="outlined-basic username"
                                        variant="outlined"
                                        size="small"
                                        name="profileJobTitle"
                                        placeholder="Enter job title..."
                                        onChange={(event) => {
                                          setFieldValue(
                                            "profileJobTitle",
                                            event.target.value
                                          );
                                        }}
                                        value={values?.profileJobTitle}
                                        helperText={
                                          touched.profileJobTitle &&
                                          errors.profileJobTitle
                                        }
                                        error={
                                          touched.profileJobTitle &&
                                          Boolean(errors.profileJobTitle)
                                        }
                                      />
                                    </Box>
                                  </Box>
                                </Col>
                                <Col lg="12">
                                  <Box className="d-flex w-100 ">
                                    <Typography className="fw-400 dark-text fs-12-muted w-20">
                                      Bio
                                      <span className="required">*</span>
                                    </Typography>
                                    <Box className="w-100">

                                      <BootstrapForm.Control
                                        as="textarea" // To make it multiline
                                        rows={2}       // Minimum rows
                                        name="profileBio"
                                        placeholder={`Enter bio here...`}
                                        value={values?.profileBio}
                                        onChange={(event) => {
                                          setFieldValue(
                                            "profileBio",
                                            event.target.value
                                          );
                                        }}
                                        className="text-area-field"
                                      />
                                      {errors?.profileBio &&
                                        touched.profileBio && (
                                          <p
                                            style={{ color: "#d32f2f" }}
                                            className="error-msg"
                                          >
                                            {errors?.profileBio}
                                          </p>
                                        )}
                                    </Box>
                                  </Box>
                                </Col>
                                <Col>
                                  <Box className="d-flex w-100 ">
                                    <Typography className="fw-400 dark-text fs-12-muted w-20">
                                      Thumbnail
                                      <span className="required">*</span>
                                    </Typography>
                                    <Box className="w-100">
                                      <ImageUploadNew
                                        value={values?.image}
                                        handleClick={() => {
                                          setOpenMediaModelFor("normal")
                                          handleOpenUploadMedia("image")
                                        }
                                        }
                                        setFieldValue={setFieldValue}
                                      />
                                      {errors?.image && touched.image && (
                                        <p
                                          style={{ color: "#d32f2f" }}
                                          className="error-msg"
                                        >
                                          {errors?.image}
                                        </p>
                                      )}
                                    </Box>
                                  </Box>
                                </Col>
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            eventKey="1"
                            className="bg-white card-wrapper w-100 mt-4"
                          >
                            <Accordion.Header className="bg-white fw-700 primary-text">
                              Contact Details
                            </Accordion.Header>
                            <Accordion.Body className="content-card-body">
                              <Row>
                                <Col lg="12">
                                  <TableContainer className="z-0">
                                    <Table
                                      aria-labelledby="tableTitle"
                                      stickyHeader
                                    >
                                      <TableHeaderForSorting
                                        columns={columns_users_contact_header}
                                      />
                                      <TableBody
                                        style={{
                                          width: "100%",
                                          top: 0,
                                          left: 0,
                                        }}
                                      >
                                        <FieldArray
                                          name="contactDetails"
                                          render={({ push, remove }) => (
                                            <>
                                              {values?.contactDetails?.map(
                                                (row, index) => (
                                                  <>
                                                    <TableRow
                                                      hover
                                                      role="checkbox"
                                                      tabIndex={-1}
                                                      key={index}
                                                      className="cursor-pointer tbl-row-attribute-row"
                                                      sx={{
                                                        verticalAlign: "top",
                                                      }}
                                                    >
                                                      <TableCell
                                                        align="left"
                                                        className={`tbl-cell-attribute`}
                                                        style={{
                                                          width: "50%",
                                                          top: 0,
                                                          left: 0,
                                                        }}
                                                      >
                                                        <Field
                                                          name={`contactDetails[${index}].service`}
                                                          render={({
                                                            field,
                                                          }) => (
                                                            <>
                                                              <Select
                                                                closeMenuOnSelect={
                                                                  true
                                                                }
                                                                options={servicesSections.filter(
                                                                  (option) =>
                                                                    !values?.contactDetails.some(
                                                                      (
                                                                        data
                                                                      ) =>
                                                                        data.service ===
                                                                        option.value
                                                                    )
                                                                )}
                                                                menuPlacement="top"
                                                                name="users"
                                                                placeholder="Select service..."
                                                                menuPosition="fixed"
                                                                className="select-service z-2"
                                                                onChange={(
                                                                  selectedOption
                                                                ) =>
                                                                  handleSelection(
                                                                    row?.id,
                                                                    selectedOption?.value
                                                                  )
                                                                }
                                                                isDisabled={
                                                                  row?.service ===
                                                                  "email" ||
                                                                  row?.service ===
                                                                  "phone"
                                                                }
                                                                value={servicesSections.find(
                                                                  (option) =>
                                                                    option.value ===
                                                                    row?.service
                                                                )}
                                                                classNamePrefix="select"
                                                                styles={{
                                                                  option: (
                                                                    provided,
                                                                    state
                                                                  ) => ({
                                                                    ...provided,
                                                                    backgroundColor:
                                                                      state.isSelected
                                                                        ? "#660c605c"
                                                                        : provided.backgroundColor,
                                                                    color:
                                                                      state.isSelected
                                                                        ? "var(--dark-color)"
                                                                        : provided.color,
                                                                  }),
                                                                }}
                                                              />
                                                              <div>
                                                                <ErrorMessage
                                                                  name={`contactDetails[${index}].service`}
                                                                  component="p"
                                                                  className="error-msg"
                                                                />
                                                              </div>
                                                            </>
                                                          )}
                                                        />
                                                      </TableCell>
                                                      <TableCell
                                                        align="left"
                                                        className={` tbl-cell-attribute`}
                                                        style={{
                                                          width: "50%",
                                                          top: 0,
                                                          left: 0,
                                                        }}
                                                      >
                                                        <Field
                                                          name={`contactDetails[${index}].value`}
                                                          render={({
                                                            field,
                                                            form,
                                                          }) => (
                                                            <>
                                                              <TextField
                                                                className="input-field bg-white"
                                                                fullWidth
                                                                variant="outlined"
                                                                size="small"
                                                                placeholder="Enter value here..."
                                                                value={
                                                                  row?.value
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  e.preventDefault();

                                                                  if (
                                                                    row?.service ==
                                                                    "email" &&
                                                                    emailRegex.test(
                                                                      e.target
                                                                        .value
                                                                    )
                                                                  ) {
                                                                    emailExists =
                                                                      checkExist(
                                                                        e
                                                                          .target
                                                                          .value,
                                                                        "email"
                                                                      );
                                                                  }

                                                                  handleSelectionForValue(
                                                                    row?.id,
                                                                    e.target
                                                                      .value
                                                                  );
                                                                }}
                                                              />
                                                              {emailExist
                                                                ?.response
                                                                ?.is_exists ==
                                                                true &&
                                                                emailRegex.test(
                                                                  row?.value
                                                                ) &&
                                                                getUserData
                                                                  ?.contactDetails[
                                                                  index
                                                                ].value !==
                                                                row?.value && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    Email
                                                                    alreday
                                                                    Exist!
                                                                  </p>
                                                                )}

                                                              <div>
                                                                <ErrorMessage
                                                                  name={`contactDetails[${index}].value`}
                                                                  component="p"
                                                                  className="error-msg"
                                                                />
                                                              </div>
                                                            </>
                                                          )}
                                                        />
                                                      </TableCell>
                                                    </TableRow>
                                                    <TableRow className="extra-row"></TableRow>
                                                  </>
                                                )
                                              )}
                                            </>
                                          )}
                                        />
                                      </TableBody>
                                    </Table>
                                  </TableContainer>

                                  {values?.contactDetails?.length <
                                    servicesSections?.length && (
                                      <div className="mt-3 d-flex flex-wrap justify-content-end">
                                        <Button
                                          variant="outlined"
                                          startIcon={<AddIcon />}
                                          className="add-option-btn"
                                          onClick={handleAddOption}
                                        >
                                          Add Option
                                        </Button>
                                      </div>
                                    )}
                                </Col>
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                          {values?.profileAccountType == "1" && (
                            <Accordion.Item
                              eventKey="2"
                              className="bg-white card-wrapper w-100 mt-4"
                            >
                              <Accordion.Header className="bg-white fw-700 primary-text">
                                Authentication
                              </Accordion.Header>
                              <Accordion.Body className="content-card-body">
                                <Row>
                                  <Col lg="12">
                                    <Box className="d-flex w-100 ">
                                      <Typography className="fw-400 dark-text fs-12-muted w-20">
                                        Username
                                        <span className="required">*</span>
                                      </Typography>
                                      <Box className="w-100">
                                        <TextField
                                          className="input-field"
                                          fullWidth
                                          id="outlined-basic username"
                                          variant="outlined"
                                          size="small"
                                          name="authenticationUserName"
                                          placeholder="Enter username here..."
                                          onChange={async (event) => {
                                            setFieldValue(
                                              "authenticationUserName",
                                              event.target.value
                                            );
                                            if (event?.target?.value !== "") {
                                              usernameExist = await checkExist(
                                                event.target.value,
                                                "username"
                                              );
                                            }
                                          }}
                                          value={values?.authenticationUserName}
                                        />
                                        {userNameExist?.response?.is_exists ==
                                          true &&
                                          usernameRegex.test(
                                            values.authenticationUserName
                                          ) &&
                                          getUserData?.authenticationUserName !==
                                          values.authenticationUserName && (
                                            <p
                                              style={{
                                                color: "#d32f2f",
                                              }}
                                              className="error-msg"
                                            >
                                              Username is already exist!
                                            </p>
                                          )}

                                        {errors?.authenticationUserName &&
                                          touched.authenticationUserName && (
                                            <p
                                              style={{
                                                color: "#d32f2f",
                                              }}
                                              className="error-msg"
                                            >
                                              {errors?.authenticationUserName}
                                            </p>
                                          )}
                                      </Box>
                                    </Box>
                                  </Col>
                                  <Col lg="12">
                                    <Box className="d-flex w-100 ">
                                      <Typography className="fw-400 dark-text fs-12-muted w-20">
                                        Password
                                        <span className="required">*</span>
                                      </Typography>
                                      <Box className="w-100">
                                        <OutlinedInput
                                          className="input-field"
                                          fullWidth
                                          id="outlined-basic username"
                                          variant="outlined"
                                          type={
                                            showPassword ? "text" : "password"
                                          }
                                          size="small"
                                          name="authenticationPassword"
                                          placeholder="Enter password here..."
                                          onChange={(event) => {
                                            setFieldValue(
                                              "authenticationPassword",
                                              event.target.value
                                            );
                                          }}
                                          sx={{ borderRadius: "6px" }}
                                          endAdornment={
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={
                                                  handleMouseDownPassword
                                                }
                                                edge="end"
                                              >
                                                {showPassword ? (
                                                  <VisibilityOff />
                                                ) : (
                                                  <Visibility />
                                                )}
                                              </IconButton>
                                            </InputAdornment>
                                          }
                                          value={values?.authenticationPassword}
                                        />
                                        {errors?.authenticationPassword &&
                                          touched.authenticationPassword && (
                                            <p
                                              style={{
                                                color: "#d32f2f",
                                              }}
                                              className="error-msg"
                                            >
                                              {errors?.authenticationPassword}
                                            </p>
                                          )}
                                      </Box>
                                    </Box>
                                  </Col>
                                  <Col lg="12" >
                                    <Box className="d-flex w-100 ">
                                      <Typography className="fw-400 dark-text fs-12-muted w-20">
                                        Confirm Password
                                        <span className="required">*</span>
                                      </Typography>
                                      <Box className="w-100" >
                                        <OutlinedInput
                                          className="input-field"
                                          fullWidth
                                          id="outlined-basic username"
                                          variant="outlined"
                                          size="small"
                                          type={
                                            showConfirmedPassword
                                              ? "text"
                                              : "password"
                                          }
                                          name="authenticationConfirmPassword"
                                          placeholder="Enter confirm password..."
                                          sx={{ borderRadius: "6px" }}
                                          endAdornment={
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={
                                                  handleClickShowConfirmedPassword
                                                }
                                                onMouseDown={
                                                  handleMouseDownPassword
                                                }
                                                edge="end"
                                              >
                                                {showConfirmedPassword ? (
                                                  <VisibilityOff />
                                                ) : (
                                                  <Visibility />
                                                )}
                                              </IconButton>
                                            </InputAdornment>
                                          }
                                          onChange={(event) => {
                                            setFieldValue(
                                              "authenticationConfirmPassword",
                                              event.target.value
                                            );
                                          }}
                                          value={
                                            values?.authenticationConfirmPassword
                                          }
                                        />
                                        {errors?.authenticationConfirmPassword &&
                                          touched.authenticationConfirmPassword && (
                                            <p
                                              style={{
                                                color: "#d32f2f",
                                              }}
                                              className="error-msg"
                                            >
                                              {
                                                errors?.authenticationConfirmPassword
                                              }
                                            </p>
                                          )}
                                      </Box>
                                    </Box>
                                  </Col>
                                  <Col lg="12" className="repeatable-col-2">
                                    <Box className="d-flex w-100 ">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              values?.authenticationNextLogin ==
                                              true
                                            }
                                            name="authenticationNextLogin"
                                            onChange={handleChange}
                                          />
                                        }
                                        label="User must change password on next login"
                                        className="fw-400 dark-text fs-12-muted checkbox-att"
                                      />
                                    </Box>
                                  </Col>
                                  <Col lg="12" className="repeatable-col-2">
                                    <Box className="d-flex w-100 ">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              values?.authenticationLoginDetails ==
                                              true
                                            }
                                            name="authenticationLoginDetails"
                                            onChange={handleChange}
                                          />
                                        }
                                        label="Email User their login details"
                                        className="fw-400 dark-text fs-12-muted checkbox-att"
                                      />
                                    </Box>
                                  </Col>
                                </Row>
                              </Accordion.Body>
                            </Accordion.Item>
                          )}
                          <Accordion.Item
                            eventKey="3"
                            className="bg-white card-wrapper w-100 mt-4"
                          >
                            <Accordion.Header className="bg-white fw-700 primary-text">
                              Organisation
                            </Accordion.Header>
                            <Accordion.Body className="content-card-body">
                              <Row>
                                <Col lg="12">
                                  <Box className="d-flex w-100 ">
                                    <Typography className="fw-400 dark-text fs-12-muted w-20">
                                      Manager(s)
                                    </Typography>
                                    <Box className="w-100" >
                                      <Select
                                        closeMenuOnSelect={false}
                                        options={getAllUsers}
                                        isMulti
                                        menuPlacement="bottom"
                                        name="organisationManagers"
                                        placeholder="Select managers..."
                                        menuPosition="fixed"
                                        onChange={(selectedOption) => {
                                          const users = selectedOption.map(
                                            (option) => option.value
                                          );
                                          setFieldValue(
                                            "organisationManagers",
                                            users
                                          );
                                        }}
                                        value={getAllUsers?.filter((option) =>
                                          values?.organisationManagers?.includes(
                                            option?.value
                                          )
                                        )}
                                        className=""
                                        classNamePrefix="select"
                                        styles={{
                                          option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isSelected
                                              ? "#660c605c"
                                              : provided.backgroundColor,
                                            color: state.isSelected
                                              ? "var(--dark-color)"
                                              : provided.color,
                                          }),
                                          multiValueLabel: (provided, state) => {
                                            return {
                                              ...provided,
                                              color:
                                                state.data?.is_system === 1
                                                  ? "rgb(0, 135, 90)"
                                                  : "rgb(0, 82, 204)",
                                            };
                                          },
                                        }}
                                      />
                                      {errors?.organisationManagers &&
                                        touched.organisationManagers && (
                                          <p
                                            style={{
                                              color: "#d32f2f",
                                            }}
                                            className="error-msg"
                                          >
                                            {errors?.organisationManagers}
                                          </p>
                                        )}
                                    </Box>
                                  </Box>
                                </Col>
                                <Col lg="12">
                                  <Box className="d-flex w-100 ">
                                    <Typography className="fw-400 dark-text fs-12-muted w-20">
                                      Direct Reports
                                    </Typography>
                                    <Box className="w-100">
                                      <Select
                                        closeMenuOnSelect={false}
                                        options={getAllUsers}
                                        isMulti
                                        menuPlacement="bottom"
                                        name="organisationDirectReports"
                                        placeholder="Select direct reports..."
                                        menuPosition="fixed"
                                        onChange={(selectedOption) => {
                                          const users = selectedOption.map(
                                            (option) => option.value
                                          );
                                          setFieldValue(
                                            "organisationDirectReports",
                                            users
                                          );
                                        }}
                                        value={getAllUsers?.filter((option) =>
                                          values?.organisationDirectReports?.includes(
                                            option?.value
                                          )
                                        )}
                                        className=""
                                        classNamePrefix="select"
                                        styles={{
                                          option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isSelected
                                              ? "#660c605c"
                                              : provided.backgroundColor,
                                            color: state.isSelected
                                              ? "var(--dark-color)"
                                              : provided.color,
                                          }),
                                          multiValueLabel: (provided, state) => {
                                            return {
                                              ...provided,
                                              color:
                                                state.data?.is_system === 1
                                                  ? "rgb(0, 135, 90)"
                                                  : "rgb(0, 82, 204)",
                                            };
                                          },
                                        }}
                                      />
                                      {errors?.organisationDirectReports &&
                                        touched.organisationDirectReports && (
                                          <p
                                            style={{
                                              color: "#d32f2f",
                                            }}
                                            className="error-msg"
                                          >
                                            {errors?.organisationDirectReports}
                                          </p>
                                        )}
                                    </Box>
                                  </Box>
                                </Col>
                                <Col lg="12">
                                  <Box className="d-flex w-100 ">
                                    <Typography className="fw-400 dark-text fs-12-muted w-20">
                                      Groups
                                    </Typography>
                                    <Box className="w-100">
                                      <Select
                                        closeMenuOnSelect={false}
                                        options={groupList}
                                        isMulti
                                        menuPlacement="bottom"
                                        name="profileGroups"
                                        placeholder="Select groups..."
                                        menuPosition="fixed"
                                        onChange={(selectedOption) => {
                                          const users = selectedOption.map(
                                            (option) => option.value
                                          );
                                          setFieldValue("profileGroups", users);
                                        }}
                                        value={groupList?.filter((option) =>
                                          values?.profileGroups?.includes(
                                            option?.value
                                          )
                                        )}
                                        className=""
                                        classNamePrefix="select"
                                        styles={{
                                          option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isSelected
                                              ? "#660c605c"
                                              : provided.backgroundColor,
                                            color: state.isSelected
                                              ? "var(--dark-color)"
                                              : provided.color,
                                          }),
                                          multiValueLabel: (provided, state) => {
                                            return {
                                              ...provided,
                                              color:
                                                state.data?.is_system === 1
                                                  ? "rgb(0, 135, 90)"
                                                  : "rgb(0, 82, 204)",
                                            };
                                          },
                                        }}
                                      />
                                      {errors?.profileGroups &&
                                        touched.profileGroups && (
                                          <p
                                            style={{ color: "#d32f2f" }}
                                            className="error-msg"
                                          >
                                            {errors?.profileGroups}
                                          </p>
                                        )}
                                    </Box>
                                  </Box>
                                </Col>
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item
                            eventKey="4"
                            className="bg-white card-wrapper w-100 mt-4"
                          >
                            <Accordion.Header className="bg-white fw-700 primary-text">
                              Attributes
                            </Accordion.Header>
                            <Accordion.Body className="att-acc-body">
                              <Box className="att-list-box">
                                <Box className="d-flex flex-column gap-3 justify-content-center att-card-body">
                                  <Box>
                                    {attributesData?.length > 0 ? (
                                      <>
                                        <AllComponent
                                          attributesData={attributesData}
                                          handleChangeForFormData={handleChangeForFormData}
                                          setOpenMediaModelFor={setOpenMediaModelFor}
                                          handleOpenUploadMediaForAtt={handleOpenUploadMediaForAtt}
                                          handleChangeForChildAtts={handleChangeForChildAtts}
                                          isChild={false}
                                          parentAttData={attributesData}
                                          handleNestedChildChange={handleNestedChildChange}
                                          handleAddNestedTextField={handleAddNestedTextField}
                                          additionalTextFieldsForNestedChildAtt1={additionalTextFieldsForNestedChildAtt1}
                                          handleChangeForNestedChildAdditionalTextField={handleChangeForNestedChildAdditionalTextField}
                                          removeNestedExtraFields={removeNestedExtraFields}
                                          handleAddTextField={handleAddTextField}
                                          additionalTextFields={additionalTextFields}
                                          handleChangeForAdditionalTextField={handleChangeForAdditionalTextField}
                                          removeExtraFields={removeExtraFields}
                                          handleClickForAtt={handleClickForAtt}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <Typography className="no-att-text text-center">There are no current attributes selected.</Typography>
                                      </>
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                              <Box className="att-action-box">
                                <Button
                                  className="btn primary-btn"
                                  // type="submit"
                                  onClick={() => {
                                    // setPublicationOption("draft");
                                    // setShowError(true);
                                    setOpenAddAttribute(true)
                                  }}
                                >
                                  <FontAwesomeIcon icon={faAdd} />
                                  Add Attributes
                                </Button>
                              </Box>
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item
                            eventKey="5"
                            className="bg-white card-wrapper w-100 mt-4"
                          >
                            <Accordion.Header className="bg-white fw-700 primary-text">
                              Category
                            </Accordion.Header>
                            <Accordion.Body className="content-card-body">
                              <Row>
                                {getAllCategories?.length > 0 && (
                                  <>
                                    {getAllCategories?.map((category) => (
                                      <>
                                        <Col lg="12" className="">
                                          <Box className="d-flex w-100">
                                            <Typography className="fw-400 dark-text fs-12-muted w-20">
                                              {category?.label}
                                            </Typography>
                                            <Box className="w-100">
                                              <Select
                                                closeMenuOnSelect={false}
                                                options={category?.options}
                                                menuPlacement="top"
                                                name="groupManagers"
                                                value={selectedCategories[category.slug] || []}
                                                placeholder={`Select Terms For ${category?.label}...`}
                                                menuPosition="fixed"
                                                isMulti
                                                onChange={(selectedOption) => handleCategoryChange(category.slug, selectedOption)}

                                                className={`muilt-select-field w-100`}
                                                classNamePrefix="select"
                                                styles={{
                                                  option: (provided, state) => {
                                                    return {
                                                      ...provided,
                                                      backgroundColor: state.isSelected
                                                        ? "#660c605c"
                                                        : provided.backgroundColor,
                                                      color: state.isSelected
                                                        ? "var(--dark-color)"
                                                        : provided.color,
                                                    };
                                                  },
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Col>
                                      </>
                                    ))}
                                  </>
                                )}
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>

                          {id && (
                            <Accordion.Item
                              eventKey="6"
                              className="bg-white card-wrapper mt-4"
                            >
                              <Accordion.Header className="bg-white fw-700 primary-text">
                                Changelog
                              </Accordion.Header>
                              <Accordion.Body className="p-0 changelog-body">
                                <Grid
                                  container
                                  spacing={1}
                                  className="changelog-div  "
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    sm={12}
                                    lg={9}
                                    xl={9}
                                  >
                                    <Box className="">
                                      <InputGroup className="">
                                        <TextField
                                          className="search-changelog-input"
                                          fullWidth
                                          id="outlined-basic username"
                                          variant="outlined"
                                          size="small"
                                          name="attributeTitle"
                                          value={search}
                                          placeholder="search changelog"
                                          onChange={(e) => {
                                            setIstype(true);
                                            setSearch(e.target.value);
                                            if (e.target.value === "") {
                                              fetchAllChangeLogs(page);
                                            }
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                              e.preventDefault();
                                              fetchAllChangeLogs(page);
                                            }
                                          }}
                                        />
                                        <Button
                                          className="search-changelog"
                                          onClick={() => {
                                            fetchAllChangeLogs(page);
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faSearch}
                                            className="search-changelog-icon"
                                          />
                                        </Button>
                                      </InputGroup>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    sm={12}
                                    lg={3}
                                    xl={3}
                                  >
                                    <div className="w-100">

                                      <Button
                                        variant="outlined"
                                        startIcon={
                                          <FontAwesomeIcon icon={faFileCsv} />
                                        }
                                        className="export-csv-btn"
                                        onClick={handleChangeLogCsvDownload}
                                      >
                                        Export as CSV
                                      </Button>
                                    </div>
                                  </Grid>
                                </Grid>
                                <Paper
                                  sx={{ width: "100%" }}
                                  className="changelog-table-div mt-4"
                                >
                                  <TableContainer className="">
                                    <Table
                                      sx={{ minWidth: 750 }}
                                      aria-labelledby="tableTitle"
                                      stickyHeader
                                    >
                                      <TableHeaderForSorting
                                        columns={
                                          columns_attribute_changelog_header
                                        }
                                        className="z-0"
                                      />
                                      <TableBody>
                                        {getAllChangeLogs?.data?.map(
                                          (data, index) => (
                                            <>
                                              <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index + 1}
                                                className=" tbl-changelog mt-2"
                                              >
                                                <TableCell
                                                  align="left"
                                                  className="tbl-border-left tbl-cell-5"
                                                >
                                                  <Box>
                                                    <Stack
                                                      direction="row"
                                                      alignContent="center"
                                                      alignItems="center"
                                                      spacing={1}
                                                      className="z-n1"
                                                    >
                                                      <Avatar
                                                        alt={data?.changed_by}
                                                        src="/static/images/avatar/1.jpg"
                                                        className="author-avtar"
                                                      />
                                                      <Typography className="fw-400 fs-14 ">
                                                        {data?.changed_by
                                                          ? data?.changed_by
                                                          : "-"}
                                                      </Typography>
                                                    </Stack>
                                                  </Box>
                                                </TableCell>
                                                <TableCell
                                                  align="left"
                                                  className="text-nowrap"
                                                >
                                                  {data?.changed_at
                                                    ? data?.changed_at
                                                    : "-"}
                                                </TableCell>
                                                <TableCell
                                                  align="left"
                                                  className="text-nowrap"
                                                >
                                                  {data?.area
                                                    ? data?.area
                                                    : "-"}
                                                </TableCell>
                                                <TableCell
                                                  align="left"
                                                  className="tbl-border-right"
                                                >
                                                  <Typography
                                                    className="fw-400 fs-14"
                                                    dangerouslySetInnerHTML={{
                                                      __html: data?.description
                                                        ? processedHTML(
                                                          data?.description
                                                        ).innerHTML
                                                        : "-",
                                                    }}
                                                  ></Typography>
                                                </TableCell>
                                              </TableRow>
                                              <TableRow className="extra-row"></TableRow>
                                            </>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Paper>
                                {total_changelog > rowsPerPage && (
                                  <TablePagination
                                    component="div"
                                    count={total_changelog}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={
                                      handleChangeRowsPerPage
                                    }
                                    labelDisplayedRows={({
                                      from,
                                      to,
                                      count,
                                    }) => {
                                      const currentPage = page + 1;
                                      const start =
                                        currentPage * rowsPerPage -
                                        rowsPerPage +
                                        1;
                                      const end = Math.min(
                                        currentPage * rowsPerPage,
                                        count
                                      );
                                      return (
                                        <>
                                          {`Showing ${start} to ${end} of `}
                                          <span>{count}</span>
                                          {" records"}
                                        </>
                                      );
                                    }}
                                  />
                                )}
                              </Accordion.Body>
                            </Accordion.Item>
                          )}
                        </Accordion>
                      </Stack>
                    </Col>
                  </Row>
                  <FileUploadModal
                    open={openFileUploadModel}
                    setOpen={setOpenFileUploadModel}
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                    title={contentForPost}
                    setFieldValue={setFieldValue}
                    fileTypes={
                      contentForPost === "image" ? imageFileTypes : fileTypes
                    }
                    handleFileChange={handleFileChange}
                    file={
                      contentForPost === "image"
                        ? selectedImageFile
                        : selectedFile
                    }
                    setFileValue={() => handleImageFileValue(setFieldValue)}
                    isImage={contentForPost === "image" ? true : false}
                    isImageEditable={isEdit}
                  />
                  <FileUploadModalAttribue
                    open={openFileUploadModelForAtt}
                    setOpen={setOpenFileUploadModelForAtt}
                    handleOpen={handleOpenForAtt}
                    handleClose={handleCloseForAtt}
                    title={contentForPostForAtt}
                    setFieldValue={setFieldValue}
                    fileTypes={
                      contentForPostForAtt === "image"
                        ? imageFileTypes
                        : fileTypes
                    }
                    handleFileChange={handleFileChangeForAtt}
                    file={
                      contentForPostForAtt === "image"
                        ? selectedImageFileForAtt
                        : selectedFileForAtt
                    }
                    setFileValue={handleImageFileValueForAtt}
                    isImage={contentForPostForAtt === "image" ? true : false}
                    isImageEditable={isEdit}
                  />

                  <UploadMediaModel
                    openMediaUpload={openMediaUpload}
                    handleCloseForUploadMedia={handleCloseForUploadMedia}
                    selectedMediaTypeForImage={selectedMediaTypeForImage}
                    selectedMediaTypeForDoc={selectedMediaTypeForDoc}
                    setSelectedMediaTypeForImage={setSelectedMediaTypeForImage}
                    setSelectedMediaTypeForDoc={setSelectedMediaTypeForDoc}
                    setSelectedMediaFolderIDForImage={setSelectedMediaFolderIDForImage}
                    setSelectedMediaFolderIDForDoc={setSelectedMediaFolderIDForDoc}
                    systemMediaFolderList={systemMediaFolderList}
                    selectedMediaFolderIDForImage={selectedMediaFolderIDForImage}
                    selectedMediaFolderIDFordoc={selectedMediaFolderIDFordoc}
                    myMediaFolderList={myMediaFolderList}
                    downloadFileForImage={downloadFileForImage}
                    downloadFileFordoc={downloadFileFordoc}
                    selectedMediaFileDetailsForImage={selectedMediaFileDetailsForImage}
                    setSelectedMediaFileDetailsForImage={setSelectedMediaFileDetailsForImage}
                    selectedMediaFileForImage={selectedMediaFileForImage}
                    setSelectedMediaFileForImage={setSelectedMediaFileForImage}
                    selectedMediaFileDetailsForDoc={selectedMediaFileDetailsForDoc}
                    setSelectedMediaFileDetailsForDoc={setSelectedMediaFileDetailsForDoc}
                    selectedMediaFileForDoc={selectedMediaFileForDoc}
                    setSelectedMediaFileForDoc={setSelectedMediaFileForDoc}
                    setFieldValue={setFieldValue}
                    mediaList={mediaList}
                    total_mediaFiles={total_mediaFiles}
                    pageForMedia={pageForMedia}
                    setPageForMedia={setPageForMedia}
                    handleChangePageForMedia={handleChangePageForMedia}
                    rowsPerPageForMedia={rowsPerPageForMedia}
                    handleChangeRowsPerPageForMedia={handleChangeRowsPerPageForMedia}
                    media_Pagination_options={media_Pagination_options}
                    mediaDataFor={mediaDataFor}
                    handleCloseMediaModelAddMedia={handleCloseMediaModelAddMedia}
                    setSelectedFileMedia={setSelectedFileMedia}
                    selectedFileMedia={selectedFileMedia}
                    isEditMedia={isEditMedia}
                    setIsEditMedia={setIsEditMedia}
                    setSelectedMedia={setSelectedMedia}
                    selectedMedia={selectedMedia}
                    deleteMedia={deleteMedia}
                    setOpenRenameModel={setOpenRenameModel}
                    setIsRenameMedia={setIsRenameMedia}
                    setLoading={setLoading}
                    userToken={userToken}
                    dispatch={dispatch}
                    navigate={navigate}
                    setSelectedAnyMediaFile={setSelectedAnyMediaFile}
                    selectedAnyMediaFile={selectedAnyMediaFile}
                    setSelectedAnyMediaFileDetails={setSelectedAnyMediaFileDetails}
                    selectedAnyMediaFileDetails={selectedAnyMediaFileDetails}
                    setUploadedFile={setUploadedFile}
                    uploadedFile={uploadedFile}
                    setShowRenderImage={setShowRenderImage}
                    showRenderImage={showRenderImage}
                    isSystemTypeMedia={isSystemTypeMedia}
                    mediaTitle={mediaTitle}
                    mediaAltText={mediaAltText}
                    mediaDescription={mediaDescription}
                    isAdvancePermissionForMedia={isAdvancePermissionForMedia}
                    permissionsForMedia={permissionsForMedia}
                    setSelectedMediaFileForUpdate={setSelectedMediaFileForUpdate}
                    setIsImageMedia={setIsImageMedia}
                    allowedDrag={true}
                    setMediaList={setMediaList}
                  />

                  <UploadMediaModelAtt
                    openMediaUpload={openMediaUploadForAtt}
                    handleCloseForUploadMedia={handleCloseForUploadMediaForAtt}
                    selectedMediaTypeForImage={selectedMediaTypeForImageAtt}
                    selectedMediaTypeForDoc={selectedMediaTypeForDocAtt}
                    setSelectedMediaTypeForImage={setSelectedMediaTypeForImageAtt}
                    setSelectedMediaTypeForDoc={setSelectedMediaTypeForDocAtt}
                    setSelectedMediaFolderIDForImage={setSelectedMediaFolderIDForImageAtt}
                    setSelectedMediaFolderIDForDoc={setSelectedMediaFolderIDForDocAtt}
                    systemMediaFolderList={systemMediaFolderListForAtt}
                    selectedMediaFolderIDForImage={selectedMediaFolderIDForImageAtt}
                    selectedMediaFolderIDFordoc={selectedMediaFolderIDFordocAtt}
                    myMediaFolderList={myMediaFolderListForAtt}
                    downloadFileForImage={downloadFileForImageAtt}
                    downloadFileFordoc={downloadFileFordocAtt}
                    selectedMediaFileDetailsForImage={selectedMediaFileDetailsForImageAtt}
                    setSelectedMediaFileDetailsForImage={setSelectedMediaFileDetailsForImageAtt}
                    selectedMediaFileForImage={selectedMediaFileForImageAtt}
                    setSelectedMediaFileForImage={setSelectedMediaFileForImageAtt}
                    selectedMediaFileDetailsForDoc={selectedMediaFileDetailsForDocAtt}
                    setSelectedMediaFileDetailsForDoc={setSelectedMediaFileDetailsForDocAtt}
                    selectedMediaFileForDoc={selectedMediaFileForDocAtt}
                    setSelectedMediaFileForDoc={setSelectedMediaFileForDocAtt}
                    mediaList={mediaListAtt}
                    total_mediaFiles={total_mediaFilesAtt}
                    pageForMedia={pageForMediaAtt}
                    setPageForMedia={setPageForMediaAtt}
                    handleChangePageForMedia={handleChangePageForMediaForAtt}
                    rowsPerPageForMedia={rowsPerPageForMediaAtt}
                    handleChangeRowsPerPageForMedia={handleChangeRowsPerPageForMediaAtt}
                    media_Pagination_options={media_Pagination_options}
                    mediaDataFor={mediaDataForAtt}
                    handleImageFileValueCustAttForAtt={handleImageFileValueCustAttForAtt}
                    handleCloseMediaModelAddMedia={handleCloseMediaModelAddMediaAtt}
                    setSelectedFileMedia={setSelectedFileMediaAtt}
                    selectedFileMedia={selectedFileMediaAtt}
                    isEditMedia={isEditMediaAtt}
                    setIsEditMedia={setIsEditMediaAtt}
                    setSelectedMedia={setSelectedMediaAtt}
                    selectedMedia={selectedMediaAtt}
                    deleteMedia={deleteMediaAtt}
                    setOpenRenameModel={setOpenRenameModelAtt}
                    setIsRenameMedia={setIsRenameMediaAtt}
                    setSelectedMediaFileDetailsForAtt={setSelectedMediaFileDetailsForAtt}
                    selectedMediaFileDetailsForAtt={selectedMediaFileDetailsForAtt}
                    setSelectedMediaFileForAtt={setSelectedMediaFileForAtt}
                    selectedMediaFileForAtt={selectedMediaFileForAtt}
                    setShowRenderImage={setShowRenderImage}
                    showRenderImage={showRenderImage}
                    setUploadedFile={setUploadedFile}
                    uploadedFile={uploadedFile}
                    isSystemTypeMediaAtt={isSystemTypeMediaAtt}
                    mediaTitleAtt={mediaTitleAtt}
                    mediaAltTextAtt={mediaAltTextAtt}
                    mediaDescriptionAtt={mediaDescriptionAtt}
                    isAdvancePermissionForMedia={isAdvancePermissionForMedia}
                    permissionsForMedia={permissionsForMedia}
                    parentFolderIdAtt={parentFolderIdAtt}
                    setLoading={setLoading}
                    userToken={userToken}
                    mediaDataForAtt={mediaDataForAtt}
                    dispatch={dispatch}
                    navigate={navigate}
                    setSelectedAnyMediaFile={setSelectedAnyMediaFile}
                    selectedAnyMediaFile={selectedAnyMediaFile}
                    setSelectedAnyMediaFileDetails={setSelectedAnyMediaFileDetails}
                    selectedAnyMediaFileDetails={selectedAnyMediaFileDetails}
                  />

                  <AddMedia
                    openFileUploadModel={openMediaModelFor === "normal" ? openFileUploadModelMedia : openFileUploadModelMediaAtt}
                    isEdit={openMediaModelFor === "normal" ? isEditMedia : isEditMediaAtt}
                    handleCloseForMediaModel={openMediaModelFor === "normal" ? handleCloseForMediaModel : handleCloseForMediaModelAtt}
                    getRootProps={getRootProps}
                    selectedFile={openMediaModelFor === "normal" ? selectedFileMedia : selectedFileMediaAtt}
                    getInputProps={getInputProps}
                    renderPreview={openMediaModelFor === "normal" ? renderPreview : renderPreviewAtt}
                    valueForMediaTab={valueForMediaTab}
                    handleChangeForMedia={handleChangeForMedia}
                    mediaTitle={openMediaModelFor === "normal" ? mediaTitle : mediaTitleAtt}
                    setMediaTitle={openMediaModelFor === "normal" ? setMediaTitle : setMediaTitleAtt}
                    mediaAltText={openMediaModelFor === "normal" ? mediaAltText : mediaAltTextAtt}
                    setMediaAltText={openMediaModelFor === "normal" ? setMediaAltText : setMediaAltTextAtt}
                    mediaDescription={openMediaModelFor === "normal" ? mediaDescription : mediaDescriptionAtt}
                    setMediaDescription={openMediaModelFor === "normal" ? setMediaDescription : setMediaDescriptionAtt}
                    mediaDirectUrl={openMediaModelFor === "normal" ? mediaDirectUrl : mediaDirectUrlAtt}
                    setMediaDirectUrl={openMediaModelFor === "normal" ? setMediaDirectUrl : setMediaDirectUrlAtt}
                    loggedUser={loggedUser}
                    isSystemTypeMedia={openMediaModelFor === "normal" ? isSystemTypeMedia : isSystemTypeMediaAtt}
                    isParentFolderSystemType={openMediaModelFor === "normal" ? isParentFolderSystemType : isParentFolderSystemTypeAtt}
                    permissionsForMedia={permissionsForMedia}
                    setPermissionsForMedia={setPermissionsForMedia}
                    isAdvancePermissionForMedia={isAdvancePermissionForMedia}
                    setIsAdvancePermissionForMedia={setIsAdvancePermissionForMedia}
                    columns_atttributes_headers={columns_atttributes_headers}
                    columns_advace_atttributes_headers={columns_advace_atttributes_headers}
                    responseForMedia={openMediaModelFor === "normal" ? responseForMedia : responseForMediaAtt}
                    storeMedia={openMediaModelFor === "normal" ? storeMedia : storeMediaAtt}
                    showSystemMediaCheckbox={false}
                  />

                  <Dialog
                    aria-labelledby="customized-dialog-title"
                    open={openMediaModelFor === "normal" ? openRnameMediaModel : openRnameMediaModelAtt}
                    maxWidth="sm"
                    fullWidth={true}
                    className="media-dialog-2"
                  >
                    <DialogTitle
                      sx={{ m: 0, p: 2 }}
                      id="customized-dialog-title"
                      className="dialog-title"
                    >
                      Rename Media
                    </DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={openMediaModelFor === "normal" ? handleCloseForMediaModel : handleCloseForMediaModelAtt}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                      className="alert-close-btn"
                    >
                      <CloseIcon />
                    </IconButton>
                    <DialogContent dividers className="media-preview">
                      <Box className=" main-app-icon-div-4 d-flex flex-wrap">
                        <Box className="media-info-div-2">
                          <Paper sx={{ width: "100%" }} className="type-list-paper-2">
                            <Typography className="fs-14">Title</Typography>
                            <TextField
                              className="input-field mt-2"
                              fullWidth
                              id="outlined-basic short summary"
                              variant="outlined"
                              placeholder={`write media title`}
                              value={openMediaModelFor === "normal" ? mediaTitle : mediaTitleAtt}
                              onChange={(event) => {
                                if (openMediaModelFor === "normal") {
                                  setMediaTitle(event?.target?.value)
                                } else {
                                  setMediaTitleAtt(event?.target?.value)
                                }
                              }}
                            />

                          </Paper>
                        </Box>
                      </Box>
                    </DialogContent>
                    <DialogActions className="d-flex flex-wrap justify-content-between gap-2 p-3 z-0 action-btn-model">
                      <Button
                        className="btn delete-btn-model"
                        onClick={openMediaModelFor === "normal" ? handleCloseForMediaModel : handleCloseForMediaModelAtt}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                        Cancel
                      </Button>
                      <Button
                        className="btn primary-btn"
                        onClick={openMediaModelFor === "normal" ? renameMedia : renameMediaAtt}
                      >
                        <FontAwesomeIcon icon={faFloppyDisk} />
                        Save Changes
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Dialog
                    aria-labelledby="customized-dialog-title"
                    open={openAddAttribute}
                    maxWidth="xl"
                    // fullWidth={true}
                    className="add-att-model"
                  >
                    <DialogTitle
                      sx={{ m: 0, p: 2 }}
                      id="customized-dialog-title"
                      className="dialog-title"
                    >
                      Add Attribute
                    </DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={handleCloseForAddAttributeModel}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                      className="alert-close-btn"
                    >
                      <CloseIcon />
                    </IconButton>
                    <DialogContent dividers className="media-preview">
                      <Box className="d-flex gap-2 p-3">
                        <InputGroup className="">
                          <TextField
                            className="search-input w-100"
                            fullWidth
                            id="outlined-basic username"
                            variant="outlined"
                            size="small"
                            name="attributeTitle"
                            value={searchAtt}
                            placeholder="search attribute"
                            onChange={(e) => {
                              // setIstype(true);
                              setIsTypingSearchAtt(true)
                              setSearchAtt(e.target.value);
                              if (e.target.value === "") {
                                fetchAllAttributes(e.target.value);
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                if (e.target.value?.length > 2) {
                                  fetchAllAttributes(e.target.value)
                                }
                                else if (e.target.value === "" && isTypingSearchAtt === true) {
                                  fetchAllAttributes(e.target.value)
                                }
                                else if (e.target.value?.length < 3) {
                                  Toast.error("Please enter atleat 3 letter")
                                }
                              }
                            }}
                          />
                        </InputGroup>
                        <Button
                          className="btn primary-btn search-att-btn"
                          // type="submit"
                          onClick={() => {
                            // setPublicationOption("draft");
                            // setShowError(true);
                            if (searchAtt?.length > 2) {
                              fetchAllAttributes(searchAtt)
                            } else if (searchAtt === "" && isTypingSearchAtt === true) {
                              fetchAllAttributes(searchAtt)
                            }
                            else if (searchAtt?.length < 3) {
                              Toast.error("Please enter atleat 3 letter")
                            }
                          }}
                        >
                          Search
                          <FontAwesomeIcon icon={faSearch} />
                        </Button>
                      </Box>
                      <Box sx={{ width: "100%" }}>
                        <Box
                          sx={{
                            borderBottom: 1,
                            borderColor: "divider",
                          }}
                          className="box-attribute-2"
                        >
                          <Tabs
                            value={value}
                            onChange={handleTabChange}
                            aria-label="basic tabs example"
                            // variant={isTablet && "scrollable"}
                            variant="scrollable"
                            className={
                              isErrorOnClassificationTab === true ||
                                isErrorOnEngTab === true ||
                                isErrorOnIRTab === true
                                ? "tabs"
                                : "add-att-tab"
                            }
                            TabIndicatorProps={{
                              style: {
                                backgroundColor:
                                  (isErrorOnClassificationTab ===
                                    true &&
                                    value === 0) ||
                                    (isErrorOnIRTab === true &&
                                      value === 1) ||
                                    (isErrorOnEngTab === true &&
                                      value === 2)
                                    ? "red"
                                    : "#1976d2",
                              },
                            }}
                          >
                            {getAllAttributeSections?.map(
                              (data, index) => (
                                <Tab
                                  label={data?.name}
                                  iconPosition="start"
                                  className="att-tab"
                                  {...a11yProps(index)}
                                />
                              )
                            )}
                          </Tabs>
                        </Box>
                        {getAllAttributeSections?.map((data, index) => {
                          // Filter attributes that belong to the selected section
                          const filteredAttributes = getAllAttributes?.filter(
                            (attdata) => data.id === "all" || attdata?.section_id === data?.id
                          );

                          return (
                            <CustomTabPanel value={value} index={index} errorTab={errorTab}>
                              <Grid container spacing={3}>
                                {filteredAttributes.length > 0 ? (
                                  filteredAttributes.map((attdata, index) => (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      lg={3}
                                      className="cursor-pointer"
                                      key={attdata.attribute_id}
                                      onClick={() => handleSelectAttribute(attdata)} // Handle click
                                    >
                                      <BootstrapTooltip
                                        title={attdata?.description !== "" ? attdata?.description : attdata?.label}
                                        placement="top"
                                        className="tooltip-desc-class"
                                      >
                                        <Box
                                          className={`att-list-div ${attributesData.some((item) => item.attribute_id === attdata.attribute_id)
                                            ? "selected-att"
                                            : ""
                                            }`}
                                        >
                                          {attdata?.icon ? (
                                            <FontAwesomeIcon icon={attdata?.icon?.icon_value} className="att-icon" />
                                          ) : (
                                            <FontAwesomeIcon icon={faFolder} className="att-icon" />
                                          )}
                                          <Typography className="fs-12-muted">{attdata?.label}</Typography>
                                        </Box>
                                      </BootstrapTooltip>
                                    </Grid>
                                  ))
                                ) : (
                                  // Show message only when there are no matching attributes
                                  <Typography className="no-att-text text-center" sx={{ width: "100%", padding: 2 }}>
                                    There are no attributes for selected tab.
                                  </Typography>
                                )}
                              </Grid>
                            </CustomTabPanel>
                          );
                        })}
                      </Box>
                    </DialogContent>
                    <DialogActions className="d-flex flex-wrap justify-content-center gap-2 p-3 z-0 action-btn-model">
                      <Button
                        className="btn primary-btn"
                        onClick={handleCloseForAddAttributeModel}
                      >
                        <FontAwesomeIcon icon={faAdd} />
                        Add Attribute
                      </Button>
                    </DialogActions>
                  </Dialog>

                </Form>

              );
            }}
          </Formik>
        </Container>
      </div>
      <Loader isLoading={loading} />
      <Toaster />
    </>
  );
};

export default AddUsers;
