import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const CheckboxMultiChoice = ({ attdata, handleChangeForFormData, attributesData, handleChangeForChildAtts, isChild, parentAttData, handleChangeForAdditionalTextField, isMulti }) => {
    return (
        <>
            {attdata?.options?.map(
                (multiData, index) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    attributesData
                                        .find(
                                            (attr) =>
                                                attr?.id ==
                                                attdata?.id &&
                                                attr?.section_id ==
                                                attdata?.section_id
                                        )
                                        ?.value?.includes(
                                            multiData?.value
                                        ) || false
                                }
                                name={
                                    multiData?.value
                                }
                                onChange={(
                                    event
                                ) => {
                                    const checked =
                                        event
                                            ?.target
                                            ?.checked;
                                    const optionId =
                                        multiData?.value;

                                    let updatedValues;
                                    if (checked) {
                                        // Add the optionId to the array if checked
                                        updatedValues =
                                            [
                                                ...(attributesData.find(
                                                    (
                                                        attr
                                                    ) =>
                                                        attr?.id ==
                                                        attdata?.id &&
                                                        attr?.section_id ===
                                                        attdata?.section_id
                                                )
                                                    ?.value ||
                                                    []),
                                                optionId,
                                            ];
                                    } else {
                                        // Remove the optionId from the array if unchecked
                                        updatedValues =
                                            (
                                                attributesData.find(
                                                    (
                                                        attr
                                                    ) =>
                                                        attr?.id ==
                                                        attdata?.id &&
                                                        attr?.section_id ===
                                                        attdata?.section_id
                                                )
                                                    ?.value ||
                                                []
                                            ).filter(
                                                (val) =>
                                                    val !==
                                                    optionId
                                            );
                                    }
                                    if (isChild === true) {
                                        handleChangeForChildAtts(
                                            attdata?.nameValue,
                                            updatedValues,
                                            attdata?.section_id,
                                            attdata,
                                            parentAttData
                                        );
                                    }
                                    else if (isMulti === true) {
                                        handleChangeForAdditionalTextField(
                                            attdata?.id,
                                            updatedValues,
                                            parentAttData?.nameValue,
                                            parentAttData?.section_id,
                                            attdata?.fieldtype
                                        )
                                    }
                                    else {
                                        handleChangeForFormData(
                                            attdata?.nameValue,
                                            updatedValues,
                                            attdata?.section_id
                                        );
                                    }

                                }}
                            />
                        }
                        label={
                            multiData?.label
                        }
                        className="radio-group-label"
                    />
                )
            )}
        </>
    )
}

export default CheckboxMultiChoice