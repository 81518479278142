import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Stack, Accordion } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/userDashboard.scss";
import {
    Box,
    Grid,
    Button,
    Paper,
    Avatar,
    Divider,
    TextField,
    Breadcrumbs,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    TableHead
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFolder,
    faFile,
    faMessage,
    faAdd,
    faSearch,
} from "@fortawesome/free-solid-svg-icons";
import logoutHelper from "../../helpers/logoutHelper";
import Loader from "../../components/common/loader";
import { Toaster } from "react-hot-toast";
import api from "../../components/common/commonFunctionForApi";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";
import "../../styles/userTypePage.scss";
import { Toast } from "../../utils/toast";
import Select from "react-select";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AllComponent from "../../components/common/attribute/allComponent";
import AddAttributeMpdel from "../../components/common/modal/AddAttributeModel";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import PaginationCustom from "../../components/common/pagination/paginationCustom";
import { headerAction } from "../../redux/slices/header/header";

function CustomTabPanel(props) {
    const { children, value, className, index, errorTab, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
            {...other}
        >
            {value === index && <Box sx={{ padding: "15px" }}>{children}</Box>}
        </div>
    );
}

const SearchResult = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [istype, setIstype] = useState(false);

    const [page, setPage] = useState(1);
    const [getAllCategories, setGetAllCategories] = useState([])
    const [getAllTypes, setGetAlltypes] = useState([])
    const [selectedCategories, setSelectedCategories] = useState({})

    const [dataOrder, setDataOrder] = useState("asc");

    const [globalSearchData, setGlobalSearchData] = useState()
    const [globalSearchDataTabs, setGlobalSearchDataTabs] = useState([])
    const [globalSearchFilterData, setGlobalSearchFilterData] = useState([])
    const [openForGlobalSearch, setOpenforGobalSearch] = useState(false)

    const [selectedTypeIds, setSelectedTypeIds] = useState([]);
    const [attributesData, setAttributes] = useState([]);
    const [openAddAttribute, setOpenAddAttribute] = useState(false)
    const [getAllAttributeSections, setGetAllAttributeSections] = useState([]);
    const [getAllAttributes, setGetAllAttributes] = useState([])
    const [isTypingSearchAtt, setIsTypingSearchAtt] = useState(false)
    const [searchAtt, setSearchAtt] = useState("")
    const [value, setValue] = useState(0);

    const [sortTypeForAdmin, setSortTypeForAdmin] = useState({
        name: null,
        updated_at: null,
        created_by: null,
    })

    const debounceTimeout = 500; // Adjust the debounce timeout as needed
    const debounceTimeoutForCatFilter = 1200; // Adjust the debounce timeout as needed

    let debounceTimer;
    const {
        open
    } = useSelector((state) => state?.drawerMenu);

    const {
        pagination
    } = useSelector((state) => state?.header);

    const user = localStorage.getItem("user");
    const loggedUser = JSON.parse(user);
    const userToken = localStorage.getItem("accessToken");

    const handleClickForTypeID = (id) => {
        if (id === "all") {
            // If "All" is clicked, deselect everything else
            setSelectedTypeIds(["all"]);
        } else {
            setSelectedTypeIds((prev) => {
                // Deselect "All" if selecting other types
                const filteredPrev = prev.filter((item) => item !== "all");

                if (filteredPrev.includes(id)) {
                    return filteredPrev.filter((selectedId) => selectedId !== id); // Deselect
                } else {
                    return [...filteredPrev, id]; // Select
                }
            });
        }
    };

    const transformCategoryData = (categories) => {
        return categories.map(category => ({
            label: category.title,
            slug: category.slug,
            id: category.id,
            options: category.categoryterm.map(term => ({
                label: term.name,
                value: term.id,
                categoryId: category.id
            }))
        }));
    };

    // Transform function
    const transformCategoryDataPayload = (data) => {
        const result = [];

        Object.values(data).forEach(items => {
            items.forEach(item => {
                let existingCategory = result.find(cat => cat.category_id === item.categoryId);

                if (existingCategory) {
                    existingCategory.term_id.push(item.value);
                } else {
                    result.push({
                        category_id: item.categoryId,
                        term_id: [item.value]
                    });
                }
            });
        });

        return result;
    };

    const formattedAttributes = attributesData.map((item) => ({
        attribute_id: item?.attribute_id,
        attribute_value: item?.value
    }));

    const handleCloseForAddAttributeModel = () => {
        setOpenAddAttribute(false)
        setValue(0)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleCategoryChange = (categoryLabel, selectedOption) => {
        setSelectedCategories(prevState => ({
            ...prevState,
            [categoryLabel]: selectedOption
        }));
    };

    const transformSelectedCategories = (selectedCategories) => {
        const payload = {};

        for (const category in selectedCategories) {
            if (selectedCategories.hasOwnProperty(category)) {
                const terms = selectedCategories[category];
                const categoryId = terms.length > 0 ? terms[0].categoryId : null;

                let termValues = terms.map(term => term.value).join(',');

                if (categoryId !== null && termValues !== "") {
                    payload[category] = {
                        category_id: categoryId,
                        term_values: termValues
                    };
                }
            }
        }

        return payload;
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
        dispatch(headerAction.setPagination({
            ...pagination,
            page: newPage
        }))
        fetchGlobalSearchData(newPage, pagination?.per_page)
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(headerAction.setPagination({
            ...pagination,
            per_page: event.target.value,
            page: 1
        }));
        fetchGlobalSearchData(page, event?.target?.value)
    };


    const handleChangeDataOrdr = () => {
        let order = dataOrder === "asc" ? "desc" : "asc";
        setDataOrder(order);
        setPage(1);
        fetchGlobalSearchData(1);
    };

    const handleChangeForFormData = (
        nameValue,
        value,
        section_id,
        isChildAtt,
        subField,
        isAppendDirectly,
        mediaData
    ) => {

        setAttributes((prevAttributes) => {
            const updatedAttributes = prevAttributes?.map((attribute) => {
                if (
                    attribute?.section_id === section_id &&
                    attribute?.nameValue === nameValue
                ) {
                    // Update the value of the attribute
                    let updatedAttribute = { ...attribute, value };

                    if (attribute?.fieldtype === "link_object" && isAppendDirectly == false) {
                        const currentLinkObject = attribute?.value || {};
                        updatedAttribute.value = {
                            ...currentLinkObject,
                            [subField]: value
                        };

                    } else {
                        updatedAttribute.value = value;
                        // If the fieldtype is radio_single_choice and options are provided
                        if (
                            attribute?.fieldtype === "radio_single_choice" &&
                            attribute?.options
                        ) {
                            const updatedOptions = attribute?.options?.map((option) => {
                                // Set the checked property based on the selected value
                                return { ...option, checked: option?.value == parseInt(value) };
                            });
                            // Update the options of the attribute
                            updatedAttribute = { ...updatedAttribute, options: updatedOptions };
                        }

                        if (attribute.fieldtype === "tags") {
                            // Initialize options with current options or an empty array
                            let options = attribute?.options || [];

                            // Separate existing and new options
                            const existingOptions = value?.filter((option) => !option.__isNew__);
                            const newOptions = value?.filter((option) => option?.__isNew__);

                            // Add new options to the existing options list
                            if (newOptions?.length > 0) {
                                options = [
                                    ...options,
                                    ...newOptions?.map((option) => ({
                                        value: option?.value,
                                        label: option?.label,
                                    })),
                                ];
                            }

                            // Extract selected values from the value array
                            const selectedValues = value?.map((option) => option?.value);

                            // Update the attribute with new options and selected values
                            updatedAttribute = {
                                ...updatedAttribute,
                                options: options,
                                value: selectedValues,
                            };
                        }

                        // If the fieldtype is checkbox_multiple_choice
                        if (
                            attribute?.fieldtype === "checkbox_multiple_choice" &&
                            attribute?.options
                        ) {
                            const updatedOptions = attribute?.options?.map((option) => {
                                // Set the checked property based on whether the option ID is included in the selected values array
                                return { ...option, checked: value?.includes(option?.id) };
                            });
                            // Update the options of the attribute
                            updatedAttribute = { ...updatedAttribute, options: updatedOptions };
                        }

                        if (isChildAtt == true) {
                            updatedAttribute = { ...updatedAttribute, childAtts: value };
                        }
                    }

                    return updatedAttribute;
                }
                return attribute;
            });
            return updatedAttributes;
        });
    };

    const handleSelectAttribute = (attdata) => {
        setAttributes((prev) => {
            // Check if the clicked item is already in the state
            const exists = prev.some((item) => item.attribute_id === attdata.attribute_id);
            if (exists) {
                // Remove the item if it exists
                return prev.filter((item) => item.attribute_id !== attdata.attribute_id);
            } else {
                // Add the item if it doesn't exist
                return [...prev, attdata];
            }
        });
    }

    const handleSort = (key) => {
        let sortTypeValue = null;
        if (sortTypeForAdmin[key] === null) {
            sortTypeValue = 'asc';
        } else if (sortTypeForAdmin[key] === 'asc') {
            sortTypeValue = 'desc';
        } else if (sortTypeForAdmin[key] === 'desc') {
            sortTypeValue = null;
        }
        const data = {
            name: null,
            updated_at: null,
            created_by: null,
            [key]: sortTypeValue
        };
        setSortTypeForAdmin(data)
    }

    const fetchAllCategoryData = async (searchData) => {
        let data = {
            search: searchData,
        }
        try {
            setLoading(true);
            const response = await api.post(`global-search/filter`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success === true) {
                const categories = response?.response?.data?.category_filter
                const types = response?.response?.data?.type_filter
                const allCategories = transformCategoryData(categories);

                const sections = [{ id: 'all', name: 'All' }, ...response?.response?.data?.section];
                setGetAllAttributeSections(sections)
                setGetAllAttributes(response?.response?.data?.attribute_filter)
                setIsTypingSearchAtt(false)
                setGetAllCategories(allCategories);
                setGetAlltypes(types)
                setLoading(false)
            }
        } catch (err) {
            if (err?.response?.status === 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
            setLoading(false);
        }
    }

    const fetchGlobalSearchData = async (pageNo, per_page) => {
        let data = {
            search: search,
            page: pageNo,
            type_id: selectedTypeIds,
            attribute_data: formattedAttributes,
            category_data: transformCategoryDataPayload(selectedCategories), // Check if categoryData is empty
            per_page: per_page
        };
        const firstNonNull = Object.entries(sortTypeForAdmin).find(([key, value]) => value !== null);
        if (firstNonNull) {
            data.order_by = firstNonNull[0] + '_' + firstNonNull[1];
        }
        setPage(0)
        dispatch(headerAction.setPagination({
            ...pagination,
            page: 0,
            per_page: 10,
        }));
        try {
            setLoading(true)
            const response = await api.post(`global-search`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success === true) {
                setGlobalSearchData(response?.response)
                setGlobalSearchDataTabs(response?.response?.Tabs)
                setGlobalSearchFilterData(response?.response?.data)

                // setTotalPages(response?.response?.post?.meta?.last_page);
                dispatch(headerAction.setPagination({
                    ...pagination,
                    page: response?.response?.meta?.current_page,
                    per_page: response?.response?.meta?.per_page,
                    meta_data: response?.response?.meta
                }));
                setLoading(false)
                setOpenforGobalSearch(true)
            } else {
                setLoading(false)
            }
        } catch (err) {
            console.log("error in handleReactionAPI::", err);
            setLoading(false);

            if (err?.response?.status === 401) {
                const userToken = localStorage.getItem("accessToken");
                logoutHelper(dispatch, navigate, userToken);
            } else if (err?.response?.status === 422) {
                const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
            }
        }
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            className:
                value === index
                    ? `selected-tab-attribute`
                    : `non-selected-tab-attribute`,
        };
    }

    useEffect(() => {
        if (search !== "") {
            fetchGlobalSearchData(1, pagination?.per_page)
        }
        setPage(1);
        fetchAllCategoryData(searchAtt)
        setSelectedCategories([])
    }, [])

    useEffect(() => {
        const { searchString, type } = location.state || {};
        if (searchString) {
            if (type) {
                setSelectedTypeIds([type]);
            }
            setSearch(searchString);
        }
    }, [Location])

    useEffect(() => {
        if (search?.length >= 3) {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => {
                setPage(1);
                fetchGlobalSearchData(1, pagination?.per_page);
            }, debounceTimeout);
        }
        else if (search === "" && istype == true) {
            setPage(1);
            fetchGlobalSearchData(1, pagination?.per_page);
        }
        else {
            clearTimeout(debounceTimer);
        }
        return () => clearTimeout(debounceTimer);
    }, [search]);

    useEffect(() => {
        if (selectedCategories && Object.getOwnPropertyNames(selectedCategories)?.length > 0) {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => {
                if (search !== "") {
                    setPage(1);
                    fetchGlobalSearchData(1, pagination?.per_page);
                }
            }, debounceTimeoutForCatFilter);
            return () => clearTimeout(debounceTimer);
        }
    }, [selectedCategories]);

    useEffect(() => {
        if (sortTypeForAdmin?.name !== null) {
            fetchGlobalSearchData(page, pagination?.per_page)
        }
    }, [sortTypeForAdmin])

    return (
        <>
            <div
                className={`main-content-wrapper-2 ${open === true ? "drawer-open" : "drawer-close"
                    }  ${window.innerWidth <= 768 && "small-screen"}`}
            >
                <Container
                    className={`${window.innerWidth <= 768 && "small-screen-container"}`}
                >
                    <Box className="">
                        <Stack spacing={2}>
                            <Breadcrumbs
                                separator={<NavigateNextIcon fontSize="small" />}
                                aria-label="breadcrumb"
                            >
                                <Typography
                                    key="3"
                                    component="h3"
                                    color="inherit"
                                    className="breadcrumb-search-title"
                                >
                                    Search
                                </Typography>
                            </Breadcrumbs>
                        </Stack>
                    </Box>
                    <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
                    <Box className="">
                        <TextField
                            className="bg-white mt-4"
                            fullWidth
                            id="outlined-basic username"
                            variant="outlined"
                            size="small"
                            name="attributeTitle"
                            value={search}
                            placeholder="Keyword Filter"
                            onChange={(e) => {
                                localStorage.removeItem("globalSearchTab")
                                setIstype(true)
                                setSearch(e.target.value);
                                if (e.target.value === "") {
                                    fetchGlobalSearchData(page, pagination?.per_page);
                                }
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    fetchGlobalSearchData(page, pagination?.per_page);
                                }
                            }}
                        />
                    </Box>

                    <Row className="mt-0">
                        <Col lg="12" md="12" >
                            <Accordion
                                alwaysOpen
                                className="w-100"
                            >
                                <Accordion.Item
                                    eventKey="0"
                                    className="bg-white card-wrapper w-100"
                                >
                                    <Accordion.Header className="bg-white fw-700 primary-text">
                                        Types
                                    </Accordion.Header>
                                    <Accordion.Body className="">
                                        {getAllTypes?.length > 0 && (
                                            <Box className="d-flex gap-2 flex-wrap">
                                                {getAllTypes?.map((type, index) => (
                                                    <Box
                                                        key={index}
                                                        className={`type-list-box ${selectedTypeIds?.includes(type?.id) ? "selected" : ""}`}
                                                        onClick={() => handleClickForTypeID(type?.id)}>
                                                        <FontAwesomeIcon icon={type?.icon} className="type-icon-box" />
                                                        <Typography className="type-name-box">{type?.name}</Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item
                                    eventKey="1"
                                    className="bg-white card-wrapper w-100 mt-3"
                                >
                                    <Accordion.Header className="bg-white fw-700 primary-text">
                                        Categories
                                    </Accordion.Header>
                                    <Accordion.Body className="">
                                        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            {getAllCategories?.length > 0 && (
                                                <>
                                                    {getAllCategories?.map((category) => (
                                                        <Grid item xs={12} lg={4} md={6} sm={6} xl={4}>
                                                            <Col lg="12" className="">
                                                                <Typography className="fw-400 fs-14 dark-text ">
                                                                    {category?.label}
                                                                </Typography>
                                                                <Select
                                                                    closeMenuOnSelect={false}
                                                                    options={category?.options}
                                                                    menuPlacement="auto"
                                                                    name="groupManagers"
                                                                    value={selectedCategories[category?.slug] || []}
                                                                    placeholder={`Select Terms...`}
                                                                    menuPosition="fixed"
                                                                    isMulti
                                                                    onChange={(selectedOption) => handleCategoryChange(category?.slug, selectedOption)}
                                                                    className={`muilt-select-field mt-1 `}
                                                                    classNamePrefix="select"
                                                                    styles={{
                                                                        option: (provided, state) => {
                                                                            return {
                                                                                ...provided,
                                                                                backgroundColor: state.isSelected
                                                                                    ? "#660c605c"
                                                                                    : provided.backgroundColor,
                                                                                color: state.isSelected
                                                                                    ? "var(--dark-color)"
                                                                                    : provided.color,
                                                                            };
                                                                        },
                                                                    }} />
                                                            </Col>
                                                        </Grid>
                                                    ))}
                                                </>
                                            )}
                                        </Grid>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item
                                    eventKey="2"
                                    className="bg-white card-wrapper w-100 mt-3"
                                >
                                    <Accordion.Header className="bg-white fw-700 primary-text">
                                        Attributes
                                    </Accordion.Header>
                                    <Accordion.Body className="att-acc-body">
                                        <Box className="att-list-box">
                                            <Box className="d-flex flex-column gap-3 justify-content-center att-card-body">
                                                <Box>
                                                    {attributesData?.length > 0 ? (
                                                        <>
                                                            <AllComponent
                                                                attributesData={attributesData}
                                                                handleChangeForFormData={handleChangeForFormData}
                                                                isChild={false}
                                                                parentAttData={attributesData}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Typography className="no-att-text text-center">There are no current attributes selected.</Typography>
                                                        </>
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className="att-action-box">
                                            <Button
                                                className="btn primary-btn"
                                                onClick={() => {
                                                    setOpenAddAttribute(true)
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faAdd} />
                                                Add Attributes
                                            </Button>
                                        </Box>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Box className="att-action-box-2">
                                <Button
                                    className="btn primary-btn mt-2 "
                                    onClick={() => {
                                        fetchGlobalSearchData(page, pagination?.per_page)
                                    }}
                                >
                                    <FontAwesomeIcon icon={faSearch} />
                                    Search
                                </Button>
                            </Box>
                        </Col>
                    </Row>

                    <Paper
                        sx={{ width: "100%" }}
                        className="type-list-paper mt-4"
                    >
                        <TableContainer component={Paper} className="type-list-container">
                            <Table
                                aria-labelledby="tableTitle"
                            >
                                {globalSearchFilterData?.length > 0 && (
                                    <>
                                        <TableHead className='type-list-header'>
                                            <TableRow>
                                                <TableCell className='type-list-header-data cursor-pointer type-list-first-header'
                                                    onClick={
                                                        () => {
                                                            handleSort('name');
                                                        }
                                                    }
                                                >Name
                                                    {(sortTypeForAdmin?.name && sortTypeForAdmin?.name === 'asc') ? <ExpandLess className="sort-icon" /> : ((sortTypeForAdmin?.name && sortTypeForAdmin?.name === 'desc') ? <ExpandMore className="sort-icon" /> : <ExpandLess className="sort-icon text-muted opacity-50" />)}
                                                </TableCell>
                                                <TableCell className='type-list-header-data type-list-third-header' align="left">Type</TableCell>
                                                <TableCell className='type-list-header-data type-list-third-header' align="left">Date Created</TableCell>
                                                <TableCell className='type-list-header-data type-list-last-header-att-post' align="right">Creator</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableRow style={{ height: '10px', backgroundColor: 'transparent' }} />
                                        <TableBody className='type-list-body'>
                                            {globalSearchFilterData?.map(
                                                (row, index) => (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={index + 1}
                                                        className='type-list-body-row cursor-pointer'
                                                        onClick={() => {
                                                            if (row?.search_type?.toLowerCase() === "folder") {
                                                                localStorage.setItem("type-selected-name-user", row?.post_type?.type_name)
                                                                localStorage.setItem("selected-folder-item", row?.folder_id)
                                                                window.open(`/user-type/${row?.post_type?.type_name}`, '_blank');
                                                            }
                                                            else if (row?.search_type?.toLowerCase() === "people") {
                                                                window.open(`/user-type/people/profile/view/${row?.id}`, '_blank');
                                                            }
                                                            else if (row?.search_type?.toLowerCase() === "chat") {
                                                                localStorage.setItem("selected-chat-room-id", row?.chat_room_id);
                                                                window.open(`/chat?chat_room=${row?.chat_room_id}&chat_id=${row?.id}`, '_blank')
                                                            }
                                                            else {
                                                                localStorage.setItem("type-selected-name-user", row?.post_data?.type_name)
                                                                window.open(`/user-type/view/${row?.post_data?.type_name}/${row?.id}`, '_blank');
                                                            }
                                                        }}
                                                    >
                                                        <TableCell
                                                            className='' component="td" scope="row"
                                                        >
                                                            {row?.search_type === "folder" ? (
                                                                <div className="d-flex flex-warp gap-3 align-items-center">
                                                                    <Box className="folder-tbl-cell-box">
                                                                        <FontAwesomeIcon icon={faFolder} className="folder-tbl-cell" />
                                                                    </Box>
                                                                    <Typography className="type-list-title" variant="h4" component="div">
                                                                        {row?.title}
                                                                    </Typography>
                                                                </div>
                                                            ) : row?.search_type === "people" ? (
                                                                <div className="d-flex flex-warp gap-3 align-items-center">
                                                                    <Avatar
                                                                        className="type-list-created-avatar"
                                                                        src={row?.image}
                                                                        alt={row?.title}
                                                                    >
                                                                        {row?.title?.charAt(0)}
                                                                    </Avatar>
                                                                    <Typography className="type-list-title" variant="h4" component="div">
                                                                        {row?.title}
                                                                    </Typography>
                                                                </div>
                                                            ) : row?.search_type === "chat" ? (
                                                                <div className="d-flex flex-warp gap-3 align-items-center">
                                                                    <Box className="folder-tbl-cell-box">
                                                                        <FontAwesomeIcon icon={faMessage} className="folder-tbl-cell" />
                                                                    </Box>
                                                                    <Typography className="type-list-title" variant="h4" component="div">
                                                                        {row?.title}
                                                                    </Typography>
                                                                </div>
                                                            ) : (
                                                                <div className="d-flex flex-warp gap-3 align-items-center">
                                                                    <Box className="folder-tbl-cell-box">
                                                                        <FontAwesomeIcon icon={faFile} className="folder-tbl-cell" />
                                                                    </Box>
                                                                    <Typography className="type-list-title" variant="h4" component="div">
                                                                        {row?.title}
                                                                    </Typography>
                                                                </div>
                                                            )}
                                                        </TableCell>
                                                        <TableCell className='type-list-text' align="left">
                                                            {row?.search_type !== "post" ? row?.search_type : row?.type}
                                                        </TableCell>
                                                        <TableCell className='type-list-text' align="left">
                                                            {row?.created_at || "---"}
                                                        </TableCell>
                                                        <TableCell className='' align="left">
                                                            <Box className='type-list-text d-flex align-items-center type-list-createdby'>
                                                                <Avatar
                                                                    className="type-list-created-avatar"
                                                                    src={row?.created_by?.thumbnail}
                                                                    alt={row?.created_by?.name}
                                                                >
                                                                    {row?.created_by?.name?.charAt(0)}
                                                                </Avatar>
                                                                <span className>
                                                                    {row?.created_by?.name}
                                                                </span>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                    </>
                                )}
                            </Table>
                        </TableContainer>
                        {globalSearchFilterData?.length > 0 && (
                            <>
                                <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
                                <PaginationCustom
                                    per_page={pagination?.per_page}
                                    per_page_options={[10, 20, 50, 100]}
                                    from={pagination?.meta_data?.from}
                                    to={pagination?.meta_data?.to}
                                    last_page={pagination?.meta_data?.last_page}
                                    total={pagination?.meta_data?.total}
                                    page={pagination?.page}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </>
                        )}
                    </Paper>
                    {globalSearchFilterData?.length < 1 && (
                        <div className="no-data mt-5">
                            <div>
                                <Image
                                    src={NoDataFoundImg}
                                    className="text-center mx-auto no-data-img mt-4"
                                />
                            </div>
                            <Typography component="h5" variant="h5">
                                No data found
                            </Typography>
                        </div>
                    )}
                </Container>

                <AddAttributeMpdel
                    openAddAttribute={openAddAttribute}
                    handleCloseForAddAttributeModel={handleCloseForAddAttributeModel}
                    setIsTypingSearchAtt={setIsTypingSearchAtt}
                    searchAtt={searchAtt}
                    setSearchAtt={setSearchAtt}
                    fetchAllAttributes={fetchAllCategoryData}
                    isTypingSearchAtt={isTypingSearchAtt}
                    value={value}
                    handleChange={handleChange}
                    getAllAttributeSections={getAllAttributeSections}
                    a11yProps={a11yProps}
                    getAllAttributes={getAllAttributes}
                    handleSelectAttribute={handleSelectAttribute}
                    attributesData={attributesData}
                    CustomTabPanel={CustomTabPanel}
                />
                <Loader isLoading={loading} />
                <Toaster />
            </div>
        </>
    )
}

export default SearchResult