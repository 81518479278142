import * as Yup from "yup";

export const addNewNewTypevalidationSchema = Yup.object().shape({
  title: Yup.string("Please enter type title.").required(
    "Type title is required."
  ),
  iconName: Yup.string("Please Select icon.").required(
    "Type icon is required."
  ),
  iconColor: Yup.string("Please Select icon color.").required(
    "Type icon color is required."
  ),
  iconBGColor: Yup.string("Please Select icon background color.").required(
    "Type icon background color is required."
  ),
  // rowsData: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       name: Yup.string().required("Attribute name is required"),
  //       attrId: Yup.string().required("Attribute id is required"),
  //       status: Yup.string().required("Attribute status is required"),
  //       chip: Yup.string().required("Attribute chip is required"),
  //     })
  //   )
  //   .min(1, "Please add minimum 1 attribute"),
});
