import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Image, InputGroup } from "react-bootstrap";
import ReactionGroup from "../../../components/ui/ReactionGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Mention, MentionsInput } from "react-mentions";
import mentionsInputStyle from "../../../helpers/styles/dashboard/mentionsInputStyle";
import mentionStyle from "../../../helpers/styles/dashboard/mentionStyle";
import DummyUser from "../../../assets/images/dummy-user.jpg";
import {
  CommentReactionClassName,
  ReactionClassName,
  ReactionIcon,
} from "../../../helpers/constants/common.js";
import { faComments, faEye } from "@fortawesome/free-solid-svg-icons";
import { formatDateToReadable } from "../../../helpers/DateTimeFormat/dateTimeFormat.js";

const InsightPostCard = ({
  post,
  setElementsCallback,
  setOpenUserImageModel,
  setSinglePostData,
  handlePopoverClose,
  handlePopoverCloseForReplyLike,
  handleEngagementModalOpen,
  toggleReaction,
  handlePopoverOpen,
  handleCommentToggle,
  handleCommentText,
  handleCommentsAPI,
  handlePaste,
  getMentionUsers,
  setIsSelectingSuggestion,
  renderCommentText,
  toggleLikeForCommentsReaction,
  setCurrentPostId,
  setCurrentPostIdForReply,
  handlePopoverOpenForReplyLike,
  handleCommentReplyToggle,
  handleCommentReplyText,
  handleCommentsReplyAPI,
  setFetchAll,
  fetchLoadMoreCommentsAPI,
}) => {
  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);
  return (
    <Card
      className="mt-4"
      data-post-id={post?.id}
      ref={setElementsCallback}
      data-post-view={post?.is_post_seen}
    >
      <CardHeader
        avatar={
          <Avatar
            aria-label="recipe"
            className="author-avtar-creator-post"
            src={post?.created_by?.thumbnail}
          >
            {post?.created_by?.name?.charAt(0)}
          </Avatar>
        }
        title={
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span className="post-posted-label">
              <b>{post?.created_by?.name}</b> posted a <b>{post?.type?.name}</b>
            </span>
            <Box>
              <Typography variant="caption" className="post-date-label">
                {formatDateToReadable(post?.published_date_time)}
              </Typography>
            </Box>
          </Box>
        }
        className="card-header-content"
      />
      <CardContent className="content-div-body-2">
        <Box className="d-flex gap-3 align-items-center">
          <Box className="insight-img-box">
            <Image
              src={
                post?.celebrate_user?.thumbnail !== ""
                  ? post?.celebrate_user?.thumbnail
                  : DummyUser
              }
              alt={post?.celebrate_user?.name || `Image`}
              style={{ width: "100%", height: "100%" }} // Ensure images are responsive
              onClick={() => {
                setOpenUserImageModel(true);
                setSinglePostData(post);
              }}
              className="cursor-pointer rounded-circle"
            />
          </Box>
          <Box>
            <Typography className="insight-content-name text-uppercase">
              {post?.content}
            </Typography>
            <Typography className="insight-content-user-name text-capitalize mt-1">{`${post?.celebrate_user?.name} ${post?.celebrate_user?.last_name}`}</Typography>
          </Box>
        </Box>
      </CardContent>
      {post?.is_post_comment || post?.is_post_like ? (
        <>
          <CardActions
            className="bg-white"
            sx={{
              border: "1px solid #EEEEEE",
              padding: "10px",
            }}
            onMouseEnter={() => {
              handlePopoverClose();
              handlePopoverCloseForReplyLike();
            }}
            onMouseLeave={() => {
              handlePopoverClose();
              handlePopoverCloseForReplyLike();
            }}
          >
            {post?.is_post_like && (
              <Box
                className="action-like w-100 d-flex flex-wrap align-items-center gap-2 cursor-pointer"
                onClick={() => {
                  handleEngagementModalOpen(post?.id);
                }}
              >
                <ReactionGroup reactions={post?.reactions} />
                <Box className="like-counts-box">
                  <Typography className="fs-12-action-btn">
                    {post?.like_count}
                  </Typography>
                </Box>
              </Box>
            )}
            {post?.is_post_comment && (
              <Box className="w-100 ms-0">
                <Typography className="fs-12-action-btn d-flex  justify-content-end">
                  {`${post?.comment_count} comments`}
                </Typography>
              </Box>
            )}
          </CardActions>
          <CardActions
            className="bg-white"
            sx={{
              border: "1px solid #EEEEEE",
              padding: "10px",
            }}
          >
            {post?.is_post_like && (
              <Box
                className="action-like w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                onClick={() => {
                  toggleReaction(
                    post?.id,
                    post?.user_has_liked === "no" ? "like" : "no",
                    post?.user_has_liked === "no"
                  );
                  handlePopoverClose();
                }}
                onMouseEnter={(e) => handlePopoverOpen(e, post.id)}
              >
                <FontAwesomeIcon
                  icon={ReactionIcon[post?.reaction || "like"]}
                  className={`fs-12-action-btn ${
                    ReactionClassName[post?.reaction || ""]
                  }`}
                />
                <Typography
                  className={`fs-12-action-btn text-capitalize ${
                    ReactionClassName[post?.reaction || ""]
                  }`}
                >
                  {post?.user_has_liked !== "no" ? post.reaction : "Like"}
                </Typography>
              </Box>
            )}
            {post?.is_post_comment && (
              <Box
                className="action-comments w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                onClick={() => handleCommentToggle(post?.id)}
                onMouseEnter={() => {
                  handlePopoverClose();
                  handlePopoverCloseForReplyLike();
                }}
                onMouseLeave={() => {
                  handlePopoverClose();
                  handlePopoverCloseForReplyLike();
                }}
              >
                <FontAwesomeIcon
                  icon={faComments}
                  className="fs-12-action-btn"
                />
                <Typography className="fs-12-action-btn">Comments</Typography>
              </Box>
            )}
          </CardActions>
          {post?.is_post_comment && post?.commentToggle && (
            <CardActions
              className="bg-white card-action-2"
              sx={{
                border: "1px solid #EEEEEE",
                padding: "15px",
              }}
              onMouseEnter={() => {
                handlePopoverClose();
              }}
              onMouseLeave={() => {
                handlePopoverClose();
              }}
            >
              <Box
                className="w-100"
                onMouseEnter={() => {
                  handlePopoverClose();
                  handlePopoverCloseForReplyLike();
                }}
                onMouseLeave={() => {
                  handlePopoverClose();
                  handlePopoverCloseForReplyLike();
                }}
                sx={{ height: "40px" }}
              >
                <Box
                  className="d-flex justify-content-start align-items-center gap-2"
                  sx={{ height: "40px" }}
                >
                  <Avatar
                    alt={loggedUser?.name}
                    className="author-avtar-creator-3"
                    src={loggedUser?.thumbnail}
                  >
                    {loggedUser?.name?.charAt(0)}
                  </Avatar>
                  <InputGroup
                    className=""
                    style={{
                      height: "40px",
                    }}
                  >
                    <MentionsInput
                      placeholder="Add Comment. Use '@' for mention"
                      value={post?.commentText}
                      singleLine={true}
                      onChange={(event) => {
                        handleCommentText(event.target.value, post?.id);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleCommentsAPI(post?.id, "yes");
                        }
                      }}
                      className="input-comments"
                      style={mentionsInputStyle}
                      a11ySuggestionsListLabel={"Suggested mentions"}
                      onPaste={handlePaste}
                      suggestionsPortalHost={document.body}
                    >
                      <Mention
                        style={mentionStyle}
                        className="mention-popup-class"
                        data={getMentionUsers}
                        onAdd={() => setIsSelectingSuggestion(true)} // Set the flag when a suggestion is selected
                        appendSpaceOnAdd={true}
                        renderSuggestion={(suggestion, index) => (
                          <div className="list-class" key={index}>
                            <div className="list-class-div-1">
                              <img
                                src={suggestion.photo}
                                alt={suggestion.display}
                                className="list-class-div-1-img"
                              />
                              <div className="list-class-div-2">
                                <span
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {suggestion?.full_name}
                                </span>
                                <br />
                                <span>{suggestion?.job_title}</span>
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    </MentionsInput>
                  </InputGroup>
                </Box>
              </Box>
            </CardActions>
          )}

          {post?.is_post_comment && post?.comments?.length > 0 && (
            <>
              <CardActions
                className="bg-white card-action-3"
                sx={{
                  border: "1px solid #EEEEEE",
                  padding: "10px",
                }}
              >
                {post?.comments?.map((comment) => (
                  <>
                    {comment?.is_parent === "yes" && (
                      <>
                        <Box
                          className="w-100 ms-0"
                          onMouseEnter={handlePopoverClose}
                          onMouseLeave={handlePopoverClose}
                        >
                          <Box className="d-flex justify-content-start align-items-center gap-2">
                            <Avatar
                              alt={comment?.user?.name}
                              className="author-avtar-creator-4 mb-auto"
                              src={comment?.user?.thumbnail}
                            >
                              {comment?.user?.name?.charAt(0)}
                            </Avatar>
                            <Box
                              sx={{
                                padding: "15px",
                              }}
                              className="comment-box"
                            >
                              <Typography className="comment-user-name">
                                {comment?.user?.name}
                              </Typography>
                              <Typography className="fs-14 mt-2">
                                {renderCommentText(comment?.comment)}
                              </Typography>
                            </Box>
                          </Box>
                          <Stack
                            direction="row"
                            divider={
                              <Divider
                                orientation="vertical"
                                flexItem
                                className="nested-divider"
                              />
                            }
                            className="mt-2 align-items-center"
                            spacing={1}
                            style={{
                              margin: "0 0 0 44px",
                            }}
                          >
                            <Box>
                              <Box className="d-flex gap-2 align-items-center">
                                <Typography className="fs-10">
                                  {formatDateToReadable(
                                    comment?.comment_date_time
                                  )}
                                </Typography>
                                <Typography className="fs-10 add-dot" />

                                <Typography
                                  className={`fs-10 cursor-pointer ${
                                    comment?.has_user_liked_comment === "yes" &&
                                    "liked-post-text"
                                  }`}
                                  onClick={() => {
                                    toggleLikeForCommentsReaction(
                                      post?.id,
                                      comment?.has_user_liked_comment === "no"
                                        ? "like"
                                        : "no",
                                      comment?.id,
                                      comment?.has_user_liked_comment === "no"
                                    );
                                  }}
                                  onMouseEnter={(e) => {
                                    setCurrentPostId(post?.id);

                                    setCurrentPostIdForReply(comment?.id);
                                    handlePopoverOpenForReplyLike(
                                      e,
                                      post.id,
                                      comment?.id
                                    );
                                  }}
                                  onMouseLeave={() => {
                                    setCurrentPostId(post?.id);

                                    setCurrentPostIdForReply(comment?.id);
                                  }}
                                >
                                  Like
                                </Typography>
                                <Box
                                  className="action-like d-flex flex-wrap align-items-center gap-1 cursor-pointer"
                                  onMouseEnter={() => {
                                    handlePopoverClose();
                                    handlePopoverCloseForReplyLike();
                                  }}
                                  onMouseLeave={() => {
                                    handlePopoverClose();
                                    handlePopoverCloseForReplyLike();
                                  }}
                                  onClick={() => {
                                    handleEngagementModalOpen(
                                      comment?.id,
                                      "comment"
                                    );
                                  }}
                                >
                                  {comment?.user_reactions?.map((reaction) => (
                                    <FontAwesomeIcon
                                      icon={ReactionIcon[reaction || ""]}
                                      className={
                                        CommentReactionClassName[reaction || ""]
                                      }
                                    />
                                  ))}

                                  <Box className="like-counts-box">
                                    <Typography className="fs-10">
                                      {comment?.comment_likes_count}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              onMouseEnter={() => {
                                handlePopoverClose();
                                handlePopoverCloseForReplyLike();
                              }}
                              onMouseLeave={() => {
                                handlePopoverClose();
                                handlePopoverCloseForReplyLike();
                              }}
                            >
                              <Box>
                                <Box className="w-100 d-flex gap-2 align-items-center">
                                  <Typography
                                    className="fs-10 cursor-pointer"
                                    onClick={() =>
                                      handleCommentReplyToggle(
                                        post?.id,
                                        comment?.id
                                      )
                                    }
                                  >
                                    Reply
                                  </Typography>
                                  <Typography className="fs-10 cursor-pointer">
                                    {`${comment?.replies_count} reply`}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Stack>
                        </Box>

                        {comment?.commentReplyToggle && (
                          <Box
                            className="w-100 ms-0"
                            sx={{ paddingLeft: "62px" }}
                            onMouseEnter={() => {
                              handlePopoverClose();
                              handlePopoverCloseForReplyLike();
                            }}
                            onMouseLeave={() => {
                              handlePopoverClose();
                              handlePopoverCloseForReplyLike();
                            }}
                          >
                            <Box className="w-100 mt-2 mb-1">
                              <Box className="d-flex justify-content-start align-items-center gap-2">
                                <Avatar
                                  alt={loggedUser?.name}
                                  className="author-avtar-creator-4"
                                  src={loggedUser?.thumbnail}
                                >
                                  {loggedUser?.name?.charAt(0)}
                                </Avatar>
                                <InputGroup
                                  className=""
                                  style={{
                                    height: "40px",
                                  }}
                                >
                                  <MentionsInput
                                    placeholder="Add Comment. Use '@' for mention"
                                    value={comment?.commentReplyText}
                                    singleLine={true}
                                    onChange={(event) => {
                                      handleCommentReplyText(
                                        event?.target?.value,
                                        post?.id,
                                        comment?.id
                                      );
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        e.preventDefault();
                                        handleCommentsReplyAPI(
                                          post?.id,
                                          "no",
                                          comment?.id
                                        );
                                      }
                                    }}
                                    className="input-comments"
                                    style={mentionsInputStyle}
                                    a11ySuggestionsListLabel={
                                      "Suggested mentions"
                                    }
                                    onPaste={handlePaste}
                                  >
                                    <Mention
                                      style={mentionStyle}
                                      className="mention-popup-class"
                                      data={getMentionUsers}
                                      appendSpaceOnAdd={true}
                                      onAdd={() =>
                                        setIsSelectingSuggestion(true)
                                      } // Set the flag when a suggestion is selected
                                      renderSuggestion={(suggestion, index) => (
                                        <div className="list-class" key={index}>
                                          <div className="list-class-div-1">
                                            <img
                                              src={suggestion.photo}
                                              alt={suggestion.display}
                                              className="list-class-div-1-img"
                                            />
                                            <div className="list-class-div-2">
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {suggestion?.full_name}
                                              </span>
                                              <br />
                                              <span>
                                                {suggestion?.job_title}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    />
                                  </MentionsInput>
                                </InputGroup>
                              </Box>
                            </Box>
                          </Box>
                        )}
                        {comment?.replies?.length > 0 ? (
                          <>
                            {comment?.replies?.map((reply) => (
                              <Box
                                className="w-100 ms-0"
                                sx={{
                                  paddingLeft: "62px",
                                }}
                                onMouseEnter={() => {
                                  handlePopoverClose();
                                  handlePopoverCloseForReplyLike();
                                }}
                                onMouseLeave={() => {
                                  handlePopoverClose();
                                  handlePopoverCloseForReplyLike();
                                }}
                              >
                                <Box className="w-100 mt-2">
                                  <Box className="d-flex justify-content-start align-items-center gap-2">
                                    <Avatar
                                      alt={reply?.user?.name}
                                      className="author-avtar-creator-4 mb-auto"
                                      src={reply?.user?.thumbnail}
                                    >
                                      {reply?.user?.name?.charAt(0)}
                                    </Avatar>
                                    <Box
                                      sx={{
                                        padding: "10px",
                                      }}
                                      className="comment-box"
                                    >
                                      <Typography className="comment-user-name">
                                        {reply?.user?.name}
                                      </Typography>
                                      <Typography className="fs-14 mt-2">
                                        {renderCommentText(reply?.comment)}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Stack
                                    direction="row"
                                    divider={
                                      <Divider
                                        orientation="vertical"
                                        flexItem
                                        className="nested-divider"
                                      />
                                    }
                                    className="mt-2 align-items-center"
                                    spacing={1}
                                    style={{
                                      margin: "0 0 0 44px",
                                    }}
                                  >
                                    <Box>
                                      <Box className="d-flex gap-2 align-items-center">
                                        <Typography className="fs-10">
                                          {formatDateToReadable(
                                            reply?.comment_date_time
                                          )}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Stack>
                                </Box>
                              </Box>
                            ))}
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </>
                ))}
                {post?.comments?.length < post?.parent_comment_count && (
                  <Box
                    className="w-100 ms-0 d-flex justify-content-center"
                    onMouseEnter={() => {
                      handlePopoverClose();
                      handlePopoverCloseForReplyLike();
                    }}
                    onMouseLeave={() => {
                      handlePopoverClose();
                      handlePopoverCloseForReplyLike();
                    }}
                  >
                    <Button
                      className="mt-4 btn-load-comments text-capitalize"
                      onClick={() => {
                        setFetchAll(true);
                        fetchLoadMoreCommentsAPI(post);
                      }}
                    >
                      <FontAwesomeIcon icon={faEye} />
                      Load More Comments
                    </Button>
                  </Box>
                )}
              </CardActions>
            </>
          )}
        </>
      ) : (
        ""
      )}
    </Card>
  );
};

export default InsightPostCard;
