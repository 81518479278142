import React, { useEffect, useState, useCallback, lazy, Suspense } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Button,
  Carousel,
} from "react-bootstrap";
import { Popper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/userDashboard.scss";
import Form from "react-bootstrap/Form";
import {
  Box,
  Paper,
  Avatar,
  IconButton,
  TextField,
  Stack,
  Divider,
  Popover,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCheck,
  faThumbsUp,
  faHeart,
  faSmile,
  faAngry,
  faPhone,
  faEnvelopeOpen,
  faFilePdf,
  faFile,
  faFileExcel,
  faEye,
  faPen,
  faTrash,
  faXmark,
  faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import logoutHelper from "../../helpers/logoutHelper";
import Loader from "../../components/common/loader";
import { Toaster } from "react-hot-toast";
import api from "../../components/common/commonFunctionForApi";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "../../utils/toast";

import DummyUser from "../../assets/images/dummy-user.jpg";
import { useLocation } from "react-router-dom";
import ModalEngagement from "../../components/common/modal/ModalEngagement";
import { onlyDateFormat } from "../../helpers/DateTimeFormat/dateTimeFormat";
import UploadMediaModel from "../../components/common/media/uploadMediaModel";
import FileSaver from "file-saver";
import AddMedia from "../../components/common/media/addMedia";
import { useDropzone } from "react-dropzone";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
import ActionMenu from "../../components/ui/ActionMenu";
import PostCard from "./component/PostCard.jsx";
import InsightPostCard from "./component/InsightPostCard.jsx";
import UpdatePostCard from "./component/UpdatePostCard.jsx";

const UpdatePostCreateModal = lazy(() =>
  import("../../components/common/dashboard/UpdatePostCreateModal")
);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const columns_atttributes_headers = [
  {
    key: 1,
    disablePadding: true,
    id: "name",
    label: "Name",
    minWidth: 250,
    className: "other-color",
  },
  {
    key: 2,
    id: "view",
    disablePadding: true,
    label: "View",
    minWidth: 50,
    className: "other-color",
  },
  {
    key: 3,
    id: "deny",
    disablePadding: true,
    label: "Deny",
    minWidth: 50,
    className: "other-color",
  },
];

const columns_advace_atttributes_headers = [
  {
    key: 1,
    disablePadding: true,
    id: "name",
    label: "Name",
    minWidth: 250,
    className: "other-color",
  },
  {
    key: 2,
    id: "view",
    disablePadding: true,
    label: "View",
    minWidth: 50,
    className: "other-color",
  },
  {
    key: 3,
    id: "deny",
    disablePadding: true,
    label: "Deny",
    minWidth: 50,
    className: "other-color",
  },

  {
    key: 5,
    id: "edit",
    disablePadding: true,
    label: "Edit",
    minWidth: 50,
    className: "other-color",
  },
  {
    key: 6,
    id: "delete",
    disablePadding: true,
    label: "Delete",
    minWidth: 50,
    className: "other-color",
  },
  {
    key: 7,
    id: "redacted",
    disablePadding: true,
    label: "Redacted",
    minWidth: 50,
    className: "other-color",
  },
];

const UserDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { open, adminAccessible } = useSelector((state) => state?.drawerMenu);

  const [loading, setLoading] = useState(false);
  const [getAllPost, setGetAllPost] = useState([]);

  const [openPostPopup, setOpenPostPopup] = useState(false);
  const [updatePostId, setUpdatePostId] = useState();
  const [page, setPage] = useState(1);

  const [anchorElForReaction, setAnchorElForReaction] = useState(null);
  const [currentPostId, setCurrentPostId] = useState(null);

  const [anchorElForReplyReaction, setAnchorElForReplyReaction] =
    useState(null);
  const [anchorElForPopupUser, setAnchorElForPopupUser] = useState(null);
  const [anchorElMenuForPost, setAnchorElForMenuForPost] = useState(null);

  const [currentPostIdForReply, setCurrentPostIdForReply] = useState(null);
  const [fetchAll, setFetchAll] = useState(false);
  const [getMentionUsers, setGetMentionUsers] = useState([]);
  const [ackQuestions, setAckQuestions] = useState([]);

  const [openAckQuePopup, setOpenAckQuePopup] = useState(false);
  const [postIdForAckQue, setPostIdForAckQue] = useState();

  const [openAckQuePopupForce, setOpenAckQuePopupForce] = useState(false);
  const [postIdForAckQueForce, setPostIdForAckQueForce] = useState();

  const [isPopupUserExist, setIsPopupUserExist] = useState(false);
  const [popupUserDetails, setPopupUserDetails] = useState();

  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);

  const openForReaction = Boolean(anchorElForReaction);
  const openForReactionReply = Boolean(anchorElForReplyReaction);
  const openForPopupUser = Boolean(anchorElForPopupUser);

  const openMenuForPost = Boolean(anchorElMenuForPost);

  const idPopupUser = openForPopupUser ? "simple-popover" : undefined;

  const [isSelectingSuggestion, setIsSelectingSuggestion] = useState(false);

  const [timeoutId, setTimeoutId] = useState(null);

  const [elements, setElements] = useState([]);
  const [modelPosts, setModelPosts] = useState([]);
  const [currentPostIndex, setCurrentPostIndex] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [modelPostsForAck, setModelPostsForAck] = useState([]);
  const [currentPostIndexForAck, setCurrentPostIndexForAck] = useState(null);
  const [isPopupOpenForAck, setIsPopupOpenForAck] = useState(false);
  const [popupAckData, setPopupAckData] = useState();

  //State used for posts paginations
  const [totalPages, setTotalPages] = useState(null);
  const [nextPage, setNextPage] = useState(false);
  const [isDataFetching, setIsDataFetching] = useState(false);

  const [openMediaUpload, setOpenMediaUpload] = useState(false);
  const [myMediaFolderList, setMyFolderList] = useState([]);
  const [systemMediaFolderList, setSystemFolderList] = useState([]);
  const [selectedMediaTypeForImage, setSelectedMediaTypeForImage] =
    useState("");
  const [selectedMediaTypeForDoc, setSelectedMediaTypeForDoc] = useState("");

  const [selectedMediaFolderIDForImage, setSelectedMediaFolderIDForImage] =
    useState("");
  const [selectedMediaFolderIDFordoc, setSelectedMediaFolderIDForDoc] =
    useState("");

  const [mediaList, setMediaList] = useState([]);
  const [mediaListResponse, setMediaListResponse] = useState();

  const [
    selectedMediaFileDetailsForImage,
    setSelectedMediaFileDetailsForImage,
  ] = useState();
  const [selectedMediaFileForImage, setSelectedMediaFileForImage] =
    useState("");

  const [selectedMediaFileDetailsForDoc, setSelectedMediaFileDetailsForDoc] =
    useState();
  const [selectedMediaFileForDoc, setSelectedMediaFileForDoc] = useState("");

  const [mediaDataFor, setMediaDataFor] = useState("");

  const [rowsPerPageForMedia, setRowsPerPageForMedia] = useState(18);
  const [pageForMedia, setPageForMedia] = useState(0);

  const [openMediaModelFor, setOpenMediaModelFor] = useState("");
  const [openFileUploadModelMedia, setOpenFileUploadModelMedia] =
    useState(false);

  const [mediaTitle, setMediaTitle] = useState("");
  const [mediaAltText, setMediaAltText] = useState("");
  const [mediaDescription, setMediaDescription] = useState("");
  const [mediaDirectUrl, setMediaDirectUrl] = useState("");
  const [isSystemTypeMedia, setIsSystemTypeMedia] = useState(false);
  const [responseForMedia, setResponseForMedia] = useState();
  const [valueForMediaTab, setValueForMediaTab] = useState(0);

  const [selectedMedia, setSelectedMedia] = useState("");
  const [permissionsForMedia, setPermissionsForMedia] = useState([]);
  const [parentFolderId, setParentFolderId] = useState(0);
  const [isParentFolderSystemType, setIsParentFolderSystemType] =
    useState(false);

  const [isEditMedia, setIsEditMedia] = useState(false);
  const [isRenameMedia, setIsRenameMedia] = useState(false);
  const [selectedFileMedia, setSelectedFileMedia] = useState(null);

  const [isAdvancePermissionForMedia, setIsAdvancePermissionForMedia] =
    useState(false);
  const [openRnameMediaModel, setOpenRenameModel] = useState(false);
  const [selectedMediaFileForUpdate, setSelectedMediaFileForUpdate] =
    useState();
  const [isImageMedia, setIsImageMedia] = useState(false);

  const [
    selectedMediaFileDetailsForImages,
    setSelectedMediaFileDetailsForImages,
  ] = useState([]);
  const [selectedMediaFileForImages, setSelectedMediaFileForImages] = useState(
    []
  );

  const [menuItemsForPostList, SetMenuItemsForPostList] = useState([]);

  const [singlePostData, setSinglePostData] = useState();

  const [openImageModel, setOpenImageModel] = useState();
  const [openUserImageModel, setOpenUserImageModel] = useState();

  const [selectedImageIndex, setSelectedImageIndex] = useState(0); // Add this state to track the selected image index
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);

  const [showRenderImage, setShowRenderImage] = useState(true);
  const [uploadedFile, setUploadedFile] = useState();
  const [selectedAnyMediaFile, setSelectedAnyMediaFile] = useState();
  const [selectedAnyMediaFileDetails, setSelectedAnyMediaFileDetails] =
    useState();

  const maxVisibleCategory = 1;

  const media_Pagination_options = [
    { label: "18", value: 18 },
    { label: "36", value: 36 },
    { label: "54", value: 54 },
    { label: "108", value: 108 },
  ];

  const userToken = localStorage.getItem("accessToken");

  const total_mediaFiles = parseInt(mediaListResponse?.meta?.total);

  const unseenPosts = getAllPost.filter(
    (post) => !post.is_seen && !post?.is_feed
  );
  const seenPosts = getAllPost.filter((post) => post.is_seen && !post?.is_feed);
  const unseenFeedPosts = getAllPost.filter(
    (post) => post.is_feed && !post.is_seen
  );
  const seenFeedPosts = getAllPost.filter(
    (post) => post.is_feed && post.is_seen
  );

  const renderUpdateContent = (text) => {
    const mentionRegex = /@\[(.*?)\]\((.*?)\)/g;
    const urlRegex = /((https?:\/\/|www\.)[^\s]+)/g;

    // Combine both regex patterns to match URLs and mentions
    const combinedRegex = new RegExp(
      `${mentionRegex.source}|${urlRegex.source}`,
      "g"
    );
    const matches = text.matchAll(combinedRegex);

    const elements = [];
    let lastIndex = 0;

    for (const match of matches) {
      const matchIndex = match.index;

      // Append any text before the match as regular text
      if (lastIndex < matchIndex) {
        elements.push(
          <span key={lastIndex}>{text.slice(lastIndex, matchIndex)}</span>
        );
      }

      // Check if match is a mention
      if (match[1] && match[2]) {
        const mentionText = match[1];
        const mentionId = match[2];
        elements.push(
          <span
            key={matchIndex}
            className="fw-bolder cursor-pointer"
            id={`update-${mentionId}`}
            aria-describedby={idPopupUser}
            onMouseEnter={(e) => {
              const id = setTimeout(() => {
                handlePopoverOpenForMentionUser(e, mentionId);
              }, 1000);
              setTimeoutId(id);
            }}
            onMouseLeave={() => {
              if (timeoutId) {
                clearTimeout(timeoutId);
                setTimeoutId(null);
              }
            }}
          >
            {mentionText}
          </span>
        );
      }
      // Check if match is a URL
      else if (match[0]) {
        const url = match[0];
        const href = url.startsWith("www.") ? `https://${url}` : url;
        elements.push(
          <a
            key={matchIndex}
            href={href}
            className="text-decoration-none color-inherit"
            target="_blank"
            rel="noopener noreferrer"
          >
            {url}
          </a>
        );
      }

      // Update lastIndex to the end of the current match
      lastIndex = matchIndex + match[0].length;
    }

    // Append any remaining text after the last match as regular text
    if (lastIndex < text.length) {
      elements.push(<span key={lastIndex}>{text.slice(lastIndex)}</span>);
    }

    return elements;
  };

  // Utility function to convert a string to title case
  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const fullNameForUpdatePost = loggedUser?.full_name
    ? toTitleCase(loggedUser.full_name)
    : "";

  const formatDateToReadable = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();

    // Determine the suffix for the day
    const suffix =
      day === 1 || day === 21 || day === 31
        ? "st"
        : day === 2 || day === 22
          ? "nd"
          : day === 3 || day === 23
            ? "rd"
            : "th";

    const month = date.toLocaleString("default", { month: "long" }); // e.g., January
    const year = date.getFullYear();

    return `${day}${suffix} ${month} ${year}`;
  };

  const getMimeType = (extension) => {
    const mimeTypes = {
      png: "image/png",
      jpg: "image/jpeg",
      jpeg: "image/jpeg",
      pdf: "application/pdf",
      docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      mp4: "video/mp4",
      mp3: "audio/mpeg",
    };
    return mimeTypes[extension] || "application/octet-stream";
  };

  const handleOpenUploadMedia = (dataFor) => {
    setMediaDataFor(dataFor);
    fetchMediaFolders(dataFor);
  };

  const handleChangePageForMedia = (event, newPage) => {
    if (newPage > pageForMedia) {
      setPageForMedia(newPage);
      let media_id;
      if (mediaDataFor === "image") {
        media_id = selectedMediaFileForImage;
      } else if (mediaDataFor === "doc") {
        media_id = selectedMediaFileForDoc;
      }
      fetchMediaFiles(newPage + 1, rowsPerPageForMedia, mediaDataFor, media_id);
    } else if (newPage < pageForMedia) {
      setPageForMedia(newPage);
      let media_id;
      if (mediaDataFor === "image") {
        media_id = selectedMediaFileForImage;
      } else if (mediaDataFor === "doc") {
        media_id = selectedMediaFileForDoc;
      }
      fetchMediaFiles(newPage + 1, rowsPerPageForMedia, mediaDataFor, media_id);
    }
  };

  const handleChangeRowsPerPageForMedia = (event) => {
    setRowsPerPageForMedia(event.target.value);
    setPageForMedia(0);
    let media_id;
    if (mediaDataFor === "image") {
      media_id = selectedMediaFileForImage;
    } else if (mediaDataFor === "doc") {
      media_id = selectedMediaFileForDoc;
    }
    fetchMediaFiles(0, event.target.value, mediaDataFor, media_id);
  };

  const handleCloseMediaModelAddMedia = () => {
    setOpenFileUploadModelMedia(true);
    let folder_id;
    if (mediaDataFor === "image") {
      folder_id = selectedMediaFolderIDForImage;
      if (selectedMediaTypeForImage === "system") {
        setIsSystemTypeMedia(true);
        setIsParentFolderSystemType(true);
      } else {
        setIsSystemTypeMedia(false);
        setIsParentFolderSystemType(true);
      }
    } else if (mediaDataFor === "doc") {
      folder_id = selectedMediaFolderIDFordoc;
      if (selectedMediaTypeForDoc === "system") {
        setIsSystemTypeMedia(true);
        setIsParentFolderSystemType(true);
      } else {
        setIsSystemTypeMedia(false);
        setIsParentFolderSystemType(true);
      }
    }
    setParentFolderId(folder_id);
  };

  const handleEditUpdatePost = (data) => {
    // fetchUpdatePostDetail(data?.id)
    setUpdatePostId(data?.id);
    setOpenPostPopup(true);
    setAnchorElForMenuForPost(null);
  };

  const handleClickForMenuPost = (event, row) => {
    console.log("menu row data::", row);

    const handleEditAction = () => {
      if (row?.type?.slug === "update") {
        // Call your function here
        handleEditUpdatePost(row);
      } else {
        // Navigate to the edit page
        navigate(`/type/edit/${row?.type?.slug}/${row?.id}`);
      }
    };

    const menuItemsArr = [
      {
        label: "View",
        icon: <FontAwesomeIcon icon={faEye} />,
        onClick: () =>
          navigate(`/user-type/view/${row?.type?.name}/${row?.id}`),
      },
      ...(row?.is_edit === 1
        ? [
          {
            label: "Edit",
            icon: <FontAwesomeIcon icon={faPen} />,
            onClick: handleEditAction, // Use the conditional handler
            showDivider: true,
            className: "edit-item", // Custom class for the menu item
            iconClassName: "edit-icon", // Custom class for the icon
            labelClassName: "edit-label",
          },
        ]
        : []),
      ...(row?.is_delete === 1
        ? [
          {
            label: "Delete",
            icon: <FontAwesomeIcon icon={faTrash} />,
            onClick: () => handleDeletePost(row),
            showDivider: true,
            className: "action-delete-item", // Custom class for the menu item
            iconClassName: "action-delete-icon", // Custom class for the icon
            labelClassName: "action-delete-label",
          },
        ]
        : []),
    ];

    SetMenuItemsForPostList(menuItemsArr);
    setAnchorElForMenuForPost(event.currentTarget);
    setSinglePostData(row);
  };

  const handleCloseForMenuPost = () => {
    setAnchorElForMenuForPost(null);
    setSinglePostData();
    SetMenuItemsForPostList([]);
  };

  const handleCloseDeleteAlertModel = () => {
    setOpenDeleteAlert(false);
    setSinglePostData();
    setAnchorElForMenuForPost(null);
  };

  const handleDeletePost = (data) => {
    setSinglePostData(data);
    setAnchorElForMenuForPost(null);

    setOpenDeleteAlert(true);
  };

  const [openEngagementModal, setOpenEngagementModal] = useState(false);
  const [enagagementModalData, setEngagementModalData] = useState([]);

  const handleEngagementModalApi = async (id) => {
    try {
      setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.get(`user/post/engagement/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success) {
        setEngagementModalData([response?.response?.data]);
        setLoading(false);
      } else if (response?.success === false) {
        setLoading(false);
        Toast.warning(response?.message);
      }
    } catch (err) {
      console.log("error in fetch post engagement api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        Toast.warning(err?.response?.message);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        Toast.warning(err?.response?.data[0]?.message);
        if (loggedUser?.user_type === "user") {
          localStorage.setItem("last-redirect-page-user", `/`);
          navigate("/");
        } else if (loggedUser?.user_type === "admin" && adminAccessible) {
          localStorage.setItem("last-redirect-page-admin-user", `/`);
          navigate(`/`);
        }
      }
    }
  };

  const handleCommentEngagementModalApi = async (id) => {
    try {
      setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.get(`user/comment/engagement/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success) {
        setEngagementModalData([response?.response?.data]);
        setLoading(false);
      } else if (response?.success === false) {
        setLoading(false);
        Toast.warning(response?.message);
      }
    } catch (err) {
      console.log("error in fetch post engagement api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        Toast.warning(err?.response?.message);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        Toast.warning(err?.response?.data[0]?.message);
        if (loggedUser?.user_type === "user") {
          localStorage.setItem("last-redirect-page-user", `/`);
          navigate("/");
        } else if (loggedUser?.user_type === "admin" && adminAccessible) {
          localStorage.setItem("last-redirect-page-admin-user", `/`);
          navigate(`/`);
        }
      }
    }
  };

  const handleEngagementModalOpen = async (id, type = "post") => {
    console.log("handle enagagement modal::", id);
    console.log("id type is ::", type);
    setLoading(true);
    console.log("after loader::", id);
    if (type === "post") {
      await handleEngagementModalApi(id);
    } else if (type === "comment") {
      await handleCommentEngagementModalApi(id);
    }
    setOpenEngagementModal(true);
    setLoading(false);
  };

  const handleEngagementModalClose = () => {
    setOpenEngagementModal(false);
    setEngagementModalData([]);
  };

  const handleClickOpen = () => {
    setOpenPostPopup(true);
  };

  const handleChangeForMedia = (event, newValue) => {
    setValueForMediaTab(newValue);
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles?.length > 0) {
      const file = acceptedFiles[0];
      const fileUrl = URL?.createObjectURL(file); // Create a URL for the file

      // Set the selected file with both file object and its URL
      setSelectedFileMedia({
        file,
        url: fileUrl,
        type: file?.type, // Get the MIME type from the dropped file
        name: file?.name, // Use the file's name for display
      });
    }
  }, []);

  const acceptedFilesForUpload = () => {
    if (mediaDataFor === "image") {
      return {
        "image/*": [], // Accept all image types
      };
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFilesForUpload(),
    multiple: false,
  });

  const renderPreview = () => {
    if (!selectedFileMedia) return null;

    // Handle image files
    if (selectedFileMedia?.type?.startsWith("image/")) {
      return (
        <div className="image-preview">
          <img
            src={selectedFileMedia?.url}
            alt="Uploaded"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover", // Maintain aspect ratio
            }}
          />
        </div>
      );
    }

    // Handle PDF files
    if (selectedFileMedia?.type === "application/pdf") {
      // return <p>PDF file selected: {selectedFile.name}</p>;
      return (
        <Card
          key={"pdf"}
          variant="outlined"
          sx={{
            p: 2,
            mb: 2,
            cursor: "pointer",
          }}
          className="pdf-doc"
        >
          <Box
            height={150}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon size="3x" icon={faFilePdf} color="#660C60" />
          </Box>
          <Divider />
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            {selectedFileMedia?.name}
          </Box>
        </Card>
      );
    }

    // Handle DOCX files
    if (
      selectedFileMedia?.type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      // return <p>DOCX file selected: {selectedFile.name}</p>;
      return (
        <Card
          key={"pdf"}
          variant="outlined"
          sx={{
            p: 2,
            mb: 2,
            cursor: "pointer",
          }}
          className="pdf-doc"
        >
          <Box
            height={150}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon size="3x" icon={faFile} color="#660C60" />
          </Box>
          <Divider />
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            {selectedFileMedia?.name}
          </Box>
        </Card>
      );
    }

    // Handle PPTX files
    if (
      selectedFileMedia?.type ===
      "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      return (
        <Card
          key={"pdf"}
          variant="outlined"
          sx={{
            p: 2,
            mb: 2,
            cursor: "pointer",
          }}
          className="pdf-doc"
        >
          <Box
            height={150}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon size="3x" icon={faFile} color="#660C60" />
          </Box>
          <Divider />
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            {selectedFileMedia?.name}
          </Box>
        </Card>
      );
    }

    if (
      selectedFileMedia?.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return (
        <Card
          key={"pdf"}
          variant="outlined"
          sx={{
            p: 2,
            mb: 2,
            cursor: "pointer",
          }}
          className="pdf-doc"
        >
          <Box
            height={150}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon size="3x" icon={faFileExcel} color="#660C60" />
          </Box>
          <Divider />
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            {selectedFileMedia?.name}
          </Box>
        </Card>
      );
    }

    // Handle MP4 files
    if (selectedFileMedia?.type === "video/mp4") {
      return (
        <Box className="w-100 h-100">
          <Typography>{selectedFileMedia?.name}</Typography>
          <ReactPlayer
            url={selectedFileMedia?.url}
            className="react-player"
            playing={true}
            width="100%"
            height="100%"
            controls={true}
          />
        </Box>
      );
    }

    // Handle MP3 files
    if (selectedFileMedia?.type === "audio/mpeg") {
      return (
        <Box className="w-100 h-100 audio-file">
          <Typography>{selectedFileMedia?.name}</Typography>
          <ReactAudioPlayer src={selectedFileMedia?.url} controls />
        </Box>
      );
    }

    return (
      <Card
        key={"pdf"}
        variant="outlined"
        sx={{
          p: 2,
          mb: 2,
          cursor: "pointer",
        }}
        className="pdf-doc"
      >
        <Box
          height={150}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <FontAwesomeIcon size="3x" icon={faFilePdf} color="#660C60" />
        </Box>
        <Divider />
        <Box
          sx={{
            paddingLeft: 1,
          }}
        >
          {selectedFileMedia?.name}
        </Box>
      </Card>
    );
  };

  const handleCloseForAckQue = () => {
    setOpenAckQuePopup(false);
    setAckQuestions([]);
    setPostIdForAckQue("");
  };

  const handleCloseForAckQueForce = () => {
    if (popupAckData?.acknowledged_on !== "") {
      setOpenAckQuePopupForce(false);
      setAckQuestions([]);
      setPostIdForAckQueForce("");
      setPopupAckData();
    } else {
      setIsPopupOpenForAck(true);
      setCurrentPostIndexForAck(0);
      setAckQuestions([]);
    }
  };

  const handleCloseForImgeModel = () => {
    setOpenImageModel(false);
    setSinglePostData();
    setSelectedImageIndex(0);
  };

  const handleCloseForUserImageModel = () => {
    setOpenUserImageModel(false);
    setSinglePostData();
  };

  const handleInputChangeForAckQuestions = (event, index) => {
    const { value } = event.target;
    setAckQuestions((prevQuestions) =>
      prevQuestions.map((que, i) =>
        i === index ? { ...que, ans: value } : que
      )
    );
  };

  const handleCloseForUploadMedia = () => {
    setOpenMediaUpload(false);
    // setFolderList()
    setMyFolderList([]);
    setSystemFolderList([]);
    setMediaDataFor("");
  };

  const downloadFileForImage = async () => {
    var blob = new Blob([selectedMediaFileDetailsForImage?.file_path], {
      type: getMimeType(selectedMediaFileDetailsForImage?.type),
    });
    FileSaver.saveAs(blob, selectedMediaFileDetailsForImage?.title);
  };

  const downloadFileFordoc = async () => {
    var blob = new Blob([selectedMediaFileDetailsForDoc?.file_path], {
      type: getMimeType(selectedMediaFileDetailsForDoc?.type),
    });
    FileSaver.saveAs(blob, selectedMediaFileDetailsForDoc?.title);
  };

  const toggleReaction = async (postId, reaction, isLiked) => {
    let newUserLikedData;

    await setGetAllPost((prevPosts) =>
      prevPosts.map((post) => {
        if (post.id === postId) {
          let newUserLiked = post?.user_has_liked;
          let newReaction = post.reaction;
          let newLikeCount = post.like_count;

          if (newUserLiked === "no") {
            // No reaction was selected before, adding a new reaction
            newUserLiked = "yes";
            newReaction = reaction;
            newLikeCount += 1; // Increase like count when adding a new reaction
          } else if (newReaction !== reaction && isLiked === false) {
            newUserLiked = "no";
            newReaction = "";
            newLikeCount -= 1;
          } else if (newReaction !== reaction && isLiked) {
            // Switching to a different reaction
            newReaction = reaction;
            // Like count remains the same since only the reaction is changing
          } else {
            // Removing the current reaction
            newUserLiked = "no";
            newReaction = "no";
            newLikeCount -= 1; // Decrease like count when removing the reaction
          }

          newUserLikedData = newUserLiked;

          return {
            ...post,
            user_has_liked: newUserLiked,
            reaction: newReaction,
            like_count: newLikeCount,
          };
        }
        return post;
      })
    );
    await handleLikePostAPI(postId, newUserLikedData, reaction);
    await setAnchorElForReaction(null); // Close the popover after selecting a reaction
  };

  const handlePopoverOpen = (event, postId) => {
    handlePopoverCloseForReplyLike();
    setAnchorElForReaction(event.currentTarget);
    setCurrentPostId(postId);
  };

  const handlePopoverClose = () => {
    setAnchorElForReaction(null);
  };

  const handlePopoverOpenForReplyLike = (event, postId, commentID) => {
    handlePopoverClose();
    setCurrentPostId(postId);
    setCurrentPostIdForReply(commentID);
    setAnchorElForReplyReaction(event.currentTarget);
  };

  const handlePopoverOpenForMentionUser = (event, id, commentID) => {
    handlePopoverClose();
    setAnchorElForPopupUser(event?.target);
    fetchMentionUserAPI(id);
  };

  const handlePopoverCloseForReplyLike = () => {
    setAnchorElForReplyReaction(null);
  };

  const handlePopoverCloseForMentionUser = () => {
    setAnchorElForPopupUser(null);
    setPopupUserDetails();
  };

  const toggleLikeForCommentsReaction = async (
    postId,
    reaction,
    commentId,
    isLiked
  ) => {
    let newUserLikedData;

    await setGetAllPost((prevPosts) =>
      prevPosts.map((post) => {
        if (post.id === postId) {
          return {
            ...post,
            comments: post?.comments?.map((comment) => {
              if (comment.id === commentId) {
                let newUserLiked = comment?.has_user_liked_comment;
                let newReaction = comment.reaction;
                let newLikeCount = comment.comment_likes_count;

                if (newUserLiked === "no") {
                  // No reaction was selected before, adding a new reaction
                  newUserLiked = "yes";
                  newReaction = reaction;
                  newLikeCount += 1; // Increase like count when adding a new reaction
                } else if (newReaction !== reaction && isLiked === false) {
                  // Removing current reaction
                  newUserLiked = "no";
                  newReaction = "";
                  newLikeCount -= 1;
                } else if (newReaction !== reaction && isLiked) {
                  // Switching to a different reaction
                  newReaction = reaction;
                  // Like count remains the same since only the reaction is changing
                } else {
                  // Removing the current reaction
                  newUserLiked = "no";
                  newReaction = "";
                  newLikeCount -= 1; // Decrease like count when removing the reaction
                }

                newUserLikedData = newUserLiked;

                return {
                  ...comment,
                  has_user_liked_comment: newUserLiked,
                  reaction: newReaction,
                  comment_likes_count: newLikeCount,
                };
              }
              return comment;
            }),
          };
        }
        return post;
      })
    );

    await handleLikePostForCommentsAPI(
      commentId,
      newUserLikedData,
      postId,
      reaction
    );
    await handlePopoverCloseForReplyLike();
  };

  const handleCommentToggle = (postId) => {
    setGetAllPost((prevPosts) =>
      prevPosts.map((post) =>
        post.id === postId
          ? {
            ...post,
            commentToggle: !post?.commentToggle,
          }
          : post
      )
    );
  };

  const handleCommentReplyToggle = (postId, commentId) => {
    setGetAllPost((prevPosts) =>
      prevPosts.map((post) =>
        post.id === postId
          ? {
            ...post,
            comments: post.comments.map((comment) =>
              comment.id === commentId
                ? {
                  ...comment,
                  commentReplyToggle: !comment.commentReplyToggle,
                }
                : comment
            ),
          }
          : post
      )
    );
  };

  const handleCommentText = (e, postId) => {
    const regex = /@(\w{2,})$/;
    if (!isSelectingSuggestion && regex.test(e)) {
      const cleanedText = e.match(regex)[1]; // Extract the matched text without @
      fetchMentionUsers(cleanedText);
    }

    setGetAllPost((prevPosts) =>
      prevPosts.map((post) =>
        post.id === postId
          ? {
            ...post,
            commentText: e,
          }
          : post
      )
    );

    setIsSelectingSuggestion(false); // Reset the flag after setting the text
  };

  const handleCommentReplyText = (e, postId, commentId) => {
    const regex = /@(\w{1,})$/;
    if (!isSelectingSuggestion && regex.test(e)) {
      const cleanedText = e.match(regex)[1]; // Extract the matched text without @
      fetchMentionUsers(cleanedText);
    }

    setGetAllPost((prevPosts) =>
      prevPosts.map((post) =>
        post.id === postId
          ? {
            ...post,
            comments: post.comments.map((comment) =>
              comment.id === commentId
                ? {
                  ...comment,
                  commentReplyText: e,
                }
                : comment
            ),
          }
          : post
      )
    );
    setIsSelectingSuggestion(false); // Reset the flag after setting the text
  };

  const handleAddComments = async (postId, data) => {
    await handleCommentToggle(postId);
    await handleCommentText("", postId);
    let newData = {
      id: data?.id,
      post_id: data?.post_id,
      user_id: data?.user_id,
      comment: data?.comment,
      is_parent: data?.is_parent,
      parent_id: data?.parent_id,
      created_at: data?.created_at,
      updated_at: data?.updated_at,
      replies_count: data?.replies_count,
      comment_likes_count: data?.comment_likes_count,
      has_user_liked_comment: data?.has_user_liked_comment,
      comment_date_time: data?.comment_date_time,
      reaction: data?.reaction,
      user_reactions: data?.user_reactions,
      user: {
        id: data?.user?.id,
        name: data?.user?.name,
        thumbnail: data?.user?.thumbnail,
      },
      replies: data?.replies,
      published_date_time: data?.comment_date_time,
    };
    await setGetAllPost((prevPosts) =>
      prevPosts.map((post) =>
        post.id === postId
          ? {
            ...post,
            comment_count: data?.comment_count,
            parent_comment_count: data?.parent_comment_count,
            comments: [newData, ...post?.comments],
          }
          : post
      )
    );
  };

  const handleAppendReplyComments = async (postId, commentId, replyContent) => {
    await handleCommentReplyToggle(postId, commentId);
    await handleCommentReplyText("", postId, commentId);
    await setGetAllPost((prevPosts) =>
      prevPosts.map((post) => {
        if (post.id === postId) {
          return {
            ...post,
            comment_count: post?.comment_count + 1,
            comments: post?.comments?.map((comment) => {
              if (comment.id === commentId) {
                const newReply = {
                  id: replyContent?.id, // Generate a unique ID for the reply
                  post_id: replyContent?.post_id,
                  user_id: replyContent?.user_id,
                  comment: replyContent?.comment,
                  is_parent: replyContent?.is_parent,
                  parent_id: replyContent?.parent_id,
                  created_at: replyContent?.created_at,
                  comment_date_time: replyContent?.comment_date_time,
                  user: {
                    id: replyContent?.user?.id,
                    name: replyContent?.user?.name,
                    thumbnail: replyContent?.user?.thumbnail,
                  },
                  published_date_time: replyContent?.comment_date_time,
                };
                return {
                  ...comment,
                  replies: [newReply, ...comment.replies],
                  replies_count: comment.replies_count + 1,
                };
              }
              return comment;
            }),
          };
        }
        return post;
      })
    );
  };

  const appendExtraComments = (postId, data, comment_page) => {
    setLoading(true);

    setGetAllPost((prevPosts) => {
      // Create a new array to ensure immutability
      return prevPosts.map((post) => {
        if (post.id === postId) {
          let updatedComments;
          if (post?.comment_page === 0) {
            updatedComments = data;
          } else {
            updatedComments = Array.isArray(data)
              ? [...post.comments, ...data]
              : [...post.comments, data];
          }
          return {
            ...post,
            comments: updatedComments,
            comment_page: comment_page,
          };
        }

        return post;
      });
    });

    setLoading(false);
  };

  const appendPostLikeData = (postId, data) => {
    console.log("Post Id", postId);
    console.log("Post data", data);
    setLoading(true);
    setGetAllPost((prevPosts) =>
      prevPosts.map((post) =>
        post.id === postId
          ? {
            ...post,
            like_count: data?.like_count,
            reaction: data?.reaction,
            reactions: data?.reactions,
            user_has_liked: data?.user_has_liked,
          }
          : post
      )
    );
    setLoading(false);
  };

  const appendCommentReplyPostLikeData = (postId, commentId, data) => {
    setLoading(true);
    setGetAllPost((prevPosts) =>
      prevPosts.map((post) =>
        post?.id === postId
          ? {
            ...post,
            comments: post?.comments?.map((comment) =>
              comment?.id === commentId
                ? {
                  ...comment,
                  comment_likes_count: data?.comment_likes_count,
                  has_user_liked_comment: data?.has_user_liked_comment,
                  reaction: data?.reaction,
                  user_reactions: data?.user_reactions,
                }
                : comment
            ),
          }
          : post
      )
    );
    setLoading(false);
  };

  const fetchAllTypePostReset = async () => {
    try {
      // setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(`user/post/feed-lists`, "", {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setGetAllPost(response?.response?.data);
      setPage(1);
      setTotalPages(response?.meta.last_page);
      if (page + 1 <= response?.meta.last_page) {
        setNextPage(true);
      }
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  };

  const fetchAllTypePost = async (page) => {
    setLoading(true);
    try {
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(
        `user/post/feed-lists`,
        { page },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const newPosts = response?.response?.data ?? []; // Fallback to an empty array if data is undefined or null

      if (!Array.isArray(newPosts)) {
        console.error("Expected newPosts to be an array, but got:", newPosts);
        return; // Exit early if newPosts is not an array
      }

      setGetAllPost((prevPosts) => {
        const uniquePosts = newPosts.filter(
          (newPost) => !prevPosts.some((post) => post?.id === newPost?.id)
        );
        return [...prevPosts, ...uniquePosts];
      });

      setTotalPages(response?.response?.meta?.last_page ?? 1); // Fallback to 1 if last_page is undefined
      setLoading(false);

      if (page + 1 <= (response?.response?.meta?.last_page ?? 1)) {
        setNextPage(true);
      }
    } catch (err) {
      console.log("error in fetch news api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
    } finally {
      setIsDataFetching(false); // Reset fetching state after the API call
      setLoading(false);
    }
  };

  const fetchMediaFolders = async (dataFor) => {
    try {
      setLoading(true);
      const response = await api.get(`user/media/browse/folders`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success) {
        // setFolderList(response?.response)
        setMyFolderList(response?.response?.my_media_folder);
        setSystemFolderList(response?.response?.system_media_folder);
        let media_id;
        if (dataFor === "image") {
          setSelectedMediaTypeForImage(
            selectedMediaTypeForImage === ""
              ? "my-media"
              : selectedMediaTypeForImage
          );
          media_id = selectedMediaFileForImage;
          setSelectedMediaFolderIDForImage(
            selectedMediaFolderIDForImage === ""
              ? 0
              : selectedMediaFolderIDForImage
          );
        } else if (dataFor === "doc") {
          setSelectedMediaTypeForDoc(
            selectedMediaTypeForDoc === ""
              ? "my-media"
              : selectedMediaTypeForDoc
          );
          media_id = selectedMediaFileForDoc;
          setSelectedMediaFolderIDForDoc(
            selectedMediaFolderIDFordoc === "" ? 0 : selectedMediaFolderIDFordoc
          );
        }
        fetchMediaFiles(pageForMedia, rowsPerPageForMedia, dataFor, media_id);
        setOpenMediaUpload(true);
        setLoading(false);
      } else if (response?.success === false) {
        setLoading(false);
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.");
      }
    }
  };

  const fetchMediaFiles = async (pageNo, perPage, dataFor, mediaFile) => {
    let type;
    if (dataFor === "image") {
      if (selectedMediaTypeForImage === "system") {
        type = "system";
      } else {
        type = "user";
      }
    } else if (dataFor === "doc") {
      if (selectedMediaTypeForDoc === "system") {
        type = "system";
      } else {
        type = "user";
      }
    }
    try {
      setLoading(true);
      const response = await api.post(
        `user/media/browse/files?page=${pageNo}`,
        {
          folder_id:
            dataFor === "image"
              ? selectedMediaFolderIDForImage
              : selectedMediaFolderIDFordoc,
          type: type,
          per_page: perPage,
          file_type: dataFor,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success) {
        setMediaListResponse(response?.response);
        setMediaList(response?.response?.data);
        if (dataFor === "image" && mediaFile !== "") {
          console.log("line 3928", mediaFile);
          setSelectedMediaFileForImage(mediaFile);
          setSelectedMediaFolderIDForImage(selectedMediaFolderIDForImage);
        } else if (dataFor === "doc" && selectedMediaFileForDoc !== "") {
          setSelectedMediaFileForDoc(selectedMediaFileForDoc);
          setSelectedMediaFolderIDForDoc(selectedMediaFolderIDFordoc);
        }
        setLoading(false);
      } else if (response?.success === false) {
        setLoading(false);
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.");
      }
    }
  };

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  const handleScroll = async () => {
    console.log("line 1651");
    const scrollableHeight = document.documentElement.scrollHeight;
    const scrollPosition = window.innerHeight + window.scrollY;
    const remainingScrollThreshold = scrollableHeight * 0.3;
    console.log("scrollableHeight::", scrollableHeight);
    console.log("scrollPosition::", scrollPosition);
    console.log("remainingScrollThreshold::", remainingScrollThreshold);

    if (
      scrollableHeight - scrollPosition < remainingScrollThreshold &&
      !isDataFetching &&
      page < totalPages
    ) {
      console.log("inside handle scroll if condition");
      setIsDataFetching(true);
      const nextPageNum = page + 1;
      setPage(nextPageNum);
      await fetchAllTypePost(nextPageNum);
    }
  };

  useEffect(() => {
    console.log("inside useEffect scroll");

    const debouncedHandleScroll = debounce(handleScroll, 200);

    window.addEventListener("scroll", debouncedHandleScroll);
    return () => window.removeEventListener("scroll", debouncedHandleScroll);
  }, [isDataFetching, page, totalPages]);

  const handleCloseForMediaModel = () => {
    setOpenFileUploadModelMedia(false);
    setMediaTitle("");
    setMediaAltText("");
    setMediaDescription("");
    setMediaDirectUrl("");
    setIsSystemTypeMedia(false);
    setSelectedFileMedia(null);
    setValueForMediaTab(0);
    setSelectedMedia("");
    setResponseForMedia();
    setIsEditMedia(false);
    setIsRenameMedia(false);
    setOpenRenameModel(false);
    setOpenMediaModelFor("");
  };

  const handleCheckboxChange = async (id) => {
    try {
      setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(
        `/user/post/acknowledge/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success) {
        Toast.success(response?.message);
        setLoading(false);
        handleAckCheckboxSuccess(id);
        // fetchAllTypePost();
      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in acknowledge user::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      Toast.error(err?.response?.data?.message);
    }
  };

  const handleAckCheckboxSuccess = async (postId) => {
    setGetAllPost((prevPosts) =>
      prevPosts.map((post) =>
        post.id === postId
          ? {
            ...post,
            acknowledged_on: getCurrentUTCDateTime(),
            acknowledged_date_time: getCurrentUTCDateTime(),
          }
          : post
      )
    );
  };

  const getCurrentUTCDateTime = () => {
    const today = new Date();
    const year = today.getUTCFullYear();
    const month = String(today.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(today.getUTCDate()).padStart(2, "0");
    const hours = String(today.getUTCHours()).padStart(2, "0");
    const minutes = String(today.getUTCMinutes()).padStart(2, "0");
    const seconds = String(today.getUTCSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleCheckboxChangeForce = async (id) => {
    try {
      setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(
        `/user/post/acknowledge/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success) {
        Toast.success(response?.message);
        setLoading(false);
        handleAckCheckboxSuccess(id);
        fetchAllTypePost();
        closePopupForAck();
        setModelPostsForAck([]);
        setCurrentPostIndexForAck(null);
        handleCloseForAckQueForce();
        setPostIdForAckQueForce();
        handleAcknowledgementPopup();
      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in acknowledge user::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      Toast.error(err?.response?.data?.message);
    }
  };

  const handleAckQuestionsAPI = async () => {
    let data = {
      answer: ackQuestions?.map((data) => {
        return {
          id: data?.id,
          answer: data?.ans,
        };
      }),
    };
    try {
      setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(
        `/user/post/acknowledge/answer/${postIdForAckQue}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success) {
        Toast.success(response?.message);
        setLoading(false);
        // fetchAllTypePostReset();
        handleAckCheckboxSuccess(postIdForAckQue);
        handleCloseForAckQue();
        setPostIdForAckQue();
      } else {
        setLoading(false);
        Toast.error(response?.message || response?.response);
      }
    } catch (err) {
      console.log("error in acknowledge user::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      Toast.error(err?.response?.data?.message);
    }
  };

  const handleAckQuestionsForceAPI = async () => {
    let data = {
      answer: ackQuestions?.map((data) => {
        return {
          id: data?.id,
          answer: data?.ans,
        };
      }),
    };
    try {
      setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(
        `/user/post/acknowledge/answer/${postIdForAckQueForce}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success) {
        Toast.success(response?.message);
        setLoading(false);
        fetchAllTypePostReset();
        closePopupForAck();
        setAckQuestions([]);
        setModelPostsForAck([]);
        setCurrentPostIndexForAck(null);
        setIsPopupOpenForAck(false);
        setOpenAckQuePopupForce(false);
        setPopupAckData();
        setPostIdForAckQueForce();
        handleAcknowledgementPopup();
      } else {
        setLoading(false);
        Toast.error(response?.message || response?.response);
      }
    } catch (err) {
      console.log("error in acknowledge user::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      Toast.error(err?.response?.data?.message);
    }
  };

  const handleLikePostAPI = async (id, newUserLiked, reaction) => {
    let data = {
      like: newUserLiked,
      reaction: reaction,
    };
    try {
      setLoading(false);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(`user/post/like/${id}`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success) {
        const responseData = response?.response?.data[0];
        if (fetchAll) {
          appendPostLikeData(id, responseData);
        } else {
          appendPostLikeData(id, responseData);
          // fetchAllTypePost();
        }
        setLoading(false);
        handlePopoverClose();
      } else {
        handlePopoverClose();
        setLoading(false);
        fetchAllTypePost();

        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);
        handlePopoverClose();

        Object.values(err?.response?.data?.errors).forEach((value) =>
          Toast.error(value)
        );
      }
    }
  };

  const handleLikePostForCommentsAPI = async (
    id,
    newUserLiked,
    post_id,
    reaction
  ) => {
    let data = {
      like: newUserLiked,
      post_id: post_id,
      reaction: reaction,
    };

    try {
      setLoading(false);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(`user/post/comment-like/${id}`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success) {
        if (fetchAll) {
          appendCommentReplyPostLikeData(
            post_id,
            id,
            response?.response?.data[0]
          );
        } else {
          appendCommentReplyPostLikeData(
            post_id,
            id,
            response?.response?.data[0]
          );
          // fetchAllTypePost();
        }
        setLoading(false);
      } else {
        setLoading(false);
        fetchAllTypePost();

        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);
        Object.values(err?.response?.data?.errors).forEach((value) =>
          Toast.error(value)
        );
      }
    }
  };

  const handleCommentsAPI = async (id, isParent) => {
    const postData = getAllPost?.filter((post) => post?.id === id);
    let data = {
      comment: postData[0]?.commentText,
      is_parent: isParent,
    };

    if (!postData[0]?.commentText || postData[0]?.commentText === "") {
      return Toast.error("Please enter at least one charactor!");
    }
    try {
      setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(`user/post/comment/${id}`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success) {
        if (fetchAll) {
          handleAddComments(id, response?.response?.data[0]);
        } else {
          handleAddComments(id, response?.response?.data[0]);
          // fetchAllTypePost();
        }
        setLoading(false);
      } else {
        setLoading(false);
        fetchAllTypePost();

        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);
        Object.values(err?.response?.data?.errors).forEach((value) =>
          Toast.error(value)
        );
      }
    }
  };

  const handleCommentsReplyAPI = async (id, isParent, commentId) => {
    const postData = getAllPost?.filter((post) => post?.id === id);
    let commentData;
    if (postData) {
      commentData = postData[0]?.comments?.find(
        (comment) => comment?.id === commentId
      );
    } else {
      console.log("Post not found");
    }

    let data = {
      comment: commentData?.commentReplyText,
      is_parent: isParent,
      parent_id: commentId,
    };

    if (
      !commentData?.commentReplyText ||
      commentData?.commentReplyText === ""
    ) {
      return Toast.error("Please enter at least one charactor!");
    }
    try {
      setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(`user/post/comment/${id}`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success) {
        if (fetchAll) {
          handleAppendReplyComments(
            postData[0]?.id,
            commentId,
            response?.response
          );
        } else {
          handleAppendReplyComments(
            postData[0]?.id,
            commentId,
            response?.response
          );
          // fetchAllTypePost();
        }
        setLoading(false);
      } else {
        setLoading(false);
        fetchAllTypePost();
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);
        Object.values(err?.response?.data?.errors).forEach((value) =>
          Toast.error(value)
        );
      }
    }
  };

  const fetchLoadMoreCommentsAPI = async (post) => {
    try {
      setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.get(
        `user/post/get-comment/${post?.id}?page=${post?.comment_page + 1}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success) {
        setLoading(false);
        const allComments = response?.response?.data;
        appendExtraComments(post?.id, allComments, post?.comment_page + 1);
      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);
        Object.values(err?.response?.data?.errors).forEach((value) =>
          Toast.error(value)
        );
      }
    }
  };

  const fetchMentionUserAPI = async (id) => {
    try {
      // setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.get(`user/mention/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success) {
        setLoading(false);
        setPopupUserDetails(response?.response);
        setIsPopupUserExist(true);
      } else {
        setLoading(false);
        setIsPopupUserExist(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);
        Object.values(err?.response?.data?.errors).forEach((value) =>
          Toast.error(value)
        );
      }
    }
  };

  const renderCommentText = (text) => {
    const mentionRegex = /@\[(.*?)\]\((.*?)\)/g;
    const parts = text.split(mentionRegex);

    return parts.map((part, index) => {
      if (index % 3 === 1) {
        // Mention text
        const mentionId = parts[index + 1];
        return (
          <span
            key={index}
            className="fs-14-bold cursor-pointer"
            id={`${mentionId} `}
            aria-describedby={idPopupUser}
            onMouseEnter={(e) => {
              const id = setTimeout(() => {
                handlePopoverOpenForMentionUser(e, mentionId);
              }, 1000);
              setTimeoutId(id);
            }}
            onMouseLeave={() => {
              if (timeoutId) {
                clearTimeout(timeoutId);
                setTimeoutId(null);
                // handlePopoverCloseForMentionUser()
              }
            }}
          >
            {part}
          </span>
        );
      } else if (index % 3 === 2) {
        // Mention identifier (we skip it)
        return null;
      } else {
        // Normal text
        return (
          <span key={index} className="fs-14">
            {part}
          </span>
        );
      }
    });
  };

  const fetchMentionUsers = async (text) => {
    try {
      setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.get(
        `user/suggestion/user-list?search=${text}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success) {
        setLoading(false);

        const allUsers = response?.response?.map((data) => {
          return {
            id: data?.id,
            display: `${data?.name} ${data?.last_name}`,
            photo: data?.thumbnail,
            job_title: data?.job_title,
            full_name: data?.full_name,
          };
        });
        setGetMentionUsers(allUsers);
      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);
        Object.values(err?.response?.data?.errors).forEach((value) =>
          Toast.error(value)
        );
      }
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const paste = (event.clipboardData || window.clipboardData).getData("text");

    // Optionally, you can process the pasted text here if needed.
    const sanitizedText = paste.replace(/[@]/g, "@ "); // Example: add a space after @

    document.execCommand("insertText", false, sanitizedText);
  };

  const closePopup = async (postId) => {
    setIsPopupOpen(false);
    setCurrentPostIndex((prevIndex) => prevIndex + 1);
    const userToken = localStorage.getItem("accessToken");
    try {
      // setLoading(true);
      await api.post(
        `user/post/highlight-dismis/${postId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  };

  const closePopupForAck = async (postId) => {
    setIsPopupOpenForAck(false);
  };

  const CustomPopup = ({ post, onClose }) => {
    return (
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className="highlight-model-title-div"
        >
          <Avatar
            aria-label="recipe"
            className="author-avtar-creator"
            src={post?.created_by?.thumbnail}
          >
            {post?.created_by?.name?.charAt(0)}
          </Avatar>
          <Typography className="text-center">
            <span>
              <b>{post?.created_by?.name}</b> posted a <b>{post?.type?.name}</b>
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className="no-border">
          <Box className="body-content-scroll">
            <CardMedia sx={{ height: 222 }} image={post?.image} />
            <div className="main-content-div-1 mt-3">
              <div className="main-div-posted">
                <div className="icon-bd-div">
                  <FontAwesomeIcon icon={faClock} />
                </div>
                <Typography className="fs-14 opacity-50">
                  {` Posted ${onlyDateFormat(
                    post?.published_date,
                    false,
                    true,
                    true
                  )}`}
                </Typography>
              </div>
            </div>
            <Box>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="content-title text-break"
              >
                {post?.title}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                className="fs-14 content-desc text-break"
              >
                {post?.content}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    );
  };

  const CustomPopupForAck = ({ post, onClose }) => {
    return (
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={true}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className="highlight-model-title-div"
        >
          <Avatar
            aria-label="recipe"
            className="author-avtar-creator"
            src={post?.created_by?.thumbnail}
          >
            {post?.created_by?.name?.charAt(0)}
          </Avatar>
          <Typography className="text-center">
            <span>
              <b>{post?.created_by?.name}</b> posted a <b>{post?.type?.name}</b>
            </span>
          </Typography>
        </DialogTitle>
        <DialogContent dividers className="no-border">
          <Box className="body-content-scroll">
            <CardMedia sx={{ height: 222 }} image={post?.image} />
            <div className="main-content-div-1 mt-3">
              <div className="main-div-posted">
                <div className="icon-bd-div">
                  <FontAwesomeIcon icon={faClock} />
                </div>
                <Typography className="fs-14 opacity-50">
                  {` Posted ${onlyDateFormat(
                    post?.published_date,
                    false,
                    true,
                    true
                  )}`}
                </Typography>
              </div>
            </div>
            <Box>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="content-title text-break"
              >
                {post?.title}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                className="fs-14 content-desc text-break"
              >
                {post?.content}
              </Typography>
            </Box>
          </Box>
          <Box
            className={`custom-checkbox mt-3 ${post?.acknowledged_on !== "" && "active-checkbox"
              }`}
          >
            <label className="checkbox-style">
              {post?.acknowledged_on !== ""
                ? `You acknowledged this on ${formatDateToReadable(
                  post?.acknowledged_date_time
                )}`
                : post?.acknowledge_data &&
                  typeof post?.acknowledge_data === "string"
                  ? `${post?.acknowledge_data}`
                  : `This requires your acknowledgement`}
              <input
                type="checkbox"
                checked={post?.acknowledged_on !== ""}
                onChange={() => {
                  if (post?.acknowledge_type === "question") {
                    setPopupAckData(post);

                    setAckQuestions(post?.acknowledge_data);
                    setPostIdForAckQueForce(post?.id);
                    onClose();
                    setOpenAckQuePopupForce(true);
                  } else if (post?.acknowledge_type === "checkbox") {
                    handleCheckboxChangeForce(post?.id);
                  }
                }}
              />
              <span className="checkmark"></span>
            </label>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    );
  };

  const callAPI = async (endpoint, intervalName) => {
    try {
      const userToken = localStorage.getItem("accessToken");
      const response = await api.get(endpoint, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        localStorage.setItem(
          `lastCallTime_${intervalName}`,
          new Date().toISOString()
        );
        setModelPosts(response?.response?.data);
      }
      setLoading(false);
    } catch (err) {
      console.log(`error in fetch news api:: ${intervalName}`, err);
      if (err?.response?.status === 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  };

  const handleAcknowledgementPopup = async () => {
    try {
      // setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.get(`user/post/acknowledge/popup`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        setModelPostsForAck(response?.response);
      }
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  };

  const validateMedia = () => {
    let isValid = true;

    if (selectedFileMedia === null) {
      Toast.error("Please upload a media file");
      isValid = false;
    }
    return isValid;
  };

  const fetchMediaDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(`user/media/file/edit/${selectedMedia}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success) {
        const responseData = response?.response;
        setLoading(false);
        setMediaTitle(responseData?.title);
        setMediaAltText(responseData?.alt_text);
        setMediaDescription(responseData?.description);
        const permissions = responseData?.permission;
        setIsSystemTypeMedia(responseData?.is_system === 0 ? false : true);
        setResponseForMedia(responseData);
        const finalPermissions = permissions?.map((data) => {
          return {
            label: data?.label,
            value: data?.user_group_id,
            view: data?.can_view === 0 ? false : true,
            deny: data?.can_deny === 0 ? false : true,
            edit: data?.can_edit === 0 ? false : true,
            delete: data?.can_delete === 0 ? false : true,
            redacted: data?.can_resource === 0 ? false : true,
            type: data?.type,
          };
        });
        const fileExtension = responseData?.file_path
          ?.split(".")
          .pop()
          .toLowerCase();
        const mimeType = getMimeType(fileExtension); // You can create a helper function to get mime type from file extension
        setMediaDirectUrl(responseData?.file_path);
        setPermissionsForMedia(finalPermissions);
        setIsAdvancePermissionForMedia(
          responseData?.advance_permission === 0 ? false : true
        );
        setSelectedFileMedia({
          url: responseData?.file_path,
          type: mimeType,
          name: responseData?.title || "Uploaded File",
        });
        setOpenFileUploadModelMedia(true);
        Toast.success(response?.message);
      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);
        Object.values(err?.response?.data?.errors).forEach((value) =>
          Toast.error(value)
        );
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.");
      }
    }
  };

  const fetchMediaDetailsForRename = async () => {
    try {
      setLoading(true);
      const response = await api.get(`user/media/file/edit/${selectedMedia}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success) {
        const responseData = response?.response;
        setLoading(false);
        setMediaTitle(responseData?.title);
        setMediaAltText(responseData?.alt_text);
        setMediaDescription(responseData?.description);
        const permissions = responseData?.permission;
        setIsSystemTypeMedia(responseData?.is_system === 0 ? false : true);
        setResponseForMedia(responseData);
        const finalPermissions = permissions?.map((data) => {
          return {
            label: data?.label,
            value: data?.user_group_id,
            view: data?.can_view === 0 ? false : true,
            deny: data?.can_deny === 0 ? false : true,
            edit: data?.can_edit === 0 ? false : true,
            delete: data?.can_delete === 0 ? false : true,
            redacted: data?.can_resource === 0 ? false : true,
            type: data?.type,
          };
        });
        const fileExtension = responseData?.file_path
          ?.split(".")
          .pop()
          .toLowerCase();
        const mimeType = getMimeType(fileExtension); // You can create a helper function to get mime type from file extension
        setMediaDirectUrl(responseData?.file_path);
        setPermissionsForMedia(finalPermissions);
        setIsAdvancePermissionForMedia(
          responseData?.advance_permission === 0 ? false : true
        );
        setSelectedFileMedia({
          url: responseData?.file_path,
          type: mimeType,
          name: responseData?.title || "Uploaded File",
        });
        setOpenRenameModel(true);
        Toast.success(response?.message);
      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);
        Object.values(err?.response?.data?.errors).forEach((value) =>
          Toast.error(value)
        );
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.");
      }
    }
  };

  const storeMedia = async () => {
    const validate = await validateMedia();

    if (validate) {
      const formData = new FormData();
      let permissionData;
      formData.append("is_system", isSystemTypeMedia ? 1 : 0);
      formData.append("title", mediaTitle);
      formData.append("alt_text", mediaAltText);
      formData.append("description", mediaDescription);
      formData.append("advance_permission", 0);

      if (isAdvancePermissionForMedia) {
        permissionData = permissionsForMedia?.map((data) => {
          return {
            user_group_id: data?.value,
            type: data?.type,
            can_view: 0,
            can_deny: 0,
            can_edit: 0,
            can_delete: 0,
            can_resource: 0,
            label: data?.label,
          };
        });
      } else {
        permissionData = permissionsForMedia?.map((data) => {
          return {
            user_group_id: data?.value,
            type: data?.type,
            can_view: 0,
            can_deny: 0,
            can_edit: 0,
            can_delete: 0,
            can_resource: 0,
            label: data?.label,
          };
        });
      }
      if (parentFolderId) {
        formData.append("folder_id", parentFolderId);
      }

      if (isEditMedia) {
        formData.append("direct_url", responseForMedia?.file_path);
      }

      formData.append("permission", JSON?.stringify(permissionData));

      if (
        selectedFileMedia?.file instanceof Blob ||
        selectedFileMedia?.file instanceof File
      ) {
        formData.append("file", selectedFileMedia?.file);
      }

      if (isEditMedia) {
        try {
          setLoading(true);
          const response = await api.post(
            `user/media/file/update/${selectedMedia}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
          if (response?.success) {
            setLoading(false);
            let media_id;
            if (mediaDataFor === "image") {
              setSelectedMediaFileForImage(response?.response?.id);
              media_id = response?.response?.id;
              setSelectedMediaFileDetailsForImage(response?.response);
              // setSelectedFileMedia(response?.response?.id)
            } else if (mediaDataFor === "doc") {
              setSelectedMediaFileForDoc(response?.response?.id);
              media_id = response?.response?.id;
              setSelectedMediaFileDetailsForDoc(response?.response);
              // setSelectedFileMedia(response?.response?.id)
            }

            await fetchMediaFiles(
              pageForMedia,
              rowsPerPageForMedia,
              mediaDataFor,
              media_id
            );
            Toast.success(response?.message);
            handleCloseForMediaModel();
          } else {
            setLoading(false);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("error in ctreate news api::", err);
          setLoading(false);

          if (err?.response?.status === 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status === 422) {
            setLoading(false);
            Object.values(err?.response?.data?.errors).forEach((value) =>
              Toast.error(value)
            );
          }
          if (err?.response?.status === 429) {
            Toast.error("Too many attempts wait for 2 min.");
          }
        }
      } else {
        try {
          setLoading(true);
          const response = await api.post(`user/media/file/store`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userToken}`,
            },
          });
          if (response?.success) {
            setLoading(false);

            Toast.success(response?.message);
            let media_id;
            if (mediaDataFor === "image") {
              console.log("2282", response?.response?.id);
              setSelectedMediaFileForImage(response?.response?.id);
              media_id = response?.response?.id;
              setSelectedMediaFileDetailsForImage(response?.response);
            } else if (mediaDataFor === "doc") {
              setSelectedMediaFileForDoc(response?.response?.id);
              media_id = response?.response?.id;
              setSelectedMediaFileDetailsForDoc(response?.response);
            }

            fetchMediaFiles(
              pageForMedia,
              rowsPerPageForMedia,
              mediaDataFor,
              media_id
            );
            handleCloseForMediaModel();
          } else {
            setLoading(false);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("error in ctreate news api::", err);
          setLoading(false);

          if (err?.response?.status === 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status === 422) {
            setLoading(false);
            Object.values(err?.response?.data?.errors).forEach((value) =>
              Toast.error(value)
            );
          }
          if (err?.response?.status === 429) {
            Toast.error("Too many attempts wait for 2 min.");
          }
        }
      }
    }
  };

  const deleteMedia = async (id) => {
    let mediID;
    if (mediaDataFor === "image") {
      mediID = selectedMediaFileForImage;
    } else if (mediaDataFor === "doc") {
      mediID = selectedMediaFileForDoc;
    }
    try {
      setLoading(true);
      const response = await api.post(
        `user/media/file/delete/${mediID}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success) {
        setLoading(false);

        Toast.success(response?.message);
        let media_id;
        if (mediaDataFor === "image") {
          setSelectedMediaFileForImage("");
          media_id = "";
          setSelectedMediaFileDetailsForImage();
        } else if (mediaDataFor === "doc") {
          setSelectedMediaFileForDoc("");
          media_id = "";
          setSelectedMediaFileDetailsForDoc();
        }

        await fetchMediaFiles(
          pageForMedia,
          rowsPerPageForMedia,
          mediaDataFor,
          media_id
        );
        handleCloseForMediaModel();
      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.");
      }
    }
  };

  const fetchGroupsAndUsersList = async () => {
    try {
      const response = await api.get(`/group-users-list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success) {
        let acknowledgementQuestionsArray = [];

        const users = response?.response?.users;
        const groups = response?.response?.groups;

        const categories = response?.response?.category;

        const finalGroups = groups?.map((data) => {
          return {
            label: data?.label,
            value: data?.value,
            view: false,
            deny: false,
            edit: false,
            delete: false,
            redacted: false,
            type: data?.type,
          };
        });
        const finalUsers = users?.map((data) => {
          return {
            label: data?.label,
            value: data?.value,
            view: false,
            deny: false,
            edit: false,
            delete: false,
            redacted: false,
            type: data?.type,
          };
        });

        const allGroupsAndUsersData = [
          { label: "Users", options: finalUsers },
          { label: "Groups", options: finalGroups },
        ];
        // Find the "all_user" option
        const allUserOption = finalUsers.find(
          (user) => user.value === "all_users"
        );

        // Preselect "all_user"
        if (allUserOption) {
          setPermissionsForMedia([allUserOption]);
        }
        setLoading(false);
      } else if (response?.success === false) {
        setLoading(false);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        Toast.warning(err?.response?.message);
        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        Toast.warning(err?.response?.data[0]?.message);
        navigate("/");
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.");
      }
    }
  };

  const deleteTypePostAPI = async (id) => {
    try {
      setLoading(true);
      const response = await api.delete(`user/post/delete/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success) {
        // if (getAllTypePosts?.length < 2) {
        //     setPage(page - 1)
        //     fetchAllPostList(page - 1)
        // } else {
        //     setPage(page)
        //     fetchAllPostList(page)
        // }

        // handleCloseDeleteAlertModel()
        const updatedPosts = getAllPost?.filter((data) => data?.id !== id);
        setGetAllPost(updatedPosts);

        Toast.success(response?.message);
        setLoading(false);
        handleCloseDeleteAlertModel();
      } else if (response?.success == false) {
        Toast.warning(response?.message);
        setLoading(false);
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attempts wait for 2 min.");
      }
      setLoading(false);
    }
  };

  const handleHighlightModelEveryMinute = () =>
    callAPI("user/post/highlight-post/min", "minute");
  const handleHighlightModelEveryHour = () =>
    callAPI("user/post/highlight-post/hour", "hour");
  const handleHighlightModelEveryDay = () =>
    callAPI("user/post/highlight-post/day", "day");
  const handleHighlightModelEveryWeek = () =>
    callAPI("user/post/highlight-post/week", "week");

  const startTimers = () => {
    const loginTime = new Date(localStorage.getItem("loginTime"));

    const minuteInterval = setInterval(() => {
      const lastCallTime = new Date(
        localStorage.getItem("lastCallTime_minute") || loginTime
      );
      if (new Date() - lastCallTime >= 60000) {
        handleHighlightModelEveryMinute();
      }
    }, 60000);

    const hourInterval = setInterval(() => {
      const lastCallTime = new Date(
        localStorage.getItem("lastCallTime_hour") || loginTime
      );
      if (new Date() - lastCallTime >= 3600000) {
        handleHighlightModelEveryHour();
      }
    }, 3600000);

    const dayInterval = setInterval(() => {
      const lastCallTime = new Date(
        localStorage.getItem("lastCallTime_day") || loginTime
      );
      if (new Date() - lastCallTime >= 86400000) {
        handleHighlightModelEveryDay();
      }
    }, 86400000);

    const weekInterval = setInterval(() => {
      const lastCallTime = new Date(
        localStorage.getItem("lastCallTime_week") || loginTime
      );
      if (new Date() - lastCallTime >= 604800000) {
        handleHighlightModelEveryWeek();
      }
    }, 604800000);

    return () => {
      clearInterval(minuteInterval);
      clearInterval(hourInterval);
      clearInterval(dayInterval);
      clearInterval(weekInterval);
    };
  };

  useEffect(() => {
    if (currentPostIndex !== null && currentPostIndex < modelPosts?.length) {
      setIsPopupOpen(true);
    }
  }, [currentPostIndex]);

  useEffect(() => {
    if (modelPosts?.length > 0) {
      setCurrentPostIndex(0);
    }
  }, [modelPosts]);

  useEffect(() => {
    if (
      currentPostIndexForAck !== null &&
      currentPostIndexForAck < modelPostsForAck?.length
    ) {
      setIsPopupOpenForAck(true);
    }
  }, [currentPostIndexForAck]);

  useEffect(() => {
    if (modelPostsForAck?.length > 0) {
      setCurrentPostIndexForAck(0);
    }
  }, [modelPostsForAck]);

  useEffect(() => {
    setLoading(true);
    fetchAllTypePost();
    fetchGroupsAndUsersList();
    handleAcknowledgementPopup();

    if (location?.pathname === "/dashboard") {
      const clearTimers = startTimers();
      return () => clearTimers();
    }
  }, [location.pathname]);

  const setElementsCallback = useCallback(
    (el) => {
      if (el && !elements.includes(el)) {
        setElements((prev) => [...prev, el]);
      }
    },
    [elements]
  );

  useEffect(() => {
    if (
      selectedMediaFolderIDForImage !== "" &&
      selectedMediaTypeForImage !== ""
    ) {
      fetchMediaFiles(
        pageForMedia,
        rowsPerPageForMedia,
        mediaDataFor,
        selectedMediaFileForImage
      );
    }
  }, [selectedMediaFolderIDForImage, selectedMediaTypeForImage]);

  useEffect(() => {
    if (selectedMediaFolderIDFordoc !== "" && selectedMediaTypeForDoc !== "") {
      fetchMediaFiles(
        pageForMedia,
        rowsPerPageForMedia,
        mediaDataFor,
        selectedMediaFileForDoc
      );
    }
  }, [selectedMediaFolderIDFordoc, selectedMediaTypeForDoc]);

  useEffect(() => {
    if (selectedMedia !== "" && isEditMedia) {
      fetchMediaDetails();
    }
  }, [selectedMedia]);

  useEffect(() => {
    if (selectedMedia !== "" && isRenameMedia) {
      fetchMediaDetailsForRename();
    }
  }, [selectedMedia]);

  return (
    <>
      <div className="bg-img-dashboard"></div>
      <div
        className={`main-content-wrapper-3 ${open ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          <Row className="mt-1 justify-content-center">
            <Col lg="8" md="7">
              <Card className="update-type-post-card">
                <CardContent className="update-type-post-card-content">
                  <Box>
                    <Box>
                      <span className="dashboard-title">
                        Welcome to platform, <b>{fullNameForUpdatePost}</b>
                      </span>
                    </Box>
                    <Box className="mt-3 d-flex justify-content-center align-items-center gap-2">
                      <Box>
                        <Avatar
                          aria-label="recipe"
                          className="update-avtar-creator"
                          src={loggedUser?.thumbnail}
                        >
                          {fullNameForUpdatePost?.charAt(0)}
                        </Avatar>
                      </Box>
                      <Box className="w-100">
                        <Form.Control
                          placeholder="Post an update"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          className="search-input w-100"
                          onClick={handleClickOpen}
                        />
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Card>

              <Paper sx={{ width: "100%" }} className="type-list-paper">
                {(unseenFeedPosts || [])?.map((post, index) => (
                  <>
                    {post?.type?.name === "Update" ? (
                      <UpdatePostCard
                        key={post?.id}
                        post={post}
                        setElementsCallback={setElementsCallback}
                        openMenuForPost={openMenuForPost}
                        handleClickForMenuPost={handleClickForMenuPost}
                        renderUpdateContent={renderUpdateContent}
                        setOpenImageModel={setOpenImageModel}
                        setSinglePostData={setSinglePostData}
                        setSelectedImageIndex={setSelectedImageIndex}
                        setAckQuestions={setAckQuestions}
                        setPostIdForAckQue={setPostIdForAckQue}
                        setOpenAckQuePopup={setOpenAckQuePopup}
                        handleCheckboxChange={handleCheckboxChange}
                        handlePopoverClose={handlePopoverClose}
                        handlePopoverCloseForReplyLike={
                          handlePopoverCloseForReplyLike
                        }
                        handleEngagementModalOpen={handleEngagementModalOpen}
                        toggleReaction={toggleReaction}
                        handlePopoverOpen={handlePopoverOpen}
                        handleCommentToggle={handleCommentToggle}
                        handleCommentText={handleCommentText}
                        handleCommentsAPI={handleCommentsAPI}
                        handlePaste={handlePaste}
                        getMentionUsers={getMentionUsers}
                        setIsSelectingSuggestion={setIsSelectingSuggestion}
                        renderCommentText={renderCommentText}
                        toggleLikeForCommentsReaction={
                          toggleLikeForCommentsReaction
                        }
                        setCurrentPostId={setCurrentPostId}
                        setCurrentPostIdForReply={setCurrentPostIdForReply}
                        handlePopoverOpenForReplyLike={
                          handlePopoverOpenForReplyLike
                        }
                        handleCommentReplyToggle={handleCommentReplyToggle}
                        handleCommentReplyText={handleCommentReplyText}
                        handleCommentsReplyAPI={handleCommentsReplyAPI}
                        setFetchAll={setFetchAll}
                        fetchLoadMoreCommentsAPI={fetchLoadMoreCommentsAPI}
                      />
                    ) : post?.type?.name === "Insight" ? (
                      <InsightPostCard
                        key={post?.id}
                        fetchLoadMoreCommentsAPI={fetchLoadMoreCommentsAPI}
                        getMentionUsers={getMentionUsers}
                        handleCommentReplyText={handleCommentReplyText}
                        handleCommentReplyToggle={handleCommentReplyToggle}
                        handleCommentText={handleCommentText}
                        handleCommentToggle={handleCommentToggle}
                        handleCommentsAPI={handleCommentsAPI}
                        handleCommentsReplyAPI={handleCommentsReplyAPI}
                        handleEngagementModalOpen={handleEngagementModalOpen}
                        handlePaste={handlePaste}
                        handlePopoverClose={handlePopoverClose}
                        handlePopoverCloseForReplyLike={
                          handlePopoverCloseForReplyLike
                        }
                        handlePopoverOpen={handlePopoverOpen}
                        handlePopoverOpenForReplyLike={
                          handlePopoverOpenForReplyLike
                        }
                        post={post}
                        renderCommentText={renderCommentText}
                        setCurrentPostId={setCurrentPostId}
                        setCurrentPostIdForReply={setCurrentPostIdForReply}
                        setElementsCallback={setElementsCallback}
                        setFetchAll={setFetchAll}
                        setIsSelectingSuggestion={setIsSelectingSuggestion}
                        setOpenUserImageModel={setOpenUserImageModel}
                        setSinglePostData={setSinglePostData}
                        toggleLikeForCommentsReaction={
                          toggleLikeForCommentsReaction
                        }
                        toggleReaction={toggleReaction}
                      />
                    ) : (
                      <PostCard
                        fetchLoadMoreCommentsAPI={fetchLoadMoreCommentsAPI}
                        getMentionUsers={getMentionUsers}
                        handleCheckboxChange={handleCheckboxChange}
                        handleClickForMenuPost={handleClickForMenuPost}
                        handleCommentReplyText={handleCommentReplyText}
                        handleCommentReplyToggle={handleCommentReplyToggle}
                        handleCommentText={handleCommentText}
                        handleCommentToggle={handleCommentToggle}
                        handleCommentsAPI={handleCommentsAPI}
                        handleCommentsReplyAPI={handleCommentsReplyAPI}
                        handleEngagementModalOpen={handleEngagementModalOpen}
                        handlePaste={handlePaste}
                        handlePopoverClose={handlePopoverClose}
                        handlePopoverCloseForReplyLike={
                          handlePopoverCloseForReplyLike
                        }
                        handlePopoverOpen={handlePopoverOpen}
                        handlePopoverOpenForReplyLike={
                          handlePopoverOpenForReplyLike
                        }
                        openMenuForPost={openMenuForPost}
                        post={post}
                        renderCommentText={renderCommentText}
                        setAckQuestions={setAckQuestions}
                        setCurrentPostId={setCurrentPostId}
                        setCurrentPostIdForReply={setCurrentPostIdForReply}
                        setFetchAll={setFetchAll}
                        setIsSelectingSuggestion={setIsSelectingSuggestion}
                        setOpenAckQuePopup={setOpenAckQuePopup}
                        setPostIdForAckQue={setPostIdForAckQue}
                        toggleLikeForCommentsReaction={
                          toggleLikeForCommentsReaction
                        }
                        toggleReaction={toggleReaction}
                        key={post?.id}
                      />
                    )}
                  </>
                ))}

                {(seenFeedPosts || [])?.map((post, index) => (
                  <>
                    {post?.type?.name === "Update" ? (
                      <UpdatePostCard
                        key={post?.id}
                        post={post}
                        setElementsCallback={setElementsCallback}
                        openMenuForPost={openMenuForPost}
                        handleClickForMenuPost={handleClickForMenuPost}
                        renderUpdateContent={renderUpdateContent}
                        setOpenImageModel={setOpenImageModel}
                        setSinglePostData={setSinglePostData}
                        setSelectedImageIndex={setSelectedImageIndex}
                        setAckQuestions={setAckQuestions}
                        setPostIdForAckQue={setPostIdForAckQue}
                        setOpenAckQuePopup={setOpenAckQuePopup}
                        handleCheckboxChange={handleCheckboxChange}
                        handlePopoverClose={handlePopoverClose}
                        handlePopoverCloseForReplyLike={
                          handlePopoverCloseForReplyLike
                        }
                        handleEngagementModalOpen={handleEngagementModalOpen}
                        toggleReaction={toggleReaction}
                        handlePopoverOpen={handlePopoverOpen}
                        handleCommentToggle={handleCommentToggle}
                        handleCommentText={handleCommentText}
                        handleCommentsAPI={handleCommentsAPI}
                        handlePaste={handlePaste}
                        getMentionUsers={getMentionUsers}
                        setIsSelectingSuggestion={setIsSelectingSuggestion}
                        renderCommentText={renderCommentText}
                        toggleLikeForCommentsReaction={
                          toggleLikeForCommentsReaction
                        }
                        setCurrentPostId={setCurrentPostId}
                        setCurrentPostIdForReply={setCurrentPostIdForReply}
                        handlePopoverOpenForReplyLike={
                          handlePopoverOpenForReplyLike
                        }
                        handleCommentReplyToggle={handleCommentReplyToggle}
                        handleCommentReplyText={handleCommentReplyText}
                        handleCommentsReplyAPI={handleCommentsReplyAPI}
                        setFetchAll={setFetchAll}
                        fetchLoadMoreCommentsAPI={fetchLoadMoreCommentsAPI}
                      />
                    ) : post?.type?.name === "Insight" ? (
                      <InsightPostCard
                        key={post?.id}
                        fetchLoadMoreCommentsAPI={fetchLoadMoreCommentsAPI}
                        getMentionUsers={getMentionUsers}
                        handleCommentReplyText={handleCommentReplyText}
                        handleCommentReplyToggle={handleCommentReplyToggle}
                        handleCommentText={handleCommentText}
                        handleCommentToggle={handleCommentToggle}
                        handleCommentsAPI={handleCommentsAPI}
                        handleCommentsReplyAPI={handleCommentsReplyAPI}
                        handleEngagementModalOpen={handleEngagementModalOpen}
                        handlePaste={handlePaste}
                        handlePopoverClose={handlePopoverClose}
                        handlePopoverCloseForReplyLike={
                          handlePopoverCloseForReplyLike
                        }
                        handlePopoverOpen={handlePopoverOpen}
                        handlePopoverOpenForReplyLike={
                          handlePopoverOpenForReplyLike
                        }
                        post={post}
                        renderCommentText={renderCommentText}
                        setCurrentPostId={setCurrentPostId}
                        setCurrentPostIdForReply={setCurrentPostIdForReply}
                        setElementsCallback={setElementsCallback}
                        setFetchAll={setFetchAll}
                        setIsSelectingSuggestion={setIsSelectingSuggestion}
                        setOpenUserImageModel={setOpenUserImageModel}
                        setSinglePostData={setSinglePostData}
                        toggleLikeForCommentsReaction={
                          toggleLikeForCommentsReaction
                        }
                        toggleReaction={toggleReaction}
                      />
                    ) : (
                      <PostCard
                        fetchLoadMoreCommentsAPI={fetchLoadMoreCommentsAPI}
                        getMentionUsers={getMentionUsers}
                        handleCheckboxChange={handleCheckboxChange}
                        handleClickForMenuPost={handleClickForMenuPost}
                        handleCommentReplyText={handleCommentReplyText}
                        handleCommentReplyToggle={handleCommentReplyToggle}
                        handleCommentText={handleCommentText}
                        handleCommentToggle={handleCommentToggle}
                        handleCommentsAPI={handleCommentsAPI}
                        handleCommentsReplyAPI={handleCommentsReplyAPI}
                        handleEngagementModalOpen={handleEngagementModalOpen}
                        handlePaste={handlePaste}
                        handlePopoverClose={handlePopoverClose}
                        handlePopoverCloseForReplyLike={
                          handlePopoverCloseForReplyLike
                        }
                        handlePopoverOpen={handlePopoverOpen}
                        handlePopoverOpenForReplyLike={
                          handlePopoverOpenForReplyLike
                        }
                        openMenuForPost={openMenuForPost}
                        post={post}
                        renderCommentText={renderCommentText}
                        setAckQuestions={setAckQuestions}
                        setCurrentPostId={setCurrentPostId}
                        setCurrentPostIdForReply={setCurrentPostIdForReply}
                        setFetchAll={setFetchAll}
                        setIsSelectingSuggestion={setIsSelectingSuggestion}
                        setOpenAckQuePopup={setOpenAckQuePopup}
                        setPostIdForAckQue={setPostIdForAckQue}
                        toggleLikeForCommentsReaction={
                          toggleLikeForCommentsReaction
                        }
                        toggleReaction={toggleReaction}
                        key={post?.id}
                      />
                    )}
                  </>
                ))}

                {(unseenPosts || [])?.map((post, index) => (
                  <>
                    {post?.type?.name === "Update" ? (
                      <UpdatePostCard
                        key={post?.id}
                        post={post}
                        setElementsCallback={setElementsCallback}
                        openMenuForPost={openMenuForPost}
                        handleClickForMenuPost={handleClickForMenuPost}
                        renderUpdateContent={renderUpdateContent}
                        setOpenImageModel={setOpenImageModel}
                        setSinglePostData={setSinglePostData}
                        setSelectedImageIndex={setSelectedImageIndex}
                        setAckQuestions={setAckQuestions}
                        setPostIdForAckQue={setPostIdForAckQue}
                        setOpenAckQuePopup={setOpenAckQuePopup}
                        handleCheckboxChange={handleCheckboxChange}
                        handlePopoverClose={handlePopoverClose}
                        handlePopoverCloseForReplyLike={
                          handlePopoverCloseForReplyLike
                        }
                        handleEngagementModalOpen={handleEngagementModalOpen}
                        toggleReaction={toggleReaction}
                        handlePopoverOpen={handlePopoverOpen}
                        handleCommentToggle={handleCommentToggle}
                        handleCommentText={handleCommentText}
                        handleCommentsAPI={handleCommentsAPI}
                        handlePaste={handlePaste}
                        getMentionUsers={getMentionUsers}
                        setIsSelectingSuggestion={setIsSelectingSuggestion}
                        renderCommentText={renderCommentText}
                        toggleLikeForCommentsReaction={
                          toggleLikeForCommentsReaction
                        }
                        setCurrentPostId={setCurrentPostId}
                        setCurrentPostIdForReply={setCurrentPostIdForReply}
                        handlePopoverOpenForReplyLike={
                          handlePopoverOpenForReplyLike
                        }
                        handleCommentReplyToggle={handleCommentReplyToggle}
                        handleCommentReplyText={handleCommentReplyText}
                        handleCommentsReplyAPI={handleCommentsReplyAPI}
                        setFetchAll={setFetchAll}
                        fetchLoadMoreCommentsAPI={fetchLoadMoreCommentsAPI}
                      />
                    ) : post?.type?.name === "Insight" ? (
                      <InsightPostCard
                        key={post?.id}
                        fetchLoadMoreCommentsAPI={fetchLoadMoreCommentsAPI}
                        getMentionUsers={getMentionUsers}
                        handleCommentReplyText={handleCommentReplyText}
                        handleCommentReplyToggle={handleCommentReplyToggle}
                        handleCommentText={handleCommentText}
                        handleCommentToggle={handleCommentToggle}
                        handleCommentsAPI={handleCommentsAPI}
                        handleCommentsReplyAPI={handleCommentsReplyAPI}
                        handleEngagementModalOpen={handleEngagementModalOpen}
                        handlePaste={handlePaste}
                        handlePopoverClose={handlePopoverClose}
                        handlePopoverCloseForReplyLike={
                          handlePopoverCloseForReplyLike
                        }
                        handlePopoverOpen={handlePopoverOpen}
                        handlePopoverOpenForReplyLike={
                          handlePopoverOpenForReplyLike
                        }
                        post={post}
                        renderCommentText={renderCommentText}
                        setCurrentPostId={setCurrentPostId}
                        setCurrentPostIdForReply={setCurrentPostIdForReply}
                        setElementsCallback={setElementsCallback}
                        setFetchAll={setFetchAll}
                        setIsSelectingSuggestion={setIsSelectingSuggestion}
                        setOpenUserImageModel={setOpenUserImageModel}
                        setSinglePostData={setSinglePostData}
                        toggleLikeForCommentsReaction={
                          toggleLikeForCommentsReaction
                        }
                        toggleReaction={toggleReaction}
                      />
                    ) : (
                      <PostCard
                        fetchLoadMoreCommentsAPI={fetchLoadMoreCommentsAPI}
                        getMentionUsers={getMentionUsers}
                        handleCheckboxChange={handleCheckboxChange}
                        handleClickForMenuPost={handleClickForMenuPost}
                        handleCommentReplyText={handleCommentReplyText}
                        handleCommentReplyToggle={handleCommentReplyToggle}
                        handleCommentText={handleCommentText}
                        handleCommentToggle={handleCommentToggle}
                        handleCommentsAPI={handleCommentsAPI}
                        handleCommentsReplyAPI={handleCommentsReplyAPI}
                        handleEngagementModalOpen={handleEngagementModalOpen}
                        handlePaste={handlePaste}
                        handlePopoverClose={handlePopoverClose}
                        handlePopoverCloseForReplyLike={
                          handlePopoverCloseForReplyLike
                        }
                        handlePopoverOpen={handlePopoverOpen}
                        handlePopoverOpenForReplyLike={
                          handlePopoverOpenForReplyLike
                        }
                        openMenuForPost={openMenuForPost}
                        post={post}
                        renderCommentText={renderCommentText}
                        setAckQuestions={setAckQuestions}
                        setCurrentPostId={setCurrentPostId}
                        setCurrentPostIdForReply={setCurrentPostIdForReply}
                        setFetchAll={setFetchAll}
                        setIsSelectingSuggestion={setIsSelectingSuggestion}
                        setOpenAckQuePopup={setOpenAckQuePopup}
                        setPostIdForAckQue={setPostIdForAckQue}
                        toggleLikeForCommentsReaction={
                          toggleLikeForCommentsReaction
                        }
                        toggleReaction={toggleReaction}
                        key={post?.id}
                      />
                    )}
                  </>
                ))}

                {unseenPosts.length > 0 && seenPosts.length > 0 && (
                  <>
                    <Divider className="upto-date-divider">
                      <Box className="upto-date-box-div">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="fs-14-primary"
                        />
                      </Box>
                    </Divider>
                    <Box>
                      <Typography
                        variant="h4"
                        className="fs-20 text-center mt-2 mb-2"
                      >
                        You’re Up to Date!
                      </Typography>
                    </Box>
                  </>
                )}

                {(seenPosts || [])?.map((post, index) => (
                  <>
                    {post?.type?.name === "Update" ? (
                      <UpdatePostCard
                        key={post?.id}
                        post={post}
                        setElementsCallback={setElementsCallback}
                        openMenuForPost={openMenuForPost}
                        handleClickForMenuPost={handleClickForMenuPost}
                        renderUpdateContent={renderUpdateContent}
                        setOpenImageModel={setOpenImageModel}
                        setSinglePostData={setSinglePostData}
                        setSelectedImageIndex={setSelectedImageIndex}
                        setAckQuestions={setAckQuestions}
                        setPostIdForAckQue={setPostIdForAckQue}
                        setOpenAckQuePopup={setOpenAckQuePopup}
                        handleCheckboxChange={handleCheckboxChange}
                        handlePopoverClose={handlePopoverClose}
                        handlePopoverCloseForReplyLike={
                          handlePopoverCloseForReplyLike
                        }
                        handleEngagementModalOpen={handleEngagementModalOpen}
                        toggleReaction={toggleReaction}
                        handlePopoverOpen={handlePopoverOpen}
                        handleCommentToggle={handleCommentToggle}
                        handleCommentText={handleCommentText}
                        handleCommentsAPI={handleCommentsAPI}
                        handlePaste={handlePaste}
                        getMentionUsers={getMentionUsers}
                        setIsSelectingSuggestion={setIsSelectingSuggestion}
                        renderCommentText={renderCommentText}
                        toggleLikeForCommentsReaction={
                          toggleLikeForCommentsReaction
                        }
                        setCurrentPostId={setCurrentPostId}
                        setCurrentPostIdForReply={setCurrentPostIdForReply}
                        handlePopoverOpenForReplyLike={
                          handlePopoverOpenForReplyLike
                        }
                        handleCommentReplyToggle={handleCommentReplyToggle}
                        handleCommentReplyText={handleCommentReplyText}
                        handleCommentsReplyAPI={handleCommentsReplyAPI}
                        setFetchAll={setFetchAll}
                        fetchLoadMoreCommentsAPI={fetchLoadMoreCommentsAPI}
                      />
                    ) : post?.type?.name === "Insight" ? (
                      <InsightPostCard
                        key={post?.id}
                        fetchLoadMoreCommentsAPI={fetchLoadMoreCommentsAPI}
                        getMentionUsers={getMentionUsers}
                        handleCommentReplyText={handleCommentReplyText}
                        handleCommentReplyToggle={handleCommentReplyToggle}
                        handleCommentText={handleCommentText}
                        handleCommentToggle={handleCommentToggle}
                        handleCommentsAPI={handleCommentsAPI}
                        handleCommentsReplyAPI={handleCommentsReplyAPI}
                        handleEngagementModalOpen={handleEngagementModalOpen}
                        handlePaste={handlePaste}
                        handlePopoverClose={handlePopoverClose}
                        handlePopoverCloseForReplyLike={
                          handlePopoverCloseForReplyLike
                        }
                        handlePopoverOpen={handlePopoverOpen}
                        handlePopoverOpenForReplyLike={
                          handlePopoverOpenForReplyLike
                        }
                        post={post}
                        renderCommentText={renderCommentText}
                        setCurrentPostId={setCurrentPostId}
                        setCurrentPostIdForReply={setCurrentPostIdForReply}
                        setElementsCallback={setElementsCallback}
                        setFetchAll={setFetchAll}
                        setIsSelectingSuggestion={setIsSelectingSuggestion}
                        setOpenUserImageModel={setOpenUserImageModel}
                        setSinglePostData={setSinglePostData}
                        toggleLikeForCommentsReaction={
                          toggleLikeForCommentsReaction
                        }
                        toggleReaction={toggleReaction}
                      />
                    ) : (
                      <PostCard
                        fetchLoadMoreCommentsAPI={fetchLoadMoreCommentsAPI}
                        getMentionUsers={getMentionUsers}
                        handleCheckboxChange={handleCheckboxChange}
                        handleClickForMenuPost={handleClickForMenuPost}
                        handleCommentReplyText={handleCommentReplyText}
                        handleCommentReplyToggle={handleCommentReplyToggle}
                        handleCommentText={handleCommentText}
                        handleCommentToggle={handleCommentToggle}
                        handleCommentsAPI={handleCommentsAPI}
                        handleCommentsReplyAPI={handleCommentsReplyAPI}
                        handleEngagementModalOpen={handleEngagementModalOpen}
                        handlePaste={handlePaste}
                        handlePopoverClose={handlePopoverClose}
                        handlePopoverCloseForReplyLike={
                          handlePopoverCloseForReplyLike
                        }
                        handlePopoverOpen={handlePopoverOpen}
                        handlePopoverOpenForReplyLike={
                          handlePopoverOpenForReplyLike
                        }
                        openMenuForPost={openMenuForPost}
                        post={post}
                        renderCommentText={renderCommentText}
                        setAckQuestions={setAckQuestions}
                        setCurrentPostId={setCurrentPostId}
                        setCurrentPostIdForReply={setCurrentPostIdForReply}
                        setFetchAll={setFetchAll}
                        setIsSelectingSuggestion={setIsSelectingSuggestion}
                        setOpenAckQuePopup={setOpenAckQuePopup}
                        setPostIdForAckQue={setPostIdForAckQue}
                        toggleLikeForCommentsReaction={
                          toggleLikeForCommentsReaction
                        }
                        toggleReaction={toggleReaction}
                        key={post?.id}
                      />
                    )}
                  </>
                ))}
              </Paper>
              {getAllPost?.length < 1 && (
                <div className="no-data mt-4">
                  <div>
                    <Image
                      src={NoDataFoundImg}
                      className="text-center mx-auto no-data-img mt-4"
                    />
                  </div>
                  <Typography component="h5" variant="h5">
                    No data found
                  </Typography>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <Popper
        open={openForReaction}
        anchorEl={anchorElForReaction}
        placement="top"
        disablePortal
      >
        <Box
          className="reaction-options"
          sx={{
            display: "flex",
            gap: "12px",
            padding: "10px",
            background: "white",
            border: "1px solid #EEEEEE",
            borderRadius: "20px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box onClick={() => toggleReaction(currentPostId, "like", true)}>
            <FontAwesomeIcon
              icon={faThumbsUp}
              className="reaction-icon fa-like-btn"
            />
          </Box>
          <Box onClick={() => toggleReaction(currentPostId, "love", true)}>
            <FontAwesomeIcon
              icon={faHeart}
              className="reaction-icon fa-love-btn"
            />
          </Box>
          <Box onClick={() => toggleReaction(currentPostId, "smile", true)}>
            <FontAwesomeIcon
              icon={faSmile}
              className="reaction-icon fa-smile-btn"
            />
          </Box>
          <Box onClick={() => toggleReaction(currentPostId, "angry", true)}>
            <FontAwesomeIcon
              icon={faAngry}
              className="reaction-icon fa-angry-btn"
            />
          </Box>
        </Box>
      </Popper>

      <Popper
        open={openForReactionReply}
        anchorEl={anchorElForReplyReaction}
        placement="top"
        disablePortal
      >
        <Box
          className="reaction-options"
          sx={{
            display: "flex",
            gap: "10px",
            padding: "7px",
            background: "white",
            border: "1px solid #EEEEEE",
            borderRadius: "20px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            onClick={() => {
              toggleLikeForCommentsReaction(
                currentPostId,
                "like",
                currentPostIdForReply,
                true
              );
            }}
          >
            <FontAwesomeIcon
              icon={faThumbsUp}
              className="reaction-icon-2 fa-like-btn"
            />
          </Box>
          <Box
            onClick={() =>
              toggleLikeForCommentsReaction(
                currentPostId,
                "love",
                currentPostIdForReply,
                true
              )
            }
          >
            <FontAwesomeIcon
              icon={faHeart}
              className="reaction-icon-2 fa-love-btn"
            />
          </Box>
          <Box
            onClick={() =>
              toggleLikeForCommentsReaction(
                currentPostId,
                "smile",
                currentPostIdForReply,
                true
              )
            }
          >
            <FontAwesomeIcon
              icon={faSmile}
              className="reaction-icon-2 fa-smile-btn"
            />
          </Box>
          <Box
            onClick={() =>
              toggleLikeForCommentsReaction(
                currentPostId,
                "angry",
                currentPostIdForReply,
                true
              )
            }
          >
            <FontAwesomeIcon
              icon={faAngry}
              className="reaction-icon-2 fa-angry-btn"
            />
          </Box>
        </Box>
      </Popper>

      {isPopupUserExist && (
        <Popover
          open={openForPopupUser}
          anchorEl={anchorElForPopupUser}
          onClose={handlePopoverCloseForMentionUser}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          id={idPopupUser}
        >
          <Card className="people-card-wrapper-3" sx={{ maxWidth: 420 }}>
            <CardContent>
              <Stack
                direction="vertical"
                className="gap-10 h-100 align-items-center"
              >
                <Box className="main-content-data-box">
                  <Stack
                    direction="horizontal"
                    className="d-flex flex-wrap gap-3 w-100 align-items-center justify-content-center"
                  >
                    <Box className="w-100 d-flex align-items-center justify-content-center">
                      {popupUserDetails?.image ? (
                        <Image
                          src={popupUserDetails?.image}
                          className="user-img text-center cursor-pointer"
                          alt={popupUserDetails?.name}
                          onClick={() => {
                            if (loggedUser?.user_type === "user") {
                              localStorage.setItem(
                                "last-redirect-page-user",
                                `/user-type/people/profile/view/${popupUserDetails?.id}`
                              );
                              navigate(
                                `/user-type/people/profile/view/${popupUserDetails?.id}`
                              );
                            } else if (
                              loggedUser?.user_type === "admin" &&
                              adminAccessible
                            ) {
                              localStorage.setItem(
                                "last-redirect-page-admin-user",
                                `/user-type/people/profile/view/${popupUserDetails?.id}`
                              );
                              navigate(
                                `/user-type/people/profile/view/${popupUserDetails?.id}`
                              );
                            }
                          }}
                        />
                      ) : (
                        <Image
                          src={DummyUser}
                          className="user-img text-center cursor-pointer"
                          alt={popupUserDetails?.name}
                          onClick={() => {
                            if (loggedUser?.user_type === "user") {
                              localStorage.setItem(
                                "last-redirect-page-user",
                                `/user-type/people/profile/view/${popupUserDetails?.id}`
                              );
                              navigate(
                                `/user-type/people/profile/view/${popupUserDetails?.id}`
                              );
                            } else if (
                              loggedUser?.user_type === "admin" &&
                              adminAccessible
                            ) {
                              localStorage.setItem(
                                "last-redirect-page-admin-user",
                                `/user-type/people/profile/view/${popupUserDetails?.id}`
                              );
                              navigate(
                                `/user-type/people/profile/view/${popupUserDetails?.id}`
                              );
                            }
                          }}
                        />
                      )}
                    </Box>
                  </Stack>
                  <Stack
                    direction="horizontal"
                    className="d-flex flex-wrap gap-3 align-items-center justify-content-center"
                  >
                    <h4
                      className="news-title dark-text fw-400 cursor-pointer text-break"
                      style={{ height: "28px" }}
                      onClick={() => {
                        if (loggedUser?.user_type === "user") {
                          localStorage.setItem(
                            "last-redirect-page-user",
                            `/user-type/people/profile/view/${popupUserDetails?.id}`
                          );
                          navigate(
                            `/user-type/people/profile/view/${popupUserDetails?.id}`
                          );
                        } else if (
                          loggedUser?.user_type === "admin" &&
                          adminAccessible
                        ) {
                          localStorage.setItem(
                            "last-redirect-page-admin-user",
                            `/user-type/people/profile/view/${popupUserDetails?.id}`
                          );
                          navigate(
                            `/user-type/people/profile/view/${popupUserDetails?.id}`
                          );
                        }
                      }}
                    >
                      {" "}
                      {popupUserDetails?.name}
                    </h4>
                  </Stack>

                  <Stack
                    direction="horizontal"
                    className="d-flex flex-wrap gap-3 mt-2 align-items-center justify-content-center"
                  >
                    <Box className="d-flex gap-4 justify-content-center align-items-center">
                      <FontAwesomeIcon
                        icon={faPhone}
                        className="fs-12-660C60"
                      />
                      <Typography className="fs-14 opacity-50">
                        {popupUserDetails?.phone
                          ? popupUserDetails?.phone
                          : "--"}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack
                    direction="horizontal"
                    className="d-flex flex-wrap gap-3 mt-1 align-items-center justify-content-center"
                  >
                    <Box className="d-flex gap-4 justify-content-center align-items-center">
                      <FontAwesomeIcon
                        icon={faEnvelopeOpen}
                        className="fs-12-660C60"
                      />
                      <Typography className="fs-14 opacity-50 text-break">
                        {popupUserDetails?.email
                          ? popupUserDetails?.email
                          : "--"}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack
                    direction="horizontal"
                    className="d-flex flex-wrap gap-3 mt-1 align-items-center justify-content-center"
                  >
                    <Button
                      className="bg-white btn-view-article d-flex gap-3"
                      variant="outlined"
                      size="large"
                      target="_blank"
                      onClick={() => {
                        handlePopoverCloseForMentionUser();
                        window.open(
                          `/user-type/people/profile/view/${popupUserDetails?.id}`,
                          "_blank"
                        );
                      }}
                    >
                      View Profile
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Popover>
      )}

      {openPostPopup && (
        <Suspense fallback={<Loader isLoading={true} />}>
          <UpdatePostCreateModal
            openPostPopup={openPostPopup}
            setOpenPostPopup={setOpenPostPopup}
            loggedUser={loggedUser}
            adminAccessible={adminAccessible}
            fetchAllTypePostReset={fetchAllTypePostReset}
            handleOpenUploadMedia={handleOpenUploadMedia}
            setOpenMediaModelFor={setOpenMediaModelFor}
            setSelectedMediaFileForUpdate={setSelectedMediaFileForUpdate}
            selectedMediaFileForUpdate={selectedMediaFileForUpdate}
            isMultiImage={true}
            selectedMediaFileDetailsForImages={
              selectedMediaFileDetailsForImages
            }
            selectedMediaFileForImages={selectedMediaFileForImages}
            setSelectedMediaFileForImages={setSelectedMediaFileForImages}
            setSelectedMediaFileDetailsForImages={
              setSelectedMediaFileDetailsForImages
            }
            updatePostId={updatePostId}
            setUpdatePostId={setUpdatePostId}
          />
        </Suspense>
      )}

      <BootstrapDialog
        onClose={handleCloseForAckQue}
        aria-labelledby="customized-dialog-title"
        open={openAckQuePopup}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {/* Modal title
           */}
          <div className="d-flex align-items-center gap-2">
            <Avatar
              alt={loggedUser?.name}
              className="author-avtar-creator"
              src={loggedUser?.thumbnail}
            >
              {loggedUser?.name?.charAt(0)}
            </Avatar>
            <span className="fs-14-700">{loggedUser?.name}</span>
          </div>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseForAckQue}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="alert-close-btn"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers className="no-border">
          <Box>
            <Typography className="fs-20 mt-2 text-center">
              Acknowledgement Questions
            </Typography>
          </Box>
          {ackQuestions?.map((que, index) => (
            <>
              <Box>
                <Typography className="fs-14 mt-2">{que?.question}</Typography>
                <TextField
                  className="input-field mt-2"
                  fullWidth
                  id="outlined-basic short summary"
                  variant="outlined"
                  multiline
                  maxRows={2}
                  placeholder={`write answer for ${que?.question}`}
                  value={que?.ans}
                  onChange={(event) =>
                    handleInputChangeForAckQuestions(event, index)
                  }
                />
              </Box>
            </>
          ))}
        </DialogContent>
        <DialogActions className="p-3">
          <Button
            className="btn primary-btn w-100"
            type="submit"
            onClick={() => {
              // sumbitPost();
              handleAckQuestionsAPI();
            }}
          >
            <FontAwesomeIcon
              icon={faCheck}
              className="d-md-block d-sm-none d-none"
            />
            <FontAwesomeIcon
              icon={faArrowRight}
              className="d-md-none d-sm-block"
            />
            Submit
          </Button>
        </DialogActions>
      </BootstrapDialog>

      <BootstrapDialog
        onClose={handleCloseForAckQueForce}
        aria-labelledby="customized-dialog-title"
        open={openAckQuePopupForce}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {/* Modal title
           */}
          <div className="d-flex align-items-center gap-2">
            <Avatar
              alt={loggedUser?.name}
              className="author-avtar-creator"
              src={loggedUser?.thumbnail}
            >
              {loggedUser?.name?.charAt(0)}
            </Avatar>
            <span className="fs-14-700">{loggedUser?.name}</span>
          </div>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseForAckQueForce}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="alert-close-btn"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers className="no-border">
          <Box>
            <Typography className="fs-20 mt-2 text-center">
              Acknowledgement Questions
            </Typography>
          </Box>
          {ackQuestions?.map((que, index) => (
            <>
              <Box>
                <Typography className="fs-14 mt-2">{que?.value}</Typography>
                <TextField
                  className="input-field mt-2"
                  fullWidth
                  id="outlined-basic short summary"
                  variant="outlined"
                  multiline
                  maxRows={2}
                  placeholder={`write answer for ${que?.value}`}
                  value={que?.ans}
                  onChange={(event) =>
                    handleInputChangeForAckQuestions(event, index)
                  }
                />
              </Box>
            </>
          ))}
        </DialogContent>
        <DialogActions className="p-3">
          <Button
            className="btn primary-btn w-100"
            type="submit"
            onClick={() => {
              handleAckQuestionsForceAPI();
            }}
          >
            <FontAwesomeIcon
              icon={faCheck}
              className="d-md-block d-sm-none d-none"
            />
            <FontAwesomeIcon
              icon={faArrowRight}
              className="d-md-none d-sm-block"
            />
            Submit
          </Button>
        </DialogActions>
      </BootstrapDialog>

      <BootstrapDialog
        onClose={handleCloseForImgeModel}
        aria-labelledby="customized-dialog-title"
        open={openImageModel}
        fullWidth
        maxWidth={"lg"}
      >
        <DialogContent dividers className="no-border-img-carousel">
          <Carousel
            data-bs-theme="dark"
            activeIndex={selectedImageIndex}
            onSelect={(selectedIndex) => setSelectedImageIndex(selectedIndex)}
          >
            {singlePostData?.multi_image.map((file, index) => (
              <Carousel.Item key={index}>
                <Image
                  className="w-100 h-100"
                  src={file?.file_url}
                  alt={file?.alt_text || `Image ${index + 1}`}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        onClose={handleCloseForUserImageModel}
        aria-labelledby="customized-dialog-title"
        open={openUserImageModel}
        fullWidth
        maxWidth={"xl"}
      >
        <DialogContent dividers className="no-border-img-carousel">
          <Image
            className="w-100 h-100"
            src={singlePostData?.celebrate_user?.thumbnail}
            alt={singlePostData?.celebrate_user?.name}
            style={{ objectFit: "contain" }}
          />
        </DialogContent>
      </BootstrapDialog>

      {isPopupOpen && currentPostIndex !== null && (
        <CustomPopup
          post={modelPosts[currentPostIndex]}
          onClose={() => closePopup(modelPosts[currentPostIndex]?.id)}
        />
      )}

      {isPopupOpenForAck && currentPostIndexForAck !== null && (
        <CustomPopupForAck
          post={modelPostsForAck[currentPostIndexForAck]}
          onClose={() =>
            closePopupForAck(modelPostsForAck[currentPostIndexForAck])
          }
        />
      )}

      {openEngagementModal && enagagementModalData[0] && (
        <ModalEngagement
          data={enagagementModalData[0]}
          onClose={handleEngagementModalClose}
          formatDateToReadable={formatDateToReadable}
        />
      )}

      <UploadMediaModel
        openMediaUpload={openMediaUpload}
        handleCloseForUploadMedia={handleCloseForUploadMedia}
        selectedMediaTypeForImage={selectedMediaTypeForImage}
        selectedMediaTypeForDoc={selectedMediaTypeForDoc}
        setSelectedMediaTypeForImage={setSelectedMediaTypeForImage}
        setSelectedMediaTypeForDoc={setSelectedMediaTypeForDoc}
        setSelectedMediaFolderIDForImage={setSelectedMediaFolderIDForImage}
        setSelectedMediaFolderIDForDoc={setSelectedMediaFolderIDForDoc}
        systemMediaFolderList={systemMediaFolderList}
        selectedMediaFolderIDForImage={selectedMediaFolderIDForImage}
        selectedMediaFolderIDFordoc={selectedMediaFolderIDFordoc}
        myMediaFolderList={myMediaFolderList}
        downloadFileForImage={downloadFileForImage}
        downloadFileFordoc={downloadFileFordoc}
        selectedMediaFileDetailsForImage={selectedMediaFileDetailsForImage}
        setSelectedMediaFileDetailsForImage={
          setSelectedMediaFileDetailsForImage
        }
        selectedMediaFileForImage={selectedMediaFileForImage}
        setSelectedMediaFileForImage={setSelectedMediaFileForImage}
        selectedMediaFileDetailsForDoc={selectedMediaFileDetailsForDoc}
        setSelectedMediaFileDetailsForDoc={setSelectedMediaFileDetailsForDoc}
        selectedMediaFileForDoc={selectedMediaFileForDoc}
        setSelectedMediaFileForDoc={setSelectedMediaFileForDoc}
        // setFieldValue={setFieldValue}
        mediaList={mediaList}
        total_mediaFiles={total_mediaFiles}
        pageForMedia={pageForMedia}
        setPageForMedia={setPageForMedia}
        handleChangePageForMedia={handleChangePageForMedia}
        rowsPerPageForMedia={rowsPerPageForMedia}
        handleChangeRowsPerPageForMedia={handleChangeRowsPerPageForMedia}
        media_Pagination_options={media_Pagination_options}
        mediaDataFor={mediaDataFor}
        handleCloseMediaModelAddMedia={handleCloseMediaModelAddMedia}
        setSelectedFileMedia={setSelectedFileMedia}
        selectedFileMedia={selectedFileMedia}
        isEditMedia={isEditMedia}
        setIsEditMedia={setIsEditMedia}
        setSelectedMedia={setSelectedMedia}
        selectedMedia={selectedMedia}
        deleteMedia={deleteMedia}
        setOpenRenameModel={setOpenRenameModel}
        setIsRenameMedia={setIsRenameMedia}
        setSelectedMediaFileForUpdate={setSelectedMediaFileForUpdate}
        selectedMediaFileForUpdate={selectedMediaFileForUpdate}
        setIsImageMedia={setIsImageMedia}
        isMultiImage={true}
        setSelectedMediaFileDetailsForImages={
          setSelectedMediaFileDetailsForImages
        }
        selectedMediaFileDetailsForImages={selectedMediaFileDetailsForImages}
        setSelectedMediaFileForImages={setSelectedMediaFileForImages}
        selectedMediaFileForImages={selectedMediaFileForImages}
        setLoading={setLoading}
        userToken={userToken}
        dispatch={dispatch}
        navigate={navigate}
        setSelectedAnyMediaFile={setSelectedAnyMediaFile}
        selectedAnyMediaFile={selectedAnyMediaFile}
        setSelectedAnyMediaFileDetails={setSelectedAnyMediaFileDetails}
        selectedAnyMediaFileDetails={selectedAnyMediaFileDetails}
        setUploadedFile={setUploadedFile}
        uploadedFile={uploadedFile}
        setShowRenderImage={setShowRenderImage}
        showRenderImage={showRenderImage}
        isSystemTypeMedia={isSystemTypeMedia}
        mediaTitle={mediaTitle}
        mediaAltText={mediaAltText}
        mediaDescription={mediaDescription}
        isAdvancePermissionForMedia={isAdvancePermissionForMedia}
        permissionsForMedia={permissionsForMedia}
        allowedDrag={false}
      />

      <AddMedia
        openFileUploadModel={openFileUploadModelMedia}
        isEdit={isEditMedia}
        handleCloseForMediaModel={handleCloseForMediaModel}
        getRootProps={getRootProps}
        selectedFile={selectedFileMedia}
        getInputProps={getInputProps}
        renderPreview={renderPreview}
        valueForMediaTab={valueForMediaTab}
        handleChangeForMedia={handleChangeForMedia}
        mediaTitle={mediaTitle}
        setMediaTitle={setMediaTitle}
        mediaAltText={mediaAltText}
        setMediaAltText={setMediaAltText}
        mediaDescription={mediaDescription}
        setMediaDescription={setMediaDescription}
        mediaDirectUrl={mediaDirectUrl}
        setMediaDirectUrl={setMediaDirectUrl}
        loggedUser={loggedUser}
        isSystemTypeMedia={isSystemTypeMedia}
        isParentFolderSystemType={isParentFolderSystemType}
        permissionsForMedia={permissionsForMedia}
        setPermissionsForMedia={setPermissionsForMedia}
        isAdvancePermissionForMedia={isAdvancePermissionForMedia}
        setIsAdvancePermissionForMedia={setIsAdvancePermissionForMedia}
        columns_atttributes_headers={columns_atttributes_headers}
        columns_advace_atttributes_headers={columns_advace_atttributes_headers}
        responseForMedia={responseForMedia}
        storeMedia={storeMedia}
        showSystemMediaCheckbox={false}
      />

      {openMenuForPost && (
        <ActionMenu
          anchorEl={anchorElMenuForPost}
          open={openMenuForPost}
          onClose={handleCloseForMenuPost}
          menuItems={menuItemsForPostList}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      )}

      <Dialog
        aria-labelledby="customized-dialog-title"
        open={openDeleteAlert}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className="dialog-title"
        >
          Are you sure?
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDeleteAlertModel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="alert-close-btn"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers className="">
          {" "}
          Are you sure want to delete post?
        </DialogContent>
        <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
          <Button
            className="btn delete-btn-model"
            onClick={handleCloseDeleteAlertModel}
          >
            <FontAwesomeIcon icon={faXmark} />
            Cancel
          </Button>
          <Button
            className="btn primary-btn"
            onClick={() => deleteTypePostAPI(singlePostData?.id)}
          >
            <FontAwesomeIcon icon={faFloppyDisk} />
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Loader isLoading={loading} />
      <Toaster />
    </>
  );
};

export default UserDashboard;
