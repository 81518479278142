import React, { useState } from "react";
import { Dialog, styled, Typography } from "@mui/material";
import {
  Box,
  Tabs,
  Tab,
  IconButton,
  Divider,
  TextField,
  Stack,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  RadioGroup,
  Radio
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../menu/AddEditItemModal.scss";
import { Row, Col } from "react-bootstrap";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { BootstrapTooltip } from "../tooltip/bootstrapTooltip";
import { AntSwitch } from "../switch/antSwitch";
import { faCircleInfo, faFloppyDisk, faXmark } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import FormControlLabel from "@mui/material/FormControlLabel";
import TableHeaderForSorting from "../TableHeaderForSorting";
import CreatableSelect from "react-select/creatable";


import { MuiColorInput } from "mui-color-input";


function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box className="tab-box padding-left-right ">{children}</Box>}
    </div>
  );
}

const columns_atttributes_headers = [
  {
    key: 1,
    disablePadding: true,
    id: "name",
    label: "Name",
    minWidth: 250,
    className: "other-color",
  },
  {
    key: 2,
    id: "view",
    disablePadding: true,
    label: "View",
    minWidth: 50,
    className: "other-color",
  },
  {
    key: 3,
    id: "deny",
    disablePadding: true,
    label: "Deny",
    minWidth: 50,
    className: "other-color",
  },
];

const columns_advace_atttributes_headers = [
  {
    key: 1,
    disablePadding: true,
    id: "name",
    label: "Name",
    minWidth: 250,
    className: "other-color",
  },
  {
    key: 2,
    id: "view",
    disablePadding: true,
    label: "View",
    minWidth: 50,
    className: "other-color",
  },
  {
    key: 3,
    id: "deny",
    disablePadding: true,
    label: "Deny",
    minWidth: 50,
    className: "other-color",
  },

  {
    key: 5,
    id: "edit",
    disablePadding: true,
    label: "Edit",
    minWidth: 50,
    className: "other-color",
  },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    // minHeight: "40vh",
    // maxHeight: "40vh"
  }
}));

function AddEditItemModal({
  menuItem,
  onClose,
  loading,
  setLoading,
  userToken,
  navigate,
  getAllGroupsAndUsers,
  setGetAllGroupsAndUsers,
  permissions,
  setPermissions,
  isAdvancePermission,
  setIsAdvancePermission,
  getAllIcons,
  menuItemTypeIcon,
  setMenuItemTypeIcon,
  menuItemTitle,
  setMenuItemTitle,
  menuItemLink,
  setMenuItemLink,
  menuItemVisible,
  setMenuItemVisible,
  menuItemTypeColor,
  setMenuItemTypeColor,
  menuItemTypeBGColor,
  setMenuItemTypeBGColor,
  menuItems,
  setMenuItems,
  menuItemPermissions,
  setMenuItemPermissions,
  menuItemIsAdvancePermission,
  setMenuItemIsAdvancePermission,
  menuItemErrors,
  setMenuItemErrors,
  validateFields,
  addMenuItems,
  updateMenuItem,
  attributeID,
  addSubMenuItems,
  addSubMenu,
  allMenuList,
  setMenuItemType,
  menuItemType,
  InternalMenuOptionLists,
  setMenuItemInternalValue,
  menuItemInternalValue
}) {

  const [activeTab, setActiveTab] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const [tabs, setTabs] = useState([
    { key: '0', label: 'Content and Settings' },
    { key: '1', label: 'Permissions' },
    { key: '2', label: 'Customisation' },
  ]);

  const openInfo = Boolean(anchorEl);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleToggleForMenuItemPermission = (value, key) => {
    setMenuItemPermissions((prevPermissions) =>
      prevPermissions?.map((permission) => {
        if (permission?.value === value) {
          if (key === 'deny') {
            // If deny is toggled, set all other permissions to false
            return {
              ...permission,
              deny: !permission.deny,
              view: false,
              edit: false,
            };
          } else {
            // If any other permission is toggled, set deny to false
            return {
              ...permission,
              [key]: !permission[key],
              deny: false,
            };
          }
        }
        return permission;
      })
    );

    setGetAllGroupsAndUsers((prevPermissions) =>
      prevPermissions.map((permission) => {
        if (permission?.value === value) {
          if (key === 'deny') {
            // If deny is toggled, set all other permissions to false
            return {
              ...permission,
              deny: !permission.deny,
              view: false,
              edit: false,
            };
          } else {
            // If any other permission is toggled, set deny to false
            return {
              ...permission,
              [key]: !permission[key],
              deny: false,
            };
          }
        }
        return permission;
      })
    );
  };

  const handleChangeForIconColor = (newValue) => {
    setMenuItemTypeColor(newValue)

  };

  const handleChangeForIconBGColor = (newValue) => {
    // setFieldValue("iconBGColor", newValue);
    setMenuItemTypeBGColor(newValue)
  };

  const handleMenuTypeChange = (event) => {
    setMenuItemType(event?.target?.value)
  }

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={true}
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
        className="highlight-menu-model-title-div"
      >
        <Typography sx={{ fontSize: "1.25rem", lineHeight: "1.75rem", fontWeight: 400 }} className="text-center menu-modal-title">
          {`${menuItem ? 'Edit' : 'Add'} Menu Item`}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
          className="alert-close-btn menu-close-btn"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className="menu-modal-dialog-content">
        <Box>
          {/* Tabs Header */}
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            className="menu-modal-tabs"
            variant="scrollable"
            scrollButtons="auto"

            TabIndicatorProps={{
              style: {
                backgroundColor: "#660C60",
              },
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                label={
                  <Box gap={2} className="menu-modal-tab-label">
                    <span>
                      {`${tab?.label}`}
                    </span>
                  </Box>
                }
                key={index}
              />
            ))}
          </Tabs>
          <Divider className="divider" />
          <CustomTabPanel key={0} value={activeTab} index={0}>
            <Row>
              <Col lg="12" className="">
                <Typography className="fw-400 dark-text">
                  Menu Item Title
                  <span className="required">
                    *
                  </span>
                </Typography>
                <TextField
                  className="input-field mt-2"
                  fullWidth
                  id="outlined-basic username"
                  variant="outlined"
                  size="small"
                  name="title"
                  placeholder="Enter title here..."
                  value={menuItemTitle}
                  onChange={(
                    event
                  ) => {
                    setMenuItemTitle(event?.target?.value)
                    if (event.target.value.trim()) {
                      setMenuItemErrors((prevErrors) => ({ ...prevErrors, title: "" }));
                    }
                  }}
                  helperText={
                    menuItemErrors.title
                  }
                  error={
                    Boolean(
                      menuItemErrors.title
                    )
                  }
                />
              </Col>

              <Col lg="12" className="col-menu-item">
                <Typography className="fw-400 dark-text">
                  Menu Item Type
                  <span className="required">
                    *
                  </span>
                </Typography>

                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="categoryType"
                  className="mt-1"
                  value={menuItemType}
                  onChange={handleMenuTypeChange}
                >
                  <FormControlLabel
                    key={"external"}
                    value={"external"}
                    control={
                      <Radio
                        size="small"
                        checked={
                          menuItemType === "external"
                        }
                        value={"external"}
                      />
                    }
                    label="Link"
                    className="font-inter"
                  />
                  <FormControlLabel
                    key={"internal"}
                    value={"internal"}
                    control={
                      <Radio
                        size="small"
                        checked={
                          menuItemType === "internal"
                        }
                        value={"internal"}
                      />
                    }
                    label={"Internal"}
                    className="font-inter"
                  />
                </RadioGroup>

              </Col>

              {menuItemType === "external" ? (
                <Col lg="12" className="col-menu-item">
                  <Typography className="fw-400 dark-text">
                    Menu Item Link
                    <span className="required">
                      *
                    </span>
                  </Typography>

                  {/* <TextField
                    className="input-field mt-2"
                    fullWidth
                    id="outlined-basic username"
                    variant="outlined"
                    size="small"
                    name="title"
                    placeholder="Enter title here..."
                    value={menuItemLink}
                    onChange={(
                      event
                    ) => {
                      setMenuItemLink(event?.target?.value)
                      if (event.target.value.trim()) {
                        setMenuItemErrors((prevErrors) => ({ ...prevErrors, link: "" }));
                      }
                    }}
                    helperText={
                      menuItemErrors.link
                    }
                    error={
                      Boolean(
                        menuItemErrors.link
                      )
                    }
                  /> */}

                  <CreatableSelect
                    placeholder={'Select internal link or add external link'}
                    classNamePrefix="select"
                    value={{ 'value': menuItemLink, 'label': menuItemLink }}
                    menuPlacement="auto"
                    maxMenuHeight={200}
                    options={allMenuList}
                    onChange={(selectedOption) => {
                      setMenuItemLink(selectedOption?.value || null);
                      if (selectedOption?.value) {
                        setMenuItemErrors((prevErrors) => ({ ...prevErrors, link: "" }));
                      }
                    }}
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#660c605c"
                          : provided.backgroundColor,
                        color: state.isSelected
                          ? "var(--dark-color)"
                          : provided.color,
                      }),
                    }}
                  />
                  {menuItemErrors?.link && (
                    <p
                      style={{ color: "#d32f2f" }}
                      className="error-msg"
                    >
                      {menuItemErrors?.link}
                    </p>
                  )}
                </Col>
              ) : (
                <Col lg="12" className="col-menu-item">
                  <Typography className="fw-400 dark-text">
                    Internal Menu Options
                    <span className="required">
                      *
                    </span>
                  </Typography>

                  <Select
                    closeMenuOnSelect={true}
                    options={InternalMenuOptionLists}
                    menuPlacement="bottom"
                    name="users"
                    value={menuItemInternalValue}
                    placeholder="Select..."
                    menuPosition="fixed"
                    onChange={(selectedOption) => {
                      setMenuItemInternalValue(selectedOption);
                      if (selectedOption) {
                        setMenuItemErrors((prevErrors) => ({ ...prevErrors, link: "" }));
                      }
                    }}
                    className={`muilt-select-field mt-2 `}
                    classNamePrefix="select"
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#660c605c"
                          : provided.backgroundColor,
                        color: state.isSelected
                          ? "var(--dark-color)"
                          : provided.color,
                      }),
                      multiValueLabel: (provided, state) => {
                        return {
                          ...provided,
                          color:
                            state.data?.type === "group"
                              ? "rgb(0, 135, 90)"
                              : "rgb(0, 82, 204)",
                        };
                      },
                    }}
                  />
                  {menuItemErrors?.link && (
                    <p
                      style={{ color: "#d32f2f" }}
                      className="error-msg"
                    >
                      {menuItemErrors?.link}
                    </p>
                  )}
                </Col>
              )}

              <Col lg="12" className="col-menu-item">
                <Box className="d-flex flex-wrap align-items-cente gap-5">
                  <Box className="d-flex flex-wrap gap-2">
                    <Typography className="fw-400 dark-text font-inter">
                      Menu Item is Visible
                    </Typography>
                    <BootstrapTooltip
                      title=" This is required field"
                      placement="right-start"
                    >
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        className="mandatory-icon fw-900"
                        aria-owns={
                          openInfo
                            ? "mouse-over-popover"
                            : undefined
                        }
                        aria-haspopup="true"
                      />
                    </BootstrapTooltip>
                  </Box>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    className="h-100"
                  >
                    <AntSwitch
                      name="fieldMandatory"
                      checked={menuItemVisible}
                      onChange={(event) => {
                        setMenuItemVisible(event?.target?.checked)
                      }}
                      inputProps={{
                        "aria-label": "ant design",
                      }}
                    />
                  </Stack>
                </Box>
              </Col>
            </Row>
          </CustomTabPanel>
          <CustomTabPanel key={1} value={activeTab} index={1}>
            <Row>
              <Col lg="12" className="">
                <Typography className="fw-400 fs-14 dark-text">
                  Select Users
                </Typography>
                <Select
                  closeMenuOnSelect={false}
                  options={getAllGroupsAndUsers}
                  menuPlacement="bottom"
                  name="users"
                  value={menuItemPermissions}
                  placeholder="Select users..."
                  isMulti
                  menuPosition="fixed"
                  onChange={(selectedOption) => {
                    const users = selectedOption.map(
                      (option) => option
                    );

                    setMenuItemPermissions(users);
                    if (menuItemPermissions?.length < 1) {
                      setMenuItemErrors((prevErrors) => ({ ...prevErrors, permissions: "" }));
                    }
                  }}
                  className={`muilt-select-field mt-2 `}
                  classNamePrefix="select"
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#660c605c"
                        : provided.backgroundColor,
                      color: state.isSelected
                        ? "var(--dark-color)"
                        : provided.color,
                    }),
                    multiValueLabel: (provided, state) => {
                      return {
                        ...provided,
                        color:
                          state.data?.type === "group"
                            ? "rgb(0, 135, 90)"
                            : "rgb(0, 82, 204)",
                      };
                    },
                  }}
                />
                {menuItemErrors?.permissions && (
                  <p
                    style={{ color: "#d32f2f" }}
                    className="error-msg"
                  >
                    {menuItemErrors?.permissions}
                  </p>
                )}
              </Col>
              {/* <Col lg="12" className="repeatable-col">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={menuItemIsAdvancePermission}
                      name="allowEdit"
                      onChange={() =>
                        setMenuItemIsAdvancePermission(
                          !menuItemIsAdvancePermission
                        )
                      }
                    />
                  }
                  label="Show Advanced Permissions"
                  className="font-inter fs-14"
                />
              </Col> */}
              <Col lg="12" className="repeatable-col">
                <TableContainer className="z-0">
                  <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                  >
                    <TableHeaderForSorting
                      columns={
                        columns_atttributes_headers
                      }
                    />
                    <TableBody>
                      {menuItemPermissions?.length > 0 && (
                        <>
                          {menuItemPermissions?.map(
                            (permission, index) => (
                              <>
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={permission?.value}
                                  className="cursor-pointer tbl-row-attribute-row"
                                >
                                  <TableCell
                                    align="left"
                                    className={` tbl-border-left tbl-cell-attribute-2`}
                                    style={{
                                      width: "100%",
                                      top: 0,
                                      left: 0,
                                    }}
                                  >
                                    <Typography className="fs-14">
                                      {permission?.label}
                                    </Typography>
                                  </TableCell>

                                  <TableCell
                                    align="right"
                                    className={`tbl-cell-attribute-2`}
                                    style={{
                                      width: "100%",
                                      top: 0,
                                      left: 0,
                                    }}
                                  >
                                    <AntSwitch
                                      name="view"
                                      checked={
                                        permission?.view
                                      }
                                      onChange={() =>
                                        handleToggleForMenuItemPermission(
                                          permission?.value,
                                          "view"
                                        )
                                      }
                                      inputProps={{
                                        "aria-label":
                                          "ant design",
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    className={`tbl-cell-attribute-2`}
                                    style={{
                                      width: "100%",
                                      top: 0,
                                      left: 0,
                                    }}
                                  >
                                    <AntSwitch
                                      name="deny"
                                      checked={
                                        permission?.deny
                                      }
                                      onChange={() =>
                                        handleToggleForMenuItemPermission(
                                          permission?.value,
                                          "deny"
                                        )
                                      }
                                      inputProps={{
                                        "aria-label":
                                          "ant design",
                                      }}
                                    />
                                  </TableCell>

                                  {menuItemIsAdvancePermission && (
                                    <>
                                      <TableCell
                                        align="right"
                                        className={`tbl-cell-attribute-2`}
                                        style={{
                                          width: "100%",
                                          top: 0,
                                          left: 0,
                                        }}
                                      >
                                        <AntSwitch
                                          name="edit"
                                          checked={
                                            permission?.edit
                                          }
                                          onChange={() =>
                                            handleToggleForMenuItemPermission(
                                              permission?.value,
                                              "edit"
                                            )
                                          }
                                          inputProps={{
                                            "aria-label":
                                              "ant design",
                                          }}
                                        />
                                      </TableCell>

                                    </>
                                  )}
                                </TableRow>
                                <TableRow className="extra-row"></TableRow>
                              </>
                            )
                          )}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Col>
            </Row>
          </CustomTabPanel>
          <CustomTabPanel key={2} value={activeTab} index={2}>
            <Typography component="p" className="fs-14">
              Icon
            </Typography>
            <Stack
              direction="row"
              className="d-flex flex-wrap gap-1 mt-2"
            >
              {getAllIcons?.map((icon, index) => (
                <div
                  className={`icon-div d-flex justify-content-center align-items-center icons-div ${menuItemTypeIcon === icon?.id &&
                    "selected-icon"
                    }`}
                  key={index}
                  onClick={() => {
                    setMenuItemTypeIcon(icon?.id);
                    setMenuItemErrors((prevErrors) => ({ ...prevErrors, icon: "" }));
                  }}
                >
                  <FontAwesomeIcon
                    icon={icon?.icon_value}
                    className="icon"
                  />

                </div>
              ))}
            </Stack>
            {menuItemErrors?.icon && (
              <p
                style={{ color: "#d32f2f" }}
                className="error-msg"
              >
                {menuItemErrors?.icon}
              </p>
            )}
            <div className="mt-3">
              <Stack className="customization-icon-color">
                <Typography
                  className="fw-400 dark-text font-inter "
                  whiteSpace="nowrap"
                >
                  Color
                </Typography>

                <MuiColorInput
                  format="hex"
                  value={menuItemTypeColor}
                  onChange={handleChangeForIconColor}
                  className="icon-color-picker"
                  fullWidth
                  id="outlined-basic username"
                  variant="outlined"
                  size="small"
                  name="iconColor"
                />
              </Stack>
              {menuItemErrors?.iconcolor && (
                <p
                  style={{ color: "#d32f2f" }}
                  className="error-msg"
                >
                  {menuItemErrors?.iconcolor}
                </p>
              )}
            </div>
            <div className="mt-3">
              <Stack className="customization-icon-color">
                <Typography
                  className="fw-400 dark-text font-inter "
                  whiteSpace="nowrap"
                >
                  Background
                </Typography>

                <MuiColorInput
                  format="hex"
                  value={menuItemTypeBGColor}
                  onChange={handleChangeForIconBGColor}
                  className="icon-color-picker2"
                  fullWidth
                  id="outlined-basic username"
                  variant="outlined"
                  size="small"
                  name="iconBGColor"
                />
              </Stack>
              {menuItemErrors?.iconBgColor && (
                <p
                  style={{ color: "#d32f2f" }}
                  className="error-msg"
                >
                  {menuItemErrors?.iconBgColor}
                </p>
              )}
            </div>
          </CustomTabPanel>
        </Box>
      </DialogContent>
      <DialogActions className="d-flex flex-wrap justify-content-between gap-2 p-3 z-0 action-btn-model">
        <Button
          className="btn delete-btn-model"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faXmark} />
          Cancel
        </Button>
        <Button
          className="btn primary-btn"
          onClick={() => {
            if (attributeID === undefined) {
              addMenuItems()
            }
            else if (attributeID !== undefined && addSubMenu === true) {
              addSubMenuItems()
            }
            else {
              updateMenuItem()
            }
          }}
        >
          <FontAwesomeIcon icon={faFloppyDisk} />
          Save Changes
        </Button>
      </DialogActions>
    </BootstrapDialog >
  )
}

export default AddEditItemModal