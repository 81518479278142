import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Image } from "react-bootstrap";
import {
  Box,
  Stack,
  Breadcrumbs,
  Link,
  Typography,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Avatar,
  TableBody,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Chip,
  CircularProgress,
  TableHead,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "../../styles/types.scss";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import logoutHelper from "../../helpers/logoutHelper";
import Loader from "../../components/common/loader";
import api from "../../components/common/commonFunctionForApi";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";
import { Toast } from "../../utils/toast";
import {
  faXmark,
  faFloppyDisk
} from "@fortawesome/free-solid-svg-icons";
import { typeTabAction } from "../../redux/slices/type/type";
import { Toaster } from "react-hot-toast";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import StatusIcon from "../../components/common/StatusIcon";
import { drawerMenuAction } from "../../redux/slices/drawer/drawer";
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import PaginationCustom from "../../components/common/pagination/paginationCustom";

function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box className="tab-box">{children}</Box>}
    </div>
  );
}

const Types = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorElMenu, setAnchorElForMenu] = useState(null);

  const openMenuFoEdit = Boolean(anchorElMenu);
  const [loading, setLoading] = useState(false);
  const [getAllTypes, setGetAllTypes] = useState([]);
  const { selectedTypeTab, pagination } = useSelector((state) => state?.typeTab);

  const [value, setValue] = useState(selectedTypeTab);
  const [typeId, setTypeId] = useState();

  const [isWarningMessage, setIsWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  //states for paginations
  const [page, setPage] = useState(1);
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [sortTypeForAdmin, setSortTypeForAdmin] = useState({
    name: null,
    updated_at: null,
    created_by: null,
  })

  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [openConfirmDeleteAlert, setOpenConfirmDeleteAlert] = useState(false);

  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);
  const userToken = localStorage.getItem("accessToken");

  const {
    open,
  } = useSelector((state) => state?.drawerMenu);

  const ITEM_HEIGHT = 48;

  const handleClick = (event, row) => {
    setAnchorElForMenu(event.currentTarget);
    setTypeId(row);
  };
  const handleClose = () => {
    setAnchorElForMenu(null);
  };

  const handleCloseWarningMessage = () => {
    setIsWarningMessage(false);

    setWarningMessage("");
  };

  const handleCloseSuccessMessage = () => {
    setIsSuccessMessage(false);
    setIsSuccessMessage("");
  };

  const handleCloseDeleteAlertModel = () => {
    setOpenDeleteAlert(false);
    setAnchorElForMenu(null);

  };

  const handleCloseConfirmDeleteAlertModel = () => {
    setOpenConfirmDeleteAlert(false);
    setAnchorElForMenu(null);
  };

  const getStatusValue = (value) => {
    return value === 0 ? "enable"
      : value === 1 ? "disable"
        : value === 3 ? "deleted"
          : "system";
  };

  const handleChange = (event, newValue) => {
    dispatch(typeTabAction.handleSelectedTab(newValue));
    setValue(newValue);
    setPage(1);
    setGetAllTypes([]);
    dispatch(drawerMenuAction?.handleFetchNewType(true))

    fetchAllTypes(getStatusValue(newValue), 1, pagination?.per_page);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    dispatch(typeTabAction.setPagination({
      ...pagination,
      page: newPage
    }))

    fetchAllTypes(getStatusValue(value), newPage, pagination?.per_page)
  };

  const handleChangeRowsPerPage = (event) => {

    dispatch(typeTabAction.setPagination({
      ...pagination,
      per_page: event.target.value,
      page: 1
    }));
    fetchAllTypes(getStatusValue(value), 1, event.target.value)
  };

  const fetchAllTypes = async (type, page, per_page) => {
    try {
      setLoading(true)
      let order_by
      const userToken = localStorage.getItem("accessToken");
      const firstNonNull = Object.entries(sortTypeForAdmin).find(([key, value]) => value !== null);
      if (firstNonNull) {
        order_by = firstNonNull[0] + '_' + firstNonNull[1];
      }
      const response = await api.get(
        `admin/type/master/list/${type}?${order_by ? `order_by=${order_by}&` : ""}page=${page ?? 1}&per_page=${per_page}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      setGetAllTypes(response?.data?.data)
      dispatch(typeTabAction.setPagination({
        ...pagination,
        page: response?.data?.meta?.current_page,
        per_page: response?.data?.meta?.per_page,
        meta_data: response?.data?.meta
      }));

    } catch (err) {
      console.log("error in fetch posts api::", err);
      if (err?.response?.status === 401) {
        localStorage.setItem("last-redirect-page", `/categories`);
        logoutHelper(dispatch, navigate, userToken);
      }

      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
    } finally {
      setIsDataFetching(false); // Reset fetching state after the API call
      setLoading(false);
    }
  };

  const changeTypeStatus = async (id, status) => {
    try {
      let data = {
        status: status,
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/type/master/status `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        console.log(value);
        if (value === 2) {
          setGetAllTypes((prevCategories) =>
            prevCategories.map((category) =>
              category.id === id ? { ...category, status: status } : category
            )
          );
        } else {
          setGetAllTypes((prevCategories) =>
            prevCategories.filter((category) => category.id !== id)
          );
        }
        setLoading(false)
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        dispatch(drawerMenuAction?.handleFetchNewType(true))
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const RestoreType = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/type/master/restore `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {

        if (value === 2) {
          setGetAllTypes((prevCategories) =>
            prevCategories.map((category) =>
              category.id === id ? { ...category, status: response?.response?.status } : category
            )
          );
        } else {
          setGetAllTypes((prevCategories) =>
            prevCategories.filter((category) => category.id !== id)
          );
        }
        setLoading(false);
        setIsSuccessMessage(true);
        dispatch(drawerMenuAction?.handleFetchNewType(true))
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const deleteTypeAPI = async (id) => {
    try {
      setLoading(true);
      const response = await api.delete(`admin/type/master/delete/${id} `, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        if (value === 2) {
          setGetAllTypes((prevCategories) =>
            prevCategories.map((category) =>
              category.id === id ? { ...category, status: 'deleted' } : category
            )
          );
        } else {
          setGetAllTypes((prevCategories) =>
            prevCategories.filter((category) => category.id !== id)
          );
        }
        setLoading(false);
        setIsSuccessMessage(true);
        dispatch(drawerMenuAction?.handleFetchNewType(true))
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        handleCloseDeleteAlertModel()
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const permanantlyDeleteTypeAPI = async (id) => {
    try {
      setLoading(true);
      const response = await api.delete(`admin/type/master/forcedelete/${id} `, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
        dispatch(drawerMenuAction?.handleFetchNewType(true))

        setGetAllTypes([]);
        setPage(1);
        handleCloseConfirmDeleteAlertModel()
        fetchAllTypes(
          getStatusValue(value), page, pagination?.per_page
        );
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attempts wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const handleSort = (key) => {
    let sortTypeValue = null;
    if (sortTypeForAdmin[key] === null) {
      sortTypeValue = 'asc';
    } else if (sortTypeForAdmin[key] === 'asc') {
      sortTypeValue = 'desc';
    } else if (sortTypeForAdmin[key] === 'desc') {
      sortTypeValue = null;
    }
    const data = {
      name: null,
      updated_at: null,
      created_by: null,
      [key]: sortTypeValue
    };
    setSortTypeForAdmin(data)

  }

  function a11yProps2(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value === index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }

  useEffect(() => {
    fetchAllTypes(
      getStatusValue(selectedTypeTab), 1, 10
    );
    setValue(selectedTypeTab);
  }, []);

  useEffect(() => {
    fetchAllTypes(getStatusValue(value), page, pagination?.per_page)
  }, [sortTypeForAdmin])

  return (
    <>
      <div
        className={`main-content-wrapper-2 ${open === true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          {isSuccessMessage && (
            <SuccessAlert
              message={successMessage}
              onClose={handleCloseSuccessMessage}
            />
          )}

          {isWarningMessage && (
            <WarningAlert
              message={warningMessage}
              onClose={handleCloseWarningMessage}
            />
          )}
          <div className="main-div-2-types">
            <Box className="">
              <Stack spacing={2}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link
                    underline="hover"
                    key="1"
                    color="inherit"
                    href="/"
                    component="h3"
                    className="cursor-pointer"
                  >
                    Admin
                  </Link>
                  <Typography key="3" component="h3" color="text.primary">
                    Types
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Box>
            <Button
              className="btn primary-btn"
              onClick={() => {
                localStorage.setItem("last-redirect-page", `/types/add-new`);

                navigate("/types/add-new");
              }}
            >
              Add New
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
          <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="box-attribute"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                className={"tabs type-post-tab"}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#660C60",
                  },
                }}
              >

                <Tab
                  label="Active"
                  iconPosition="start"
                  {...a11yProps2(0)}
                />
                <Tab
                  label="Inactive"
                  {...a11yProps2(1)}
                  iconPosition="start"
                />

                <Tab
                  label="System"
                  {...a11yProps2(2)}
                  iconPosition="start"
                />
                <Tab
                  label="Deleted"
                  {...a11yProps2(3)}
                  iconPosition="start"
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={value}>
              <Paper sx={{ width: "100%" }} className="type-list-paper">
                <TableContainer component={Paper} className="type-list-container">
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                  >
                    {getAllTypes?.length > 0 && (
                      <>

                        <TableHead className='type-list-header'>
                          <TableRow>
                            <TableCell className='type-list-header-data cursor-pointer type-list-first-header'
                              onClick={
                                () => {
                                  handleSort('name');
                                }
                              }
                            >Name {(sortTypeForAdmin?.name && sortTypeForAdmin?.name === 'asc') ? <ExpandLess className="sort-icon" /> : ((sortTypeForAdmin?.name && sortTypeForAdmin?.name === 'desc') ? <ExpandMore className="sort-icon" /> : <ExpandLess className="sort-icon text-muted opacity-50" />)}</TableCell>
                            <TableCell className='type-list-header-data type-list-second-header-att-post' align="left">Last Updated</TableCell>
                            <TableCell className='type-list-header-data type-list-third-header' align="left">Creator</TableCell>
                            <TableCell className='type-list-header-data type-list-last-header-att-post' align="right">Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableRow style={{ height: '10px', backgroundColor: 'transparent' }} />

                        <TableBody className='type-list-body'>
                          {getAllTypes?.map((row, index) => (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index + 1}
                              className='type-list-body-row'
                            >
                              <TableCell
                                className='' component="td" scope="row"
                              >
                                <div
                                  className="d-flex flex-warp gap-3"
                                  style={{ fontSize: "14px" }}
                                >
                                  <Typography className="type-list-title" variant="h4" component="div">
                                    {row?.name}
                                  </Typography>
                                  {row?.system_type === 1 && (
                                    <Chip
                                      label="System Type"
                                      className="chip-label-div"
                                    />
                                  )}
                                </div>
                              </TableCell>

                              <TableCell className='type-list-text' align="left">
                                {row?.used}
                              </TableCell>

                              <TableCell className='' align="left">
                                <Box className='type-list-text d-flex align-items-center type-list-createdby'>
                                  <Avatar
                                    className="type-list-created-avatar"
                                    src={row?.created_by}
                                    alt={row?.created_by}
                                  >
                                    {row?.created_by?.charAt(0)}
                                  </Avatar>
                                  <span className>
                                    {row?.created_by}
                                  </span>
                                </Box>
                              </TableCell>

                              <TableCell
                                align="center"
                                className="tbl-body-cell-2 tbl-border-right"
                              >
                                <div>
                                  <Stack
                                    direction="row"
                                    alignContent="center"
                                    alignItems="center"
                                    spacing={1}
                                    className="gap-1"
                                  >
                                    <StatusIcon status={row?.status} />
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={
                                        openMenuFoEdit
                                          ? "long-menu"
                                          : undefined
                                      }
                                      aria-expanded={
                                        openMenuFoEdit ? "true" : undefined
                                      }
                                      aria-haspopup="true"
                                      onClick={(e) => handleClick(e, row)}
                                      className="status-icon"
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </Stack>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </>
                    )}
                  </Table>
                </TableContainer>
                {getAllTypes?.length > 0 && (
                  <>
                    <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
                    <PaginationCustom
                      per_page={pagination?.per_page}
                      per_page_options={[10, 20, 50, 100]}
                      from={pagination?.meta_data?.from}
                      to={pagination?.meta_data?.to}
                      last_page={pagination?.meta_data?.last_page}
                      total={pagination?.meta_data?.total}
                      page={pagination?.page}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </>
                )}
                {isDataFetching && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 2
                    }}
                  >
                    <CircularProgress sx={{ color: "#660C60" }} />
                  </Box>
                )}
              </Paper>
              {getAllTypes?.length < 1 && (
                <div className="no-data mt-5">
                  <div>
                    <Image
                      src={NoDataFoundImg}
                      className="text-center mx-auto no-data-img mt-4"
                    />
                  </div>
                  <Typography component="h5" variant="h5">
                    No data found
                  </Typography>
                </div>
              )}
            </CustomTabPanel>
          </Box>
        </Container>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openDeleteAlert}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            Are you sure?
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDeleteAlertModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="">
            {" "}
            Are you sure want to delete type?
          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
            <Button
              className="btn delete-btn-model"
              onClick={handleCloseDeleteAlertModel}
            >
              <FontAwesomeIcon icon={faXmark} />
              Cancel
            </Button>
            <Button
              className="btn primary-btn"
              onClick={() => {
                deleteTypeAPI(typeId?.id)
              }
              }
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openConfirmDeleteAlert}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            Are you sure?
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseConfirmDeleteAlertModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="">
            {" "}
            Are you sure want to permanantly delete type? Once you permanatly
            delete you can not restore it again.
          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
            <Button
              className="btn delete-btn-model"
              onClick={handleCloseConfirmDeleteAlertModel}
            >
              <FontAwesomeIcon icon={faXmark} />
              Cancel
            </Button>
            <Button
              className="btn primary-btn"
              onClick={() => permanantlyDeleteTypeAPI(typeId?.id)}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Menu
          anchorEl={anchorElMenu}
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          open={openMenuFoEdit}
          anchorOrigin={"right"}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
              zIndex: "1",
            },
          }}
        >
          {typeId?.status !== "deleted" && (
            <MenuItem
              onClick={() => {
                localStorage.setItem("last-redirect-page", `/types/edit/${typeId?.id}`);

                navigate(`/types/edit/${typeId?.id}`);
              }}
            >
              Edit
            </MenuItem>
          )}

          {typeId?.status !== "enable" && typeId?.status !== "deleted" && (
            <MenuItem onClick={() => changeTypeStatus(typeId?.id, "enable")}>
              Active
            </MenuItem>
          )}
          {typeId?.status !== "disable" && typeId?.status !== "deleted" && (
            <MenuItem onClick={() => changeTypeStatus(typeId?.id, "disable")}>
              Inactive
            </MenuItem>
          )}
          {typeId?.used < 1 &&
            typeId?.status !== "deleted" &&
            typeId?.system_type !== 1 && (
              <MenuItem onClick={() => {
                setAnchorElForMenu(null);
                setOpenDeleteAlert(true)
              }}>
                Delete
              </MenuItem>
            )}
          {typeId?.status === "deleted" && typeId?.system_type !== 1 && (
            <MenuItem onClick={() => RestoreType(typeId?.id)}>Restore</MenuItem>
          )}
          {typeId?.status === "deleted" && typeId?.system_type !== 1 && (
            <MenuItem onClick={() => {
              setAnchorElForMenu(null);
              setOpenConfirmDeleteAlert(true)
            }}>
              Permanantly Delete
            </MenuItem>
          )}
        </Menu>
      </div>

      <Loader isLoading={loading} />
      <Toaster />
    </>
  );
};

export default Types;
