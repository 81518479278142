import React from "react";
import { Button } from "react-bootstrap";
import {
    Stack,
    Box,
    Typography
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faUpload } from "@fortawesome/free-solid-svg-icons";

const FileComponent = ({ attdata, setOpenMediaModelFor, handleOpenUploadMediaForAtt, handleChangeForFormData }) => {
    return (
        <>
            <Stack gap={2}>
                {attdata?.value ? (
                    <>
                        <div className="upload-img-wrapper-3 d-flex">
                            <Box className="w-25 doc-file-icon-div">
                                <FontAwesomeIcon icon={faFile} className="doc-file-icon" />
                            </Box>
                            <Box className="w-75 media-details-div">
                                <Typography className="media-title">{attdata?.title}</Typography>
                                <Typography className="media-size mt-1">{attdata?.file_size}</Typography>
                            </Box>
                            {/* {attdata?.value !==
                            "" &&
                            `File name: ${attdata?.value
                                ?.name ||
                            attdata?.name
                            }`} */}
                        </div>
                        <Box>
                            <Typography className="remove-img cursor-pointer" onClick={() => {
                                // setFieldValue("file", "")
                                handleChangeForFormData(
                                    attdata?.nameValue,
                                    "",
                                    attdata?.section_id,
                                    "no",
                                )
                            }}>Remove File</Typography>
                        </Box>
                    </>
                ) : (
                    <Button
                        className="btn ternary-btn w-100 mt-3"
                        onClick={() => {
                            setOpenMediaModelFor("attribute")
                            handleOpenUploadMediaForAtt("doc", attdata)
                        }
                        }
                    >
                        <FontAwesomeIcon
                            icon={faUpload}
                        />
                        {attdata?.value
                            ? "Change File"
                            : "Upload File"}
                    </Button>
                )}


            </Stack>
        </>
    )
}

export default FileComponent;