import { createSlice } from "@reduxjs/toolkit";
const initialData = {
  selectedUserTab: 0,
  pagination: {
    page: 1,
    per_page: 10,
    meta_data: null
  }
};

const userTab = createSlice({
  name: "userTabDetails",
  initialState: initialData,
  reducers: {
    handleSelectedTab(state, action) {
      state.selectedUserTab = action?.payload;
    },
    setPagination(state, action) {
      state.pagination = action.payload;
    },
  },
});

export const userTabAction = userTab.actions;
export default userTab.reducer;
