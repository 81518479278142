import React from "react";
import Select from "react-select";

const UserGroupListComponent = ({ attdata, handleChangeForFormData, handleChangeForChildAtts, isChild, parentAttData, handleChangeForAdditionalTextField, isMulti }) => {
    return (
        <>
            <Select
                closeMenuOnSelect={
                    false
                }
                options={
                    attdata?.options
                }
                menuPlacement="auto"
                name={
                    attdata?.nameValue
                }
                placeholder={`Select ${attdata?.label}...`}
                className="muilt-select-field"
                classNamePrefix="select"
                isMulti
                value={attdata?.value}
                onChange={(
                    selectedOption
                ) => {
                    const selectedValues =
                        selectedOption.map(
                            (
                                option
                            ) =>
                                option
                        );

                    if (isChild === true) {
                        handleChangeForChildAtts(
                            attdata?.nameValue,
                            selectedValues,
                            attdata?.section_id,
                            attdata,
                            parentAttData
                        );
                    }
                    else if (isMulti === true) {
                        handleChangeForAdditionalTextField(
                            attdata?.id,
                            selectedValues,
                            parentAttData?.nameValue,
                            parentAttData?.section_id,
                            attdata?.fieldtype
                        );
                    }
                    else {
                        handleChangeForFormData(
                            attdata?.nameValue,
                            selectedValues,
                            attdata?.section_id
                        );
                    }
                    attdata.value =
                        selectedValues;
                }}
                styles={{
                    option:
                        (
                            provided,
                            state
                        ) => {
                            return {
                                ...provided,
                                backgroundColor:
                                    state.isSelected
                                        ? "#660c605c"
                                        : provided.backgroundColor,
                                color:
                                    state.isSelected
                                        ? "var(--dark-color)"
                                        : provided.color,
                            };
                        },
                }}
            />
        </>
    )
}

export default UserGroupListComponent;