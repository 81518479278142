import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const RadioSingleChoice = ({
    attdata,
    handleChangeForFormData,
    handleChangeForChildAtts,
    isChild,
    parentAttData,
    handleChangeForAdditionalTextField,
    isMulti,
}) => {
    return (
        <>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name={attdata?.nameValue}
                className=""
                value={attdata?.value}
                onChange={(event) => {
                    if (isChild === true) {
                        handleChangeForChildAtts(
                            attdata?.nameValue,
                            parseInt(event?.target?.value, 10),
                            attdata?.section_id,
                            attdata,
                            parentAttData
                        );
                    } else if (isMulti === true) {
                        handleChangeForAdditionalTextField(
                            attdata?.id,
                            parseInt(event?.target?.value, 10),
                            parentAttData?.nameValue,
                            parentAttData?.section_id,
                            attdata?.fieldtype
                        );
                    } else {
                        handleChangeForFormData(
                            attdata?.nameValue,
                            parseInt(event?.target?.value, 10),
                            attdata?.section_id
                        );
                    }
                }}
            >
                {attdata?.options?.map((attribute) => (
                    <FormControlLabel
                        key={attribute?.id}
                        value={attribute?.id}
                        control={
                            <Radio
                                size="small"
                                checked={attdata?.value === attribute?.value}
                                value={attribute?.value}
                                sx={{ padding: 0 }}
                            />
                        }
                        label={attribute?.label}
                        className="radio-group-label"
                    />
                ))}
            </RadioGroup>
        </>
    );
};

export default RadioSingleChoice;
