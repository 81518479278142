import React, { useEffect, useState, useRef, useCallback } from "react";

import { Mention, MentionsInput } from 'react-mentions'
import mentionsInputStyle from "../../../helpers/styles/header/mentionsInputStyle";
import mentionStyle from "../../../helpers/styles/header/mentionStyle";
import api from '../commonFunctionForApi';
import { Toast } from '../../../utils/toast';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import logoutHelper from '../../../helpers/logoutHelper';
import { debounce } from 'lodash';

function QuickMessageInput(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { typedMessage, setTypedMessage, handleTyping, handleKeyDown, chatRoomId, borderOnSendMessage } = props;

  //All the states
  const [getMentionUsers, setGetMentionUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');


  //Api calls
  const fetchMentionUsers = async (text) => {
    try {
      const userToken = localStorage.getItem("accessToken");

      const response = await api.post(`user/conversation/mention-users`, {
        'chat_room_id': chatRoomId,
        'search': text
      }, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        const allUsers = response?.response?.data?.map((data) => {
          return {
            id: data?.user_id,
            display: `${data?.name} ${data?.last_name}`,
            photo: data?.thumbnail,
          }
        })
        setGetMentionUsers(allUsers)
      } else {
        Toast.error(response?.message);
      }

    } catch (err) {
      console.error("error in ctreate news api::", err);

      if (err?.response?.status == 401) {
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
    }
  }

  const debouncedFetchMentionUsers = debounce((text) => {
    fetchMentionUsers(text);
  }, 200)

  const fetchUserchange = (e) => {
    const regex = /@([\w\s]+)$/;
    if (regex.test(e)) {
      const cleanedText = e.match(regex)[1]; // Extract the matched text without @
      setSearchTerm(cleanedText);
    }
  }

  useEffect(() => {

    if (searchTerm) {
      debouncedFetchMentionUsers(searchTerm);
    }

    return () => {
      debouncedFetchMentionUsers.cancel();
    };
  }, [searchTerm]);

  useEffect(() => {
    fetchMentionUsers('');
  }, []);

  return (
    <MentionsInput
      value={typedMessage}
      onChange={(e) => {
        fetchUserchange(e.target.value)
        setTypedMessage(e.target.value)
        handleTyping()
      }}
      className={`quick-chat-input w-100 ${borderOnSendMessage == true && "border-send-message-div"}`}
      allowSpaceInQuery={true}
      allowSuggestionsAboveCursor={true}
      forceSuggestionsAboveCursor={true}
      // suggestionsPortalHost={document.getElementById('popover-container')}
      suggestionsPortalHost={document.body}
      onKeyDown={handleKeyDown}
      style={mentionsInputStyle}
      placeholder={"Quick Respond..."}
      a11ySuggestionsListLabel={"Suggested mentions"}
    >
      <Mention trigger="@"
        data={getMentionUsers} // Pass in the mention data (array of objects with id and display properties)
        style={mentionStyle}
        renderSuggestion={(
          suggestion,
          search,
          highlightedDisplay,
          index,
          focused
        ) => (
          <div
            className="list-class"
            key={index}
          >
            <div className="list-class-div-1">
              <img
                src={suggestion.photo}
                alt={suggestion.display}
                className="list-class-div-1-img"
              />
              <div className="list-class-div-2">
                <span style={{ fontWeight: 'bold' }}>{suggestion?.display}</span>
                <br />
              </div>
            </div>

          </div>
        )} />
    </MentionsInput>

  )
}

export default QuickMessageInput