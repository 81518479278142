import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Form } from "react-bootstrap";
import {
    Box,
    Breadcrumbs,
    Link,
    Typography,
    Divider,
    Button,
    Stack,
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faCheck,
    faCircleInfo,
    faFloppyDisk,
    faTrash,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Toast } from "../../utils/toast";
import Loader from "../../components/common/loader";
import { useParams } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import Select from "react-select";
import api from "../../components/common/commonFunctionForApi";
import logoutHelper from "../../helpers/logoutHelper";
import Accordion from "react-bootstrap/Accordion";
import { BootstrapTooltip } from "../../components/common/tooltip/bootstrapTooltip";
import { AntSwitch } from "../../components/common/switch/antSwitch";
import "bootstrap-daterangepicker/daterangepicker.css";
import { parseInt } from "lodash";
import "../../styles/insight.scss"
import CloseIcon from "@mui/icons-material/Close";

const insightTypeOptions = [
    { value: "user", label: "User" },
    { value: "post", label: "Post" }
]

const insightWhenShowDateOptions = [
    { value: 0, label: "On Day" },
    ...Array.from({ length: 30 }, (_, i) => ({
        value: i + 1,
        label: `${i + 1} Day${i + 1 > 1 ? "s" : ""} Before`,
    }))
];

const insightWhenShowHourOptions = [
    ...Array.from({ length: 12 }, (_, i) => {
        const value = (i + 1).toString().padStart(2, "0");
        return {
            value,
            label: value
        };
    })
];

const insightWhenShowMinuteOptions = [
    ...Array.from({ length: 12 }, (_, i) => {
        const value = (i * 5).toString().padStart(2, "0");
        return { value, label: value };
    })
];

const insightWhenShowAMPMOptions = [
    { value: "AM", label: "AM" },
    { value: "PM", label: "PM" },
]

const insightWhenShowOutputOptions = [
    { value: "feed", label: "Show on Dashboard" },
    { value: "chat", label: "Send Direct Chat Message" },
    { value: "feed_chat", label: "Both (Dashboard + Chat Message)" }
]

const AddInsight = () => {
    let { id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [isWarningMessage, setIsWarningMessage] = useState(false);
    const [warningMessage, setWarningMessage] = useState([]);

    const [isSuccessMessage, setIsSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [insightAttrbuteOptions, setInsightAttributeOptions] = useState([])

    const [insightName, setInsightName] = useState("")
    const [insightLabel, setInsightLabel] = useState("")
    const [insightType, setInsightType] = useState("")
    const [insightAttribute, setInsightAttribute] = useState("")
    const [insightWhenDate, setInsightWhenDate] = useState("")
    const [insightWhenHour, setInsightWhenHour] = useState("")
    const [insightWhenMinute, setInsightWhenMinute] = useState("")
    const [insightWhenAMPM, setInsightWhenAMPM] = useState("")
    const [insightOutput, setInsightOutput] = useState("")
    const [insightText, setInsightText] = useState("")
    const [insightErrors, setInsightErrors] = useState({})
    const [insightStatus, setInsightStatus] = useState(true)
    const [openDeleteTypeAlert, setOpenDeleteTypeAlert] = useState(false)

    const { open } = useSelector((state) => state?.drawerMenu);

    const userToken = localStorage.getItem("accessToken");
    const user = localStorage.getItem("user");
    const loggedUser = JSON.parse(user);

    const handleCloseSuccessMessage = () => {
        setIsSuccessMessage(false);
        setIsSuccessMessage("");
    };

    const handleCloseWarningMessage = (index) => {
        const updatedMessages = [...warningMessage];
        updatedMessages.splice(index, 1);
        setWarningMessage(updatedMessages);
    };

    const clearAll = () => {
        setInsightName("")
        setInsightLabel("")
        setInsightType("")
        setInsightAttributeOptions([])
        setInsightAttribute("")
        setInsightWhenDate("")
        setInsightWhenHour("")
        setInsightWhenMinute("")
        setInsightWhenAMPM("")
        setInsightOutput("")
        setInsightText("")
        setInsightErrors({})
        setInsightStatus(true)
    }

    const handleCloseDeleteTypeModel = () => {
        setOpenDeleteTypeAlert(false)
    }

    const validateFields = () => {
        const errors = {};
        if (!insightName.trim()) {
            errors.name = "Insight Name is required."
        }
        if (!insightLabel.trim()) {
            errors.label = "Insight Label is required."
        }
        if (!insightType.trim()) {
            errors.type = "Insight Type is required."
        }
        if (insightAttribute === "") {
            errors.attribute = "Insight Attribute is required."
        }
        if (insightWhenDate === "") {
            errors.date = "When to Show Insight Date is required."
        }
        if (insightWhenHour === "") {
            errors.timeHour = "Insight Time Hour is required."
        }
        if (!insightWhenMinute.trim()) {
            errors.timeMinute = "Insight Time Minute is required."
        }
        if (!insightWhenAMPM.trim()) {
            errors.timeAMPM = "Insight Time AM/PM is required."
        }
        if (!insightOutput.trim()) {
            errors.output = "Insight Output is required."
        }
        if (!insightText.trim()) {
            errors.text = "Insight Text is required."
        }
        return errors
    }

    const onSubmit = async () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setInsightErrors(validationErrors); // Display errors if validation fails
        } else {
            console.log("submited success")
            let data = {
                name: insightName,
                label: insightLabel,
                post_type: insightType,
                attribute_id: insightAttribute,
                action_when: insightWhenDate,
                action_time: `${insightWhenHour}:${insightWhenMinute} ${insightWhenAMPM}`,
                output_type: insightOutput,
                display_text: insightText,
                status: insightStatus === true ? "enable" : "disable"
            }
            console.log("data for submit::", data)
            if (id) {
                try {
                    setLoading(true);
                    const response = await api.post(
                        `/admin/insight/update/${id}`,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${userToken}`,
                            },
                        }
                    );
                    if (response?.success === true) {
                        setLoading(false);
                        clearAll()
                        Toast.success(response?.message);
                        setSuccessMessage(response?.message)
                        localStorage.setItem("last-redirect-page", `/insight`);
                        navigate("/insight");

                    } else if (response?.success === false) {
                        setLoading(false);
                        setIsWarningMessage(true);
                        setWarningMessage(response?.message);
                        Toast.error(response?.message);
                    }
                } catch (err) {
                    console.log("error in ctreate news api::", err);
                    setLoading(false);

                    if (err?.response?.status === 401) {
                        setLoading(false);
                        logoutHelper(dispatch, navigate, userToken);
                    }
                    if (err?.response?.status === 422) {
                        setLoading(false);
                        setIsWarningMessage(true);
                        setWarningMessage(err?.response?.data?.errors?.name);
                        Object.values(
                            err?.response?.data?.errors
                        ).forEach((value) => Toast.error(value));
                    }
                    if (err?.response?.status === 429) {
                        Toast.error("Too many attempts wait for 2 min.");
                    }
                }
            } else {
                try {
                    setLoading(true);
                    const response = await api.post(
                        "/admin/insight/store",
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${userToken}`,
                            },
                        }
                    );
                    if (response?.success === true) {
                        setLoading(false);
                        clearAll()
                        setSuccessMessage(response?.message)
                        Toast.success(response?.message);
                        localStorage.setItem("last-redirect-page", `/insight`);
                        navigate("/insight");

                    } else if (response?.success === false) {
                        setLoading(false);
                        setIsWarningMessage(true);
                        setWarningMessage(response?.message);
                        Toast.error(response?.message);
                    }
                } catch (err) {
                    console.log("error in ctreate news api::", err);
                    setLoading(false);

                    if (err?.response?.status === 401) {
                        setLoading(false);
                        logoutHelper(dispatch, navigate, userToken);
                    }
                    if (err?.response?.status === 422) {
                        setLoading(false);
                        setIsWarningMessage(true);
                        setWarningMessage(err?.response?.data?.errors?.name);
                        Object.values(
                            err?.response?.data?.errors
                        ).forEach((value) => Toast.error(value));
                    }
                    if (err?.response?.status === 429) {
                        Toast.error("Too many attempts wait for 2 min.");
                    }
                }
            }
        }

    }

    const fetchInsightAttributeList = async () => {
        try {
            setLoading(true);
            const response = await api.get(`admin/insight/attribute-list/${insightType}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success === true) {
                const optiondata = response?.response?.data?.map((data) => {
                    return {
                        value: data?.id,
                        label: data?.attribute_name
                    }
                })
                setInsightAttributeOptions(optiondata)
                setLoading(false);
            } else if (response?.success === false) {
                setLoading(false);
                Toast.warning(response?.message);
                localStorage.setItem("last-redirect-page", `/`);
                navigate("/");
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/`);
                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data[0]?.message);
                Toast.warning(err?.response?.data[0]?.message);
                setWarningMessage(err?.response?.data?.message);
                Toast.warning(err?.response?.data?.message);
                localStorage.setItem("last-redirect-page", `/`);
                navigate("/");
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
        }
    }

    const fetchInsightDetails = async () => {
        try {
            setLoading(true);
            const response = await api.get(`admin/insight/edit/${id}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success === true) {
                setInsightName(response?.response?.data?.name)
                setInsightLabel(response?.response?.data?.label)
                setInsightType(response?.response?.data?.post_type)
                setInsightAttribute(parseInt(response?.response?.data?.attribute_id))
                setInsightWhenDate(parseInt(response?.response?.data?.action_when))
                setInsightWhenHour(response?.response?.data?.hour)
                setInsightWhenMinute(response?.response?.data?.minute)
                setInsightWhenAMPM(response?.response?.data?.ampm)
                setInsightOutput(response?.response?.data?.output_type)
                setInsightText(response?.response?.data?.display_text)
                setInsightStatus(response?.response?.data?.status === "enable" ? true : false)
                setLoading(false);
            } else if (response?.success === false) {
                setLoading(false);
                Toast.warning(response?.message);
                localStorage.setItem("last-redirect-page", `/`);
                navigate("/");
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data[0]?.message);
                Toast.warning(err?.response?.data[0]?.message);
                setWarningMessage(err?.response?.data?.message);
                Toast.warning(err?.response?.data?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
        }
    }

    const deleteInsightAPI = async () => {
        try {
            setLoading(true);
            const response = await api.delete(`admin/insight/delete/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success === true) {
                setLoading(false);
                setOpenDeleteTypeAlert(false)
                setIsSuccessMessage(true);
                setSuccessMessage(response?.message);
                Toast.success(response?.message);
                setLoading(false);
                navigate("/insight")
            }
            else if (response?.success === false) {
                setLoading(false)
                Toast.warning(response?.message)
                setIsWarningMessage(true)
                setWarningMessage(response?.message)
            }
        } catch (err) {
            if (err?.response?.status === 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        if (insightType !== "") {
            fetchInsightAttributeList()
        }
    }, [insightType])

    useEffect(() => {
        if (id) {
            fetchInsightDetails()
        }
    }, [id])

    return (
        <>
            <div
                className={`main-content-wrapper-2 ${open === true ? "drawer-open" : "drawer-close"
                    }  ${window.innerWidth <= 768 && "small-screen"}`}
            >
                <Container
                    className={`${window.innerWidth <= 768 && "small-screen-container"}`}
                >
                    {isSuccessMessage && (
                        <SuccessAlert
                            message={successMessage}
                            onClose={handleCloseSuccessMessage}
                        />
                    )}

                    {isWarningMessage && (
                        <>
                            {warningMessage?.map((message, index) => (
                                <>
                                    <WarningAlert
                                        message={message}
                                        onClose={() => handleCloseWarningMessage(index)}
                                    />
                                </>
                            ))}
                        </>
                    )}
                    <Box className="">
                        <Stack spacing={2}>
                            <Breadcrumbs
                                separator={<NavigateNextIcon fontSize="small" />}
                                aria-label="breadcrumb"
                            >
                                <Link
                                    underline="hover"
                                    key="1"
                                    color="inherit"
                                    href="/"
                                    component="h3"
                                >
                                    Admin
                                </Link>
                                <Typography
                                    key="3"
                                    component="h3"
                                    color="inherit"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        localStorage.setItem("last-redirect-page", `/insight`);
                                        navigate("/insight");
                                    }}
                                >
                                    Insight
                                </Typography>
                                <Typography key="3" component="h3" color="text.primary">
                                    {id ? "Edit" : "Add"} Insight
                                </Typography>
                            </Breadcrumbs>
                        </Stack>
                    </Box>
                    <Divider className="mt-4" style={{ background: "#EEEEEE" }} />

                    <Box className="publish-bar-box">
                        <Box className="delete-div-box">

                            {id && (
                                <Box className="d-flex gap-2 align-items-center justify-content-center cursor-pointer" onClick={() => {
                                    setOpenDeleteTypeAlert(true);
                                }}>
                                    <FontAwesomeIcon icon={faTrash} className="delete-div-box-icon" />
                                    <Typography className="delete-div-box-text">Delete</Typography>
                                </Box>
                            )}

                        </Box>
                        <Box className="last-div-box">
                            <Box className="publish-btn-div-box">
                                <Button
                                    className="btn primary-btn w-100"
                                    type="submit"
                                    onClick={() => {
                                        onSubmit()
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        className="d-md-block d-sm-none d-none"
                                    />
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        className="d-md-none d-sm-block"
                                    />
                                    Publish
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Row className="mt-1 form-row">
                        <Col lg="12" md="12" className="form-col">
                            <Stack direction="row" className="gap-30">
                                <Accordion
                                    defaultActiveKey={["0", "1", "2", "3"]}
                                    alwaysOpen
                                    className="w-100"
                                >
                                    <Accordion.Item
                                        eventKey="0"
                                        className="bg-white card-wrapper w-100"
                                    >
                                        <Accordion.Header className="bg-white fw-700 primary-text">
                                            Content
                                        </Accordion.Header>
                                        <Accordion.Body className="">
                                            <Box
                                                sx={{
                                                    borderBottom: 1,
                                                    borderColor: "divider",
                                                }}
                                                className="box-attribute"
                                            >
                                                <Row>
                                                    <Col lg="12" className="col-insight-data">
                                                        <Typography className="fw-400 dark-text">
                                                            Insight Name
                                                            <span className="required">
                                                                *
                                                            </span>
                                                        </Typography>
                                                        <TextField
                                                            className="input-field mt-2"
                                                            fullWidth
                                                            id="outlined-basic username"
                                                            variant="outlined"
                                                            size="small"
                                                            name="title"
                                                            placeholder="Enter insight name here..."
                                                            value={
                                                                insightName
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                setInsightName(event?.target?.value)
                                                                if (event.target.value.trim()) {
                                                                    setInsightErrors((prevErrors) => ({ ...prevErrors, name: "" }));
                                                                }
                                                            }}

                                                            helperText={
                                                                insightErrors.name
                                                            }
                                                            error={
                                                                Boolean(
                                                                    insightErrors.name
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                    <Col lg="12" className="col-insight-data">
                                                        <Typography className="fw-400 dark-text">
                                                            Insight Label
                                                            <span className="required">
                                                                *
                                                            </span>
                                                        </Typography>
                                                        <TextField
                                                            className="input-field mt-2"
                                                            fullWidth
                                                            id="outlined-basic username"
                                                            variant="outlined"
                                                            size="small"
                                                            name="title"
                                                            placeholder="Enter insight label here..."
                                                            value={
                                                                insightLabel
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                setInsightLabel(event?.target?.value)
                                                                if (event.target.value.trim()) {
                                                                    setInsightErrors((prevErrors) => ({ ...prevErrors, label: "" }));
                                                                }
                                                            }}

                                                            helperText={
                                                                insightErrors.label
                                                            }
                                                            error={
                                                                Boolean(
                                                                    insightErrors.label
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                    <Col lg="12" className="col-insight-data-2">
                                                        <Typography className="fw-400 dark-text">
                                                            Insight Type
                                                            <span className="required">
                                                                *
                                                            </span>
                                                        </Typography>
                                                        <Select
                                                            options={insightTypeOptions}
                                                            menuPlacement="bottom"
                                                            name="organisationManagers"
                                                            placeholder="Select insight type..."
                                                            menuPosition="fixed"
                                                            onChange={(selectedOption) => {
                                                                setInsightType(selectedOption?.value)
                                                                if (selectedOption) {
                                                                    setInsightErrors((prevErrors) => ({ ...prevErrors, type: "" }));
                                                                }
                                                            }}
                                                            value={insightTypeOptions.find(
                                                                (option) =>
                                                                    option.value === insightType
                                                            )}
                                                            className="mt-2"
                                                            classNamePrefix="select"
                                                            styles={{
                                                                option: (provided, state) => ({
                                                                    ...provided,
                                                                    backgroundColor: state.isSelected
                                                                        ? "#660c605c"
                                                                        : provided.backgroundColor,
                                                                    color: state.isSelected
                                                                        ? "var(--dark-color)"
                                                                        : provided.color,
                                                                }),
                                                                multiValueLabel: (provided, state) => {
                                                                    return {
                                                                        ...provided,
                                                                        color:
                                                                            state.data?.is_system === 1
                                                                                ? "rgb(0, 135, 90)"
                                                                                : "rgb(0, 82, 204)",
                                                                    };
                                                                },
                                                            }}
                                                        />
                                                        {insightErrors?.type && (
                                                            <p
                                                                style={{
                                                                    color: "#d32f2f",
                                                                }}
                                                                className="error-msg"
                                                            >
                                                                {insightErrors?.type}
                                                            </p>
                                                        )}
                                                    </Col>
                                                    <Col lg="12" className="col-insight-data-2">
                                                        <Typography className="fw-400 dark-text">
                                                            Select Attribute
                                                            <span className="required">
                                                                *
                                                            </span>
                                                        </Typography>
                                                        <Select
                                                            options={insightAttrbuteOptions}
                                                            menuPlacement="bottom"
                                                            name="organisationManagers"
                                                            placeholder="Select attriubute..."
                                                            menuPosition="fixed"
                                                            onChange={(selectedOption) => {
                                                                setInsightAttribute(selectedOption?.value)
                                                                if (selectedOption) {
                                                                    setInsightErrors((prevErrors) => ({ ...prevErrors, attribute: "" }));
                                                                }
                                                            }}
                                                            value={insightAttrbuteOptions.find(
                                                                (option) =>
                                                                    option.value === insightAttribute
                                                            )}
                                                            className="mt-2"
                                                            classNamePrefix="select"
                                                            styles={{
                                                                option: (provided, state) => ({
                                                                    ...provided,
                                                                    backgroundColor: state.isSelected
                                                                        ? "#660c605c"
                                                                        : provided.backgroundColor,
                                                                    color: state.isSelected
                                                                        ? "var(--dark-color)"
                                                                        : provided.color,
                                                                }),
                                                                multiValueLabel: (provided, state) => {
                                                                    return {
                                                                        ...provided,
                                                                        color:
                                                                            state.data?.is_system === 1
                                                                                ? "rgb(0, 135, 90)"
                                                                                : "rgb(0, 82, 204)",
                                                                    };
                                                                },
                                                            }}
                                                        />
                                                        {insightErrors?.attribute && (
                                                            <p
                                                                style={{
                                                                    color: "#d32f2f",
                                                                }}
                                                                className="error-msg"
                                                            >
                                                                {insightErrors?.attribute}
                                                            </p>
                                                        )}
                                                    </Col>
                                                    <Col lg="12" className="col-insight-data-2">
                                                        <Typography className="fw-400 dark-text">
                                                            When to Show Insight Date
                                                            <span className="required">
                                                                *
                                                            </span>
                                                        </Typography>
                                                        <Select
                                                            options={insightWhenShowDateOptions}
                                                            menuPlacement="bottom"
                                                            name="organisationManagers"
                                                            placeholder="Select when to show insight date..."
                                                            menuPosition="fixed"
                                                            onChange={(selectedOption) => {
                                                                setInsightWhenDate(selectedOption?.value)
                                                                if (selectedOption) {
                                                                    setInsightErrors((prevErrors) => ({ ...prevErrors, date: "" }));
                                                                }
                                                            }}
                                                            value={insightWhenShowDateOptions.find(
                                                                (option) =>
                                                                    option.value === insightWhenDate
                                                            )}
                                                            className="mt-2"
                                                            classNamePrefix="select"
                                                            styles={{
                                                                option: (provided, state) => ({
                                                                    ...provided,
                                                                    backgroundColor: state.isSelected
                                                                        ? "#660c605c"
                                                                        : provided.backgroundColor,
                                                                    color: state.isSelected
                                                                        ? "var(--dark-color)"
                                                                        : provided.color,
                                                                }),
                                                                multiValueLabel: (provided, state) => {
                                                                    return {
                                                                        ...provided,
                                                                        color:
                                                                            state.data?.is_system === 1
                                                                                ? "rgb(0, 135, 90)"
                                                                                : "rgb(0, 82, 204)",
                                                                    };
                                                                },
                                                            }}
                                                        />
                                                        {insightErrors?.date && (
                                                            <p
                                                                style={{
                                                                    color: "#d32f2f",
                                                                }}
                                                                className="error-msg"
                                                            >
                                                                {insightErrors?.date}
                                                            </p>
                                                        )}
                                                    </Col>
                                                    <Col lg="12" className="col-insight-data-2">
                                                        <Typography className="fw-400 dark-text">
                                                            When to Show Insight time
                                                            <span className="required">
                                                                *
                                                            </span>
                                                        </Typography>
                                                        <Box className="d-flex gap-3 w-100">
                                                            <Box className="w-33">
                                                                <Select
                                                                    options={insightWhenShowHourOptions}
                                                                    menuPlacement="bottom"
                                                                    name="organisationManagers"
                                                                    placeholder="Select hour..."
                                                                    menuPosition="fixed"
                                                                    onChange={(selectedOption) => {
                                                                        setInsightWhenHour(selectedOption?.value)
                                                                        if (selectedOption) {
                                                                            setInsightErrors((prevErrors) => ({ ...prevErrors, timeHour: "" }));
                                                                        }
                                                                    }}
                                                                    value={insightWhenShowHourOptions.find(
                                                                        (option) =>
                                                                            option.value === insightWhenHour
                                                                    )}
                                                                    className="mt-2 "
                                                                    classNamePrefix="select"
                                                                    styles={{
                                                                        option: (provided, state) => ({
                                                                            ...provided,
                                                                            backgroundColor: state.isSelected
                                                                                ? "#660c605c"
                                                                                : provided.backgroundColor,
                                                                            color: state.isSelected
                                                                                ? "var(--dark-color)"
                                                                                : provided.color,
                                                                        }),
                                                                        multiValueLabel: (provided, state) => {
                                                                            return {
                                                                                ...provided,
                                                                                color:
                                                                                    state.data?.is_system === 1
                                                                                        ? "rgb(0, 135, 90)"
                                                                                        : "rgb(0, 82, 204)",
                                                                            };
                                                                        },
                                                                    }}
                                                                />
                                                                {insightErrors?.timeHour && (
                                                                    <p
                                                                        style={{
                                                                            color: "#d32f2f",
                                                                        }}
                                                                        className="error-msg"
                                                                    >
                                                                        {insightErrors?.timeHour}
                                                                    </p>
                                                                )}
                                                            </Box>
                                                            <Box className="w-33">
                                                                <Select
                                                                    options={insightWhenShowMinuteOptions}
                                                                    menuPlacement="bottom"
                                                                    name="organisationManagers"
                                                                    placeholder="Select min..."
                                                                    menuPosition="fixed"
                                                                    onChange={(selectedOption) => {
                                                                        setInsightWhenMinute(selectedOption?.value)
                                                                        if (selectedOption) {
                                                                            setInsightErrors((prevErrors) => ({ ...prevErrors, timeMinute: "" }));
                                                                        }
                                                                    }}
                                                                    value={insightWhenShowMinuteOptions.find(
                                                                        (option) =>
                                                                            option.value === insightWhenMinute
                                                                    )}
                                                                    className="mt-2"
                                                                    classNamePrefix="select"
                                                                    styles={{
                                                                        option: (provided, state) => ({
                                                                            ...provided,
                                                                            backgroundColor: state.isSelected
                                                                                ? "#660c605c"
                                                                                : provided.backgroundColor,
                                                                            color: state.isSelected
                                                                                ? "var(--dark-color)"
                                                                                : provided.color,
                                                                        }),
                                                                        multiValueLabel: (provided, state) => {
                                                                            return {
                                                                                ...provided,
                                                                                color:
                                                                                    state.data?.is_system === 1
                                                                                        ? "rgb(0, 135, 90)"
                                                                                        : "rgb(0, 82, 204)",
                                                                            };
                                                                        },
                                                                    }}
                                                                />
                                                                {insightErrors?.timeMinute && (
                                                                    <p
                                                                        style={{
                                                                            color: "#d32f2f",
                                                                        }}
                                                                        className="error-msg"
                                                                    >
                                                                        {insightErrors?.timeMinute}
                                                                    </p>
                                                                )}
                                                            </Box>
                                                            <Box className="w-33">
                                                                <Select
                                                                    options={insightWhenShowAMPMOptions}
                                                                    menuPlacement="bottom"
                                                                    name="organisationManagers"
                                                                    placeholder="Select am/pm..."
                                                                    menuPosition="fixed"
                                                                    onChange={(selectedOption) => {
                                                                        setInsightWhenAMPM(selectedOption?.value)
                                                                        if (selectedOption) {
                                                                            setInsightErrors((prevErrors) => ({ ...prevErrors, timeAMPM: "" }));
                                                                        }
                                                                    }}
                                                                    value={insightWhenShowAMPMOptions.find(
                                                                        (option) =>
                                                                            option.value === insightWhenAMPM
                                                                    )}
                                                                    className="mt-2"
                                                                    classNamePrefix="select"
                                                                    styles={{
                                                                        option: (provided, state) => ({
                                                                            ...provided,
                                                                            backgroundColor: state.isSelected
                                                                                ? "#660c605c"
                                                                                : provided.backgroundColor,
                                                                            color: state.isSelected
                                                                                ? "var(--dark-color)"
                                                                                : provided.color,
                                                                        }),
                                                                        multiValueLabel: (provided, state) => {
                                                                            return {
                                                                                ...provided,
                                                                                color:
                                                                                    state.data?.is_system === 1
                                                                                        ? "rgb(0, 135, 90)"
                                                                                        : "rgb(0, 82, 204)",
                                                                            };
                                                                        },
                                                                    }}
                                                                />
                                                                {insightErrors?.timeAMPM && (
                                                                    <p
                                                                        style={{
                                                                            color: "#d32f2f",
                                                                        }}
                                                                        className="error-msg"
                                                                    >
                                                                        {insightErrors?.timeAMPM}
                                                                    </p>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    </Col>
                                                    <Col lg="12" className="col-insight-data-2">
                                                        <Typography className="fw-400 dark-text">
                                                            Show Output
                                                            <span className="required">
                                                                *
                                                            </span>
                                                        </Typography>
                                                        <Select
                                                            options={insightWhenShowOutputOptions}
                                                            menuPlacement="bottom"
                                                            name="organisationManagers"
                                                            placeholder="Select output..."
                                                            menuPosition="fixed"
                                                            onChange={(selectedOption) => {
                                                                setInsightOutput(selectedOption?.value)
                                                                if (selectedOption) {
                                                                    setInsightErrors((prevErrors) => ({ ...prevErrors, output: "" }));
                                                                }
                                                            }}
                                                            value={insightWhenShowOutputOptions.find(
                                                                (option) =>
                                                                    option.value === insightOutput
                                                            )}
                                                            className="mt-2"
                                                            classNamePrefix="select"
                                                            styles={{
                                                                option: (provided, state) => ({
                                                                    ...provided,
                                                                    backgroundColor: state.isSelected
                                                                        ? "#660c605c"
                                                                        : provided.backgroundColor,
                                                                    color: state.isSelected
                                                                        ? "var(--dark-color)"
                                                                        : provided.color,
                                                                }),
                                                                multiValueLabel: (provided, state) => {
                                                                    return {
                                                                        ...provided,
                                                                        color:
                                                                            state.data?.is_system === 1
                                                                                ? "rgb(0, 135, 90)"
                                                                                : "rgb(0, 82, 204)",
                                                                    };
                                                                },
                                                            }}
                                                        />
                                                        {insightErrors?.output && (
                                                            <p
                                                                style={{
                                                                    color: "#d32f2f",
                                                                }}
                                                                className="error-msg"
                                                            >
                                                                {insightErrors?.output}
                                                            </p>
                                                        )}
                                                    </Col>
                                                    <Col lg="12" className="col-insight-data">
                                                        <Typography className="fw-400 dark-text">
                                                            Text To Display
                                                            <span className="required">
                                                                *
                                                            </span>
                                                        </Typography>

                                                        <Form.Control
                                                            as="textarea" // To make it multiline
                                                            rows={2}       // Minimum rows
                                                            name="profileBio"
                                                            placeholder="Enter text to display..."
                                                            value={
                                                                insightText
                                                            }
                                                            onChange={(event) => {
                                                                setInsightText(event?.target?.value)
                                                                if (event.target.value.trim()) {
                                                                    setInsightErrors((prevErrors) => ({ ...prevErrors, text: "" }));
                                                                }
                                                            }}
                                                            className="text-area-field mt-2"
                                                        />
                                                        {insightErrors?.text && (
                                                            <p
                                                                style={{
                                                                    color: "#d32f2f",
                                                                }}
                                                                className="error-msg"
                                                            >
                                                                {insightErrors?.text}
                                                            </p>
                                                        )}
                                                    </Col>
                                                    <Col lg="12">
                                                        <Stack
                                                            direction="row"
                                                            gap={2}
                                                        >
                                                            <div className="d-flex align-items-center gap-2">
                                                                <Typography
                                                                    className="fw-400 dark-text font-inter "
                                                                    whiteSpace="nowrap"
                                                                >
                                                                    Enable Insight
                                                                </Typography>
                                                                <BootstrapTooltip
                                                                    title="You can enable/disable insight."
                                                                    placement="bottom"
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faCircleInfo}
                                                                        className="mandatory-icon fw-900"
                                                                        aria-haspopup="true"
                                                                    />
                                                                </BootstrapTooltip>
                                                            </div>
                                                            <AntSwitch
                                                                name="userStatus"
                                                                checked={insightStatus}
                                                                onChange={() => {
                                                                    setInsightStatus(!insightStatus)
                                                                }}
                                                                inputProps={{
                                                                    "aria-label": "ant design",
                                                                }}
                                                            />
                                                        </Stack>
                                                    </Col>
                                                </Row>
                                            </Box>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Stack>
                        </Col>
                    </Row>
                </Container>
                <Dialog
                    aria-labelledby="customized-dialog-title"
                    open={openDeleteTypeAlert}
                    maxWidth="sm"
                    fullWidth={true}
                >
                    <DialogTitle
                        sx={{ m: 0, p: 2 }}
                        id="customized-dialog-title"
                        className="dialog-title"
                    >
                        Are you Sure ?
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDeleteTypeModel}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        className="alert-close-btn"
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent
                        dividers
                    >
                        <DialogContentText id="alert-dialog-description">
                            Are you sure want to delete the insight ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
                        <Button
                            className="btn delete-btn-model"
                            onClick={handleCloseDeleteTypeModel}
                        >
                            <FontAwesomeIcon icon={faXmark} />
                            No
                        </Button>
                        <Button
                            className="btn primary-btn"
                            onClick={() => {
                                deleteInsightAPI()
                            }
                            }
                        >
                            <FontAwesomeIcon icon={faFloppyDisk} />
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Loader isLoading={loading} />
                <Toaster />
            </div>
        </>
    )
}

export default AddInsight