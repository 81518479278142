import React from "react";
import { TextField } from "@mui/material";

const LinkObjectComponent = ({
    attdata,
    handleChangeForFormData,
    index,
    handleChangeForChildAtts,
    isChild,
    parentAttData,
    handleChangeForAdditionalTextField,
    isMulti
}) => {
    return (
        <>
            <TextField
                className="input-field"
                fullWidth
                id={`outlined-basic-title-${index}`}
                variant="outlined"
                name={attdata?.nameValue}
                placeholder={`Enter ${attdata?.label} Title...`}
                value={attdata?.value?.title || ""}
                onChange={(event) => {
                    if (isChild === true) {
                    }
                    else if (isMulti === true) {
                        handleChangeForAdditionalTextField(
                            attdata?.id,
                            event
                                ?.target
                                ?.value,
                            parentAttData?.nameValue,
                            parentAttData?.section_id,
                            attdata?.fieldtype,
                            "title"
                        )
                    }
                    else {
                        handleChangeForFormData(
                            attdata?.nameValue,
                            event?.target?.value,
                            attdata?.section_id,
                            false,
                            "title", // Specify this is the title subfield
                            false
                        );
                    }
                }}
            />

            {/* {attdata?.is_mandatory && showBelowError && !attdata?.value?.title && (
                <p style={{ color: "#d32f2f" }} className="error-msg">
                    {`${attdata?.label} Title is required.`}
                </p>
            )} */}
            <TextField
                className="input-field mt-2"
                fullWidth
                id={`outlined-basic-url-${index}`}
                variant="outlined"
                name={attdata?.nameValue}
                placeholder={`Enter ${attdata?.label} URL...`}
                value={attdata?.value?.url || ""}
                onChange={(event) => {
                    if (isChild === true) {
                    }
                    else if (isMulti === true) {
                        handleChangeForAdditionalTextField(
                            attdata?.id,
                            event
                                ?.target
                                ?.value,
                            parentAttData?.nameValue,
                            parentAttData?.section_id,
                            attdata?.fieldtype,
                            "url"
                        )
                    } else {
                        handleChangeForFormData(
                            attdata?.nameValue,
                            event?.target?.value,
                            attdata?.section_id,
                            false,
                            "url", // Specify this is the url subfield
                            false
                        )
                    }
                }
                }
                type="text"
            />
            {/* {attdata?.value?.url && !isValidURL(attdata?.value?.url) && (
                <p style={{ color: "#d32f2f" }} className="error-msg">
                    {`${attdata?.label} URL must be a valid URL.`}
                </p>
            )} */}
        </>
    );
};

export default LinkObjectComponent;
