import { lazy, Suspense } from "react";
import AuthGuard from "../utils/authGuard";
import AuthGuardForSuperAdmin from "../utils/authGuardForSuperAdmin";
import AdminConversation from "../pages/admin-conversation/adminConversation";

const conversationRoutes = [
    {
        path: "/chat",
        element: (
            <Suspense>
                <AuthGuard>
                    <AdminConversation />
                </AuthGuard>
            </Suspense>
        ),
    },
]

export default conversationRoutes;