import { createSlice } from "@reduxjs/toolkit";
const initialData = {
    selectedTypeTab: 0,
    selectedTypeTabForAdmin: 0,
    pagination: {
        page: 1,
        per_page: 10,
        meta_data: null
    },
    paginationForPost: {
        page: 1,
        per_page: 10,
        meta_data: null
    }
};

const typeTab = createSlice({
    name: "typeTabDetails",
    initialState: initialData,
    reducers: {
        handleSelectedTab(state, action) {
            state.selectedTypeTab = action?.payload
        },
        handleSelectedTabForAdmin(state, action) {
            state.selectedTypeTabForAdmin = action?.payload
        },
        setPagination(state, action) {
            state.pagination = action.payload;
        },
        setPaginationForPost(state, action) {
            state.paginationForPost = action.payload;
        },
    }
})

export const typeTabAction = typeTab.actions;
export default typeTab.reducer;