import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const CheckboxSingleChoice = ({
    attdata,
    handleChangeForFormData,
    handleChangeForChildAtts,
    isChild,
    parentAttData,
    handleChangeForAdditionalTextField,
    isMulti,
}) => {
    return (
        <>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={attdata?.value === true}
                        name={attdata?.nameValue}
                        onChange={(event) => {
                            if (isChild === true) {
                                handleChangeForChildAtts(
                                    attdata?.nameValue,
                                    event?.target?.checked,
                                    attdata?.section_id,
                                    attdata,
                                    parentAttData
                                );
                            } else if (isMulti === true) {
                                handleChangeForAdditionalTextField(
                                    attdata?.id,
                                    event?.target?.checked,
                                    parentAttData?.nameValue,
                                    parentAttData?.section_id,
                                    attdata?.fieldtype
                                );
                            } else {
                                handleChangeForFormData(
                                    attdata?.nameValue,
                                    event?.target?.checked,
                                    attdata?.section_id
                                );
                            }
                        }}
                        sx={{ marginRight: "0" }}
                    />
                }
                // label={attdata?.label}
                className="font-inter ack-5"
            />
        </>
    );
};

export default CheckboxSingleChoice;
