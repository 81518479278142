import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Toast } from "react-bootstrap";
import ReactDOMServer from "react-dom/server";

import { useNavigate } from "react-router-dom";
import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';
import gjsPresetNewsLetter from 'grapesjs-preset-newsletter';
import { pageAction } from "../../redux/slices/page/page";

import api from "../../components/common/commonFunctionForApi";
import Loader from "../../components/common/loader";
import addUserMenuBlock2, { CustomMenu } from "../../components/common/editor-blocks/addUserMenuBlock2";
import logoutHelper from "../../helpers/logoutHelper";
import LinkBlock, { RenderLinkBlock } from "../../components/common/editor-blocks/linkBlock";
import YoutubeBlock, { RenderYoutubeBlock } from "../../components/common/editor-blocks/youtubeBlock";

import grapesjsPluginCKEditor from 'grapesjs-plugin-ckeditor';
import ImageBlock, { RenderImageBlock } from "../../components/common/editor-blocks/imageBlock";
import EmbedBlock, { RenderEmbedBlock } from "../../components/common/editor-blocks/embedBlock";
import TypeBlock, { RenderTypeBlock } from "../../components/common/editor-blocks/typeBlock";
import PeopleBlock, { RenderPeopleBlock } from "../../components/common/editor-blocks/peopleBlock";
import FeedBlock, { RenderFeedBlock } from "../../components/common/editor-blocks/feedBlock";

const TemplatePage = () => {
    const { open, adminAccessible } = useSelector((state) => state?.drawerMenu);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { html, css, js, styles, components, menu, link, pageSlug, youtube, usersOptionsData } = useSelector((state) => state?.page);

    const [userMenuLists, setUserMenuLists] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    const [getAllMenus, setGetAllMenus] = useState([])
    const [listingLabels, setListingLabels] = useState([]);
    const [linksData, setLinksData] = useState([])
    const [typesData, setTypesData] = useState([])
    const [feedTypesData, setFeedTypesData] = useState([])

    const [categoryData, setCategoryData] = useState([])
    const [groupsData, setGroupsData] = useState([])
    const [usersData, setUsersData] = useState([])

    const userToken = localStorage.getItem("accessToken");

    const user = localStorage.getItem("user");
    const loggedUser = JSON.parse(user);

    const fetchAllMenus = async () => {
        try {
            setIsLoading(true);
            const response = await api.post(
                `admin/menu/all`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            setGetAllMenus(response?.data);
        } catch (err) {
            console.log("err`or in fetch menu api::", err);
            if (err?.response?.status == 401) {
                setIsLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attempts wait for 2 min.");
            }
        }
    }

    const fetchAllTypes = async () => {
        try {
            setIsLoading(true);
            const response = await api.post(
                `admin/page/component/type-list`,
                { update_type: 0 },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            setTypesData(response?.response?.data);
        } catch (err) {
            console.log("err`or in fetch menu api::", err);
            if (err?.response?.status == 401) {
                setIsLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attempts wait for 2 min.");
            }
        }
    }

    const fetchAllFeedTypes = async () => {
        try {
            setIsLoading(true);
            const response = await api.post(
                `admin/page/component/type-list`,
                { update_type: 1 },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            setFeedTypesData(response?.response?.data);
        } catch (err) {
            console.log("err`or in fetch menu api::", err);
            if (err?.response?.status == 401) {
                setIsLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attempts wait for 2 min.");
            }
        }
    }

    const fetchAllCategories = async () => {
        try {
            setIsLoading(true);
            const response = await api.get(
                `admin/page/component/category-list`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            setCategoryData(response?.response?.data);
        } catch (err) {
            console.log("err`or in fetch menu api::", err);
            if (err?.response?.status == 401) {
                setIsLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attempts wait for 2 min.");
            }
        }
    }

    const fetchAllGroups = async () => {
        try {
            setIsLoading(true);
            const response = await api.get(
                `admin/page/component/group-list`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            setGroupsData(response?.response?.data);
        } catch (err) {
            console.log("err`or in fetch menu api::", err);
            if (err?.response?.status == 401) {
                setIsLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attempts wait for 2 min.");
            }
        }
    }

    const fetchAllUsers = async () => {
        try {
            setIsLoading(true);
            const response = await api.post(
                `admin/page/component/user-list`,
                { group: [], search: "", user_id: [] },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            setUsersData(response?.response?.data);
        } catch (err) {
            console.log("err`or in fetch menu api::", err);
            if (err?.response?.status == 401) {
                setIsLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attempts wait for 2 min.");
            }
        }
    }

    const fetchAllLabels = async () => {
        try {
            setIsLoading(true);
            const response = await api.get(`admin/link/label-list`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            setIsLoading(false)
            if (response?.success === true) {
                const labels = response?.response?.labels;
                setListingLabels(labels);
                if (pageSlug === "") {
                    dispatch(pageAction?.setLinkData({ selectLink: response?.response?.labels[0]?.value }))
                }
                await fetchLinkData(pageSlug === "" ? response?.response?.labels[0]?.value : link?.selectLink)
                setIsLoading(false);
            } else if (response?.success === false) {
                setIsLoading(false);
                Toast.warning(response?.message);
                localStorage.setItem("last-redirect-page", `/`);
                navigate("/");
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setIsLoading(false);
            if (err?.response?.status === 401) {
                setIsLoading(false);
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/`);
                navigate("/");
            }
            if (err?.response?.status === 403) {
                setIsLoading(false);
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/`);
                navigate("/");
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
        }
    }

    const fetchLinkData = async (link) => {
        setIsLoading(true);
        try {
            const response = await api.get(`admin/link/link-list/${link}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success === true) {
                const data = response?.response?.data
                setLinksData(data)
                setIsLoading(false);
            } else if (response?.success === false) {
                Toast.warning(response?.message);
                localStorage.setItem("last-redirect-page", `/`);
            }
        } catch (err) {
            console.log("error in fetch links list api::", err);
            setIsLoading(false);
            if (err?.response?.status === 401) {
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/`);
            }
            if (err?.response?.status === 403) {
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/`);
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attempts wait for 2 min.")
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const apiCall = async () => {
            await fetchAllMenus()
            await fetchAllTypes()
            await fetchAllFeedTypes()
            await fetchAllCategories()
            await fetchAllGroups()
            await fetchAllUsers()
            await fetchAllLabels()
        }
        apiCall()
    }, []);

    useEffect(() => {

        if (isLoading) return;

        const editor = grapesjs.init({
            container: '#gjs',
            height: '700px',
            width: '100%',

            plugins: [
                gjsPresetNewsLetter,
                (editor) => addUserMenuBlock2(editor, {
                    menuItems: userMenuLists,
                    menuList: getAllMenus,
                    setUserMenuLists: setUserMenuLists,
                    userToken: userToken,
                    dispatch: dispatch,
                    menu: menu
                }), (editor) => LinkBlock(editor, {
                    linksData: linksData,
                    listingLabels: listingLabels,
                    userToken: userToken,
                    dispatch: dispatch,
                    link: link
                }), (editor) => YoutubeBlock(editor, { youtube: youtube, dispatch: dispatch }), grapesjsPluginCKEditor, (editor) => ImageBlock(editor, {
                    userToken: userToken,
                    navigate: navigate,
                }), (editor) => EmbedBlock(editor),
                (editor) => TypeBlock(editor, {
                    userToken: userToken,
                    typesData: typesData,
                    categoryData: categoryData,
                    loggedUser: loggedUser,
                    navigate: navigate,
                    adminAccessible: adminAccessible
                }),
                (editor) => PeopleBlock(editor, { groupsData: groupsData, userToken: userToken, usersOptionsData: usersOptionsData, dispatch: dispatch, usersData: usersData }),
                (editor) => FeedBlock(editor, {
                    userToken: userToken,
                    typesData: feedTypesData,
                    categoryData: categoryData,
                    loggedUser: loggedUser,
                    navigate: navigate,
                    adminAccessible: adminAccessible

                }),
            ],

            storageManager: {
                id: 'gjs-',
                type: 'local',
                // autosave: true,
                autoload: false,
                storeComponents: true,
                storeStyles: true,
                storeHtml: true,
                storeCss: true,
            },
            deviceManager: {
                devices:
                    [
                        {
                            id: 'desktop',
                            name: 'Desktop',
                            width: '',
                        },
                        {
                            id: 'tablet',
                            name: 'Tablet',
                            width: '768px',
                            widthMedia: '992px',
                        },
                        {
                            id: 'mobilePortrait',
                            name: 'Mobile portrait',
                            width: '320px',
                            widthMedia: '575px',
                        },
                    ]
            },

            pluginsOpts: {
                [gjsPresetNewsLetter]: {

                },
                [grapesjsPluginCKEditor]: {
                    // CKEditor-specific options
                    options: {
                        language: 'en',
                        toolbarGroups: [
                            { name: 'document', groups: ['mode', 'document', 'doctools'] },
                            { name: 'clipboard', groups: ['clipboard', 'undo'] },
                            { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
                            { name: 'forms', groups: ['forms'] },
                            '/',
                            { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                            { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
                            { name: 'links', groups: ['links'] },
                            { name: 'insert', groups: ['insert'] },
                            '/',
                            { name: 'styles', groups: ['styles'] },
                            { name: 'colors', groups: ['colors'] },
                            { name: 'tools', groups: ['tools'] },
                            { name: 'others', groups: ['others'] },
                            { name: 'about', groups: ['about'] }
                        ],
                        removeButtons: 'NewPage'
                    },
                },

            },

        })

        editor.BlockManager.add('custom-text-block', {
            label: 'Text',
            category: 'Components',
            content: {
                type: 'custom-text-block', // Define a custom type
                content: `
                 <div class="block-wrapper">
                    <h2 class="block-title">Title</h2>
                    <div class="block-content">Edit me with CKEditor!</div>
                </div>`,
                traits: [
                    {
                        type: 'text',
                        label: 'Title',
                        name: 'title', // Bind this to the title attribute
                        placeholder: 'Enter a title',
                    },
                    {
                        type: 'text',
                        label: 'ID',
                        name: 'id',
                        placeholder: 'Enter an ID',
                    },
                ],
                classes: ["custom-text-block"],
                style: { padding: '10px' },
                editable: true, // Enable CKEditor for this block
            },
            media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M254 52.8C249.3 40.3 237.3 32 224 32s-25.3 8.3-30 20.8L57.8 416 32 416c-17.7 0-32 14.3-32 32s14.3 32 32 32l96 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-1.8 0 18-48 159.6 0 18 48-1.8 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l96 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-25.8 0L254 52.8zM279.8 304l-111.6 0L224 155.1 279.8 304z"/></svg>'
        });

        // Extend the DomComponents for custom rendering logic
        editor.DomComponents.addType('custom-text-block', {
            model: {
                defaults: {
                    traits: ['title', 'id'], // Bind these traits
                    components: `
                <div class="block-wrapper">
                    <h2 class="block-title" style="text-align: center;">Title</h2>
                    <div class="block-content">Edit me with CKEditor!</div>
                </div>
            `, // Define the structure of the block
                    attributes: {
                        title: '', // Default value for the title
                    },
                },
                init() {
                    // Listen to title changes
                    this.on("change:attributes", this.handleTitle);
                },
                handleTitle() {
                    const title = this.getAttributes().title || ''; // Get the title attribute
                    const titleElement = this.view.el.querySelector('.block-title'); // Access the block title in the view
                    if (titleElement) {
                        titleElement.textContent = title; // Update the title dynamically
                    }
                },
            },
            view: {
                onRender() {
                    // This ensures the title is set after rendering
                    const model = this.model;
                    const title = model.getAttributes().title || '';
                    const titleElement = this.el.querySelector('.block-title');
                    if (titleElement) {
                        titleElement.textContent = title; // Set the title during render
                    }
                },
            },
        });

        // Add or reinitialize the custom menu block whenever `userMenuLists` changes
        const reinitializeMenuBlock = () => {
            // console.log("typesData ini::", typesData)
            addUserMenuBlock2(editor, { menuItems: userMenuLists, menuList: getAllMenus, setUserMenuLists: setUserMenuLists, userToken: userToken, dispatch: dispatch, menu: menu });
            LinkBlock(editor, {
                linksData: linksData,
                listingLabels: listingLabels,
                userToken: userToken,
                dispatch: dispatch,
                link: link
            })
            YoutubeBlock(editor, {
                youtube: youtube,
                dispatch: dispatch
            })
            ImageBlock(editor, {
                userToken: userToken,
                navigate: navigate,
            })
            EmbedBlock(editor)
            TypeBlock(editor, {
                userToken: userToken,
                typesData: typesData,
                categoryData: categoryData,
                loggedUser: loggedUser,
                navigate: navigate,
                adminAccessible: adminAccessible
            })
            PeopleBlock(editor, { groupsData: groupsData, userToken: userToken, usersOptionsData: usersOptionsData, dispatch: dispatch, usersData: usersData })
            FeedBlock(editor, {
                userToken: userToken,
                typesData: feedTypesData,
                categoryData: categoryData,
                loggedUser: loggedUser,
                navigate: navigate,
                adminAccessible: adminAccessible

            })
            // Optionally remove and re-add the block to ensure updates
            editor.BlockManager.remove('user-menu');
            editor.BlockManager.add('user-menu', {
                label: 'Custom Menu',
                category: 'Components',
                content: {
                    type: 'react-component',
                    components: ReactDOMServer.renderToString(
                        <CustomMenu menuItems={userMenuLists} position={menu?.position} />
                    ),
                    tagName: 'div',
                },
                media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z"/></svg>',

            });
            editor.BlockManager.remove('cust-link-block');

            editor.BlockManager.add("cust-link-block", {
                label: "Cstom Link Block",
                category: "Components",
                content: {
                    type: "custom-link-block-component", // Custom type for React
                    components: ReactDOMServer.renderToString(
                        <RenderLinkBlock link={linksData} />
                    ),
                    tagName: "div", // Wrap the menu in a div
                },
                media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z"/></svg>',
            });

            editor.BlockManager.add("cust-youtube-block", {
                label: "Custom Youtube Block",
                category: "Components",
                content: {
                    type: "custom-youtube-block-component", // Custom type for React
                    components: ReactDOMServer.renderToString(
                        <RenderYoutubeBlock />
                    ),
                    className: "youtube-blk-class",
                    tagName: "div", // Wrap the menu in a div
                },
                media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z"/></svg>',
            });

            editor.BlockManager.remove('cust-img-block');

            editor.BlockManager.add("cust-img-block", {
                extend: 'image',
                label: "Custom Image Block",
                category: "Components",
                content: {
                    type: "custom-img-block-component", // Custom type for React
                    components: ReactDOMServer.renderToString(
                        <RenderImageBlock />
                    ),
                    className: "img-blk-class",
                    tagName: "div", // Wrap the menu in a div

                },
                media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M0 96C0 60.7 28.7 32 64 32l384 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6l96 0 32 0 208 0c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>',
            });

            editor.BlockManager.add("cust-embed-block", {
                label: "Custom Embed Block",
                category: "Components",
                content: {
                    type: "custom-embed-block-component", // Custom type for React
                    components: ReactDOMServer.renderToString(
                        <RenderEmbedBlock />
                    ),
                    className: "embed-blk-class",
                    tagName: "div", // Wrap the menu in a div
                },
                media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"/></svg>',
            });

            editor.BlockManager.add("cust-type-block", {
                label: "Custom Type Block",
                category: "Components",
                content: {
                    type: "custom-type-block-component", // Custom type for React
                    components: ReactDOMServer.renderToString(
                        <RenderTypeBlock />
                    ),
                    className: "type-blk-class",
                    tagName: "div", // Wrap the menu in a div
                },
                media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M40 48C26.7 48 16 58.7 16 72l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24L40 48zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L192 64zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-288 0zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-288 0zM16 232l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24l-48 0c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24l0 48c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24l-48 0z"/></svg>',
            });

            editor.BlockManager.add("cust-people-block", {
                label: "Custom People Block",
                category: "Components",
                content: {
                    type: "custom-people-block-component", // Custom type for React
                    components: ReactDOMServer.renderToString(
                        <RenderPeopleBlock />
                    ),
                    className: "people-blk-className",
                    tagName: "div", // Wrap the menu in a div
                },
                media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192l42.7 0c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0L21.3 320C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7l42.7 0C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3l-213.3 0zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352l117.3 0C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7l-330.7 0c-14.7 0-26.7-11.9-26.7-26.7z"/></svg>',
            });

            editor.BlockManager.add("cust-feed-block", {
                label: "Custom Feed Block",
                category: "Components",
                content: {
                    type: "custom-feed-block-component", // Custom type for React
                    components: ReactDOMServer.renderToString(
                        <RenderFeedBlock />
                    ),
                    className: "feed-blk-className",
                    tagName: "div", // Wrap the menu in a div
                },
                media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M0 64C0 46.3 14.3 32 32 32c229.8 0 416 186.2 416 416c0 17.7-14.3 32-32 32s-32-14.3-32-32C384 253.6 226.4 96 32 96C14.3 96 0 81.7 0 64zM0 416a64 64 0 1 1 128 0A64 64 0 1 1 0 416zM32 160c159.1 0 288 128.9 288 288c0 17.7-14.3 32-32 32s-32-14.3-32-32c0-123.7-100.3-224-224-224c-17.7 0-32-14.3-32-32s14.3-32 32-32z"/></svg>',
            });

        };

        const blockManager = editor.BlockManager;

        editor.on("load", () => {
            reinitializeMenuBlock();
            console.log('Blocks:', editor.BlockManager.getAll());
            const iframe = editor.Canvas.getFrameEl();

            if (iframe) {
                const doc = iframe.contentDocument || iframe.document;

                // Inject Material-UI CSS
                const muiCssLink = document.createElement("link");
                muiCssLink.rel = "stylesheet";
                muiCssLink.href = "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap";
                doc.head.appendChild(muiCssLink);

                // Inject Bootstrap CSS
                const bootstrapCSS = document.createElement("link");
                bootstrapCSS.rel = "stylesheet";
                bootstrapCSS.href = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css";
                doc.head.appendChild(bootstrapCSS);

                // Inject Bootstrap JS (optional, for JS functionality like modals, tooltips, etc.)
                const bootstrapJS = document.createElement("script");
                bootstrapJS.src = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.bundle.min.js";
                bootstrapJS.defer = true;
                doc.head.appendChild(bootstrapJS);
            }
        });

        blockManager.remove('list-items'); // Example: removes "list items"
        blockManager.remove('grid-items');
        blockManager.remove('text');
        blockManager.remove('image');

        const domComponents = editor?.DomComponents;

        // Add a Save button to the panel
        editor.Panels.addButton('options', {
            id: 'save-button',
            // className: 'fa fa-save',
            className: 'gjs-btn-save',
            command: 'save-data', // Custom command
            attributes: { title: 'Save Template' },
            label: 'Save Template', // Add label text for a button-like appearance
        });

        // Define the save command
        editor?.Commands?.add('save-data', {
            run: () => {
                const htmlData = editor.getHtml();
                // console.log("html data::", htmlData)
                const cssData = editor.getCss();
                const jsData = editor.getJs()
                const componentData = JSON.stringify(editor.getComponents())
                // console.log("componentData data::", editor.getComponents())

                const styleData = JSON.stringify(editor.getStyle())

                // Parse the HTML
                const parser = new DOMParser();
                const htmlDocument = parser.parseFromString(htmlData, 'text/html');

                // Extract menuPosition and menuType from custom-menu-block elements
                const menuData = Array.from(htmlDocument.querySelectorAll('.custom-menu-block'))
                    .map((el) => {
                        const type = el.getAttribute('menuType');
                        const position = el.getAttribute('menuPosition');
                        if (type && position) {
                            return { type: parseInt(type, 10), position };
                        }
                        return null;
                    })
                    .filter(Boolean); // Remove null values

                // Parse the HTML and extract selectLinks attributes
                const selectedLinks = Array.from(
                    htmlDocument.querySelectorAll('.custom-link-block')
                ).map((el) => el.getAttribute('selectLinks')).filter(Boolean);

                const ytData = Array.from(htmlDocument.querySelectorAll('.custom-youtube-block'))
                    .map((el) => {
                        const title = el.getAttribute('youtubeUrl');
                        const url = el.getAttribute('youtubeTitle');
                        if (title || url) {
                            return { title: title, url: url };
                        }
                        return null;
                    })
                    .filter(Boolean); // Remove null values

                // Extract data from custom-type-block elements
                const types = Array.from(htmlDocument.querySelectorAll('.custom-type-block'))
                    .map((el) => {
                        const typeID = parseInt(el.getAttribute('type'), 10);
                        const categoryString = el.getAttribute('category'); // Get the category string
                        const categoryID = categoryString
                            ? categoryString.split(',').map((id) => parseInt(id, 10)) // Convert to an array of integers
                            : []; // Default to an empty array if category is not present
                        const layout = el.getAttribute('layout');
                        const max = el.getAttribute('max');
                        const title = el.getAttribute('title');

                        if (!isNaN(typeID) && categoryID.length > 0 && layout && max) {
                            return { typeID, categoryID, layout, max, title };
                        }
                        return null;
                    })
                    .filter(Boolean); // Remove null or incomplete entries

                // Extract custom-people-block data
                const peopleData = Array.from(htmlDocument.querySelectorAll('.custom-people-block'))
                    .map((el) => {
                        const title = el.getAttribute('title') || null; // Use null as a fallback for missing attributes
                        const groups = el.getAttribute('groups')?.trim()
                            ? el.getAttribute('groups').split(',').map(Number)
                            : []; // Handle empty string explicitly
                        const users = el.getAttribute('users')?.trim()
                            ? el.getAttribute('users').split(',').map(Number)
                            : []; // Handle empty string explicitly
                        const sort = el.getAttribute('sort') || null;
                        const viewtype = el.getAttribute('viewtype') || null;
                        const showpeople = el.getAttribute('showpeople') || null;
                        const showphoto = el.getAttribute('showphoto') || null;
                        const showemail = el.getAttribute('showemail') || null;
                        const showjobtitle = el.getAttribute('showjobtitle') || null;
                        const showmobile = el.getAttribute('showmobile') || null;

                        return {
                            title,
                            groups,
                            users,
                            sort,
                            viewtype,
                            showpeople,
                            showphoto,
                            showemail,
                            showjobtitle,
                            showmobile,
                        };
                    });

                //Extract custom-feed-block-data
                const feedData = Array.from(htmlDocument.querySelectorAll('.custom-feed-block'))
                    .map((el, index) => {
                        const title = el.getAttribute('title') || null; // Use null as a fallback for missing attributes
                        const feedTypes = el.getAttribute('feedType')?.trim()
                            ? el.getAttribute('feedType').split(',').map(Number)
                            : []; // Handle empty string explicitly
                        const categories = el.getAttribute('category')?.trim()
                            ? el.getAttribute('category').split(',').map(Number)
                            : []; // Handle empty string explicitly
                        const showUpdateBox = el.getAttribute('showUpdateBox') || null;

                        return {
                            title,
                            feedTypes,
                            categories,
                            showUpdateBox,
                            id: index + 1,
                        };
                    });

                dispatch(pageAction?.setTemplateData({ htmlData, cssData, jsData, componentData, styleData }))
                dispatch(pageAction?.setLinkData({ selectLink: selectedLinks }))
                dispatch(pageAction?.setMenuID(menuData))
                dispatch(pageAction?.setYoutubeData(ytData))
                dispatch(pageAction?.setTypesData(types))
                dispatch(pageAction?.setPeopleData(peopleData))
                dispatch(pageAction?.setFeedData(feedData))

                // Clear the editor canvas
                editor?.DomComponents?.clear(); // Clear all components
                editor?.CssComposer?.clear();   // Clear all styles

                // Optionally reset the editor storage
                // Clear local storage (if using local storage manager)
                if (editor?.StorageManager?.getConfig()?.type === 'local') {

                    // const storageId = editor.StorageManager.getConfig().id || 'gjs-';
                    const storageId = 'gjsProject';

                    Object.keys(localStorage).forEach((key) => {
                        if (key.startsWith(storageId)) {
                            localStorage.removeItem(key);
                        }
                    });
                }
                alert('Template saved!');

                window.history.back();
                // navigate("/page/preview")
                editor.destroy()
            },
        });

        if (html || css || js) {
            editor.DomComponents.clear();
            editor.CssComposer.clear();

            editor.setComponents(JSON.parse(components) || ''); // Import the HTML into GrapesJS
            editor.setStyle(JSON.parse(styles) || '');      // Import the CSS into GrapesJS
            if (js) {
                // To handle JavaScript, you can use custom logic if needed
                const existingScriptTag = document.getElementById('grapesjs-js');
                if (existingScriptTag) {
                    existingScriptTag.remove(); // Remove any existing script to avoid duplicates
                }
                const scriptTag = document.createElement('script');
                scriptTag.id = 'grapesjs-js';
                scriptTag.innerHTML = js;
                document.body.appendChild(scriptTag);
            }
        }

        return () => editor.destroy();

    }, [html, css, js, isLoading])

    return (
        <>
            <div
                className={`main-content-wrapper-2 ${open == true ? "drawer-open" : "drawer-close"
                    }`}
            >
                <Container
                    className={`${window.innerWidth <= 768 && "small-screen-container"}`}
                >
                    <div id="gjs"></div>
                </Container>
                <Loader isLoading={isLoading} />

            </div>

        </>
    )
}

export default TemplatePage