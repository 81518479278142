import React from "react";
import { TextField } from "@mui/material";
import { Form } from "react-bootstrap";

const LongText = ({
    attdata,
    handleChangeForFormData,
    handleChangeForChildAtts,
    isChild,
    parentAttData,
    handleChangeForAdditionalTextField,
    isMulti,
}) => {
    return (
        <>
            {/* <TextField
                className="input-field"
                fullWidth
                id="outlined-basic short summary"
                variant="outlined"
                multiline
                maxRows={2}
                name={attdata?.nameValue}
                placeholder={`Enter ${attdata?.label}...`}
                value={attdata?.value}
                onChange={(event) => {
                    if (isChild === true) {
                        handleChangeForChildAtts(
                            attdata?.nameValue,
                            event.target.value,
                            attdata?.section_id,
                            attdata,
                            parentAttData
                        );
                    } else if (isMulti === true) {
                        handleChangeForAdditionalTextField(
                            attdata?.id,
                            event?.target?.value,
                            parentAttData?.nameValue,
                            parentAttData?.section_id,
                            attdata?.fieldtype
                        );
                    } else {
                        handleChangeForFormData(
                            attdata?.nameValue,
                            event?.target?.value,
                            attdata?.section_id
                        );
                    }
                }}
            /> */}
            <Form.Control
                as="textarea" // To make it multiline
                rows={2}       // Minimum rows
                name="fileDescription"
                placeholder={`Enter ${attdata?.label}...`}
                value={attdata?.value}
                onChange={(event) => {
                    if (isChild === true) {
                        handleChangeForChildAtts(
                            attdata?.nameValue,
                            event.target.value,
                            attdata?.section_id,
                            attdata,
                            parentAttData
                        );
                    } else if (isMulti === true) {
                        handleChangeForAdditionalTextField(
                            attdata?.id,
                            event?.target?.value,
                            parentAttData?.nameValue,
                            parentAttData?.section_id,
                            attdata?.fieldtype
                        );
                    } else {
                        handleChangeForFormData(
                            attdata?.nameValue,
                            event?.target?.value,
                            attdata?.section_id
                        );
                    }
                }}
                // isInvalid={touched.fileDescription && Boolean(errors.fileDescription)}
                className="text-area-field"
            />
        </>
    );
};

export default LongText;
