import { createSlice } from "@reduxjs/toolkit";
const initialData = {
    selectedAttributeTab: 0,
    pagination: {
        page: 1,
        per_page: 10,
        meta_data: null
    }
};

const attributeTab = createSlice({
    name: "attributeTabDetails",
    initialState: initialData,
    reducers: {
        handleSelectedTab(state, action) {
            state.selectedAttributeTab = action?.payload
        },
        setPagination(state, action) {
            state.pagination = action.payload;
        },
    }
})

export const attributeTabAction = attributeTab.actions;
export default attributeTab.reducer;