import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const CheckboxMultiChoiceRepeatable = ({ attdata, handleChangeForFormData, attributesData, handleChangeForChildAtts, isChild, parentAttData, handleChangeForAdditionalTextField, isMulti }) => {

    return (
        <>
            {attdata?.options?.map(
                (multiData, index) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    attributesData
                                        ?.find(attr => attr.nameValue === attdata.nameValue && attr.section_id === attdata.section_id)
                                        ?.value?.find(val => typeof val === 'object' && val.id === attdata.id)
                                        ?.value?.includes(multiData.value) || false
                                }
                                name={
                                    multiData?.value
                                        ?.toString()
                                }
                                onChange={(
                                    event
                                ) => {
                                    const checked =
                                        event
                                            ?.target
                                            ?.checked;
                                    const optionId =
                                        multiData?.value;
                                    const attributeIndex = attributesData?.findIndex(
                                        attr => attr.nameValue === attdata.nameValue && attr.section_id === attdata.section_id
                                    );
                                    let updatedValues;


                                    if (attributeIndex !== -1) {
                                        const updatedAttributes = [...attributesData];
                                        const attribute = updatedAttributes[attributeIndex];
                                        const repeatableIndex = attribute?.value?.findIndex(val => typeof val === 'object' && val.id === attdata.id);

                                        if (repeatableIndex !== -1) {
                                            const repeatable = { ...attribute.value[repeatableIndex] };

                                            if (checked) {
                                                updatedValues = [...(repeatable.value || []), optionId];
                                            } else {
                                                updatedValues = (repeatable.value || [])?.filter(val => val !== optionId);
                                            }

                                            repeatable.value = updatedValues;
                                            updatedAttributes[attributeIndex].value[repeatableIndex] = repeatable;

                                            // setAttributesData(updatedAttributes);

                                            console.log("updatedValues::", updatedValues);
                                        }
                                    }
                                    handleChangeForAdditionalTextField(
                                        attdata?.id,
                                        updatedValues,
                                        attdata?.nameValue,
                                        attdata?.section_id,
                                        attdata?.fieldtype

                                    );
                                }}
                            />
                        }
                        label={
                            multiData?.label
                        }
                        className="radio-group-label"
                    />
                )
            )}
        </>
    )
}

export default CheckboxMultiChoiceRepeatable