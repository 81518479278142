import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { configForContentEditor } from "../../../../helpers/constants/common.js";

const TextEditor = ({ attdata, handleChangeForFormData, handleChangeForChildAtts, isChild, parentAttData, handleChangeForAdditionalTextField, isMulti }) => {
    return (
        <>
            <CKEditor
                editor={Editor}
                className=""
                config={
                    configForContentEditor
                }
                data={attdata?.value}
                onChange={(
                    event,
                    editor
                ) => {
                    const data =
                        editor?.getData();
                    if (isChild === true) {
                        handleChangeForChildAtts(
                            attdata?.nameValue,
                            data,
                            attdata?.section_id,
                            attdata,
                            parentAttData
                        );
                    }
                    else if (isMulti === true) {
                        handleChangeForAdditionalTextField(
                            attdata?.id,
                            data,
                            parentAttData?.nameValue,
                            parentAttData?.section_id,
                            attdata?.fieldtype
                        )
                    }
                    else {
                        handleChangeForFormData(
                            attdata?.nameValue,
                            data,
                            attdata?.section_id
                        );
                    }

                }}
            />
        </>
    )
}

export default TextEditor;