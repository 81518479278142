import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Col, Button } from "react-bootstrap";
import {
    Box,
    TextField,
    Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";

const RadioSingleChoiceForACK = ({
    attdata,
    handleChangeForFormData,
    handleChangeForChildAtts,
    parentAttData,
    handleNestedChildChange,
    handleAddNestedTextField,
    additionalTextFieldsForNestedChildAtt1,
    handleChangeForNestedChildAdditionalTextField,
    removeNestedExtraFields
}) => {

    return (
        <>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name={
                    attdata?.nameValue
                }
                className=""
                value={attdata?.value}
                onChange={(event) => {
                    const intValue = parseInt(event.target.value, 10);
                    handleChangeForChildAtts(
                        attdata?.nameValue,
                        intValue, // Pass the integer value
                        attdata?.section_id,
                        attdata,
                        parentAttData
                    );

                }}
            >
                {attdata?.options?.map(
                    (attribute) => (
                        <FormControlLabel
                            key={
                                attribute?.id
                            }
                            value={
                                attribute?.id
                            }
                            control={
                                <Radio
                                    size="small"
                                    checked={
                                        attdata?.value ==
                                        attribute?.value
                                    }
                                    value={
                                        attribute?.value
                                    }
                                />
                            }
                            label={
                                attribute?.label
                            }
                            className="radio-group-label"
                        />
                    )
                )}
            </RadioGroup>
        </>
    )
}

export default RadioSingleChoiceForACK