import React, { useEffect, useState, useRef, useCallback } from "react";
import { Row, Col, Image, Button } from "react-bootstrap";

import {
  Box,
  Avatar,
  IconButton,
  TextField,
  Stack,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCheck,
  faImage,
  faChevronUp,
  faChevronDown,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Mention, MentionsInput } from "react-mentions";
import "./UpdatePostCreateModal.scss";
import mentionsInputStyle from "../../../helpers/styles/dashboard/mentionsInputStyle";
import mentionStyle from "../../../helpers/styles/dashboard/mentionStyle";
import { Toast } from "../../../utils/toast";
import logoutHelper from "../../../helpers/logoutHelper";
import api from "../commonFunctionForApi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import dayjs from "dayjs";
import moment from "moment";
import Loader from "../loader";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid"; // Option 1: Use uuid library (better for unique IDs)
import AddIcon from "@mui/icons-material/Add";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function CustomTextField(props) {
  // Wrap TextField component to make it compatible with MentionsInput's inputComponent
  return (
    <TextField
      {...props}
      className="input-field-update"
      id="outlined-basic short summary"
      fullWidth
      variant="outlined"
      multiline
      maxRows={2}
      placeholder="What are you thinking about?"
    />
  );
}

function UpdatePostCreateModal({
  openPostPopup,
  setOpenPostPopup,
  loggedUser,
  adminAccessible,
  fetchAllTypePostReset,
  handleOpenUploadMedia,
  setOpenMediaModelFor,
  setSelectedMediaFileForUpdate,
  selectedMediaFileForUpdate,
  showAdvancedOption = true,
  isMultiImage = false,
  selectedMediaFileDetailsForImages,
  selectedMediaFileForImages,
  setSelectedMediaFileForImages,
  setSelectedMediaFileDetailsForImages,
  updatePostId,
  setUpdatePostId,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [getMentionUsers, setGetMentionUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [content, setContent] = useState("");
  const [showBelowError, setShowBelowError] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [attributesData, setAttributes] = useState([]);
  const [postImage, setPostImage] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [contentForPost, setContentForPost] = useState("");
  const [openFileUploadModel, setOpenFileUploadModel] = useState(false);
  const [selectedFileForAtt, setSelectedFileForAtt] = useState(null);
  const [formData, setFormData] = useState({});
  const [selectedImageFileForAtt, setSelectedImageFileForAtt] = useState(null);
  const [attData, setAttData] = useState();
  const [additionalTextFields, setAdditionalTextFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isWarningMessage, setIsWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [categoryListData, setCategoryListData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [cateTermList, setCateTermList] = useState([]);
  const [selectedCatTermList, setSelectedCatTermList] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [getAllCategories, setGetAllCategories] = useState([]);
  const [selectedCategoriesTerms, setSelectedCategoriesTerms] = useState({});
  const fileInputRef = useRef(null);

  const debouncedFetchMentionUsers = useCallback(
    debounce((text) => {
      fetchMentionUsers(text);
    }, 200),
    []
  );

  // All apis
  const fetchElementList = async () => {
    try {
      setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.get(`user/post/update-type-post/attributes`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        let acknowledgementQuestionsArray = [];

        setAttributes(response?.response);
        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        Toast.warning(response?.message);
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        if (loggedUser?.user_type == "admin" && adminAccessible == false) {
          localStorage.setItem("last-redirect-page", `/chat`);
          navigate("/");
        } else if (
          loggedUser?.user_type == "admin" &&
          adminAccessible == true
        ) {
          localStorage.setItem("last-redirect-page-admin-user", `/chat`);
          navigate("/");
        } else {
          localStorage.setItem("last-redirect-page-admin-user", `/chat`);
          navigate("/");
        }
      }
    }
  };

  const fetchMentionUsers = async (text) => {
    try {
      const userToken = localStorage.getItem("accessToken");

      const response = await api.get(
        `user/suggestion/user-list?search=${text}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success == true) {
        const allUsers = response?.response?.map((data) => {
          return {
            id: data?.id,
            display: `${data?.name} ${data?.last_name}`,
            photo: data?.thumbnail,
            job_title: data?.job_title,
            full_name: data?.full_name,
          };
        });
        setGetMentionUsers(allUsers);
      } else {
        Toast.error(response?.message);
      }
    } catch (err) {
      console.error("error in ctreate news api::", err);

      if (err?.response?.status == 401) {
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
    }
  };

  const transformSelectedCategories = (selectedCategories, allCategories) => {
    const payload = [];

    allCategories.forEach((category) => {
      const categoryLabel = category?.slug?.toLowerCase();
      const terms = selectedCategories[categoryLabel] || [];
      const categoryId = category?.id;

      if (terms.length > 0) {
        payload.push({
          category_id: categoryId,
          term_values: terms.map((term) => term?.value),
        });
      }
    });

    return payload;
  };

  function processAttributes(attributes) {
    const result = [];

    attributes.forEach((attribute) => {
      const {
        nameValue,
        fieldtype,
        attribute_id,
        value,
        is_repeatable,
        id,
        child_attribute = [],
        ...rest
      } = attribute;
      let attributeValue = value;

      // Handle different field types
      if (
        fieldtype === "checkbox_multiple_choice" ||
        fieldtype === "dropdodown_multiple_choice" ||
        fieldtype === "users_list" ||
        fieldtype === "group_list" ||
        fieldtype === "tags"
      ) {
        attributeValue = Array.isArray(value) ? value : [];
      }

      if (fieldtype === "checkbox_single_choice") {
        attributeValue = String(value);
      }

      if (fieldtype === "date" && value !== "") {
        const date = dayjs(value).format("YYYY-MM-DD");
        attributeValue = date;
      }

      if (fieldtype === "time" && value !== "") {
        const time = dayjs(value, "HH:mm A").format("HH:mm A");
        attributeValue = time;
      }

      if (fieldtype === "date_time" && value !== "") {
        const dateTime = dayjs(value).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        attributeValue = dateTime;
      }

      if (fieldtype === "date_range") {
        const startDate = moment(value.startDate);
        const endDate = moment(value.endDate);
        const formattedStartDate = startDate.format("YYYY-MM-DD");
        const formattedEndDate = endDate.format("YYYY-MM-DD");
        attributeValue = `${formattedStartDate} - ${formattedEndDate}`;
      }

      if (fieldtype === "image") {
        attributeValue = value;
      }

      if (fieldtype === "file") {
        attributeValue = value;
      }

      if (is_repeatable === true) {
        if (Array.isArray(value)) {
          console.log("line 3460");
          attributeValue = value;
        } else if (value === null) {
          attributeValue = "";
        } else {
          console.log("line 3463");
          attributeValue = [value];
        }
      }

      // Create the main attribute object
      const attributeObject = {
        attribute_id: attribute_id,
        attribute_core_name: nameValue,
        attribute_data_type:
          fieldtype === "checkbox_multiple_choice" ||
            fieldtype === "dropdodown_multiple_choice" ||
            fieldtype === "users_group_list" ||
            fieldtype === "link_object"
            ? "array"
            : fieldtype === "file"
              ? "file"
              : fieldtype === "image"
                ? "image"
                : "string",
        attribute_value: attributeValue,
        is_child: "no",
        child_attribute: [],
        id: id,
      };

      // Recursively process child attributes if any
      if (child_attribute.length > 0) {
        attributeObject.child_attribute = processAttributes(child_attribute);
        attributeObject.is_child = "yes";
      }

      // Add the attribute object to the result array
      result.push(attributeObject);
    });

    return result;
  }

  function generatePayload(attributeData) {
    const payload = processAttributes(attributeData);
    return payload;
  }

  const sumbitPost = async () => {
    const validate = await validateData();

    if (validate?.isValid == false) {
      setShowBelowError(true);
    } else {
      setShowBelowError(false);
      const formData = new FormData();
      const categoryData = transformSelectedCategories(
        selectedCategoriesTerms,
        getAllCategories
      );
      const payload = generatePayload(attributesData);
      console.log("payload::", payload);
      formData.append("description", content);
      // if (selectedMediaFileForUpdate?.id !== undefined && selectedMediaFileForUpdate?.id !== null) {
      // formData.append("image[]", selectedMediaFileForUpdate?.id);
      // }

      selectedMediaFileForImages?.forEach((item) => {
        formData.append("image[]", item);
      });

      formData.append("category_data", JSON?.stringify(categoryData));
      formData.append("attributes", JSON.stringify(payload));
      selectedMediaFileDetailsForImages
        ?.filter((item) => item.isNew)
        ?.forEach((item) => {
          formData.append("upload_image[]", item?.file);
        });
      let data = {
        // description: content,
        // image: selectedMediaFileForImages?.map((data) => data),
        // category_data: categoryData,
        // attributes: payload,
      };

      // const transformedAttributesData = attributesData?.reduce(
      //   (acc, attribute) => {
      //     let attributeValue = attribute?.value;
      //     let attributeIsChild =
      //       attribute?.childAtts?.length > 0 ? "yes" : "no";
      //     let attributeChildAtts = [];

      //     // If the fieldtype is checkbox_multiple_choice or dropdodown_multiple_choice, convert the value to a comma-separated string
      //     if (
      //       attribute?.fieldtype === "checkbox_multiple_choice" ||
      //       attribute?.fieldtype === "dropdodown_multiple_choice" ||
      //       attribute?.fieldtype === "users_list" ||
      //       attribute?.fieldtype === "group_list" ||
      //       attribute?.fieldtype === "tags"
      //     ) {
      //       attributeValue = Array.isArray(attribute?.value)
      //         ? attribute?.value.join(",")
      //         : attribute?.value;
      //     }

      //     if (attribute?.fieldtype === "checkbox_single_choice") {
      //       attributeValue =
      //         attribute?.value === true ? "true" : "false";
      //     }

      //     // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator

      //     if (attribute?.fieldtype === "date") {
      //       const date = dayjs(attribute?.value)?.format(
      //         "YYYY-MM-DD"
      //       );
      //       attributeValue = date;
      //     }
      //     if (attribute?.fieldtype === "time") {
      //       const time = dayjs(attribute?.value, "HH:mm A")?.format(
      //         "HH:mm A"
      //       );
      //       attributeValue = time;
      //     }
      //     if (attribute?.fieldtype === "date_time") {
      //       const dateTime = dayjs(attribute?.value)?.format(
      //         "YYYY-MM-DDTHH:mm:ss.SSSZ"
      //       );
      //       attributeValue = dateTime;
      //     }
      //     if (attribute?.fieldtype === "date_range") {
      //       const startDate = moment(attribute.value.startDate);
      //       const endDate = moment(attribute.value.endDate);
      //       const formattedStartDate =
      //         startDate?.format("YYYY-MM-DD");
      //       const formattedEndDate = endDate?.format("YYYY-MM-DD");
      //       // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator
      //       attributeValue = `${formattedStartDate} - ${formattedEndDate}`;
      //     }

      //     if (attribute?.fieldtype === "image") {
      //       if (
      //         selectedImageFileForAtt instanceof Blob ||
      //         selectedImageFileForAtt instanceof File
      //       ) {
      //         attributeValue = "";
      //       } else {
      //         attributeValue = extractFileName(attribute.value);
      //       }
      //     }

      //     if (attribute?.fieldtype === "file") {
      //       if (
      //         selectedFileForAtt instanceof Blob ||
      //         selectedFileForAtt instanceof File
      //       ) {
      //         attributeValue = "";
      //       } else {
      //         attributeValue = extractFileName(attribute.value);
      //       }
      //     }

      //     if (attribute?.is_repeatable == true) {
      //       attributeValue = JSON?.stringify(attribute?.value);
      //     }

      //     if (attribute?.childAtts?.length > 0) {
      //       if (attribute.value == false) {
      //         attributeChildAtts = attribute?.childAtts?.reduce(
      //           (acc, childAtt) => {
      //             let attributeValueForChild = "";

      //             // If the fieldtype is checkbox_multiple_choice or dropdodown_multiple_choice, convert the value to a comma-separated string
      //             if (
      //               childAtt?.fieldtype ===
      //               "checkbox_multiple_choice" ||
      //               childAtt?.fieldtype ===
      //               "dropdodown_multiple_choice" ||
      //               childAtt?.fieldtype === "users_list" ||
      //               childAtt?.fieldtype === "group_list" ||
      //               attribute?.fieldtype === "tags"
      //             ) {
      //               attributeValueForChild = "";
      //             }

      //             if (
      //               childAtt?.fieldtype ===
      //               "checkbox_single_choice"
      //             ) {
      //               attributeValueForChild = "";
      //             }

      //             // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator

      //             if (childAtt?.fieldtype === "date") {
      //               const date = dayjs(
      //                 childAtt?.value
      //               )?.format("YYYY-MM-DD");
      //               attributeValueForChild = "";
      //             }
      //             if (childAtt?.fieldtype === "time") {
      //               const time = dayjs(
      //                 childAtt?.value,
      //                 "HH:mm A"
      //               )?.format("HH:mm A");
      //               attributeValueForChild = "";
      //             }
      //             if (childAtt?.fieldtype === "date_time") {
      //               const dateTime = dayjs(
      //                 childAtt?.value
      //               )?.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      //               attributeValueForChild = "";
      //             }
      //             if (childAtt?.fieldtype === "date_range") {
      //               const startDate = moment(
      //                 childAtt?.value?.startDate
      //               );
      //               const endDate = moment(
      //                 childAtt?.value?.endDate
      //               );
      //               const formattedStartDate =
      //                 startDate?.format("YYYY-MM-DD");
      //               const formattedEndDate =
      //                 endDate?.format("YYYY-MM-DD");
      //               // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator
      //               attributeValueForChild = "";
      //             }

      //             if (childAtt?.fieldtype === "image") {
      //               if (
      //                 selectedImageFileForAtt instanceof
      //                 Blob ||
      //                 selectedImageFileForAtt instanceof
      //                 File
      //               ) {
      //                 attributeValueForChild = "";
      //               } else {
      //                 attributeValueForChild = "";
      //               }
      //             }

      //             if (childAtt?.fieldtype === "file") {
      //               if (
      //                 selectedFileForAtt instanceof
      //                 Blob ||
      //                 selectedFileForAtt instanceof File
      //               ) {
      //                 attributeValueForChild = "";
      //               } else {
      //                 attributeValueForChild = "";
      //               }
      //             }

      //             if (childAtt?.is_repeatable == true) {
      //               attributeValueForChild = "";
      //             }

      //             acc[childAtt?.nameValue] = {
      //               parentAttId: attribute?.id,
      //               attribute_id: childAtt?.attribute_id,
      //               attribute_core_name:
      //                 childAtt?.nameValue,
      //               attribute_data_type:
      //                 childAtt?.fieldtype ===
      //                   "checkbox_multiple_choice" ||
      //                   childAtt?.fieldtype ===
      //                   "dropdodown_multiple_choice"
      //                   ? "array"
      //                   : childAtt?.fieldtype === "file"
      //                     ? "file"
      //                     : childAtt?.fieldtype ===
      //                       "image"
      //                       ? "image"
      //                       : "string",
      //               attribute_value: attributeValueForChild,
      //               is_child: "no",
      //             };

      //             return acc;
      //           },
      //           {}
      //         );
      //       } else if (attribute.value != 1) {
      //         attributeChildAtts = attribute?.childAtts?.reduce(
      //           (acc, childAtt) => {
      //             let attributeValueForChild = "";

      //             // If the fieldtype is checkbox_multiple_choice or dropdodown_multiple_choice, convert the value to a comma-separated string
      //             if (
      //               childAtt?.fieldtype ===
      //               "checkbox_multiple_choice" ||
      //               childAtt?.fieldtype ===
      //               "dropdodown_multiple_choice" ||
      //               childAtt?.fieldtype === "users_list" ||
      //               childAtt?.fieldtype === "group_list" ||
      //               attribute?.fieldtype === "tags"
      //             ) {
      //               attributeValueForChild = "";
      //             }

      //             if (
      //               childAtt?.fieldtype ===
      //               "checkbox_single_choice"
      //             ) {
      //               attributeValueForChild = "";
      //             }

      //             // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator

      //             if (childAtt?.fieldtype === "date") {
      //               const date = dayjs(
      //                 childAtt?.value
      //               )?.format("YYYY-MM-DD");
      //               attributeValueForChild = "";
      //             }
      //             if (childAtt?.fieldtype === "time") {
      //               const time = dayjs(
      //                 childAtt?.value,
      //                 "HH:mm A"
      //               )?.format("HH:mm A");
      //               attributeValueForChild = "";
      //             }
      //             if (childAtt?.fieldtype === "date_time") {
      //               const dateTime = dayjs(
      //                 childAtt?.value
      //               )?.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      //               attributeValueForChild = "";
      //             }
      //             if (childAtt?.fieldtype === "date_range") {
      //               const startDate = moment(
      //                 childAtt?.value?.startDate
      //               );
      //               const endDate = moment(
      //                 childAtt?.value?.endDate
      //               );
      //               const formattedStartDate =
      //                 startDate?.format("YYYY-MM-DD");
      //               const formattedEndDate =
      //                 endDate?.format("YYYY-MM-DD");
      //               // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator
      //               attributeValueForChild = "";
      //             }

      //             if (childAtt?.fieldtype === "image") {
      //               if (
      //                 selectedImageFileForAtt instanceof
      //                 Blob ||
      //                 selectedImageFileForAtt instanceof
      //                 File
      //               ) {
      //                 attributeValueForChild = "";
      //               } else {
      //                 attributeValueForChild = "";
      //               }
      //             }

      //             if (childAtt?.fieldtype === "file") {
      //               if (
      //                 selectedFileForAtt instanceof
      //                 Blob ||
      //                 selectedFileForAtt instanceof File
      //               ) {
      //                 attributeValueForChild = "";
      //               } else {
      //                 attributeValueForChild = "";
      //               }
      //             }

      //             if (childAtt?.is_repeatable == true) {
      //               attributeValueForChild = "";
      //             }

      //             acc[childAtt?.nameValue] = {
      //               parentAttId: attribute?.id,
      //               attribute_id: childAtt?.attribute_id,
      //               attribute_core_name:
      //                 childAtt?.nameValue,
      //               attribute_data_type:
      //                 childAtt?.fieldtype ===
      //                   "checkbox_multiple_choice" ||
      //                   childAtt?.fieldtype ===
      //                   "dropdodown_multiple_choice"
      //                   ? "array"
      //                   : childAtt?.fieldtype === "file"
      //                     ? "file"
      //                     : childAtt?.fieldtype ===
      //                       "image"
      //                       ? "image"
      //                       : "string",
      //               attribute_value: attributeValueForChild,
      //               is_child: "no",
      //             };

      //             return acc;
      //           },
      //           {}
      //         );
      //       } else {
      //         attributeChildAtts = attribute?.childAtts?.reduce(
      //           (acc, childAtt) => {
      //             let attributeValueForChild =
      //               childAtt?.value;

      //             // If the fieldtype is checkbox_multiple_choice or dropdodown_multiple_choice, convert the value to a comma-separated string
      //             if (
      //               childAtt?.fieldtype ===
      //               "checkbox_multiple_choice" ||
      //               childAtt?.fieldtype ===
      //               "dropdodown_multiple_choice" ||
      //               childAtt?.fieldtype === "users_list" ||
      //               childAtt?.fieldtype === "group_list"
      //             ) {
      //               attributeValueForChild = Array?.isArray(
      //                 childAtt?.value
      //               )
      //                 ? childAtt?.value.join(",")
      //                 : childAtt?.value;
      //             }

      //             if (
      //               childAtt?.fieldtype ===
      //               "checkbox_single_choice"
      //             ) {
      //               attributeValueForChild = String(
      //                 attribute?.value
      //               );
      //             }

      //             // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator

      //             if (childAtt?.fieldtype === "date") {
      //               const date = dayjs(
      //                 childAtt?.value
      //               )?.format("YYYY-MM-DD");
      //               attributeValueForChild = date;
      //             }
      //             if (childAtt?.fieldtype === "time") {
      //               const time = dayjs(
      //                 childAtt?.value,
      //                 "HH:mm A"
      //               )?.format("HH:mm A");
      //               attributeValueForChild = time;
      //             }
      //             if (childAtt?.fieldtype === "date_time") {
      //               const dateTime = dayjs(
      //                 childAtt?.value
      //               )?.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      //               attributeValueForChild = dateTime;
      //             }
      //             if (childAtt?.fieldtype === "date_range") {
      //               const startDate = moment(
      //                 childAtt?.value?.startDate
      //               );
      //               const endDate = moment(
      //                 childAtt?.value?.endDate
      //               );
      //               const formattedStartDate =
      //                 startDate?.format("YYYY-MM-DD");
      //               const formattedEndDate =
      //                 endDate?.format("YYYY-MM-DD");
      //               // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator
      //               attributeValueForChild = `${formattedStartDate} - ${formattedEndDate}`;
      //             }

      //             if (childAtt?.fieldtype === "image") {
      //               if (
      //                 selectedImageFileForAtt instanceof
      //                 Blob ||
      //                 selectedImageFileForAtt instanceof
      //                 File
      //               ) {
      //                 attributeValueForChild = "";
      //               } else {
      //                 attributeValueForChild =
      //                   extractFileName(childAtt.value);
      //               }
      //             }

      //             if (childAtt?.fieldtype === "file") {
      //               if (
      //                 selectedFileForAtt instanceof
      //                 Blob ||
      //                 selectedFileForAtt instanceof File
      //               ) {
      //                 attributeValueForChild = "";
      //               } else {
      //                 attributeValueForChild =
      //                   extractFileName(childAtt.value);
      //               }
      //             }

      //             if (childAtt?.is_repeatable == true) {
      //               attributeValueForChild =
      //                 JSON?.stringify(childAtt?.value);
      //             }

      //             acc[childAtt?.nameValue] = {
      //               parentAttId: attribute?.id,
      //               attribute_id: childAtt?.attribute_id,
      //               attribute_core_name:
      //                 childAtt?.nameValue,
      //               attribute_data_type:
      //                 childAtt?.fieldtype ===
      //                   "checkbox_multiple_choice" ||
      //                   childAtt?.fieldtype ===
      //                   "dropdodown_multiple_choice"
      //                   ? "array"
      //                   : childAtt?.fieldtype === "file"
      //                     ? "file"
      //                     : childAtt?.fieldtype ===
      //                       "image"
      //                       ? "image"
      //                       : "string",
      //               attribute_value: attributeValueForChild,
      //               is_child: "no",
      //             };

      //             return acc;
      //           },
      //           {}
      //         );
      //       }
      //     }

      //     acc[attribute?.nameValue] = {
      //       attribute_id: attribute?.attribute_id,
      //       attribute_core_name: attribute?.nameValue,
      //       attribute_data_type:
      //         attribute?.fieldtype ===
      //           "checkbox_multiple_choice" ||
      //           attribute?.fieldtype ===
      //           "dropdodown_multiple_choice"
      //           ? "array"
      //           : attribute?.fieldtype === "file"
      //             ? "file"
      //             : attribute?.fieldtype === "image"
      //               ? "image"
      //               : "string",
      //       attribute_value: attributeValue,
      //       is_child: attributeIsChild,
      //       childAtts: attributeChildAtts,
      //     };

      //     return acc;
      //   },
      //   {}
      // );

      // formData.append(
      //   "attributes",
      //   JSON.stringify([transformedAttributesData])
      // );

      try {
        setLoading(true);
        const userToken = localStorage.getItem("accessToken");
        const response = await api.post(
          `user/post/update-type-post`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        if (response?.success == true) {
          setLoading(false);
          setContent("");
          setSelectedImageFile("");
          setPostImage();
          setSelectedMediaFileForUpdate();
          setSuccessMessage(response?.message);
          handleClose();
          setSelectedMediaFileForImages([]);
          setSelectedMediaFileDetailsForImages([]);
          setSelectedCategoriesTerms({});
          setGetAllCategories([]);
          setSelectedCategories([]);
          fetchAllTypePostReset();

          Toast.success(response?.message);
        } else {
          setLoading(false);
          setContent("");
          setSelectedImageFile("");
          setIsWarningMessage(true);
          setWarningMessage(response?.message);
          Toast.error(response?.message);
        }
        setContent("");
        setSelectedImageFile("");
        setIsCollapsed(false);
        fetchElementList();
      } catch (err) {
        console.log("error in ctreate news api::", err);
        setLoading(false);

        if (err?.response?.status == 401) {
          setLoading(false);
          const userToken = localStorage.getItem("accessToken");
          logoutHelper(dispatch, navigate, userToken);
        }
        if (err?.response?.status == 422) {
          setLoading(false);
          setIsWarningMessage(true);
          setWarningMessage(err?.response?.data?.errors?.image);
          const errorData = Object.values(err?.response?.data?.errors).forEach(
            (value) => Toast.error(value)
          );
        }
      }
    }
  };

  const updatePost = async () => {
    const validate = await validateData();

    if (validate?.isValid == false) {
      setShowBelowError(true);
    } else {
      setShowBelowError(false);
      const formData = new FormData();
      const categoryData = transformSelectedCategories(
        selectedCategoriesTerms,
        getAllCategories
      );
      const payload = generatePayload(attributesData);
      console.log("payload::", payload);
      formData.append("description", content);
      // if (selectedMediaFileForUpdate?.id !== undefined && selectedMediaFileForUpdate?.id !== null) {
      // formData.append("image[]", selectedMediaFileForUpdate?.id);
      // }

      selectedMediaFileForImages?.forEach((item) => {
        formData.append("image[]", item);
      });

      formData.append("category_data", JSON?.stringify(categoryData));
      formData.append("attributes", JSON.stringify(payload));
      selectedMediaFileDetailsForImages
        ?.filter((item) => item.isNew)
        ?.forEach((item) => {
          formData.append("upload_image[]", item?.file);
        });
      let data = {
        // description: content,
        // image: selectedMediaFileForImages?.map((data) => data),
        // category_data: categoryData,
        // attributes: payload,
      };

      try {
        setLoading(true);
        const userToken = localStorage.getItem("accessToken");
        const response = await api.post(
          `user/post/update-type/update/${updatePostId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        if (response?.success == true) {
          setLoading(false);
          setContent("");
          setSelectedImageFile("");
          setPostImage();
          setSelectedMediaFileForUpdate();
          setSuccessMessage(response?.message);
          handleClose();
          setSelectedMediaFileForImages([]);
          setSelectedMediaFileDetailsForImages([]);
          setSelectedCategoriesTerms({});
          setGetAllCategories([]);
          setSelectedCategories([]);
          fetchAllTypePostReset();
          setUpdatePostId();
          Toast.success(response?.message);
        } else {
          setLoading(false);
          setContent("");
          setSelectedImageFile("");
          setIsWarningMessage(true);
          setWarningMessage(response?.message);
          Toast.error(response?.message);
        }
        setContent("");
        setSelectedImageFile("");
        setIsCollapsed(false);
        fetchElementList();
      } catch (err) {
        console.log("error in ctreate news api::", err);
        setLoading(false);

        if (err?.response?.status == 401) {
          setLoading(false);
          const userToken = localStorage.getItem("accessToken");
          logoutHelper(dispatch, navigate, userToken);
        }
        if (err?.response?.status == 422) {
          setLoading(false);
          setIsWarningMessage(true);
          setWarningMessage(err?.response?.data?.errors?.image);
          const errorData = Object.values(err?.response?.data?.errors).forEach(
            (value) => Toast.error(value)
          );
        }
      }
    }
  };

  // All functions
  const validateData = () => {
    let isValid = true;
    let errorMessages = [];

    if (content === "" || content === null || content === undefined) {
      isValid = false;
    }
    return { isValid, errorMessages };
  };

  const handleClose = () => {
    setContent();
    setPostImage();
    setOpenPostPopup(false);
    setSelectedMediaFileForImages([]);
    setSelectedMediaFileDetailsForImages([]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPostImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCategoryChange = (categoryLabel, selectedOption) => {
    setSelectedCategoriesTerms((prevState) => ({
      ...prevState,
      [categoryLabel]: selectedOption,
    }));
  };

  const handleOpen = () => setOpenFileUploadModel(true);
  const handleCloseForFileUpload = () => setOpenFileUploadModel(false);

  const handleImageFileValue = (setFieldValue) => {
    if (
      selectedImageFile instanceof Blob ||
      selectedImageFile instanceof File ||
      selectedFile instanceof Blob ||
      selectedFile instanceof File
    ) {
      if (contentForPost === "image") {
        setFieldValue("image", URL.createObjectURL(selectedImageFile));
        setOpenFileUploadModel(false);
      } else {
        setFieldValue("file", selectedFile);
        setOpenFileUploadModel(false);
      }
    } else {
      console.error("Selected file is not a valid Blob or File object");
    }
  };

  const handleAddTextField = (
    nameValue,
    label,
    section_id,
    fieldtype,
    options,
    is_mandatory,
    repeatableAtt,
    valueAtt
  ) => {
    const lastId =
      additionalTextFields.length > 0
        ? additionalTextFields[additionalTextFields.length - 1].id
        : null;

    const newTextField = {
      id: lastId + 1, // Generate a unique ID for the new text field
      nameValue: nameValue,
      value: "",
      label: label,
      section_id: section_id, // Set the appropriate tab value
      fieldtype: fieldtype,
      is_mandatory: is_mandatory,
      ...(options && { options: options }), // Conditionally include options if available
    };
    setAdditionalTextFields([...additionalTextFields, newTextField]);
    if (repeatableAtt == true) {
      const updatedValue = Array.isArray(valueAtt)
        ? [...valueAtt, newTextField]
        : [newTextField];
      handleChangeForFormData(nameValue, updatedValue, section_id);
    }
  };

  const handleChangeForAdditionalTextField = (
    id,
    value,
    nameValue,
    section_id
  ) => {
    const updatedTextFields = additionalTextFields.map((field) =>
      field.id === id && field?.nameValue === nameValue
        ? { ...field, value: value }
        : field
    );
    setAdditionalTextFields(updatedTextFields);

    const finalData = updatedTextFields?.filter(
      (data) => data?.nameValue == nameValue
    );

    handleChangeForFormData(nameValue, finalData, section_id);
  };

  const removeExtraFields = (id, nameValue, section_id) => {
    const updatedTextFields = additionalTextFields?.filter(
      (field) => field?.id !== id
    );
    setAdditionalTextFields(updatedTextFields);
    handleChangeForFormData(nameValue, updatedTextFields, section_id);
  };

  const handleChangeForFormData = (
    nameValue,
    value,
    section_id,
    isChildAtt
  ) => {
    setAttributes((prevAttributes) => {
      const updatedAttributes = prevAttributes.map((attribute) => {
        if (
          attribute?.section_id === section_id &&
          attribute?.nameValue === nameValue
        ) {
          // Update the value of the attribute
          let updatedAttribute = { ...attribute, value };

          // If the fieldtype is radio_single_choice and options are provided
          if (
            attribute?.fieldtype === "radio_single_choice" &&
            attribute?.options
          ) {
            const updatedOptions = attribute?.options?.map((option) => {
              // Set the checked property based on the selected value
              return {
                ...option,
                checked: option?.id === parseInt(value),
              };
            });
            // Update the options of the attribute
            updatedAttribute = {
              ...updatedAttribute,
              options: updatedOptions,
            };
          }

          if (attribute?.fieldtype === "tags") {
            // Initialize options with current options or an empty array
            let options = attribute?.options || [];

            // Separate existing and new options
            const existingOptions = value?.filter(
              (option) => !option.__isNew__
            );
            const newOptions = value?.filter((option) => option?.__isNew__);

            // Add new options to the existing options list
            if (newOptions?.length > 0) {
              options = [
                ...options,
                ...newOptions?.map((option) => ({
                  value: option?.value,
                  label: option?.label,
                })),
              ];
            }

            // Extract selected values from the value array
            const selectedValues = value?.map((option) => option?.value);

            // Update the attribute with new options and selected values
            updatedAttribute = {
              ...updatedAttribute,
              options: options,
              value: selectedValues,
            };
          }

          // If the fieldtype is checkbox_multiple_choice
          if (
            attribute?.fieldtype === "checkbox_multiple_choice" &&
            attribute?.options
          ) {
            const updatedOptions = attribute?.options?.map((option) => {
              // Set the checked property based on whether the option ID is included in the selected values array
              return {
                ...option,
                checked: value?.includes(option?.id),
              };
            });
            // Update the options of the attribute
            updatedAttribute = {
              ...updatedAttribute,
              options: updatedOptions,
            };
          }

          if (isChildAtt == true) {
            updatedAttribute = {
              ...updatedAttribute,
              childAtts: value,
            };
          }

          return updatedAttribute;
        }
        return attribute;
      });

      return updatedAttributes;
    });

    setFormData((prevFormData) => ({
      ...prevFormData,
      [nameValue]: value,
    }));
  };

  const handleChangeForChildAtts = (
    nameValue,
    value,
    section_id,
    childAtts,
    attdata
  ) => {
    const findAtt = attributesData
      .filter((data) => data?.id === attdata?.id)
      .map((data) => {
        return {
          id: data?.id,
          section_id: data?.section_id,
          label: data?.label,
          is_mandatory: data?.is_mandatory,
          nameValue: data?.nameValue,
          fieldtype: data?.fieldtype,
          options: data?.options,
          parentID: data?.parentID,
          childAtts: data?.childAtts,
          value: data?.value,
        };
      });

    const updatedChildAtts = findAtt.flatMap((att) => {
      return att.childAtts.map((childAtt) => {
        if (
          childAtt.section_id === section_id &&
          childAtt.nameValue === nameValue
        ) {
          // If the fieldtype is checkbox_multiple_choice
          if (
            childAtt.fieldtype === "checkbox_multiple_choice" &&
            childAtt.options
          ) {
            const updatedOptions = childAtt.options.map((option) => {
              // Set the checked property based on whether the option ID is included in the selected values array
              return {
                ...option,
                checked: value.includes(option.id),
              };
            });

            // Update the options of the child attribute
            return { ...childAtt, options: updatedOptions, value };
          } else {
            // If the fieldtype is not checkbox_multiple_choice, simply update the value
            return { ...childAtt, value };
          }
        }
        return childAtt;
      });
    });

    const updatedAttData = attributesData.map((att) => {
      if (att.id === attdata.id) {
        return { ...att, childAtts: updatedChildAtts };
      }
      return att;
    });
    setAttributes(updatedAttData);
  };

  const handleRemoveImage = (id) => {
    const updatedImages = selectedMediaFileDetailsForImages?.filter(
      (data) => data?.id !== id
    );
    const imgs = selectedMediaFileForImages?.filter((data) => data !== id)
    // Update state here with updatedImages if needed
    console.log("Updated images:", updatedImages);
    setSelectedMediaFileDetailsForImages(updatedImages);
    setSelectedMediaFileForImages(imgs)
  };

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedMediaFileDetailsForImages((prevFiles) => {
      // Calculate how many more files can be added
      const remainingSlots = 10 - prevFiles.length;

      if (remainingSlots <= 0) {
        return prevFiles; // Do nothing if already at max limit
      }

      // Take only the allowed number of files
      const newFiles = acceptedFiles.slice(0, remainingSlots).map((file) => ({
        file,
        id: uuidv4(),
        url: URL.createObjectURL(file),
        type: file.type,
        name: file.name,
        isNew: true,
      }));

      return [...prevFiles, ...newFiles]; // Append new files while respecting the limit
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [], // Accept all image types
      // "application/pdf": [], // Accept PDFs
      // "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], // DOCX
      // "application/vnd.openxmlformats-officedocument.presentationml.presentation": [], // PPTX
      // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], // XLSX
      // "video/mp4": [], // MP4
      // "audio/mpeg": [], // MP3
    },
    multiple: true,
  });

  const renderPreview = () => {
    if (selectedMediaFileDetailsForImages?.length === 0) return null;
    // console.log("selectedMediaFileDetailsForImages::", selectedMediaFileDetailsForImages)
    return (
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {selectedMediaFileDetailsForImages?.map((fileMedia, index) => {
          const isImageFile =
            /\.(jpg|jpeg|png)$/i.test(
              fileMedia.name || fileMedia.file?.path || ""
            ) || // Check file extension
            /image\/(jpg|jpeg|png)/.test(fileMedia.type || fileMedia.type); // Check MIME type

          const imageUrl =
            fileMedia.file_path || // Check for direct URL (like AWS)
            fileMedia.url; // Check for blob URL or other sources

          return (
            // isImageFile &&
            imageUrl && (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <div
                  className="upload-img-wrapper"
                  style={{ position: "relative" }}
                >
                  <Image src={imageUrl} alt={`Preview ${index}`} />
                  <IconButton
                    size="small"
                    style={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      color: "white",
                    }}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the click event from propagating to the dropzone
                      handleRemoveImage(fileMedia?.id);
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              </Grid>
            )
          );
        })}
        {/* "Add Image" button */}
        {selectedMediaFileDetailsForImages?.length < 10 && (
          <Grid item xs={2} sm={4} md={4} className="upload-extra-image-grid">
            <div
              {...getRootProps({
                className: "upload-img-wrapper add-image-btn",
                style: {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  border: "2px dashed #ccc",
                  height: "100%",
                  textAlign: "center",
                },
              })}
            >
              <input {...getInputProps()} />
              {/* <input {...getInputProps()} /> */}
              <FontAwesomeIcon icon={faUpload} className="add-more-images" />
            </div>
          </Grid>
        )}

      </Grid>
    );
  };

  const fetchUserchange = (e) => {
    const regex = /@([\w\s]+)$/;
    if (regex.test(e)) {
      const cleanedText = e.match(regex)[1]; // Extract the matched text without @
      setSearchTerm(cleanedText);
    }
  };

  const fetchCategoryList = async () => {
    try {
      setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.get(`user/post/update-type/data`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        const catData = response?.response?.data?.category;
        const data = catData?.map((data) => {
          return {
            label: data?.title,
            value: data?.id,
          };
        });
        setAttributes(response?.response?.data?.attribute);
        setCategoryListData(catData);
        setCategoryList(data);
        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        Toast.warning(response?.message);
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        if (loggedUser?.user_type == "admin" && adminAccessible == false) {
          localStorage.setItem("last-redirect-page", `/chat`);
          navigate("/");
        } else if (
          loggedUser?.user_type == "admin" &&
          adminAccessible == true
        ) {
          localStorage.setItem("last-redirect-page-admin-user", `/chat`);
          navigate("/");
        } else {
          localStorage.setItem("last-redirect-page-admin-user", `/chat`);
          navigate("/");
        }
      }
    }
  };

  const fetchUpdatePostDetail = async (id) => {
    try {
      setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.get(`user/post/update-type/edit/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        setContent(response?.response?.data?.description);
        const imgIDs = response?.response?.data?.image?.map((data) => data?.id);
        setSelectedMediaFileDetailsForImages(response?.response?.data?.image || []);
        setSelectedMediaFileForImages(imgIDs);
        setAttributes(response?.response?.data?.attributes);

        // Extract assigned category IDs
        const assignedCategoryIds =
          response?.response?.data?.assign_category.map(
            (cat) => cat.category_id
          );

        // Filter category data based on assigned category IDs
        // const selectedCategories = response?.response?.data?.category_data.filter(cat => assignedCategoryIds.includes(cat.id));

        setSelectedCategories(assignedCategoryIds);

        const updatedCategories = assignedCategoryIds
          .map((categoryId) => {
            const category = response?.response?.data?.category_data?.find(
              (cat) => cat.id === categoryId
            );
            console.log("category:::", category);
            if (category) {
              return {
                label: category.title,
                slug: category.slug,
                id: category.id,
                options: category.categoryterm.map((term) => ({
                  label: term.name,
                  value: term.id,
                  categoryId: term.category_id,
                })),
              };
            }
            return null;
          })
          .filter(Boolean); // Remove any null values

        console.log("updatedCategories::", updatedCategories);
        setGetAllCategories(updatedCategories);

        // Prepare selected category terms
        const selectedTerms = {};
        response?.response?.data?.assign_category.forEach(
          ({ category_id, term_values }) => {
            const category = updatedCategories.find(
              (cat) => cat.id === category_id
            );
            if (category) {
              selectedTerms[category.slug] = category.options.filter((option) =>
                term_values.includes(option.value)
              );
            }
          }
        );

        console.log("selectedTerms::", selectedTerms);
        setSelectedCategoriesTerms(selectedTerms);
      }
      setLoading(false);
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);
        Object.values(err?.response?.data?.errors).forEach((value) =>
          Toast.error(value)
        );
      }
    }
  };

  //All UseEffects

  // Effect to handle debounced fetch based on search term
  useEffect(() => {
    if (searchTerm) {
      debouncedFetchMentionUsers(searchTerm);
    }

    return () => {
      debouncedFetchMentionUsers.cancel();
    };
  }, [searchTerm, debouncedFetchMentionUsers]);

  useEffect(() => {
    fetchMentionUsers("");
    fetchCategoryList();
    fetchElementList();

    if (updatePostId) {
      fetchUpdatePostDetail(updatePostId);
    }
  }, []);

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openPostPopup}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {/* Modal title
           */}
          <div className="d-flex align-items-center gap-2">
            <Avatar
              alt={loggedUser?.name}
              className="author-avtar-creator"
              src={loggedUser?.thumbnail}
            >
              {loggedUser?.name?.charAt(0)}
            </Avatar>
            <span className="fs-14-700">{loggedUser?.name}</span>
          </div>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="alert-close-btn"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers className="no-border">
          <MentionsInput
            className="input-field-update" // Apply the custom class from CSS
            value={content}
            onChange={(event, newValue) => {
              fetchUserchange(event.target.value);
              setContent(newValue);
            }}
            placeholder="What are you thinking about?"
            a11ySuggestionsListLabel="Suggested mentions"
            style={mentionsInputStyle}
            allowSpaceInQuery={true}
          >
            <Mention
              trigger="@"
              data={getMentionUsers} // Pass in the mention data (array of objects with id and display properties)
              style={mentionStyle}
              renderSuggestion={(
                suggestion,
                search,
                highlightedDisplay,
                index,
                focused
              ) => (
                <div className="list-class" key={index}>
                  <div className="list-class-div-1">
                    <img
                      src={suggestion.photo}
                      alt={suggestion.display}
                      className="list-class-div-1-img"
                    />
                    <div className="list-class-div-2">
                      <span style={{ fontWeight: "bold" }}>
                        {suggestion?.full_name}
                      </span>
                      <br />
                      <span>{suggestion?.job_title}</span>
                    </div>
                  </div>
                </div>
              )}
            />
          </MentionsInput>

          {showBelowError == true && content === "" && (
            <p
              style={{
                color: "#d32f2f",
              }}
              className="error-msg"
            >
              {`Content is required.`}
            </p>
          )}

          <Stack gap={4} className="mt-5 mb-1">
            {isMultiImage === true ? (
              <>
                {/* <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                  {selectedMediaFileDetailsForImages?.map((file, index) => (
                    <Grid item xs={2} sm={4} md={4} key={index}>
                      <div className="upload-img-wrapper">
                        <Image src={file?.file_path} />
                      </div>
                    </Grid>
                  ))}
                </Grid> */}
                <Box
                  className={`w-100 update-new-box-media ${selectedMediaFileDetailsForImages?.length < 1 &&
                    "border-media-upload-box"
                    }`}
                >
                  {selectedMediaFileDetailsForImages?.length === 0 ? (
                    <div
                      {...getRootProps({
                        className: `${selectedMediaFileDetailsForImages?.length > 0
                          ? "image-zone"
                          : "dropzone-2"
                          }`,
                        style: {
                          textAlign: "center",
                          cursor: "pointer",
                          position: "relative",
                          width: "100%",
                          height: "100%",
                          minHeight: "120px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        },
                      })}
                    >
                      <input {...getInputProps()} />
                      <p className="drag-drop-text">
                        Drag 'n' drop image here, or click to select
                      </p>
                    </div>
                  ) : (
                    <div className="w-100">{renderPreview()}</div>
                  )}
                  {/* <div
                    {...getRootProps({
                      className: `${selectedMediaFileDetailsForImages?.length > 0 ? 'image-zone' : "dropzone-2"}`,
                      style: {
                        textAlign: "center",
                        cursor: "pointer",
                        position: "relative",
                        width: "100%",
                        height: "100%",
                      },
                    })}
                  >
                    <input {...getInputProps()} />
                    {selectedMediaFileDetailsForImages?.length === 0 ? (
                      <p className="drag-drop-text">Drag 'n' drop image here, or click to select</p>
                    ) : (
                      <div className="">{renderPreview()}</div>
                    )}
                  </div> */}
                </Box>
              </>
            ) : (
              <>
                {selectedMediaFileForUpdate?.file_path && (
                  <div className="upload-img-wrapper">
                    <Image src={selectedMediaFileForUpdate?.file_path} />
                  </div>
                )}
              </>
            )}
            {selectedMediaFileDetailsForImages?.length < 10 && (
              <Box className="w-100">
                <Button
                  className="btn ternary-btn"
                  // onClick={handleButtonClick}
                  onClick={() => {
                    setOpenMediaModelFor("normal");
                    handleOpenUploadMedia("image");
                  }}
                >
                  <FontAwesomeIcon icon={faImage} />
                  {selectedMediaFileForUpdate?.file_path
                    ? "Change Media"
                    : "Choose From Media"}
                </Button>
              </Box>
            )}

            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            {showAdvancedOption === true && (
              <Divider>
                <Button
                  onClick={() => setIsCollapsed((prevState) => !prevState)}
                  className="bg-white hide-option-btn"
                >
                  {isCollapsed == false
                    ? "Show Advanced Options"
                    : "Hide Advanced Options"}

                  <FontAwesomeIcon
                    icon={isCollapsed == false ? faChevronUp : faChevronDown}
                    className="hide-option-btn-svg"
                  />
                </Button>
              </Divider>
            )}

            {isCollapsed == true && (
              <Box>
                <Row>
                  {attributesData?.map((attdata, index) => (
                    <Col lg="12" key={attdata?.id} className="att-col-12">
                      {attdata?.fieldtype === "checkbox_single_choice" && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={attdata?.value == true}
                              name={attdata?.nameValue}
                              onChange={(event) => {
                                handleChangeForFormData(
                                  attdata?.nameValue,
                                  event?.target?.checked,
                                  attdata?.section_id
                                );
                              }}
                            />
                          }
                          label={attdata?.label}
                          className="font-inter"
                        />
                      )}
                    </Col>
                  ))}
                  <Select
                    closeMenuOnSelect={false}
                    options={categoryList}
                    menuPlacement="auto"
                    name="select-cate"
                    placeholder={`Select category...`}
                    className="muilt-select-field w-100 cate-update-post"
                    classNamePrefix="select"
                    isMulti
                    value={categoryList?.filter((option) =>
                      selectedCategories?.includes(option?.value)
                    )}
                    onChange={(selectedOption) => {
                      const selectedValues = selectedOption.map(
                        (option) => option?.value
                      );
                      setSelectedCategories(selectedValues);

                      const updatedCategories = selectedValues
                        .map((categoryId) => {
                          const category = categoryListData.find(
                            (cat) => cat.id === categoryId
                          );
                          if (category) {
                            return {
                              label: category.title,
                              slug: category.slug,
                              id: category.id,
                              options: category.categoryterm.map((term) => ({
                                label: term.name,
                                value: term.id,
                                categoryId: term.category_id,
                              })),
                            };
                          }
                          return null;
                        })
                        .filter(Boolean); // Remove any null values

                      setGetAllCategories(updatedCategories);

                      // Remove any keys from selectedCategoriesTerms that are not in the updated categories
                      setSelectedCategoriesTerms((prevTerms) => {
                        const updatedTerms = { ...prevTerms };
                        const activeSlugs = updatedCategories.map(
                          (cat) => cat.slug
                        );

                        Object.keys(updatedTerms).forEach((key) => {
                          if (!activeSlugs.includes(key)) {
                            delete updatedTerms[key];
                          }
                        });

                        return updatedTerms;
                      });
                    }}
                    styles={{
                      option: (provided, state) => {
                        return {
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#660c605c"
                            : provided.backgroundColor,
                          color: state.isSelected
                            ? "var(--dark-color)"
                            : provided.color,
                        };
                      },
                    }}
                  />
                  {getAllCategories?.length > 0 && (
                    <>
                      {getAllCategories?.map((category) => (
                        <>
                          <Col
                            lg="12"
                            className="cate-term-list-update-post mt-1"
                          >
                            <Box className="d-flex w-100 align-items-center">
                              <Typography className="fw-400 dark-text fs-12-muted">
                                {category?.label}
                              </Typography>
                            </Box>
                          </Col>
                          <Col lg="12" className="cate-term-list-update-post">
                            <Box className="w-100">
                              <Select
                                closeMenuOnSelect={false}
                                options={category?.options}
                                menuPlacement="top"
                                name="groupManagers"
                                value={
                                  selectedCategoriesTerms[category.slug] || []
                                }
                                placeholder={`Select Terms For ${category?.label}...`}
                                menuPosition="fixed"
                                isMulti
                                onChange={(selectedOption) =>
                                  handleCategoryChange(
                                    category.slug,
                                    selectedOption
                                  )
                                }
                                className={`muilt-select-field w-100`}
                                classNamePrefix="select"
                                styles={{
                                  option: (provided, state) => {
                                    return {
                                      ...provided,
                                      backgroundColor: state.isSelected
                                        ? "#660c605c"
                                        : provided.backgroundColor,
                                      color: state.isSelected
                                        ? "var(--dark-color)"
                                        : provided.color,
                                    };
                                  },
                                }}
                              />
                            </Box>
                          </Col>
                        </>
                      ))}
                    </>
                  )}
                </Row>
              </Box>
            )}
          </Stack>
        </DialogContent>
        <DialogActions className="p-3">
          <Button
            className="btn primary-btn w-100"
            type="submit"
            onClick={() => {
              if (updatePostId) {
                updatePost();
              } else {
                sumbitPost();
              }
            }}
          >
            <FontAwesomeIcon
              icon={faCheck}
              className="d-md-block d-sm-none d-none"
            />
            <FontAwesomeIcon
              icon={faArrowRight}
              className="d-md-none d-sm-block"
            />
            Post Update
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Loader isLoading={loading} />
    </>
  );
}

export default UpdatePostCreateModal;
