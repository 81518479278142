import React from 'react'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BootstrapTooltip } from './tooltip/bootstrapTooltip'

function StatusIcon({ status }) {
  return (
    <BootstrapTooltip
      title={status === 'archived' ? "Archived" :
        status === "enable"
          ? "Active" : status === "draft" ? "Draft"
            : status === "disable"
              ? "Inactive"
              : "Deleted"}
      placement="top"

    >
      <FontAwesomeIcon
        icon={faCircle}
        size="xs"
        style={{
          color:
            status === 'archived' ? "#FB7900" :
              status === "enable"
                ? "#45B733" : status === "draft" ? "#FFFF00"
                  : status === "disable"
                    ? "#e10e0e"
                    : "#959595"
        }}
      />
    </BootstrapTooltip>

  )
}

export default StatusIcon